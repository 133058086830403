import { Flex, Text } from '@chakra-ui/react';

type Props = {
  count: number;
  label: string;
};

export const Indicator = ({ count, label }: Props) => (
  <Flex
    direction="column"
    justify="center"
    h="65px"
    px={4}
    borderLeftWidth="1px"
    borderColor="ui.grey3"
  >
    <Text size="p1">{count}</Text>
    <Text size="p1">{label}</Text>
  </Flex>
);
