import { ArrowForwardIcon, CheckIcon } from '@chakra-ui/icons';
import { Box, Center, Flex, Heading } from '@chakra-ui/react';

import { ItriomButtonOutline } from 'components/Button';
import { useAuthContext } from 'contexts/AuthContext';

import { useGetOnboardContent } from '../../hooks';

export const ThankYou = () => {
  const { logout } = useAuthContext();

  const { sdg } = useGetOnboardContent();

  return (
    <Flex color="ui.white" w="full" h="full" justifyContent="center">
      <Box maxW={540} textAlign="center">
        <Heading size="h4" mb={10}>
          Thank you.
        </Heading>
        <Flex justifyContent="center">
          <Center
            w={70}
            h={70}
            borderRadius="50%"
            borderColor="ui.white"
            borderWidth={1}
          >
            <CheckIcon color="ui.white" fontSize="3xl" />
          </Center>
        </Flex>

        <Heading size="h5" my={20}>
          {sdg.thankYouCopy}
        </Heading>
        <ItriomButtonOutline
          bgColor="primary.lime.default"
          _hover={{
            color: 'primary.blue.default',
            bgColor: 'ui.white',
          }}
          rightIcon={<ArrowForwardIcon />}
          mx="auto"
          mb={6}
          onClick={async () => {
            await logout();
          }}
          fontFamily="Roboto"
        >
          Log out
        </ItriomButtonOutline>
      </Box>
    </Flex>
  );
};
