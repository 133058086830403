import { Link } from 'react-router-dom';

import { Grid } from '@chakra-ui/layout';
import { Image, Box } from '@chakra-ui/react';

import { TextContent } from '../TextContent';
import { CommonTileProps } from '../types';

export type ImagePosition = 'top' | 'bottom' | 'left' | 'right';

export type Props = CommonTileProps & {
  imagePosition: ImagePosition;
};

const TEMPLATE_AREAS: Record<ImagePosition, string> = {
  top: '"image" "content"',
  bottom: '"content" "image"',
  left: '"image content"',
  right: '"content image"',
};

export const SideImageTile = ({
  tags,
  title,
  description,
  image,
  link,
  imagePosition = 'left',
}: Props) => {
  return (
    <Grid
      as={Link}
      to={link}
      templateAreas={TEMPLATE_AREAS[imagePosition]}
      templateColumns={
        imagePosition === 'top' || imagePosition === 'bottom'
          ? '1fr'
          : '50% 50%'
      }
      templateRows={
        imagePosition === 'top' || imagePosition === 'bottom'
          ? '50% 50%'
          : '1fr'
      }
      w="100%"
      h="100%"
    >
      <Image src={image} objectFit="cover" gridArea="image" w="100%" h="100%" />
      <Box
        p={4}
        w="100%"
        h="100%"
        gridArea="content"
        overflow="hidden"
        bg="ui.white"
      >
        <TextContent
          variant="dark"
          description={description}
          title={title}
          tags={tags}
        />
      </Box>
    </Grid>
  );
};
