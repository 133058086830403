import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AspectRatio, Box, Grid, GridItem } from '@chakra-ui/react';

import { ButtonsGroup } from 'components/Button/ButtonsGroup';
import { SdgOverview, SGDS_OVERVIEW } from 'constants/Sdgs';
import { FlipCard } from 'features/Onboarding/components/SdgConfigurator/SdgsDetail/FlipCard';
import { SectionHeading } from 'features/Onboarding/components/SectionHeader';

interface Props {
  onBackButtonClicked: VoidFunction;
  onNextButtonClicked: VoidFunction;
}

export const OverviewSection = ({
  onBackButtonClicked,
  onNextButtonClicked,
}: Props) => {
  const { t } = useTranslation(['family', 'common']);
  const [sdg, setSdg] = useState<SdgOverview | null>();

  const handleClick = (clickedSdg: SdgOverview) => {
    setSdg(sdg === clickedSdg ? null : clickedSdg);
  };

  return (
    <Grid
      gridTemplateColumns="repeat(4, 1fr)"
      gridTemplateRows="auto 1fr auto"
      overflowY="scroll"
      maxH="100vh"
      mx="auto"
      maxW={1060}
      pt="6.875rem"
      pb={4}
    >
      <GridItem colSpan={4}>
        <SectionHeading
          heading={t('family:charter.section.sdgInfluences.heading')}
          subheading={t('family:charter.section.sdgInfluences.subheading')}
          color="primary.blue.default"
        />
      </GridItem>
      {Object.values(SGDS_OVERVIEW).map((sdgItem) => (
        <AspectRatio key={sdgItem.internalName} ratio={27 / 70} h="full">
          <Box onClick={() => handleClick(sdgItem)}>
            <FlipCard isFlipped={sdgItem === sdg} sdgOverview={sdgItem} />
          </Box>
        </AspectRatio>
      ))}
      <GridItem colStart={1} colSpan={4} pt={16}>
        <ButtonsGroup
          leftButton={{
            text: t('common:back'),
            color: 'primary.blue.default',
            onClick: onBackButtonClicked,
          }}
          rightButton={{
            text: t('family:charter.go-to-your-charter-page'),
            onClick: onNextButtonClicked,
          }}
        />
      </GridItem>
    </Grid>
  );
};
