import { useField } from 'formik';

import { WarningFilled } from '@carbon/icons-react/next';
import { Flex, Text } from '@chakra-ui/layout';
import {
  FormControl,
  FormErrorMessage,
  Box,
  FormLabel,
} from '@chakra-ui/react';

import { HeroMediaType } from 'types';

import { UploadCheckBox } from './UploadCheckBox';
import { UploadPreview, UploadField } from './components';
import { UploadProps } from './types';

export const Upload = ({
  name,
  label,
  maxFiles,
  maxSize = '5MB',
  uploadAndUseId,
  containerStyles = {},
  withCheckboxes,
  type = HeroMediaType.Image,
  showErrorIcon,
  ...previewProps
}: UploadProps): JSX.Element => {
  const isImage = type === HeroMediaType.Image;

  const [, { error, touched }, { setTouched }] = useField(name);

  const handleTouch = () => setTouched(true);

  const isInvalid = touched && !!error;

  return (
    <FormControl
      isInvalid={isInvalid}
      sx={containerStyles}
      aria-label={label}
      aria-labelledby={name}
    >
      <Flex direction="column">
        <Flex>
          <FormLabel htmlFor={name} flex={1}>
            {label}
          </FormLabel>
          {withCheckboxes && <UploadCheckBox type={type} />}
        </Flex>
        <Text size="p2" color="ui.grey2" mb={3}>
          Only {isImage ? '.jpg and .png' : '.mp4'} files. {maxSize} max file
          size.
        </Text>
        <Box onClick={handleTouch}>
          <UploadField
            name={name}
            onTouch={handleTouch}
            isImage={isImage}
            maxSize={maxSize}
            maxFiles={maxFiles}
            uploadAndUseId={uploadAndUseId}
            isInvalid={isInvalid}
          />
          <UploadPreview isImage={isImage} name={name} {...previewProps} />
        </Box>
      </Flex>
      <FormErrorMessage gap={1}>
        {error}
        <Flex>{showErrorIcon && <WarningFilled />}</Flex>
      </FormErrorMessage>
    </FormControl>
  );
};
