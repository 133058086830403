import { Heading } from '@chakra-ui/react';

import { SectionWithImage } from './SectionWithImage';

export const MeasuringChangeSection = () => (
  <SectionWithImage
    reverse
    heading="Measuring Positive Change – Linking Impact Investment with Data and Dashboards"
    imageSrc="/assets/marketplace/Itriom-platform_Dashboards.gif"
  >
    <Heading size="h5">
      Obviously, it’s crucial to ensure the family’s impact is tangible,
      sustainable and positive. Because that’s the only basis for a lasting and
      credible legacy.
      <br /> <br />
      With this in mind your impact investments, initiatives and philanthropic
      activity can be tracked based on chosen metrics. Reported on using visual
      dashboards to understand the positive impact of your actions.
      <br /> <br />
      All the visuals and data from your dashboard can be accessed and utilised
      in quarterly and annual reports, family communications and externally for
      public affairs and discrete marketing.
    </Heading>
  </SectionWithImage>
);
