import { Chat } from '@carbon/icons-react/next';
import { Box, HStack, Flex, Text } from '@chakra-ui/react';

import { AppreciationType } from 'types';

import { Appreciate } from '../Appreciate';

type Props = {
  postId: string;
  appreciationCount: number;
  commentCount: number;
  isSelfAppreciated: boolean;
};

export const PostCardFooter = ({
  postId,
  appreciationCount,
  commentCount,
  isSelfAppreciated,
}: Props) => {
  return (
    <HStack w="full">
      <Box flex={1}>
        <Appreciate
          isSelfAppreciated={isSelfAppreciated}
          count={appreciationCount}
          referenceId={postId}
          type={AppreciationType.Post}
        />
      </Box>

      <Flex gap={1}>
        <Text size="p3">{commentCount}</Text>
        <Chat />
      </Flex>
    </HStack>
  );
};
