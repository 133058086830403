import { Tab } from 'components/TabPane';

import { AllConnections } from './AllConnections';
import { ConnectionTabPanel } from './ConnectionTabPanel';
import { ConnectionTab } from './types';

export const connectionTabsConfig: Record<ConnectionTab, Tab> = {
  [ConnectionTab.All]: {
    content: <AllConnections />,
    navigateTo: `#${ConnectionTab.All}`,
    labelKey: 'all',
  },
  [ConnectionTab.Family]: {
    content: <ConnectionTabPanel type={ConnectionTab.Family} />,
    navigateTo: `#${ConnectionTab.Family}`,
    labelKey: 'family',
  },
  [ConnectionTab.Peers]: {
    content: <ConnectionTabPanel type={ConnectionTab.Peers} />,
    navigateTo: `#${ConnectionTab.Peers}`,
    labelKey: 'peers',
  },
  [ConnectionTab.Advisor]: {
    content: <ConnectionTabPanel type={ConnectionTab.Advisor} />,
    navigateTo: `#${ConnectionTab.Advisor}`,
    labelKey: 'advisor',
  },
  [ConnectionTab.Recommended]: {
    content: <ConnectionTabPanel type={ConnectionTab.Recommended} />,
    navigateTo: `#${ConnectionTab.Recommended}`,
    labelKey: 'recommended',
  },
};
