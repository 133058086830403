import { useTranslation } from 'react-i18next';

import { ApolloError } from '@apollo/client';

import {
  libraryTypeBaseLinkMap,
  libraryTypeFolderLinkMap,
} from 'features/Library/utils/libraryTypeLinkMap';
import { libraryTypeToLabelMap } from 'features/Library/utils/libraryTypeToLabelMap';
import { Links } from 'router/links';
import { LibraryMediaOutput, LibrarySharedMediaOutput } from 'types';
import { generateUrlById } from 'utils/links';

export const useBreadcrumbsLinks = ({
  details,
  loading,
  error,
  shared,
}: {
  details: LibrarySharedMediaOutput | LibraryMediaOutput | undefined;
  error: ApolloError | undefined;
  loading: boolean;
  shared: boolean;
}) => {
  const { t } = useTranslation(['library']);

  if (details && !loading && !error) {
    return [
      {
        link: shared
          ? Links.librarySharedWithMe
          : libraryTypeBaseLinkMap[details.libraryType],
        label: t(
          shared
            ? 'library:side-navigation.items.shared'
            : libraryTypeToLabelMap[details.libraryType]
        ),
      },
      ...details.resolvedPath.map((path) => ({
        link: generateUrlById(
          shared
            ? Links.librarySharedWithMeFolder
            : libraryTypeFolderLinkMap[path.libraryType],
          path.id
        ),
        label: path.name,
        id: path.id,
      })),
      {
        link: generateUrlById(
          shared
            ? Links.librarySharedWithMeFolder
            : libraryTypeFolderLinkMap[details.libraryType],
          details.id
        ),
        label: details.name,
        id: details.id,
      },
    ];
  }

  return [];
};
