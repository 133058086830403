import { useQuery } from '@apollo/client';

import { Query } from 'types';

import { familyConnectionsGql } from '../documents';

export const useFamilyConnections = () => {
  const { data, loading, error } =
    useQuery<Pick<Query, 'familyConnections'>>(familyConnectionsGql);

  const familyConnections = data?.familyConnections ?? [];

  return { familyConnections, loading, error };
};
