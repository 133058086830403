import { useCallback } from 'react';

import { gql, useLazyQuery } from '@apollo/client';

import { Query, QueryMembersToAddPaginatedArgs } from 'types';
import { MemberToAddOutputFragment } from 'types/generated-fragments';

export const membersToAddToGroupGql = gql`
  ${MemberToAddOutputFragment}
  query MembersToAddPaginated(
    $data: FindGroupMembersInput!
    $pagination: PaginationInput!
  ) {
    membersToAddPaginated(data: $data, pagination: $pagination) {
      nodes {
        ...MemberToAddOutputFragment
      }
      offset
      totalCount
    }
  }
`;

type FetchMembersArgs = {
  groupId: string;
  memberName: string;
};

export const useMembersToAddToGroup = () => {
  const [fetchUsers, { data, loading, error }] = useLazyQuery<
    Pick<Query, 'membersToAddPaginated'>,
    QueryMembersToAddPaginatedArgs
  >(membersToAddToGroupGql, { fetchPolicy: 'no-cache' });

  const handleFetchUsers = useCallback(
    ({ groupId, memberName }: FetchMembersArgs) =>
      fetchUsers({
        variables: {
          data: { groupId, memberName },
          pagination: { limit: 10, offset: 0 },
        },
      }),
    [fetchUsers]
  );

  return {
    handleFetchUsers,
    membersToAdd: data?.membersToAddPaginated.nodes || [],
    loading,
    error,
  };
};
