import { ReactNode } from 'react';

import { Heading, Flex, FlexProps } from '@chakra-ui/react';

type Props = {
  heading: string;
  subheading?: ReactNode;
  color?: string;
  size?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  containerStyling?: FlexProps;
};

export const SectionHeading = ({
  color = 'white',
  size = 'h1',
  heading,
  subheading,
  containerStyling,
}: Props) => {
  return (
    <Flex
      direction="column"
      color={color}
      mr={{ lg: '40%', base: '0' }}
      mb={8}
      {...containerStyling}
    >
      <Heading
        size={size}
        pb={4}
        mb={4}
        position="relative"
        _after={{
          position: 'absolute',
          left: 0,
          bottom: 0,
          content: '""',
          width: 16,
          height: 1,
          backgroundColor: color,
        }}
      >
        {heading}
      </Heading>
      {subheading && (
        <Heading size="h6" mt={4}>
          {subheading}
        </Heading>
      )}
    </Flex>
  );
};
