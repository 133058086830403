import { Flex, Tabs } from '@chakra-ui/react';

import { LayoutGrid, LayoutGridElement } from 'components/Layout/Grid';
import { PageHeader } from 'components/PageHeader';
import { QueryStateGuard } from 'components/QueryStateGuard';
import { TrendingGroups } from 'components/TrendingGroups';

import { useGroupContext } from '../GroupContext';
import {
  GroupPreview,
  GroupSearch,
  GroupsSider,
  GroupTabList,
} from '../components';

export const PreviewGroupPage = () => {
  const { group, loading, error } = useGroupContext();

  return (
    <QueryStateGuard loading={loading} error={error}>
      {group && (
        <LayoutGrid>
          <LayoutGridElement>
            <PageHeader
              avatarSrc={group.avatarImage?.signedUrl}
              name={group.name}
            />
          </LayoutGridElement>

          <LayoutGridElement colSpan={4}>
            <GroupsSider />
          </LayoutGridElement>

          <LayoutGridElement colSpan={8}>
            <Tabs>
              <GroupTabList />
              <GroupPreview group={group} />
            </Tabs>
          </LayoutGridElement>

          <LayoutGridElement colSpan={4}>
            <Flex direction="column" justify="flex-end" gap={4}>
              <GroupSearch />
              <TrendingGroups />
            </Flex>
          </LayoutGridElement>
        </LayoutGrid>
      )}
    </QueryStateGuard>
  );
};
