import { ThreeDotsMenu } from 'components/ThreeDotsMenu';
import { useCurrentLibraryLocation } from 'features/Library/hooks/useCurrentLibraryLocation';
import { LibraryLocation } from 'features/Library/types';
import { LibraryMediaOutput, LibrarySharedMediaOutput } from 'types';

import { MenuProvider } from '../MenuElements/MenuProvider';
import { SharedMenuProvider } from '../MenuElements/SharedMenuProvider';

interface Props {
  item: LibraryMediaOutput | LibrarySharedMediaOutput;
}

export const Menu = ({ item }: Props) => {
  const { libraryLocation } = useCurrentLibraryLocation();

  return (
    <ThreeDotsMenu
      iconVariant="dark"
      elements={
        libraryLocation === LibraryLocation.SharedWithMe
          ? SharedMenuProvider({ item })
          : MenuProvider({ item })
      }
    />
  );
};
