import { useQuery } from '@apollo/client';

import { Query } from 'types';

import { allFamilyConnectionsGql } from '../documents';

export const useAllFamilyConnections = () => {
  const { data, loading, error } = useQuery<
    Pick<Query, 'allFamilyConnections'>
  >(allFamilyConnectionsGql);
  const familyConnections = data?.allFamilyConnections ?? [];

  return { familyConnections, loading, error };
};
