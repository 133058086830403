import { LocalStorageKeys } from 'types';

export const useLocalStorageJWT = () => {
  const getJwtFromLocalStorage = () => {
    return localStorage.getItem(LocalStorageKeys.JWT);
  };

  const setJwtInLocalStorage = (jwt: string) => {
    localStorage.setItem(LocalStorageKeys.JWT, jwt);
    // Trigger a storage event so that AuthContext.tsx can refetch the account data
    window.dispatchEvent(new Event('storage'));
  };

  const clearJwtInLocalStorage = () => {
    localStorage.removeItem(LocalStorageKeys.JWT);
  };

  return {
    getJwtFromLocalStorage,
    setJwtInLocalStorage,
    clearJwtInLocalStorage,
  };
};
