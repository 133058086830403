import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

import { Option } from 'types';

import { useSdgOptions } from '../../../../../../hooks/sdgs/useSdgOptions';

interface UseEventFilters {
  yearFilter?: string;
  setYearFilter: (value: string) => void;
  monthFilter?: string;
  setMonthFilter: (value: string) => void;
  sdgFilter?: string;
  setSdgFilter: (value: string) => void;
  sdgOptions: Option<string>[];
  yearOptions: Option<string>[];
  monthOptions: Option<string>[];
  currentYear: number;
  selectedYearLabel?: string;
  selectedMonthLabel?: string;
  selectedSDGLabel?: string;
}

export const EventFiltersContext = createContext<UseEventFilters | null>(null);

type EventContextProviderProps = {
  children?: ReactNode;
};

export const EventContextProvider = ({
  children,
}: EventContextProviderProps): JSX.Element => {
  const [yearFilter, setYearFilter] = useState<string>();
  const [monthFilter, setMonthFilter] = useState<string>();
  const [sdgFilter, setSdgFilter] = useState<string>();
  const { sdgOptions } = useSdgOptions();

  const options: Option[] = sdgOptions;
  const currentYear = new Date().getFullYear();
  const targetYear = new Date().getFullYear() - 10;
  const yearOptions: Option[] = useMemo(
    () => [
      { value: '0', label: 'All' },
      ...Array.from({ length: currentYear - targetYear + 1 }, (_, index) => ({
        value: (currentYear - index).toString(),
        label: (currentYear - index).toString(),
      })),
    ],
    [currentYear, targetYear]
  );
  const monthOptions: Option[] = useMemo(
    () => [
      { value: '1', label: 'January' },
      { value: '2', label: 'February' },
      { value: '3', label: 'March' },
      { value: '4', label: 'April' },
      { value: '5', label: 'May' },
      { value: '6', label: 'June' },
      { value: '7', label: 'July' },
      { value: '8', label: 'August' },
      { value: '9', label: 'September' },
      { value: '10', label: 'October' },
      { value: '11', label: 'November' },
      { value: '12', label: 'December' },
    ],
    []
  );

  const selectedYearLabel = useMemo(
    () => yearOptions.find((e) => e.value === yearFilter)?.label,
    [yearFilter, yearOptions]
  );

  const selectedMonthLabel = useMemo(
    () => monthOptions.find((e) => e.value === monthFilter)?.label,
    [monthFilter, monthOptions]
  );

  const selectedSDGLabel = useMemo(
    () => sdgOptions.find((e) => e.value === sdgFilter)?.label,
    [sdgFilter, sdgOptions]
  );

  return (
    <EventFiltersContext.Provider
      value={useMemo<UseEventFilters>(
        () => ({
          yearOptions,
          yearFilter,
          setYearFilter,
          monthOptions,
          monthFilter,
          setMonthFilter,
          sdgOptions: options,
          sdgFilter,
          setSdgFilter,
          selectedMonthLabel,
          selectedYearLabel,
          selectedSDGLabel,
          currentYear,
        }),
        [yearFilter, monthFilter, sdgFilter, options, currentYear]
      )}
    >
      {children}
    </EventFiltersContext.Provider>
  );
};

export const useEventFilters = (): UseEventFilters => {
  const context = useContext(EventFiltersContext);

  if (!context) {
    throw new Error('useEventFilters was used outside of its Provider');
  }

  return context;
};
