import { Flex, Heading, Image, Text, Box } from '@chakra-ui/react';

import { ServiceCard } from 'features/NextGenServices/types';

export const InterService = ({ src, title, description }: ServiceCard) => {
  return (
    <Flex direction="column" w={308} h="full">
      <Image src={src} />
      <Box>
        <Heading size="h5" mt={5} color="ui.white">
          {title}
        </Heading>
        <Text size="p1" color="ui.white" my={4}>
          {description}
        </Text>
      </Box>
    </Flex>
  );
};
