import { ReactNode } from 'react';

import { Flex, Heading } from '@chakra-ui/react';

import { CalendarBlock } from 'components/CalendarBlock';
import { ItriomTag } from 'components/ItriomTag';
import { ItriomTagsV2Output } from 'types';
import { flattenTags } from 'utils/tags';

interface Props {
  title: string;
  subtitle?: string;
  tags?: ItriomTagsV2Output;
  children?: ReactNode;
  calendarDate?: {
    start?: string;
    end?: string;
    format?: string;
  };
}

export const HeroBody = ({
  title,
  subtitle,
  tags,
  calendarDate,
  children,
}: Props) => {
  return (
    <Flex
      direction="column"
      justify="flex-end"
      align="flex-start"
      height="100%"
      w="full"
      color="ui.white"
      gap={4}
    >
      {title && <Heading size="h1">{title}</Heading>}
      {subtitle && <Heading size="h2">{subtitle}</Heading>}
      {tags && (
        <Flex gap={2} wrap="wrap">
          {flattenTags(tags).map((tag) => (
            <ItriomTag key={tag} customVariant="outlineLight">
              {tag}
            </ItriomTag>
          ))}
        </Flex>
      )}
      {calendarDate && (
        <CalendarBlock
          startDate={calendarDate.start}
          endDate={calendarDate.end}
          format={calendarDate.format}
          containerStyling={{ fontSize: '20px' }}
        />
      )}
      {children}
    </Flex>
  );
};
