import { Form, FormikProvider, useFormik } from 'formik';

import { KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Image as ImageIcon } from '@carbon/icons-react/next';
import {
  Flex,
  Button,
  Icon,
  useDisclosure,
  IconButton,
} from '@chakra-ui/react';

import { FileUploadModal } from 'components/FileUploadModal';
import { TextAreaInput } from 'components/Form/TextAreaInput';
import { ImagesPreview } from 'components/ImagesPreview';

import { PostFormBaseProps, FormValues } from './types';
import { getValidationSchema } from './validation';

export const PostFormBase = ({
  handleSubmit,
  initialValues,
  textCharLimit,
  textLabel,
  textPlaceholder,
  titleCharLimit = 200,
  titleLabel,
  titlePlaceholder,
  withTitle,
}: PostFormBaseProps) => {
  const { t } = useTranslation(['network', 'common']);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const validationSchema = getValidationSchema({
    textCharLimit,
    titleCharLimit,
  });

  const formik = useFormik<FormValues>({
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers) => {
      await handleSubmit(values);

      helpers.resetForm();
    },
  });

  const { isSubmitting, setFieldValue } = formik;

  const handleKeyboardSend = async (event: KeyboardEvent) => {
    if (event.shiftKey && event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      formik.handleSubmit();
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form style={{ width: '100%' }}>
        <Flex direction="column">
          {withTitle && (
            <TextAreaInput
              type="text"
              name="title"
              label={titleLabel || t('network:post-form.title.label')}
              placeholder={
                titlePlaceholder || t('network:post-form.title.placeholder')
              }
              maxLength={titleCharLimit}
              mb={6}
              minH={12}
            />
          )}

          <TextAreaInput
            onKeyDown={handleKeyboardSend}
            type="text"
            name="text"
            label={textLabel || t('network:post-form.content.label')}
            placeholder={
              textPlaceholder || t('network:post-form.content.placeholder')
            }
            maxLength={textCharLimit}
            minH={12}
          />

          {!isOpen && <ImagesPreview images={formik.values.upload} />}

          <Flex justify="flex-end" align="center" gap={4} mt={2}>
            <IconButton
              display="contents"
              aria-label={t('network:choose-image')}
              color="primary.blue.default"
              icon={<Icon as={ImageIcon} h={5} w={5} />}
              onClick={onOpen}
              data-cy="image-attachment-icon"
            />
            <Button
              size="small"
              variant="primaryBlue"
              isLoading={isSubmitting}
              type="submit"
            >
              {t('common:post')}
            </Button>
          </Flex>
          <FileUploadModal
            isOpen={isOpen}
            setFieldValue={setFieldValue}
            onClose={onClose}
          />
        </Flex>
      </Form>
    </FormikProvider>
  );
};
