import { useNavigate } from 'react-router-dom';

import { useGetDownloadUrlForSharedLibraryFile } from 'gql/library/query/getDownloadUrlForSharedLibraryFile';
import { Links } from 'router/links';
import { LibraryMediaOutput, LibrarySharedMediaOutput } from 'types';
import { generateUrlById } from 'utils/links';

import { ItemRowDisplay } from './ItemRowDisplay';

interface Props {
  item: LibraryMediaOutput | LibrarySharedMediaOutput;
  isChecked: boolean;
  displayCheckbox?: boolean;
  enableDragAndDrop?: boolean;
  onCheckToggle: (
    item: LibraryMediaOutput | LibrarySharedMediaOutput,
    checked: boolean
  ) => void;
}

export const ItemRowShared = ({
  item,
  onCheckToggle,
  isChecked,
  displayCheckbox = true,
  enableDragAndDrop = true,
}: Props) => {
  const navigate = useNavigate();
  const { sharedDownloadUrl } = useGetDownloadUrlForSharedLibraryFile(item);

  const handleItemClick = () => {
    navigate(generateUrlById(Links.librarySharedWithMeFolder, item.id));
  };

  return (
    <ItemRowDisplay
      item={item}
      isChecked={isChecked}
      onCheckToggle={onCheckToggle}
      handleItemClick={handleItemClick}
      downloadUrl={sharedDownloadUrl}
      displayCheckbox={displayCheckbox}
      enableDragAndDrop={enableDragAndDrop}
    />
  );
};
