import { useTranslation } from 'react-i18next';

import { Breadcrumbs } from 'components/Breadcrumbs';
import { Links } from 'router/links';

import { InterConnectedServicesSection } from '../components/InterConnectedServicesSection';
import { MarketplaceHeroSection } from '../components/MarketplaceHeroSection';
import { NextGenHeaderSection } from '../components/NextGenHeaderSection';
import { NextGenServicesSection } from '../components/NextGenServicesSection';
import { QuoteSection } from '../components/QuoteSection';
import { SingleLeaderSection } from '../components/SingleLeaderSection';
import { SolutionSection } from '../components/SolutionSection';
import { geostrategy } from '../content';

const {
  hero,
  solutions,
  interConnectedServices,
  heroDescription,
  leader,
  quote,
  services,
} = geostrategy;

export const NextGenGeostrategyPage = () => {
  const { t } = useTranslation(['navigation']);

  return (
    <>
      <NextGenHeaderSection {...hero} />
      <Breadcrumbs
        items={[
          { link: Links.home, label: t('navigation:home') },
          {
            link: Links.nextGenGeoStrategy,
            label: t('navigation:geostrategy'),
          },
        ]}
      />
      <MarketplaceHeroSection text={heroDescription} />
      <InterConnectedServicesSection {...interConnectedServices} />
      <SolutionSection {...solutions} />
      <SingleLeaderSection {...leader} />
      <QuoteSection {...quote} />
      <NextGenServicesSection services={services} />
    </>
  );
};
