import { Navigate } from 'react-router-dom';

import { Loader } from 'components/Loader';
import { useAuthContext } from 'contexts/AuthContext';
import { Links } from 'router/links';
import { SecureRouteProps } from 'router/types';
import { OnboardAccountStatus, WithChildren } from 'types';

type Props = WithChildren<SecureRouteProps>;

export const SecureRoute = ({
  children,
  mustBeLogin,
  roles,
  onlyAfterOnboarding,
}: Props) => {
  const { account, loading } = useAuthContext();
  const { role, onboardingStatus } = account ?? {};

  const isSecuredRoute = !!roles;
  const hasRoleWithAccessToSecureRoute =
    isSecuredRoute && role && roles.includes(role);

  const isOnboarded = onboardingStatus === OnboardAccountStatus.Onboarded;

  if (loading) return <Loader />;

  if (isSecuredRoute && !hasRoleWithAccessToSecureRoute) {
    return <Navigate to={Links.home} />;
  }

  if (mustBeLogin && !account) {
    return <Navigate to={Links.signIn} />;
  }

  if (onlyAfterOnboarding && !isOnboarded) {
    return <Navigate to={Links.valuesConfigurator} />;
  }

  return <>{children}</>;
};
