import { ANIMATION_CATEGORY_DELAY, ANIMATION_START_DELAY } from './constants';
import { AxisDirection, Curvature, CurveConfig, Position } from './types';

export const getArcPath = ({
  radius,
  xDir,
  yDir,
  curveOrientation = Curvature.Inside,
}: CurveConfig): string =>
  `a${radius} ${radius} 0 0 ${curveOrientation} ${radius * xDir} ${
    radius * yDir
  }`;

const getCurveConfig = (pos: Position): Omit<CurveConfig, 'radius'> => {
  switch (pos) {
    case Position.LeftBottom:
      return {
        xDir: AxisDirection.Positive,
        yDir: AxisDirection.Positive,
        curveOrientation: Curvature.Inside,
      };
    case Position.RightBottom:
      return {
        xDir: AxisDirection.Positive,
        yDir: AxisDirection.Negative,
        curveOrientation: Curvature.Inside,
      };
    case Position.RightTop:
      return {
        xDir: AxisDirection.Positive,
        yDir: AxisDirection.Positive,
        curveOrientation: Curvature.Outside,
      };
    case Position.LeftTop:
    default:
      return {
        xDir: AxisDirection.Positive,
        yDir: AxisDirection.Negative,
        curveOrientation: Curvature.Outside,
      };
  }
};

const getCurveStartPoint = (
  radius: number,
  pos: Position,
  padding = 0
): [number, number] => {
  switch (pos) {
    case Position.LeftBottom:
      return [padding, radius];
    case Position.RightBottom:
      return [radius, 2 * radius - padding];
    case Position.RightTop:
      return [radius, padding];
    case Position.LeftTop:
    default:
      return [padding, radius];
  }
};

export const getQuarterPath = (radius: number, pos: Position, padding = 20) => {
  const arcRadius = radius - padding;

  const { xDir, yDir, curveOrientation } = getCurveConfig(pos);

  const curvePath = getArcPath({
    radius: arcRadius,
    xDir,
    yDir,
    curveOrientation,
  });

  const [x, y] = getCurveStartPoint(radius, pos, padding);

  return `M${x} ${y} ${curvePath}`;
};

export const getBuildingAnimationTimeout = (index: number): number =>
  ANIMATION_START_DELAY + ANIMATION_CATEGORY_DELAY * index;
