export enum EventBlockId {
  Intro = 'intro',
  Organisers = 'eventorganisers',
  KeynoteSpeaker = 'keynotespeaker',
  Topics = 'eventtopics',
  Objectives = 'eventobjectives',
  UltimateGoal = 'ultimategoal',
  Agenda = 'agenda',
  Location = 'location',
  Sponsors = 'sponsors',
  Comments = 'comments',
  JoinCTA = 'joinevent',
  RequestCTA = 'requesthelp',
}
