import { useGetDownloadUrlForLibraryFile } from 'gql/library/query/getDownloadUrlForLibraryFile';
import { LibraryMediaOutput, LibrarySharedMediaOutput } from 'types';

import { MenuElements } from './MenuElements';

interface Props {
  item: LibraryMediaOutput | LibrarySharedMediaOutput;
}

export const MenuProvider = ({ item }: Props) => {
  const { downloadUrl } = useGetDownloadUrlForLibraryFile(item);

  return MenuElements({ item, downloadUrl });
};
