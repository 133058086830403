import { useParams } from 'react-router-dom';

import { WelcomeLayout } from 'components/Layout/WelcomeLayout';
import { OtpForm } from 'features/Onboarding/components/OtpForm';
import { SecondFactorVerificationTypeEnum } from 'types';

import { Cta } from '../../components/Cta';

export const OtpWelcomePage = () => {
  const { token } = useParams();

  return (
    <WelcomeLayout title="The Values Configurator">
      <OtpForm
        token={token}
        tokenType={SecondFactorVerificationTypeEnum.OnboardSignIn}
        successBlock={
          <Cta title="Login successful" subtitle="Thank you and welcome" />
        }
      />
    </WelcomeLayout>
  );
};
