import { SvgElement } from '../types';
import { Dimensions } from '../types/Dimensions';

export const setDimensions = (
  el: SvgElement,
  { height, width }: Dimensions
): SvgElement => {
  el.attr('width', width)
    .attr('height', height)
    .attr('viewBox', [0, 0, width, height])
    .attr('style', 'max-width: 100%; height: auto; height: intrinsic;');

  return el;
};
