import { useState } from 'react';

import { LocationStarFilled32 } from '@carbon/icons-react';
import { Flex, Image, Box, IconButton, Text } from '@chakra-ui/react';

import { ViewButton } from 'components/Button';
import { Panel } from 'components/Layout/Panel';
import { useInitiatives } from 'hooks/initiatives';
import { Links } from 'router/links';
import { InitiativeOutput } from 'types';

import { InitiativeMapModal } from './InitiativeMapModal';

const hardCodedCoords = [
  [-35, 12],
  [3, 0],
  [15, 6],
];

const generateCoord = () => Math.floor(Math.random() * 90 - 45);

export const InitiativeMap = (): JSX.Element => {
  const { initiatives } = useInitiatives();
  const [selectedInitiative, setSelectedInitiative] =
    useState<InitiativeOutput | null>(null);

  const handleClose = () => setSelectedInitiative(null);

  return (
    <Panel
      title="Initiatives"
      action={
        <ViewButton to={Links.initiatives} variant="blue">
          View all initiatives
        </ViewButton>
      }
    >
      <Flex
        position="relative"
        bgColor="ui.white"
        w="100%"
        h="full"
        align="center"
        onClick={handleClose}
        my="auto"
      >
        <Flex pos="relative" display="inline-flex" direction="column" w="100%">
          <Flex pos="relative">
            {initiatives.slice(0, 3).map((initiative, index) => {
              const [x = generateCoord(), y = generateCoord()] =
                hardCodedCoords[index] ?? [];

              return (
                <Box
                  key={initiative.id}
                  pos="absolute"
                  top={`${-y + 50}%`}
                  left={`${x + 50}%`}
                >
                  <IconButton
                    aria-label="open initiative"
                    variant="ghost"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setSelectedInitiative(initiative);
                    }}
                    _hover={{
                      svg: {
                        fill: 'primary.lime.highlight',
                      },
                    }}
                  >
                    <LocationStarFilled32 />
                  </IconButton>
                  <Text size="p3">{initiative.hero.headline}</Text>
                </Box>
              );
            })}
            <Image src="/assets/map.jpg" w="full" />
          </Flex>
        </Flex>

        {selectedInitiative && (
          <InitiativeMapModal
            initiative={selectedInitiative}
            handleClose={handleClose}
          />
        )}
      </Flex>
    </Panel>
  );
};
