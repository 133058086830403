import { useTranslation } from 'react-i18next';

import { Box, Image, Text, useDisclosure } from '@chakra-ui/react';

import { ViewButton } from 'components/Button';
import { OnboardingIntroModal } from 'features/Charter/components/OnboardingIntoModal';

export const ValueSubtitle = () => {
  const { t } = useTranslation(['family']);

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box>
      <OnboardingIntroModal isOpen={isOpen} onClose={onClose} />
      <Image src="/assets/onboardingValues.png" fontSize={14} />
      <Text my={4}>{t('family:charter.section.values.description')}</Text>
      <ViewButton onClick={onOpen} variant="blue">
        {t('family:charter.section.values.cta')}
      </ViewButton>
    </Box>
  );
};
