import { NotificationCategoryEnum, WithChildren } from 'types';

import { NotificationsContextProvider } from './NotificationsContext';
import { NotificationsSubscriber } from './NotificationsSubscriber';

export const NotificationsProviders = ({ children }: WithChildren) => (
  <NotificationsContextProvider category={NotificationCategoryEnum.Connection}>
    <NotificationsContextProvider category={NotificationCategoryEnum.Message}>
      <NotificationsContextProvider category={NotificationCategoryEnum.Other}>
        <NotificationsSubscriber>{children}</NotificationsSubscriber>
      </NotificationsContextProvider>
    </NotificationsContextProvider>
  </NotificationsContextProvider>
);
