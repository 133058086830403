import { useMemo } from 'react';

import { MultiselectInput } from 'components/Form/MultiselectInput';
import { Role, Option } from 'types';

import { selectInputStyles } from './styles';

type OptionWithRole = Option & {
  role: Role;
};

type Props = {
  membersOptions: OptionWithRole[];
};

export const GroupAdvisorsInput = ({ membersOptions }: Props) => {
  const advisorsOptions = useMemo(
    () =>
      membersOptions.filter((profile) => profile.role === Role.ExpertAdvisor),
    [membersOptions]
  );

  return (
    <MultiselectInput
      name="advisorIds"
      label="Group Advisors"
      options={advisorsOptions}
      inputStyles={selectInputStyles}
    />
  );
};
