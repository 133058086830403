import { Box } from '@chakra-ui/react';

import { HeroBody } from 'components/HeroBody';
import { HeroMedia } from 'components/HeroMedia';
import { SimpleValuesSection } from 'components/SimpleValuesSection';
import { ConnectCta } from 'features/Profile/components/ConnectCta';
import { useProfile } from 'hooks/profiles/useProfile';
import { AdvisorOutput } from 'types';
import { getFullName, getFullNameWithActiveStatus } from 'utils/string';

import {
  Mission,
  Location,
  About,
  AwardsAndRecognition,
  Advocacy,
  SdgInfluences,
} from '../AdvisorSections';

type Props = { advisor: AdvisorOutput };

export const AdvisorPreview = ({ advisor }: Props) => {
  const {
    hero: {
      mission,
      advisorAccountId,
      headline,
      heroImage: { signedUrl },
    },
    about,
    location,
    awards,
    advocacy,
    sdGoals,
    values,
  } = advisor;

  const { profile } = useProfile(advisorAccountId);

  return (
    <Box>
      <HeroMedia
        imageUrl={signedUrl}
        childrenContainerStyles={{ justifyContent: 'flex-end', mb: 20 }}
        heading={getFullNameWithActiveStatus(profile)}
      >
        <HeroBody title={headline}>
          {profile && (
            <ConnectCta
              key="connect"
              userId={profile.id}
              userName={getFullName(profile?.firstName, profile?.lastName)}
              connectionState={profile.connectionState}
            />
          )}
        </HeroBody>
      </HeroMedia>
      <Mission mission={mission} />
      {location && <Location location={location} />}
      {about && <About about={about} />}
      {awards && <AwardsAndRecognition awards={awards} />}
      {advocacy && <Advocacy advocacy={advocacy} />}
      {values && <SimpleValuesSection values={values} />}
      <SdgInfluences sdgs={sdGoals} />
    </Box>
  );
};
