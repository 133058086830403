import { useQuery } from '@apollo/client';

import { Query } from 'types';

import { getLibraryMediaFavorites } from '../documents';

export const useGetLibraryMediaFavorites = ({ skip }: { skip?: boolean }) => {
  const { data, error, loading, refetch } = useQuery<
    Pick<Query, 'getLibraryMediaFavorites'>
  >(getLibraryMediaFavorites, { skip });

  return { resources: data?.getLibraryMediaFavorites, error, loading, refetch };
};
