import { Box, Divider, Flex, Text } from '@chakra-ui/react';

type SingleIndicatorProps = { label: string };

const SingleIndicator = ({ label }: SingleIndicatorProps) => {
  return (
    <Flex w="100%" direction="column" align="center">
      <Text size="p2b">{label}</Text>
    </Flex>
  );
};

const indicators: SingleIndicatorProps[] = [
  { label: 'Short term' },
  { label: 'Intermediate' },
  { label: 'Long term' },
];

export const SdgRelevance = () => {
  return (
    <Box mt={10} display={{ base: 'none', lg: 'inline-grid' }}>
      <Divider color="primary.blue.default" />
      <Flex>
        {indicators.map((indicatorProps) => (
          <SingleIndicator {...indicatorProps} key={indicatorProps.label} />
        ))}
      </Flex>
    </Box>
  );
};
