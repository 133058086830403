import { useField } from 'formik';

import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { CloudUpload, TrashCan } from '@carbon/icons-react/next';
import {
  FormControl,
  Flex,
  Heading,
  Button,
  Box,
  Text,
  VStack,
  IconButton,
  useToast,
} from '@chakra-ui/react';

import { useCurrentLibraryLocation } from 'features/Library/hooks/useCurrentLibraryLocation';
import { mapLibraryLocationToLibraryType } from 'features/Library/utils/mapLibraryLocationToLibraryType';
import { useGetLibraryDetails } from 'gql/library/query/getLibraryDetails';
import { humanFileSize } from 'utils/size';
import { concat } from 'utils/string';

import { INFO_SEPARATOR } from '../../constants';
import { FileIcon } from '../ItemRow/FileIcon';
import { FIELD_NAME } from './constants';

export const UploadBox = () => {
  const [field, { error, touched }, { setValue }] = useField(FIELD_NAME);
  const { t } = useTranslation(['library', 'common']);
  const toast = useToast();
  const { libraryLocation } = useCurrentLibraryLocation();
  const { libraryDetails } = useGetLibraryDetails({
    libraryType: mapLibraryLocationToLibraryType(libraryLocation),
  });

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const addedFiles = acceptedFiles.filter(
        (file) =>
          !field.value.find(
            (existingFile: File) =>
              existingFile.name === file.name && existingFile.size === file.size
          )
      );
      const newValues = [...new Set([...field.value, ...addedFiles])];
      setValue(newValues);
    },
    [field.value, setValue]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    validator: (file) => {
      if (file.size > (libraryDetails?.maximumSizePerFile || Infinity)) {
        toast({
          status: 'error',
          title: t('library:toasts.upload-fail-title'),
          description: t('library:toasts.file-too-large', {
            maxFileSize: humanFileSize(
              libraryDetails?.maximumSizePerFile || Infinity
            ),
          }),
        });

        return {
          code: 'size-too-large',
          message: `File is larger than ${humanFileSize(
            libraryDetails?.maximumSizePerFile || Infinity
          )}`,
        };
      }

      return null;
    },
  });

  const isInvalid = touched && !!error;

  const handleRemove = (file: File) => {
    const newValue = field.value.filter(
      (existingFile: File) => existingFile !== file
    );
    setValue(newValue);
  };

  const hasFiles = !!field.value.length;

  return (
    <FormControl isInvalid={isInvalid} as={Flex} flexDirection="column" gap={6}>
      <Box>
        <Text size="p1">{t('library:upload.description')}</Text>
        <Text size="p1">
          {t('library:upload.max-file-size', {
            maxFileSize: humanFileSize(
              libraryDetails?.maximumSizePerFile || Infinity
            ),
          })}
        </Text>
      </Box>
      <VStack
        {...getRootProps()}
        flex={1}
        cursor="pointer"
        padding={4}
        borderWidth="2px"
        borderRadius="2px"
        borderStyle="dashed"
        backgroundColor={isDragActive ? 'ui.grey4' : 'ui.white'}
        outline="none"
        transition="border 0.24s ease-in-out"
        borderColor={isInvalid ? 'negative' : 'ui.grey2'}
      >
        {hasFiles ? (
          <Box height={282} w="100%">
            <input {...getInputProps()} />
            <Flex overflowY="auto" direction="column" gap={2} h={235}>
              {field.value.map((file: File) => (
                <Flex
                  key={file.name + file.size}
                  gap={3}
                  alignItems="center"
                  justifyContent="flex-start"
                  width="100%"
                  px={2}
                  py={1}
                  border="1px solid"
                  borderColor="ui.grey4"
                >
                  <FileIcon mimeType={file.type} name={file.name} />
                  <Text size="p2">{concat(file.name, 24)}</Text>
                  <Text size="p2">{INFO_SEPARATOR}</Text>
                  <Text size="p2">{humanFileSize(file.size)}</Text>
                  <Flex flex={1} justifyContent="flex-end">
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemove(file);
                      }}
                      icon={<TrashCan />}
                      aria-label="Remove file"
                      variant="ghost"
                    />
                  </Flex>
                </Flex>
              ))}
            </Flex>
            <Flex justifyContent="center" p={4}>
              <Button variant="primaryBlue">
                {t('library:upload.browse-files')}
              </Button>
            </Flex>
          </Box>
        ) : (
          <>
            <input {...getInputProps()} />
            <Flex
              direction="column"
              alignItems="center"
              justifyContent="center"
              gap={4}
              paddingY={6}
            >
              <CloudUpload width={56} height={56} />
              <Heading size="h5">{t('library:upload.drag-and-drop')}</Heading>
              <Text size="p1">{t('common:or')}</Text>
              <Button variant="primaryBlue">
                {t('library:upload.browse-files')}
              </Button>
            </Flex>
          </>
        )}
      </VStack>
    </FormControl>
  );
};
