import { useTranslation } from 'react-i18next';

import { SelectInput } from 'components/Form/SelectInput';
import { useFamilyOptions } from 'gql/family/query/familyOptions';

type Props = {
  familyIdFieldName: string;
  disabled?: boolean;
};

export const FamilyNameSelectSection = ({
  familyIdFieldName,
  disabled,
}: Props) => {
  const { familyOptions, loading, error } = useFamilyOptions();
  const { t } = useTranslation(['cms']);

  return (
    <SelectInput
      name={familyIdFieldName}
      label={t('cms:family.name')}
      options={familyOptions}
      loading={loading}
      error={error}
      containerStyles={{ w: 208 }}
      disabled={disabled}
    />
  );
};
