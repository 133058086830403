import { Flex, Heading } from '@chakra-ui/react';

import { DropContainer } from 'components/Draggable';
import { SDGDragItem } from 'types';

import { ItemType } from '../types';
import { SdgDragElement } from './SdgDragElement';

type Props = {
  item: SDGDragItem | undefined;
  onSelect: (item: SDGDragItem) => void;
  idx?: string;
};

export const SelectCell = ({ item, onSelect, idx = '' }: Props) => {
  return (
    <DropContainer accept={ItemType} onDrop={onSelect}>
      <Flex
        w={24}
        h={24}
        justify="center"
        align="center"
        id={item ? `draggable-${item.sdg.internalName}` : `slot-${idx}`}
      >
        {item ? (
          <SdgDragElement item={item} />
        ) : (
          <Heading size="h3">{idx}</Heading>
        )}
      </Flex>
    </DropContainer>
  );
};
