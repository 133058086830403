import { useTranslation } from 'react-i18next';

import { Box, Heading } from '@chakra-ui/react';

import { Breadcrumbs } from 'components/Breadcrumbs';
import { Links } from 'router/links';

import { EventListing } from '../components/EventListing';
import { EventsLandingPageHero } from '../components/EventsLandingPageHero';
import { PastEventList } from '../components/PastEventList';

export const EventsLandingPage = () => {
  const { t } = useTranslation(['navigation', 'events']);

  return (
    <>
      <EventsLandingPageHero />
      <Breadcrumbs
        items={[
          { link: Links.home, label: t('navigation:home') },
          { link: Links.events, label: t('events:event-listings') },
        ]}
      />
      <Box bgColor="ui.white" minH="70vh" px={4}>
        <Heading size="h3" p={{ base: 3, lg: 6 }} pb={{ lg: 12 }}>
          {t('events:event-listings')}
        </Heading>
        <EventListing />
      </Box>
      <PastEventList />
    </>
  );
};
