import { Form, FormikProvider } from 'formik';

import { useTranslation } from 'react-i18next';

import { Box, Button, Text } from '@chakra-ui/react';

import { TextInput } from 'components/Form/TextInput';
import { RightBlock } from 'components/Layout/RightBlock';
import { useAuthContext } from 'contexts/AuthContext';
import {
  useAccountByToken,
  useSetNewPasswordWithToken,
} from 'hooks/authorization';

import { ResetPasswordFailedModal } from '../components/ResetPasswordFailedModal';

const MESSAGE =
  'Sorry, your time to set up a new password has expired. Please close this message and try again.';

export const NewPasswordPage = () => {
  const { secondFactorJWT } = useAuthContext();
  const { account } = useAccountByToken(secondFactorJWT || '');
  const { t } = useTranslation(['common']);

  const { formik, loading, isOpen, onClose } = useSetNewPasswordWithToken();

  return (
    <>
      <ResetPasswordFailedModal
        isOpen={isOpen}
        onClose={onClose}
        message={MESSAGE}
      />
      <RightBlock
        title={`Welcome ${account?.firstName}`}
        subtitle="Please create a new password for your Itriom account."
      >
        <FormikProvider value={formik}>
          <Form autoComplete="nope">
            <Text size="p1" mb={4}>
              Password minimum length is 8 characters
            </Text>
            <Text size="p1" mb={4}>
              New password must not be the same as previous and requires:
            </Text>
            <Box as="ul" pl={6} mb={14}>
              <li>Uppercase and lowercase letter (A, z)</li>
              <li>Numeric character (0-9)</li>
              <li>Special character (!, %, @, #, etc.)</li>
            </Box>
            <TextInput
              label="New password"
              name="password"
              type="password"
              preventCopy
              preventPaste
            />
            <Box mb={4} />
            <TextInput
              label="Confirm password"
              name="confirmPassword"
              type="password"
              preventCopy
              preventPaste
            />
            <Button
              bgColor="primary.lime.default"
              variant="solid"
              borderRadius={2}
              h={12}
              px={6}
              mt={14}
              type="submit"
              isLoading={loading}
            >
              <Text size="p1">{t('common:submit')}</Text>
            </Button>
          </Form>
        </FormikProvider>
      </RightBlock>
    </>
  );
};
