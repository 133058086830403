import { createContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Edit, TrashCan } from '@carbon/icons-react/next';
import { useDisclosure } from '@chakra-ui/react';

import { MenuElement } from 'components/ThreeDotsMenu';
import { useAuthContext } from 'contexts/AuthContext';
import { Role, WithRenderProp } from 'types';

type ManagePostContextType = {
  menuElements: MenuElement[];
  isEditing: boolean;
  handleCancelEditing: VoidFunction;
  isDeleteConfirmationModalOpen: boolean;
  handleCancelDeleting: VoidFunction;
};

const ManagePostContext = createContext<ManagePostContextType | null>(null);

type Props = {
  entityName: string;
  createdBy: string;
};

export const ManagePostContextProvider = ({
  entityName,
  createdBy,
  children,
}: WithRenderProp<Props, ManagePostContextType>) => {
  const { t } = useTranslation(['common', 'cms']);

  const { account } = useAuthContext();

  const [isEditing, setIsEditing] = useState(false);

  const confirmationModal = useDisclosure();

  const menuElements = useMemo<MenuElement[]>(
    () => [
      {
        text: t('common:edit-entity', { entity: entityName }),
        icon: Edit,
        handleClick: () => setIsEditing(true),
      },
      {
        text: t('cms:delete.action', { entity: entityName }),
        icon: TrashCan,
        allowedRoles: [Role.Admin],
        handleClick: confirmationModal.onOpen,
      },
    ],
    [confirmationModal.onOpen, entityName, t]
  );

  const handleCancelEditing = () => {
    setIsEditing(false);
  };

  const isAuthor = useMemo(() => {
    return account?.id === createdBy;
  }, [account?.id, createdBy]);

  const filteredMenuElements = useMemo(() => {
    return menuElements.filter((menuElement) => {
      if (isAuthor) {
        return true;
      }

      if (!account?.role) {
        return false;
      }

      return (menuElement.allowedRoles || []).includes(account.role);
    });
  }, [isAuthor, account?.role, menuElements]);

  const value = useMemo<ManagePostContextType>(
    () => ({
      isEditing,
      menuElements: filteredMenuElements,
      handleCancelEditing,
      handleCancelDeleting: confirmationModal.onClose,
      isDeleteConfirmationModalOpen: confirmationModal.isOpen,
    }),
    [
      confirmationModal.isOpen,
      confirmationModal.onClose,
      isEditing,
      filteredMenuElements,
    ]
  );

  return (
    <ManagePostContext.Provider value={value}>
      {children(value)}
    </ManagePostContext.Provider>
  );
};
