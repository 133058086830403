import { gql, useMutation } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { createMock } from 'mocks';
import { familyCharterMock } from 'mocks/familyCharter.mock';
import {
  Mutation,
  MutationUpdateFamilyCharterHeroArgs,
  FamilyCharterInputs,
} from 'types';
import { getImageId } from 'utils/file';

import { familiesChartersGql } from './useFamiliesCharters';
import { familyCharterGql } from './useFamilyCharter';

export const updateFamilyCharterHeroGql = gql`
  mutation UpdateFamilyCharterHero($data: UpdateFamilyCharterHeroInput!) {
    updateFamilyCharterHero(data: $data) {
      id
    }
  }
`;

export const updateFamilyCharterHeroGqlMock = createMock<
  Pick<Mutation, 'updateFamilyCharterHero'>,
  MutationUpdateFamilyCharterHeroArgs
>(
  updateFamilyCharterHeroGql,
  { data: { updateFamilyCharterHero: familyCharterMock } },
  { data: { id: familyCharterMock.id, hero: familyCharterMock.hero } }
);

export const useUpdateFamilyCharterHero = () => {
  const [updateFamilyCharterHeroMutation] = useMutation<
    Pick<Mutation, 'updateFamilyCharterHero'>,
    MutationUpdateFamilyCharterHeroArgs
  >(updateFamilyCharterHeroGql, {
    refetchQueries: [familyCharterGql, familiesChartersGql],
  });

  const updateFamilyCharterHero = (values: FamilyCharterInputs) => {
    const { heroImage, heroVideo, id = '', ...rest } = values;

    return updateFamilyCharterHeroMutation({
      variables: {
        data: {
          hero: {
            ...rest,
            heroImageId: getImageId(heroImage),
            heroVideoId: getImageId(heroVideo),
          },
          id,
        },
      },
    });
  };

  const updateFamilyCharterHeroWithToast = useWithMutationToast(
    updateFamilyCharterHero,
    {
      successMessage: 'Family Charter updated successfully',
      errorMessage: 'Error updating Family Charter',
    }
  );

  return {
    handleSubmit: updateFamilyCharterHeroWithToast,
  };
};
