import { Link } from 'react-router-dom';

import { Flex } from '@chakra-ui/layout';
import { Box } from '@chakra-ui/react';

import { TextContent } from '../TextContent';
import { CommonTileProps } from '../types';

export const TextTile = ({
  tags,
  title,
  description,
  link,
}: Omit<CommonTileProps, 'image'>) => {
  return (
    <Flex
      as={Link}
      to={link}
      w="100%"
      h="100%"
      position="relative"
      alignItems="center"
      bg="ui.white"
    >
      <Box p={5} pt={8} w="100%" h="100%">
        <TextContent
          variant="dark"
          description={description}
          title={title}
          tags={tags}
        />
      </Box>
    </Flex>
  );
};
