import { FamilyValuesCategoryEnum } from 'types';

import { CreateIconOptions } from './types';

const VALUES_CATEGORY: Record<FamilyValuesCategoryEnum, CreateIconOptions> = {
  [FamilyValuesCategoryEnum.Advancement]: {
    d: 'M6.875 6.875h6.875V96.25h89.375v6.875H13.75a6.872 6.872 0 0 1-6.875-6.875V6.875Zm72.188 24.063h24.062V55H96.25V42.66L67.753 71.19a3.438 3.438 0 0 1-4.881 0L48.125 56.41 25.472 79.062l-4.847-4.847 25.06-25.094a3.438 3.438 0 0 1 4.88 0l14.748 14.781 26.09-26.09h-12.34v-6.876Z',
    displayName: 'advancement-icon',
  },
  [FamilyValuesCategoryEnum.Contribution]: {
    d: 'M103.122 55v3.437H41.336a69.797 69.797 0 0 0 11.726 37.718c.225.01.448.025.672.04.419.027.837.054 1.263.054a41.393 41.393 0 0 0 9.522-1.104l1.581 6.69a48.085 48.085 0 0 1-51.615-20.967 48.08 48.08 0 1 1 88.637-25.869Zm-27.585-3.438h20.568a41.328 41.328 0 0 0-30.103-36.295 83.478 83.478 0 0 1 9.535 36.295Zm-6.879 0a69.797 69.797 0 0 0-11.725-37.719c-.225-.01-.449-.025-.672-.04-.42-.027-.838-.054-1.264-.054-.426 0-.844.027-1.263.055-.224.014-.447.029-.672.04a69.797 69.797 0 0 0-11.726 37.718h27.322Zm-34.2 0a83.478 83.478 0 0 1 9.534-36.295A41.329 41.329 0 0 0 13.89 51.562h20.568ZM23.257 81.299a41.329 41.329 0 0 0 20.735 13.433 83.478 83.478 0 0 1-9.534-36.295H13.89a41.328 41.328 0 0 0 9.367 22.862Zm79.865-12.55H68.747v6.876h34.375V68.75ZM89.372 82.5H68.747v6.876h20.625V82.5Z',
    displayName: 'contribution-icon',
  },
  [FamilyValuesCategoryEnum.Organisational]: {
    d: 'M86.813 70.111V55.875A6.875 6.875 0 0 0 79.936 49h-27.5V35.25h6.876a6.883 6.883 0 0 0 6.874-6.875V7.75A6.883 6.883 0 0 0 59.313.875H38.686a6.882 6.882 0 0 0-6.874 6.875v20.625a6.882 6.882 0 0 0 6.875 6.875h6.874V49h-27.5a6.875 6.875 0 0 0-6.875 6.875v14.239a13.75 13.75 0 1 0 6.876 0v-14.24h27.5v14.238a13.75 13.75 0 1 0 6.874 0V55.875h27.5v14.236a13.75 13.75 0 1 0 6.876 0ZM38.687 7.75h20.626l.003 20.625H38.687V7.75ZM21.5 83.375a6.875 6.875 0 1 1-6.875-6.875 6.883 6.883 0 0 1 6.875 6.875Zm34.375 0A6.875 6.875 0 1 1 49 76.5a6.885 6.885 0 0 1 6.875 6.875Zm27.5 6.875a6.875 6.875 0 1 1 6.875-6.875 6.883 6.883 0 0 1-6.875 6.875Z',
    displayName: 'organisational-icon',
  },
  [FamilyValuesCategoryEnum.Societal]: {
    d: 'M88.23 15.488a10.312 10.312 0 1 0-11.46 17.148 10.312 10.312 0 0 0 11.46-17.148ZM72.95 9.772a17.188 17.188 0 1 1 19.1 28.582 17.188 17.188 0 0 1-19.1-28.582Zm9.55 38.353h6.875a17.21 17.21 0 0 1 17.187 17.188v13.75h-6.874v-13.75A10.324 10.324 0 0 0 89.374 55H82.5v-6.875Zm-3.438 55h-6.874V96.25a10.324 10.324 0 0 0-10.313-10.313h-13.75A10.324 10.324 0 0 0 37.812 96.25v6.875h-6.874V96.25a17.208 17.208 0 0 1 17.187-17.188h13.75A17.208 17.208 0 0 1 79.063 96.25v6.875Zm-18.333-56.7a10.312 10.312 0 1 0-11.458 17.149 10.312 10.312 0 0 0 11.458-17.148ZM45.451 40.71A17.187 17.187 0 1 1 64.55 69.291a17.187 17.187 0 0 1-19.1-28.582ZM27.5 48.125h-6.875A17.208 17.208 0 0 0 3.437 65.313v13.75h6.876v-13.75A10.324 10.324 0 0 1 20.624 55H27.5v-6.875Zm5.73-32.637a10.312 10.312 0 1 0-11.46 17.148 10.312 10.312 0 0 0 11.46-17.148ZM17.95 9.772a17.188 17.188 0 1 1 19.1 28.582 17.188 17.188 0 0 1-19.1-28.582Z',
    displayName: 'societal-icon',
  },
};

export const ICONS = { ...VALUES_CATEGORY };
