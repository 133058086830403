import { useQuery } from '@apollo/client';

import { useHasConnections } from 'hooks/connections/useHasConnections';
import { Query } from 'types';

import { connectionInvitationsGql } from '../documents';

export const useConnectionInvitations = () => {
  const { hasConnections } = useHasConnections();

  const { data, loading, error } = useQuery<
    Pick<Query, 'connectionInvitations'>
  >(connectionInvitationsGql, { skip: !hasConnections });

  return {
    connectionInvitations: data?.connectionInvitations ?? [],
    loading,
    error,
  };
};
