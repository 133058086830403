import { gql, useMutation } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationUpdatePostArgs } from 'types';

import { postsGql } from './usePosts';

const updatePostGql = gql`
  mutation UpdatePost($post: PostUpdateInput!, $images: [Upload!]) {
    updatePost(post: $post, images: $images) {
      id
    }
  }
`;

type TData = Pick<Mutation, 'updatePost'>;
type TVariables = MutationUpdatePostArgs;

export const useUpdatePost = () => {
  const [updatePostMutation] = useMutation<TData, TVariables>(updatePostGql);

  const handleMutation = (variables: MutationUpdatePostArgs) =>
    updatePostMutation({ variables, refetchQueries: [postsGql] });

  const updatePost = useWithMutationToast(handleMutation, {
    successMessage: 'Post edited successfully',
    errorMessage: 'Error editing post',
  });

  return { updatePost };
};
