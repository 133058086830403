import { useCallback } from 'react';

import { useLazyQuery } from '@apollo/client';

import { Query, QuerySignedUploadUrlArgs } from 'types';

import { signedUploadUrlGql } from '../documents';

export const useSignedUploadUrl = () => {
  const [getSignedUploadUrl, { loading, error, data }] = useLazyQuery<
    Pick<Query, 'signedUploadUrl'>,
    QuerySignedUploadUrlArgs
  >(signedUploadUrlGql, { fetchPolicy: 'cache-and-network' });

  const handleQuery = useCallback(
    async (fileType: string) => {
      return getSignedUploadUrl({ variables: { fileType } });
    },
    [getSignedUploadUrl]
  );

  return {
    getSignedUploadUrl: handleQuery,
    signedUploadUrl: data?.signedUploadUrl,
    error,
    loading,
  };
};
