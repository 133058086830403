import { Box, Heading, HStack } from '@chakra-ui/react';

export const ScoresPageHeader = () => (
  <HStack height={500} bgColor="#BDCFD8" textAlign="center">
    <Box textAlign="center" verticalAlign="middle" w="full">
      <Heading size="h3">Results of points allocation</Heading>
      <Heading size="h2" textAlign="center" w="40%" mx="auto">
        Itriom has grouped values relevant to families, into four categories
        that capture both the areas of purpose for the family and enablers for
        achieving the purpose.
      </Heading>
    </Box>
  </HStack>
);
