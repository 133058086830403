import { string, object } from 'yup';

import { ErrorMessages } from 'utils/validation';

import { PostFormBaseProps } from './types';

const textSchema = string().required('Required');
const titleSchema = string();

export const getValidationSchema = ({
  textCharLimit,
  titleCharLimit,
}: Pick<PostFormBaseProps, 'textCharLimit' | 'titleCharLimit'>) =>
  object().shape({
    text: textCharLimit
      ? textSchema.max(
          textCharLimit,
          ErrorMessages.TOO_LONG('text', textCharLimit)
        )
      : textSchema,
    title: titleCharLimit
      ? titleSchema.max(
          titleCharLimit,
          ErrorMessages.TOO_LONG('title', titleCharLimit)
        )
      : titleSchema,
  });
