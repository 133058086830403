import { Link } from 'react-router-dom';

import { Text, Flex, Heading } from '@chakra-ui/react';

import { underlay } from 'theme/mixins/underlay';

type Props = {
  image?: string;
  profileUrl: string;
  name: string;
  headline: string;
};

export const AdvisorCardTemplate = ({
  image,
  profileUrl,
  name,
  headline,
}: Props) => (
  <Flex
    w="full"
    h={180}
    position="relative"
    bgSize="cover"
    bgPosition="center"
    bgImage={image}
    as={Link}
    to={profileUrl}
    {...underlay()}
  >
    <Flex
      p={4}
      position="absolute"
      bottom={0}
      left={0}
      w="full"
      h="full"
      direction="column"
      color="ui.white"
      justify="flex-end"
      gap={1}
    >
      <Heading size="h4">{name}</Heading>

      <Text size="p3" mb={2} mr="40%">
        {headline}
      </Text>
    </Flex>
  </Flex>
);
