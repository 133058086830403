import { useField } from 'formik';

import { WarningFilled } from '@carbon/icons-react/next';
import {
  CSSObject,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
} from '@chakra-ui/react';

import { Option } from 'types';

import { TagInputCore } from './TagInputCore';

type Props = {
  name: string;
  label: string;
  labelHint?: string;
  options: Option[];
  labelStyles?: CSSObject;
  hasErrorIcon?: boolean;
  inputStyles?: CSSObject;
};

export const MultiselectInput = ({
  name,
  label,
  labelHint,
  options,
  labelStyles = {},
  hasErrorIcon,
  inputStyles = {},
}: Props): JSX.Element => {
  const [field, { value, error, touched }, { setValue, setError }] = useField<
    string[]
  >({
    name,
    type: 'tags',
  });

  const handleTagsChange = (tags: string[]): void => {
    setValue(tags);
  };

  const isInvalid = touched && !!error;

  return (
    <FormControl isInvalid={isInvalid}>
      <FormLabel htmlFor={name}>
        <Flex mb={2}>
          {label && (
            <Text as="label" size="p3" sx={labelStyles}>
              {label}
            </Text>
          )}{' '}
          {labelHint && <Text ml={1}>{labelHint}</Text>}
        </Flex>
      </FormLabel>
      <TagInputCore
        options={options}
        id={name}
        tags={value || []}
        onTagsChange={handleTagsChange}
        field={field}
        setError={setError}
        inputStyles={inputStyles}
      />
      <FormErrorMessage>
        {error}
        <Flex ml={2}>{hasErrorIcon && <WarningFilled />}</Flex>
      </FormErrorMessage>
    </FormControl>
  );
};
