import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChevronDown, ChevronUp } from '@carbon/icons-react/next';
import { Button, Divider, Flex } from '@chakra-ui/react';

import { Panel } from 'components/Layout/Panel';
import { ManagePostContextProvider } from 'contexts/ManagePostContext';
import { useDeletePost } from 'hooks/posts/useDeletePost';
import { Post } from 'types';

import { Comment } from '../Comment';
import { NewCommentForm } from '../CommentForm';
import { DeleteEntityConfirmationModal } from '../DeleteEntityConfirmationModal';
import { ManagePostMenuButton } from '../ManagePostMenuButton';
import { EditPostForm } from '../PostForm/EditPostForm';
import { PostCardFooter } from './PostCardFooter';
import { PostCardHeader } from './PostCardHeader';
import { PostPreview } from './PostPreview';

export const PostCard = ({ post }: { post: Post }) => {
  const [shouldShowAllComments, setShouldShowAllComments] = useState(false);

  const toggleShowAllComments = () => {
    setShouldShowAllComments((prev) => !prev);
  };

  const { t } = useTranslation(['common', 'cms', 'network']);

  const { postedBy, createdAt, createdBy, comments, images, text, title } =
    post;

  const entityName = t('common:post').toLowerCase();

  const { handleDeletePost } = useDeletePost();

  const handleDelete = () => handleDeletePost(post.id);

  return (
    <Panel
      contentWrapperStyling={{ direction: 'column', position: 'relative' }}
      ariaLabel="post"
    >
      <PostCardHeader createdAt={createdAt} postedBy={postedBy} />
      <>
        <ManagePostContextProvider
          createdBy={createdBy}
          entityName={entityName}
        >
          {({
            menuElements,
            isEditing,
            handleCancelEditing,
            isDeleteConfirmationModalOpen,
            handleCancelDeleting,
          }) => (
            <>
              <Flex mx={14} pt={2}>
                {!isEditing ? (
                  <PostPreview images={images} text={text} title={title} />
                ) : (
                  <EditPostForm
                    post={post}
                    onUpdate={handleCancelEditing}
                    withTitle
                  />
                )}
              </Flex>

              <Flex position="absolute" right={4}>
                <ManagePostMenuButton
                  isEditing={isEditing}
                  handleCancelEditing={handleCancelEditing}
                  menuElements={menuElements}
                />
              </Flex>

              <DeleteEntityConfirmationModal
                handleDelete={handleDelete}
                isOpen={isDeleteConfirmationModalOpen}
                onClose={handleCancelDeleting}
                entity={entityName}
              >
                {t('cms:delete.action-explanation', {
                  entity: entityName,
                  relatedEntities: t('network:related-comments'),
                })}
              </DeleteEntityConfirmationModal>
            </>
          )}
        </ManagePostContextProvider>

        <Flex mx={14} direction="column">
          <Divider borderColor="primary.blue.default" opacity={1} />

          <PostCardFooter
            appreciationCount={post.appreciationCount}
            isSelfAppreciated={post.isSelfAppreciated}
            commentCount={comments.length}
            postId={post.id}
          />

          <NewCommentForm
            referenceId={post.id}
            placeholder={t('network:respond-to', { entity: t('network:post') })}
          />

          {comments
            .slice(0, shouldShowAllComments ? undefined : 1)
            .map((comment) => (
              <Comment comment={comment} key={comment.id} />
            ))}
        </Flex>
        {comments.length > 1 && (
          <Flex justifyContent="flex-start" mx={14} pt={4}>
            <Button
              px={0}
              onClick={toggleShowAllComments}
              variant="ghost"
              rightIcon={
                shouldShowAllComments ? <ChevronUp /> : <ChevronDown />
              }
            >
              {t('network:show-comments', {
                action: shouldShowAllComments
                  ? t('common:hide')
                  : t('common:view'),
                count: comments.length - 1,
              })}
            </Button>
          </Flex>
        )}
      </>
    </Panel>
  );
};
