import { FormikProvider, useFormik } from 'formik';

import { useState } from 'react';

import { SelectInput } from 'components/Form/SelectInput';
import { AttendanceStatusEnum } from 'types';

import { ChangeAttendModal } from './ChangeAttendModal';
import { ATTENDANCE_VALUE, AttendFormValues } from './constants';

interface Props {
  defaultValue?: AttendanceStatusEnum;
  accountId: string;
}

const options = Object.entries(ATTENDANCE_VALUE).map(([key, value]) => ({
  label: value,
  value: key,
}));

export const AttendanceSelect = ({ defaultValue, accountId }: Props) => {
  const [status, setStatus] = useState<AttendanceStatusEnum | null>(null);
  const formik = useFormik<AttendFormValues>({
    initialValues: {
      attendanceStatus: defaultValue,
    },
    onSubmit: () => Promise.resolve(),
  });

  const onClose = () => setStatus(null);

  return (
    <FormikProvider value={formik}>
      <SelectInput
        name="attendanceStatus"
        options={options}
        defaultOptionText="Select"
        onChange={(statusOption) => {
          setStatus(statusOption);
        }}
        inputStyles={{
          minWidth: 130,
        }}
      />
      <ChangeAttendModal
        accountId={accountId}
        status={status}
        onClose={onClose}
      />
    </FormikProvider>
  );
};
