import { Conversation, Message } from 'types';

export type AddMessageAction = {
  type: MessagesActionTypes.AddMessage;
  payload: Message;
};

export type DeleteMessageAction = {
  type: MessagesActionTypes.DeleteMessage;
  payload: Message;
};

export type SetMessagesAction = {
  type: MessagesActionTypes.SetMessages;
  payload: { messages: Message[]; conversationId: Conversation['id'] };
};

export type SetMessagesTotalCountAction = {
  type: MessagesActionTypes.SetMessagesTotalCount;
  payload: { messagesTotalCount: number; conversationId: Conversation['id'] };
};

export type DeleteAllMessagesAction = {
  type: MessagesActionTypes.DeleteAllMessages;
  payload: { conversationId: Conversation['id'] };
};

export enum MessagesActionTypes {
  AddMessage = 'addMessage',
  DeleteMessage = 'deleteMessage',
  SetMessages = 'setMessages',
  SetMessagesTotalCount = 'setMessagesTotalCount',
  DeleteAllMessages = 'deleteAllMessages',
}

export type MessagesActionsUnion =
  | AddMessageAction
  | DeleteMessageAction
  | SetMessagesAction
  | SetMessagesTotalCountAction
  | DeleteAllMessagesAction;

export type MessagesState = {
  messagesMap: Record<Conversation['id'], Message[]>;
  messagesTotalCountMap: Record<Conversation['id'], number>;
};

export type MessagesActions = {
  addMessage: (message: Message) => void;
  deleteMessage: (message: Message) => void;
  setMessages: (
    conversationId: Conversation['id'],
    messages: Message[]
  ) => void;
  setMessagesTotalCount: (
    conversationId: Conversation['id'],
    messagesTotalCount: number
  ) => void;
  deleteAllMessages: (conversationId: Conversation['id']) => void;
};

export type MessagesSelectors = {
  selectMessagesForConversation: (
    conversationId: Conversation['id']
  ) => Message[];
  selectIsMessageInConversations: (message: Message) => boolean;
  selectHasMoreMessages: (conversationId: Conversation['id']) => boolean;
};
