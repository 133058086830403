import * as d3 from 'd3';

import { addXLabel } from '.';
import { SvgElement, GElement } from '../types';
import { Axis } from '../types/Axis';
import { Dimensions } from '../types/Dimensions';
import { HighlightElement } from '../types/types';

type TOptions = {
  dimensions: Dimensions;
  axis: Axis<d3.InternSet<number>>;
  highlightElement?: HighlightElement;
};

export const addXAxis = (el: SvgElement, options: TOptions): GElement => {
  const { dimensions, axis, highlightElement } = options;

  const { hasTicks, axisStroke, label, tickPadding, fontSize } = axis;

  const { width, height, margin } = dimensions;

  const xAxis = d3.axisBottom(axis.scale).tickPadding(tickPadding);

  if (!hasTicks) {
    xAxis.tickSize(0);
  }

  const xAxisElement = el
    .append('g')
    .attr('transform', `translate(0,${height - margin.bottom})`)
    .call(xAxis)
    .call((g) =>
      g
        .select('.domain')
        .attr('d', `M${margin.left},1H${width - margin.right}`)
        .attr('stroke', axisStroke)
    );

  xAxisElement
    .selectAll('.tick text')
    .attr('font-family', 'Publico Headline')
    .attr('font-weight', '500')
    .attr('font-size', fontSize);

  xAxisElement.selectAll('.tick').each(function setHighlight(tickValue) {
    if (highlightElement && tickValue === highlightElement.index) {
      const tick = d3.select(this);

      tick.select('text').attr('fill', highlightElement.color);

      tick
        .insert('circle', ':first-child')
        .attr('cy', tickPadding + fontSize / 2 - 2)
        .attr('r', fontSize)
        .attr('fill', '#0A1E40');
    }
  });

  if (label) {
    addXLabel(xAxisElement, label, width, margin.bottom);
  }

  return xAxisElement;
};
