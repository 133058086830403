import { Error } from 'components/Error';
import { Loader } from 'components/Loader';
import { useAvailableSdgs } from 'hooks/onboarding/useAvailableSdgs';
import { usePersonalSdgs } from 'hooks/onboarding/usePersonalSdgs';

import { SdgOrderForm } from './SdgOrderForm';

export const SdgOrder = () => {
  const availableSdgsQuery = useAvailableSdgs();
  const personalSdgsQuery = usePersonalSdgs();

  if (availableSdgsQuery.loading || personalSdgsQuery.loading)
    return <Loader />;

  // TODO: when BE fixes error of initial personal sdgs being nullable
  // if (availableSdgsQuery.error || personalSdgsQuery.error) return <Error />;
  if (availableSdgsQuery.error) return <Error />;

  if (!availableSdgsQuery.availableSdgs) {
    return null;
  }

  return (
    <SdgOrderForm
      availableSdgs={availableSdgsQuery.availableSdgs}
      personalSdgs={personalSdgsQuery.personalSdgs}
    />
  );
};
