import { Heading } from '@chakra-ui/react';

import { useAuthContext } from 'contexts/AuthContext';
import { Insight } from 'types';
import { getFullName } from 'utils/string';

import { FeaturedNewsCard } from './Card/FeaturedNewsCard';
import { CarouselBaseV2 } from './CarouselBaseV2';

export const CarouselFeaturedNews = ({ insights }: { insights: Insight[] }) => {
  const { account } = useAuthContext();

  const { firstName, lastName } = account || {};

  return (
    <CarouselBaseV2 items={insights} ItemComponent={FeaturedNewsCard}>
      <Heading
        size="h5"
        position="absolute"
        left={4}
        top={88}
        zIndex={2}
        color="ui.white"
      >
        Welcome back {getFullName(firstName, lastName)}
      </Heading>
    </CarouselBaseV2>
  );
};
