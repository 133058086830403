import { useNavigate } from 'react-router-dom';

import { OverflowMenuHorizontal } from '@carbon/icons-react/next';
import { Button, Menu, MenuButton, MenuList } from '@chakra-ui/react';

import { Links } from 'router/links';
import { ProfileConnectionStateEnum } from 'types';
import { generateUrlById } from 'utils/links';

import { ConnectMenuButton } from './ConnectMenuButton';
import { useConnectMenuButtonProps } from './useConnectMenuButtonProps';

type Props = {
  id: string;
  connectionState: ProfileConnectionStateEnum;
};

export const ProfileActionMenu = ({ id, connectionState }: Props) => {
  const navigate = useNavigate();

  const connectMenuButtonProps = useConnectMenuButtonProps(id, connectionState);

  const handleRedirectToProfile = () =>
    navigate(generateUrlById(Links.profile, id));

  return (
    <Menu>
      <MenuButton
        color="inherit"
        as={Button}
        variant="ghost"
        size="xs"
        aria-label="menu"
      >
        <OverflowMenuHorizontal />
      </MenuButton>

      <MenuList fontSize="sm">
        <ConnectMenuButton text="View" handleClick={handleRedirectToProfile} />

        <ConnectMenuButton {...connectMenuButtonProps} />
      </MenuList>
    </Menu>
  );
};
