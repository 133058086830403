import { LibraryMediaOutput, LibrarySharedMediaOutput } from 'types';

export const downloadFile = (
  filePath: string,
  file: LibraryMediaOutput | LibrarySharedMediaOutput
) => {
  fetch(filePath, {
    method: 'GET',
    headers: {
      'Content-Type': file.mimeType,
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));

      const link = document.createElement('a');
      link.href = url;
      link.download = file.name;

      document.body.appendChild(link);

      link.click();

      link.parentNode?.removeChild(link);
    });
};
