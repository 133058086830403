import { useTranslation } from 'react-i18next';

import { Avatar } from '@chakra-ui/react';

import { useOtherConversationMembers } from 'features/Messaging/hooks/useOtherConversationMembers';
import {
  getGroupChatSubtitle,
  getGroupChatTitle,
  isGroupChat,
} from 'features/Messaging/utils';
import { Conversation } from 'types';
import { getAvatarSrc } from 'utils/file';
import { getFullName, getTitleAndFamily } from 'utils/string';

import { GroupConversationAvatars } from '../GroupConversationAvatars';
import { ConversationHeaderActionMenu } from './ConversationHeaderActionMenu';
import { HeaderLayout } from './HeaderLayout';

type Props = {
  conversation: Conversation;
};

export const ConversationHeader = ({ conversation }: Props) => {
  const otherMembers = useOtherConversationMembers(conversation);
  const { t } = useTranslation(['common']);
  const isGroupConversation = isGroupChat(conversation);

  if (isGroupConversation) {
    return (
      <HeaderLayout
        avatarSection={<GroupConversationAvatars conversation={conversation} />}
        actionMenu={<ConversationHeaderActionMenu members={otherMembers} />}
        title={getGroupChatTitle(otherMembers, true)}
        subtitle={getGroupChatSubtitle(conversation)}
        isGroupConversation
      />
    );
  }

  const [interlocutor] = otherMembers;

  return (
    <HeaderLayout
      avatarSection={
        <Avatar
          src={getAvatarSrc(interlocutor?.avatarImage)}
          size="md"
          mr={3}
        />
      }
      actionMenu={<div />}
      title={
        interlocutor
          ? getFullName(interlocutor?.firstName, interlocutor?.lastName)
          : t('common:user-deleted')
      }
      subtitle={getTitleAndFamily(
        interlocutor?.title || '',
        interlocutor?.family?.name || ''
      )}
    />
  );
};
