import { GridItem, GridItemProps } from '@chakra-ui/react';

import { useBreakpoint } from 'theme';
import { WithChildren } from 'types';

import { COLUMNS } from '../constants';

type GridProperties = Pick<
  GridItemProps,
  | 'gridArea'
  | 'gridColumn'
  | 'gridRow'
  | 'colSpan'
  | 'rowSpan'
  | 'minH'
  | 'py'
  | 'p'
  | 'px'
>;

type Props = WithChildren<
  {
    shouldDisplay?: boolean;
  } & GridProperties
>;

export const LayoutGridElement = ({
  children,
  shouldDisplay = true,
  ...gridProperties
}: Props) => {
  const breakpoint = useBreakpoint();

  if (!shouldDisplay) return null;

  const colSpanWithDefault = gridProperties.colSpan || COLUMNS[breakpoint];

  return (
    <GridItem {...gridProperties} colSpan={colSpanWithDefault}>
      {children}
    </GridItem>
  );
};
