import { useNavigate } from 'react-router-dom';

import { gql, useMutation } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Links } from 'router/links';
import {
  Mutation,
  MutationCreateFamilyCharterArgs,
  FamilyCharterInputs,
} from 'types';
import { getImageId } from 'utils/file';
import { generateUrlById } from 'utils/links';

export const createFamilyCharterGql = gql`
  mutation CreateFamilyCharter($data: CreateFamilyCharterInput!) {
    createFamilyCharter(data: $data) {
      id
    }
  }
`;

export const useCreateFamilyCharterHero = () => {
  const navigate = useNavigate();
  const [createFamilyCharterMutation] = useMutation<
    Pick<Mutation, 'createFamilyCharter'>,
    MutationCreateFamilyCharterArgs
  >(createFamilyCharterGql, {
    refetchQueries: ['FamilyCharter', 'FamiliesCharter'],
    onCompleted: (data) => {
      navigate(
        generateUrlById(Links.editFamilyCharter, data.createFamilyCharter.id)
      );
    },
  });

  const createFamilyCharter = (values: FamilyCharterInputs) => {
    const { heroImage, heroVideo, ...rest } = values;

    return createFamilyCharterMutation({
      variables: {
        data: {
          hero: {
            ...rest,
            heroImageId: getImageId(heroImage),
            heroVideoId: getImageId(heroVideo),
          },
        },
      },
    });
  };

  const createFamilyCharterWithToast = useWithMutationToast(
    createFamilyCharter,
    {
      successMessage: 'Family Charter created successfully',
      errorMessage: 'Error creating Family Charter',
    }
  );

  return {
    createFamilyCharter: createFamilyCharterWithToast,
  };
};
