import { useQuery } from '@apollo/client';

import { Query } from 'types';

import { recentConnectionsGql } from '../documents';

export const useRecentConnections = () => {
  const { data, loading, error } =
    useQuery<Pick<Query, 'recentConnections'>>(recentConnectionsGql);

  const recentConnections = data?.recentConnections ?? [];

  return { recentConnections, loading, error };
};
