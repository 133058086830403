import { gql, useMutation } from '@apollo/client';

import { recommendedConnectionsGql } from 'components/RecommendedConnections/useRecommendedConnections';
import { notificationsGql } from 'features/Notifications/hooks/useLoadNotifications';
import { profileGql } from 'hooks/profiles/useProfile';
import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationAcceptConnectionInvitationArgs } from 'types';

import { connectionInvitationsGql, recentConnectionsGql } from '../documents';

const acceptConnectionInvitationGql = gql`
  mutation AcceptConnectionInvitation($invitationId: String!) {
    acceptConnectionInvitation(invitationId: $invitationId)
  }
`;

type Props = {
  customSuccessMessage?: string;
};

export const useAcceptConnectionInvitation = (props?: Props) => {
  const { customSuccessMessage = 'You are now connected' } = props || {};

  const [acceptConnectionInvitationMutation] = useMutation<
    Pick<Mutation, 'acceptConnectionInvitation'>,
    MutationAcceptConnectionInvitationArgs
  >(acceptConnectionInvitationGql, {
    refetchQueries: [
      profileGql,
      connectionInvitationsGql,
      recentConnectionsGql,
      notificationsGql,
      recommendedConnectionsGql,
    ],
  });

  const handleAccept = (invitationId: string) =>
    acceptConnectionInvitationMutation({ variables: { invitationId } });

  const acceptConnectionInvitation = useWithMutationToast(handleAccept, {
    successMessage: customSuccessMessage,
    errorMessage: 'Error accepting connection invitation',
  });

  return { acceptConnectionInvitation };
};
