import { lazy, Suspense } from 'react';

import { TextInput } from 'components/Form/TextInput';

import { PhoneWithCountrySelectProps } from './types';

// Lazy loading is performed because underlying `utils.js` (intl-tel-input) is large
// And it's suggested by authors of package
// See https://github.com/jackocnr/intl-tel-input#initialisation-options
const PhoneWithCountrySelectMain = lazy(
  () => import('./PhoneWithCountrySelect')
);

export const PhoneWithCountrySelect = (props: PhoneWithCountrySelectProps) => {
  const { label } = props;

  return (
    <Suspense
      fallback={<TextInput name="temp-component" label={label} isDisabled />}
    >
      <PhoneWithCountrySelectMain {...props} />
    </Suspense>
  );
};
