import { Heading } from '@chakra-ui/react';

import { useKeyEvents } from 'hooks/events';

import { KeyEventCard } from './Card/KeyEventCard';
import { CarouselBaseV2 } from './CarouselBaseV2';

export const CarouselKeyEvents = () => {
  const { keyEvents } = useKeyEvents();

  return (
    <CarouselBaseV2 items={keyEvents} ItemComponent={KeyEventCard}>
      <Heading
        size="h5"
        color="primary.lime.highlight"
        position="absolute"
        top={3}
        left={5}
        zIndex={5}
      >
        Key Event
      </Heading>
    </CarouselBaseV2>
  );
};
