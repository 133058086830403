import { Box, useDisclosure } from '@chakra-ui/react';

import { ItriomMenuButton } from 'components/Button';
import { Modal } from 'components/Modal';
import { Group } from 'types';

import { GroupMemberMenu } from './GroupMemberMenu';
import { GroupMemberRow } from './GroupMemberRow';

type Props = {
  group: Group;
};

export const GroupMembersModal = ({ group }: Props) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const groupAdminIds = group.admins.map(({ id }) => id);

  const groupAdminsWithoutOwner = group.admins.filter(
    (admin) => admin.id !== group.owner.id
  );

  const groupMembersWithoutAdminsAndOwner = group.members.filter(
    (member) =>
      !groupAdminIds.includes(member.id) && member.id !== group.owner.id
  );

  return (
    <>
      <ItriomMenuButton onClick={onOpen}>View Members</ItriomMenuButton>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title="Group Members"
        containerStyling={{ maxH: '400px', h: 'full' }}
      >
        <Box overflowY="scroll" h="full" maxH="300px">
          <GroupMemberRow profile={group.owner} type="Owner">
            {group.isAdmin && (
              <GroupMemberMenu group={group} profile={group.owner} />
            )}
          </GroupMemberRow>

          {groupAdminsWithoutOwner.map((admin) => (
            <GroupMemberRow key={admin.id} profile={admin} type="Admin">
              {group.isAdmin && (
                <GroupMemberMenu group={group} profile={admin} />
              )}
            </GroupMemberRow>
          ))}

          {groupMembersWithoutAdminsAndOwner.map((member) => (
            <GroupMemberRow key={member.id} profile={member}>
              {group.isAdmin && (
                <GroupMemberMenu group={group} profile={member} />
              )}
            </GroupMemberRow>
          ))}
        </Box>
      </Modal>
    </>
  );
};
