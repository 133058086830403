import { ReactNode } from 'react';

import { useVerifySecondFactorForToken } from 'hooks/authorization';
import { SecondFactorVerificationTypeEnum } from 'types';

import { OtpValidator } from './OtpValidator';

type Props = {
  onBack?: () => void;
  token: string;
  tokenType: SecondFactorVerificationTypeEnum;
  buttonAfterSubmit?: ReactNode;
};

export const OnboardingOtpValidator = ({
  onBack,
  token,
  tokenType,
  buttonAfterSubmit,
}: Props) => {
  const { verifySecondFactorForToken, loading } =
    useVerifySecondFactorForToken();

  const handleSubmitCode = (otpCode: string, onError: () => void) => {
    verifySecondFactorForToken({
      secondFactor: Number(otpCode),
      token,
      tokenType,
    }).catch(onError);
  };

  return (
    <OtpValidator
      handleSubmit={handleSubmitCode}
      buttonAfterSubmit={buttonAfterSubmit}
      token={token}
      tokenType={tokenType}
      onBack={onBack}
      loading={loading}
    />
  );
};
