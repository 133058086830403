import { Heading } from '@chakra-ui/react';

import { SectionWithImage } from './SectionWithImage';

export const FamilyValuesSection = () => (
  <SectionWithImage
    heading="Linking Family Values, Sustainable Development Goals (SDGs) and Impact
Investment Opportunities – Our Process"
    imageSrc="/assets/marketplace/Itriom-platform_Values.gif"
  >
    <Heading size="h5">
      At Itriom’s heart is our consultative process and unique digital tools
      which help families find clear values and a clarity of purpose across the
      generations.
      <br /> <br />
      This strategy directs impact investment and philanthropy and determines
      which bespoke longer-term cause-related initiatives are created.
      <br /> <br />
      Each strand has its own role in delivering the family purpose, respecting
      the values and honouring the promises of the charter with tangible,
      positive and identifiable change.
    </Heading>
  </SectionWithImage>
);
