import { Tab } from 'components/TabPane';

import { ForumTab, ForumTabPanel } from './ForumTabPanel';

export const forumTabsConfig: Record<ForumTab, Tab> = {
  [ForumTab.All]: {
    content: <ForumTabPanel type={ForumTab.All} />,
    navigateTo: `#${ForumTab.All}`,
    labelKey: 'all',
  },
  [ForumTab.Family]: {
    content: <ForumTabPanel type={ForumTab.Family} />,
    navigateTo: `#${ForumTab.Family}`,
    labelKey: 'family',
  },
  [ForumTab.Advisor]: {
    content: <ForumTabPanel type={ForumTab.Advisor} />,
    navigateTo: `#${ForumTab.Advisor}`,
    labelKey: 'advisor',
  },
  [ForumTab.Itriom]: {
    content: <ForumTabPanel type={ForumTab.Itriom} />,
    navigateTo: `#${ForumTab.Itriom}`,
    labelKey: 'itriom',
  },
};
