import { useAuthContext } from 'contexts/AuthContext';
import { isLinkMenuItem } from 'router/guards';
import { Links, loggedOutMenu, MenuItem, primaryMenu } from 'router/links';
import { Role } from 'types';

export const INVITABLE_ROLES = [
  Role.ItriomStaff,
  Role.ExpertAdvisor,
  Role.FamilyAdmin,
  Role.FamilyMember,
  Role.HeadOfFamily,
];

export const useHasConnections = () => {
  const { account } = useAuthContext();
  const role = account?.role;

  const hasConnections = role && INVITABLE_ROLES.includes(role);

  const filterMenuLinks = (): MenuItem[] => {
    if (!role) {
      return loggedOutMenu;
    }

    if (hasConnections) {
      return primaryMenu;
    }

    const filteredMenu = primaryMenu.reduce(
      (allMenuItems: MenuItem[], menuItem) => {
        if (isLinkMenuItem(menuItem)) {
          if (menuItem.link !== Links.connections) {
            return [...allMenuItems, menuItem];
          }
        } else {
          const filteredDropdown = menuItem.dropdownItems.filter(
            (dropdownItem) => dropdownItem.link !== Links.connections
          );

          return [
            ...allMenuItems,
            { label: menuItem.label, dropdownItems: filteredDropdown },
          ];
        }

        return [...allMenuItems];
      },
      []
    );

    return filteredMenu;
  };

  return { hasConnections, filterMenuLinks };
};
