import { gql } from '@apollo/client';

export const signedUploadUrlGql = gql`
  query SignedUploadUrl($fileType: String!) {
    signedUploadUrl(fileType: $fileType) {
      signedUrl
      temporaryFileKey
    }
  }
`;

export const commitFileInLibraryGql = gql`
  mutation CommitFileInLibrary(
    $libraryType: LibraryTypeEnum!
    $temporaryFileKey: String!
    $fileName: String!
    $destinationId: String
  ) {
    commitFileInLibrary(
      libraryType: $libraryType
      temporaryFileKey: $temporaryFileKey
      fileName: $fileName
      destinationId: $destinationId
    ) {
      id
      createdAt
      updatedAt
      path
      name
      mimeType
      isDirectory
      libraryType
      libraryOwner
      cdnKey
      resolvedPath {
        id
        createdAt
        updatedAt
        path
        name
        mimeType
        isDirectory
        libraryType
        libraryOwner
        cdnKey
        isFavorite
      }
      size
      resources {
        id
        createdAt
        updatedAt
        path
        name
        mimeType
        isDirectory
        libraryType
        libraryOwner
        cdnKey
        isFavorite
      }
      isFavorite
    }
  }
`;
