import { SdgOutput } from 'types';
import { SdgInternalName } from 'types/Sdg';

export const TEST_SDGS: [string, string] = ['test-sdg1', 'test-sdg2'];

export const generateSdgOutput = ({
  __typename = 'SdgOutput',
  description = '',
  icon = '',
  id = '',
  index = 0,
  internalName = SdgInternalName.Sdg1,
  name = '',
  createdAt = Date.now(),
  updatedAt = Date.now(),
}: Partial<SdgOutput> = {}): SdgOutput => ({
  __typename,
  description,
  icon,
  id,
  index,
  internalName,
  name,
  createdAt,
  updatedAt,
});

export const mockedSdgs = {
  sdgs: [
    generateSdgOutput({
      id: '61ea7d073ee4a589d586cbc3',
      name: TEST_SDGS[0],
      description: TEST_SDGS[0],
      internalName: SdgInternalName.Sdg1,
      icon: 'sdg-1-no-poverty',
    }),
    generateSdgOutput({
      id: '61ea7d073ee4a589d586cbc6',
      name: TEST_SDGS[1],
      description: TEST_SDGS[1],
      internalName: SdgInternalName.Sdg2,
      icon: 'sdg-2-zero-hunger',
    }),
  ],
};
