import { useField } from 'formik';

import {
  HTMLInputTypeAttribute,
  ReactNode,
  useState,
  useId,
  ClipboardEventHandler,
} from 'react';

import { View20, ViewOff20 } from '@carbon/icons-react';
import { WarningFilled } from '@carbon/icons-react/next';
import {
  CSSObject,
  Flex,
  FormControl,
  FormErrorMessage,
  IconButton,
  Input,
  InputProps,
  Text,
} from '@chakra-ui/react';

import { MaxLengthInfo } from '../MaxLengthInfo';

type Props = {
  type?: HTMLInputTypeAttribute;
  name: string;
  label?: string;
  sublabel?: string;
  helperIcons?: ReactNode;
  placeholder?: string;
  containerStyles?: CSSObject;
  inputStyles?: CSSObject;
  autoFocus?: boolean;
  maxLength?: number;
  showErrorIcon?: boolean;
  preventCopy?: boolean;
  preventPaste?: boolean;
} & InputProps;

export const TextInput = ({
  type = 'text',
  name,
  label,
  sublabel,
  helperIcons,
  containerStyles = {},
  inputStyles = {},
  placeholder,
  autoFocus = false,
  maxLength,
  showErrorIcon,
  preventCopy,
  preventPaste,
  ...inputProps
}: Props): JSX.Element => {
  const [showPassword, setShowPassword] = useState(false);
  const isPasswordType = type === 'password';
  const inputType = isPasswordType && showPassword ? 'text' : type;

  const [field, { error, touched }] = useField<string>({
    name,
    type: inputType,
  });

  const uniqueId = useId();

  const isInvalid = touched && !!error;

  const preventCopyPaste: ClipboardEventHandler = (event) => {
    event.preventDefault();
  };

  return (
    <FormControl isInvalid={isInvalid} sx={containerStyles}>
      <Flex fontSize={12} lineHeight={1.2} mb={2} alignItems="flex-end">
        {label && (
          <Text as="label" htmlFor={uniqueId}>
            {label}
          </Text>
        )}
        <Flex flex={1} />
        {maxLength && <MaxLengthInfo maxLength={maxLength} field={field} />}
        {helperIcons}
      </Flex>
      {sublabel && (
        <Flex mb={1}>
          <Text size="p3">{sublabel}</Text>
        </Flex>
      )}
      <Flex position="relative">
        <Input
          {...field}
          id={uniqueId}
          placeholder={placeholder}
          sx={inputStyles}
          border="none"
          borderBottom="1px solid"
          borderBottomColor="primary.blue.default"
          bgColor="ui.background"
          borderRadius={0}
          autoFocus={autoFocus}
          type={inputType}
          value={field.value || ''}
          {...inputProps}
          autoComplete="new-password"
          onPaste={preventPaste ? preventCopyPaste : undefined}
          onCopy={preventCopy ? preventCopyPaste : undefined}
        />
        {isPasswordType && (
          <IconButton
            position="absolute"
            zIndex={1}
            top={1}
            right={1}
            size="sm"
            bgColor="transparent"
            color="primary.blue.default"
            border="none"
            isRound
            _hover={{ bgColor: 'transparent' }}
            aria-label="change password visibility"
            onClick={() => {
              setShowPassword((prev) => !prev);
            }}
            icon={showPassword ? <ViewOff20 /> : <View20 />}
          />
        )}
      </Flex>

      <FormErrorMessage gap={1}>
        {error}
        <Flex>{showErrorIcon && <WarningFilled />}</Flex>
      </FormErrorMessage>
    </FormControl>
  );
};
