import { Link } from 'react-router-dom';

import { Flex, Image, Heading, Text } from '@chakra-ui/react';

import { Links } from 'router/links';
import { Insight } from 'types';
import { generateUrlById } from 'utils/links';
import { flattenTags } from 'utils/tags';

import { ItriomTag } from './ItriomTag';

export const InsightCard = ({ insight }: { insight: Insight }) => {
  const { id, hero, tags } = insight;

  return (
    <Flex
      key={id}
      bgColor="ui.white"
      minH={228}
      flex={1}
      direction={{ base: 'column', sm: 'row' }}
    >
      <Flex flex={1}>
        <Image
          src={hero.heroImage?.signedUrl}
          h="full"
          w="full"
          objectFit="cover"
          objectPosition="center"
        />
      </Flex>
      <Flex flex={1} p={4} direction="column" gap={4}>
        <Heading
          size="h4"
          as={Link}
          to={generateUrlById(Links.insight, id)}
          _hover={{
            textDecoration: 'underline',
          }}
        >
          {hero.headline}
        </Heading>
        <Flex columnGap={2} rowGap={1} wrap="wrap">
          {flattenTags(tags).map((tag) => (
            <ItriomTag key={tag}>{tag}</ItriomTag>
          ))}
        </Flex>
        <Text size="p1">{hero.summary}</Text>
      </Flex>
    </Flex>
  );
};
