import { ReactNode } from 'react';

import { CSSObject, Flex, Heading, Text } from '@chakra-ui/react';

type Props = {
  title: ReactNode | string;
  subtitle?: ReactNode | string;
  children: ReactNode;
  contentStyles?: CSSObject;
  id?: string;
};

export const ContentSection = ({
  title,
  children,
  subtitle,
  contentStyles = {},
  id,
}: Props): JSX.Element => {
  return (
    <Flex
      py={8}
      px={{ base: 4, md: 8 }}
      gap={{ base: 4, md: 16 }}
      id={id}
      direction={{ base: 'column', md: 'row' }}
    >
      <Flex direction="column" w={256} minW={256} gap={13}>
        <Heading size="h5">{title}</Heading>
        <Text size="p2">{subtitle}</Text>
      </Flex>

      <Flex flex={1} sx={contentStyles} direction="column" maxW={910}>
        {children}
      </Flex>
    </Flex>
  );
};
