import { gql, useQuery } from '@apollo/client';

import { useAuthContext } from 'contexts/AuthContext';
import {
  AccountRecommendationEnum,
  Query,
  QueryRecommendedConnectionsArgs,
} from 'types';
import { ProfileFragment } from 'types/generated-fragments';

export const recommendedConnectionsGql = gql`
  ${ProfileFragment}
  query RecommendedConnections(
    $pagination: PaginationInput!
    $recommendationType: AccountRecommendationEnum!
  ) {
    recommendedConnections(
      pagination: $pagination
      recommendationType: $recommendationType
    ) {
      nodes {
        ...ProfileFragment
      }
    }
  }
`;

export const useRecommendedConnections = (
  recommendationType: AccountRecommendationEnum,
  limit = 2
) => {
  const { account } = useAuthContext();

  const { data, loading, error } = useQuery<
    Pick<Query, 'recommendedConnections'>,
    QueryRecommendedConnectionsArgs
  >(recommendedConnectionsGql, {
    variables: {
      pagination: { limit, offset: 0 },
      recommendationType,
    },
    skip: !account,
  });

  const recommendedConnections = data?.recommendedConnections?.nodes ?? [];

  return { recommendedConnections, loading, error };
};
