import {
  OrganisationalValue,
  ContributionValue,
  AdvancementValue,
  SocietalValue,
  ValueCategoryName,
  ValueName,
} from 'types/ValuesConfigurator';

export const ORGANISATIONAL_VALUE_NAMES = Object.values(OrganisationalValue);
export const CONTRIBUTION_VALUE_NAMES = Object.values(ContributionValue);
export const ADVANCEMENT_VALUE_NAMES = Object.values(AdvancementValue);
export const SOCIETAL_VALUE_NAMES = Object.values(SocietalValue);

export const COLORS = ['#BBE5D2', '#56BD8F', '#F9C950', '#BDCFD8'] as const;

export const valueCategorySchema = {
  [ValueCategoryName.Advancement]: ADVANCEMENT_VALUE_NAMES,
  [ValueCategoryName.Societal]: SOCIETAL_VALUE_NAMES,
  [ValueCategoryName.Contribution]: CONTRIBUTION_VALUE_NAMES,
  [ValueCategoryName.Organisational]: ORGANISATIONAL_VALUE_NAMES,
} as const;

// TODO: refactor and move to i18n

export const VALUES_DESCRIPTIONS: Record<ValueName, string> = {
  [OrganisationalValue.Resilience]:
    'The awareness to recognise challenges and the capability to find new ways to overcome and grow from them.',
  [OrganisationalValue.Leadership]:
    'Guiding and motivating others while also inspiring and empowering them to make key decisions.',
  [OrganisationalValue.Transparency]:
    'Always behaving in ways that are clearly communicated, understandable and justifiable whilst encouraging openness and accountability.',
  [OrganisationalValue.Adaptability]:
    'The energy and intelligence to prosper in constantly changing environments by embracing new ideas and implementing fresh approaches.',
  [ContributionValue.Fairness]:
    'Ensuring an equitable share of opportunities and benefits and displaying the qualities of being reasonable, right and free from bias.',
  [ContributionValue.Inclusivity]:
    'Taking an all-encompassing approach, embracing diversity and appreciating the benefits others contribute to shared success.',
  [ContributionValue.Stewardship]:
    "Taking a responsible and nurturing approach to protect and preserve an inheritance entrusted to one's care.",
  [ContributionValue.Community]:
    'Fostering the sense of belonging, bringing like-minded people together in shared purpose, interests and commitment to realise a common goal.',
  [AdvancementValue.Progress]:
    'Committing to continual improvement, welcoming the views of others, whilst setting realistic as well as uplifting goals.',
  [AdvancementValue.Innovation]:
    'Introducing new methods, breakthroughs, inventions and ideas, challenging everyone to think creatively, fearlessly and radically to find the answers today’s issues need.',
  [AdvancementValue.Learning]:
    'Searching for every opportunity to absorb new facts, skills, truths and insights and discovering more ways to apply this knowledge.',
  [AdvancementValue.Curiosity]:
    'Being inquisitive, always questioning the common knowledge and accepted view to find fresh and enlightening answers.',
  [SocietalValue.Collaboration]:
    'Working towards a common, agreed purpose and sharing responsibility and accountability for its success.',
  [SocietalValue.Compassion]:
    'Acknowledging, listening and acting to confront and alleviate the misfortune of others.',
  [SocietalValue.Equality]:
    'Respecting the value of all, acting without bias to protect everyone’s right to fair treatment and equal opportunities.',
  [SocietalValue.Empowerment]:
    'Giving others the opportunity and power to realise their true potential.',
};

export const CATEGORIES_DESCRIPTIONS = {
  [ValueCategoryName.Organisational]:
    'Values that guide the family’s operational approach, primarily, but not necessarily in the operation of its businesses.',
  [ValueCategoryName.Contribution]:
    'Values aimed at making a difference outside of the family and its business contexts, such as sustainable development and societal improvement.',
  [ValueCategoryName.Advancement]:
    'Values aimed at advancing and continually improving the family and its approach to life and doing business.',
  [ValueCategoryName.Societal]:
    'Values that promote better relations within the family and with others.',
} as const;
