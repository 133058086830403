import { Links } from 'router/links';

import { NextGenService, ServiceCard, SolutionCards } from '../types';

const cards: SolutionCards[] = [
  {
    iconSrc:
      '/assets/nextGenServices/solutionServices/sustainability/legacy.svg',
    title: 'Legacy',
    description:
      'A sustainable approach means families can help solve some of the most pressing global challenges, positively impact society and secure an enduring and meaningful legacy.',
  },
  {
    iconSrc:
      '/assets/nextGenServices/solutionServices/sustainability/reputation.svg',
    title: 'Reputation',
    description:
      'Making a tangible and positive impact will enhance the family’s stakeholder engagement and relationships, particularly with communities where the family have investments and businesses.',
  },
  {
    iconSrc:
      '/assets/nextGenServices/solutionServices/sustainability/impact.svg',
    title: 'Impact',
    description:
      'Increasing consumer, regulatory and stakeholder demand for sustainable services and products means investments and businesses with a sustainability focussed approach are best placed to create and grow shareholder value.',
  },
];

const services: ServiceCard[] = [
  {
    src: '/assets/nextGenServices/interServices/sustainability/risk.png',
    title: 'Sustainability risk audit',
    description:
      'We audit the family’s investments, businesses, philanthropic activities and governance systems to identify the risks and opportunities they face. This increased awareness means the family can focus on where their initiatives will make the most difference, and where risks may be.',
  },
  {
    src: '/assets/nextGenServices/interServices/sustainability/vision.png',
    title: 'Vision, goals & target setting',
    description:
      'Having identified both the risks and opportunities, we work with the family to agree a bespoke vision and unique goals for realising both. We also draw on our data and benchmarking expertise to develop robust targets so that the family can see tangible progress towards every goal.',
  },
  {
    src: '/assets/nextGenServices/interServices/sustainability/strategy.png',
    title: 'Sustainability strategy',
    description:
      'This identifies and sets out the distinct actions that are needed to achieve the agreed goals, underpinned by robust KPIs.',
  },
  {
    src: '/assets/nextGenServices/interServices/sustainability/implementation.png',
    title: 'Implementation',
    description:
      'The actions and steps embedded in the sustainability strategy that will be implemented, managed and fully resourced to meet the family’s ambitions. From formulation to full implementation, Itriom supports every family with firm project management, clear communications and rigorous progress tracking.',
  },
  {
    src: '/assets/nextGenServices/interServices/sustainability/tracking.png',
    title: 'Performance tracking',
    description:
      'Families and their stakeholders will want to know how they’re progressing towards their target and goals. All of us want progress to be measured in a credible, evidence – based way. Our proprietary dashboards are deigned to present relevant information intuitively and simply, making progress and objectives easy to monitor.',
  },
  {
    src: '/assets/nextGenServices/interServices/sustainability/reporting.png',
    title: 'Reporting & disclosure',
    description:
      'Itriom helps UHNW families to produce a Family Sustainability Report. It transparently analyses and summarises the family’s impact on sustainable development. Sharing the actions the family have taken also helps to truly engage stakeholders.',
  },
];

export const sustainability: NextGenService = {
  hero: {
    src: '/assets/nextGenServices/heroImages/sustainability.png',
    title: 'Next Generation Sustainability',
    subtitle: 'Accelerating and maintaining sustainability',
    link: Links.nextGenSustainability,
  },
  heroDescription:
    'The global and irreversible transition to a sustainable economy is both a vast challenge and huge opportunity for UHNW families. Those with the right sustainability-based strategy can improve the resilience of their businesses and investments, enhance their reputation and create a tangible positive impact while achieving financial return.',
  interConnectedServices: {
    serviceCards: services,
  },
  solutions: {
    sectionTitle:
      'How our next generation leadership & resilience services help you',
    cards,
  },
  leader: {
    src: '/assets/nextGenServices/leaders/herb.png',
    title: 'Dr. Herb Castillo',
    subtitle: 'Associate Partner Practice Leader - Sustainability',
    bodyText:
      'Dr. Herb creates methodologies and frameworks for managing, measuring and assessing sustainability performance. His work identifies where maximum impact can be made.',
    findOutMoreUrl: '',
  },
  quote: {
    quote:
      '“Addressing sustainability opportunities and risks enable UHNW families to meaningfully and demonstrably contribute to making a positive impact on the world’s biggest and most pressing challenges. While also realising financial returns by investing in developing products and services that seek to find answers for such challenges.”',
    author: (
      <>
        Dr Herb Castillo <br />
        Associate Partner
        <br />
        Practice Leader - Sustainability
      </>
    ),
  },
  services: [
    {
      title: 'Next Generation Leadership & Resilience',
      subtitle: 'Delivering the way forward',
      src: '/assets/nextGenServices/heroImages/sustainability.png',
      link: Links.nextGenSustainability,
    },
    {
      title: 'Next Generation Geostrategy',
      subtitle: 'Shaping the future',
      src: '/assets/nextGenServices/heroImages/geostrategy.png',
      link: Links.nextGenGeoStrategy,
    },
  ],
};
