import { useTranslation } from 'react-i18next';

import { Flex, Heading } from '@chakra-ui/react';

import { ViewButton } from 'components/Button';
import { CardWithImage } from 'components/CardWithImage';
import { ConnectionProfile } from 'components/ProfileCard/ConnectionProfile';
import { QueryStateGuard } from 'components/QueryStateGuard';
import { useAuthContext } from 'contexts/AuthContext';
import { ConnectionTab } from 'features/Connections/components/ConnectionTabs/types';
import { Links } from 'router/links';
import { isItriomStaff } from 'utils/roles';

import { useAllRecommendedConnections } from './useAllRecommendedConnections';

type Props = {
  showButton?: boolean;
  showList?: boolean;
};

export const RecommendedConnections = ({ showButton, showList }: Props) => {
  const { account } = useAuthContext();
  const { recommendedConnections, loading, error } =
    useAllRecommendedConnections();

  const { t } = useTranslation(['connections', 'common']);

  if (isItriomStaff(account?.role)) return null;

  const rowConnections = recommendedConnections.slice(0, 2);
  const listConnections = recommendedConnections.slice(2);

  return (
    <Flex direction="column" gap={2} w="full">
      <Flex>
        <Heading size="h5">{t('connections:recommended')}</Heading>
        <ViewButton
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          to={`${Links.connections}#${ConnectionTab.Recommended}`}
          variant="blue"
          containerStyling={{ ml: 'auto' }}
        >
          {t('common:view-all')}
        </ViewButton>
      </Flex>

      <QueryStateGuard loading={loading} error={error}>
        <Flex wrap="wrap" justify="space-between" gap={2}>
          {rowConnections.length ? (
            rowConnections.map((profile) => (
              <CardWithImage
                type="Profile"
                data={profile}
                key={profile.id}
                {...(rowConnections.length === 2 && { flex: 1 })}
              />
            ))
          ) : (
            <Flex bgColor="ui.white" p={2}>
              {t('connections:no-recommended')}
            </Flex>
          )}
        </Flex>
        {showList && (
          <Flex direction="column">
            {listConnections.map((profile) => (
              <ConnectionProfile profile={profile} key={profile.id} />
            ))}
          </Flex>
        )}
      </QueryStateGuard>
      <Flex flex={1} />
      {showButton && (
        <ViewButton
          to={`${Links.connections}#${ConnectionTab.Recommended}`}
          variant="blue"
          containerStyling={{ ml: 'auto' }}
        >
          {t('connections:view-recommended')}
        </ViewButton>
      )}
    </Flex>
  );
};
