import { Global } from '@emotion/react';

export const Fonts = (): JSX.Element => (
  <Global
    styles={`
      @font-face {
        font-family: 'Publico Headline';
        font-style: normal;
        font-display: swap;
        src: url('/assets/fonts/PublicoHeadline-Black-Web.woff2') format('woff2'), url('/assets/fonts/PublicoHeadline-Black-Web.woff.woff') format('woff');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
        font-family: 'Publico Headline Medium';
        font-style: normal;
        font-display: swap;
        src: url('/assets/fonts/PublicoHeadline-Medium-Web.woff2') format('woff2'), url('/assets/fonts/PublicoHeadline-Medium-Web.woff.woff') format('woff');   
      @font-face {
        font-family: 'Publico Headline Light';
        font-style: normal;
        font-display: swap;
        src: url('/assets/fonts/PublicoHeadline-Light-Web.woff2') format('woff2'), url('/assets/fonts/PublicoHeadline-Light-Web.woff.woff') format('woff');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      @font-face {
        font-family: 'Publico Headline Roman';
        font-style: normal;
        font-display: swap;
        src: url('/assets/fonts/PublicoHeadline-Roman-Web.woff2') format('woff2'), url('/assets/fonts/PublicoHeadline-Roman-Web.woff.woff') format('woff');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
    `}
  />
);
