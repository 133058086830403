import { gql, useMutation } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation } from 'types';

import { postsGql } from './usePosts';

const deletePostGql = gql`
  mutation DeletePost($deletePostId: String!) {
    deletePost(id: $deletePostId)
  }
`;

export const useDeletePost = () => {
  const [mutation] = useMutation<Pick<Mutation, 'deletePost'>>(deletePostGql, {
    refetchQueries: [postsGql],
  });

  const handleDeletePost = (id: string) =>
    mutation({ variables: { deletePostId: id } });

  const handleDeletePostWithToast = useWithMutationToast(handleDeletePost, {
    disableSuccessMessage: true,
  });

  return { handleDeletePost: handleDeletePostWithToast };
};
