import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { Flex, Image, StackDivider } from '@chakra-ui/react';

import { Links } from 'router/links';

import { WelcomeLayoutFooter } from '../WelcomeLayout/WelcomeLayoutFooter';

export type LayoutWithBlockProps = {
  bgImage?: string;
  leftContent?: ReactNode;
  children?: ReactNode;
};

export const LayoutWithBlock = ({
  bgImage,
  leftContent,
  children,
}: LayoutWithBlockProps) => {
  return (
    <Flex
      position="fixed"
      top={0}
      left={0}
      bottom={0}
      right={0}
      bgColor="primary.blue.default"
      bgImage={bgImage}
      bgPosition="center"
      bgSize="cover"
      bgRepeat="no-repeat"
      zIndex={10}
    >
      <Flex
        zIndex={11}
        position="absolute"
        flexWrap="wrap"
        top={0}
        left={0}
        bottom={0}
        right={0}
        backdropFilter="brightness(0.7)"
        p={{ base: 4, md: 12 }}
        py={{ base: 10, md: 10 }}
        overflowY="auto"
      >
        <Flex alignItems="flex-start" flex={1} direction="column" mr={10}>
          <Link to={Links.home}>
            <Image
              src="/assets/itriom-logo.svg"
              alt="itriom logo"
              width={100}
              mb={8}
            />
          </Link>
          <StackDivider borderColor="ui.white" borderBottomWidth={1} />
          <Flex
            direction="column"
            alignItems="flex-start"
            flex={1}
            color="ui.white"
            justifyContent="center"
            maxW={600}
            gap={10}
          >
            {leftContent}
          </Flex>
          <WelcomeLayoutFooter color="ui.white" />
        </Flex>
        {children}
      </Flex>
    </Flex>
  );
};
