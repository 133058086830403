import {
  Box,
  Flex,
  FlexProps,
  Heading,
  SimpleGrid,
  StackDivider,
  Text,
} from '@chakra-ui/react';

import { SdgCard } from 'components/SdgCard';
import { SdgOutput } from 'types';

type Props = {
  sdgs: SdgOutput[];
  id?: string;
  containerStyling?: FlexProps;
  title: string;
  description: string;
};

export const OnboardSdgsSummary = ({
  sdgs,
  id,
  containerStyling,
  title,
  description,
}: Props) => {
  return (
    <Flex
      direction="column"
      width="100%"
      alignItems="flex-start"
      maxW={1080}
      justifyContent="center"
      display="inline-grid"
      id={id}
      color="ui.white"
      {...containerStyling}
    >
      <Box w={480} fontFamily="Publico">
        <Heading alignSelf="flex-start" pb={2}>
          {title}
        </Heading>
        <StackDivider
          borderColor="ui.white"
          borderBottomWidth={3}
          w={14}
          mb={4}
        />
        <Text fontSize="lg" fontWeight={500}>
          {description}
        </Text>
      </Box>

      <SimpleGrid
        columns={{ lg: 3, md: 1 }}
        mt={8}
        width="100%"
        alignItems="stretch"
        gap={12}
      >
        {sdgs.map((sdg) => (
          <SdgCard sdg={sdg} key={sdg.id} />
        ))}
      </SimpleGrid>
    </Flex>
  );
};
