import { gql, useQuery } from '@apollo/client';

import { GroupInternalFragment } from 'gql/fragments';
import { GroupInternalType } from 'types';

export const yourMostActiveGroupsGql = gql`
  ${GroupInternalFragment}

  query YourMostActiveGroups {
    yourMostActiveGroups {
      ...GroupInternalFragment
    }
  }
`;

export const useYourMostActiveGroups = () => {
  const { data, loading, error } = useQuery<{
    yourMostActiveGroups: GroupInternalType[];
  }>(yourMostActiveGroupsGql, {
    fetchPolicy: 'cache-and-network',
  });

  const yourMostActiveGroups = data?.yourMostActiveGroups || [];

  return { yourMostActiveGroups, loading, error };
};
