import { Grid } from '@chakra-ui/react';

import { DraggableProvider } from 'components/Draggable';
import { LayoutGrid } from 'components/Layout/Grid';
import { useSearchContext } from 'contexts/SearchContext';
import { PreviewModal } from 'features/Library/components/PreviewModal';
import { RenameModal } from 'features/Library/components/RenameModal';
import { ShareModal } from 'features/Library/components/ShareModal';
import { LibraryProvider } from 'features/Library/context';
import { EventResults } from 'features/Search/components/EventResults';
import { Heading } from 'features/Search/components/Heading';
import { Loading } from 'features/Search/components/Loading';
import { ResultBlock } from 'features/Search/components/ResultBlock';
import { SearchBox } from 'features/Search/components/SearchBox';
import { SimpleResults } from 'features/Search/components/SimpleResults';

import { ConnectionResults } from '../components/ConnectionResults';
import { LibraryResults } from '../components/LibraryResults';

export const SearchPage = () => {
  const { searchResults, loading, fixedSearchResults, fixedLibraryItemsCount } =
    useSearchContext();

  return (
    <LibraryProvider>
      <DraggableProvider>
        <LayoutGrid>
          <Grid py={6} gap={6}>
            <Heading />
            <SearchBox />
            {loading && <Loading />}
            {!!searchResults.connections?.totalCount && (
              <ResultBlock
                type="connections"
                resultsLength={searchResults.connections.totalCount}
                renderComponent={
                  <ConnectionResults
                    results={searchResults.connections.nodes}
                  />
                }
              />
            )}
            {!!searchResults.events?.totalCount && (
              <ResultBlock
                type="events"
                resultsLength={searchResults.events.totalCount}
                renderComponent={
                  <EventResults results={searchResults.events.nodes} />
                }
              />
            )}
            {!!searchResults.initiatives?.totalCount && (
              <ResultBlock
                type="initiatives"
                resultsLength={searchResults.initiatives.totalCount}
                renderComponent={
                  <SimpleResults results={searchResults.initiatives.nodes} />
                }
              />
            )}
            {!!searchResults.insights?.totalCount && (
              <ResultBlock
                type="insights"
                resultsLength={searchResults.insights.totalCount}
                renderComponent={
                  <SimpleResults results={searchResults.insights.nodes} />
                }
              />
            )}
            {!!searchResults.library?.totalCount && (
              <ResultBlock
                type="library"
                resultsLength={
                  fixedLibraryItemsCount || searchResults.library.totalCount
                }
                renderComponent={
                  <LibraryResults
                    results={searchResults.library.nodes}
                    fixedResults={fixedSearchResults}
                  />
                }
              />
            )}
          </Grid>
        </LayoutGrid>
        <ShareModal />
        <PreviewModal />
        <RenameModal />
      </DraggableProvider>
    </LibraryProvider>
  );
};
