import { Flex, Image } from '@chakra-ui/react';

import { MarketplaceHeading } from './MarketplaceHeading';

export const OurPartnersSection = () => (
  <Flex
    direction="column"
    justify="center"
    align="center"
    w="full"
    bg="#F5F5F5"
    py={24}
    gap={24}
  >
    <MarketplaceHeading fontSize={30}>Our partners</MarketplaceHeading>
    <Flex
      direction={{ md: 'row', base: 'column' }}
      w="full"
      align="center"
      justify="center"
      gap={{ md: '140px', base: '55px' }}
    >
      <Image
        src="/assets/marketplace/partners/envestors_logo.png"
        width={250}
      />
      <Image src="/assets/marketplace/partners/actual_logo.png" width={250} />
    </Flex>
  </Flex>
);
