import { useLocation } from 'react-router-dom';

import { Links } from 'router/links';
import { ValueCategoryName, ValueCategoryType } from 'types/ValuesConfigurator';

const getValuesConfiguratorLink = (
  type: ValueCategoryType,
  category: ValueCategoryName
): string => `/onboarding/values/${type}/${category}`;

const CATEGORY_ORDER = [
  ValueCategoryName.Organisational,
  ValueCategoryName.Contribution,
  ValueCategoryName.Advancement,
  ValueCategoryName.Societal,
];

const IMPORTANCE_LINKS = CATEGORY_ORDER.map((categoryName) =>
  getValuesConfiguratorLink(ValueCategoryType.Importance, categoryName)
);

const RELEVANCE_LINKS = CATEGORY_ORDER.map((categoryName) =>
  getValuesConfiguratorLink(ValueCategoryType.Relevance, categoryName)
);

const LINKS_IN_ORDER = [
  ...IMPORTANCE_LINKS,
  Links.valuesConfiguratorImportanceToRelevance,
  ...RELEVANCE_LINKS,
  Links.valuesConfiguratorScores,
];

export const useNextValuesConfiguratorPath = () => {
  const { pathname } = useLocation();

  const currentIndex = LINKS_IN_ORDER.indexOf(pathname);

  const nextPath =
    currentIndex < 0 || currentIndex > LINKS_IN_ORDER.length - 2
      ? Links.home
      : LINKS_IN_ORDER[currentIndex + 1]!; // we know from expression above that this index is proper and won't return undefined

  return nextPath;
};
