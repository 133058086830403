import { formatDistance } from 'date-fns';

import { useNavigate } from 'react-router-dom';

import { Avatar, Flex, MenuItem, Text } from '@chakra-ui/react';

import { useMarkNotificationsAsRead } from 'features/Notifications/hooks/useMarkNotificationsAsRead';
import { useRedirectToConversationWithUser } from 'hooks/useRedirectToConversationWithUser';
import { Links } from 'router/links';
import { NotificationTypeEnum, NotificationUnion } from 'types';
import { getAvatarSrc } from 'utils/file';
import { generateUrlById } from 'utils/links';
import { isItriomStaff } from 'utils/roles';

import { useNotificationText } from '../utils';
import { ConnectionActions } from './ConnectionActions';
import { GroupActions } from './GroupActions';
import { ViewAction } from './ViewActions';

type Props = {
  notificationData: NotificationUnion;
  markAsRead: (notificationId: string) => void;
};

export const NotificationItem = ({ notificationData, markAsRead }: Props) => {
  const { createdAt, type, sender, isRead, metadata } = notificationData;
  const { id, firstName, lastName, title, avatarImage, role } = sender || {};

  const timeAgo = formatDistance(new Date(createdAt), new Date(), {
    addSuffix: true,
  });

  const navigate = useNavigate();
  const { markNotificationsAsRead } = useMarkNotificationsAsRead();

  const handleRedirectToConversation =
    useRedirectToConversationWithUser(sender);

  const handleRedirectToUserProfile = () => {
    if (id && !isItriomStaff(role)) {
      navigate(generateUrlById(Links.profile, id));
    }
  };

  const handleRedirectToGroup = () => {
    if ('group' in metadata && metadata.group) {
      navigate(generateUrlById(Links.groupsSingle, metadata.group.id));
    }
  };

  const handleRedirectToLibrarySharedWithMe = () => {
    navigate(Links.librarySharedWithMe);
  };

  const handleRedirectToPost = () => {
    if (
      'postInGroupId' in metadata &&
      metadata.postInGroupId &&
      typeof metadata.postInGroupId === 'string'
    ) {
      navigate(generateUrlById(Links.groupsSingle, metadata.postInGroupId));
    } else {
      navigate(Links.forum);
    }
  };

  const handleClick = async () => {
    if (!isRead) {
      await markNotificationsAsRead([notificationData.id]);
    }

    markAsRead(notificationData.id);

    switch (type) {
      case NotificationTypeEnum.ConnectionInvitationAccepted:
      case NotificationTypeEnum.ConnectionInvitationSent:
        handleRedirectToUserProfile();
        break;
      case NotificationTypeEnum.MessageSent:
        handleRedirectToConversation();
        break;
      case NotificationTypeEnum.GroupNewMember:
      case NotificationTypeEnum.GroupAdminLeft:
      case NotificationTypeEnum.GroupNewAdmin:
      case NotificationTypeEnum.GroupInvitationAccepted:
      case NotificationTypeEnum.GroupInvitationSent:
        handleRedirectToGroup();
        break;
      case NotificationTypeEnum.CommentNewResponse:
      case NotificationTypeEnum.PostNewComment:
      case NotificationTypeEnum.PostNewAppreciation:
        handleRedirectToPost();
        break;
      case NotificationTypeEnum.LibraryResourceShared:
        handleRedirectToLibrarySharedWithMe();
        break;
      default:
        break;
    }
  };

  const notificationText = useNotificationText(notificationData);

  return (
    <MenuItem py={3} onClick={handleClick} position="relative">
      <Flex gap={2}>
        <Avatar src={getAvatarSrc(avatarImage)} w={4} h={4} mt={1} />
        <Flex direction="column" gap={1}>
          <Text size="p1" color="ui.grey2" noOfLines={2}>
            {firstName} {lastName}
          </Text>
          <Text noOfLines={1} size="p3" color="ui.grey2">
            {title} {title && '•'} {timeAgo}
          </Text>

          <Text size="p3" color="primary.blue.default">
            {notificationText}
          </Text>
          {'invitationId' in metadata && !('groupId' in metadata) && (
            <ConnectionActions metadata={metadata} />
          )}
          {'groupId' in metadata && 'actionStatus' in metadata && (
            <GroupActions metadata={metadata} />
          )}
          {(('entityType' in metadata && 'entityId' in metadata) ||
            'initiativeId' in metadata) && <ViewAction metadata={metadata} />}
        </Flex>
        {!isRead && (
          <Flex
            w="6px"
            h="6px"
            borderRadius={10}
            bgColor="primary.lime.highlight"
            position="absolute"
            top={5}
            right={5}
          />
        )}
      </Flex>
    </MenuItem>
  );
};
