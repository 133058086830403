import { Center, Heading } from '@chakra-ui/react';

import { INNER_RADIUS, WHITE_RIGHT_WIDTH } from '../../constants';
import { Layer, StateValueCategory } from '../../types';

type Props = {
  category: StateValueCategory;
};

export const CenterWheelTemplate = ({ category }: Props) => (
  <Center
    position="relative"
    w={INNER_RADIUS * 2 + WHITE_RIGHT_WIDTH}
    h={INNER_RADIUS * 2 + WHITE_RIGHT_WIDTH}
  >
    <Center
      zIndex={Layer.WhiteSeparator}
      position="absolute"
      w="full"
      h="full"
      background="ui.white"
      borderRadius="50%"
    />
    <Center
      zIndex={Layer.Inner}
      position="absolute"
      width={INNER_RADIUS * 2}
      height={INNER_RADIUS * 2}
      background={category ? category.mainColor : 'base1'}
      color={category ? category.textColor : 'white'}
      borderRadius="50%"
      boxShadow="0px 2px 4px 0px #00000026 inset"
    >
      {category ? (
        <Heading textAlign="center" margin={6} size="h6">
          {category.description}
        </Heading>
      ) : (
        <Heading textAlign="center" size="h3" margin={6}>
          Itriom <br /> Impact Values Model
        </Heading>
      )}
    </Center>
  </Center>
);
