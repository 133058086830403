import {
  addConversationHandler,
  deleteConversationHandler,
  conversationSelectHandler,
  setConversationsHandler,
  setConverastionsTotalCountHandler,
  addToUnreadHandler,
  conversationSelectByUserHandler,
  handleInterlocutorTrigger,
} from './handlers';
import {
  ConversationsActionsUnion,
  ConversationsActionTypes,
  ConversationsState,
} from './types';

export const conversationsReducer = (
  state: ConversationsState,
  action: ConversationsActionsUnion
): ConversationsState => {
  switch (action.type) {
    case ConversationsActionTypes.AddConversation:
      return addConversationHandler(state, action.payload);
    case ConversationsActionTypes.AddToUnread:
      return addToUnreadHandler(state, action.payload);
    case ConversationsActionTypes.SetConversations:
      return setConversationsHandler(state, action.payload);
    case ConversationsActionTypes.SetConversationsTotalCount:
      return setConverastionsTotalCountHandler(state, action.payload);
    case ConversationsActionTypes.DeleteConversation:
      return deleteConversationHandler(state, action.payload);
    case ConversationsActionTypes.HandleConversationSelect:
      return conversationSelectHandler(state, action.payload);
    case ConversationsActionTypes.HandleSelectConversationByUser:
      return conversationSelectByUserHandler(state, action.payload);
    case ConversationsActionTypes.HandleInterlocutorTrigger:
      return handleInterlocutorTrigger(state, action.payload);
    default:
      return state;
  }
};
