import { FamilyOnboardMemberDetailsOutput } from 'types';

import { CmsSection } from '../../../CmsSection';
import { OnboardingTable } from './OnboardingTable';

type Props = {
  members: FamilyOnboardMemberDetailsOutput[];
};

export const OnboardingTableSection = ({ members }: Props) => (
  <CmsSection title="Family members">
    <OnboardingTable members={members} />
  </CmsSection>
);
