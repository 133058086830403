import { useTranslation } from 'react-i18next';

import { Breadcrumbs } from 'components/Breadcrumbs';
import { Links } from 'router/links';

import { FindService } from '../components/FindService';
import { InsightsSection } from '../components/InsightsSection';
import { LeadersSections } from '../components/LeadersSections';
import { MarketplaceHeroSection } from '../components/MarketplaceHeroSection';
import { NextGenHeaderSection } from '../components/NextGenHeaderSection';
import { NextGenOurPartnersSection } from '../components/NextGenOurPartnersSection';
import { QuoteSection } from '../components/QuoteSection';
import { SolutionSection } from '../components/SolutionSection';
import { SolutionCards } from '../types';

const cards: SolutionCards[] = [
  {
    title: 'Unity',
    description:
      "Bringing families together in intergenerational and meaningful dialogue, establishing leadership, agreeing initiatives and collaborating in delivery through hearing the next generation's true voice.",
    iconSrc: '/assets/nextGenServices/nextGenHelp/unity.svg',
  },
  {
    title: 'Purpose',
    description:
      'Applying the digital tools and incisive thinking that achieves the clarity of purpose essential for defining and realising the family’s sustainable and philanthropic ambitions.',
    iconSrc: '/assets/nextGenServices/nextGenHelp/purpose.svg',
  },
  {
    title: 'Legacy',
    description:
      'Involving the whole family in living their agreed values and purpose through initiatives chosen for achieving maximum, positive, measurable and enduring impact.',
    iconSrc: '/assets/nextGenServices/nextGenHelp/legacy.svg',
  },
];

export const NextGenServicesPage = () => {
  const { t } = useTranslation(['navigation']);

  return (
    <>
      <NextGenHeaderSection
        src="/assets/nextGenServices/hero_image.png"
        title="Next Generation Services"
        subtitle="Engaging the next generation"
      />
      <Breadcrumbs
        items={[
          { link: Links.home, label: t('navigation:home') },
          {
            link: Links.nextGenServices,
            label: t('navigation:next-gen-services'),
          },
        ]}
      />
      <MarketplaceHeroSection text="Developing leadership, accelerating sustainability and challenging perceptions through geostrategy. Itriom provides the illuminating expertise and deep consultative skills crucial to bringing inter-generational ultra-high net worth families together in values, purpose, achievement and legacy." />
      <FindService />
      <SolutionSection
        sectionTitle="How our next generation services help you"
        description="Using unique and proven processes, we fully enable committed UHNW families to tackle some of the world’s most daunting challenges. Aligning ourselves with the UN’s sustainable development goals to produce tangible and robustly measurable results."
        cards={cards}
      />
      <LeadersSections />
      <QuoteSection
        quote="“It is difficult as a father to know when your children are ready to inherit first generation wealth. Tim guided us through this process, asking difficult questions and coaching my children in what such responsibility would entail. I feel much happier now that in time they will be prepared for such a journey.”"
        author="— CEO FTSE 100 Company"
      />
      <InsightsSection />
      <NextGenOurPartnersSection />
    </>
  );
};
