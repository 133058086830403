import { ApolloError } from '@apollo/client';

import { Error } from 'components/Error';
import { Loader } from 'components/Loader';
import { WithChildren } from 'types';

type ErrorComponentProps = { error: ApolloError | Error; title?: string };
type ErrorComponentType = (props: ErrorComponentProps) => JSX.Element;

const DefaultErrorComponent = ({ error, title }: ErrorComponentProps) => (
  <Error title={title ?? error.message} />
);

type LoaderType = () => JSX.Element;

const DefaultLoader = () => <Loader />;

export type QueryStateGuardProps = WithChildren<{
  loading?: boolean;
  error?: Error | ApolloError;
  errorTitle?: string;
  ErrorComponent?: ErrorComponentType;
  LoaderComponent?: LoaderType;
}>;

export const QueryStateGuard = ({
  error,
  loading,
  children,
  errorTitle,
  ErrorComponent = DefaultErrorComponent,
  LoaderComponent = DefaultLoader,
}: QueryStateGuardProps) => {
  if (loading) return <LoaderComponent />;
  if (error) return <ErrorComponent error={error} title={errorTitle} />;

  return <>{children}</>;
};
