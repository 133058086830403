import { gql, useMutation } from '@apollo/client';

import { recommendedConnectionsGql } from 'components/RecommendedConnections/useRecommendedConnections';
import { accountGql } from 'gql/accounts/query/account';
import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationRemoveFromConnectionsArgs } from 'types';

import {
  advisorConnectionsGql,
  eventConnectionsGql,
  familyConnectionsGql,
  initiativeConnectionsGql,
  itriomConnectionsGql,
  recentConnectionsGql,
} from '../documents';

const removeFromConnectionsGql = gql`
  mutation RemoveFromConnections($accountId: String!) {
    removeFromConnections(accountId: $accountId)
  }
`;

export const useRemoveFromConnections = () => {
  const [deleteConnectionMutation] = useMutation<
    Pick<Mutation, 'removeFromConnections'>,
    MutationRemoveFromConnectionsArgs
  >(removeFromConnectionsGql, {
    refetchQueries: [
      accountGql,
      familyConnectionsGql,
      advisorConnectionsGql,
      eventConnectionsGql,
      initiativeConnectionsGql,
      itriomConnectionsGql,
      recentConnectionsGql,
      recommendedConnectionsGql,
    ],
  });

  const handleDelete = (accountId: string) =>
    deleteConnectionMutation({
      variables: {
        accountId,
      },
    });

  const removeConnection = useWithMutationToast(handleDelete, {
    successMessage: 'Connection removed',
    errorMessage: 'Error removing connection',
  });

  return { removeConnection };
};
