import {
  OrganisationalValuesOutput,
  ContributionValuesOutput,
  AdvancementValuesOutput,
  SocietalValuesOutput,
} from 'types';
import { stripTypenames } from 'utils/apollo';

import { CategoryValueData } from '../CategorySummary/types';

const colors = ['#BBE5D2', '#56BD8F', '#F9C950', '#BDCFD8'];

export const mapToSummary = (
  values:
    | OrganisationalValuesOutput
    | ContributionValuesOutput
    | AdvancementValuesOutput
    | SocietalValuesOutput
    | undefined
) => {
  if (values) {
    const categories = stripTypenames(values);
    const scores = Object.entries(categories).map(
      // @ts-expect-error TODO validate and refactor if needed
      ([valueName, score], index) => ({
        [valueName]: {
          value: score,
          color: colors[index],
        },
      })
    );

    return { ...scores[0], ...scores[1], ...scores[2], ...scores[3] } as Record<
      string,
      CategoryValueData
    >;
  }

  return {};
};
