import { Link } from 'react-router-dom';

import { Flex } from '@chakra-ui/react';

import { LogoIcon } from 'components/ItriomIcon/LogoIcon';
import { Links } from 'router/links';

export const LogoMenuItem = (): JSX.Element => {
  return (
    <Flex align="center" as={Link} to={Links.home} title="home" color="inherit">
      <LogoIcon />
    </Flex>
  );
};
