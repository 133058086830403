export enum Position {
  LeftTop,
  RightTop,
  RightBottom,
  LeftBottom,
}

export enum Layer {
  Category,
  WhiteSeparator,
  Inner,
  Values,
}

export enum AxisDirection {
  Positive = 1,
  Negative = -1,
}

export enum Curvature {
  Inside,
  Outside,
}

export type CurveConfig = {
  radius: number;
  xDir: AxisDirection;
  yDir: AxisDirection;
  curveOrientation?: Curvature;
};

export type ValueCategory = {
  name: string;
  description: string;
  mainColor: string;
  secondaryColor: string;
  values: [string, string, string, string];
  textColor: string;
  position: Position;
  icon: {
    path: string;
    transform: string;
  };
};

export type StateValueCategory = ValueCategory | null;
export type StateValue = string | null;

export enum ValuePosition {
  OuterLeft,
  InnerLeft,
  InnerRight,
  OuterRight,
}
