import { useQuery } from '@apollo/client';

import { Query, QueryMutualConnectionsArgs } from 'types';

import { mutualConnectionsGql } from '../documents';

export const useMutualConnections = (accountId: string) => {
  const { data, loading, error } = useQuery<
    Pick<Query, 'mutualConnections'>,
    QueryMutualConnectionsArgs
  >(mutualConnectionsGql, {
    variables: {
      pagination: { limit: 100, offset: 0 },
      accountId,
    },
  });

  const mutualConnections = data?.mutualConnections?.nodes ?? [];

  return { mutualConnections, loading, error };
};
