import { Grid } from '@chakra-ui/react';

import { SearchInitiativeResultOutput, SearchInsightResultOutput } from 'types';

import { MAX_PREVIEW } from '../constants';
import { SimpleResult } from './SimpleResult';

interface Props {
  results: SearchInsightResultOutput[] | SearchInitiativeResultOutput[];
}

export const SimpleResults = ({ results }: Props) => {
  return (
    <Grid gap={12} templateColumns="1fr 1fr">
      {results.slice(0, MAX_PREVIEW).map((result) => (
        <SimpleResult result={result.object} key={result.object.id} />
      ))}
    </Grid>
  );
};
