import { Heading, HeadingProps, Text } from '@chakra-ui/react';

import { WithChildren } from 'types';

type Props = { heading?: string; headingSize?: HeadingProps['size'] };

export const Paragraph = ({ heading, children }: WithChildren<Props>) => (
  <>
    {heading && (
      <Heading size="h4" mt={12} mb={2}>
        {heading}
      </Heading>
    )}
    <Text
      size="p1"
      sx={{
        a: { textDecor: 'underline' },
        strong: { display: 'block', my: 4 },
      }}
    >
      {children}
    </Text>
  </>
);
