import { useTranslation } from 'react-i18next';

import { isValueName } from 'types/ValuesConfigurator';

type ValueCopyType = {
  description: string;
  actions: [string, string, string];
};

const isValueCopyType = (obj: unknown): obj is ValueCopyType => {
  const assertedObj = obj as ValueCopyType;
  const hasDescription = typeof assertedObj.description === 'string';

  const hasThreeActionString =
    assertedObj.actions instanceof Array &&
    assertedObj.actions.length === 3 &&
    assertedObj.actions.every((action) => typeof action === 'string');

  return hasDescription && hasThreeActionString;
};

export const useValuesCopy = () => {
  const { t } = useTranslation(['values']);

  const getValueCopy = (valueName: string): ValueCopyType => {
    const lowerCaseValueName = valueName.toLowerCase();

    if (!isValueName(lowerCaseValueName)) {
      throw new Error(`Invalid value name: ${lowerCaseValueName}`);
    }

    const valueCopyObject = t(`values:values.${lowerCaseValueName}`, {
      returnObjects: true,
    });

    if (!isValueCopyType(valueCopyObject)) {
      throw new Error(
        `Value copy object retrieved from i18n is in wrong format: ${lowerCaseValueName}`
      );
    }

    return valueCopyObject;
  };

  return { getValueCopy };
};
