import { Box, Grid, Image } from '@chakra-ui/react';

import { WithChildren } from 'types';

import { CONTENT_PADDING_X } from '../constants';
import { MarketplaceHeading } from './MarketplaceHeading';

type Props = {
  heading: string;
  imageSrc: string;
  reverse?: boolean;
};

export const SectionWithImage = ({
  heading,
  imageSrc,
  children,
  reverse = false,
}: WithChildren<Props>) => (
  <Grid
    gridTemplateColumns={{ md: '1fr 1fr', base: '1fr' }}
    gridTemplateRows={{ md: 'auto auto', base: 'auto auto auto' }}
    columnGap={6}
    rowGap={{ md: '47px', base: '44px' }}
    w="full"
    px={CONTENT_PADDING_X}
  >
    <MarketplaceHeading
      gridRow={{ md: 1, base: 2 }}
      gridColumn={{ lg: reverse ? 2 : 1, md: '1 / 3', base: 1 }}
      textAlign="left"
    >
      {heading}
    </MarketplaceHeading>
    <Box
      gridRow={{ md: 2, base: 3 }}
      gridColumn={{ md: reverse ? 2 : 1, base: 1 }}
    >
      {children}
    </Box>
    <Image
      gridRow={{ lg: '1 / 3', md: 2, base: 1 }}
      gridColumn={{ md: reverse ? 1 : 2, base: 1 }}
      src={imageSrc}
      alt={heading}
    />
  </Grid>
);
