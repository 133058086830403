import { ReactNode } from 'react';

import { Flex } from '@chakra-ui/react';

import { Panel } from 'components/Layout/Panel';

import { MESSAGE_CENTRE_ID } from '../constants';

type LayoutProps = {
  sideContent: ReactNode;
  mainContent: ReactNode;
  action: ReactNode;
};
export const MessagesLayout = ({
  sideContent,
  mainContent,
  action,
}: LayoutProps) => (
  <Panel title="Messages" action={action} contentWrapperStyling={{ p: 0 }}>
    <Flex direction="column" w="full" id={MESSAGE_CENTRE_ID}>
      <Flex direction={{ base: 'column', md: 'row' }} flex={1}>
        {sideContent}
        <Flex bgColor="ui.white" flex={2}>
          {mainContent}
        </Flex>
      </Flex>
    </Flex>
  </Panel>
);
