import { Collapse, Flex } from '@chakra-ui/react';

import { FamilyCharterGoalOutput } from 'types';

import { GoalInitiative } from './GoalInitiative';
import { ObjectiveBanner } from './ObjectiveBanner';

type Props = {
  goal: FamilyCharterGoalOutput;
  isOpen: boolean;
};

export const GoalCollapse = ({ isOpen, goal }: Props) => {
  const { objectives, initiatives } = goal;

  return (
    <Collapse in={isOpen} animateOpacity={false} style={{ margin: 0 }}>
      <Flex direction="column" gap={4} mx={4} mb={4}>
        {objectives.map((objective) => (
          <ObjectiveBanner objective={objective} key={objective.id} />
        ))}
        {initiatives.map((initiative, index) => (
          <GoalInitiative
            shouldShowCharts={index === 0}
            initiativeId={initiative?.initiativeId}
            key={initiative.id}
          />
        ))}
      </Flex>
    </Collapse>
  );
};
