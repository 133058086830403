import * as d3 from 'd3';

import { addYLabel } from '.';
import { DEFAULT_TICK_DISTANCE } from '../constants';
import { GElement, SvgElement } from '../types';
import { Axis } from '../types/Axis';
import { Dimensions } from '../types/Dimensions';

type TOptions = {
  dimensions: Dimensions;
  axis: Axis<[number, number]>;
};

export const addYAxis = (el: SvgElement, options: TOptions): GElement => {
  const { dimensions, axis } = options;

  const { height, margin, padding } = dimensions;

  const ticksCount = axis.ticksCount ?? height / DEFAULT_TICK_DISTANCE;

  const { format, label, hasTicks, axisStroke, fontSize, tickPadding } = axis;

  const yAxis = d3
    .axisLeft(axis.scale)
    .ticks(ticksCount)
    .tickPadding(tickPadding);

  if (format) {
    yAxis.tickFormat(format);
  }

  if (!hasTicks) {
    yAxis.tickSize(0);
  }

  const yAxisElement = el
    .append('g')
    .attr('transform', `translate(${margin.left},0)`)
    .call(yAxis)
    .call((g) =>
      g
        .select('.domain')
        .attr('d', `M1,${height - margin.bottom}V${padding.top + margin.top}`)
        .attr('stroke', axisStroke)
    );

  yAxisElement
    .selectAll('.tick text')
    .attr('font-family', 'Publico Headline')
    .attr('font-weight', '500')
    .attr('font-size', fontSize);

  if (label) {
    addYLabel(yAxisElement, label, margin.left);
  }

  return yAxisElement;
};
