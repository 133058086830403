import { Button, ButtonProps } from '@chakra-ui/react';

export const ITRIOM_BUTTON_OUTLINE_PROPS = {
  color: 'primary.blue.default',
  borderColor: 'primary.blue.default',
  bgColor: 'white',
  border: 'solid',
  borderRadius: 2,
  borderWidth: 'revert',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  h: 12,
  px: 6,
  fontSize: 'md',
  fontFamily: 'Roboto',
  fontWeight: 400,
};

export const ItriomButtonOutline = ({ children, ...props }: ButtonProps) => {
  return (
    <Button
      {...ITRIOM_BUTTON_OUTLINE_PROPS}
      _hover={{
        color: 'ui.white',
        bgColor: 'primary.blue.default',
      }}
      {...props}
    >
      {children}
    </Button>
  );
};
