import { CheckIcon } from '@chakra-ui/icons';
import { Box } from '@chakra-ui/react';

import { ItriomButtonOutline } from 'components/Button';

import { Row } from './Row';

type Props = {
  label: string;
  handleClick: () => void;
  isCompleted?: boolean;
  isButtonDisabled?: boolean;
};

export const TriggerRow = ({
  label,
  handleClick,
  isCompleted,
  isButtonDisabled,
}: Props) => {
  return (
    <Row label={label}>
      {isCompleted ? (
        <Box my={3}>
          <CheckIcon color="status.green.default" fontSize="lg" h="full" />
        </Box>
      ) : (
        <ItriomButtonOutline
          onClick={handleClick}
          isDisabled={isButtonDisabled}
        >
          Complete
        </ItriomButtonOutline>
      )}
    </Row>
  );
};
