import { useCallback } from 'react';

import { gql, useMutation } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationCompleteFamilyValueConfiguratorArgs } from 'types';

export const completeFamilyValuesConfiguratorGql = gql`
  mutation CompleteFamilyValueConfigurator($familyId: String!) {
    completeFamilyValueConfigurator(familyId: $familyId)
  }
`;

export const useCompleteValuesConfigurator = (familyId: string) => {
  const [completeValuesConfiguratorMutation] = useMutation<
    Pick<Mutation, 'completeFamilyValueConfigurator'>,
    MutationCompleteFamilyValueConfiguratorArgs
  >(completeFamilyValuesConfiguratorGql, {
    refetchQueries: ['OnboardManagement'],
  });

  const completeValuesConfigurator = useCallback(async () => {
    const { errors } = await completeValuesConfiguratorMutation({
      variables: { familyId },
    });

    if (errors?.length) {
      throw new Error(errors[0]?.message);
    }
  }, [completeValuesConfiguratorMutation, familyId]);

  const completeValuesConfiguratorWithToast = useWithMutationToast(
    completeValuesConfigurator,
    {
      successMessage: 'Completed Value Configurator process for family.',
      errorMessage: 'Failed completing Value Configurator process.',
    }
  );

  return completeValuesConfiguratorWithToast;
};
