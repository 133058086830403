import { Navigate } from 'react-router-dom';

import { Page404 } from 'components/Page404';
import { AccountPage } from 'features/Account';
import { AdvisorPage } from 'features/Advisors';
import {
  SignInPage,
  ResetPasswordLinkPage,
  ForgotPasswordOtp,
  CheckMailPage,
  NewPasswordPage,
  TwoFA,
  ConfirmationPasswordResetPage,
} from 'features/Authorization';
import { AdminCharter, CuratorCharter } from 'features/Charter';
import { ConnectionsPage } from 'features/Connections';
import { EventPage, EventsLandingPage } from 'features/Events';
import { ForumPage } from 'features/Forum';
import { GroupPage, GroupsPage } from 'features/Group';
import { HomePage } from 'features/Home';
import { InitiativesLandingPage, InitiativePage } from 'features/Initiative';
import { InsightsLandingPage, InsightPage } from 'features/Insight';
import { PrivacyPolicyPage, TermsOfUsePage } from 'features/LegalNotice';
import { LibraryPage } from 'features/Library/routes';
import { MarketplacePage } from 'features/Marketplace';
import {
  NextGenGeostrategyPage,
  NextGenLeadershipPage,
  NextGenServicesPage,
  NextGenSustainabilityPage,
} from 'features/NextGenServices';
import { RsvpExpiredPage, RsvpPage } from 'features/Onboarding/routes/RsvpPage';
import { ProfilePage } from 'features/Profile/routes/ProfilePage';
import { SearchPage } from 'features/Search/routes';
import { SearchPageSingleType } from 'features/Search/routes/SearchPageSingleType';
import { Links } from 'router/links';
import { createPage } from 'router/utils/createPage';
import { LayoutType } from 'types';

export const getCommonRoutes = () => {
  return (
    <>
      {createPage(Links.rsvp, <RsvpPage />, {
        mustBeLogin: false,
        layout: LayoutType.None,
      })}
      {createPage(Links.rsvpExpired, <RsvpExpiredPage />, {
        mustBeLogin: false,
        layout: LayoutType.None,
      })}
      {createPage(Links.signIn, <SignInPage />, {
        mustBeLogin: false,
        layout: LayoutType.SignIn,
      })}
      {createPage(Links.forgotPassword, <ResetPasswordLinkPage />, {
        mustBeLogin: false,
        layout: LayoutType.SignIn,
      })}
      {createPage(Links.forgotPasswordOtp, <ForgotPasswordOtp />, {
        mustBeLogin: false,
        layout: LayoutType.SignIn,
      })}
      {createPage(Links.forgotPasswordCheckMail, <CheckMailPage />, {
        mustBeLogin: false,
        layout: LayoutType.SignIn,
      })}
      {createPage(Links.newPassword, <NewPasswordPage />, {
        mustBeLogin: false,
        layout: LayoutType.SignIn,
      })}
      {createPage(
        Links.forgotPasswordGoToPlatform,
        <ConfirmationPasswordResetPage />,
        {
          mustBeLogin: false,
          layout: LayoutType.SignIn,
        }
      )}
      {createPage(Links.twoFA, <TwoFA />, {
        mustBeLogin: false,
        layout: LayoutType.OldDesign,
      })}
      {createPage(Links.home, <HomePage />, {
        onlyAfterOnboarding: true,
      })}
      {createPage(Links.initiatives, <InitiativesLandingPage />, {
        onlyAfterOnboarding: true,
      })}
      {createPage(Links.initiativesSingle, <InitiativePage />, {
        onlyAfterOnboarding: true,
      })}
      {createPage(Links.insights, <InsightsLandingPage />, {
        onlyAfterOnboarding: true,
        layout: LayoutType.OldDesign,
      })}
      {createPage(Links.insight, <InsightPage />, {
        onlyAfterOnboarding: true,
      })}
      {createPage(Links.forum, <ForumPage />, {
        onlyAfterOnboarding: true,
      })}
      {createPage(Links.groups, <GroupsPage />, {
        onlyAfterOnboarding: true,
      })}
      {createPage(Links.groupsSingle, <GroupPage />, {
        onlyAfterOnboarding: true,
      })}
      {createPage(Links.profile, <ProfilePage />, {
        onlyAfterOnboarding: true,
      })}
      {createPage(Links.account, <AccountPage type={Links.account} />, {
        onlyAfterOnboarding: true,
        layout: LayoutType.OldDesign,
      })}
      {createPage(
        Links.accountDetails,
        <AccountPage type={Links.accountDetails} />,
        {
          onlyAfterOnboarding: true,
          layout: LayoutType.OldDesign,
        }
      )}
      {createPage(
        Links.accountSettings,
        <AccountPage type={Links.accountSettings} />,
        {
          onlyAfterOnboarding: true,
          layout: LayoutType.OldDesign,
        }
      )}
      {createPage(Links.events, <EventsLandingPage />, {
        onlyAfterOnboarding: true,
        layout: LayoutType.OldDesign,
      })}
      {createPage(Links.event, <EventPage />, {
        onlyAfterOnboarding: true,
      })}
      {createPage(Links.connections, <ConnectionsPage />, {
        onlyAfterOnboarding: true,
        layout: LayoutType.OldDesign,
      })}
      {
        // to be reworked to be actually CMS part only as "view preview"
        createPage(Links.advisorPage, <AdvisorPage />, {
          onlyAfterOnboarding: true,
          layout: LayoutType.OldDesign,
        })
      }
      {createPage(Links.families, <CuratorCharter />, {
        onlyAfterOnboarding: true,
      })}
      {createPage(Links.charter, <AdminCharter />, {
        onlyAfterOnboarding: true,
      })}
      {createPage(Links.marketplace, <MarketplacePage />, {
        onlyAfterOnboarding: true,
        layout: LayoutType.OldDesign,
      })}
      {createPage(Links.nextGenServices, <NextGenServicesPage />, {
        onlyAfterOnboarding: true,
        layout: LayoutType.OldDesign,
      })}
      {createPage(Links.nextGenGeoStrategy, <NextGenGeostrategyPage />, {
        onlyAfterOnboarding: true,
        layout: LayoutType.OldDesign,
      })}
      {createPage(Links.nextGenSustainability, <NextGenSustainabilityPage />, {
        onlyAfterOnboarding: true,
        layout: LayoutType.OldDesign,
      })}
      {createPage(Links.nextGenLR, <NextGenLeadershipPage />, {
        onlyAfterOnboarding: true,
        layout: LayoutType.OldDesign,
      })}
      {createPage(Links.termsOfUse, <TermsOfUsePage />, {
        mustBeLogin: false,
      })}
      {createPage(Links.privacy, <PrivacyPolicyPage />, {
        mustBeLogin: false,
      })}
      {createPage(Links.search, <SearchPage />)}
      {createPage(Links.searchSingleType, <SearchPageSingleType />)}
      {createPage(Links.library, <Navigate to={Links.libraryMy} />, {
        onlyAfterOnboarding: true,
      })}
      {createPage(Links.libraryMy, <LibraryPage />, {
        onlyAfterOnboarding: true,
      })}
      {createPage(Links.libraryFamily, <LibraryPage />, {
        onlyAfterOnboarding: true,
      })}
      {createPage(Links.librarySharedWithMe, <LibraryPage />, {
        onlyAfterOnboarding: true,
      })}
      {createPage(Links.libraryFavourites, <LibraryPage />, {
        onlyAfterOnboarding: true,
      })}
      {createPage(Links.libraryItriom, <LibraryPage />, {
        onlyAfterOnboarding: true,
      })}
      {createPage(Links.libraryRecentlyDeleted, <LibraryPage />, {
        onlyAfterOnboarding: true,
      })}
      {createPage(Links.libraryFamilyFolder, <LibraryPage />, {
        onlyAfterOnboarding: true,
      })}
      {createPage(Links.libraryMyFolder, <LibraryPage />, {
        onlyAfterOnboarding: true,
      })}
      {createPage(Links.libraryItriomFolder, <LibraryPage />, {
        onlyAfterOnboarding: true,
      })}
      {createPage(Links.librarySharedWithMeFolder, <LibraryPage />, {
        onlyAfterOnboarding: true,
      })}
      {createPage('*', <Page404 />, {
        mustBeLogin: false,
      })}
    </>
  );
};
