import { useMemo } from 'react';

import { Option } from 'types';

import { useInitiatives } from './useInitiatives';

export const useInitiativeOptions = () => {
  const { initiatives, loading, error } = useInitiatives();

  const initiativeOptions: Option[] = useMemo(
    () =>
      initiatives.map((initiative) => ({
        value: initiative.id,
        label: initiative.hero.headline,
      })),
    [initiatives]
  );

  return { initiativeOptions, loading, error };
};
