import { Box, Grid, Heading, Image } from '@chakra-ui/react';

import { ContentSection } from 'components/CmsLayout';
import { AdvisorBlockId, AdvisorLocationOutput } from 'types';

export const Location = ({ location }: { location: AdvisorLocationOutput }) => {
  const { name, description, image } = location;

  return (
    <ContentSection title="Location" id={AdvisorBlockId.Location}>
      <Grid
        gridTemplateColumns={{ base: '1fr', md: '1fr 1fr' }}
        backgroundColor="ui.white"
      >
        <Image
          src={image.signedUrl}
          alt="location-image"
          h="100%"
          objectFit="cover"
          objectPosition="center"
        />

        <Box px={4} py={6}>
          <Heading size="md" mb={4}>
            {name}
          </Heading>
          <Heading size="md">{description}</Heading>
        </Box>
      </Grid>
    </ContentSection>
  );
};
