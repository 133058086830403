import { Link } from 'react-router-dom';

import { Text } from '@chakra-ui/react';

import { useSearchContext } from 'contexts/SearchContext';

interface Props {
  text: string;
  locationUrl?: string;
}

export const TitlePreview = ({ text, locationUrl }: Props) => {
  const { searchTerm } = useSearchContext();

  const isSearchTermInText = text.includes(searchTerm);
  const [prefixChunk, suffixChunk] = text.split(searchTerm);

  return (
    <Text
      size="p1"
      as={Link}
      to={locationUrl || ''}
      noOfLines={1}
      _hover={{ textDecoration: 'underline' }}
    >
      {isSearchTermInText ? (
        <>
          {prefixChunk}
          <strong>{searchTerm}</strong>
          {suffixChunk}
        </>
      ) : (
        text
      )}
    </Text>
  );
};
