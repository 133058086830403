import { PostList } from 'components/Posts';
import { QueryStateGuard } from 'components/QueryStateGuard';
import { usePosts } from 'hooks/posts';
import { PostSource, Role } from 'types';

export enum ForumTab {
  All = 'All',
  Family = 'Family',
  Advisor = 'Advisor',
  Itriom = 'Itriom',
}

export const forumTabsFilters = {
  [ForumTab.All]: {},
  [ForumTab.Family]: { isFromFamily: true },
  [ForumTab.Advisor]: { authorsRoles: [Role.ExpertAdvisor] },
  [ForumTab.Itriom]: {
    authorsRoles: [
      Role.ExpertAdvisor,
      Role.ItriomCurator,
      Role.ItriomStaff,
      Role.Admin,
    ],
  },
};

type Props = {
  type: ForumTab;
};

export const ForumTabPanel = ({ type }: Props) => {
  const { posts, loading, error } = usePosts({
    filters: { source: PostSource.Forum, ...forumTabsFilters[type] },
    pagination: { limit: 100, offset: 0 },
  });

  return (
    <QueryStateGuard loading={loading} error={error}>
      <PostList posts={posts} />
    </QueryStateGuard>
  );
};
