import { ApolloError } from '@apollo/client';
import { GridProps, Heading, VStack, Divider, Flex } from '@chakra-ui/react';

import { Avatar } from 'components/Avatar';
import { CardWithImage } from 'components/CardWithImage';
import { QueryStateGuard } from 'components/QueryStateGuard';
import { Profile } from 'types';

type OwnProps = {
  heading: string;
  connections: Profile[];
  loading?: boolean;
  error?: ApolloError;
};

type Props = OwnProps & GridProps;

const MAX_ELEMENTS_HORIZONTAL = 0;
const MAX_ELEMENTS_VERTICAL = 3;

export const ConnectionSection = ({
  heading,
  connections,
  ...connectionsStatus
}: Props): JSX.Element | null => {
  if (!connections) return null;

  return (
    <QueryStateGuard {...connectionsStatus}>
      <Heading size="h4" mt={6}>
        {heading}
      </Heading>

      {connections.length === 0 ? (
        <Flex gap={2} my={4}>
          No connections to display
        </Flex>
      ) : (
        <>
          <Flex wrap="wrap" gap={2} my={4}>
            {connections.length === 0 ? (
              <>No data here</>
            ) : (
              connections
                .slice(0, MAX_ELEMENTS_HORIZONTAL)
                .map((profile) => (
                  <CardWithImage
                    type="Profile"
                    data={profile}
                    key={profile.id}
                  />
                ))
            )}
          </Flex>

          <VStack
            align="flex-start"
            divider={<Divider />}
            backgroundColor="ui.white"
            p={4}
          >
            {connections
              .slice(
                MAX_ELEMENTS_HORIZONTAL,
                MAX_ELEMENTS_VERTICAL + MAX_ELEMENTS_HORIZONTAL
              )
              .map((connection) => (
                <Avatar
                  variant="author"
                  subheadingInfo={connection.family?.name}
                  profile={connection}
                  key={connection.id}
                />
              ))}
          </VStack>
        </>
      )}
    </QueryStateGuard>
  );
};
