import { gql, useQuery } from '@apollo/client';

import { InsightDeepNestingFragment } from 'gql/fragments';
import { usePaginatedQuery } from 'hooks/pagination';
import {
  InputMaybe,
  InsightItriomVisibilityTypes,
  InsightStatusEnum,
  ListInsightVisibilityFiltersInput,
  Query,
  QueryListInsightsArgs,
} from 'types';

export const listInsightsGql = gql`
  ${InsightDeepNestingFragment}
  query ListInsights(
    $pagination: PaginationInput = { offset: 0, limit: 100 }
    $filters: ListInsightInput!
  ) {
    listInsights(pagination: $pagination, filters: $filters) {
      offset
      totalCount
      nodes {
        ...InsightDeepNestingFragment
      }
    }
  }
`;

type UseListInsightsProps = Partial<QueryListInsightsArgs> & {
  skip?: boolean;
};

export const useListInsights = ({
  filters = {},
  pagination = { limit: 20, offset: 0 },
  skip,
}: UseListInsightsProps = {}) => {
  const { data, loading, error } = useQuery<
    Pick<Query, 'listInsights'>,
    QueryListInsightsArgs
  >(listInsightsGql, {
    variables: {
      filters,
      pagination,
    },
    skip,
  });

  const listInsights = data?.listInsights.nodes || [];

  return { listInsights, loading, error };
};

interface Props {
  familyId?: string;
  type: InsightItriomVisibilityTypes;
}
export const usePaginatedListInsights = ({ familyId, type }: Props) => {
  const { error, loading, initialLoad, loadMore } = usePaginatedQuery<
    Pick<Query, 'listInsights'>,
    QueryListInsightsArgs
  >(listInsightsGql, {
    offset: 0,
    limit: 5,
  });

  const filters = {
    statuses: [InsightStatusEnum.Published],
    familyId,
    visibility: mapInsightTypeToVisibilityFilter(type, familyId),
  };

  const loadInsights = () =>
    initialLoad({ filters }).then(({ listInsights }) => listInsights);

  const loadMoreInsights = () =>
    loadMore({ filters }).then(({ listInsights }) => listInsights);

  return {
    loadMoreInsights,
    loadInsights,
    error,
    loading,
  };
};

const mapInsightTypeToVisibilityFilter = (
  type: InsightItriomVisibilityTypes,
  familyId?: string
): InputMaybe<ListInsightVisibilityFiltersInput> => {
  switch (type) {
    case InsightItriomVisibilityTypes.Family: {
      return {
        family: true,
      };
    }
    case InsightItriomVisibilityTypes.Itriom: {
      return {
        itriom: true,
      };
    }
    case InsightItriomVisibilityTypes.Peers: {
      return {
        connections: true,
        family: true,
      };
    }
    case InsightItriomVisibilityTypes.Public: {
      return {
        isPublic: true,
      };
    }
    default: {
      return undefined;
    }
  }
};
