import { FamilyOnboardStateEnum } from 'types';
import { isFamilyOnboardCompleted } from 'utils/values';

import { useOnboardingManagement } from './useOnboardManagement';

export const useHasFamilyFinishedValuesConfigurator = () => {
  const { onboardManagement, error, loading } = useOnboardingManagement();

  return {
    hasFamilyFinished: isFamilyOnboardCompleted(
      onboardManagement?.state,
      FamilyOnboardStateEnum.ValueConfiguratorCompleted
    ),
    error,
    loading,
  };
};
