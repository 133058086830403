import { OnboardManagementOutput } from 'types';

import { CmsSection } from '../../../CmsSection';
import { MeetingInviteForm } from './MeetingInviteForm';

type Props = {
  onboardManagement: OnboardManagementOutput;
};

export const MeetingInviteSection = ({ onboardManagement }: Props) => (
  <CmsSection title="Meeting invite">
    <MeetingInviteForm onboardManagement={onboardManagement} />
  </CmsSection>
);
