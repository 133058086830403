import * as d3 from 'd3';

import { useEffect, useMemo, useRef } from 'react';

import { addBars, setDimensions, addTitle, addLegend } from '../modules';
import { addXAxis } from '../modules/xAxis';
import { addYAxis } from '../modules/yAxis';
import { BarConfig } from '../types';
import { Axis, Scale } from '../types/Axis';
import { Config } from '../types/Config';
import { Dimensions, DimensionsConfig } from '../types/Dimensions';
import { HighlightElement } from '../types/types';

export type BarChartProps<T> = {
  data: T[];
  xGetter: (d: T) => number;
  yGetter: (d: T) => number;
  labelGetter?: (d: T) => string;
  colorGetter?: ((d: T) => string) | ((d: T, i: number) => string);
  config?: Config & {
    barWidthModifier?: number; // width of bar, in range of [0, 1]
    barBackground?: string; // color of background
  };
  dimensions?: DimensionsConfig;
  highlightElement?: HighlightElement;
};

export const BarChart = <T,>({
  data,
  xGetter,
  yGetter,
  highlightElement,
  labelGetter = () => '',
  colorGetter = () => 'currentColor',
  config = {},
  dimensions: dimensionsConfig = {},
}: BarChartProps<T>): JSX.Element => {
  const svgRef = useRef<SVGSVGElement | null>(null);

  const dimensions = useMemo(
    () => new Dimensions(dimensionsConfig),
    [dimensionsConfig]
  );

  useEffect(() => {
    if (!svgRef.current) {
      return;
    }

    // Clears svg contents
    svgRef.current.innerHTML = '';

    const X = d3.map(data, xGetter);
    const Y = d3.map(data, yGetter);
    const colors = d3.map(data, colorGetter);
    const labels = d3.map(data, labelGetter);

    const { width, height, margin, padding } = dimensions;

    const {
      title,
      legend,
      barWidthModifier = 0.8,
      barBackground,
      yAxis: yAxisConfig = {},
      xAxis: xAxisConfig = {},
    } = config;

    const xAxis = new Axis(
      xAxisConfig,
      new d3.InternSet(X),
      [margin.left + padding.left, width - margin.right - padding.right],
      Scale.Band
    );

    const yAxis = new Axis(
      yAxisConfig,
      [0, d3.max(Y) || 0] as [number, number],
      [height - margin.bottom - padding.bottom, margin.top + padding.top],
      Scale.Linear
    );

    const indices = d3
      .range(X.length)
      .filter((i) => xAxis.domain?.has(X[i] || 0));

    const svg = d3.select(svgRef.current);

    setDimensions(svg, dimensions);

    if (title) {
      addTitle(svg, { title, dimensions });
    }

    if (xAxis.visible) {
      addXAxis(svg, {
        dimensions,
        axis: xAxis,
        highlightElement,
      });
    }

    if (yAxis.visible) {
      addYAxis(svg, {
        dimensions,
        axis: yAxis,
      });
    }

    const barOffset = (xAxis.bandwidth * (1 - barWidthModifier)) / 2;

    const getBarConfig = (i: number): BarConfig => ({
      x: (xAxis.scale(X[i] ?? 0) ?? 0) + barOffset,
      y: yAxis.scale(Y[i] ?? 0) ?? 0,
      width: xAxis.bandwidth * barWidthModifier,
      height: (yAxis.scale(0) ?? 0) - (yAxis.scale(Y[i] ?? 0) ?? 0),
      label: labels[i] || '',
      color: colors[i],
    });

    addBars(svg, {
      indices,
      getBarConfig,
      barBackground,
      dimensions,
    });

    if (legend) {
      addLegend(svg, { dimensions, legend, indices, getBarConfig });
    }

    // We only want to recalculate chart when data, config or dimensions (storybook)
    // changes, other dependencies should not change at all
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, config, dimensions]);

  return (
    <svg ref={svgRef} width={dimensions.width} height={dimensions.height} />
  );
};
