import { useTranslation } from 'react-i18next';

import { IntroTile } from 'components/ContentGrid/IntroTile';
import { Insight } from 'types';

import { mapInsightsToGridInsights } from '../../mapInsightsToGridInsights';
import { InsightsGrid } from '../InsightsGrid';

interface Props {
  insights: Insight[];
}

export const FeaturedInsightGrid = ({ insights }: Props) => {
  const mappedGridInsights = mapInsightsToGridInsights(insights);
  const { t } = useTranslation(['insights']);

  return (
    <InsightsGrid
      type="featured"
      gridInsights={mappedGridInsights}
      headingContent={
        <IntroTile
          title={t('insights:featured-news.title')}
          subtitle={t('insights:featured-news.subtitle')}
        />
      }
    />
  );
};
