import { useTranslation } from 'react-i18next';

import { Heading, Grid } from '@chakra-ui/react';

import { Topic } from 'types';

import { ContentWithHeading } from '../../ContentWithHeading';
import { ElementWithBackgroundImage } from '../../ElementWithBackgroundImage';
import { EventBodyElement } from '../../EventBodyElement';

export const TopicsSection = ({ topics }: { topics: Topic[] }) => {
  const { t } = useTranslation(['events']);

  if (!topics || !topics.length) return null;

  return (
    <EventBodyElement>
      <ContentWithHeading heading={t('events:topics')}>
        <Grid gridTemplateColumns="repeat(5, 1fr)" gap={4}>
          {topics.map((topic) => (
            <ElementWithBackgroundImage
              image={topic.heroImage}
              aspectRatio="1/1"
              backgroundSize="cover"
              alignItems="flex-start"
              underlayDirection="column-reverse"
              py={0}
              key={topic.description}
            >
              <Heading color="ui.white" size="h5" zIndex={3}>
                {topic.description}
              </Heading>
            </ElementWithBackgroundImage>
          ))}
        </Grid>
      </ContentWithHeading>
    </EventBodyElement>
  );
};
