import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { CmsLayout } from 'features/Cms/components/CmsLayout';
import { UpdateMemberByFamilyAdmin } from 'features/Cms/components/UpdateMemberByFamilyAdmin';
import { useExtendedProfile } from 'hooks/profiles/useExtendedProfile';

export const EditMemberPage = () => {
  const { t } = useTranslation(['cms']);

  const { id } = useParams();

  const { extendedProfile, loading, error } = useExtendedProfile(id);

  return (
    <CmsLayout title={t('cms:user.edit')} loading={loading} error={error}>
      {extendedProfile && (
        <UpdateMemberByFamilyAdmin extendedProfile={extendedProfile} />
      )}
    </CmsLayout>
  );
};
