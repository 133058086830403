import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Flex } from '@chakra-ui/layout';
import { Text, useDisclosure } from '@chakra-ui/react';

import { RightBlock } from 'components/Layout/RightBlock';
import { SupportEmailButton } from 'components/SupportEmailButton';
import { ForgotPasswordOtpValidator } from 'features/Onboarding/components/OtpForm/OtpValidator/ForgotPasswordOtpValidator';
import { useRequestForgotPasswordVerificationSecondFactor } from 'hooks/authorization';
import { Links } from 'router/links';

import { ResetPasswordFailedModal } from '../components/ResetPasswordFailedModal';

const MESSAGE =
  'Sorry, your link to set up a new password has expired. Please close this message and try again.';

export const ForgotPasswordOtp = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const { onOpen, onClose, isOpen } = useDisclosure();

  const { requestOtp } = useRequestForgotPasswordVerificationSecondFactor();

  useEffect(() => {
    if (token)
      requestOtp({ token }).catch(() => {
        onOpen();
      });
  }, []);

  return (
    <>
      <ResetPasswordFailedModal
        isOpen={isOpen}
        onClose={onClose}
        message={MESSAGE}
      />
      <RightBlock
        title="Forgot Password"
        subtitle="Enter your One-Time Passcode"
      >
        <Flex mt="72px" direction="column" gap={14}>
          <Text size="p1">
            We have sent you a one-time passcode to the mobile number associated
            with your account to verify your identity.
          </Text>

          <Flex direction="column" height="100%">
            <ForgotPasswordOtpValidator
              token={token || ''}
              onBack={() => {
                navigate(Links.signIn);
              }}
            />
          </Flex>
        </Flex>
        <Text size="p3" mt="auto">
          Please contact <SupportEmailButton /> for technical issues or if you
          need further assistance.
        </Text>
      </RightBlock>
    </>
  );
};
