import i18n from 'i18n/config';

import { LibraryMediaOutput, LibrarySharedMediaOutput } from 'types';
import { formatDate } from 'utils/date';
import { humanFileSize } from 'utils/size';

import { LibraryLocation } from '../types';
import { useCurrentLibraryLocation } from './useCurrentLibraryLocation';

export const useGetInfoItems = (
  item: LibraryMediaOutput | LibrarySharedMediaOutput
): string[] => {
  const info = [];
  const { libraryLocation } = useCurrentLibraryLocation();

  if (item.isDirectory) {
    if (libraryLocation !== LibraryLocation.RecentlyDeleted) {
      info.push(
        i18n.t('library:folder.number-of-files', {
          count: item.resources ? item.resources.length : 0,
        })
      );
    }
  } else {
    info.push(humanFileSize(item.size));
  }

  // if (
  //   [LibraryTypeEnum.Family].includes(item.libraryType) &&
  //   item.libraryOwner
  // ) {
  //   info.push(ownerName);
  // }

  info.push(formatDate(new Date(item.createdAt), 'dd.MM.yyyy'));

  return info;
};
