import { Flex } from '@chakra-ui/react';

import { FamilyCharterGoalOutput } from 'types';

import { GoalCard } from './GoalCard';
import { GoalCollapse } from './GoalCollapse';

type Props = {
  goal: FamilyCharterGoalOutput;
  toggleOpen: VoidFunction;
  isOpen: boolean;
};

export const GoalSection = ({ goal, toggleOpen, isOpen }: Props) => {
  if (!goal.sdg) return null;

  return (
    <Flex
      direction="column"
      w="100%"
      id={`goal${goal.id}`}
      gap={4}
      border="default"
      bg="ui.background"
    >
      <GoalCard goal={goal} isOpen={isOpen} toggleOpen={toggleOpen} />
      <GoalCollapse goal={goal} isOpen={isOpen} />
    </Flex>
  );
};
