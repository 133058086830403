import { gql, useQuery } from '@apollo/client';

import { FamilyCharterDeepNestingFragment } from 'gql/fragments';
import { Query } from 'types';
import {
  ProfileFragment,
  FamilyCharterNoNestingFragment,
  FamilyMemberRelationOutputFragment,
  ItriomTagsV2OutputFragment,
  PrincipalAboutOutputFragment,
  PrincipalHeroOutputFragment,
  PrincipalPrivacySettingsOutputFragment,
  PrincipalValueOutputFragment,
  FamilyNoNestingFragment,
  FamilyValuesOutputFragment,
  GoalFragment,
  MediaOutputFragment,
  ProfileNoNestingFragment,
  SdgOutputFragment,
} from 'types/generated-fragments';

export const PrincipalOutputFragment = gql`
  ${PrincipalHeroOutputFragment}
  ${PrincipalAboutOutputFragment}
  ${PrincipalPrivacySettingsOutputFragment}
  ${PrincipalValueOutputFragment}
  ${ItriomTagsV2OutputFragment}
  ${FamilyCharterNoNestingFragment}
  ${FamilyMemberRelationOutputFragment}
  fragment PrincipalOutputFragment on PrincipalOutput {
    id
    createdAt
    updatedAt
    createdBy
    updatedBy
    hero {
      ...PrincipalHeroOutputFragment
    }
    about {
      ...PrincipalAboutOutputFragment
    }
    status
    privacySettings {
      ...PrincipalPrivacySettingsOutputFragment
    }
    values {
      ...PrincipalValueOutputFragment
    }
    tags {
      ...ItriomTagsV2OutputFragment
    }
    familyCharter {
      ...FamilyCharterNoNestingFragment
    }
    isHeadOfFamily
    memberRelations {
      ...FamilyMemberRelationOutputFragment
    }
  }
`;

export const FamilyOutputFragment = gql`
  ${MediaOutputFragment}
  ${SdgOutputFragment}
  ${GoalFragment}
  ${ProfileNoNestingFragment}
  ${FamilyValuesOutputFragment}
  ${FamilyCharterDeepNestingFragment}
  ${FamilyNoNestingFragment}
  fragment FamilyOutputFragment on Family {
    ...FamilyNoNestingFragment
    heroImage {
      ...MediaOutputFragment
    }
    heroVideo {
      ...MediaOutputFragment
    }
    influences {
      ...SdgOutputFragment
    }
    goals {
      ...GoalFragment
    }
    members {
      ...ProfileNoNestingFragment
    }
    admins {
      ...ProfileNoNestingFragment
    }
    advisors {
      ...ProfileNoNestingFragment
    }
    sdGoals {
      ...SdgOutputFragment
    }
    values {
      ...FamilyValuesOutputFragment
    }
    familyCharter {
      ...FamilyCharterDeepNestingFragment
    }
  }
`;

export const profileGql = gql`
  ${ProfileFragment}
  ${PrincipalOutputFragment}
  ${FamilyOutputFragment}
  query Profile($profileId: String!) {
    profile(id: $profileId) {
      ...ProfileFragment
      principal {
        ...PrincipalOutputFragment
      }
      family {
        ...FamilyOutputFragment
      }
    }
  }
`;

export const useProfile = (id: string | undefined) => {
  const { data, loading, error, refetch } = useQuery<Pick<Query, 'profile'>>(
    profileGql,
    { variables: { profileId: id }, skip: !id }
  );
  const profile = data?.profile;

  return { profile, loading, error, refetch };
};
