export enum LibraryLocation {
  My = 'MY_LIBRARY',
  Family = 'FAMILY_LIBRARY',
  SharedWithMe = 'SHARED_WITH_ME',
  Favourites = 'FAVOURITES',
  RecentlyDeleted = 'RECENTLY_DELETED',
  Itriom = 'ITRIOM_LIBRARY',
  SearchLibraryContext = 'SEARCH_LIBRARY_CONTEXT',
}

export enum ItemType {
  FOLDER = 'FOLDER',
  FILE = 'FILE',
}

export interface DropResults {
  id: string;
  isDirectory: boolean;
}
