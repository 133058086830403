import { Box } from '@chakra-ui/layout';

import { ConditionalWrapper } from 'components/ConditionalWrapper';
import { useAuthContext } from 'contexts/AuthContext';
import { WithChildren } from 'types';

import { Footer } from './Footer';
import { LayoutGrid } from './Grid';
import { Header } from './Header';
import { LayoutProps } from './types';

const LayoutGridOldDesign = ({ children }: WithChildren) => (
  <LayoutGrid isOldDesign>{children}</LayoutGrid>
);

export const Layout = ({ children, isOldDesign = false }: LayoutProps) => {
  const { account } = useAuthContext();

  const showFooter = !!account;

  return (
    <Box bg="base3">
      <Header />
      <Box as="main" minH="100vh" px={4} w="full" pb={16}>
        <ConditionalWrapper Wrapper={isOldDesign && LayoutGridOldDesign}>
          {children}
        </ConditionalWrapper>
      </Box>
      {showFooter && <Footer />}
    </Box>
  );
};
