import { LayoutGridElement } from 'components/Layout/Grid';
import { InitiativeType } from 'features/Initiative/types';

import { InitiativeTabBasePanel } from './InitiativeTabBasePanel';

type Props = {
  type: InitiativeType;
};

export const InitiativesTabPanel = ({ type }: Props) => {
  return (
    <LayoutGridElement gridColumn="5 / span 12">
      <InitiativeTabBasePanel type={type} />
    </LayoutGridElement>
  );
};
