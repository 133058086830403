import { Flex, Heading } from '@chakra-ui/react';

import { MarkdownPreview } from 'components/MarkdownPreview';

type Props = {
  heading?: string;
  richText?: string;
};

export const ArticleRichText = ({ heading, richText }: Props): JSX.Element => {
  return (
    <Flex direction="column" gap={4}>
      <Heading size="h5">{heading}</Heading>
      <MarkdownPreview content={richText} />
    </Flex>
  );
};
