import { HStack } from '@chakra-ui/react';

import { ValueCategoryName } from 'types/ValuesConfigurator';

import { Bubble } from './Bubble';
import { valuesConfiguratorBubbles } from './constants';

type Props = {
  activeStep?: ValueCategoryName;
  isHighlighted?: boolean;
};

export const BubbleStepper = ({ activeStep, isHighlighted = false }: Props) => {
  return (
    <HStack>
      {valuesConfiguratorBubbles.map((valuesConfiguratorBubble) => (
        <Bubble
          key={valuesConfiguratorBubble.label.valueOf()}
          {...valuesConfiguratorBubble}
          isActive={
            isHighlighted || valuesConfiguratorBubble.label === activeStep
          }
        />
      ))}
    </HStack>
  );
};
