import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, Text } from '@chakra-ui/react';

import { Panel } from 'components/Layout/Panel';
import { QueryStateGuard } from 'components/QueryStateGuard';
import { SingleGroupCard } from 'components/SingleGroupCard';
import { useYourGroups } from 'hooks/groups';
import { shuffleArray } from 'utils/arrays';

export const YourGroups = () => {
  const { t } = useTranslation(['network']);

  const { yourGroups, loading, error } = useYourGroups();

  const firstThreeGroups = useMemo(
    () => shuffleArray(yourGroups).slice(0, 3),
    [yourGroups]
  );

  return (
    <Panel title="Your groups" h="fit-content" variant="secondary">
      <QueryStateGuard loading={loading} error={error}>
        <Flex gap={2} direction="column" w="full">
          {firstThreeGroups.length ? (
            firstThreeGroups.map((group) => (
              <SingleGroupCard key={group.id} group={group} />
            ))
          ) : (
            <Text>{t('network:groups.empty-state')}</Text>
          )}
        </Flex>
      </QueryStateGuard>
    </Panel>
  );
};
