import { ReactNode, useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import useMeasure from 'react-use-measure';

import { BoxProps, Flex, FlexProps, Heading, Image } from '@chakra-ui/react';

import { underlay } from 'theme/mixins/underlay';
import { HeroMediaType } from 'types';

export type HeroMediaProps = {
  imageUrl?: string;
  children?: ReactNode;
  videoUrl?: string;
  videoAutoPlay?: boolean;
  heading?: string;
  preferredHeroMediaToDisplay?: HeroMediaType;
  hasControls?: boolean;
  containerStyles?: BoxProps;
  childrenContainerStyles?: FlexProps;
};

export const HeroMedia = ({
  heading,
  children,
  preferredHeroMediaToDisplay,
  imageUrl,
  videoUrl,
  videoAutoPlay = true,
  hasControls = true,
  containerStyles,
  childrenContainerStyles,
}: HeroMediaProps) => {
  const [containerRef, bounds] = useMeasure();
  const [playVideo, setPlayVideo] = useState(videoAutoPlay);

  if (
    (!videoUrl && !imageUrl && !children) ||
    preferredHeroMediaToDisplay === null
  ) {
    return null;
  }

  const displayVideo =
    videoUrl && preferredHeroMediaToDisplay === HeroMediaType.Video;

  const togglePlayVideo = () => {
    if (videoUrl) {
      setPlayVideo((prev) => !prev);
    }
  };

  return (
    <Flex
      ref={containerRef}
      pos="relative"
      w="100%"
      maxH={700}
      h="50vw"
      minH={300}
      backgroundColor="rgba(0,0,0,0.5)"
      sx={{
        '& video': {
          objectFit: 'cover',
        },
      }}
      {...containerStyles}
    >
      <Flex
        position="absolute"
        zIndex={0}
        {...underlay()}
        width="full"
        height="full"
        sx={{
          '& video, & img': {
            width: '100%',
            height: '100%',
            display: 'block',
            objectFit: 'cover',
            objectPosition: 'center',
          },
        }}
      >
        {displayVideo ? (
          <ReactPlayer
            url={videoUrl}
            controls={hasControls}
            width={bounds.width}
            height={bounds.height}
            playing={playVideo}
            loop={videoAutoPlay}
            muted
            pip
            poster={imageUrl}
          />
        ) : (
          <Image src={imageUrl} />
        )}
      </Flex>

      {(children || heading) && (
        <Flex
          mr="auto"
          mt="auto"
          px={8}
          py={28}
          zIndex={10}
          direction="column"
          justify="flex-end"
          align="flex-start"
          color="ui.white"
          gap={2}
          onClick={togglePlayVideo}
          cursor="default"
          userSelect="none"
          {...childrenContainerStyles}
        >
          {heading && (
            <Heading color="ui.white" size="h1">
              {heading}
            </Heading>
          )}
          {children}
        </Flex>
      )}
    </Flex>
  );
};
