import { addSeconds, format } from 'date-fns';

import { duration } from './date';

const addPluralSuffix = (timeRange: number): string =>
  timeRange > 1 ? 's ' : ' ';

const getRange = (timeRange: number | undefined, rangeName: string): string =>
  timeRange ? `${timeRange} ${rangeName}${addPluralSuffix(timeRange)}` : '';

export const getDurationString = (
  from: Date | number,
  to: Date | number
): string => {
  if (from > to) {
    return 'Invalid time';
  }

  const { days, hours, minutes } = duration(from, to);
  const daysString = getRange(days, 'day');
  const hoursString = getRange(hours, 'hour');
  const minutesString = getRange(minutes, 'min');

  return daysString + hoursString + minutesString.trim();
};

export const formatTime = (seconds: number) => {
  const helperDate = addSeconds(new Date(0), seconds);
  const formattedTime = format(helperDate, 'mm:ss');

  return formattedTime + (seconds > 59 ? 'min' : 's');
};
