import { useQuery } from '@apollo/client';

import { Query } from 'types';

import { peerConnectionsGql } from '../documents';

export const usePeerConnections = () => {
  const { data, loading, error } =
    useQuery<Pick<Query, 'peerConnections'>>(peerConnectionsGql);

  const peerConnections = data?.peerConnections ?? [];

  return { peerConnections, loading, error };
};
