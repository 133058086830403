import { useTranslation } from 'react-i18next';

import { CarouselBase } from 'components/Carousel/CarouselBase';
import { ImageTextTile } from 'components/ImageTextTile';
import { LayoutGridElement } from 'components/Layout/Grid';
import { NextGenCardProps } from 'features/NextGenServices/types';

import { CARD_CAROUSEL_WIDTH } from '../../../../components/ImageTextTile/ImageTextTile';
import { LEADERS } from '../../content/services';

const LEADERS_WITH_ID = LEADERS.map((leader) => ({
  ...leader,
  id: leader.title,
}));

const CARD_MARGIN = 16;

export const LeadersSections = () => {
  const { t } = useTranslation(['services']);

  return (
    <LayoutGridElement colSpan={16}>
      <CarouselBase
        title={t('services:headings.leaders')}
        items={LEADERS_WITH_ID}
        ItemComponent={LeaderSliderComponent}
        spaceBetween={16}
        slidesPerGroup={3}
        swiperStyles={{
          width: `calc(${CARD_CAROUSEL_WIDTH + CARD_MARGIN / 2}px * 3)`,
          position: 'unset',
        }}
        containerStyles={{
          alignItems: 'center',
          '& [class^=swiper-slide]': { height: 'unset' },
        }}
      />
    </LayoutGridElement>
  );
};

const LeaderSliderComponent = ({
  item,
}: {
  item: NextGenCardProps & { id: string };
}) => {
  return <ImageTextTile {...item} />;
};
