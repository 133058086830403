import { gql } from '@apollo/client';

import { MessageFragment } from 'types/generated-fragments';

export const sendMessageGql = gql`
  ${MessageFragment}
  mutation SendMessage($message: MessageInput!) {
    sendMessage(message: $message) {
      ...MessageFragment
    }
  }
`;
