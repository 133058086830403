import { gql, useQuery } from '@apollo/client';

import { FamilyCharterStatusEnum, Query } from 'types';

export const familiesChartersGql = gql`
  query FamiliesCharter(
    $filters: ListFamilyCharterInput! = { 
      statuses: [${FamilyCharterStatusEnum.Draft}, ${FamilyCharterStatusEnum.Published}]
    }
    $pagination: PaginationInput! = { limit: 100 }
  ) {
    familiesCharter(filters: $filters, pagination: $pagination) {
      nodes {
        id
        status
        hero{
          heading
          heroImage{
            signedUrl
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const useFamiliesCharters = () => {
  const { data, loading, error } = useQuery<Pick<Query, 'familiesCharter'>>(
    familiesChartersGql,
    { fetchPolicy: 'cache-and-network' }
  );

  const charters = data?.familiesCharter.nodes || [];

  return { charters, loading, error };
};
