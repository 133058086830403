import { Form, FormikProvider, useFormik } from 'formik';

import { useTranslation } from 'react-i18next';

import { Button, Heading, useDisclosure } from '@chakra-ui/react';

import { TextAreaInput } from 'components/Form/TextAreaInput';
import { Modal } from 'components/Modal';
import { HelpRequestOutput, TriggeredCtaEnum } from 'types';

import { ElementWithBackgroundImage } from '../../ElementWithBackgroundImage';
import { useCtaEmail } from './hooks/useCtaEmail';
import { RequestMessage } from './types';
import { initialMessage } from './utils';
import { messageSchema, MESSAGE_MAX_LENGTH } from './validation';

type Props = {
  eventId: string;
  eventHeading: string;
  cta: HelpRequestOutput;
};

export const RequestHelpCTA = ({
  eventId,
  eventHeading,
  cta,
}: Props): JSX.Element | null => {
  const { image, name, description } = cta;
  const { sendEmail } = useCtaEmail(
    TriggeredCtaEnum.Help,
    eventId,
    eventHeading
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation(['events']);

  const handleSubmit = async (values: RequestMessage) => {
    const { message } = values;
    await sendEmail(message);
    onClose();
  };

  const formik = useFormik({
    initialValues: initialMessage,
    onSubmit: handleSubmit,
    validationSchema: messageSchema,
  });

  if (!cta?.isEnabled) {
    return null;
  }

  return (
    <>
      <ElementWithBackgroundImage image={image} minH={320}>
        <Heading size="h3" color="ui.white">
          {name}
        </Heading>
        <Heading size="h6" color="ui.white" maxW="66%">
          {description}
        </Heading>
        <Button variant="primaryLime" w="max-content" onClick={onOpen}>
          {t('events:request-help')}
        </Button>
      </ElementWithBackgroundImage>

      <Modal title="Request help" isOpen={isOpen} onClose={onClose}>
        <FormikProvider value={formik}>
          <Form>
            <TextAreaInput
              name="message"
              label="Your message"
              placeholder="Type your request here..."
              maxLength={MESSAGE_MAX_LENGTH}
              rows={5}
              autoFocus
            />
            <Button type="submit" ml="auto" mt={4} display="block">
              Send request
            </Button>
          </Form>
        </FormikProvider>
      </Modal>
    </>
  );
};
