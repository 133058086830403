import { OverflowMenuHorizontal } from '@carbon/icons-react/next';
import { CheckIcon } from '@chakra-ui/icons';
import { Flex } from '@chakra-ui/react';

import { ITRIOM_BUTTON_OUTLINE_PROPS } from 'components/Button/ItriomButtonOutilne/ItriomButtonOutline';

type Props = {
  isCompleted: boolean;
};

export const CompletionIndicator = ({ isCompleted }: Props) => {
  const indicatorStyling = isCompleted
    ? { color: 'status.green.default', borderColor: 'status.green.default' }
    : { color: 'status.amber.default', borderColor: 'status.amber.default' };

  const indicatorContent = isCompleted ? (
    <>
      Done
      <CheckIcon
        color="status.green.default"
        fontSize="lg"
        h="full"
        ml={3}
        my={3}
      />
    </>
  ) : (
    <>
      In progress
      <OverflowMenuHorizontal
        color="status.green.default"
        size={24}
        fontSize="lg"
        style={{ marginLeft: 12 }}
      />
    </>
  );

  return (
    <Flex {...ITRIOM_BUTTON_OUTLINE_PROPS} {...indicatorStyling}>
      {indicatorContent}
    </Flex>
  );
};
