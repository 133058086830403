import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ChevronUp, ChevronDown, Close } from '@carbon/icons-react/next';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { Flex, Heading, Progress, Text, Grid } from '@chakra-ui/react';

import { IconButton } from 'components/Button/IconButton';
import { INFO_SEPARATOR } from 'features/Library/constants';
import { UploadingFile, useLibraryContext } from 'features/Library/context';

import { FileIcon } from '../ItemRow/FileIcon';

export const UploadingBubble = () => {
  const {
    uploadingItems,
    hideUploadingBubble,
    toggleMinimizeUploadingBubble,
    uploadingBubbleState,
    removeUploadingItem,
  } = useLibraryContext();
  const { t } = useTranslation(['library']);

  const currentCount = useMemo(
    () =>
      uploadingItems.filter((item) => item.progress !== 100 || item.hasError)
        .length,
    [uploadingItems]
  );

  const handleRemove = (item: UploadingFile) => {
    if (item.progress !== 100) {
      window.dispatchEvent(new Event('ABORT_UPLOAD'));
    } else {
      removeUploadingItem(item);
    }
  };

  if (uploadingBubbleState === 'HIDDEN') {
    return null;
  }

  return (
    <Flex
      zIndex={20}
      position="fixed"
      bottom={0}
      w="465px"
      right={8}
      bg="ui.white"
      direction="column"
      pt={2}
      pb={8}
      borderRadius={1}
      boxShadow="0px 2px 5px 0px rgba(0, 0, 0, 0.10)"
    >
      <Flex w="100%" justifyContent="flex-end" px={2}>
        <IconButton
          variant="ghost"
          icon={uploadingBubbleState === 'MINIMIZED' ? ChevronUp : ChevronDown}
          onClick={toggleMinimizeUploadingBubble}
          aria-label="Minimize"
        />
        <IconButton
          variant="ghost"
          icon={Close}
          onClick={hideUploadingBubble}
          aria-label="Close"
        />
      </Flex>
      <Flex px={8} gap={4} direction="column">
        <Heading size="h4">
          {t('library:upload.currently-uploading', {
            count: uploadingItems.length,
            currentCount,
          })}
        </Heading>
        {uploadingBubbleState === 'VISIBLE' &&
          uploadingItems.map((item) => (
            <Flex key={item.name} direction="column">
              <Grid
                gridTemplateColumns="auto auto auto auto 1fr"
                p={4}
                border="solid 1px"
                gap={4}
                borderColor="ui.grey4"
                alignItems="center"
                justifyContent="flex-start"
              >
                <FileIcon name={item.name} />
                <Text>{item.name}</Text>
                <Text>{INFO_SEPARATOR}</Text>
                <Text size="p3">{item.size}</Text>
                <IconButton
                  justifySelf="flex-end"
                  size="xl"
                  icon={
                    !item.hasError && item.progress === 100
                      ? CheckCircleIcon
                      : Close
                  }
                  variant="ghost"
                  color={
                    !item.hasError && item.progress === 100
                      ? 'status.green.default'
                      : 'status.red.default'
                  }
                  aria-label="Remove"
                  onClick={() => handleRemove(item)}
                />
              </Grid>

              <Progress
                value={item.progress}
                colorScheme={item.progress === 100 ? 'green' : 'purple'}
                size="xs"
              />
            </Flex>
          ))}
      </Flex>
    </Flex>
  );
};
