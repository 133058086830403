import { useCallback, useMemo, useState } from 'react';

import { CloseButtonProps } from '@chakra-ui/react';

import { Modal } from 'components/Modal';

import { getMultistepConfig, ValuesIntroScreen } from './multistepConfig';

const getCloseButtonColorStyling = (
  currentScreen: ValuesIntroScreen
): CloseButtonProps => {
  switch (currentScreen) {
    case ValuesIntroScreen.AboutPage:
      return { color: 'primary.blue.default', background: 'ui.white' };
    case ValuesIntroScreen.Categorisation:
    case ValuesIntroScreen.Essentials:
    case ValuesIntroScreen.Wheel:
    default:
      return { color: 'ui.white', background: 'primary.blue.default' };
  }
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const OnboardingIntroModal = ({ isOpen, onClose }: Props) => {
  const [currentScreen, setCurrentScreen] = useState(
    ValuesIntroScreen.AboutPage
  );

  const handleClose = useCallback(() => {
    onClose();
    setCurrentScreen(ValuesIntroScreen.AboutPage);
  }, [onClose]);

  const multistepConfig = useMemo(
    () => getMultistepConfig(handleClose, setCurrentScreen),
    [handleClose]
  );

  const currentStep = useMemo(
    () => multistepConfig[currentScreen],
    [currentScreen, multistepConfig]
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      isFull
      bodyStyling={{ p: 0 }}
      closeButtonStyling={{
        ...getCloseButtonColorStyling(currentScreen),
        borderRadius: 0,
        zIndex: 20,
      }}
    >
      {currentStep}
    </Modal>
  );
};
