import { useAddConfiguratorValues } from 'hooks/onboarding/useAddConfiguratorValues';
import { AdvancementValuesInput, ValueConfiguratorCategoryEnum } from 'types';

import {
  ValuesConfiguratorForm,
  advancementValues,
} from '../ValuesConfugratorForm';

type Props = {
  valuesCategory: ValueConfiguratorCategoryEnum;
};

export const AdvancementStep = ({ valuesCategory }: Props): JSX.Element => {
  const addConfiguratorValues = useAddConfiguratorValues();

  const onSubmit = (values: AdvancementValuesInput) =>
    addConfiguratorValues({ valuesCategory, advancementValues: values });

  return (
    <ValuesConfiguratorForm
      onSubmit={onSubmit}
      category={advancementValues}
      valuesCategory={valuesCategory}
    />
  );
};
