import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Grid } from '@chakra-ui/react';

import { ItriomButtonOutline } from 'components/Button';
import { FamilyMemberNamePanel } from 'components/FamilyMemberNamePanel';
import { Links } from 'router/links';
import { AccountStatus, ExtendedProfileOutput, FamilyStatusEnum } from 'types';
import { generateUrlById } from 'utils/links';

import { UserActivationButton } from '../UserActivationButton';

interface Props {
  member: ExtendedProfileOutput;
  hasActivationButton?: boolean;
  editButtonLink?: Links;
}

export const MemberRow = ({
  member,
  hasActivationButton = true,
  editButtonLink = Links.cmsUserEdit,
}: Props) => {
  const { t } = useTranslation(['common']);

  const isFamilyActive = member.family?.status === FamilyStatusEnum.Active;

  const showActivationButton = hasActivationButton && isFamilyActive;

  return (
    <Grid
      gap={4}
      gridTemplateColumns={`1fr 4.75rem ${
        showActivationButton ? '7.75rem' : ''
      }`}
      {...((member.status === AccountStatus.Inactive || !isFamilyActive) && {
        color: 'ui.grey2',
      })}
      role="row"
    >
      <FamilyMemberNamePanel
        firstName={member.firstName}
        lastName={member.lastName}
        role={member.role}
      />
      <Link to={generateUrlById(editButtonLink, member.id)}>
        <ItriomButtonOutline
          bgColor="primary.blue.default"
          color="ui.white"
          _hover={{ color: 'primary.blue.default', bgColor: 'ui.white' }}
        >
          {t('common:edit')}
        </ItriomButtonOutline>
      </Link>
      {showActivationButton && <UserActivationButton member={member} />}
    </Grid>
  );
};
