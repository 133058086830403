import { Layout } from 'components/Layout/Layout';
import { WelcomeLayout } from 'components/Layout/WelcomeLayout';
import { ValuesIntroLayout } from 'features/Onboarding';
import { LayoutType, WithChildren } from 'types';

export const layouts: Record<
  LayoutType,
  ({ children }: WithChildren) => JSX.Element
> = {
  [LayoutType.Default]: Layout,
  [LayoutType.OldDesign]: ({ children }: WithChildren) => (
    <Layout isOldDesign>{children}</Layout>
  ),
  [LayoutType.ValuesIntro]: ValuesIntroLayout,
  [LayoutType.None]: ({ children }: WithChildren) => <>{children}</>,
  [LayoutType.SignIn]: ({ children }: WithChildren) => (
    <WelcomeLayout title="Welcome to Itriom">{children}</WelcomeLayout>
  ),
};
