export enum Links {
  account = '/account',
  accountDetails = '/account/details',
  accountSettings = '/account/settings',
  advisorManageOwnProfile = '/cms/advisors/manageOwn',
  advisorOnboardPage = '/cms/onboarding/advisors',
  advisorPage = '/advisor/:id',
  blankPage = '/blank',
  charter = '/charter',
  cmsAdvisorProfiles = '/cms/advisors',
  cmsAdvisors = '/cms/advisor-account',
  cmsCreateFamilyCharter = '/cms/families/add',
  cmsEvents = '/cms/events',
  cmsFamilyCharter = '/cms/family/edit',
  cmsFamilyCharters = '/cms/families',
  cmsInitiatives = '/cms/initiatives',
  cmsInsights = '/cms/insights',
  cmsMarketplaceDemoRequests = '/cms/marketplace/requests',
  cmsNextGenServicesDemoRequests = '/cms/nextGen/requests',
  cmsProfileEdit = '/cms/profile/edit/:id',
  cmsProfiles = '/cms/profile/edit',
  cmsUserCreate = '/cms/users/create',
  cmsUserEdit = '/cms/users/edit/:id',
  cmsUsers = '/cms/users/edit',
  connections = '/connections',
  createAdvisor = '/cms/advisor-account/add',
  createAdvisorProfile = '/cms/advisors/add',
  createEvent = '/cms/events/add',
  createInitiative = '/cms/initiatives/add',
  createInsight = '/cms/insights/add',
  curator = '/curator',
  editAdvisor = '/cms/advisors-account/edit/:id',
  editAdvisorProfile = '/cms/advisors/edit/:id',
  editEvent = '/cms/events/edit/:id',
  editFamilyCharter = '/cms/families/edit/:id',
  editInitiative = '/cms/initiatives/edit/:id',
  editInsight = '/cms/insights/edit/:id',
  event = '/events/:id',
  events = '/events',
  families = '/families/:id',
  familyManagement = '/family-management',
  familyManagementUserEdit = '/family-management/users/:id',
  familyOnboardingSetup = '/cms/onboarding/setup',
  forgotPassword = '/forgotPassword',
  forgotPasswordCheckMail = '/forgotPassword/checkMail',
  forgotPasswordGoToPlatform = '/forgotPassword/platform',
  forgotPasswordOtp = '/reset-password/:token',
  forum = '/forum',
  goals = '/goals',
  groups = '/groups',
  groupsSingle = '/groups/:id',
  home = '/',
  initiatives = '/initiatives',
  initiativesSingle = '/initiatives/:id',
  insight = '/insights/:id',
  insights = '/insights',
  manageAccess = '/manage/access',
  marketplace = '/marketplace',
  messageCentre = '/#messageCentre',
  newPassword = '/forgotPassword/new',
  nextGenGeoStrategy = '/nextGen/geostrategy',
  nextGenLR = '/nextGen/leadership',
  nextGenServices = '/nextGen/services',
  nextGenSustainability = '/nextGen/sustainability',
  onboardingLogout = '/onboarding/logout',
  onboardingOtp = '/onboarding/login/:token',
  onboardingOtpSetPassword = '/onboarding/set-password',
  onboardingOtpSetPasswordSuccess = '/onboarding/set-password-success',
  privacy = '/privacy',
  profile = '/profile/:id',
  rsvp = '/rsvp',
  rsvpExpired = '/rsvp/expired',
  sdgConfigurator = '/onboarding/sdgs',
  sdgConfiguratorFamiliesSection = '/onboarding/sdgs#families',
  sdgConfiguratorWelcome = '/onboarding/sdgs/welcome',
  sdgConfiguratorWhatAre = '/onboarding/sdgs/what-are',
  sdgConfiguratorWhyAlign = '/onboarding/sdgs/why-align',
  search = '/search',
  searchSingleType = '/search/:type',
  signIn = '/sign-in',
  signOut = '/sign-out',
  termsOfUse = '/terms-of-use',
  twoFA = '/2fa',
  valuesConfigurator = '/onboarding/values',
  valuesConfiguratorAbout = '/onboarding/values/about',
  valuesConfiguratorCategorisation = '/onboarding/values/categorisation',
  valuesConfiguratorCta = '/onboarding/values/cta',
  valuesConfiguratorEssential = '/onboarding/values/essential',
  valuesConfiguratorFirstValue = '/onboarding/values/importance/organisational',
  valuesConfiguratorHowToUse = '/onboarding/values/how-to',
  valuesConfiguratorImpact = '/onboarding/values/impact',
  valuesConfiguratorImportance = '/onboarding/values/importance',
  valuesConfiguratorImportanceToRelevance = '/onboarding/values/next',
  valuesConfiguratorRelevance = '/onboarding/values/relevance',
  valuesConfiguratorScores = '/onboarding/values/scores',
  valuesConfiguratorThankYou = '/onboarding/values/thank-you',

  // LIBRARY
  library = '/library', // Redirect to my
  libraryMy = '/library/my',
  libraryMyFolder = '/library/my/:id',
  libraryFamily = '/library/family',
  libraryFamilyFolder = '/library/family/:id',
  libraryItriom = '/library/itriom',
  libraryItriomFolder = '/library/itriom/:id',
  librarySharedWithMe = '/library/shared-with-me',
  librarySharedWithMeFolder = '/library/shared-with-me/:id',
  libraryRecentlyDeleted = '/library/deleted',
  libraryFavourites = '/library/favourites',
  libraryFile = '/library/files/:id',
}

export type LinkMenuItem = {
  label: string;
  link: Links;
};

export type DropdownMenuItem = {
  label: string;
  dropdownItems: LinkMenuItem[];
};

export type MenuItem = LinkMenuItem | DropdownMenuItem;

export const primaryMenu: MenuItem[] = [
  { label: 'Home', link: Links.home },
  { label: 'Charter', link: Links.charter },
  { label: 'Initiatives', link: Links.initiatives },
  { label: 'Events', link: Links.events },
  {
    label: 'Network',
    dropdownItems: [
      { label: 'Forum', link: Links.forum },
      { label: 'Groups', link: Links.groups },
      { label: 'Connections', link: Links.connections },
    ],
  },
  { label: 'Insights', link: Links.insights },
  {
    label: 'Services',
    dropdownItems: [
      { label: 'Next Generation Services', link: Links.nextGenServices },
      { label: 'Leadership & Resilience', link: Links.nextGenLR },
      { label: 'Sustainability', link: Links.nextGenSustainability },
      { label: 'Geostrategy', link: Links.nextGenGeoStrategy },
      { label: 'Marketplace', link: Links.marketplace },
    ],
  },
];

export const loggedOutMenu: MenuItem[] = [
  { label: 'Sign in', link: Links.signIn },
];
