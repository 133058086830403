import { useMemo } from 'react';

import { useAuthContext } from 'contexts/AuthContext';
import { listInitiativesGql } from 'hooks/initiatives';
import { usePaginatedQuery } from 'hooks/pagination';
import {
  FollowedInitiativesTreatment,
  InitiativeStatusEnum,
  InputMaybe,
  ListInitiativesVisibilityFiltersInput,
  Query,
  QueryListInitiativesArgs,
} from 'types';

import { InitiativeType, TABS_WITH_FOLLOW_SECTION } from '../../types';

export const useInitiativesPaginated = (
  type: InitiativeType,
  isOther?: boolean
) => {
  const { account } = useAuthContext();
  const { initialLoad, loadMore, loading, error } = usePaginatedQuery<
    Pick<Query, 'listInitiatives'>,
    QueryListInitiativesArgs
  >(listInitiativesGql, { offset: 0, limit: 5 });

  const followedInitiativesTreatment = useMemo(() => {
    if (TABS_WITH_FOLLOW_SECTION.includes(type)) {
      if (isOther) {
        return FollowedInitiativesTreatment.ExcludeFollowed;
      }

      return FollowedInitiativesTreatment.LimitToFollowed;
    }

    return undefined;
  }, [type, isOther]);

  const filters = useMemo(() => {
    let visibilityFilters: InputMaybe<ListInitiativesVisibilityFiltersInput>;

    if (type === InitiativeType.All) {
      visibilityFilters = account?.familyId
        ? {
            familyIdHasAccessTo: account?.familyId,
          }
        : { isPublic: true };
    }

    if (type !== InitiativeType.All) {
      visibilityFilters = {};

      if (type === InitiativeType.Family) {
        visibilityFilters.family = true;
      }

      if (type === InitiativeType.Joint) {
        visibilityFilters.joint = true;
      }

      if (type === InitiativeType.Peer) {
        visibilityFilters.connections = true;
      }

      if (type === InitiativeType.Public) {
        visibilityFilters.isPublic = true;
      }
    }

    return {
      status: InitiativeStatusEnum.Published,
      followedInitiativesTreatment,
      visibilityFilters,
    };
  }, [account?.familyId, account?.id, followedInitiativesTreatment, type]);

  const loadInitiatives = () =>
    initialLoad({ filters }).then(({ listInitiatives }) => listInitiatives);

  const loadMoreInitiatives = () =>
    loadMore({ filters }).then(({ listInitiatives }) => listInitiatives);

  return {
    loadMoreInitiatives,
    loadInitiatives,
    loading,
    error,
  };
};
