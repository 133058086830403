import { MouseEventHandler } from 'react';

import { OverflowMenuHorizontal } from '@carbon/icons-react/next';
import {
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from '@chakra-ui/react';

import { ThreeDotsIconVariant, MenuElement } from './types';

type Props = {
  elements: MenuElement[];
  iconVariant?: ThreeDotsIconVariant;
  id?: string;
};

export const ThreeDotsMenu = ({
  iconVariant = 'light',
  elements,
  id,
}: Props) => {
  const { isOpen, onClose, onToggle } = useDisclosure();

  const handleMenuToggle: MouseEventHandler = (e): void => {
    e.preventDefault();
    e.stopPropagation();

    onToggle();
  };

  const filteredElements = elements.filter((element) => !element.hidden);

  return (
    <Menu isLazy isOpen={isOpen} onClose={onClose} id={id}>
      <MenuButton
        as={IconButton}
        minW="30px"
        w="30px"
        h="30px"
        icon={<Icon as={OverflowMenuHorizontal} h={5} w={5} />}
        variant="outline"
        border="none"
        visibility={!filteredElements.length ? 'hidden' : 'visible'}
        color={iconVariant === 'dark' ? 'primary.blue.default' : 'ui.white'}
        onClick={handleMenuToggle}
      />
      <MenuList p={0} color="primary.blue.default" overflow="hidden">
        {filteredElements.map(({ handleClick, text, icon }, i) => (
          <MenuItem
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              handleClick();
            }}
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            h={10}
            justifyContent="space-between"
          >
            {text}
            {icon && <Icon as={icon} />}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
