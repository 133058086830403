import { Close } from '@carbon/icons-react/next';
import { Flex, IconButton, MenuItem, Text } from '@chakra-ui/react';

import { MarkAllAsReadButton } from './MarkAllAsReadButton';

type Props = {
  isDisabled?: boolean;
  handleMarkAllAsRead: () => void;
  isMarkAllAsReadLoading?: boolean;
  heading: string;
  showMarkAllAsReadButton?: boolean;
};

export const NotificationsListHeader = ({
  isDisabled,
  handleMarkAllAsRead,
  isMarkAllAsReadLoading,
  heading,
  showMarkAllAsReadButton,
}: Props) => (
  <Flex
    position="sticky"
    top={0}
    left={0}
    bgColor="ui.white"
    zIndex="sticky"
    direction="column"
  >
    <Flex
      justify="space-between"
      py={3}
      px={4}
      align="center"
      borderBottom="1px solid"
      borderColor="ui.grey3"
    >
      <Text size="p1">{heading}</Text>
      <IconButton
        aria-label="close notifications modal"
        variant="ghost"
        isRound
        size="xs"
        overflow="hidden"
        icon={
          <MenuItem as="div" justifyContent="center" p={0}>
            <Close size={24} />
          </MenuItem>
        }
      />
    </Flex>
    {showMarkAllAsReadButton && (
      <MarkAllAsReadButton
        disabled={!!isDisabled}
        handleClick={handleMarkAllAsRead}
        isLoading={isMarkAllAsReadLoading}
      />
    )}
  </Flex>
);
