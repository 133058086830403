import { ReactNode } from 'react';

import {
  Center,
  Flex,
  StackDivider,
  Image,
  Box,
  FlexProps,
} from '@chakra-ui/react';

import {
  ButtonsGroup,
  ButtonsGroupProps,
} from 'components/Button/ButtonsGroup';

type Props = {
  children: ReactNode;
  renderBackground?: boolean;
  background?: FlexProps['background'];
  color?: FlexProps['color'];
  showHeader?: boolean;
  containerStyling?: FlexProps;
  contentWrapperStyling?: FlexProps;
} & ButtonsGroupProps;

export const ValuesLayout = ({
  renderBackground,
  children,
  background = 'primary.blue.default',
  color = 'white',
  showHeader,
  leftButton,
  rightButton,
  containerStyling,
  contentWrapperStyling,
}: Props) => (
  <Flex
    position="fixed"
    top={0}
    left={0}
    bottom={0}
    right={0}
    background={background}
    color={color}
    fontFamily="Publico"
    zIndex={10}
    {...containerStyling}
  >
    {renderBackground && (
      <Box
        as="video"
        w="full"
        h="full"
        autoPlay
        muted
        loop
        objectFit="cover"
        src="/assets/video/values-configurator.mp4"
      />
    )}
    <Flex
      zIndex={11}
      position="absolute"
      top={0}
      left={0}
      bottom={0}
      right={0}
      backdropFilter={renderBackground ? 'brightness(0.7)' : 'none'}
      p={{ base: 4, md: 12 }}
      pt={{ base: 4, md: 4 }}
      overflowY="auto"
      direction="column"
      {...contentWrapperStyling}
    >
      {showHeader && (
        <>
          <Image
            src="/assets/itriom-logo.svg"
            alt="itriom logo"
            width={100}
            mb={8}
          />
          <StackDivider borderColor="ui.white" borderBottomWidth={2} />
        </>
      )}
      <Center flex={1} w="full" h="full">
        {children}
      </Center>
      <ButtonsGroup leftButton={leftButton} rightButton={rightButton} />
    </Flex>
  </Flex>
);
