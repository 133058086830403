import { useMemo } from 'react';

import { Flex } from '@chakra-ui/react';

import { QueryStateGuard } from 'components/QueryStateGuard';
import { useLibraryContext } from 'features/Library/context';
import { useCurrentLibraryLocation } from 'features/Library/hooks/useCurrentLibraryLocation';
import { useLibrary } from 'features/Library/hooks/useLibrary';
import { LibraryLocation } from 'features/Library/types';
import { LibraryMediaOutput, LibrarySharedMediaOutput } from 'types';

import { ItemRow } from '../ItemRow';
import { ItemRowShared } from '../ItemRow/ItemRowShared';
import { FilesFoldersHeading } from './FilesFoldersHeading';

interface Props {
  type: 'files' | 'folders';
}

export const FilesFoldersSection = ({ type }: Props) => {
  const {
    addActionBarSelectedItem,
    removeActionBarSelectedItems,
    sortDirection,
    favouritesFilterEnabled,
    actionBarSelectedItems,
    actionBarSelectedItemsContains,
  } = useLibraryContext();

  const { libraryLocation } = useCurrentLibraryLocation();

  const { files, folders, error, loading } = useLibrary();

  const items = useMemo(
    () =>
      (type === 'files' ? files || [] : folders || [])
        .filter((item: LibraryMediaOutput | LibrarySharedMediaOutput) =>
          favouritesFilterEnabled ? item.isFavorite : true
        )
        .sort(
          (
            a: LibraryMediaOutput | LibrarySharedMediaOutput,
            b: LibraryMediaOutput | LibrarySharedMediaOutput
          ) => {
            if (sortDirection === 'ASC') {
              return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
            }

            return a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1;
          }
        ),
    [type, files, folders, favouritesFilterEnabled, sortDirection]
  );

  const handleSelect = (
    item: LibraryMediaOutput | LibrarySharedMediaOutput,
    isSelected: boolean
  ) => {
    if (isSelected) {
      addActionBarSelectedItem(item);
    } else {
      removeActionBarSelectedItems(item);
    }
  };

  const hasEveryItemSelected = useMemo(
    () =>
      !!items.length &&
      items.every((item) => actionBarSelectedItemsContains(item)),
    [actionBarSelectedItemsContains, items]
  );

  const toggleAllChecked = () => {
    items.forEach((item) => {
      if (!hasEveryItemSelected) {
        addActionBarSelectedItem(item);
      } else {
        removeActionBarSelectedItems(item);
      }
    });
  };

  return (
    <Flex
      direction="column"
      bgColor="ui.white"
      borderRadius={2}
      borderWidth="1px"
      borderColor="ui.grey4"
    >
      <FilesFoldersHeading
        type={type}
        checkedCount={
          actionBarSelectedItems.filter(
            (item) => item.isDirectory === (type === 'folders')
          ).length
        }
        count={items.length}
        onAllChecked={toggleAllChecked}
        hasEveryItemSelected={hasEveryItemSelected}
      />
      <QueryStateGuard error={error} loading={loading}>
        <Flex direction="column" gap={2} p={4}>
          {items.map((item) =>
            libraryLocation === LibraryLocation.SharedWithMe ? (
              <ItemRowShared
                key={item.id}
                isChecked={actionBarSelectedItemsContains(item)}
                onCheckToggle={handleSelect}
                item={item}
              />
            ) : (
              <ItemRow
                key={item.id}
                isChecked={actionBarSelectedItemsContains(item)}
                onCheckToggle={handleSelect}
                item={item}
              />
            )
          )}
        </Flex>
      </QueryStateGuard>
    </Flex>
  );
};
