import { createContext, ReactNode, useContext } from 'react';
import { useParams } from 'react-router-dom';

import { QueryStateGuard } from 'components/QueryStateGuard';
import { useAdvisor } from 'gql/advisors';
import { AdvisorOutput } from 'types';

const AdvisorContext = createContext<AdvisorOutput | null>(null);

type AdvisorContextProviderProps = {
  children?: ReactNode;
};

export const AdvisorContextProvider = ({
  children,
}: AdvisorContextProviderProps): JSX.Element => {
  const { id } = useParams();
  if (!id) throw new TypeError('Advisor id is required');

  const { advisor, loading, error } = useAdvisor(id);

  return (
    <QueryStateGuard loading={loading} error={error}>
      {advisor && (
        <AdvisorContext.Provider value={advisor}>
          {children}
        </AdvisorContext.Provider>
      )}
    </QueryStateGuard>
  );
};

export const useAdvisorContext = (): AdvisorOutput => {
  const context = useContext(AdvisorContext);

  if (!context) {
    throw new Error('useAdvisorContext was used outside of its Provider');
  }

  return context;
};
