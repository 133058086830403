import { useGetDownloadUrlForSharedLibraryFile } from 'gql/library/query/getDownloadUrlForSharedLibraryFile';
import { LibraryMediaOutput, LibrarySharedMediaOutput } from 'types';

import { MenuElements } from './MenuElements';

interface Props {
  item: LibraryMediaOutput | LibrarySharedMediaOutput;
}

export const SharedMenuProvider = ({ item }: Props) => {
  const { sharedDownloadUrl } = useGetDownloadUrlForSharedLibraryFile(item);

  return MenuElements({ item, downloadUrl: sharedDownloadUrl });
};
