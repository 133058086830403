export enum FeatureFlag {
  SEARCH = 'SEARCH',
  LIBRARY = 'LIBRARY',
}

export const useFeatureFlag = (name: FeatureFlag): boolean => {
  if (process.env.NODE_ENV === 'development') {
    // all flags should be enabled on dev env
    // so that we don't need to remember to change them in this env
    return true;
  }

  const enabledFlags: string[] =
    process.env.REACT_APP_ENABLED_FEATURES?.split(',') || [];

  return enabledFlags.includes(name);
};
