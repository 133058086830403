import { useTranslation } from 'react-i18next';

import { Download16 } from '@carbon/icons-react';
import { Flex, Heading, Image, Text, Link } from '@chakra-ui/react';

import { ViewButton } from 'components/Button';

export const CARD_CAROUSEL_WIDTH = 308;

type Props = {
  direction?: 'column' | 'row';
  variant?: 'light' | 'dark';
  src?: string;
  title: string;
  subtitle?: string;
  bodyText: string;
  author?: string;
  findOutMoreUrl?: string;
  downloadFilePath?: string;
};

export const ImageTextTile = ({
  src,
  title,
  subtitle,
  bodyText,
  downloadFilePath,
  findOutMoreUrl,
  author,
  direction = 'column',
  variant = 'light',
}: Props) => {
  const { t } = useTranslation(['services', 'common']);

  const textColor = variant === 'light' ? 'primary.blue.default' : 'ui.white';
  const bgColor = variant === 'light' ? 'ui.white' : 'inherit';

  return (
    <Flex
      direction={direction}
      w={direction === 'column' ? CARD_CAROUSEL_WIDTH : 800}
      bgColor={bgColor}
      h="full"
    >
      <Image src={src} h={208} fit="cover" />
      <Flex p={4} direction="column" gap={4} flex={1}>
        <Flex direction="column" gap={1}>
          <Heading size="h4" color={textColor}>
            {title}
          </Heading>
          {subtitle && (
            <Heading size="h6" whiteSpace="pre-line" color={textColor}>
              {subtitle}
            </Heading>
          )}
        </Flex>
        <Flex direction="column" gap={2} flex={1}>
          <Text size="p1" color={textColor}>
            {bodyText}
          </Text>
          {author && (
            <Text size="p2" color={textColor}>
              {t('services:by-author', { name: author })}
            </Text>
          )}
        </Flex>
        <Flex justifyContent="space-between">
          {findOutMoreUrl && (
            <ViewButton
              to={findOutMoreUrl}
              variant={variant === 'light' ? 'blue' : 'white'}
            >
              {t('common:find-out-more')}
            </ViewButton>
          )}
          {downloadFilePath && (
            <Flex
              gap={2}
              as={Link}
              align="center"
              fontSize={14}
              transition="color 0.2s"
              fontWeight="normal"
              py={1}
              lineHeight={1}
              href={downloadFilePath}
              download
            >
              {t('common:download')} <Download16 />
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
