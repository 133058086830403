import { ReactNode, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { VStack, Text } from '@chakra-ui/react';

import { Loader } from 'components/Loader';

import { useFilesizeValidator, useHandleDrop } from '../hooks';
import { UploadProps } from '../types';

type Props = Pick<UploadProps, 'name' | 'maxFiles' | 'uploadAndUseId'> & {
  isImage: boolean;
  isInvalid: boolean;
  onTouch: () => void;
  maxSize: string;
  children?: ReactNode;
};

export const UploadField = ({
  name,
  isImage,
  isInvalid,
  onTouch,
  maxSize = '150MB',
  maxFiles = 1,
  uploadAndUseId = false,
  children,
}: Props) => {
  const [isFileOver, setIsFileOver] = useState(false);
  const filesizeValidator = useFilesizeValidator({ isImage, maxSize });

  const { handleDrop, loading } = useHandleDrop({
    name,
    maxFiles,
    uploadAndUseId,
  });

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    onFileDialogCancel: onTouch,
    onDragEnter: () => setIsFileOver(true),
    onDragLeave: () => setIsFileOver(false),
    maxFiles,
    multiple: maxFiles !== 1,
    accept: isImage
      ? { 'image/*': ['.jpeg', '.png'] }
      : { 'video/*': ['.mp4'] },
    validator: filesizeValidator,
  });

  const dropzoneProps = getRootProps();

  if (loading) return <Loader />;

  return (
    <VStack
      {...dropzoneProps}
      flex={1}
      cursor="pointer"
      padding={4}
      borderWidth="2px"
      borderRadius="2px"
      borderStyle="dashed"
      backgroundColor={isFileOver ? 'ui.grey4' : 'ui.white'}
      outline="none"
      transition="border 0.24s ease-in-out"
      borderColor={isInvalid ? 'negative' : 'ui.grey2'}
    >
      <input {...getInputProps()} id={name} name={name} />
      {children || (
        <Text size="p2" color="ui.grey2">
          Drag 'n' drop files here, or click to select your file
        </Text>
      )}
    </VStack>
  );
};
