import { string, object } from 'yup';

export const MESSAGE_MAX_LENGTH = 2000;

export const messageSchema = object().shape({
  message: string()
    .trim()
    .required('Message is required')
    .max(
      MESSAGE_MAX_LENGTH,
      `Message cannot be longer than ${MESSAGE_MAX_LENGTH} characters`
    ),
});
