import * as d3 from 'd3';

import { TickFormatter } from '.';

type Range = [number, number];

export enum Scale {
  Band,
  Linear,
}

export type AxisConfig<TDomain> = {
  domain?: TDomain;
  visible?: boolean;
  label?: string;
  format?: TickFormatter;
  hasTicks?: boolean;
  axisStroke?: string; // color of axis
  fontSize?: number;
  tickPadding?: number;
  ticksCount?: number;
};

export class Axis<TDomain extends Iterable<d3.NumberValue>> {
  public domain: TDomain;

  public range: Range;

  public scale: d3.AxisScale<d3.NumberValue>;

  private scaleType: Scale;

  readonly visible: boolean;

  readonly label?: string;

  readonly hasTicks: boolean;

  readonly format?: TickFormatter;

  readonly axisStroke: string;

  readonly fontSize: number;

  readonly tickPadding: number;

  readonly ticksCount?: number;

  constructor(config: AxisConfig<TDomain>, d: TDomain, r: Range, scale: Scale) {
    const {
      format,
      label,
      domain,
      ticksCount,
      axisStroke = '#000',
      hasTicks = true,
      visible = true,
      fontSize = 12,
      tickPadding = 16,
    } = config;

    this.scaleType = scale;
    this.visible = visible;
    this.format = format;
    this.label = label;
    this.hasTicks = hasTicks;
    this.axisStroke = axisStroke;
    this.fontSize = fontSize;
    this.tickPadding = tickPadding;
    this.ticksCount = ticksCount;

    this.domain = domain || d;
    this.range = r;

    switch (this.scaleType) {
      case Scale.Band:
        this.scale = d3.scaleBand(this.domain, this.range);
        break;
      case Scale.Linear:
      default:
        this.scale = d3.scaleLinear(this.domain, this.range);
        break;
    }
  }

  get bandwidth(): number {
    return this.scale.bandwidth?.() ?? 1;
  }
}
