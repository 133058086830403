import { gql, MutationHookOptions, useMutation } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationUpdateInitiativeVisibilityArgs } from 'types';

import { initiativeGql } from './useInitiative';
import { listInitiativesGql } from './useInitiatives';

export enum ViewingPermission {
  FAMILY = 'family',
  PUBLIC = 'public',
}

export type UpdateInitiativeVisibilityFormType = {
  id: string;
  familyOwnersIds: string[];
  itriom: boolean;
  connections: boolean;
  featured: boolean;
  family: boolean;
  viewingPermission: ViewingPermission | null;
};

export const updateInitiativeVisibilityGql = gql`
  mutation UpdateInitiativeVisibility(
    $visibility: InitiativeVisibilityInput!
    $id: String!
  ) {
    updateInitiativeVisibility(visibility: $visibility, id: $id) {
      id
    }
  }
`;

type TData = Pick<Mutation, 'updateInitiativeVisibility'>;
type TVariables = MutationUpdateInitiativeVisibilityArgs;

export const useUpdateInitiativeVisibility = (
  options?: MutationHookOptions<TData, TVariables>
) => {
  const [updateInitiativeVisibilityMutation, { loading }] = useMutation<
    TData,
    TVariables
  >(updateInitiativeVisibilityGql, {
    refetchQueries: [listInitiativesGql, initiativeGql],
    ...options,
  });

  const handleUpdate = (values: UpdateInitiativeVisibilityFormType) => {
    const { id, ...visibility } = values;

    return updateInitiativeVisibilityMutation({
      variables: {
        id,
        visibility: {
          family: visibility.family,
          itriom: visibility.itriom,
          familyOwnersIds: visibility.familyOwnersIds,
          featured: !!visibility.featured,
          connections: visibility.connections,
          isPublic: visibility.viewingPermission === ViewingPermission.PUBLIC,
        },
      },
    });
  };

  const updateInitiativeVisibility = useWithMutationToast(handleUpdate, {
    successMessage: 'Successfully updated visibility',
    errorMessage: 'Failed updating visibility',
  });

  return { updateInitiativeVisibility, loading };
};
