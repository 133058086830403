import {
  DEFAULT_HEIGHT,
  DEFAULT_MARGIN,
  DEFAULT_PADDING,
  DEFAULT_WIDTH,
} from '../constants';

/** In pixels */
type BoxSizing = {
  top: number;
  right: number;
  bottom: number;
  left: number;
};

export type DimensionsConfig = {
  width?: number;
  height?: number;
  margin?: Partial<BoxSizing>;
  padding?: Partial<BoxSizing>;
};

export class Dimensions {
  readonly width: number; // the outer width of the chart, in pixels

  readonly height: number; // the outer height of the chart, in pixels

  readonly margin: BoxSizing;

  readonly padding: BoxSizing;

  constructor(props?: DimensionsConfig) {
    const { height, width, margin, padding } = props || {};

    this.width = width ?? DEFAULT_WIDTH;
    this.height = height ?? DEFAULT_HEIGHT;

    this.margin = {
      top: margin?.top ?? DEFAULT_MARGIN.top,
      right: margin?.right ?? DEFAULT_MARGIN.right,
      bottom: margin?.bottom ?? DEFAULT_MARGIN.bottom,
      left: margin?.left ?? DEFAULT_MARGIN.left,
    };

    this.padding = {
      top: padding?.top ?? DEFAULT_PADDING.top,
      right: padding?.right ?? DEFAULT_PADDING.right,
      bottom: padding?.bottom ?? DEFAULT_PADDING.bottom,
      left: padding?.left ?? DEFAULT_PADDING.left,
    };
  }
}
