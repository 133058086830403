import {
  Insight,
  InsightBlockOutput,
  InsightBlockType,
  InsightFullMediaBlockOutput,
  InsightGalleryBlockOutput,
  InsightHeadingBlockOutput,
  InsightLeftTextBlockOutput,
  InsightQuoteBlockOutput,
  InsightRichTextBlockOutput,
  InsightRightTextBlockOutput,
} from './generated-types';

/** Related to fragment of the same name */
export type RelatedArticle = Pick<Insight, 'id'> & {
  hero: {
    headline: string;
    heroImage: {
      signedUrl?: string;
    };
  };
};

export enum InsightBlockId {
  RelatedArticles = 'relatedarticles',
  ArticleBody = 'content',
}

export type InsightHeadingBlock = InsightHeadingBlockOutput & {
  type: InsightBlockType.Heading;
};

export type InsightLeftTextBlock = InsightBlockOutput &
  Pick<InsightRightTextBlockOutput, 'optionalHeading' | 'text'> & {
    image?: InsightLeftTextBlockOutput['image'];
    type: InsightBlockType.LeftText;
  };

export type InsightRightTextBlock = InsightBlockOutput &
  Pick<InsightRightTextBlockOutput, 'optionalHeading' | 'text'> & {
    image?: InsightRightTextBlockOutput['image'];
    type: InsightBlockType.RightText;
  };

export type InsightFullMediaBlock = InsightBlockOutput & {
  image?: InsightFullMediaBlockOutput['image'];
  type: InsightBlockType.FullMedia;
};

export type InsightGalleryBlock = InsightGalleryBlockOutput & {
  type: InsightBlockType.Gallery;
};

export type InsightRichTextBlock = InsightRichTextBlockOutput & {
  type: InsightBlockType.RichText;
};

export enum QuoteAuthorType {
  None = 'none',
  Platform = 'platform',
  External = 'external',
}

export type InsightQuoteBlock = Pick<
  InsightQuoteBlockOutput,
  'order' | 'text' | 'customName' | 'customTitle' | 'account'
> & {
  accountId?: string;
  type: InsightBlockType.Quote;
  authorType: QuoteAuthorType;
};

export type InsightBlockUnion =
  | InsightHeadingBlock
  | InsightLeftTextBlock
  | InsightRightTextBlock
  | InsightFullMediaBlock
  | InsightGalleryBlock
  | InsightRichTextBlock
  | InsightQuoteBlock;

export type InsightInternal = Omit<Insight, 'blocks'> & {
  blocks: InsightBlockUnion[];
};
