import { useMemo } from 'react';

import { Option } from 'types';
import { timezones } from 'utils/date';

export const useTimezoneOptions = () => {
  const timezoneOptions = useMemo<Option[]>(
    () =>
      timezones.map((timezone) => ({
        label: `${timezone.abbr} ${timezone.text}`,
        value: timezone.id,
      })),
    []
  );

  return { timezoneOptions };
};
