import { useRemoveResourceInLibrary } from 'gql/library/mutations/removeResourceInLibrary';
import { useRemoveTrashFromLibrary } from 'gql/library/mutations/removeTrashFromLibrary';
import { useGetDownloadUrlForLibraryFileLazy } from 'gql/library/query/getDownloadUrlForLibraryFileLazy';

import { useLibraryContext } from '../context';
import { downloadFile } from '../utils/downloadFile';

export const useBulkActions = () => {
  const { actionBarSelectedItems, clearActionBarSelectedItems } =
    useLibraryContext();
  const { getDownloadUrl } = useGetDownloadUrlForLibraryFileLazy();
  const { removeResourceInLibrary } = useRemoveResourceInLibrary();
  const { removeTrashFromLibrary } = useRemoveTrashFromLibrary();

  const downloadMultipleItems = () => {
    actionBarSelectedItems
      .filter((item) => !item.isDirectory)
      .forEach(async (item) => {
        const { data } = await getDownloadUrl({
          variables: {
            fileId: item.id,
            libraryType: item.libraryType,
          },
        });

        if (!data?.getDownloadUrlForLibraryFile) {
          throw new Error('no download url');
        }

        downloadFile(data.getDownloadUrlForLibraryFile, item);
      });
    clearActionBarSelectedItems();
  };

  const deleteMultipleItems = () => {
    actionBarSelectedItems.forEach(async (item) => {
      await removeResourceInLibrary({
        libraryType: item.libraryType,
        resourceId: item.id,
      });
    });

    clearActionBarSelectedItems();
  };

  const removeMultipleTrashItems = () => {
    actionBarSelectedItems.forEach(async (item) => {
      await removeTrashFromLibrary({
        libraryType: item.libraryType,
        resourceId: item.id,
      });
    });
    clearActionBarSelectedItems();
  };

  return {
    downloadMultipleItems,
    deleteMultipleItems,
    removeMultipleTrashItems,
  };
};
