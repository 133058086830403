import { useEffect, useState } from 'react';

import { usePersonalSdgs } from 'hooks/onboarding/usePersonalSdgs';
import { useFamilySdgChoices } from 'hooks/sdgs/useFamilySdgChoices';

export const useOpenedSdgSections = () => {
  const [availableSections, setAvailableSections] = useState<number[]>([]);

  const { personalSdgs } = usePersonalSdgs();
  const { familySdgsChoices } = useFamilySdgChoices();

  useEffect(() => {
    if (personalSdgs) {
      setAvailableSections((prevState) => [...prevState, 3]);
    }

    if (familySdgsChoices) {
      setAvailableSections((prevState) => [...prevState, 4]);
    }
  }, [personalSdgs, familySdgsChoices]);

  const isThirdSectionVisible = availableSections.includes(3);
  const isFourthSectionVisible = availableSections.includes(4);

  return { isThirdSectionVisible, isFourthSectionVisible };
};
