import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';

import { Forum16 } from '@carbon/icons-react';
import {
  Text,
  Flex,
  Link,
  Box,
  Image,
  Grid,
  SimpleGrid,
  Button,
} from '@chakra-ui/react';

import { ItriomButtonOutline } from 'components/Button';
import { MESSAGE_CENTRE_ID } from 'features/Messaging';
import { isLinkMenuItem } from 'router/guards';
import { primaryMenu, Links } from 'router/links';
import { theme } from 'theme';

const FOOTER_LINK_STYLE = {
  fontSize: '12px',
  fontWeight: 400,
  textDecoration: 'underline',
  _hover: { textDecoration: 'none' },
};

export const Footer = (): JSX.Element => {
  const navigate = useNavigate();
  const { hash } = useLocation();

  const handleRedirect = () => {
    if (hash.includes(MESSAGE_CENTRE_ID)) {
      navigate(0);
    } else {
      navigate(`/#${MESSAGE_CENTRE_ID}`);
    }
  };

  return (
    <Flex w="full" bgColor="primary.blue.default" as="footer" py={10} px={4}>
      <SimpleGrid
        columns={{ base: 1, sm: 2 }}
        w="100%"
        maxW={theme.breakpoints.xl}
        color="ui.white"
        mx="auto"
      >
        <Flex direction="column" justifyContent="space-between">
          <Grid
            templateColumns={{
              base: '1fr 1fr',
              md: 'repeat(9, min-content)',
            }}
            columnGap={{ base: 133, sm: 7, md: 6 }}
            w={{ base: '50%', md: 'auto' }}
            fontSize="small"
          >
            {primaryMenu.map((menuItem) => {
              if (isLinkMenuItem(menuItem)) {
                return (
                  <Link
                    as={RouterLink}
                    to={menuItem.link}
                    key={menuItem.label}
                    flexBasis="25%"
                    mb={4}
                    sx={FOOTER_LINK_STYLE}
                  >
                    {menuItem.label}
                  </Link>
                );
              }

              return null;
            })}
          </Grid>
          <Box display={{ base: 'none', md: 'inherit' }}>
            <Link as={RouterLink} to={Links.home}>
              <Image h={6} src="/assets/logoWhite.svg" />
            </Link>
          </Box>
        </Flex>
        <Flex
          ml={{ base: 0, sm: 'auto' }}
          direction="column"
          justifyContent="space-between"
        >
          <ItriomButtonOutline
            bgColor="primary.lime.default"
            ml={{ md: 'auto' }}
            mb={10}
            rightIcon={<Forum16 />}
            maxW={177}
            borderRadius="4px"
            fontSize="sm"
            gap={3}
            onClick={handleRedirect}
          >
            Message area
          </ItriomButtonOutline>
          <Box mb={4} display={{ base: 'inherit', md: 'none' }}>
            <Link as={RouterLink} to={Links.home} sx={FOOTER_LINK_STYLE}>
              <Image
                h={6}
                src="/assets/logoWhite.svg"
                alt="Itriom logo white"
              />
            </Link>
          </Box>
          <Flex direction="row" columnGap={6}>
            <Link as={RouterLink} to={Links.termsOfUse} sx={FOOTER_LINK_STYLE}>
              Terms
            </Link>
            <Link as={RouterLink} to={Links.privacy} sx={FOOTER_LINK_STYLE}>
              Privacy
            </Link>
            <Button
              _hover={{ bgColor: 'none' }}
              p={0}
              h={4}
              as={Link}
              onClick={handleRedirect}
              sx={FOOTER_LINK_STYLE}
            >
              Itriom support
            </Button>
            <Text size="p2">© 2022 Itriom Limited</Text>
          </Flex>
        </Flex>
      </SimpleGrid>
    </Flex>
  );
};
