import { useOnboardingManagement } from 'hooks/onboarding/useOnboardManagement';

import { OnboardCopyType, SDG_INFLUENCES_BLOCK } from './constants';

export const useGetOnboardContent = () => {
  const { onboardManagement } = useOnboardingManagement();

  const role =
    onboardManagement?.members.length === 1
      ? OnboardCopyType.Individual
      : OnboardCopyType.Family;

  return SDG_INFLUENCES_BLOCK[role];
};
