import { CharterWithGoals } from 'components/CharterWithGoals';
import { ConnectionsPanel } from 'components/ConnectionsPanel';
import { LayoutGrid, LayoutGridElement } from 'components/Layout/Grid';
import { YourAdvisors } from 'components/YourAdvisors';
import { useAuthContext } from 'contexts/AuthContext';
import { MessagingCentre } from 'features/Messaging';
import { useHasConnections } from 'hooks/connections/useHasConnections';
import { hasSdg } from 'utils/roles';

import {
  Hero,
  InitiativeMap,
  KeyEvent,
  NewsList,
  UpcomingEvent,
} from '../components';

const DEFAULT_SECTION_HEIGHT = {
  FIRST: 370,
  SECOND: 600,
  THIRD: 300,
};

export const HomePage = () => {
  const { hasConnections } = useHasConnections();
  const { account } = useAuthContext();

  return (
    <LayoutGrid
      gridTemplateRows={{
        lg: `${DEFAULT_SECTION_HEIGHT.FIRST}px ${DEFAULT_SECTION_HEIGHT.SECOND}px auto auto auto`,
      }}
    >
      <LayoutGridElement
        colSpan={{ lg: 11, md: 8, sm: 4 }}
        gridRow={1}
        minH={DEFAULT_SECTION_HEIGHT.FIRST}
        shouldDisplay={hasSdg(account?.role)}
      >
        <Hero />
      </LayoutGridElement>

      <LayoutGridElement
        gridRow={{ base: 2, lg: 1 }}
        colSpan={{ lg: 5, md: 8, sm: 4 }}
        minH={DEFAULT_SECTION_HEIGHT.FIRST}
      >
        <KeyEvent />
      </LayoutGridElement>

      <LayoutGridElement
        colSpan={4}
        gridRow={{ base: 3, md: 3, lg: 2 }}
        minH={DEFAULT_SECTION_HEIGHT.SECOND}
        shouldDisplay={!!account?.familyId}
      >
        <CharterWithGoals h="full" />
      </LayoutGridElement>

      <LayoutGridElement
        colSpan={4}
        gridRow={{ base: 4, md: 3, lg: 2 }}
        minH={DEFAULT_SECTION_HEIGHT.SECOND}
      >
        <UpcomingEvent />
      </LayoutGridElement>

      <LayoutGridElement
        colSpan={{ lg: 8, md: 8, sm: 4 }}
        gridRow={{ base: 5, md: 4, lg: 2 }}
        minH={DEFAULT_SECTION_HEIGHT.SECOND}
      >
        <InitiativeMap />
      </LayoutGridElement>

      <LayoutGridElement
        colSpan={{ lg: 4, md: 8, sm: 4 }}
        gridRow={{ lg: 3 }}
        shouldDisplay={hasConnections}
        minH={DEFAULT_SECTION_HEIGHT.SECOND}
      >
        <ConnectionsPanel />
      </LayoutGridElement>

      <LayoutGridElement
        colSpan={{ base: 4, md: 8, lg: 12 }}
        gridRow={{ lg: 3 }}
        minH={DEFAULT_SECTION_HEIGHT.SECOND}
      >
        <MessagingCentre />
      </LayoutGridElement>

      <LayoutGridElement>
        <YourAdvisors variant="grey" />
      </LayoutGridElement>

      <LayoutGridElement>
        <NewsList />
      </LayoutGridElement>
    </LayoutGrid>
  );
};
