import { Link } from 'react-router-dom';

import { Avatar, Box, Flex, Text } from '@chakra-ui/react';

import { useEsgCategories } from 'hooks/useEsgCategories';
import { Links } from 'router/links';
import { GroupInternalType } from 'types';
import { generateUrlById } from 'utils/links';

import { GroupMenu } from './GroupMenu';

export type SingleGroupCardProps = {
  variant?: 'primary' | 'secondary';
  group: GroupInternalType;
  withEsgCategory?: boolean;
  withBorder?: boolean;
};

export const SingleGroupCard = ({
  variant = 'primary',
  group,
  withEsgCategory,
  withBorder = true,
}: SingleGroupCardProps) => {
  const { id, name, avatarImage } = group;

  const { getEsgCategory } = useEsgCategories();

  return (
    <Flex
      as={Link}
      to={generateUrlById(Links.groupsSingle, id)}
      key={id}
      p={2}
      pr={10}
      w="full"
      gap={2}
      border={withBorder ? 'default' : 'none'}
      background={variant === 'primary' ? 'ui.white' : 'transparent'}
      transition="300ms ease background"
      alignItems="center"
      position="relative"
      title={name}
      aria-label="group card"
      _hover={{ background: variant === 'primary' ? 'ui.grey4' : 'ui.white' }}
    >
      <Avatar size="sm" name={name} src={avatarImage?.signedUrl} />
      <Flex direction="column">
        <Text size="p3" noOfLines={2}>
          {name}
        </Text>
        {withEsgCategory && (
          <Text size="p3">{getEsgCategory(group.category)?.name}</Text>
        )}
      </Flex>

      <Box pos="absolute" right={2}>
        <GroupMenu group={group} iconVariant="dark" />
      </Box>
    </Flex>
  );
};
