import { gql } from '@apollo/client';

import { AdvisorOutputDeepNestingFragment } from 'types/generated-fragments';

export const advisorGql = gql`
  ${AdvisorOutputDeepNestingFragment}
  query Advisor($advisorId: String!) {
    advisor(id: $advisorId) {
      ...AdvisorOutputDeepNestingFragment
    }
  }
`;

export const advisorByRelatedAccountIdGql = gql`
  ${AdvisorOutputDeepNestingFragment}
  query AdvisorByRelatedAccountId($advisorByRelatedAccountId: String!) {
    advisorByRelatedAccountId(id: $advisorByRelatedAccountId) {
      ...AdvisorOutputDeepNestingFragment
    }
  }
`;

export const listEditableAdvisorsGql = gql`
  ${AdvisorOutputDeepNestingFragment}
  query ListEditableAdvisors(
    $filters: ListEditableAdvisorInput = { statuses: [PUBLISHED, DRAFT] }
    $pagination: PaginationInput = { offset: 0, limit: 100 }
  ) {
    listEditableAdvisors(filters: $filters, pagination: $pagination) {
      nodes {
        ...AdvisorOutputDeepNestingFragment
      }
      offset
      totalCount
    }
  }
`;
