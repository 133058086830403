import { gql, useSubscription } from '@apollo/client';

import { Message, Subscription } from 'types';

export const messageSubscriptionGql = gql`
  subscription MessageSubscription {
    messageSubscription {
      payload {
        id
        createdAt
        updatedAt
        createdBy
        updatedBy
        text
        conversationId
        recipientIds
        deletedBy
      }
    }
  }
`;

type Props = {
  canSubscribe?: boolean;
  onNextData: (data: Message | undefined) => void;
};

export const useSubscribeToMessages = ({ canSubscribe, onNextData }: Props) => {
  useSubscription<Pick<Subscription, 'messageSubscription'>>(
    messageSubscriptionGql,
    {
      skip: !canSubscribe,
      onSubscriptionData: (d) =>
        onNextData(d.subscriptionData.data?.messageSubscription.payload),
    }
  );
};
