import { NavLink } from 'react-router-dom';

import { Box } from '@chakra-ui/react';

import { colors } from 'theme/colors';
import { WithChildren } from 'types';

interface Props {
  to: string;
  withBorder?: boolean;
}

export const NavigationLink = ({
  to,
  children,
  withBorder,
}: WithChildren<Props>) => {
  return (
    <NavLink
      to={to}
      style={({ isActive }: { isActive: boolean }) =>
        isActive ? { backgroundColor: colors.ui.grey4 } : {}
      }
    >
      <Box
        py={6}
        mx={4}
        borderColor="ui.grey4"
        borderTopWidth={withBorder ? 1 : 0}
      >
        {children}
      </Box>
    </NavLink>
  );
};
