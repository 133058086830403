import { useTranslation } from 'react-i18next';

import {
  Flex,
  Tabs,
  TabList,
  Tab,
  Text,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react';

import { ClearableSearchInput } from 'components/Form/ClearableSearchInput';
import { Modal } from 'components/Modal';
import { QueryStateGuard } from 'components/QueryStateGuard';
import { useLibraryContext } from 'features/Library/context';
import { useAllConnections } from 'hooks/connections/useAllConnections';

import { MemberRow } from './MemberRow';

export const ShareModal = () => {
  const { shareModal, actionBarSelectedItems, actionSingleItem } =
    useLibraryContext();
  const { t } = useTranslation(['library', 'common']);

  const {
    allConnections,
    familyConnections,
    peerConnections,
    error,
    loading,
    filter,
  } = useAllConnections();

  const sharableItems = actionSingleItem
    ? [actionSingleItem]
    : actionBarSelectedItems;

  return (
    <Modal
      isOpen={shareModal.isOpen}
      onClose={shareModal.onClose}
      title={t('library:share.title', {
        count: sharableItems.length,
        fileName: sharableItems[0]?.name,
      })}
    >
      <ClearableSearchInput
        loading={false}
        onQueryChange={filter}
        placeholder={t('library:share.placeholder')}
      />
      <Tabs isFitted>
        <TabList>
          <Tab>{t('common:all')}</Tab>
          <Tab>{t('common:family')}</Tab>
          <Tab>{t('common:connections')}</Tab>
        </TabList>
        <TabPanels>
          <TabPanel
            as={Flex}
            h={234}
            gap={2}
            flexDirection="column"
            px={0}
            py={4}
            overflowY="auto"
          >
            <QueryStateGuard loading={loading} error={error}>
              {allConnections.length ? (
                allConnections.map((profile) => (
                  <MemberRow
                    key={profile.id}
                    profile={profile}
                    items={sharableItems}
                  />
                ))
              ) : (
                <Text>{t('library:share.no-connections')}</Text>
              )}
            </QueryStateGuard>
          </TabPanel>
          <TabPanel
            as={Flex}
            h={234}
            gap={2}
            flexDirection="column"
            px={0}
            py={4}
            overflowY="auto"
          >
            <QueryStateGuard loading={loading} error={error}>
              {familyConnections.length ? (
                familyConnections.map((profile) => (
                  <MemberRow
                    key={profile.id}
                    profile={profile}
                    items={sharableItems}
                  />
                ))
              ) : (
                <Text>{t('library:share.no-connections')}</Text>
              )}
            </QueryStateGuard>
          </TabPanel>
          <TabPanel
            as={Flex}
            h={234}
            gap={2}
            flexDirection="column"
            px={0}
            py={4}
            overflowY="auto"
          >
            <QueryStateGuard loading={loading} error={error}>
              {peerConnections.length ? (
                peerConnections.map((profile) => (
                  <MemberRow
                    key={profile.id}
                    profile={profile}
                    items={sharableItems}
                  />
                ))
              ) : (
                <Text>{t('library:share.no-connections')}</Text>
              )}
            </QueryStateGuard>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Modal>
  );
};
