import { gql, useQuery } from '@apollo/client';

import { Event } from 'gql/fragments';
import { Query, QueryKeyEventsArgs } from 'types';

export const keyEventsGql = gql`
  ${Event}
  query KeyEvents($pagination: PaginationInput!) {
    keyEvents(pagination: $pagination) {
      nodes {
        ...Event
      }
      offset
      totalCount
    }
  }
`;

export const useKeyEvents = () => {
  const { data, loading, error } = useQuery<
    Pick<Query, 'keyEvents'>,
    QueryKeyEventsArgs
  >(keyEventsGql, { variables: { pagination: { limit: 3, offset: 0 } } });
  const keyEvents = data?.keyEvents.nodes || [];

  return { keyEvents, loading, error };
};
