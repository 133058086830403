import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Flex, Heading } from '@chakra-ui/layout';
import { Grid, Text } from '@chakra-ui/react';

import { ViewButton } from 'components/Button';
import { SdgIcon } from 'components/Icons/SdgIcon';
import { Panel } from 'components/Layout/Panel';
import { QueryStateGuard } from 'components/QueryStateGuard';
import { useAuthContext } from 'contexts/AuthContext';
import { useFamilyCharterByFamilyId } from 'hooks/familyCharter';
import { Links } from 'router/links';
import { FamilyCharter } from 'types';

const Content = ({ familyCharter }: { familyCharter?: FamilyCharter }) => {
  const { t } = useTranslation(['family']);
  const { account } = useAuthContext();

  if (!account?.familyId) {
    return (
      <Flex w="full" bgColor="ui.white" p={2}>
        You don't have family
      </Flex>
    );
  }

  if (!familyCharter) {
    return (
      <Flex w="full" bgColor="ui.white" p={2}>
        You don't have family charter
      </Flex>
    );
  }

  const { goals } = familyCharter;

  if (goals.length === 0) {
    return (
      <Flex w="full" bgColor="ui.white" p={2}>
        Your family don't have goals
      </Flex>
    );
  }

  return (
    <Grid flex={1} gap={4} templateRows="repeat(3, 1fr)" h="full">
      {goals.map((goal, index) => (
        <Grid
          bg="ui.white"
          w="100%"
          key={goal.id}
          as={Link}
          to={Links.charter}
          gridTemplateColumns="repeat(2, 1fr)"
          gridAutoFlow="column"
          alignItems="center"
          gap={4}
        >
          <SdgIcon
            name={goal.sdg.internalName}
            variant="bgImage"
            additionalStyling={{
              w: '100%',
              h: '100%',
              objectFit: 'cover',
            }}
          />
          <Flex direction="column" gap={4}>
            <SdgIcon
              name={goal.sdg.internalName}
              variant="outline"
              additionalStyling={{ w: '3rem' }}
            />
            <Flex direction="column">
              <Heading size="h4">
                {t('family:charter.goal', { number: index + 1 })}
              </Heading>

              <Text size="p2" noOfLines={2}>
                {goal.description}
              </Text>
            </Flex>
          </Flex>
        </Grid>
      ))}
    </Grid>
  );
};

type Props = {
  h?: 'full' | 'fit-content';
};

export const CharterWithGoals = ({ h = 'fit-content' }: Props) => {
  const { familyCharter, error, loading } = useFamilyCharterByFamilyId();

  return (
    <Panel
      title="Charter"
      action={
        <ViewButton variant="blue" to={Links.charter}>
          View charter
        </ViewButton>
      }
      h={h}
    >
      <Flex flex={1}>
        <QueryStateGuard loading={loading} error={error}>
          <Content familyCharter={familyCharter} />
        </QueryStateGuard>
      </Flex>
    </Panel>
  );
};
