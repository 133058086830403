import { useCallback } from 'react';

import { gql } from '@apollo/client';

import {
  GroupNotificationOutputFragment,
  NewFamilyContentOutputFragment,
  PostNotificationOutputFragment,
  InitiativeNotificationOutputFragment,
} from 'gql/notifications/fragments';
import { usePaginatedQuery } from 'hooks/pagination';
import {
  NotificationCategoryEnum,
  PaginatedNotificationUnion,
  QueryNotificationsArgs,
} from 'types';
import {
  ConnectionAcceptedNotificationOutputFragment,
  ConnectionSentNotificationOutputFragment,
  ContentDeletedByAdminOutputFragment,
  LibraryResourceSharedOutputFragment,
  GroupInvitationSentNotificationOutputFragment,
  MessageSentNotificationOutputFragment,
} from 'types/generated-fragments';

const senderWithImage = `
sender {
  id
  firstName
  lastName
  title
  role
  avatarImage {
    signedUrl
  }
}
`;

export const notificationsGql = gql`
  ${ConnectionSentNotificationOutputFragment}
  ${ConnectionAcceptedNotificationOutputFragment}
  ${LibraryResourceSharedOutputFragment}
  ${GroupInvitationSentNotificationOutputFragment}
  ${GroupNotificationOutputFragment}
  ${MessageSentNotificationOutputFragment}
  ${ContentDeletedByAdminOutputFragment}
  ${PostNotificationOutputFragment}
  ${NewFamilyContentOutputFragment}
  ${InitiativeNotificationOutputFragment}
  query Notifications($pagination: PaginationInput!, $category: NotificationCategoryEnum) {
    notifications(pagination: $pagination, category: $category) {
      offset
      totalCount
      totalUnreadCount
      nodes {
        ... on ConnectionSentNotificationOutput {
          ...ConnectionSentNotificationOutputFragment
          ${senderWithImage}
          }
        ... on ConnectionAcceptedNotificationOutput {
          ...ConnectionAcceptedNotificationOutputFragment
          ${senderWithImage}
          }
        ... on LibraryResourceSharedOutput  {
          ...LibraryResourceSharedOutputFragment
          ${senderWithImage}
          }
        ... on GroupInvitationSentNotificationOutput {
          ...GroupInvitationSentNotificationOutputFragment
          ${senderWithImage}
          }
        ... on GroupNotificationOutput {
          ...GroupNotificationOutputFragment
          ${senderWithImage}
          }
        ... on MessageSentNotificationOutput {
          ...MessageSentNotificationOutputFragment
          ${senderWithImage}
          }
        ... on ContentDeletedByAdminOutput {
          ...ContentDeletedByAdminOutputFragment,
          ${senderWithImage}
        }
        ... on PostNotificationOutput {
          ...PostNotificationOutputFragment,
          ${senderWithImage}
        }
        ... on NewFamilyContentOutput {
          ...NewFamilyContentOutputFragment,
          ${senderWithImage}
        }
        ... on InitiativeNotificationOutput {
          ...InitiativeNotificationOutputFragment
          ${senderWithImage}
        }
      }
    }
  }
`;

const NOTIFICATIONS_DEFAULT_OFFSET = 0;
const NOTIFICATIONS_DEFAULT_LIMIT = 10;

const DEFAULT_PAGINATION_VARIABLES = {
  offset: NOTIFICATIONS_DEFAULT_OFFSET,
  limit: NOTIFICATIONS_DEFAULT_LIMIT,
};

export const useLoadNotifications = (category?: NotificationCategoryEnum) => {
  const { initialLoad, loadMore } = usePaginatedQuery<
    { notifications: PaginatedNotificationUnion },
    QueryNotificationsArgs
  >(notificationsGql, DEFAULT_PAGINATION_VARIABLES);

  const loadNotifications = useCallback(
    () => initialLoad({ category }).then((result) => result.notifications),
    [category, initialLoad]
  );

  const loadMoreNotifications = useCallback(
    () => loadMore({ category }).then((result) => result.notifications),
    [category, loadMore]
  );

  return {
    initialLoad: loadNotifications,
    loadMore: loadMoreNotifications,
  };
};
