import { Link as RouterLink } from 'react-router-dom';

import { AvatarProps, Avatar, Text, Link, HStack, Box } from '@chakra-ui/react';

import { Links } from 'router/links';
import { getAvatarSrc } from 'utils/file';
import { generateUrlById } from 'utils/links';
import { isItriomStaff } from 'utils/roles';

import { ProfileCardProps } from './types';

type Props = Pick<ProfileCardProps, 'profile'> & AvatarProps;

export const MessageProfile = ({
  profile,
  ...profileProps
}: Props): JSX.Element => {
  const toLink = isItriomStaff(profile.role)
    ? '#'
    : generateUrlById(Links.profile, profile.id);

  return (
    <Link as={RouterLink} to={toLink} w="100%">
      <HStack
        justify="flex-start"
        p={2}
        bg="ui.white"
        w="100%"
        _hover={{ bg: 'base3' }}
        {...profileProps}
      >
        <Avatar src={getAvatarSrc(profile.avatarImage)} size="sm" />
        <Box>
          <Text size="p2">
            {profile.firstName} {profile.lastName}
          </Text>
          <Text size="p3">{profile.title}</Text>
          <Text size="p3">{profile.family?.name}</Text>
        </Box>
      </HStack>
    </Link>
  );
};
