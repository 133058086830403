import { gql, useMutation } from '@apollo/client';

import { useAuthContext } from 'contexts/AuthContext';
import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { createMock, eventMock } from 'mocks';
import { Mutation, MutationSendCtaArgs, TriggeredCtaEnum } from 'types';

import { createTemplate } from '../utils';

export const JoinSuccessMessage =
  'Thank you for your interest. Itriom has received your request to join the EVENT. You will soon receive a confirmation from Itriom by email.';
export const RequestHelpSuccessMessage =
  'Thank you for your inquiry. Itriom has received your request and will endeavour to respond as soon as possible. You will soon receive an email from Itriom.';

export const sendCtaGql = gql`
  mutation SendCta($data: TriggeredCtaInput!) {
    sendCta(data: $data) {
      id
    }
  }
`;

export const sendEmailMock = (type: TriggeredCtaEnum) =>
  createMock<Pick<Mutation, 'sendCta'>, MutationSendCtaArgs>(
    sendCtaGql,
    { data: { sendCta: { id: eventMock.id } } },
    {
      data: {
        content:
          "@User requested for more information about EVENT: \n    name: Reneawable wind and solar energy in Ukraine\n    id: 624c20998477a5767e8952bc\n\n    User's email address is: adi@itriom.com\n    User's name is: Adi Godrej\n    \n    Please contact the customer as soon as possible. \n    User's message is:\n    Hello",
        id: '624c20998477a5767e8952bc',
        type,
      },
    }
  );

export const useCtaEmail = (
  type: TriggeredCtaEnum,
  eventId: string,
  eventHeading: string
) => {
  const { account } = useAuthContext();
  const [sendEmailMutation] = useMutation<
    Pick<Mutation, 'sendCta'>,
    MutationSendCtaArgs
  >(sendCtaGql);

  const sendEmail = (message?: string) => {
    if (!account) {
      throw new Error('No account found');
    }

    const content = createTemplate({
      type,
      account,
      eventId,
      eventHeading,
      userMessage: message,
    });

    const variables: MutationSendCtaArgs = {
      data: {
        content,
        id: eventId,
        type,
      },
    };

    return sendEmailMutation({ variables });
  };

  const sendEmailWithToast = useWithMutationToast(sendEmail, {
    successMessage:
      type === TriggeredCtaEnum.Join
        ? JoinSuccessMessage
        : RequestHelpSuccessMessage,
    errorMessage: 'Error during sending an email',
  });

  return { sendEmail: sendEmailWithToast };
};
