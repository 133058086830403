import { Flex, Heading } from '@chakra-ui/react';

import { MarkdownPreview } from 'components/MarkdownPreview';
import { Event } from 'types';

import { EventBodyElement } from '../../EventBodyElement';

interface Props {
  event: Event;
}

export const SummarySection = ({ event }: Props) => {
  if (!event.hero.summary && !event.hero.description) return null;

  return (
    <EventBodyElement>
      <Flex direction="column" gap={4}>
        {event.hero.summary && (
          <Heading size="h3">{event.hero.summary}</Heading>
        )}

        {event.hero.description && (
          <MarkdownPreview content={event.hero.description} />
        )}
      </Flex>
    </EventBodyElement>
  );
};
