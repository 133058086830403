import { Form, FormikProvider, useFormik } from 'formik';

import { useTranslation } from 'react-i18next';

import { Button, Flex } from '@chakra-ui/react';

import { TextInput } from 'components/Form/TextInput';
import { Modal } from 'components/Modal';
import { useSearchContext } from 'contexts/SearchContext';
import { useCurrentLibraryLocation } from 'features/Library/hooks/useCurrentLibraryLocation';
import { LibraryLocation } from 'features/Library/types';
import { mapLibraryLocationToLibraryType } from 'features/Library/utils/mapLibraryLocationToLibraryType';
import { useCreateDirectoryInLibrary } from 'gql/library/mutations/createDirectoryInLibrary';
import { useRenameResourceInLibrary } from 'gql/library/mutations/renameDirectoryInLibrary';
import { useGetResourceDetailsInLibrary } from 'gql/library/query/getResourceDetailsInLibrary';
import { LibraryTypeEnum } from 'types';

const FIELD_NAME = 'folderName';

interface FormValues {
  [FIELD_NAME]: string;
}

interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
  type: 'rename' | 'create';
  currentData?: {
    resourceId: string;
    name: string;
    extension: string | undefined;
    libraryType?: LibraryTypeEnum;
  };
}

export const FolderNameModal = ({
  isOpen,
  onClose,
  type,
  currentData,
}: Props) => {
  const { t } = useTranslation(['library', 'common']);

  const formik = useFormik<FormValues>({
    initialValues: { [FIELD_NAME]: currentData?.name || '' },
    onSubmit: () => Promise.resolve(),
  });

  const { libraryLocation, folderId } = useCurrentLibraryLocation();
  const { createDirectoryInLibrary } = useCreateDirectoryInLibrary();
  const { renameResourceInLibrary } = useRenameResourceInLibrary();
  const { refetch } = useGetResourceDetailsInLibrary(
    mapLibraryLocationToLibraryType(undefined),
    undefined
  );
  const { searchResults, setFixedSearchResults, fixedSearchResults } =
    useSearchContext();

  const handleSubmit = async () => {
    let libraryType;

    if (
      libraryLocation === LibraryLocation.SearchLibraryContext &&
      currentData?.libraryType
    ) {
      libraryType = currentData?.libraryType;
    } else {
      libraryType = mapLibraryLocationToLibraryType(libraryLocation);
    }

    if (!libraryType) {
      throw new Error('Library type not handled');
    }

    if (type === 'create') {
      createDirectoryInLibrary({
        libraryType,
        destinationId: folderId,
        directoryName: formik.values[FIELD_NAME],
      });
    } else if (currentData?.resourceId) {
      await renameResourceInLibrary({
        libraryType,
        resourceId: currentData.resourceId,
        newName:
          currentData.extension !== undefined
            ? `${formik.values[FIELD_NAME]}${currentData.extension}`
            : formik.values[FIELD_NAME],
      });

      try {
        const newItem = await refetch({
          libraryType,
          resourceId: currentData.resourceId,
        });
        let resultsWithoutChangedItem;

        if (fixedSearchResults) {
          resultsWithoutChangedItem = fixedSearchResults.filter(
            (element) => element.object.id !== currentData.resourceId
          );
        } else {
          resultsWithoutChangedItem = searchResults.library?.nodes.filter(
            (element) => element.object.id !== currentData.resourceId
          );
        }

        if (resultsWithoutChangedItem) {
          setFixedSearchResults([
            {
              object: newItem.data.getResourceDetailsInLibrary,
              highlights: {},
            },
            ...resultsWithoutChangedItem,
          ]);
        }
      } catch (err) {
        // ignore
      }
    }

    formik.resetForm();
    onClose();
  };

  return (
    <Modal
      title={t(`library:folder.${type}-modal-title`)}
      isOpen={isOpen}
      onClose={onClose}
      closeButtonVisible={false}
      footer={
        <Flex justifyContent="space-between" w="full">
          <Button variant="secondaryBlue" onClick={onClose}>
            {t('common:cancel')}
          </Button>
          <Button
            variant="primaryBlue"
            onClick={handleSubmit}
            isDisabled={!!formik.errors[FIELD_NAME]}
          >
            {t(`library:folder.${type}`)}
          </Button>
        </Flex>
      }
    >
      <FormikProvider value={formik}>
        <Form>
          <TextInput
            name={FIELD_NAME}
            label={t('library:folder.input-label')}
          />
        </Form>
      </FormikProvider>
    </Modal>
  );
};
