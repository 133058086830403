import { stubString } from 'lodash';

import { Link } from 'react-router-dom';

import { Folder } from '@carbon/icons-react/next';
import { Flex, Text } from '@chakra-ui/react';

import { concat } from 'utils/string';

export const FolderLink = ({
  isDeep,
  isShared,
  path,
}: {
  isDeep: boolean;
  isShared: boolean;
  path: { url: string; label: string };
}) => {
  return (
    <Link to={path.url}>
      <Flex
        alignItems="center"
        gap={2}
        _hover={{ textDecoration: 'underline' }}
      >
        <Folder />
        <Text>
          {isShared && isDeep ? concat(path.label, 12) : concat(path.label, 25)}
        </Text>
      </Flex>
    </Link>
  );
};
