import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

import { SdgIcon } from 'components/Icons/SdgIcon';
import { SGDS_OVERVIEW } from 'constants/Sdgs';
import { SdgInternalName } from 'types/Sdg';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  sdgInternalName: SdgInternalName;
};

export const SdgModal = ({ isOpen, onClose, sdgInternalName }: Props) => {
  const { color, description } = SGDS_OVERVIEW[sdgInternalName];

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay bg="#0A1E40BF" backdropFilter="auto" backdropBlur="6px" />
      <ModalContent bgColor={color} p={4} borderRadius={8}>
        <ModalHeader>
          <HStack justifyContent="space-between" alignItems="flex-start">
            <SdgIcon
              name={sdgInternalName}
              additionalStyling={{ h: 32, alt: sdgInternalName }}
            />
            <Button
              variant="outline"
              w={24}
              borderRadius="1px"
              _hover={{ color, bgColor: 'ui.white' }}
              onClick={onClose}
            >
              <Text size="p1" color="ui.white">
                Close
              </Text>
            </Button>
          </HStack>
        </ModalHeader>
        <ModalBody>
          <Text color="ui.white" size="p1">
            {description}
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
