import { Comment } from 'types';
import { getUpdateFileValue } from 'utils/file';

import { CommentFormBase } from './CommentFormBase';
import { CommentFormConfigProps, FormValues } from './types';
import { useUpdateComment } from './useUpdateComment';

type Props = CommentFormConfigProps & {
  comment: Comment;
  onUpdate?: VoidFunction;
};

export const EditCommentForm = ({ comment, onUpdate, ...baseProps }: Props) => {
  const { handleUpdateComment } = useUpdateComment();

  const { id, referenceId, images = [], text } = comment;

  const initialValues: FormValues = {
    text,
    upload: images,
  };

  const handleSubmit = async (values: FormValues) => {
    await handleUpdateComment({
      images: getUpdateFileValue(values.upload),
      comment: { id, referenceId, text: values.text },
    });

    if (onUpdate) {
      onUpdate();
    }
  };

  return (
    <CommentFormBase
      {...baseProps}
      handleSubmit={handleSubmit}
      initialValues={initialValues}
    />
  );
};
