import { Loader } from 'components/Loader';
import { ValuesSummary } from 'components/ValuesSummary';

import { useFamilyNormalizedValues } from './useFamilyNormalizedValues';

export const FamilyValues = (): JSX.Element => {
  const { normalizedFamilyValues, error, loading } =
    useFamilyNormalizedValues();

  if (loading) {
    return <Loader />;
  }

  return (
    <ValuesSummary
      error={error}
      normalizedValues={normalizedFamilyValues}
      title="Family Values"
      subtitle="Family Combined scores displayed"
      description="Itriom has grouped values relevant to families, into four categories that capture both the areas of purpose for the family and enablers for achieving the purpose."
    />
  );
};
