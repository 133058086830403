import { EXTENSION_REGEX } from 'features/Library/constants';
import { useLibraryContext } from 'features/Library/context';

import { FolderNameModal } from '../FolderNameModal';

export const RenameModal = () => {
  const { renameModal } = useLibraryContext();

  if (!renameModal.item) {
    return null;
  }

  const getFileNameWithoutExtension = (fileName: string) => {
    return fileName.replace(EXTENSION_REGEX, '');
  };

  const getExtensionFromFileName = (fileName: string) => {
    return fileName.match(EXTENSION_REGEX)?.[0];
  };

  return (
    <FolderNameModal
      type="rename"
      currentData={{
        resourceId: renameModal.item.id,
        name: getFileNameWithoutExtension(renameModal.item.name),
        extension: getExtensionFromFileName(renameModal.item.name),
        libraryType: renameModal.item.libraryType,
      }}
      onClose={renameModal.onClose}
      isOpen={renameModal.isOpen}
    />
  );
};
