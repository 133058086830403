import { useQuery } from '@apollo/client';

import {
  LibraryTypeEnum,
  Query,
  QueryGetResourceDetailsInLibraryArgs,
} from 'types';

import { qetResourceDetailsInLibraryGql } from '../documents';

export const useGetResourceDetailsInLibrary = (
  libraryType?: LibraryTypeEnum,
  resourceId?: string
) => {
  const { data, error, loading, refetch } = useQuery<
    Pick<Query, 'getResourceDetailsInLibrary'>,
    Partial<QueryGetResourceDetailsInLibraryArgs>
  >(qetResourceDetailsInLibraryGql, {
    variables: {
      libraryType,
      resourceId,
    },
    skip: !libraryType || !resourceId,
  });

  const details = data?.getResourceDetailsInLibrary;

  return {
    details,
    loading,
    error,
    refetch,
  };
};
