import { Category, Value } from './types';

export const values: Record<Category, Value> = {
  [Category.Unity]: {
    iconUrl: '/assets/icons/values/categories/unity.svg',
    title: 'Unity',
    description:
      '**Values represents** a family’s view of what is desirable and important.',
    color: '#89C3D3',
  },
  [Category.Goals]: {
    iconUrl: '/assets/icons/values/categories/goals.svg',
    title: 'Goals',
    description:
      '**Values identify** the collective motivations and goals the family are striving to attain.',
    color: '#5B869E',
  },
  [Category.Culture]: {
    iconUrl: '/assets/icons/values/categories/culture.svg',
    title: 'Culture',
    description:
      '**Values inform** the family’s culture and approach in achieving their ambitions.',
    color: '#56BD8F',
  },
  [Category.Direction]: {
    iconUrl: '/assets/icons/values/categories/direction.svg',
    title: 'Direction',
    description:
      '**Well defined values** therefore act as a glue that binds the family together in consistent direction, behaviours and action.',
    color: '#F9C950',
  },
};
