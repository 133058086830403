import { QueryStateGuard } from 'components/QueryStateGuard';

import { ConnectionList } from '../ConnectionList';
import { ConnectionTab } from './types';
import { useConnections } from './useConnections';

type Props = {
  type: ConnectionTab;
};

export const ConnectionTabPanel = ({ type }: Props) => {
  const { connections, loading, error } = useConnections(type);

  return (
    <QueryStateGuard loading={loading} error={error}>
      <ConnectionList connections={connections} type={type} />
    </QueryStateGuard>
  );
};
