export const MESSAGING_DEFAULT_LIMIT = 10;
export const MESSAGING_DEFAULT_OFFSET = 0;

export type ConversationPaginationVariables = {
  filters: {
    limit: number;
    offset: number;
  };
  messagesFilters: {
    limit: number;
    offset: number;
  };
};

export const initConversationsVariables: ConversationPaginationVariables = {
  filters: {
    limit: MESSAGING_DEFAULT_LIMIT,
    offset: MESSAGING_DEFAULT_OFFSET,
  },
  messagesFilters: {
    limit: MESSAGING_DEFAULT_LIMIT,
    offset: MESSAGING_DEFAULT_OFFSET,
  },
};
