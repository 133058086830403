import { gql, useQuery } from '@apollo/client';

import { createMock, valueConfiguratorValuesMock } from 'mocks';
import { Query } from 'types';
import { ValueConfiguratorNormalizedOutputDeepNestingFragment } from 'types/generated-fragments';

export const valueConfiguratorCategoryNormalizedGql = gql`
  ${ValueConfiguratorNormalizedOutputDeepNestingFragment}
  query ValueConfiguratorCategoryNormalized {
    valueConfiguratorCategoryNormalized {
      ...ValueConfiguratorNormalizedOutputDeepNestingFragment
    }
  }
`;

export const normalizedValuesMock = createMock<
  Pick<Query, 'valueConfiguratorCategoryNormalized'>
>(valueConfiguratorCategoryNormalizedGql, {
  data: { valueConfiguratorCategoryNormalized: valueConfiguratorValuesMock },
});

export const useNormalizedValues = () => {
  const { data, error, loading } = useQuery<
    Pick<Query, 'valueConfiguratorCategoryNormalized'>
  >(valueConfiguratorCategoryNormalizedGql);

  return {
    normalizedValues: data?.valueConfiguratorCategoryNormalized || {},
    error,
    loading,
  };
};
