import { ReactNode } from 'react';

import { Box, Heading, Text } from '@chakra-ui/react';

type Props = {
  title?: string;
  children?: ReactNode;
};

export const Error = ({
  title = 'Something went wrong',
  children = 'Please try again later or contact us if you need help.',
}: Props): JSX.Element => {
  return (
    <Box mt={2} p={2}>
      <Heading
        size="h2"
        pb={4}
        mb={3}
        borderBottom="1px solid"
        borderColor="primary.blue.default"
      >
        {title}
      </Heading>
      <Text>{children}</Text>
    </Box>
  );
};
