import { useGetResourceDetailsInLibrary } from 'gql/library/query/getResourceDetailsInLibrary';

import { mapLibraryLocationToLibraryType } from '../utils/mapLibraryLocationToLibraryType';
import { useBreadcrumbsLinks } from './useBreadcrumbsLinks';
import { useCurrentLibraryLocation } from './useCurrentLibraryLocation';

export const useBreadcrumbItems = () => {
  const { libraryLocation, folderId } = useCurrentLibraryLocation();

  const { details, loading, error } = useGetResourceDetailsInLibrary(
    mapLibraryLocationToLibraryType(libraryLocation),
    folderId
  );

  return useBreadcrumbsLinks({ details, loading, error, shared: false });
};
