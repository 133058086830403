import { object, string, ref } from 'yup';

export const newPasswordSchema = object().shape({
  password: string()
    .required('Please enter your password')
    .matches(/[A-Z]/, 'Password must have at least 1 Uppercase letter.')
    .matches(/[a-z]/, 'Password must have at least 1 Lowercase letter.')
    .matches(/[0-9]/, 'Password must have at least 1 numeric character.')
    .matches(/[^A-Za-z0-9]/, 'Password must have at least 1 special character.')
    .min(8, 'Password must be at least 8 characters long.'),
  confirmPassword: string()
    .required('Confirm your password')
    .oneOf([ref('password')], 'Passwords must match'),
});
