import { ReactNode } from 'react';

import { Heading } from '@chakra-ui/react';

import { LayoutWithBlock } from 'components/Layout/LayoutWithBlock';

type Props = {
  title?: string;
  children: ReactNode;
};

export const WelcomeLayout = ({ title, children }: Props) => {
  return (
    <LayoutWithBlock
      bgImage="/assets/backgrounds/login.jpg"
      leftContent={
        <Heading
          size="h2"
          fontWeight="bold"
          fontSize={{ base: 30, md: 56 }}
          lineHeight={1.1}
          fontFamily="Publico Headline Medium"
          mb={4}
        >
          {title}
        </Heading>
      }
    >
      {children}
    </LayoutWithBlock>
  );
};
