import { useTranslation } from 'react-i18next';

import { Heading, Text } from '@chakra-ui/react';

import { Section } from 'components/Layout/Sections/Section';

type Props = {
  purpose: string;
};

export const PurposeSection = ({ purpose }: Props) => {
  const { t } = useTranslation(['family']);

  return (
    <Section
      title={t('family:charter.section.purpose.title')}
      subtitleContent={
        <Text size="p2">{t('family:charter.section.purpose.description')}</Text>
      }
    >
      <Heading color="primary.blue.default" size="h4">
        {purpose}
      </Heading>
    </Section>
  );
};
