import { useParams } from 'react-router-dom';

import { Divider } from '@chakra-ui/react';

import { ContentCommentItem } from 'components/ContentCommentItem';
import { NewPostForm } from 'components/Posts/PostForm';
import { usePosts } from 'hooks/posts';
import { PostSource } from 'types';

export const EventUpdateList = () => {
  const { id } = useParams();

  if (!id) throw new TypeError('Event id is required');

  const { posts, refetch } = usePosts({
    filters: {
      source: PostSource.Event,
      referenceId: id,
    },
    pagination: { limit: 100, offset: 0 },
  });

  return (
    <>
      {posts.map((post) => (
        <ContentCommentItem post={post} key={post.id} />
      ))}

      <Divider mt={5} mb={2} borderColor="grey" />
      <NewPostForm
        textLabel="Short Content"
        source={PostSource.Event}
        referenceId={id}
        onAdd={refetch}
        textCharLimit={100}
      />
    </>
  );
};
