import { gql, QueryHookOptions, useQuery } from '@apollo/client';

import { Query } from 'types';
import { FamilySdgConfiguratorOutputFragment } from 'types/generated-fragments';

export const familySdgChoicesGql = gql`
  ${FamilySdgConfiguratorOutputFragment}
  query SdgIdsWithNumericScores {
    familySdgsChoices {
      ...FamilySdgConfiguratorOutputFragment
    }
  }
`;

export const useFamilySdgChoices = (options?: QueryHookOptions) => {
  const { data, loading, error } = useQuery<Pick<Query, 'familySdgsChoices'>>(
    familySdgChoicesGql,
    options
  );

  return { familySdgsChoices: data?.familySdgsChoices, loading, error };
};
