import { MutationHookOptions, gql, useMutation } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { createMock, postsMock, POST_TEST_VALUES } from 'mocks';
import { Mutation, MutationCreatePostArgs, PostSource } from 'types';

export const createPostGql = gql`
  mutation CreatePost($post: PostCreateInput!, $images: [Upload!]) {
    createPost(post: $post, images: $images) {
      id
    }
  }
`;

type TData = Pick<Mutation, 'createPost'>;
type TVariables = MutationCreatePostArgs;

export const createPostGqlMock = createMock<TData, TVariables>(
  createPostGql,
  {
    data: { createPost: postsMock[0] },
  },
  {
    post: {
      text: POST_TEST_VALUES.text,
      referenceId: POST_TEST_VALUES.referenceId,
      source: PostSource.Event,
    },
  }
);

export const useCreatePost = (
  options?: MutationHookOptions<TData, TVariables>
) => {
  const [createPostMutation] = useMutation<TData, TVariables>(
    createPostGql,
    options
  );

  const handleMutation = (variables: MutationCreatePostArgs) =>
    createPostMutation({ variables });

  const createPost = useWithMutationToast(handleMutation, {
    successMessage: 'Post created successfully',
    errorMessage: 'Error creating post',
  });

  return { createPost };
};
