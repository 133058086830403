import { Box, Center } from '@chakra-ui/react';

import { OnboardSdgsSummary } from 'components/OnboardSdgsSummary';
import { useFamilySdgChoices } from 'hooks/sdgs/useFamilySdgChoices';
import { useSdgsFromConfigurator } from 'hooks/sdgs/useSdgsFromConfigurator';
import { SdgsConfigId } from 'types';

import { ThankYou } from './ThankYou';

type Props = {
  title: string;
  description: string;
};

export const SdgInfluences = ({ description, title }: Props) => {
  const { familySdgsChoices, error } = useFamilySdgChoices();

  const sdgs = useSdgsFromConfigurator(familySdgsChoices);

  if (!sdgs) return null;

  if (error) return <Center>{error.message}</Center>;

  return (
    <Box>
      <OnboardSdgsSummary
        sdgs={sdgs}
        id={SdgsConfigId.Families}
        containerStyling={{ py: 20 }}
        description={description}
        title={title}
      />
      <ThankYou />
    </Box>
  );
};
