import { useAuthContext } from 'contexts/AuthContext';
import { AccountStatus, WithChildren } from 'types';

import { useSubscribeToMessages } from '../MessagingCommunication';
import { useLoadInitialMessagingState } from '../hooks/useLoadInitialMessagingState';
import { useOnSubscribtionMessageHandler } from '../hooks/useOnSubscribtionMessageHandler';

export const MessagesSubscriber = ({ children }: WithChildren) => {
  const { account } = useAuthContext();

  const onMessageHandler = useOnSubscribtionMessageHandler();

  useSubscribeToMessages({
    canSubscribe: !!account && account.status === AccountStatus.Active,
    onNextData: onMessageHandler,
  });

  useLoadInitialMessagingState(!!account);

  return <>{children}</>;
};
