import { Insight } from 'types';
import { flattenTags } from 'utils/tags';

import { GridInsight } from './components/InsightsGrid';

export const mapInsightsToGridInsights = (insights: Insight[]): GridInsight[] =>
  insights.map((insight) => {
    const tags: string[] = insight.visibilityDetails?.familyOwner?.name
      ? [insight.visibilityDetails?.familyOwner?.name]
      : [];

    return {
      id: insight.id,
      heading: insight.hero.headline,
      image: insight.hero.heroImage.signedUrl ?? '',
      tags: [...flattenTags(insight.tags), ...tags],
      summary: insight.hero.summary,
    };
  });
