import { useTranslation } from 'react-i18next';

import { Flex, Button, Text } from '@chakra-ui/react';

import { useAuthContext } from 'contexts/AuthContext';
import { useRequestOTPforJWT } from 'hooks/authorization/useRequestOTPforJWT';
import { useCountdown } from 'hooks/useCountdown';
import { VerificationCodeSendLocation } from 'types';

import { MoreOptions } from './MoreOptions';

const NEXT_OTP_SEND_AWAIT_SECONDS = 60;

export const ResendOtpSection = () => {
  const { t } = useTranslation(['common']);
  const { secondFactorJWT } = useAuthContext();

  const { requestOTPforJWT } = useRequestOTPforJWT();
  const { counter, resetCounter } = useCountdown(NEXT_OTP_SEND_AWAIT_SECONDS);

  const handleSendNewCode = async (
    sendLocation?: VerificationCodeSendLocation
  ) => {
    if (!secondFactorJWT) {
      return;
    }

    const { data } = await requestOTPforJWT({
      jwt: secondFactorJWT,
      sendLocation,
    });

    if (data?.requestNewOTP) {
      resetCounter();
    }
  };

  if (!secondFactorJWT) {
    return null;
  }

  return (
    <Flex gap={2} flexDirection="column" alignItems="center">
      <Flex mt={8} gap={1} alignItems="baseline">
        <Text size="p2" color="primary.blue.default">
          {t('common:otp.not-received')}
        </Text>
        <Button
          variant="link"
          onClick={() => handleSendNewCode()}
          isDisabled={counter > 0}
        >
          {counter
            ? t('common:otp.wait-before-resend', { number: counter })
            : t('common:otp.click-to-resend')}
        </Button>
      </Flex>
      <MoreOptions
        onConfirm={() => handleSendNewCode(VerificationCodeSendLocation.Email)}
        waitCounter={counter}
      />
    </Flex>
  );
};
