import { Heading, HeadingProps } from '@chakra-ui/react';

import { WithChildren } from 'types';

export const MarketplaceHeading = ({
  children,
  ...headingProps
}: WithChildren<HeadingProps>) => (
  <Heading size="h3" textAlign="center" w="full" {...headingProps}>
    {children}
  </Heading>
);
