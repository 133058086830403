import ReactCardFlip from 'react-card-flip';

import { Box, ListItem, OrderedList, Text } from '@chakra-ui/react';

import { SdgIcon } from 'components/Icons/SdgIcon';
import { SdgOverview } from 'constants/Sdgs';

type Props = {
  isFlipped: boolean;
  sdgOverview: SdgOverview;
};

export const FlipCard = ({ isFlipped, sdgOverview }: Props) => {
  const { color, description, keyTargets, index, internalName } = sdgOverview;

  return (
    <ReactCardFlip
      isFlipped={isFlipped}
      containerStyle={{
        height: '100%',
        cursor: 'pointer',
        userSelect: 'none',
      }}
    >
      <Box position="relative" h="full">
        <SdgIcon
          variant="bgImage"
          name={internalName}
          additionalStyling={{ h: 'full', objectFit: 'cover' }}
        />

        <SdgIcon
          name={internalName}
          additionalStyling={{
            w: 128,
            left: 4,
            bottom: 4,
            position: 'absolute',
          }}
        />
      </Box>

      <Box bgColor={color} p={6} height="full" color="ui.white">
        <Text size="p1b" mb={2}>
          Goal {index}
        </Text>
        <Text size="p1" mb={6}>
          {description}
        </Text>
        <Text size="p1b" mb={2}>
          Key targets
        </Text>
        <OrderedList>
          {keyTargets.map((keyTarget) => (
            <ListItem key={keyTarget}>
              <Text size="p1">{keyTarget}</Text>
            </ListItem>
          ))}
        </OrderedList>
      </Box>
    </ReactCardFlip>
  );
};
