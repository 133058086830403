import { StackDivider, Center, Heading } from '@chakra-ui/react';

export const ValuesCategorisation = () => {
  return (
    <Center flex={1} maxW={540} flexDirection="column" alignItems="flex-start">
      <Heading size="h3" mb={4}>
        Categorisation of Values
      </Heading>
      <StackDivider
        borderColor="primary.blue.default"
        borderBottomWidth={3}
        w={14}
        mb={8}
      />
      <Heading size="h2" mb={10}>
        Itriom has four categories of values: Organisational, Contribution,
        Advancement and Societal.
      </Heading>
      <Heading size="h2">
        Categorising values this way provide both direction and drives action
        towards creating impact and fulfilling the family’s purpose.
      </Heading>
    </Center>
  );
};
