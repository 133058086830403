import { InfiniteList } from 'components/InfiniteList';
import { useAuthContext } from 'contexts/AuthContext';
import { useMessagesSelectors } from 'features/Messaging/MessagingStateManager/hooks';
import { useDeleteMessage } from 'features/Messaging/hooks/useDeleteMessage';
import { useLoadMoreMessages } from 'features/Messaging/hooks/useLoadMoreMessages';
import { Conversation as ConversationType } from 'types';

import { Message } from './Message';

type Props = {
  conversation: ConversationType;
};

export const MessagesInfiniteList = ({ conversation }: Props): JSX.Element => {
  const { account } = useAuthContext();

  const { selectMessagesForConversation, selectHasMoreMessages } =
    useMessagesSelectors();

  const hasMoreMessages = selectHasMoreMessages(conversation.id);
  const messages = selectMessagesForConversation(conversation.id);

  const loadMoreMessages = useLoadMoreMessages(conversation.id);

  const deleteMessage = useDeleteMessage();

  return (
    <InfiniteList
      hasMoreItems={hasMoreMessages}
      loadMoreItems={loadMoreMessages}
      items={messages}
      inverse
      wrapperProps={{ minH: '325px', maxH: '390px' }}
      renderItem={(message) => (
        <Message
          handleDelete={() => deleteMessage(message)}
          message={message}
          sender={conversation.members.find(
            ({ id }) => id === message.createdBy
          )}
          key={message.id}
          isAuthor={message.createdBy === account?.id}
        />
      )}
    />
  );
};
