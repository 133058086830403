import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { CheckIcon } from '@chakra-ui/icons';
import { Flex } from '@chakra-ui/layout';
import { Button, Center, Heading } from '@chakra-ui/react';

import { RightBlock } from 'components/Layout/RightBlock';
import { useAuthContext } from 'contexts/AuthContext';
import { useOnboardingManagement } from 'hooks/onboarding/useOnboardManagement';
import { Role } from 'types';

import { getRedirectLink } from '../utils/getRedirectLink';

type Props = {
  title: string;
  subtitle?: string;
  hideRedirectButton?: boolean;
};

export const Cta = ({ title, subtitle, hideRedirectButton }: Props) => {
  const { account } = useAuthContext();
  const { onboardManagement, error, loading } = useOnboardingManagement();

  const { t } = useTranslation(['common']);

  const link = getRedirectLink(
    onboardManagement,
    account?.onboardingStatus,
    account?.role === Role.ExpertAdvisor
  );

  return (
    <RightBlock title={title} error={error?.message} loading={loading}>
      <Flex flex={1} justify="center" align="center" direction="column">
        <Flex w="full" justify="center">
          <Center
            w={70}
            h={70}
            borderRadius="50%"
            borderColor="primary.blue.default"
            borderWidth={1}
          >
            <CheckIcon color="primary.blue.default" fontSize="3xl" />
          </Center>
        </Flex>
        <Heading size="h5" mt={12}>
          {subtitle}
        </Heading>
      </Flex>

      {!hideRedirectButton && link && (
        <Link to={link}>
          <Button
            bgColor="primary.lime.default"
            color="primary.blue.default"
            variant="solid"
            borderRadius={2}
            h={12}
            w="full"
            fontSize="md"
          >
            {t('common:continue')}
          </Button>
        </Link>
      )}
    </RightBlock>
  );
};
