import { gql, useMutation } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationDeleteEventArgs } from 'types';

import { eventsGql } from './useEvents';

export const deleteEventGql = gql`
  mutation DeleteEvent($eventId: String!) {
    deleteEvent(eventId: $eventId)
  }
`;

export const useDeleteEvent = () => {
  const [deleteEventRequest] = useMutation<
    Pick<Mutation, 'deleteEvent'>,
    MutationDeleteEventArgs
  >(deleteEventGql, {
    refetchQueries: [eventsGql],
  });

  const handleDeleteEvent = (eventId: string) =>
    deleteEventRequest({ variables: { eventId } });

  const deleteEvent = useWithMutationToast(handleDeleteEvent, {
    successMessage: 'The event was successfully deleted',
    errorMessage: 'Deleting failed',
  });

  return { deleteEvent };
};
