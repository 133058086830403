import { Flex } from '@chakra-ui/react';

import { useValuesCopy } from 'hooks/copy/useValueCopy';
import { capitalize } from 'utils/string';

import { CategoryChart, CategoryChartData } from './CategoryChart';
import { ValueDescription } from './ValueDescription';

type Props = {
  categoryName: string;
  values: CategoryChartData[] | undefined;
};

export const CategoryChartSection = ({
  categoryName,
  values,
}: Props): JSX.Element | null => {
  const { getValueCopy } = useValuesCopy();

  if (!values || !values.length) return null;

  const maxValue = values.reduce((prev, curr) =>
    prev.value < curr.value ? curr : prev
  );

  return (
    <Flex w={300} direction="column" my={10} gap={2}>
      <CategoryChart
        data={values}
        categoryName={categoryName}
        highlightElement={maxValue}
      />
      <ValueDescription
        name={capitalize(maxValue.name)}
        description={getValueCopy(maxValue.name).description || ''}
      />
    </Flex>
  );
};
