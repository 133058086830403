import { Message } from 'types';

import {
  MessagesState,
  AddMessageAction,
  SetMessagesAction,
  SetMessagesTotalCountAction,
  DeleteMessageAction,
  DeleteAllMessagesAction,
} from './types';

export const addMessageHandler = (
  state: MessagesState,
  message: AddMessageAction['payload']
): MessagesState => {
  const messagesForConversation =
    state.messagesMap[message.conversationId] || [];

  const filteredMessagesForConversation = messagesForConversation.filter(
    ({ id }) => id !== message.id
  );

  const newMessagesForConversation = [
    ...filteredMessagesForConversation,
    message,
  ];

  return {
    ...state,
    messagesMap: {
      ...state.messagesMap,
      [message.conversationId]: newMessagesForConversation,
    },
  };
};

export const deleteMessageHandler = (
  state: MessagesState,
  message: DeleteMessageAction['payload']
): MessagesState => {
  const deletedMessage: Message = {
    ...message,
    deletedBy: message.createdBy,
    text: undefined,
  };

  return addMessageHandler(state, deletedMessage);
};

export const setMessagesHandler = (
  state: MessagesState,
  { conversationId, messages }: SetMessagesAction['payload']
): MessagesState => ({
  ...state,
  messagesMap: {
    ...state.messagesMap,
    [conversationId]: messages,
  },
});

export const setMessagesTotalCountHandler = (
  state: MessagesState,
  { conversationId, messagesTotalCount }: SetMessagesTotalCountAction['payload']
): MessagesState => ({
  ...state,
  messagesTotalCountMap: {
    ...state.messagesTotalCountMap,
    [conversationId]: messagesTotalCount,
  },
});

export const deleteAllMessagesHandler = (
  state: MessagesState,
  { conversationId }: DeleteAllMessagesAction['payload']
): MessagesState => ({
  ...state,
  messagesMap: Object.entries(state.messagesMap).reduce(
    (acc, [key, value]) =>
      key === conversationId ? acc : { ...acc, [key]: value },
    {}
  ),
});
