import { ReactNode } from 'react';

import { Text } from '@chakra-ui/layout';
import { Flex, ListItem } from '@chakra-ui/react';

type Props = {
  children: ReactNode;
  label: string;
};

export const Row = ({ children, label }: Props) => {
  return (
    <ListItem mb={2}>
      <Flex justify="space-between">
        <Text size="p1">{label}</Text>
        <Flex gap={2}>{children}</Flex>
      </Flex>
    </ListItem>
  );
};
