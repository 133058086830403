import { gql, useSubscription } from '@apollo/client';

import { useAuthContext } from 'contexts/AuthContext';
import {
  GroupNotificationOutputFragment,
  PostNotificationOutputFragment,
} from 'gql/notifications/fragments';
import { NotificationUnion, Subscription, WithChildren } from 'types';
import {
  ConnectionAcceptedNotificationOutputFragment,
  GroupInvitationSentNotificationOutputFragment,
  ConnectionSentNotificationOutputFragment,
  MessageSentNotificationOutputFragment,
  ContentDeletedByAdminOutputFragment,
  ProfileNoNestingFragment,
  ProfileFragment,
} from 'types/generated-fragments';

import { useSubscriptionNotificationHandler } from '../hooks/useSubscribtionNotificationHandler';

const addProfileNestingToFragment = (fragment: string): string =>
  fragment
    .replace(ProfileNoNestingFragment, ProfileFragment)
    .replace('...ProfileNoNestingFragment', '...ProfileFragment');

const notificationSubscriptionGql = gql`
  ${addProfileNestingToFragment(ConnectionSentNotificationOutputFragment)}
  ${addProfileNestingToFragment(ConnectionAcceptedNotificationOutputFragment)}
  ${addProfileNestingToFragment(GroupInvitationSentNotificationOutputFragment)}
  ${addProfileNestingToFragment(ContentDeletedByAdminOutputFragment)}
  ${GroupNotificationOutputFragment}
  ${PostNotificationOutputFragment}
  ${addProfileNestingToFragment(MessageSentNotificationOutputFragment)}
  subscription ConnectionSentNotificationOutput {
    notificationAdded {
      payload {
        ... on ConnectionSentNotificationOutput {
          ...ConnectionSentNotificationOutputFragment
        }
        ... on ConnectionAcceptedNotificationOutput {
          ...ConnectionAcceptedNotificationOutputFragment
        }
        ... on GroupInvitationSentNotificationOutput {
          ...GroupInvitationSentNotificationOutputFragment
        }
        ... on GroupNotificationOutput {
          ...GroupNotificationOutputFragment
        }
        ... on MessageSentNotificationOutput {
          ...MessageSentNotificationOutputFragment
        }
        ... on ContentDeletedByAdminOutput {
          ...ContentDeletedByAdminOutputFragment
        }
        ... on PostNotificationOutput {
          ...PostNotificationOutputFragment
        }
      }
    }
  }
`;

export const NotificationsSubscriber = ({ children }: WithChildren) => {
  const { account } = useAuthContext();

  const { subscriptionNotificationHandler } =
    useSubscriptionNotificationHandler();

  useSubscription<Pick<Subscription, 'notificationAdded'>>(
    notificationSubscriptionGql,
    {
      skip: !account,
      onData: (payload) => {
        subscriptionNotificationHandler(
          payload.data.data?.notificationAdded.payload as NotificationUnion
        );
      },
    }
  );

  return <>{children}</>;
};
