import {
  AccountStatus,
  CallToActionOutput,
  EsgCategoryEnum,
  Event,
  EventStatusEnum,
  HeroMediaType,
  Honorific,
  ProfileConnectionStateEnum,
  Role,
  TagType,
} from 'types';

import { generateMedia } from './images.mock';
import { generateTimezoneDateOutput } from './timezoneDate.mock';

export const FORMATTED_START_DATE = 'April 18, 2022';
export const FORMATTED_END_DATE = 'August 21, 2022';
export const FORMATTED_RANGE = 'April 18, 2022 - August 21, 2022';

export const callToActionMock: CallToActionOutput = {
  __typename: 'CallToActionOutput',
  joinInfo: {
    __typename: 'JoinOutput',
    image: generateMedia(),
    name: 'name',
    description: 'asdasdsadasd',
    email: 'email',
    isEnabled: true,
  },
  helpRequest: {
    __typename: 'HelpRequestOutput',
    image: generateMedia(),
    name: 'Lorem ipsum',
    description: 'Desc',
    email: 'sadasd@op.pl',
    isEnabled: true,
  },
};

export const eventMock: Event = {
  __typename: 'Event',
  id: '624c20998477a5767e8952bc',
  description: 'testest',
  mainTopicDescription: 'testest',
  preferredHeroMediaToDisplay: HeroMediaType.Video,
  basicInfo: {
    __typename: 'EventBasicInfo',
    isVirtual: true,
    startTime: generateTimezoneDateOutput(),
    endTime: generateTimezoneDateOutput(),
    location: {
      __typename: 'Location',
      addressLine1: 'line 1',
      addressLine2: 'line 2',
      images: [],
      isGalleryEnabled: false,
      city: 'City',
      country: 'Country',
      postcode: '78563',
      state: 'State',
    },
  },
  visibilityDetails: {
    __typename: 'EventVisibilityOutput',
    connections: true,
    externalUrl: '',
    family: false,
    familyOwnersIds: [],
    isPublic: true,
    itriom: false,
    joint: false,
  },
  hero: {
    description: '',
    heroImage: generateMedia({
      signedUrl: '/assets/nextGenServices/hero_image.png',
    }),
    heroVideo: generateMedia(),
    name: 'Reneawable wind and solar energy in Ukraine',
    subtitle: 'Solar energy in Ukraine initiative',
    summary:
      'India climate activist Licypriya Kangujam heads a list of speakers on why she took a stand. She became involved in activism after attending a UN disaster conference in Mongolia with her father in 2018.',
    preferredHeroMediaToDisplay: HeroMediaType.Video,
  },
  keySpeaker: {
    __typename: 'EventKeySpeakerOutput',
    about: 'test',
    image: generateMedia(),
    keynoteSpeakerHeading: 'test',
    name: 'test',
    title: 'test',
  },
  status: EventStatusEnum.Published,
  topics: [],
  tags: {
    sdGoalTag: {
      description: 'test',
      icon: 'sdg-14',
      index: 12,
      internalName: 'sdg-14',
      id: '6256a7c7fc3a1d9cb38ce700',
      createdAt: 1649846215962,
      updatedAt: 1649846215962,
      name: 'SDG 14 - Life Below Water',
      __typename: 'SdgOutput',
    },
    countryFocusTag: {
      id: '6256a7c7fc3a1d9cb38ce718',
      createdAt: 1649846215995,
      updatedAt: 1649846215995,
      name: 'Oceans',
      type: TagType.Environmental,
      order: 0,
      __typename: 'TagOutput',
    },
    esgFocus: EsgCategoryEnum.Governance,
    __typename: 'ItriomTagsV2Output',
  },

  isAttending: false,
  agenda: {
    __typename: 'Agenda',
    heading: 'Biggest event in the Europe',
    description:
      'Quisque mauris turpis, tempus vulputate odio a, suscipit tincidunt urna. Curabitur tincidunt, magna sed egestas gravida, tortor tellus pharetra purus, a efficitur ex magna ut nulla',
    image: generateMedia(),
    agendaItems: [
      {
        __typename: 'AgendaItem',
        heading: 'Saving the Cocos Keeling Islands',
        description:
          'Nunc dignissim sapien et justo tristique, at rutrum ex fermentum. Ut ornare tellus et consequat rutrum. Curabitur varius sem quam, non efficitur massa malesuada ut. Duis nibh ipsum, mattis tristique pharetra vel, scelerisque sollicitudin ante. Nam sit amet mollis libero, sit amet ultrices libero.',
        image: generateMedia(),
        startTime: '2022-04-21T11:10:00.000Z',
        endTime: '2022-04-21T19:00:00.000Z',
        speakers: [
          {
            __typename: 'SpeakerOutput',
            profile: {
              honorific: Honorific.Miss,
              status: AccountStatus.Active,
              __typename: 'Profile',
              avatarImage: generateMedia(),
              heroImage: generateMedia(),
              id: '61ed723162715d298cd08421a',
              createdAt: 1642951218500,
              updatedAt: 1648032504925,
              firstName: 'Anushka',
              lastName: 'Godrej',
              title: 'Aerospace & Defense',
              description: '',
              isConnected: false,
              connectionState: ProfileConnectionStateEnum.NotConnected,
              canBeInvited: false,
              role: Role.FamilyMember,
            },
            isKeySpeaker: true,
          },
          {
            __typename: 'SpeakerOutput',
            profile: {
              honorific: Honorific.Baron,
              status: AccountStatus.Active,
              __typename: 'Profile',
              avatarImage: generateMedia(),
              heroImage: generateMedia(),
              id: '61ed723162715d298cd08422',
              createdAt: 1642951218502,
              updatedAt: 1643122435889,
              firstName: 'Zayn',
              lastName: 'Godrej',
              title: 'Real Estate',
              description: '',
              isConnected: false,
              connectionState: ProfileConnectionStateEnum.NotConnected,
              canBeInvited: false,
              role: Role.FamilyMember,
            },
            isKeySpeaker: false,
          },
        ],
      },
    ],
  },

  organisers: [],
  sponsors: [
    {
      __typename: 'Sponsor',
      name: 'Abu Choudhury',
      title: 'Environmental Engineering',
      showName: true,
      isCompany: false,
      profile: {
        honorific: Honorific.Mr,
        status: AccountStatus.Active,
        __typename: 'Profile',
        avatarImage: generateMedia(),
        heroImage: generateMedia(),
        id: '61ed723162715d298cd0842b',
        createdAt: 1642951218513,
        updatedAt: 1642951218513,
        firstName: 'Abu',
        lastName: 'Choudhury',
        title: 'Environmental Engineering',
        description: '',
        isConnected: false,
        connectionState: ProfileConnectionStateEnum.NotConnected,
        canBeInvited: false,
        role: Role.FamilyMember,
      },
      image: generateMedia(),
    },
    {
      __typename: 'Sponsor',
      name: 'Secure Fisheries',
      title: '',
      showName: false,
      isCompany: true,
      profile: {
        honorific: Honorific.Dame,
        status: AccountStatus.Active,
        __typename: 'Profile',
        avatarImage: generateMedia(),
        heroImage: generateMedia(),
        id: '61ed723162715d298cd08421c',
        createdAt: 1642951218500,
        updatedAt: 1648032504925,
        firstName: 'Anushka',
        lastName: 'Godrej',
        title: 'Aerospace & Defense',
        description: '',
        isConnected: false,
        connectionState: ProfileConnectionStateEnum.NotConnected,
        canBeInvited: false,
        role: Role.FamilyMember,
      },
      image: generateMedia(),
    },
    {
      __typename: 'Sponsor',
      name: 'GreenPeace',
      title: '',
      showName: true,
      isCompany: true,
      profile: {
        honorific: Honorific.Mrs,
        status: AccountStatus.Active,
        canBeInvited: false,
        __typename: 'Profile',
        avatarImage: generateMedia(),
        heroImage: generateMedia(),
        id: '61ed723162715d298cd08421d',
        createdAt: 1642951218500,
        updatedAt: 1648032504925,
        firstName: 'Anushka',
        lastName: 'Godrej',
        title: 'Aerospace & Defense',
        description: '',
        isConnected: false,
        connectionState: ProfileConnectionStateEnum.NotConnected,
        role: Role.FamilyMember,
      },
      image: generateMedia(),
    },
    {
      __typename: 'Sponsor',
      name: 'The Ocean Cleanup',
      title: '',
      showName: false,
      isCompany: true,
      profile: {
        honorific: Honorific.Mrs,
        __typename: 'Profile',
        avatarImage: generateMedia(),
        heroImage: generateMedia(),
        id: '61ed723162715d298cd08421e',
        createdAt: 1642951218500,
        updatedAt: 1648032504925,
        firstName: 'Anushka',
        lastName: 'Godrej',
        title: 'Aerospace & Defense',
        description: '',
        isConnected: false,
        status: AccountStatus.Active,
        connectionState: ProfileConnectionStateEnum.NotConnected,
        canBeInvited: false,
        role: Role.FamilyMember,
      },
      image: generateMedia(),
    },
  ],
  objectives: [],
  callToAction: callToActionMock,
  ultimateGoal: {
    __typename: 'UltimateGoalOutput',
    title: 'very important goal',
    description: 'decsription of very important goal',
    sdIcon: '61ea7d073ee4a589d586cbd2',
    image: generateMedia(),
  },
  isCommentsEnabled: true,
  createdAt: 1656925200000,
  updatedAt: 1656925200000,
  createdBy: 'xxx',
  updatedBy: 'xxx',
  appreciationCount: 122222,
};
