import { ValueConfiguratorCategoryEnum } from 'types';

import { OrganisationalStep } from '../../components/ValuesConfigurator';

type Props = {
  valuesCategory?: ValueConfiguratorCategoryEnum;
};

export const OrganisationalValues = ({
  valuesCategory = ValueConfiguratorCategoryEnum.Importance,
}: Props): JSX.Element => {
  return <OrganisationalStep valuesCategory={valuesCategory} />;
};
