import { Flex } from '@chakra-ui/react';

import { ItemRow } from 'features/Library/components/ItemRow';
import { SearchLibraryResultOutput } from 'types';

import { MAX_LIBRARY_PREVIEW } from '../constants';

interface Props {
  results: SearchLibraryResultOutput[];
  showAllResults?: boolean;
  fixedResults?: SearchLibraryResultOutput[];
}

export const LibraryResults = ({
  results,
  showAllResults = false,
  fixedResults,
}: Props) => {
  const resultsToMap = (): SearchLibraryResultOutput[] => {
    if (fixedResults) {
      return showAllResults
        ? fixedResults
        : fixedResults.slice(0, MAX_LIBRARY_PREVIEW);
    }

    if (results) {
      return showAllResults ? results : results.slice(0, MAX_LIBRARY_PREVIEW);
    }

    return [];
  };

  return (
    <Flex direction="column" gap={2} p={4} w="inherit">
      {resultsToMap().map((result) => {
        return (
          <ItemRow
            key={result.object.id}
            item={result.object}
            isChecked={false}
            displayCheckbox={false}
            enableDragAndDrop={false}
            onCheckToggle={() => null}
          />
        );
      })}
    </Flex>
  );
};
