import { useCallback } from 'react';

import { useToast } from '@chakra-ui/react';

import { getBytesSize } from 'utils/size';

import { FileWithPathAndPreview } from '../types';

type Props = { isImage: boolean; maxSize: string };

export const useFilesizeValidator = ({ isImage, maxSize }: Props) => {
  const toast = useToast();

  const filesizeValidator = useCallback(
    (file: FileWithPathAndPreview) => {
      const overriddenMaxSize = isImage || maxSize ? maxSize : '150MB';

      if (file.size > getBytesSize(overriddenMaxSize)) {
        toast({
          title: 'Uploading failed',
          description: `Can't upload files larger than ${overriddenMaxSize}`,
          status: 'error',
          isClosable: true,
        });

        return {
          code: 'size-too-large',
          message: `File is larger than ${overriddenMaxSize}`,
        };
      }

      return null;
    },
    [maxSize, toast, isImage]
  );

  return filesizeValidator;
};
