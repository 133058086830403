import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { Flex, Button, Heading } from '@chakra-ui/react';

import { Panel } from 'components/Layout/Panel';
import { useAuthContext } from 'contexts/AuthContext';
import { useProfile } from 'hooks/profiles/useProfile';
import { Links } from 'router/links';

export const NotCompletedProfile = () => {
  const { t } = useTranslation(['common', 'profile']);
  const { id } = useParams();
  const { account } = useAuthContext();
  const { profile } = useProfile(id);

  const isOwnAccount = profile && account && profile.id === account.id;

  return (
    <Panel title={t('common:advisor')} containerStyling={{ mt: 8 }}>
      <Flex
        direction="column"
        alignItems="center"
        gap={6}
        w="full"
        py="4.25rem"
      >
        <Heading size="h3" textAlign="center">
          {isOwnAccount
            ? t('profile:own-not-completed')
            : t('profile:other-not-completed')}
        </Heading>
        {isOwnAccount && (
          <Button
            w="min-content"
            variant="primaryLime"
            as={Link}
            to={Links.advisorManageOwnProfile}
          >
            {t('profile:complete-your-profile')}
          </Button>
        )}
      </Flex>
    </Panel>
  );
};
