import { gql, useQuery } from '@apollo/client';

import { Event } from 'gql/fragments';
import {
  EventType,
  EventVisibilityFiltersInput,
  InputMaybe,
  Query,
  QueryEventsArgs,
} from 'types';

export const eventsGql = gql`
  ${Event}
  query Events($pagination: PaginationInput, $filters: EventFilters) {
    events(pagination: $pagination, filters: $filters) {
      nodes {
        ...Event
      }
      totalCount
    }
  }
`;

export const useEvents = (variables?: QueryEventsArgs) => {
  const { data, loading, error, refetch } = useQuery<
    Pick<Query, 'events'>,
    QueryEventsArgs
  >(eventsGql, { variables });

  const events = data?.events.nodes || [];

  return { events, loading, error, refetch };
};

export const mapEventTypeToVisibilityFilter = (
  type: EventType
): InputMaybe<EventVisibilityFiltersInput> => {
  switch (type) {
    case EventType.All: {
      return {
        userHasAccessTo: true,
      };
    }

    case EventType.My: {
      return {
        isPublic: true,
      };
    }

    case EventType.Family: {
      return {
        family: true,
      };
    }

    case EventType.Itriom: {
      return {
        itriom: true,
      };
    }

    case EventType.Peers: {
      return {
        connections: true,
        family: true,
      };
    }

    case EventType.Public: {
      return {
        isPublic: true,
      };
    }

    default: {
      return undefined;
    }
  }
};
