import { useCallback } from 'react';

import { useToast, UseToastOptions } from '@chakra-ui/react';

const DEFAULT_TOAST_OPTIONS: UseToastOptions = {
  isClosable: true,
  duration: 5000,
  position: 'bottom',
};

export type ToastConfig = {
  successMessage?: string;
  errorMessage?: string;
  successConfig?: UseToastOptions;
  errorConfig?: UseToastOptions;
  disableSuccessMessage?: boolean;
  disableErrorMessage?: boolean;
};

/** Wrapper for mutation that handles toast messages */
export const useWithMutationToast = <T extends unknown[], R>(
  mutationCallback: (...args: T) => Promise<R>,
  options: ToastConfig | ((...args: T) => ToastConfig) = {}
) => {
  const toast = useToast();

  return useCallback(
    async (...args: T) => {
      const toastConfig =
        typeof options === 'function' ? options(...args) : options;

      const {
        errorMessage,
        successMessage,
        errorConfig,
        successConfig,
        disableErrorMessage = false,
        disableSuccessMessage = false,
      } = toastConfig;

      try {
        const result = await mutationCallback(...args);

        if (!disableSuccessMessage) {
          toast(
            successConfig || {
              title: successMessage || 'Updated data successfully',
              status: 'success',
              ...DEFAULT_TOAST_OPTIONS,
            }
          );
        }

        return result;
      } catch (error) {
        if (error instanceof Error) {
          if (!disableErrorMessage) {
            toast(
              errorConfig || {
                title: errorMessage || 'Failed updating data',
                description: error.message,
                status: 'error',
                ...DEFAULT_TOAST_OPTIONS,
              }
            );
          }
        }

        throw error;
      }
    },
    [mutationCallback, toast, options]
  );
};
