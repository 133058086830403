import { isGroupChat } from 'features/Messaging/utils';
import { Profile } from 'types';

import {
  ConversationsState,
  AddConversationAction,
  SetConversationsAction,
  DeleteConversationAction,
  HandleConversationSelectAction,
  SetConversationsTotalCountAction,
  AddConversationToUnreadAction,
  HandleSelectConversationByUserAction,
  HandleInterlocutorTrigger,
} from './types';

export const addConversationHandler = (
  state: ConversationsState,
  newConversation: AddConversationAction['payload']
): ConversationsState => {
  const prevConversations = state.conversations.filter(
    ({ id }) => id !== newConversation.id
  );

  const newConversations = [...prevConversations, newConversation];

  return {
    ...state,
    conversations: newConversations,
  };
};

export const addToUnreadHandler = (
  state: ConversationsState,
  conversationId: AddConversationToUnreadAction['payload']
): ConversationsState => {
  const isActiveConversation = state.activeConversationId === conversationId;

  const unreadConversationIds = isActiveConversation
    ? state.unreadConversationIds
    : [...state.unreadConversationIds, conversationId];

  return {
    ...state,
    unreadConversationIds,
  };
};

export const setConversationsHandler = (
  state: ConversationsState,
  newConversations: SetConversationsAction['payload']
): ConversationsState => ({
  ...state,
  conversations: newConversations,
});

export const setConverastionsTotalCountHandler = (
  state: ConversationsState,
  conversationsTotalCount: SetConversationsTotalCountAction['payload']
): ConversationsState => ({ ...state, conversationsTotalCount });

export const deleteConversationHandler = (
  state: ConversationsState,
  conversationId: DeleteConversationAction['payload']
): ConversationsState => ({
  ...state,
  conversationsTotalCount: state.conversationsTotalCount - 1,
  conversations: state.conversations.filter(({ id }) => id !== conversationId),
});

export const conversationSelectHandler = (
  state: ConversationsState,
  activeConversationId: HandleConversationSelectAction['payload']
): ConversationsState => {
  const unreadConversationsWithoutCurrent = state.unreadConversationIds.filter(
    (conversationId) => conversationId !== activeConversationId
  );

  return {
    ...state,
    activeConversationId,
    interlocutorsForNewConversation: [],
    unreadConversationIds: unreadConversationsWithoutCurrent,
  };
};

export const handleInterlocutorTrigger = (
  state: ConversationsState,
  user: HandleInterlocutorTrigger['payload']
): ConversationsState => {
  const isInterlocutorInState = state.interlocutorsForNewConversation?.find(
    ({ id }) => id === user.id
  );

  const removeInterlocutorFromState = (usetToRemove: Profile) =>
    state.interlocutorsForNewConversation?.filter(
      ({ id }) => id !== usetToRemove.id
    );

  const addInterlocutorToState = (userToAdd: Profile) => [
    ...state.interlocutorsForNewConversation,
    userToAdd,
  ];

  const newInterlocutorsState = isInterlocutorInState
    ? removeInterlocutorFromState(user)
    : addInterlocutorToState(user);

  return {
    ...state,
    interlocutorsForNewConversation: newInterlocutorsState,
  };
};

export const conversationSelectByUserHandler = (
  state: ConversationsState,
  user: HandleSelectConversationByUserAction['payload']
): ConversationsState => {
  const { conversations } = state;

  const privateConversations = conversations.filter(
    (conversation) => !isGroupChat(conversation)
  );

  const existingConversation = privateConversations.find(
    (conversation) =>
      !!conversation.members.find((profile) => profile.id === user.id)
  );

  if (existingConversation) {
    return conversationSelectHandler(state, existingConversation.id);
  }

  return {
    ...state,
    activeConversationId: undefined,
    interlocutorsForNewConversation: [user],
  };
};
