import { SimpleGrid, VStack } from '@chakra-ui/react';

import { useFamilyValuesWithRelatedSdg } from 'hooks/onboarding/useFamilyValuesWithRelatedSdg';

import { useGetOnboardContent } from '../../../hooks';
import { SectionHeading } from '../../SectionHeader';
import { Linkage } from './Linkage';

export const SdgLinkage = () => {
  const { familyValuesWithRelatedSdg } = useFamilyValuesWithRelatedSdg();
  const {
    sdg: { linkedSdgsTitle },
  } = useGetOnboardContent();

  return (
    <VStack w="full" pt={20}>
      <SectionHeading
        size="h3"
        heading={linkedSdgsTitle}
        color="primary.blue.default"
      />
      <SimpleGrid
        my={4}
        mx="40%"
        w="full"
        columns={{ base: 2, lg: 4 }}
        width={{ base: 540, lg: 1080 }}
      >
        {familyValuesWithRelatedSdg?.map((values) => (
          <Linkage
            valueName={values.name}
            category={values.category}
            sdgs={values.sdg}
            key={values.id}
          />
        ))}
      </SimpleGrid>
    </VStack>
  );
};
