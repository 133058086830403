import { useEffect, useState } from 'react';

import { Text } from '@chakra-ui/react';

import { formatTime } from 'utils/date';

type Props = {
  setIsResendMessage: (isResendMessage: boolean) => void;
};

export const ResendCountdown = ({ setIsResendMessage }: Props) => {
  const [counter, setCounter] = useState(60);

  useEffect(() => {
    const timer = setInterval(() => setCounter((prev) => prev - 1), 1000);

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [setIsResendMessage]);

  useEffect(() => {
    if (!counter) {
      setIsResendMessage(false);
    }
  }, [counter, setIsResendMessage]);

  return (
    <Text size="p2" mb={14}>
      A new code will be sent in <b>{formatTime(counter)}</b>
    </Text>
  );
};
