import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ProgressBar } from 'components/ProgressBar';
import { QueryStateGuard } from 'components/QueryStateGuard';
import { useCurrentLibraryLocation } from 'features/Library/hooks/useCurrentLibraryLocation';
import { mapLibraryLocationToLibraryType } from 'features/Library/utils/mapLibraryLocationToLibraryType';
import { useGetLibraryDetails } from 'gql/library/query/getLibraryDetails';
import { humanFileSize } from 'utils/size';

export const LibraryDataUsage = () => {
  const { libraryLocation } = useCurrentLibraryLocation();
  const { t } = useTranslation(['library']);

  const { libraryDetails, loading, error, refetch } = useGetLibraryDetails({
    libraryType: mapLibraryLocationToLibraryType(libraryLocation),
  });

  useEffect(() => {
    refetch();
  }, [libraryLocation, refetch]);

  return (
    <QueryStateGuard loading={loading} error={error}>
      {libraryDetails && (
        <ProgressBar
          max={libraryDetails.maximumSize || Infinity}
          value={libraryDetails.usedSize}
          bottomText={t('library:side-navigation.space-usage', {
            used: humanFileSize(libraryDetails.usedSize),
            // eslint-disable-next-line prettier/prettier
                available: humanFileSize(
                  libraryDetails.maximumSize || Infinity
                ),
          })}
        />
      )}
    </QueryStateGuard>
  );
};
