import { useTranslation } from 'react-i18next';

import { Flex, Spinner, Text } from '@chakra-ui/react';

export const Loading = () => {
  const { t } = useTranslation(['common']);

  return (
    <Flex
      h="full"
      justifyContent="center"
      alignItems="center"
      direction="column"
      gap="4"
    >
      <Spinner
        size="xl"
        thickness="0.5rem"
        color="primary.blue.default"
        emptyColor="ui.grey4"
      />
      <Text>{t('common:search.searching')}</Text>
    </Flex>
  );
};
