import { Form, FormikProvider, useFormik } from 'formik';

import { KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Image as ImageIcon, SendAlt } from '@carbon/icons-react/next';
import {
  Button,
  HStack,
  Icon,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';

import { FileUploadModal } from 'components/FileUploadModal';
import { TextAreaInput } from 'components/Form/TextAreaInput';
import { ImagesPreview } from 'components/ImagesPreview';

import { CommentFormBaseProps, FormValues } from './types';
import { PostSchema } from './validation';

export const CommentFormBase = ({
  handleSubmit,
  initialValues,
  placeholder,
}: CommentFormBaseProps) => {
  const { t } = useTranslation(['network', 'common']);

  const formik = useFormik<FormValues>({
    initialValues,
    validationSchema: PostSchema,
    onSubmit: async (values, { resetForm }) => {
      await handleSubmit(values);

      resetForm();
    },
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { values, isSubmitting, setFieldValue } = formik;

  const handleKeyboardSend = (event: KeyboardEvent) => {
    if (event.shiftKey && event.key === 'Enter') {
      event.stopPropagation();
      event.preventDefault();
      formik.handleSubmit();
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form style={{ width: '100%', padding: '0.75rem 0' }}>
        <TextAreaInput
          onKeyDown={handleKeyboardSend}
          type="text"
          name="text"
          placeholder={placeholder || t('network:write-your-comment')}
          bg="ui.grey5"
          p={3}
          borderRadius={0}
          border="none"
          borderBottom="1px solid"
          borderColor="primary.blue.default"
          minHeight={12}
        />

        {!isOpen && <ImagesPreview images={values.upload} mt={2} />}

        <HStack justify="space-between">
          <IconButton
            display="contents"
            aria-label={t('network:choose-image')}
            color="primary.blue.default"
            icon={<Icon as={ImageIcon} h={4} w={4} />}
            onClick={onOpen}
          />
          <Button
            mt={0}
            p={0}
            isLoading={isSubmitting}
            type="submit"
            variant="ghost"
            rightIcon={<SendAlt />}
          >
            {t('common:send')}
          </Button>
        </HStack>
        <FileUploadModal
          isOpen={isOpen}
          setFieldValue={setFieldValue}
          onClose={onClose}
        />
      </Form>
    </FormikProvider>
  );
};
