import { Position } from '../types';
import { Dimensions } from '../types/Dimensions';

type TOptions = { position?: Position; dimensions: Dimensions };

export const getVerticalPosition = ({
  position,
  dimensions,
}: TOptions): number => {
  const { height, margin } = dimensions;

  switch (position) {
    case 'end':
      return height;
    case 'center':
      return height / 2;
    case 'start':
    default:
      return margin.top;
  }
};

export const getHorizontalPosition = ({
  position,
  dimensions,
}: TOptions): number => {
  const { width, margin } = dimensions;

  switch (position) {
    case 'end':
      return width - margin.right;
    case 'center':
      return width / 2;
    case 'start':
    default:
      return margin.left;
  }
};
