import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Search20 } from '@carbon/icons-react';
import { Flex, Text, useOutsideClick } from '@chakra-ui/react';

import { useSearchContext } from 'contexts/SearchContext';

export const NoMatchedResults = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation(['common']);

  const { closeSearchPreview } = useSearchContext();

  useOutsideClick({ ref, handler: closeSearchPreview });

  return (
    <Flex
      ref={ref}
      direction="column"
      alignItems="center"
      px={6}
      py={5}
      gap={2}
    >
      <Text size="p1" textAlign="center">
        {t('common:search.no-results-heading')}
      </Text>
      <Search20 />
      <Text size="p3" textAlign="center">
        {t('common:search.no-results-text')}
      </Text>
    </Flex>
  );
};
