import { useMemo } from 'react';

import { useAuthContext } from 'contexts/AuthContext';
import { Role } from 'types';

export const useRoleValidation = () => {
  const { account } = useAuthContext();

  const isAdmin = useMemo(() => {
    return account?.role === Role.Admin;
  }, [account?.role]);

  return { isAdmin };
};
