import { Comment as CommentType } from 'types';

import { CommentBase } from './CommentBase';
import { CommentResponses } from './CommentResponses';

type Props = {
  comment: CommentType;
};

export const Comment = ({ comment }: Props) => {
  return (
    <>
      <CommentBase comment={comment} />
      <CommentResponses id={comment.id} comments={comment.comments} />
    </>
  );
};
