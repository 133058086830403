import { gql, useQuery } from '@apollo/client';

import { GroupInternalFragment } from 'gql/fragments';
import { GroupInternalType } from 'types';

export const groupsGql = gql`
  ${GroupInternalFragment}
  query Groups {
    groups {
      ...GroupInternalFragment
    }
  }
`;

export const useGroups = () => {
  const { data, loading, error } = useQuery<{ groups: GroupInternalType[] }>(
    groupsGql,
    {
      fetchPolicy: 'cache-and-network',
    }
  );

  const groups = data?.groups ?? [];

  return { groups, loading, error };
};
