import { Box } from '@chakra-ui/react';

import { ImportanceScores } from './ImportanceScores';
import { RelevanceScores } from './RelevanceScores';
import { ScoreSection } from './ScoreSection';

export const IndividualScores = (): JSX.Element => {
  return (
    <Box w="full">
      <ScoreSection
        heading="Importance"
        description="Importance refers to the extent by which one views this value as desirable."
      >
        <ImportanceScores />
      </ScoreSection>

      <ScoreSection
        heading="Relevance"
        description="Relevance refers to the extent by which the
          value might enable the family to make a meaningful difference (in the
          specific family context)."
      >
        <RelevanceScores />
      </ScoreSection>
    </Box>
  );
};
