import { Link } from 'react-router-dom';

import { Flex, Heading, Image, Text } from '@chakra-ui/react';

import { ItriomTag } from 'components/ItriomTag';
import { useInitiatives } from 'hooks/initiatives';
import { Links } from 'router/links';
import { InitiativeOutput, InitiativeStatusEnum } from 'types';
import { generateUrlById } from 'utils/links';
import { flattenTags } from 'utils/tags';

import { CarouselBase } from '../CarouselBase';

const InitiativeCard = ({ item }: { item: InitiativeOutput }) => {
  const { id, hero, tags } = item;
  const { heroImage, headline, summary } = hero;

  return (
    <Flex
      as={Link}
      to={generateUrlById(Links.initiativesSingle, id)}
      key={id}
      maxW={640}
      minW={640}
      h={480}
      position="relative"
      _hover={{ '& img': { transform: 'scale(1.05)' } }}
      overflow="hidden"
    >
      <Image
        src={heroImage.signedUrl}
        w="full"
        h="full"
        transition="transform ease-out 0.2s"
        objectFit="cover"
        objectPosition="center"
      />
      <Flex
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        p={10}
        gap={4}
        direction="column"
        bgColor="rgba(0, 0, 0, 0.25)"
        color="ui.white"
      >
        <Heading size="h3" noOfLines={2}>
          {headline}
        </Heading>
        <Text size="p1">{summary}</Text>
        <Flex wrap="wrap" gap={2}>
          {flattenTags(tags).map((tag) => (
            <ItriomTag key={tag} customVariant="outlineLight">
              {tag}
            </ItriomTag>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};

export const CarouselInitiatives = () => {
  const { initiatives } = useInitiatives({
    filters: {
      status: InitiativeStatusEnum.Published,
      visibilityFilters: { featured: true },
    },
    pagination: { limit: 4, offset: 0 },
  });

  return (
    <CarouselBase
      items={initiatives}
      ItemComponent={InitiativeCard}
      spaceBetween={16}
      swiperStyles={{ padding: '0 80px' }}
      hasPagination={false}
    />
  );
};
