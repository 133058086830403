import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
  useState,
} from 'react';

import { ApolloError } from '@apollo/client';

import { QueryStateGuard } from 'components/QueryStateGuard';
import { WithChildren } from 'types';

import {
  composeConversationsActions,
  composeConversationsSelectors,
  ConversationsActions,
  ConversationsSelectors,
} from './Conversations';
import {
  composeMessagesSelectors,
  MessagesActions,
  MessagesSelectors,
  composeMessagesActions,
} from './Messages';
import { combinedReducer } from './combinedReducer';
import { CombinedState } from './types';

type MessagingContextType = {
  messagesActions: MessagesActions;
  messagesSelectors: MessagesSelectors;
  conversationsActions: ConversationsActions;
  conversationsSelectors: ConversationsSelectors;
  reportError: (e: ErrorType) => void;
};

const MessagingStateContext = createContext<MessagingContextType | undefined>(
  undefined
);

export const useMessagingStateContext = () => {
  const context = useContext(MessagingStateContext);

  if (!context) {
    throw new Error('useMessagingStateContext used outside of its provider');
  }

  return context;
};

const initialState: CombinedState = {
  conversations: {
    activeConversationId: '',
    conversations: [],
    conversationsTotalCount: 0,
    unreadConversationIds: [],
    interlocutorsForNewConversation: [],
  },
  messages: {
    messagesMap: {},
    messagesTotalCountMap: {},
  },
};

type ErrorType = Error | ApolloError;

export const MessagingStateContextProvider = ({ children }: WithChildren) => {
  const [state, dispatch] = useReducer(combinedReducer, initialState);

  const conversationsSelectors = useMemo(
    () => composeConversationsSelectors(state.conversations),
    [state.conversations]
  );

  const messagesSelectors = useMemo(
    () => composeMessagesSelectors(state.messages),
    [state.messages]
  );

  const messagesActions = composeMessagesActions(dispatch);

  const conversationsActions = composeConversationsActions(dispatch);

  const [error, setError] = useState<ErrorType | undefined>();

  const reportError = useCallback((e: ErrorType) => setError(e), []);

  const value = useMemo(
    () => ({
      conversationsActions,
      conversationsSelectors,
      messagesActions,
      messagesSelectors,
      reportError,
    }),
    [
      conversationsActions,
      conversationsSelectors,
      messagesActions,
      messagesSelectors,
      reportError,
    ]
  );

  return (
    <MessagingStateContext.Provider value={value}>
      <QueryStateGuard error={error}>{children}</QueryStateGuard>
    </MessagingStateContext.Provider>
  );
};
