import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useValueConfiguratorValues } from 'hooks/onboarding/useValueConfiguratorValues';
import { Links } from 'router/links';
import { ValueConfiguratorStatusEnum } from 'types';

export const useValuesConfigurator = () => {
  const { valueConfigurator, loading, error } = useValueConfiguratorValues();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const categoryName = pathname.split('/');
  const isRelevance = categoryName[3] === 'relevance';

  useEffect(() => {
    if (
      !isRelevance &&
      valueConfigurator?.status ===
        ValueConfiguratorStatusEnum.ImportanceFinished
    ) {
      navigate(Links.valuesConfiguratorImportanceToRelevance);
    }

    if (valueConfigurator?.status === ValueConfiguratorStatusEnum.Completed) {
      navigate(Links.valuesConfiguratorScores);
    }
  }, [valueConfigurator, isRelevance, navigate]);

  return { valueConfigurator, loading, error };
};
