import { Link } from 'react-router-dom';

import { Button } from '@chakra-ui/react';

type ViewButtonProps = {
  to: string;
  children: React.ReactNode;
};

export const ViewButton = ({ to, children }: ViewButtonProps) => {
  return (
    <Button variant="secondaryLime" p={0} w={130} mb={10} as={Link} to={to}>
      {children}
    </Button>
  );
};
