import { useState } from 'react';

import { ChevronDownIcon } from '@chakra-ui/icons';
import { Box } from '@chakra-ui/layout';
import { Button, Flex } from '@chakra-ui/react';

import { MessageProfile } from 'components/ProfileCard/MessageProfile';
import { Profile } from 'types';

import { ActionMenuLayout } from '../ActionMenuLayout';

const MAX_NUMBERS_OF_PROFILES = 3;
const MAX_PROFILE_HEIGHT = 50;

type Props = {
  members: Profile[];
};

const COLOR_PROPS = { color: 'primary.blue.default', bg: 'ui.grey4' };
const BUTTON_STATE_PROPS = { bg: 'inherit', color: 'inherit' };

export const ConversationHeaderActionMenu = ({ members }: Props) => {
  const [isViewAllEnabled, setIsViewAllEnabled] = useState(false);

  const handleViewAll = () => setIsViewAllEnabled(true);

  const shouldHaveScroll =
    !isViewAllEnabled && members.length > MAX_NUMBERS_OF_PROFILES;

  return (
    <ActionMenuLayout
      buttonStyling={{ color: 'primary.blue.default' }}
      menuListStyling={{
        border: 'none',
        borderRadius: 0,
        ...COLOR_PROPS,
      }}
      withCloseIcon
    >
      <Box
        {...(shouldHaveScroll && {
          maxH: MAX_PROFILE_HEIGHT * MAX_NUMBERS_OF_PROFILES,
          overflowY: 'scroll',
        })}
      >
        {members.map((member) => (
          <MessageProfile
            profile={member}
            key={member.id}
            {...COLOR_PROPS}
            maxH={MAX_PROFILE_HEIGHT}
            _hover={{ bg: 'inherit' }}
          />
        ))}
      </Box>
      {shouldHaveScroll && (
        <Button
          ml="auto"
          h="30px"
          w="full"
          onClick={handleViewAll}
          rightIcon={<ChevronDownIcon />}
          _hover={BUTTON_STATE_PROPS}
          _active={BUTTON_STATE_PROPS}
          _focus={BUTTON_STATE_PROPS}
        >
          <Flex w="full" justify="flex-end" pr="10px">
            View all
          </Flex>
        </Button>
      )}
    </ActionMenuLayout>
  );
};
