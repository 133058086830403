import { useQuery } from '@apollo/client';

import {
  LibraryMediaOutput,
  LibrarySharedMediaOutput,
  Query,
  QueryGetDownloadUrlForLibraryFileArgs,
} from 'types';

import { getDownloadUrlForLibraryFileGql } from '../documents';

export const useGetDownloadUrlForLibraryFile = (
  item: LibraryMediaOutput | LibrarySharedMediaOutput
) => {
  const { data, error, loading } = useQuery<
    Pick<Query, 'getDownloadUrlForLibraryFile'>,
    Partial<QueryGetDownloadUrlForLibraryFileArgs>
  >(getDownloadUrlForLibraryFileGql, {
    variables: {
      libraryType: item.libraryType,
      fileId: item.id,
    },
    skip: item.isDirectory,
  });

  const downloadUrl = data?.getDownloadUrlForLibraryFile;

  return {
    downloadUrl,
    loading,
    error,
  };
};
