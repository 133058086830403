import { useField } from 'formik';

import {
  Flex,
  FormControl,
  FormLabel,
  Switch as ChakraSwitch,
  SwitchProps,
  Text,
} from '@chakra-ui/react';

type Props = {
  icon?: React.ReactNode;
  label: string;
  description?: string;
  name: string;
  onChange?: (value: boolean) => void;
} & Omit<SwitchProps, 'onChange'>;

export const Switch = ({
  icon = null,
  label,
  description = '',
  name,
  onChange,
  ...props
}: Props) => {
  const [field] = useField({ name });

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    field.onChange(event);

    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <FormControl display="flex" alignItems="center" gap={2}>
      <Flex w={7} alignItems="center" justify="center">
        {icon}
      </Flex>
      <FormLabel
        display="flex"
        flex={1}
        flexDirection="column"
        m={0}
        htmlFor={name}
      >
        <Text size="p2b">{label}</Text>
        <Text size="p3b" color="ui.grey2">
          {description}
        </Text>
      </FormLabel>
      <ChakraSwitch
        {...field}
        isChecked={field.value}
        id={name}
        onChange={handleToggle}
        {...props}
      />
    </FormControl>
  );
};
