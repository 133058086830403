import { Heading, Text, Flex } from '@chakra-ui/react';

import { MultiCarousel } from 'components/MultiCarousel';
import { QueryStateGuard } from 'components/QueryStateGuard';
import { useEventsPublishedPaginated } from 'hooks/events';
import { getCurrentDateWithRounding } from 'utils/date';

import { SingleEventCard } from './SingleEventCard';

export const PastEventList = () => {
  const { eventsPublishedPaginated, loading, error } =
    useEventsPublishedPaginated({
      filters: {
        toDate: new Date(getCurrentDateWithRounding()).toISOString(),
      },
    });

  return (
    <Flex bgColor="ui.grey4" py={8} px={4}>
      <Flex direction="column" mx="auto" maxW={960} w="full" gap={3}>
        <Heading size="h4">Past events</Heading>
        <Text size="p1" maxW="24rem">
          Other events around the world that you may be interested in but have
          already been and gone.
        </Text>
        <QueryStateGuard loading={loading} error={error}>
          <MultiCarousel
            items={eventsPublishedPaginated}
            renderItem={(item) => <SingleEventCard event={item} />}
            spaceBetween={21}
          />
        </QueryStateGuard>
      </Flex>
    </Flex>
  );
};
