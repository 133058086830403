import { gql, useMutation } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { createMock } from 'mocks';
import { familyCharterMock } from 'mocks/familyCharter.mock';
import {
  Mutation,
  MutationChangeFamilyCharterPublicationStateArgs,
} from 'types';
import { statusToastOptions } from 'utils/status';

export const changeFamilyCharterPublicationStateGql = gql`
  mutation ChangeFamilyCharterPublicationState($id: String!) {
    changeFamilyCharterPublicationState(id: $id) {
      id
    }
  }
`;

export const changeFamilyCharterPublicationStateMock = createMock<
  Pick<Mutation, 'changeFamilyCharterPublicationState'>,
  MutationChangeFamilyCharterPublicationStateArgs
>(
  changeFamilyCharterPublicationStateGql,
  {
    data: { changeFamilyCharterPublicationState: { id: familyCharterMock.id } },
  },
  { id: familyCharterMock.id }
);

export const useChangeFamilyCharterPublicationState = (id: string) => {
  const [changeStatus, { loading }] = useMutation<
    Pick<Mutation, 'changeFamilyCharterPublicationState'>,
    MutationChangeFamilyCharterPublicationStateArgs
  >(changeFamilyCharterPublicationStateGql, {
    refetchQueries: ['FamilyCharterForFamilyMember', 'FamilyCharter'],
  });

  const handleChange = () =>
    changeStatus({
      variables: { id },
    });

  const publishFamily = useWithMutationToast(handleChange, statusToastOptions);

  return { publishFamily, loading };
};
