import { Flex } from '@chakra-ui/react';

import { CardWithImage } from 'components/CardWithImage';
import { GroupsListSection } from 'components/GroupsListSection';
import { SingleGroupCard } from 'components/SingleGroupCard';
import { useRandomRecommendedGroups } from 'hooks/groups';

type Props = {
  withList?: boolean;
};

export const RecommendedGroups = ({ withList = true }: Props) => {
  const { randomRecommendedGroups, loading, error } =
    useRandomRecommendedGroups();

  const showOnlyList = randomRecommendedGroups.length === 1;
  const cardGroups = randomRecommendedGroups.slice(0, 2);
  const listGroups = randomRecommendedGroups.slice(2);

  return (
    <GroupsListSection
      heading="Recommended"
      loading={loading}
      error={error}
      showChildren={!!randomRecommendedGroups.length}
    >
      {showOnlyList ? (
        randomRecommendedGroups[0] && (
          <SingleGroupCard group={randomRecommendedGroups[0]} />
        )
      ) : (
        <>
          <Flex wrap="wrap" justify="space-between" gap={2}>
            {cardGroups.map((group) => (
              <CardWithImage
                type="Group"
                data={group}
                key={group.id}
                flex={1}
                w="auto"
                maxW="50%"
              />
            ))}
          </Flex>
          {withList &&
            listGroups.map((group) => (
              <SingleGroupCard
                key={group.id}
                group={group}
                variant="secondary"
              />
            ))}
        </>
      )}
    </GroupsListSection>
  );
};
