import { useCallback } from 'react';

import { useMutation } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationSendMessageArgs } from 'types';

import { NewConversationInput } from '../types';
import { sendMessageGql } from './sendMessageGql';

export const useCreateConversation = () => {
  const [sendMessageMutation] = useMutation<
    Pick<Mutation, 'sendMessage'>,
    MutationSendMessageArgs
  >(sendMessageGql);

  const createConversation = useCallback(
    async (newConversation: NewConversationInput) => {
      const result = await sendMessageMutation({
        variables: { message: newConversation },
      });

      return result.data?.sendMessage.conversationId;
    },
    [sendMessageMutation]
  );

  const createConversationWithToast = useWithMutationToast(createConversation, {
    successMessage: 'Successfully created conversation',
    errorMessage: 'Error creating conversation',
  });

  return createConversationWithToast;
};
