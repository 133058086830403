import { useTranslation } from 'react-i18next';

import { FlexProps } from '@chakra-ui/layout';
import { Avatar, Flex, Text } from '@chakra-ui/react';

import { ViewButton } from 'components/Button';
import { Links } from 'router/links';
import { generateUrlById } from 'utils/links';

import type { Initiative } from '../../../types';

type Props = Initiative & FlexProps;

export const InitiativePanel = ({ id, heroImage, name }: Props) => {
  const { t } = useTranslation(['common']);

  const initiativeLink = generateUrlById(Links.initiativesSingle, id ?? '');

  return (
    <Flex
      align="center"
      w="full"
      background="ui.white"
      border="default"
      px={4}
      gap={2}
    >
      <Avatar src={heroImage?.signedUrl} my={4} w="41px" h="41px" />

      <Flex direction="column" w="full">
        <Text size="p3">{t('common:initiative')}</Text>
        <Text size="p1">{name}</Text>
      </Flex>

      <ViewButton
        variant="blue"
        to={initiativeLink}
        containerStyling={{ ml: 'auto', minW: 'fit-content' }}
      >
        {t('common:cta.viewInitiative')}
      </ViewButton>
    </Flex>
  );
};
