import { Tab } from 'components/TabPane';
import { InsightItriomVisibilityTypes } from 'types';

import { InsightTabPanel } from './components';
import { AllTabContent } from './components/AllTabContent';

enum TabKey {
  All = 'ALL',
}

type InsightsTabs = InsightItriomVisibilityTypes | TabKey;

export const insightsTabsConfig: Record<InsightsTabs, Tab> = {
  [TabKey.All]: {
    content: <AllTabContent />,
    navigateTo: `#${TabKey.All}`,
    labelKey: 'all',
  },
  [InsightItriomVisibilityTypes.Family]: {
    content: <InsightTabPanel type={InsightItriomVisibilityTypes.Family} />,
    navigateTo: `#${InsightItriomVisibilityTypes.Family}`,
    labelKey: 'family',
  },
  [InsightItriomVisibilityTypes.Itriom]: {
    content: <InsightTabPanel type={InsightItriomVisibilityTypes.Itriom} />,
    navigateTo: `#${InsightItriomVisibilityTypes.Itriom}`,
    labelKey: 'itriom',
  },
  [InsightItriomVisibilityTypes.Peers]: {
    content: <InsightTabPanel type={InsightItriomVisibilityTypes.Peers} />,
    navigateTo: `#${InsightItriomVisibilityTypes.Peers}`,
    labelKey: 'peers',
  },
  [InsightItriomVisibilityTypes.Public]: {
    content: <InsightTabPanel type={InsightItriomVisibilityTypes.Public} />,
    navigateTo: `#${InsightItriomVisibilityTypes.Public}`,
    labelKey: 'public',
  },
};
