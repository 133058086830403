import { Text } from '@chakra-ui/react';

export const MaxLengthInfo = ({
  maxLength,
  field,
}: {
  maxLength: number;
  field: { value?: string };
}): JSX.Element => {
  const fieldLength = field.value?.length || 0;

  return (
    <Text
      size="p2"
      as="span"
      color={fieldLength > maxLength ? 'negative' : 'inherit'}
    >
      {fieldLength} / {maxLength}
    </Text>
  );
};
