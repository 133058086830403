import { gql, useMutation } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import {
  EventStatusEnum,
  Mutation,
  MutationChangeEventStatusArgs,
} from 'types';
import { statusToastOptions } from 'utils/status';

import { eventGql } from './useEvent';

export const changeEventStatusGql = gql`
  mutation ChangeEventStatus($event: ChangeEventStatusInput!) {
    changeEventStatus(event: $event) {
      id
    }
  }
`;

export const useChangeEventStatus = (
  id: string,
  currentStatus: EventStatusEnum
) => {
  const [changeStatus, { loading }] = useMutation<
    Pick<Mutation, 'changeEventStatus'>,
    MutationChangeEventStatusArgs
  >(changeEventStatusGql, {
    refetchQueries: [eventGql],
  });

  const handleChange = () =>
    changeStatus({
      variables: {
        event: {
          id,
          status:
            currentStatus === EventStatusEnum.Published
              ? EventStatusEnum.Draft
              : EventStatusEnum.Published,
        },
      },
    });

  const publishEvent = useWithMutationToast(handleChange, statusToastOptions);

  return { publishEvent, loading };
};
