import { Form, FormikProvider, useFormik } from 'formik';

import { FormErrorMessage, Flex, FormControl, Box } from '@chakra-ui/react';

import { DraggableProvider } from 'components/Draggable';
import { useSubmitPersonalSdgs } from 'hooks/sdgs/useSubmitPersonalSdgs';
import { SdgConfiguratorOutput, SdgFormType, SdgOutput } from 'types';

import {
  SdgBank,
  SdgInputSlots,
  SdgOrderHeader,
  SubmitButtonWithModal,
} from './components';
import { useSelectionItems } from './useSelectionItems';
import { mapSdgToDragItem, getInitialValues } from './utils';

type Props = {
  availableSdgs: SdgOutput[];
  personalSdgs?: SdgConfiguratorOutput;
};

export const SdgOrderForm = ({ availableSdgs, personalSdgs }: Props) => {
  const mappedSdgs = availableSdgs.map(mapSdgToDragItem);

  const { initialAvailable, initialSelected } = getInitialValues(
    mappedSdgs,
    personalSdgs?.sdgIdsWithNumericScores
  );

  const handleSubmit = useSubmitPersonalSdgs();

  const form = useFormik<SdgFormType>({
    initialValues: { selected: initialSelected },
    onSubmit: handleSubmit,
  });

  const { availableItems, selectedItems, moveToSelected, moveToAvailable } =
    useSelectionItems({
      initialAvailable,
      initialSelected,
      changeObserver: (items) => {
        form.setFieldValue('selected', items);
      },
    });

  const errorMessage =
    selectedItems.length !== mappedSdgs.length && 'All sdgs must be ordered';

  return (
    <FormikProvider value={form}>
      <Box>
        <Form>
          <Flex
            color="ui.white"
            px="auto"
            py={12}
            gap={4}
            align="flex-start"
            direction="column"
            w={1080}
            mx={16}
          >
            <SdgOrderHeader />
            <DraggableProvider>
              <SdgBank
                allBankSdgs={availableSdgs}
                availableItems={availableItems}
                moveToAvailable={moveToAvailable}
              />
              <SdgInputSlots
                maxItems={availableSdgs.length}
                onSelect={moveToSelected}
                selectedItems={selectedItems}
              />
            </DraggableProvider>

            <FormControl display="flex" isInvalid={!!errorMessage}>
              <FormErrorMessage ml="auto">{errorMessage}</FormErrorMessage>
            </FormControl>

            <SubmitButtonWithModal
              onSubmit={form.submitForm}
              disabled={!!errorMessage}
            />
          </Flex>
        </Form>
      </Box>
    </FormikProvider>
  );
};
