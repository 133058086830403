import { InfoIcon } from '@chakra-ui/icons';
import {
  Box,
  Modal,
  ModalCloseButton,
  ModalContent,
  useDisclosure,
  IconButton,
} from '@chakra-ui/react';

import { HowToUse } from './HandOff';

export const GuideModal = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <IconButton
        aria-label="open content"
        onClick={onOpen}
        fontSize={20}
        icon={<InfoIcon />}
        isRound
        size="sm"
      />
      <Box position="absolute">
        <Modal isOpen={isOpen} onClose={onClose} size="3xl">
          <ModalContent
            px={4}
            py={12}
            borderRadius="12px"
            border="5px solid #E5E5E5"
            boxShadow="22px 22px 19px rgba(0, 0, 0, 0.45)"
          >
            <ModalCloseButton />
            <HowToUse isFromModal />
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
};
