import { useTranslation } from 'react-i18next';

import { HoverButton } from 'components/Button';
import { useAttendToEvent, useUnAttendToEvent } from 'hooks/events';

type ButtonProps = {
  eventId: string;
};

const FollowEventButton = ({ eventId }: ButtonProps) => {
  const { attendToEvent, loading } = useAttendToEvent(eventId);
  const { t } = useTranslation(['events']);

  return (
    <HoverButton onClick={attendToEvent} isLoading={loading}>
      {t('events:attend')}
    </HoverButton>
  );
};

const UnAttendEventButton = ({ eventId }: ButtonProps) => {
  const { loading, unAttendToEvent } = useUnAttendToEvent(eventId);
  const { t } = useTranslation(['events']);

  return (
    <HoverButton
      isLoading={loading}
      onClick={unAttendToEvent}
      hover={{ content: t('events:unattend') }}
    >
      {t('events:attending')}
    </HoverButton>
  );
};

type EventAttendButtonProps = {
  eventId: string;
  isAttending: boolean;
};

export const EventAttendButton = ({
  eventId,
  isAttending,
}: EventAttendButtonProps) => {
  if (isAttending) return <UnAttendEventButton eventId={eventId} />;

  return <FollowEventButton eventId={eventId} />;
};
