import { useTranslation } from 'react-i18next';

import { UserForm } from 'features/Cms/components/UserForm';
import { UpdateUserFormFields, UserFormFieldName } from 'features/Cms/types';
import { useUpdateFamilyMember } from 'gql/family/mutation/updateFamilyMember';
import { Links } from 'router/links';
import { ExtendedProfileOutput } from 'types';

import { useEditUserInitialValues } from '../hooks/useEditUserInitialValues';

type Props = {
  extendedProfile: ExtendedProfileOutput;
};

export const UpdateMemberByFamilyAdmin = ({ extendedProfile }: Props) => {
  const { t } = useTranslation(['cms']);

  const initialValues = useEditUserInitialValues(extendedProfile);

  const { updateFamilyMember } = useUpdateFamilyMember();

  const handleSubmit = async ({
    email,
    dateOfBirth,
    phone,
    honorific,
  }: UpdateUserFormFields) => {
    await updateFamilyMember({
      updateAccount: {
        honorific,
        dateOfBirth,
        email,
        id: extendedProfile.id,
        phone,
      },
    });
  };

  return (
    <UserForm
      isEditing
      buttonText={t('cms:member.update-details')}
      backLink={Links.familyManagement}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      hiddenFields={[
        UserFormFieldName.IsHeadOfFamily,
        UserFormFieldName.IsFamilyAdmin,
        UserFormFieldName.FamilyId,
      ]}
    />
  );
};
