import { useState } from 'react';
import { Link } from 'react-router-dom';

import { ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { MenuGroup, MenuItem, MenuDivider } from '@chakra-ui/react';

import { DropdownMenuItem } from 'router/links';

interface Props {
  menuItem: DropdownMenuItem;
}

export const MobileDropdownItems = ({ menuItem }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = (
    event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>
  ) => {
    event.stopPropagation();
    event.preventDefault();
    setIsOpen((prev) => !prev);
  };

  if (isOpen) {
    return (
      <MenuGroup key={menuItem.label} onClick={toggle} cursor="pointer">
        <MenuItem closeOnSelect={false} onClick={toggle}>
          {menuItem.label}
          <ChevronUpIcon color="inherit" boxSize={6} py="auto" />
        </MenuItem>
        {menuItem.dropdownItems.map(({ link, label }) => (
          <MenuItem key={label} as={Link} to={link}>
            {label}
          </MenuItem>
        ))}
        <MenuDivider />
      </MenuGroup>
    );
  }

  return (
    <MenuItem closeOnSelect={false} onClick={toggle}>
      {menuItem.label} <ChevronDownIcon color="inherit" boxSize={6} py="auto" />
    </MenuItem>
  );
};
