import { useField } from 'formik';

import { Box, Flex, Grid, Heading, HStack, Text } from '@chakra-ui/react';

import { SliderInput } from 'components/Form/SliderInput';
import { capitalize } from 'utils/string';

import { ValueField, ValuesInputs } from '../types';

type Props<T extends ValuesInputs> = {
  field: ValueField<T>;
};

export const SliderField = <T extends ValuesInputs>({
  field,
}: Props<T>): JSX.Element => {
  const { definition, variant } = field;
  const name = String(field.name);

  const [meta] = useField(name);

  return (
    <Grid py={3} gridTemplateColumns={{ base: '1fr', md: '3fr 1fr 2fr' }}>
      <HStack
        ml={{ md: 16 }}
        bgColor="ui.grey3"
        borderRadius="4px"
        minH={12}
        alignItems="center"
        h="full"
        pl="2"
      >
        <Text minW="20%" size="p2b">
          {capitalize(name)}
        </Text>

        <Text size="p3">{definition}</Text>
      </HStack>

      <Flex justifyContent="center" alignItems="center">
        <Heading size="h4">{meta.value}</Heading>
      </Flex>
      <Box>
        <SliderInput name={name} variant={variant} />
      </Box>
    </Grid>
  );
};
