import { useTranslation } from 'react-i18next';

import { Flex, Heading } from '@chakra-ui/react';

import { ImageTextTile } from 'components/ImageTextTile';

import { INSIGHTS } from '../../content/services';

export const InsightsSection = () => {
  const { t } = useTranslation(['services']);

  return (
    <Flex
      direction="column"
      justify="center"
      align="center"
      w="full"
      bg="#F5F5F5"
      py={24}
      gap={8}
    >
      <Heading size="h3" textAlign="center" w="full">
        {t('services:headings.insights')}
      </Heading>
      <Flex
        direction={{ md: 'row', base: 'column' }}
        w="full"
        align="baseline"
        justify="center"
        gap={{ md: '16px', base: '55px' }}
      >
        {INSIGHTS.map((insight) => (
          <ImageTextTile {...insight} key={insight.src} />
        ))}
      </Flex>
    </Flex>
  );
};
