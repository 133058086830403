import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Grid, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';

import { LayoutGrid, LayoutGridElement } from 'components/Layout/Grid';
import { PageHeader } from 'components/PageHeader';
import { useAuthContext } from 'contexts/AuthContext';
import { useGroups } from 'hooks/groups';
import { GroupType } from 'types';

import {
  GroupList,
  GroupSection,
  GroupSearch,
  GroupsSider,
  GroupTabList,
} from '../components';
import { GROUP_TABS } from '../types';
import { getCurrentTab } from '../utils';

export const GroupsPage = () => {
  const { groups } = useGroups();
  const { account } = useAuthContext();
  const { t } = useTranslation(['common', 'groups']);

  const { hash } = useLocation();

  const currentIndex = useMemo(() => {
    const currentTab = getCurrentTab(hash);

    return GROUP_TABS.indexOf(currentTab);
  }, [hash]);

  return (
    <LayoutGrid>
      <LayoutGridElement>
        <PageHeader name={t('common:groups')} />
      </LayoutGridElement>

      <LayoutGridElement colSpan={4}>
        <GroupsSider />
      </LayoutGridElement>

      <LayoutGridElement colSpan={12}>
        <Tabs isFitted index={currentIndex}>
          <Grid templateColumns="repeat(12, 1fr)" gridColumnGap={4}>
            <LayoutGridElement colSpan={8}>
              <GroupTabList />
            </LayoutGridElement>

            <LayoutGridElement colSpan={4}>
              <GroupSearch />
            </LayoutGridElement>

            <LayoutGridElement colSpan={12}>
              <TabPanels>
                <TabPanel px={0} gap={4} display="flex" flexDirection="column">
                  <GroupSection
                    heading={t('groups:group-type', {
                      type: t('common:family'),
                    })}
                    groups={groups?.filter(
                      (group) =>
                        group.familyId === account?.familyId && group.familyOnly
                    )}
                  />
                  <GroupSection
                    heading={t('groups:group-type', {
                      type: t('common:peers'),
                    })}
                    groups={groups?.filter(
                      (group) => group.type === GroupType.Peer
                    )}
                  />
                  <GroupSection
                    heading={t('groups:group-type', {
                      type: t('common:advisors'),
                    })}
                    groups={groups?.filter(
                      (group) => group.type === GroupType.Advisor
                    )}
                  />
                  <GroupSection
                    heading={t('groups:group-type', {
                      type: t('common:initiatives'),
                    })}
                    groups={groups?.filter(
                      (group) => group.type === GroupType.Initiative
                    )}
                  />
                  <GroupSection
                    heading={t('groups:group-type', {
                      type: t('common:events'),
                    })}
                    groups={groups?.filter(
                      (group) => group.type === GroupType.Event
                    )}
                  />
                  <GroupSection
                    heading={t('groups:group-type', {
                      type: t('common:itriom'),
                    })}
                    groups={groups?.filter(
                      (group) => group.type === GroupType.Itriom
                    )}
                  />
                </TabPanel>

                <TabPanel px={0}>
                  <GroupList
                    groups={groups?.filter(
                      (group) =>
                        group.familyId === account?.familyId && group.familyOnly
                    )}
                  />
                </TabPanel>

                <TabPanel px={0}>
                  <GroupList
                    groups={groups?.filter(
                      (group) => group.type === GroupType.Peer
                    )}
                  />
                </TabPanel>

                <TabPanel px={0}>
                  <GroupList
                    groups={groups?.filter(
                      (group) => group.type === GroupType.Advisor
                    )}
                  />
                </TabPanel>

                <TabPanel px={0}>
                  <GroupList
                    groups={groups?.filter(
                      (group) => group.type === GroupType.Initiative
                    )}
                  />
                </TabPanel>

                <TabPanel px={0}>
                  <GroupList
                    groups={groups?.filter(
                      (group) => group.type === GroupType.Event
                    )}
                  />
                </TabPanel>

                <TabPanel px={0}>
                  <GroupList
                    groups={groups?.filter(
                      (group) => group.type === GroupType.Itriom
                    )}
                  />
                </TabPanel>
              </TabPanels>
            </LayoutGridElement>
          </Grid>
        </Tabs>
      </LayoutGridElement>
    </LayoutGrid>
  );
};
