import { Link } from 'react-router-dom';

import { Flex, Grid, GridItem, Heading, Text, Image } from '@chakra-ui/react';

import { ItriomTag } from 'components/ItriomTag';
import { Links } from 'router/links';
import { InitiativeOutput, Insight } from 'types';
import { generateUrlById } from 'utils/links';
import { flattenTags } from 'utils/tags';

interface Props {
  result: InitiativeOutput | Insight;
}

export const SimpleResult = ({ result }: Props) => {
  return (
    <Grid
      templateColumns="1fr 1fr"
      templateRows="repeat(2, auto) 1fr"
      gap={4}
      as={Link}
      to={generateUrlById(
        result.__typename === 'Insight'
          ? Links.insight
          : Links.initiativesSingle,
        result.id
      )}
    >
      <GridItem rowSpan={3}>
        <Image
          src={result.hero.heroImage.signedUrl}
          w="full"
          css={{ aspectRatio: '1/1' }}
          fit="cover"
        />
      </GridItem>
      <GridItem>
        <Heading size="h4">{result.hero.headline}</Heading>
      </GridItem>
      <GridItem as={Flex} gap="2" alignItems="center">
        {flattenTags(result.tags).map((tag) => (
          <ItriomTag key={tag}>{tag}</ItriomTag>
        ))}
      </GridItem>
      <GridItem>
        <Text size="p1" noOfLines={6}>
          {result.hero.summary}
        </Text>
      </GridItem>
    </Grid>
  );
};
