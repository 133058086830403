import { gql, useQuery } from '@apollo/client';

import { Query } from 'types';
import { SdgOutputFragment } from 'types/generated-fragments';

export const sdgConfiguratorAvailableSdgsGql = gql`
  ${SdgOutputFragment}
  query SdgConfiguratorAvailableSdgs {
    sdgConfiguratorAvailableSdgs {
      ...SdgOutputFragment
    }
  }
`;

export const useAvailableSdgs = () => {
  const { data, error, loading } = useQuery<
    Pick<Query, 'sdgConfiguratorAvailableSdgs'>
  >(sdgConfiguratorAvailableSdgsGql);

  return {
    availableSdgs: data?.sdgConfiguratorAvailableSdgs,
    error,
    loading,
  };
};
