import { gql, useMutation } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import {
  ValueConfiguratorInput,
  Mutation,
  MutationAddValueConfiguratorScoresArgs,
} from 'types';

export const addValueConfiguratorScoresGql = gql`
  mutation AddConfiguratorValues($data: ValueConfiguratorInput!) {
    addValueConfiguratorScores(data: $data) {
      organisationalValues {
        importance {
          resilience
        }
      }
    }
  }
`;

export const useAddConfiguratorValues = () => {
  const [addConfiguratorValuesMutation] = useMutation<
    Pick<Mutation, 'addValueConfiguratorScores'>,
    MutationAddValueConfiguratorScoresArgs
  >(addValueConfiguratorScoresGql, { refetchQueries: ['ValueConfigurator'] });

  const addValues = (values: ValueConfiguratorInput) =>
    addConfiguratorValuesMutation({
      variables: { data: values },
    });

  return useWithMutationToast(addValues, {
    successMessage: 'Values added successfully',
    errorMessage: 'Error adding values',
  });
};
