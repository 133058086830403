import { Flex } from '@chakra-ui/react';

import { CONTENT_PADDING_X } from '../constants';
import { BookDemoButton } from './BookDemoButton';
import { MarketplaceHeading } from './MarketplaceHeading';

export const MarketplaceHeroSection = () => (
  <Flex
    bg="ui.white"
    px={CONTENT_PADDING_X}
    py={{ md: '66px', base: '44px' }}
    direction="column"
    gap={{ md: '66px', base: '40px' }}
  >
    <MarketplaceHeading>
      Our marketplace sets out potential investment opportunities that will
      produce targeted and impactful environmental, social and sustainable
      change.
    </MarketplaceHeading>
    <BookDemoButton />
  </Flex>
);
