import { Button, Grid } from '@chakra-ui/react';

import { Avatar } from 'components/Avatar';
import { useSearchContext } from 'contexts/SearchContext';
import { useLibraryContext } from 'features/Library/context';
import { mapLibraryLocationToLibraryType } from 'features/Library/utils/mapLibraryLocationToLibraryType';
import { useShareResourceInLibrary } from 'gql/library/mutations/shareResourceInLibrary';
import { useUnshareResourceInLibrary } from 'gql/library/mutations/unshareResourceInLibrary';
import { useGetResourceDetailsInLibrary } from 'gql/library/query/getResourceDetailsInLibrary';
import { LibraryMediaOutput, LibrarySharedMediaOutput, Profile } from 'types';

interface Props {
  profile: Profile;
  items: (LibraryMediaOutput | LibrarySharedMediaOutput)[];
}

export const MemberRow = ({ profile, items }: Props) => {
  const { shareResourceInLibrary } = useShareResourceInLibrary();
  const { unshareResourceInLibrary } = useUnshareResourceInLibrary();
  const { shareModal } = useLibraryContext();
  const { refetch } = useGetResourceDetailsInLibrary(
    mapLibraryLocationToLibraryType(undefined),
    undefined
  );
  const { searchResults, setFixedSearchResults, fixedSearchResults } =
    useSearchContext();
  const hasAllShared = items.every((item) =>
    item.shares.some(({ id }) => id === profile.id)
  );

  const handleShare = () => {
    items.forEach(async (item) => {
      if (!hasAllShared && !item.shares.some(({ id }) => id === profile.id)) {
        await shareResourceInLibrary({
          consumerId: profile.id,
          libraryType: item.libraryType,
          resourceId: item.id,
        });
      } else if (hasAllShared) {
        await unshareResourceInLibrary({
          consumerId: profile.id,
          libraryType: item.libraryType,
          resourceId: item.id,
        });
      }

      try {
        const newItem = await refetch({
          libraryType: item.libraryType,
          resourceId: item.id,
        });
        let resultsWithoutChangedItem;

        if (fixedSearchResults) {
          resultsWithoutChangedItem = fixedSearchResults.filter(
            (element) => element.object.id !== item.id
          );
        } else {
          resultsWithoutChangedItem = searchResults.library?.nodes.filter(
            (element) => element.object.id !== item.id
          );
        }

        if (resultsWithoutChangedItem) {
          setFixedSearchResults([
            {
              object: newItem.data.getResourceDetailsInLibrary,
              highlights: {},
            },
            ...resultsWithoutChangedItem,
          ]);
        }
      } catch (err) {
        // ignore
      }

      shareModal.onClose();
    });
  };

  return (
    <Grid gridTemplateColumns="1fr auto">
      <Avatar onProfileClick={null} profile={profile} variant="author" />
      <Button
        onClick={handleShare}
        size="small"
        variant={hasAllShared ? 'ghost' : 'outline'}
        {...(hasAllShared ? { px: 0, fontWeight: 700 } : {})}
      >
        {hasAllShared ? 'Remove' : 'Share'}
      </Button>
    </Grid>
  );
};
