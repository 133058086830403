import { SdgConfiguratorOutput, SdgOutput } from 'types';

import { generateSdgOutput } from './sdgs';

type TestSdg = Pick<SdgOutput, 'id' | 'internalName'>;

export const TEST_SDGS: [TestSdg, TestSdg, TestSdg] = [
  {
    id: '62b9a0134b2625e83b055066',
    internalName: 'SDG_1',
  },
  {
    id: '62b9a0134b2625e83b055069',
    internalName: 'SDG_4',
  },
  {
    id: '62b9a0134b2625e83b05506b',
    internalName: 'SDG_6',
  },
];

export const sdgConfiguratorSdgsMock: {
  sdgConfiguratorAvailableSdgs: SdgOutput[];
} = {
  sdgConfiguratorAvailableSdgs: [
    generateSdgOutput({
      id: TEST_SDGS[0].id,
      name: 'name',
      index: 1,
      description: 'No Poverty',
      icon: 'sdg-1-no-poverty',
      internalName: TEST_SDGS[0].internalName,
    }),
    generateSdgOutput({
      id: TEST_SDGS[1].id,
      name: 'name',
      index: 4,
      description: 'Quality Education',
      icon: 'sdg-4-quality-education',
      internalName: TEST_SDGS[1].internalName,
    }),
    generateSdgOutput({
      id: TEST_SDGS[2].id,
      name: 'name',
      index: 6,
      description: 'Clean Water and Sanitation',
      icon: 'sdg-6-water-and-sanitation',
      internalName: TEST_SDGS[2].internalName,
    }),
  ],
};

export const personalSdgConfigurationMock: {
  personalSdgsConfiguration: SdgConfiguratorOutput;
} = {
  personalSdgsConfiguration: {
    sdgIdsWithNumericScores: TEST_SDGS.map((sdg, index) => ({
      sdgId: sdg.id,
      numericScore: index,
      __typename: 'SdgIdWithNumericScoreOutput',
    })),
  },
};
