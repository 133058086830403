import { Navigate } from 'react-router-dom';

import { Flex } from '@chakra-ui/react';

import { DraggableProvider } from 'components/Draggable';
import { LayoutGrid, LayoutGridElement } from 'components/Layout/Grid';
import { useAuthContext } from 'contexts/AuthContext';
import { FeatureFlag, useFeatureFlag } from 'hooks/useFeatureFlag';
import { Links } from 'router/links';

import { ActionBar } from '../components/ActionBar';
import { Banner } from '../components/Banner';
import { DroppableBreadcrumbs } from '../components/DroppableBreadcrumbs';
import { DroppableBreadcrumbsShared } from '../components/DroppableBreadcrumbs/DroppableBreadcrumbsShared';
import { FilesFoldersSection } from '../components/FilesFoldersSection';
import { PreviewModal } from '../components/PreviewModal';
import { RenameModal } from '../components/RenameModal';
import { ShareModal } from '../components/ShareModal';
import { SideNavigation } from '../components/SideNavigation';
import { Upload } from '../components/Upload';
import { UploadingBubble } from '../components/UploadingBubble';
import { LibraryProvider } from '../context';
import { useCurrentLibraryLocation } from '../hooks/useCurrentLibraryLocation';
import { LibraryLocation } from '../types';

export const LibraryPage = () => {
  // TODO: REMOVE FEATURE FLAG WHEN ENTIRE FEATURE IMPLEMENTED
  const isLibraryEnabled = useFeatureFlag(FeatureFlag.LIBRARY);
  const { libraryLocation } = useCurrentLibraryLocation();
  const { account } = useAuthContext();

  if (!isLibraryEnabled) {
    return <Navigate to={Links.home} />;
  }

  if (!account?.familyId && libraryLocation === LibraryLocation.Family) {
    return <Navigate to={Links.libraryMy} />;
  }

  return (
    <LibraryProvider>
      <LayoutGrid rowGap={6}>
        <LayoutGridElement>
          <Banner />
        </LayoutGridElement>
        <LayoutGridElement gridColumn="1 / span 4">
          <Upload />
        </LayoutGridElement>
        <LayoutGridElement gridColumn="5 / span 12">
          <ActionBar />
        </LayoutGridElement>
        <LayoutGridElement gridColumn="1 / span 4">
          <SideNavigation />
        </LayoutGridElement>
        <LayoutGridElement gridColumn="5 / span 12">
          <DraggableProvider>
            <Flex flexDirection="column" gap={3}>
              {libraryLocation === LibraryLocation.SharedWithMe ? (
                <DroppableBreadcrumbsShared />
              ) : (
                <DroppableBreadcrumbs />
              )}
              <Flex flexDirection="column" gap={9}>
                <FilesFoldersSection type="folders" />
                <FilesFoldersSection type="files" />
              </Flex>
            </Flex>
          </DraggableProvider>
        </LayoutGridElement>
        <Flex />
      </LayoutGrid>
      <ShareModal />
      <UploadingBubble />
      <PreviewModal />
      <RenameModal />
    </LibraryProvider>
  );
};
