import { useCallback, useState } from 'react';

import { ApolloError, DocumentNode, useQuery } from '@apollo/client';

import { InputMaybe, PaginationInput } from 'types';

import { useApolloNetworkStateWrapper } from './useApolloNetworkStateWrapper';

type ParamsWithPagination = { pagination?: InputMaybe<PaginationInput> };

type FetchFunction<TQuery, TParams> = (
  params?: Omit<TParams, 'pagination'>
) => Promise<TQuery>;

type PaginationOptions = {
  limit: number;
  offset: number;
};

type UsePaginationResult<TQuery, TParams extends ParamsWithPagination> = {
  initialLoad: FetchFunction<TQuery, TParams>;
  loadMore: FetchFunction<TQuery, TParams>;
  reload: FetchFunction<TQuery, TParams>;
  loading: boolean;
  error: ApolloError | undefined;
};

export const usePaginatedQuery = <TQuery, TParams extends ParamsWithPagination>(
  query: DocumentNode,
  initialPaginationOptions: PaginationOptions
): UsePaginationResult<TQuery, TParams> => {
  const [currentOffset, setCurrentOffset] = useState(
    initialPaginationOptions.offset
  );

  const incrementCurrentOffset = useCallback(
    () => setCurrentOffset((prev) => prev + initialPaginationOptions.limit),
    [initialPaginationOptions.limit]
  );

  const { refetch, fetchMore } = useQuery<TQuery, TParams>(query, {
    skip: true,
    fetchPolicy: 'no-cache',
  });

  const { error, loading, withNetworkState } = useApolloNetworkStateWrapper();

  const initialLoad = useCallback(
    async (params?: Omit<TParams, 'pagination'>) => {
      const data = await withNetworkState(() =>
        refetch({
          pagination: initialPaginationOptions,
          ...params,
        } as Partial<TParams>)
      );

      incrementCurrentOffset();

      return data;
    },
    [
      withNetworkState,
      incrementCurrentOffset,
      refetch,
      initialPaginationOptions,
    ]
  );

  const loadMore = useCallback(
    async (params?: Omit<TParams, 'pagination'>) => {
      const data = await withNetworkState(() =>
        fetchMore({
          variables: {
            ...params,
            pagination: {
              offset: currentOffset,
              limit: initialPaginationOptions.limit,
            },
          },
        })
      );

      incrementCurrentOffset();

      return data;
    },
    [
      currentOffset,
      fetchMore,
      incrementCurrentOffset,
      initialPaginationOptions.limit,
      withNetworkState,
    ]
  );

  const reload = useCallback(
    async (params?: Omit<TParams, 'pagination'>) => {
      const data = await withNetworkState(() =>
        refetch({
          pagination: {
            offset: 0,
            limit: currentOffset + initialPaginationOptions.limit,
          },
          ...params,
        } as Partial<TParams>)
      );

      return data;
    },
    [currentOffset, initialPaginationOptions.limit, refetch, withNetworkState]
  );

  return { initialLoad, loadMore, reload, loading, error };
};
