import { animated, useTransition } from 'react-spring';

import { Close } from '@carbon/icons-react/next';
import { Box, Center, Flex, Heading, Text, Image } from '@chakra-ui/react';

type Props = {
  isActive: boolean;
  toggle: () => void;
  title: string;
  description: string;
  image: string;
};

export const InteractiveCard = ({
  isActive,
  toggle,
  description,
  title,
  image,
}: Props) => {
  const transition = useTransition(isActive, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { tension: 200 },
  });

  return (
    <Flex w="full" direction="column" userSelect="none">
      <Flex
        color="ui.white"
        bgColor="primary.blue.default"
        w="full"
        h={20}
        justify="space-between"
        align="center"
        px={4}
        py={4}
      >
        <Heading size="h3">{title}</Heading>
        <Center
          onClick={toggle}
          w={6}
          h={6}
          border="1px solid white"
          cursor="pointer"
        >
          {isActive ? <Close /> : <Text lineHeight="full">•••</Text>}
        </Center>
      </Flex>
      <Flex
        onClick={toggle}
        w="full"
        minH={240}
        h="full"
        position="relative"
        bgColor="base3"
        align="center"
        justify="center"
        cursor="pointer"
        px={6}
      >
        {transition(({ opacity }, animatedIsActive) => (
          <animated.div style={{ opacity }}>
            {animatedIsActive ? (
              <Text size="p2">{description}</Text>
            ) : (
              <Box
                bgColor="base1"
                w="full"
                h="full"
                position="absolute"
                top={0}
                left={0}
              >
                <Image src={image} w="100%" h="100%" objectFit="cover" />
              </Box>
            )}
          </animated.div>
        ))}
      </Flex>
    </Flex>
  );
};
