import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Flex, Text } from '@chakra-ui/react';

import { MoreOptions } from 'features/Authorization/components/MoreOptions';
import { useRequestSecondFactorForToken } from 'hooks/authorization';
import { useCountdown } from 'hooks/useCountdown';
import {
  SecondFactorVerificationTypeEnum,
  VerificationCodeSendLocation,
} from 'types';

import { ResendCountdown } from './ResendCountdown';

type Props = {
  setOtpCode: (value: string) => void;
  token: string;
  tokenType?: SecondFactorVerificationTypeEnum;
};

const NEXT_OTP_SEND_AWAIT_SECONDS = 60;

export const ResendOtp = ({ setOtpCode, token, tokenType }: Props) => {
  const [isResendMessage, setIsResendMessage] = useState(false);
  const { requestSecondFactorForToken } = useRequestSecondFactorForToken({
    onCompleted: () => {
      setOtpCode('');
      resetCounter();
    },
  });
  const { t } = useTranslation(['common']);
  const { counter, resetCounter } = useCountdown(NEXT_OTP_SEND_AWAIT_SECONDS);

  const handleClick = (sendLocation?: VerificationCodeSendLocation) =>
    requestSecondFactorForToken({
      token,
      tokenType: tokenType || SecondFactorVerificationTypeEnum.ResetPassword,
      sendLocation,
    });

  if (isResendMessage) {
    return <ResendCountdown setIsResendMessage={setIsResendMessage} />;
  }

  return (
    <Flex flexDirection="column" pb={8}>
      <Flex gap={1} alignItems="baseline" flexDirection="row">
        <Text size="p3">
          {t('common:otp.not-received')}
          <Button
            variant="link"
            color="primary.blue.default"
            ml={1}
            fontSize="xs"
            onClick={() => handleClick()}
          >
            {counter
              ? t('common:otp.wait-before-resend', { number: counter })
              : t('common:otp.click-to-resend')}
          </Button>
        </Text>
      </Flex>
      <MoreOptions
        onConfirm={() => handleClick(VerificationCodeSendLocation.Email)}
        waitCounter={counter}
      />
    </Flex>
  );
};
