export enum SdgInternalName {
  Sdg1 = 'SDG_1',
  Sdg2 = 'SDG_2',
  Sdg3 = 'SDG_3',
  Sdg4 = 'SDG_4',
  Sdg5 = 'SDG_5',
  Sdg6 = 'SDG_6',
  Sdg7 = 'SDG_7',
  Sdg8 = 'SDG_8',
  Sdg9 = 'SDG_9',
  Sdg10 = 'SDG_10',
  Sdg11 = 'SDG_11',
  Sdg12 = 'SDG_12',
  Sdg13 = 'SDG_13',
  Sdg14 = 'SDG_14',
  Sdg15 = 'SDG_15',
  Sdg16 = 'SDG_16',
  Sdg17 = 'SDG_17',
}
