import { Flex } from '@chakra-ui/react';

import { TabPane } from 'components/TabPane';

import { eventTabsConfig } from './eventTabConfig';

export const EventListing = () => {
  return (
    <Flex direction="column" mx="auto" maxW={960} w="full">
      <TabPane tabsConfig={eventTabsConfig} />
    </Flex>
  );
};
