import { DocumentNode } from '@apollo/client';

import { Panel } from 'components/Layout/Panel';
import { useCreatePost } from 'hooks/posts';
import { PostSource } from 'types';
import { getUpdateFileValue } from 'utils/file';

import { PostFormBase } from './PostFormBase';
import { PostFormConfigProps, SubmitFunction } from './types';

type Props = PostFormConfigProps & {
  referenceId?: string;
  source: PostSource;
  onAdd?: () => void;
  refetchQueries?: DocumentNode[];
};

export const NewPostForm = ({
  source,
  referenceId,
  refetchQueries,
  onAdd,
  ...baseProps
}: Props) => {
  const { createPost } = useCreatePost({ refetchQueries });

  const handleSubmit: SubmitFunction = async (values) => {
    await createPost({
      images: getUpdateFileValue(values.upload),
      post: {
        title: values.title,
        text: values.text,
        referenceId,
        source,
      },
    });

    if (onAdd) {
      onAdd();
    }
  };

  const initialValues = {
    title: '',
    text: '',
    upload: [],
  };

  return (
    <Panel>
      <PostFormBase
        {...baseProps}
        initialValues={initialValues}
        handleSubmit={handleSubmit}
      />
    </Panel>
  );
};
