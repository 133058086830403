import { ReactNode } from 'react';

import { Box, Button, ButtonGroup, Text } from '@chakra-ui/react';

import { useRequestSecondFactorForToken } from 'hooks/authorization';
import { SecondFactorVerificationTypeEnum } from 'types';

type Props = {
  email: string;
  onSuccess: () => void;
  token: string;
  buttonAfterSubmit?: ReactNode;
  tokenType: SecondFactorVerificationTypeEnum;
};

export const SendOtp = ({
  email,
  onSuccess,
  token,
  tokenType,
  buttonAfterSubmit,
}: Props) => {
  const { requestSecondFactorForToken } = useRequestSecondFactorForToken({
    variables: { tokenType, token },
    onCompleted: () => {
      onSuccess();
    },
  });

  const handleClick = () => requestSecondFactorForToken({ token, tokenType });

  return (
    <Box>
      <Text size="p3" mb={3}>
        Email address
      </Text>
      <Box
        p={3}
        bg="ui.grey4"
        borderColor="primary.blue.default"
        borderBottomWidth={2}
        mb={3}
      >
        <Text size="p1" noOfLines={1} title={email} cursor="default">
          {email}
        </Text>
      </Box>

      <Text size="p3" mb={16}>
        We will send a text to the mobile associated with this email
      </Text>
      <ButtonGroup w="full" justifyContent="space-between">
        <Button
          bgColor="primary.lime.default"
          color="primary.blue.default"
          variant="solid"
          borderRadius={2}
          h={12}
          w={123}
          mb={4}
          onClick={handleClick}
        >
          <Text size="p1">Send code</Text>
        </Button>
        {buttonAfterSubmit}
      </ButtonGroup>

      <Text size="p3" mb={16}>
        Click to receive a one time verification code.
      </Text>
    </Box>
  );
};
