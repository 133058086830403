import { Divider, Grid, VStack } from '@chakra-ui/react';

import { Panel } from 'components/Layout/Panel';
import { SingleGroupCard } from 'components/SingleGroupCard';

import { GroupCard } from './GroupCard';
import { GroupSectionTemplateProps } from './types';

const MAX_ELEMENTS_H = 4;
const MAX_ELEMENTS_V = 3;

export const GroupSectionTemplate = ({
  groups,
  heading,
  CardComponent = SingleGroupCard,
}: GroupSectionTemplateProps) => (
  <Panel title={heading} contentWrapperStyling={{ direction: 'column' }}>
    <Grid
      gridGap={4}
      gridTemplateRows={{
        sm: 'repeat(2, 1fr)',
        lg: '1fr',
      }}
      gridTemplateColumns={{
        sm: 'repeat(1, 1fr)',
        md: 'repeat(2, 1fr)',
        lg: 'repeat(4, 1fr)',
      }}
      w="full"
    >
      {groups.slice(0, MAX_ELEMENTS_H).map((group) => (
        <GroupCard key={group.id} group={group} />
      ))}
    </Grid>

    <VStack
      mt={6}
      w="full"
      align="flex-start"
      divider={<Divider />}
      backgroundColor="ui.white"
    >
      {groups
        .slice(MAX_ELEMENTS_H, MAX_ELEMENTS_V + MAX_ELEMENTS_H)
        .map((group) => (
          <CardComponent
            group={group}
            key={group.id}
            withEsgCategory
            withBorder={false}
          />
        ))}
    </VStack>
  </Panel>
);
