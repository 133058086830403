import { Flex } from '@chakra-ui/react';

import { SdgCard } from 'components/SdgCard';

import { SectionHeading } from '../../SectionHeader';
import { usePersonalMappedSdgs } from './usePersonalMappedSdgs';

export const OrderedSdgs = () => {
  const personalMappedSdgs = usePersonalMappedSdgs();

  return (
    <Flex px={24} mt={16} direction="column" width="100%" py={5}>
      <SectionHeading
        size="h3"
        color="primary.blue.default"
        heading="Your Individual Prioritisation of your family’s linked Sustainable Development Goals"
        subheading="Your Individual Prioritisation of your family’s linked Sustainable Development Goals"
      />
      <Flex
        direction="column"
        mx="auto"
        padding={4}
        borderRadius={8}
        alignItems="center"
        border="1px solid"
        borderColor="darkBlue5"
      >
        {personalMappedSdgs.map((sdg) => (
          <SdgCard key={sdg.id} sdg={sdg} isHorizontal />
        ))}
      </Flex>
    </Flex>
  );
};
