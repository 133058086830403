import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { ArrowLeft } from '@carbon/icons-react/next';
import { GridItem, Heading as ChakraHeading } from '@chakra-ui/react';

import { ViewButton } from 'components/Button';
import { useSearchContext } from 'contexts/SearchContext';
import { Links } from 'router/links';
import { SearchResult } from 'types';

import { isSearchResultType } from '../guards';

export const Heading = () => {
  const { t } = useTranslation(['common']);
  const { searchResults, fixedLibraryItemsCount } = useSearchContext();
  const { type } = useParams();
  const navigate = useNavigate();

  const searchType =
    type && isSearchResultType(type.toLowerCase(), searchResults)
      ? (type?.toLowerCase() as keyof SearchResult)
      : undefined;

  return (
    <GridItem px="6">
      <ChakraHeading css={{ '&:first-letter': { textTransform: 'uppercase' } }}>
        {t('common:search.results', {
          type,
          foundItems:
            searchType && type !== 'library'
              ? searchResults[searchType]?.totalCount
              : fixedLibraryItemsCount ?? searchResults.library?.totalCount,
        }).trim()}
      </ChakraHeading>
      {searchType && (
        <ViewButton
          leftIcon={<ArrowLeft />}
          rightIcon={null}
          variant="blue"
          onClick={() => navigate(Links.search)}
        >
          {t('common:search.go-back')}
        </ViewButton>
      )}
    </GridItem>
  );
};
