export enum Breakpoints {
  Mobile = '382px',
  Tablet = '736px',
  SmallDesktop = '1024px',
  Desktop = '1280px',
  LargeDesktop = '1536px',
}

export const breakpoints = {
  sm: Breakpoints.Mobile,
  md: Breakpoints.Tablet,
  lg: Breakpoints.SmallDesktop,
  xl: Breakpoints.Desktop,
  '2xl': Breakpoints.LargeDesktop,
};
