import { Flex, Text } from '@chakra-ui/react';

export const WelcomeToSdgs = () => {
  return (
    <Flex direction="column" gap={4}>
      <Text size="p1">
        It uses the values your family have selected to identify the SDGs most
        relevant to you. By identifying the three SDGs that your family want to
        focus on, enables us to:
      </Text>
      <Text size="p1">
        Recommend potential investments and relevant philanthropic opportunities
      </Text>
      <Text size="p1">Support you in creating high-impact initiatives</Text>

      <Text size="p1">Facilitate collaboration with like-minded partners</Text>
    </Flex>
  );
};
