import { Box, VStack } from '@chakra-ui/layout';
import { Input, Checkbox } from '@chakra-ui/react';

export const SecuritySettings = () => {
  return (
    <Box w="600px">
      <VStack align="flex-start">
        <Checkbox defaultChecked>Enable 2 Factor Authentication</Checkbox>{' '}
        <Input placeholder="Phone Number" />
      </VStack>
    </Box>
  );
};
