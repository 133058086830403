import { useQuery } from '@apollo/client';

import { Query, QueryGetSharedResourceFromLibraryArgs } from 'types';

import { getSharedResourceFromLibrary } from '../documents';

export const useGetSharedResourceFromLibrary = ({
  skip,
  resourceId,
}: QueryGetSharedResourceFromLibraryArgs & {
  skip: boolean;
}) => {
  const { data, error, loading, refetch } = useQuery<
    Pick<Query, 'getSharedResourceFromLibrary'>,
    QueryGetSharedResourceFromLibraryArgs
  >(getSharedResourceFromLibrary, { skip, variables: { resourceId } });

  return {
    details: data?.getSharedResourceFromLibrary,
    error,
    loading,
    refetch,
  };
};
