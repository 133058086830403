import {
  AboutPage,
  OrganisationalValues,
  ContributionValues,
  AdvancementValues,
  SocietalValues,
  SdgPage,
  ValuesSummaryPage,
  WelcomePage,
  ValuesEssentialPage,
  ValuesCategorisationPage,
  ValuesImpactPage,
  ValuesCtaPage,
  HowToUsePage,
  ImportanceToRelevancePage,
  ThankYouPage,
  WhatAreSdgsPage,
  WhyAlignToSdgsPage,
  WelcomeSdgPage,
  OtpSetPasswordPage,
  OtpSetPasswordSuccessPage,
  OtpWelcomePage,
  OnboardingLogoutPage,
} from 'features/Onboarding';
import { Links } from 'router/links';
import { createPage } from 'router/utils/createPage';
import { LayoutType, ValueConfiguratorCategoryEnum } from 'types';
import { ValueCategoryName } from 'types/ValuesConfigurator';

export const getOnboardingRoutes = () => {
  return (
    <>
      {createPage(Links.onboardingLogout, <OnboardingLogoutPage />, {
        mustBeLogin: false,
        layout: LayoutType.None,
      })}
      {createPage(Links.onboardingOtp, <OtpWelcomePage />, {
        mustBeLogin: false,
        layout: LayoutType.None,
      })}
      {createPage(Links.onboardingOtpSetPassword, <OtpSetPasswordPage />, {
        layout: LayoutType.None,
      })}
      {createPage(
        Links.onboardingOtpSetPasswordSuccess,
        <OtpSetPasswordSuccessPage />,
        {
          layout: LayoutType.None,
        }
      )}
      {createPage(Links.valuesConfiguratorHowToUse, <HowToUsePage />, {
        layout: LayoutType.None,
      })}
      {createPage(
        Links.valuesConfiguratorImportanceToRelevance,
        <ImportanceToRelevancePage />,
        {
          layout: LayoutType.None,
        }
      )}
      {createPage(Links.valuesConfiguratorThankYou, <ThankYouPage />, {
        layout: LayoutType.None,
      })}
      {createPage(Links.sdgConfiguratorWelcome, <WelcomeSdgPage />, {
        layout: LayoutType.None,
      })}
      {createPage(Links.sdgConfiguratorWhatAre, <WhatAreSdgsPage />, {
        layout: LayoutType.None,
      })}
      {createPage(Links.sdgConfiguratorWhyAlign, <WhyAlignToSdgsPage />, {
        layout: LayoutType.None,
      })}
      {createPage(Links.valuesConfiguratorScores, <ValuesSummaryPage />, {
        layout: LayoutType.ValuesIntro,
      })}
      {createPage(Links.sdgConfigurator, <SdgPage />, {
        layout: LayoutType.ValuesIntro,
      })}
      {createPage(
        [Links.valuesConfiguratorRelevance, ValueCategoryName.Organisational],
        <OrganisationalValues
          valuesCategory={ValueConfiguratorCategoryEnum.Relevance}
        />,
        { layout: LayoutType.ValuesIntro }
      )}
      {createPage(
        [Links.valuesConfiguratorRelevance, ValueCategoryName.Contribution],
        <ContributionValues
          valuesCategory={ValueConfiguratorCategoryEnum.Relevance}
        />,
        { layout: LayoutType.ValuesIntro }
      )}
      {createPage(
        [Links.valuesConfiguratorRelevance, ValueCategoryName.Societal],
        <SocietalValues
          valuesCategory={ValueConfiguratorCategoryEnum.Relevance}
        />,
        { layout: LayoutType.ValuesIntro }
      )}
      {createPage(
        [Links.valuesConfiguratorRelevance, ValueCategoryName.Advancement],
        <AdvancementValues
          valuesCategory={ValueConfiguratorCategoryEnum.Relevance}
        />,
        { layout: LayoutType.ValuesIntro }
      )}
      {createPage(
        [Links.valuesConfiguratorImportance, ValueCategoryName.Organisational],
        <OrganisationalValues />,
        { layout: LayoutType.ValuesIntro }
      )}
      {createPage(
        [Links.valuesConfiguratorImportance, ValueCategoryName.Contribution],
        <ContributionValues />,
        { layout: LayoutType.ValuesIntro }
      )}
      {createPage(
        [Links.valuesConfiguratorImportance, ValueCategoryName.Societal],
        <SocietalValues />,
        { layout: LayoutType.ValuesIntro }
      )}
      {createPage(
        [Links.valuesConfiguratorImportance, ValueCategoryName.Advancement],
        <AdvancementValues />,
        { layout: LayoutType.ValuesIntro }
      )}
      {createPage(Links.valuesConfiguratorScores, <ValuesSummaryPage />, {
        layout: LayoutType.ValuesIntro,
      })}
      {createPage(Links.valuesConfigurator, <WelcomePage />, {
        layout: LayoutType.None,
      })}
      {createPage(Links.valuesConfiguratorAbout, <AboutPage />, {
        layout: LayoutType.None,
      })}
      {createPage(Links.valuesConfiguratorEssential, <ValuesEssentialPage />, {
        layout: LayoutType.None,
      })}
      {createPage(
        Links.valuesConfiguratorCategorisation,
        <ValuesCategorisationPage />,
        {
          layout: LayoutType.None,
        }
      )}
      {createPage(Links.valuesConfiguratorImpact, <ValuesImpactPage />, {
        layout: LayoutType.None,
      })}
      {createPage(Links.valuesConfiguratorCta, <ValuesCtaPage />, {
        layout: LayoutType.None,
      })}
    </>
  );
};
