import { Link } from 'react-router-dom';

import { Heading, Text, Flex, Image } from '@chakra-ui/react';

import { ItriomTag } from 'components/ItriomTag';
import { Links } from 'router/links';
import { Event } from 'types';
import { generateUrlById } from 'utils/links';
import { flattenTags } from 'utils/tags';

export const SingleEventCard = ({ event }: { event: Event }) => {
  const { id, hero, tags } = event;
  const { heroImage, name, summary } = hero;

  return (
    <Flex
      w={300}
      minW={300}
      key={id}
      bgColor="ui.white"
      direction="column"
      as={Link}
      to={generateUrlById(Links.event, id)}
    >
      <Image
        src={heroImage?.signedUrl}
        w="full"
        h={230}
        objectFit="cover"
        objectPosition="center"
      />
      <Flex
        direction="column"
        p={3}
        gap={2}
        h={200}
        textAlign="left"
        overflow="hidden"
      >
        <Heading size="h5">{name}</Heading>
        <Flex gap={1} wrap="wrap">
          {flattenTags(tags).map((tag) => (
            <ItriomTag key={tag}>{tag}</ItriomTag>
          ))}
        </Flex>
        <Text size="p2" noOfLines={3}>
          {summary}
        </Text>
      </Flex>
    </Flex>
  );
};
