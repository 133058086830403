import { Flex, Image, Heading } from '@chakra-ui/react';

import { useEsgCategories } from 'hooks/useEsgCategories';
import { underlay } from 'theme/mixins/underlay';
import { Group } from 'types';

type Props = {
  group: Group;
};

export const GroupBanner = ({ group }: Props) => {
  const { category, heroImage } = group;
  const { getEsgCategory } = useEsgCategories();

  return (
    <Flex
      direction="column"
      width="full"
      {...underlay({ direction: 'column', size: '50%' })}
      position="relative"
    >
      <Image
        src={heroImage?.signedUrl}
        alt="Group banner"
        h={140}
        objectFit="cover"
        objectPosition="center"
      />
      <Heading
        bottom={0}
        pb={3}
        left={0}
        right={0}
        textAlign="center"
        position="absolute"
        color="ui.white"
        fontSize={20}
        lineHeight={1.2}
      >
        {getEsgCategory(category)?.name}
      </Heading>
    </Flex>
  );
};
