import { useNavigate } from 'react-router-dom';

import { gql, MutationHookOptions, useMutation } from '@apollo/client';

import { useAuthContext } from 'contexts/AuthContext';
import { Links } from 'router/links';
import { Mutation, MutationVerifyForgotPasswordSecondFactorArgs } from 'types';

const verifyForgotPasswordSecondFactorGql = gql`
  mutation VerifyForgotPasswordSecondFactor(
    $token: String!
    $secondFactor: Float!
  ) {
    verifyForgotPasswordSecondFactor(token: $token, secondFactor: $secondFactor)
  }
`;

export const useVerifyForgotPasswordSecondFactor = <
  TData extends Pick<Mutation, 'verifyForgotPasswordSecondFactor'>,
  TVariables extends MutationVerifyForgotPasswordSecondFactorArgs
>(
  options?: MutationHookOptions<TData, TVariables>
) => {
  const navigate = useNavigate();
  const { setSecondFactorJWT } = useAuthContext();

  const [mutation, { loading, error }] = useMutation<TData, TVariables>(
    verifyForgotPasswordSecondFactorGql,

    {
      onCompleted: (data) => {
        setSecondFactorJWT(data.verifyForgotPasswordSecondFactor);
        navigate(Links.newPassword);
      },
      ...options,
    }
  );

  const verifyForgotPasswordSecondFactor = (variables: TVariables) =>
    mutation({ variables });

  return {
    verifyForgotPasswordSecondFactor,
    loading,
    error,
  };
};
