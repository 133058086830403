import { useTranslation } from 'react-i18next';

import { HeroBody } from 'components/HeroBody';
import { HeroMedia } from 'components/HeroMedia';
import { useCurrentLibraryLocation } from 'features/Library/hooks/useCurrentLibraryLocation';
import { LibraryLocation } from 'features/Library/types';

export const Banner = () => {
  const { t } = useTranslation(['library']);

  const { libraryLocation } = useCurrentLibraryLocation();

  return (
    <HeroMedia
      imageUrl="/assets/library/library-banner.jpg"
      containerStyles={{ h: 320 }}
    >
      <HeroBody
        title={t(
          `library:location.${
            libraryLocation === LibraryLocation.SearchLibraryContext
              ? 'MY_LIBRARY'
              : libraryLocation || 'MY_LIBRARY'
          }`
        )}
      />
    </HeroMedia>
  );
};
