import { FormikErrors } from 'formik';

import { FormValues, NAME_CONFIRMATION_FIELD, Props } from './types';

interface ValidateProps extends Pick<Props, 'name'> {
  formValues: FormValues;
  errorMessage: string;
}

export const handleValidate = ({
  formValues: { deletionConfirmationField },
  name,
  errorMessage,
}: ValidateProps) => {
  const errors: FormikErrors<FormValues> = {};

  if (deletionConfirmationField !== name) {
    errors[NAME_CONFIRMATION_FIELD] = errorMessage;
  }

  return errors;
};
