import { gql, useMutation } from '@apollo/client';

import { notificationsGql } from 'features/Notifications/hooks/useLoadNotifications';
import { Mutation, MutationAcceptGroupInvitationArgs } from 'types';

import { useWithMutationToast } from '../useWithMutationToast';
import { groupGql } from './useGroup';

export const acceptGroupInvitationGql = gql`
  mutation AcceptGroupInvitation($groupId: String!) {
    acceptGroupInvitation(groupId: $groupId)
  }
`;

export const useAcceptGroupInvitation = () => {
  const [acceptGroupInvitationMutation] = useMutation<
    Pick<Mutation, 'acceptGroupInvitation'>,
    Partial<MutationAcceptGroupInvitationArgs>
  >(acceptGroupInvitationGql, { refetchQueries: [groupGql, notificationsGql] });

  const handleAcceptGroupInvitation = (groupId: string) =>
    acceptGroupInvitationMutation({
      variables: {
        groupId,
      },
    });

  const acceptGroupInvitation = useWithMutationToast(
    handleAcceptGroupInvitation,
    {
      successMessage: 'You accepted invitation',
      errorMessage: 'Failure',
    }
  );

  return { acceptGroupInvitation };
};
