import { SdgInternalName } from 'types/Sdg';

export type SdgOverview = {
  description: string;
  keyTargets: string[];
  color: string;
  internalName: SdgInternalName;
  index: number;
};

export const SGDS_OVERVIEW: Record<SdgInternalName, SdgOverview> = {
  [SdgInternalName.Sdg1]: {
    description: 'End poverty in all its forms everywhere.',
    keyTargets: [
      'Eradicate poverty',
      ' Ensure equal access to basic services, technology and resources',
      'Implement policies and frameworks to build resilience to shocks and disasters',
    ],
    color: '#E5243B',
    internalName: SdgInternalName.Sdg1,
    index: 1,
  },
  [SdgInternalName.Sdg2]: {
    description:
      'End hunger, achieve food security and improved nutrition and promote sustainable agriculture.',
    keyTargets: [
      'Ensure equal access nutritious food in order to end malnutrition',
      'Increase the sustainability and resilience of agricultural systems involving small-scale food producers',
      'Invest in infrastructure, technology and research, adopt measures to stabilise markets and ensure access to market information',
    ],
    color: '#DDA83A',
    internalName: SdgInternalName.Sdg2,
    index: 2,
  },
  [SdgInternalName.Sdg3]: {
    description:
      'Ensure healthy lives and promote well-being for all at all ages.',
    keyTargets: [
      'Reduce mortality rates, injuries, illness and spread of disease while promoting mental well-being',
      'Increase access to health care, medicines, vaccines, health financing and education',
      'Support research and development of medicines and vaccines, recruitment of health workforce, and improvement of early warning systems for health risks',
    ],
    color: '#4C9F38',
    internalName: SdgInternalName.Sdg3,
    index: 3,
  },
  [SdgInternalName.Sdg4]: {
    description:
      'Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all.',
    keyTargets: [
      'Achieve universal literacy and numeracy ensuring equal access to education',
      'Remove any disparities (gender, disabilities and other vulnerabilities) from the education system',
      'Build and upgrade educational infrastructure, increase supply of teachers and availability of scholarships',
    ],
    color: '#C5192D',
    internalName: SdgInternalName.Sdg4,
    index: 4,
  },
  [SdgInternalName.Sdg5]: {
    description: 'Achieve gender equality and empower all women and girls.',
    keyTargets: [
      'End discrimination and violence against women and girls',
      'Ensure equal access to healthcare, economic resources, property and technology',
      'Strengthen policies and legislation for gender equality and ensure women’s participation in leadership and decision-making',
    ],
    color: '#FF3A21',
    internalName: SdgInternalName.Sdg5,
    index: 5,
  },
  [SdgInternalName.Sdg6]: {
    description:
      'Ensure availability and sustainable management of water and sanitation for all.',
    keyTargets: [
      'Provide access to sanitation and hygiene facilities and safe drinking water',
      'Improve water quality, increase water-use efficiency, and protect water-related ecosystems through proper water resource management',
      'Expand water and sanitation support in developing countries and engage community in water and sanitation management',
    ],
    color: '#26BDE2',
    internalName: SdgInternalName.Sdg6,
    index: 6,
  },
  [SdgInternalName.Sdg7]: {
    description:
      'Ensure access to affordable, reliable, sustainable and modern energy for all.',
    keyTargets: [
      'Ensure universal access to energy',
      'Increase global percentage of renewable energy and improve energy efficiency through the promotion research, technology and investment in clean energy',
      'Expand and upgrade energy services in developing countries',
    ],
    color: '#FCC30B',
    internalName: SdgInternalName.Sdg7,
    index: 7,
  },
  [SdgInternalName.Sdg8]: {
    description:
      'Promote sustained, inclusive and sustainable economic growth, full and productive employment and decent work for all.',
    keyTargets: [
      'Sustain economic growth through ending discriminations in the economy, promoting policies that promote job creation, and promoting youth education, employment and training, and technological upgrading and innovation',
      'Improve resource efficiency in consumption and production patterns to decouple economic growth from environmental degradation',
      'Protect and promote labour rights',
    ],
    color: '#A21942',
    internalName: SdgInternalName.Sdg8,
    index: 8,
  },
  [SdgInternalName.Sdg9]: {
    description:
      'Build resilient infrastructure, promote inclusive and sustainable industrialisation and foster innovation.',
    keyTargets: [
      'Develop sustainable, resilient and inclusive infrastructures while retrofitting existing infrastructure to make them sustainable paying attention to the needs of  developing nations',
      'Promote sustainable industrialisation',
      'Universal access to information and communications technology',
    ],
    color: '#FD6925',
    internalName: SdgInternalName.Sdg9,
    index: 9,
  },
  [SdgInternalName.Sdg10]: {
    description: 'Reduce inequality within and among countries.',
    keyTargets: [
      'Reduce income inequalities promoting universal social, economic and political inclusion',
      'Adopt fiscal and social policies that promote equality',
      'Encourage assistance and investment for developing countries',
    ],
    color: '#DD1367',
    internalName: SdgInternalName.Sdg10,
    index: 10,
  },
  [SdgInternalName.Sdg11]: {
    description:
      'Make cities and human settlements inclusive, safe, resilient and sustainable.',
    keyTargets: [
      'Ensure access to safe, affordable, sustainable and inclusive housing, transport systems, and green and public spaces',
      'Enhance inclusive and sustainable urbanisation reducing adverse effects of natural disasters and environmental impact of cities',
      'Implement policies for inclusion, resource efficiency and disaster risk reduction, strong national and regional development planning and support developed countries in sustainable building',
    ],
    color: '#FD9D24',
    internalName: SdgInternalName.Sdg11,
    index: 11,
  },
  [SdgInternalName.Sdg12]: {
    description: 'Ensure sustainable consumption and production patterns.',
    keyTargets: [
      'Implement sustainable consumption and production frameworks which manage the use of natural resources while reducing waste',
      'Implement sustainable procurement politics and reporting into reporting cycle, developing and implementing tools for monitoring',
      'Improve scientific and technological capacity for sustainable consumption and production',
    ],
    color: '#BF8B2E',
    internalName: SdgInternalName.Sdg12,
    index: 12,
  },
  [SdgInternalName.Sdg13]: {
    description: 'Take urgent action to combat climate change and its impacts.',
    keyTargets: [
      'Improve resilience and adaptative capacity to climate related disasters',
      'Integrate climate change measures into strategy, planning and policies',
      'Improve education on climate change mitigation, adaptation and impact reduction while implementing UN Framework on Climate Change',
    ],
    color: '#3F7E44',
    internalName: SdgInternalName.Sdg13,
    index: 13,
  },
  [SdgInternalName.Sdg14]: {
    description:
      'Conserve and sustainable use the oceans, seas and marine resources for sustainable development.',
    keyTargets: [
      'Reduce adverse effects on marine ecosystems including marine pollution, ocean acidification, and overfishing to conserve, protect and restore coastal and marine ecosystems',
      'Increase scientific research and technology and implement policy to protect ocean health',
      'Increase sharing of economic benefits from use of marine resources particularly supporting small scale fishers',
    ],
    color: '#0A97D9',
    internalName: SdgInternalName.Sdg14,
    index: 14,
  },
  [SdgInternalName.Sdg15]: {
    description:
      'Protect, restore and promote sustainable use of terrestrial ecosystems, sustainably manage forests, combat desertification, and halt and reserve land degradation and halt biodiversity loss.',
    keyTargets: [
      'End desertification and deforestation conserving and restoring terrestrial ecosystems',
      'Integrate ecosystem management and biodiversity into governmental and financial planning',
      'Combat poaching and trafficking of species and promote sustainable management of natural resources',
    ],
    color: '#56C02B',
    internalName: SdgInternalName.Sdg15,
    index: 15,
  },
  [SdgInternalName.Sdg16]: {
    description:
      'Promote peaceful and inclusive societies for sustainable development, provide access to justice for all and build effective, accountable and inclusive institutions at all levels.',
    keyTargets: [
      'Ensure equal access to justice',
      'Develop effective, accountable and transparent institutions',
      'Ensure inclusive and representative decision-making',
    ],
    color: '#00689D',
    internalName: SdgInternalName.Sdg16,
    index: 16,
  },
  [SdgInternalName.Sdg17]: {
    description:
      'Strengthen the means of implementation and revitalize the global partnership for sustainable development.',
    keyTargets: [
      'Increase knowledge sharing and access to science, technology and innovation capacity particularly for developing nations with a focus on promoting sustainable technologies',
      'Enhance global partnership for sustainable development',
      'Increase investing in least developed countries',
    ],
    color: '#19486A',
    internalName: SdgInternalName.Sdg17,
    index: 17,
  },
};
