import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Heading, Grid, Text } from '@chakra-ui/react';

import { Agenda } from 'types';

import { ContentWithHeading } from '../../ContentWithHeading';
import { ElementWithBackgroundImage } from '../../ElementWithBackgroundImage';
import { EventBodyElement } from '../../EventBodyElement';

interface Props {
  agenda?: Agenda;
}

export const SpeakersSection = ({ agenda }: Props) => {
  const { t } = useTranslation(['events']);
  const speakers = useMemo(
    () => [
      ...new Set(
        agenda?.agendaItems
          .flatMap((item) => item.speakers)
          .map((speaker) => speaker.profile)
      ),
    ],
    [agenda]
  );

  if (!speakers.length) return null;

  return (
    <EventBodyElement>
      <ContentWithHeading heading={t('events:speakers')}>
        <Grid gridTemplateColumns="repeat(4, 1fr)" gap={4}>
          {speakers.map((speaker) => (
            <ElementWithBackgroundImage
              key={speaker.id}
              image={speaker.avatarImage}
              aspectRatio="2 / 3"
              underlayDirection="column"
              childrenGap={0}
            >
              <Heading size="h4" color="ui.white">
                {speaker.firstName}
              </Heading>
              <Heading size="h4" color="ui.white">
                {speaker.lastName}
              </Heading>
              {speaker.title && <Text color="ui.white">{speaker.title}</Text>}
            </ElementWithBackgroundImage>
          ))}
        </Grid>
      </ContentWithHeading>
    </EventBodyElement>
  );
};
