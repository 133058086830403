import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  Text,
  Avatar as ChakraAvatar,
  Grid,
  GridItem,
  GridProps,
} from '@chakra-ui/react';

import { Links } from 'router/links';
import { AccountStatus, Profile } from 'types';
import { getAvatarSrc } from 'utils/file';
import { generateUrlById } from 'utils/links';
import { isItriomStaff } from 'utils/roles';
import { getFullName } from 'utils/string';

import { AVATAR_IMAGE_STYLING_PROPS, gridStyles, textAlign } from './constants';
import { AvatarVariant } from './types';

const CURSOR_STYLE: Record<'pointer' | 'default', Partial<GridProps>> = {
  pointer: {
    cursor: 'pointer',
    _hover: { textDecoration: 'underline' },
  },
  default: {
    cursor: 'default',
    pointerEvents: 'none',
  },
};

const FONT_COLOR: Record<AccountStatus, GridProps['color']> = {
  [AccountStatus.Active]: 'primary.blue.default',
  [AccountStatus.Inactive]: 'ui.grey2',
};

type Props = {
  variant?: AvatarVariant;
  profile: Pick<
    Profile,
    | 'lastName'
    | 'firstName'
    | 'status'
    | 'role'
    | 'id'
    | 'avatarImage'
    | 'title'
  >;
  subheadingInfo?: string;
  withRedirectToProfile?: boolean;
  onProfileClick?: VoidFunction | null; // null means no action
};

export const Avatar = ({
  profile,
  variant = 'verticalCompact',
  subheadingInfo,
  onProfileClick,
}: Props) => {
  const navigate = useNavigate();

  const fullName = getFullName(profile.firstName, profile.lastName);
  const { t } = useTranslation(['common']);

  const avatarImageProps = AVATAR_IMAGE_STYLING_PROPS[variant];
  const isActive = profile.status === AccountStatus.Active;

  const linkToProfile = useMemo(() => {
    if (!isActive) return null;

    if (isItriomStaff(profile.role)) return null;

    return generateUrlById(Links.profile, profile.id);
  }, [isActive, profile.id, profile.role]);

  const hasRedirect = onProfileClick !== null && linkToProfile;

  const handleClick = () => {
    if (onProfileClick) {
      onProfileClick();
    } else if (hasRedirect) {
      navigate(linkToProfile);
    }
  };

  return (
    <Grid
      gap={2}
      justifyContent="center"
      w="max-content"
      color={FONT_COLOR[profile.status]}
      {...gridStyles[variant]}
      {...CURSOR_STYLE[onProfileClick || hasRedirect ? 'pointer' : 'default']}
      onClick={handleClick}
      userSelect="none"
      aria-label="avatar"
    >
      <ChakraAvatar
        justifySelf="center"
        justifyContent="center"
        size={variant === 'author' ? 'sm' : undefined}
        {...avatarImageProps}
        name={fullName}
        src={getAvatarSrc(profile.avatarImage)}
      />
      <GridItem textAlign={textAlign[variant]}>
        <Text size="p2b">
          {fullName} {!isActive && t('common:inactive-user')}
        </Text>
        <Text size="p3" maxWidth="150px">
          {profile.title}
          {profile.title && subheadingInfo && ` | `}
          {subheadingInfo && subheadingInfo}
        </Text>
      </GridItem>
    </Grid>
  );
};
