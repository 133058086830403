import { eventsGql } from 'hooks/events';
import { usePaginatedQuery } from 'hooks/pagination';
import { EventFilters, Query, QueryEventsArgs } from 'types';

const NOTIFICATIONS_DEFAULT_OFFSET = 0;
const NOTIFICATIONS_DEFAULT_LIMIT = 20;

const DEFAULT_PAGINATION_VARIABLES = {
  offset: NOTIFICATIONS_DEFAULT_OFFSET,
  limit: NOTIFICATIONS_DEFAULT_LIMIT,
};

export const useLoadEvents = (filters?: EventFilters) => {
  const { initialLoad, loadMore, reload, error, loading } = usePaginatedQuery<
    Pick<Query, 'events'>,
    QueryEventsArgs
  >(eventsGql, DEFAULT_PAGINATION_VARIABLES);

  const loadEvents = () =>
    initialLoad({ filters }).then((result) => result.events);

  const loadMoreEvents = () =>
    loadMore({ filters }).then((result) => result.events);

  const reloadEvents = () =>
    reload({ filters }).then((result) => result.events);

  return {
    loadEvents,
    loadMoreEvents,
    reloadEvents,
    error,
    loading,
  };
};
