import { ReactNode } from 'react';

import { Flex, Text } from '@chakra-ui/react';

interface Props {
  title: string;
  renderComponent: ReactNode;
}

export const SearchPreview = ({ title, renderComponent }: Props) => {
  return (
    <Flex direction="column" gap="2">
      <Text size="p3b">{title}</Text>
      {renderComponent}
    </Flex>
  );
};
