/* eslint-disable react/jsx-pascal-case */

/* eslint-disable camelcase */
import { Form, FormikProvider } from 'formik';

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Email,
  Locked,
  Network_1,
  UserMultiple,
} from '@carbon/icons-react/next';
import {
  Button,
  ButtonGroup,
  Flex,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';

import { ItriomMenuButton } from 'components/Button';
import { Switch } from 'components/Form/Switch';
import { TextAreaInput } from 'components/Form/TextAreaInput';
import { TextInput } from 'components/Form/TextInput';
import { Upload } from 'components/Form/Upload';
import { Group } from 'types';
import { getFullName } from 'utils/string';

import { GroupAdminInput } from './GroupAdminInput';
import { GroupAdvisorsInput } from './GroupAdvisorsInput';
import { GroupEsgFocusInput } from './GroupEsgFocusInput';
import { GroupSdgFocusInput } from './GroupSdgFocusInput';
import { GroupTagsInput } from './GroupTagsInput';
import { PublicGroupModal } from './PublicGroupModal';
import { bottomButtonStyles } from './styles';
import { useGroupDialog } from './useGroupDialog';

type Props = {
  group?: Group;
};

export const ManageGroup = ({ group }: Props) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { t } = useTranslation(['groups', 'common']);
  const { formik, isNewGroup, publicGroupConfirmationModal } = useGroupDialog({
    group,
    isOpen,
    onClose,
  });

  const isEditing = !isNewGroup;

  const { isSubmitting, handleSubmit } = formik;

  const title = isNewGroup ? t('groups:create') : t('groups:manage');

  const handleChangeIsPublic = (value: boolean) => {
    formik.setFieldValue('familyOnly', !value);
  };

  const handleChangeFamilyOnly = (value: boolean) => {
    formik.setFieldValue('isPublic', !value);
  };

  const membersOptions = useMemo(
    () =>
      group?.members?.map(({ id, firstName, lastName, role }) => ({
        value: id,
        label: getFullName(firstName, lastName),
        role,
      })) ?? [],
    [group?.members]
  );

  return (
    <>
      <ItriomMenuButton onClick={onOpen}>{title}</ItriomMenuButton>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent p={[3, 6]} minW={650} borderRadius={0}>
          <Heading size="h5" mb={6}>
            {isNewGroup
              ? t('groups:create')
              : t('groups:update', { name: group?.name })}
          </Heading>
          <ModalCloseButton />
          <ModalBody>
            <FormikProvider value={formik}>
              <Form>
                <Flex maxW={520} mx="auto" direction="column" gap={4} mb={20}>
                  <HStack alignItems="flex-start">
                    <Upload
                      name="heroImage"
                      label={t('groups:manage-modal.banner.title')}
                      showErrorIcon
                    />

                    <Upload
                      name="avatarImage"
                      label={t('groups:manage-modal.avatar.title')}
                      showErrorIcon
                    />
                  </HStack>

                  <TextInput
                    type="text"
                    name="name"
                    label={t('groups:manage-modal.name.title')}
                    showErrorIcon
                    placeholder={t('groups:manage-modal.name.placeholder')}
                  />

                  <TextAreaInput
                    maxLength={100}
                    type="text"
                    name="description"
                    label={t('groups:manage-modal.short-description.title')}
                    showErrorIcon
                    placeholder={t(
                      'groups:manage-modal.short-description.placeholder'
                    )}
                  />

                  {isEditing && (
                    <>
                      <GroupAdminInput
                        membersOptions={membersOptions}
                        isDisabled={!group?.canMakeAdmins}
                      />
                      <GroupAdvisorsInput membersOptions={membersOptions} />
                    </>
                  )}
                  <GroupSdgFocusInput />
                  <GroupEsgFocusInput />
                  <GroupTagsInput />

                  <Flex mb={10} />

                  <Switch
                    icon={<Network_1 size={24} />}
                    label={t('groups:manage-modal.public.title')}
                    description={t('groups:manage-modal.public.description')}
                    name="isPublic"
                    onChange={handleChangeIsPublic}
                    isDisabled={isEditing && !group?.familyOnly}
                  />

                  {(isNewGroup || group?.familyOnly) && (
                    <Switch
                      icon={<Locked size="24" />}
                      label={t('groups:manage-modal.family-only.title')}
                      description={t(
                        'groups:manage-modal.family-only.description'
                      )}
                      name="familyOnly"
                      onChange={handleChangeFamilyOnly}
                    />
                  )}

                  <Switch
                    icon={<UserMultiple size="24" />}
                    label={t(
                      'groups:manage-modal.automatic-family-members.title'
                    )}
                    description={t(
                      'groups:manage-modal.automatic-family-members.description'
                    )}
                    name="automaticallyAddFamilyMembers"
                  />

                  <Switch
                    icon={<Email size="24" />}
                    label={t('groups:manage-modal.allow-invites.title')}
                    description={t(
                      'groups:manage-modal.allow-invites.description'
                    )}
                    name="allowInvites"
                  />
                </Flex>

                <ButtonGroup isAttached flex={1} width="100%">
                  <Button
                    width="100%"
                    sx={bottomButtonStyles}
                    bg="#333333"
                    ml="-48px"
                    onClick={onClose}
                  >
                    {t('common:cancel')}
                  </Button>
                  <Button
                    width="100%"
                    sx={bottomButtonStyles}
                    bg="primary.lime.default"
                    color="primary.blue.default"
                    mr="-48px"
                    type="submit"
                    isLoading={isSubmitting}
                  >
                    {t('common:save')}
                  </Button>
                  <PublicGroupModal
                    isOpen={publicGroupConfirmationModal.isOpen}
                    onClose={publicGroupConfirmationModal.onClose}
                    onSubmit={handleSubmit}
                    isSubmitting={isSubmitting}
                  />
                </ButtonGroup>
              </Form>
            </FormikProvider>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
