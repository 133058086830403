import { gql, useQuery } from '@apollo/client';

import { OnboardReferenceType, Query, QuerySdgsByReferenceArgs } from 'types';
import { FamilySdgConfiguratorOutputFragment } from 'types/generated-fragments';

export const familySdgsByFamilyIdGql = gql`
  ${FamilySdgConfiguratorOutputFragment}
  query SdgsByReference(
    $referenceId: String!
    $referenceType: OnboardReferenceType!
  ) {
    sdgsByReference(referenceId: $referenceId, referenceType: $referenceType) {
      ...FamilySdgConfiguratorOutputFragment
    }
  }
`;

export const useFamilySdgsByFamilyId = (
  referenceType: OnboardReferenceType,
  referenceId: string
) => {
  const { data, loading, error } = useQuery<
    Pick<Query, 'sdgsByReference'>,
    QuerySdgsByReferenceArgs
  >(familySdgsByFamilyIdGql, { variables: { referenceType, referenceId } });

  return { familySdgsChoices: data?.sdgsByReference, loading, error };
};
