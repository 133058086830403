import { Form, FormikProvider } from 'formik';

import { Box, Button, Text } from '@chakra-ui/react';

import { TextInput } from 'components/Form/TextInput';
import { RightBlock } from 'components/Layout/RightBlock';
import { WelcomeLayout } from 'components/Layout/WelcomeLayout';
import { useAuthContext } from 'contexts/AuthContext';
import { useSetPasswordForFirstTime } from 'hooks/authorization';

export const OtpSetPasswordPage = () => {
  const { account } = useAuthContext();

  const { formik, error, loading } = useSetPasswordForFirstTime();

  return (
    <WelcomeLayout title="The Values Configurator">
      <RightBlock
        title={`Welcome ${account?.firstName}`}
        subtitle="Please set up a password for your Itriom account."
      >
        <FormikProvider value={formik}>
          <Form>
            <Text size="p2" mb={4}>
              Password minimum length is 8 characters
            </Text>
            <Text size="p2" mb={4}>
              New password must require:
            </Text>
            <Box as="ul" pl={6} mb={14}>
              <li>Uppercase and lowercase letter (A, z)</li>
              <li>Numeric character (0-9)</li>
              <li>Special character (!, %, @, #, etc.)</li>
            </Box>
            <TextInput label="New password" name="password" type="password" />
            <Box mb={4} />
            <TextInput
              label="Confirm password"
              name="confirmPassword"
              type="password"
            />
            <Button
              bgColor="primary.lime.default"
              color="primary.blue.default"
              variant="solid"
              borderRadius={2}
              h={12}
              px={6}
              mt={14}
              type="submit"
              isLoading={loading}
            >
              <Text size="p2">Submit</Text>
            </Button>
            <Text color="status.red.default" size="p3">
              {error?.message}
            </Text>
          </Form>
        </FormikProvider>
      </RightBlock>
    </WelcomeLayout>
  );
};
