import { gql } from '@apollo/client';

import { ProfileFragment } from 'types/generated-fragments';

export const GroupNotificationMetadataFragment = gql`
  fragment GroupNotificationMetadataFragment on GroupNotificationMetadata {
    accountId
    group {
      id
      name
    }
  }
`;

export const PostNotificationMetadataFragment = gql`
  fragment PostNotificationMetadataFragment on PostNotificationMetadata {
    accountId
    postInGroupId
    postId
  }
`;

export const NewFamilyContentNotificationMetadataFragment = gql`
  fragment NewFamilyContentNotificationMetadataFragment on NewFamilyContentMetadata {
    entityType
    entityId
    accountId
  }
`;

export const InitiativeNotificationMetadataFragment = gql`
  fragment InitiativeNotificationMetadataFragment on InitiativeNotificationMetadata {
    initiativeId
    accountId
  }
`;

export const GroupNotificationOutputFragment = gql`
  ${ProfileFragment}
  ${GroupNotificationMetadataFragment}
  fragment GroupNotificationOutputFragment on GroupNotificationOutput {
    id
    createdAt
    type
    isRead
    metadata {
      ...GroupNotificationMetadataFragment
    }
    category
    sender {
      ...ProfileFragment
    }
  }
`;

export const PostNotificationOutputFragment = gql`
  ${ProfileFragment}
  ${PostNotificationMetadataFragment}
  fragment PostNotificationOutputFragment on PostNotificationOutput {
    id
    createdAt
    type
    isRead
    category
    metadata {
      ...PostNotificationMetadataFragment
    }
    sender {
      ...ProfileFragment
    }
  }
`;

export const NewFamilyContentOutputFragment = gql`
  ${ProfileFragment}
  ${NewFamilyContentNotificationMetadataFragment}
  fragment NewFamilyContentOutputFragment on NewFamilyContentOutput {
    id
    createdAt
    type
    category
    metadata {
      ...NewFamilyContentNotificationMetadataFragment
    }
    sender {
      ...ProfileFragment
    }
  }
`;

export const InitiativeNotificationOutputFragment = gql`
  ${ProfileFragment}
  ${InitiativeNotificationMetadataFragment}
  fragment InitiativeNotificationOutputFragment on InitiativeNotificationOutput {
    id
    createdAt
    type
    category
    metadata {
      ...InitiativeNotificationMetadataFragment
    }
    sender {
      ...ProfileFragment
    }
  }
`;
