import { useState } from 'react';

import { VStack } from '@chakra-ui/layout';
import { Box, Flex, Heading, Text, useDisclosure } from '@chakra-ui/react';

import { ValueCategoryIcon } from 'components/ItriomIcon';
import { useValuesCopy } from 'hooks/copy/useValueCopy';
import { FamilyValuesCategoryEnum, SdgOutput } from 'types';
import { SdgInternalName } from 'types/Sdg';
import { capitalize } from 'utils/string';

import { SdgElement } from '../../SdgElement';
import { SdgModal } from '../SdgModal';

type Props = {
  category: FamilyValuesCategoryEnum;
  valueName: string;
  sdgs: SdgOutput[];
};

export const Linkage = ({ category, valueName, sdgs }: Props) => {
  const [sdgInternalName, setSdgInternalName] = useState<SdgInternalName>(
    SdgInternalName.Sdg1
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { getValueCopy } = useValuesCopy();

  const handleClick = (sdgName: string) => {
    setSdgInternalName(sdgName as SdgInternalName);
    onOpen();
  };

  return (
    <Flex
      direction="column"
      borderRadius="5px 5px 0px 0px"
      py={6}
      justifyContent="space-between"
      m={3}
      maxWidth={250}
    >
      <Box
        display="inherit"
        justifyContent="center"
        alignItems="center"
        bgColor="ui.white"
        color="primary.blue.default"
      >
        <Box>
          <ValueCategoryIcon category={category} />
        </Box>
        <Text ml={2} size="p1">
          {category}
        </Text>
      </Box>
      <VStack
        bgColor="primary.blue.default"
        borderRadius="4px"
        mt={6}
        p={6}
        h="full"
        justifyContent="space-between"
      >
        <Box>
          <SdgModal
            isOpen={isOpen}
            onClose={onClose}
            sdgInternalName={sdgInternalName}
          />

          <Heading size="h3" color="ui.white">
            {capitalize(valueName)}
          </Heading>
          <Text size="p2" my={4} color="ui.white">
            {getValueCopy(valueName).description}
          </Text>
        </Box>
        <Box>
          <Flex
            flex="1 1"
            flexDirection="row-reverse"
            flexWrap="wrap-reverse"
            justifyContent="flex-end"
            gap={1}
          >
            {sdgs.map((sdg) => (
              <Box
                w={24}
                h={24}
                key={sdg.id}
                onClick={() => handleClick(sdg.internalName)}
              >
                <SdgElement internalName={sdg.internalName} />
              </Box>
            ))}
          </Flex>
        </Box>
      </VStack>
    </Flex>
  );
};
