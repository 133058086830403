import { Form, FormikProvider, useFormik } from 'formik';
import { string, object, mixed } from 'yup';

import { Box, VStack } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/react';

import { TextAreaInput } from 'components/Form/TextAreaInput';
import { TextInput } from 'components/Form/TextInput';
import { Upload } from 'components/Form/Upload';
import { useAuthContext } from 'contexts/AuthContext';
import { useUpdateAccount } from 'hooks/useUpdateAccount';
import { Account, Media } from 'types';
import { getUpdateFileValue, mediaOutputToArray } from 'utils/file';

const validationSchema = object().shape({
  firstName: string().required('No first name provided'),
  lastName: string().required('No last name provided'),
  title: string().required('No title provided'),
  description: string(),
  heroImage: mixed().required('No hero image supplied'),
  avatarImage: mixed().required('No avatar image supplied'),
});

interface FormValues
  extends Pick<
    Account,
    'firstName' | 'lastName' | 'title' | 'description' | 'role'
  > {
  heroImage: Media;
  avatarImage: Media;
}

type Props = { account: Account };

const PersonalInformationWithProvider = ({ account }: Props) => {
  const { firstName, lastName, role, title, description } = account;

  const { updateAccount } = useUpdateAccount();

  const handleSubmit = (values: FormValues) => {
    const { heroImage, avatarImage, ...restValues } = values;

    return updateAccount({
      account: { ...restValues },
      heroImage: getUpdateFileValue(heroImage),
      avatarImage: getUpdateFileValue(avatarImage),
    });
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      role,
      firstName,
      lastName,
      title: title ?? '',
      description: description ?? '',
      heroImage: mediaOutputToArray(account?.heroImage),
      avatarImage: mediaOutputToArray(account?.avatarImage),
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  const { isSubmitting } = formik;

  return (
    <Box w="600px">
      <FormikProvider value={formik}>
        <Form>
          <VStack>
            <TextInput
              type="text"
              name="firstName"
              label="First name"
              placeholder="First name"
            />
            <TextInput
              type="text"
              name="lastName"
              label="Last name"
              placeholder="First name"
            />
            <TextInput
              type="text"
              name="title"
              label="Title"
              placeholder="First name"
            />
            <TextAreaInput type="text" name="description" label="Description" />
            <Upload name="heroImage" label="Mainbanner" />
            <Upload name="avatarImage" label="Avatar image" />
          </VStack>
          <Button mt={4} isLoading={isSubmitting} type="submit">
            Update account
          </Button>
        </Form>
      </FormikProvider>
    </Box>
  );
};

export const PersonalInformation = () => {
  const { account } = useAuthContext();

  return account ? <PersonalInformationWithProvider account={account} /> : null;
};
