import { Form, FormikProvider, useFormik } from 'formik';

import { Link, useSearchParams } from 'react-router-dom';

import { Flex } from '@chakra-ui/layout';
import { Button, Text, ButtonGroup } from '@chakra-ui/react';

import { TextInput } from 'components/Form/TextInput';
import { RightBlock } from 'components/Layout/RightBlock';
import { useForgotPassword } from 'hooks/authorization';
import { Links } from 'router/links';

import { forgotPasswordSchema } from '../components/validation';

export const ResetPasswordLinkPage = () => {
  const { forgotPassword, loading } = useForgotPassword();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');

  const formik = useFormik({
    initialValues: { email: email || '' },
    validationSchema: forgotPasswordSchema,
    onSubmit: forgotPassword,
  });

  return (
    <RightBlock title="Forgot Password" subtitle="Confirm your email address">
      <Flex direction="column" gap={14}>
        <Text size="p2">
          We will send you an email with a link to reset your password.
        </Text>

        <FormikProvider value={formik}>
          <Form style={{ height: '100%' }}>
            <Flex direction="column" height="100%">
              <TextInput
                label="Email address"
                name="email"
                type="email"
                placeholder="name@domain.com"
              />

              <ButtonGroup mt={120}>
                <Button
                  as={Link}
                  to={Links.signIn}
                  bgColor="transparent"
                  color="primary.lime.default"
                  variant="primaryLime"
                  borderRadius={2}
                  borderColor="primary.lime.default"
                  h={12}
                  fontSize="md"
                  px={6}
                  mr="auto"
                  _hover={{
                    color: 'limeHighlight',
                    borderColor: 'limeHighlight',
                  }}
                  _focus={{
                    bgColor: 'transparent',
                    color: 'primary.lime.default',
                    borderColor: 'primary.lime.default',
                  }}
                >
                  Back
                </Button>
                <Button
                  bgColor="primary.lime.default"
                  color="primary.blue.default"
                  variant="primaryLime"
                  borderRadius={2}
                  h={12}
                  fontSize="md"
                  px={6}
                  type="submit"
                  isLoading={loading}
                >
                  Send Link
                </Button>
              </ButtonGroup>
            </Flex>
          </Form>
        </FormikProvider>
      </Flex>
    </RightBlock>
  );
};
