import { Box, Flex, Heading } from '@chakra-ui/react';

import { BookDemoButton } from './BookDemoButton';
import { SectionWithImage } from './SectionWithImage';

export const LegacySection = () => (
  <Flex bg="primary.blue.default" w="full" color="ui.white" py={90}>
    <SectionWithImage
      heading="Leaving a Lasting Legacy: Overview of Impact and Importance"
      imageSrc="/assets/marketplace/legacy-image.jpg"
    >
      <Flex direction="column" gap={8} pr={32}>
        <Heading size="h5">
          By tackling the world’s most pressing problems through tangible,
          measurable and sustainable outcomes, we are creating credible and
          lasting legacies for the families we represent. While helping bring
          the UN’s Sustainable Development Goals closer to fruition.
        </Heading>
        <Box mr="auto">
          <BookDemoButton />
        </Box>
      </Flex>
    </SectionWithImage>
  </Flex>
);
