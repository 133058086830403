import { gql, useQuery } from '@apollo/client';

import { Profile } from 'gql/fragments';
import { Query } from 'types';

export const newlyJoinedGroupMembersGql = gql`
  ${Profile}
  query NewlyJoinedGroupMembers($groupId: String!) {
    newlyJoinedGroupMembers(id: $groupId) {
      ...Profile
    }
  }
`;

export const useNewlyJoinedGroupMembers = (groupId?: string) => {
  const { data, loading, error } = useQuery<
    Pick<Query, 'newlyJoinedGroupMembers'>
  >(newlyJoinedGroupMembersGql, {
    variables: {
      groupId,
    },
    skip: !groupId,
  });

  const newlyJoinedGroupMembers = data?.newlyJoinedGroupMembers || [];

  return { newlyJoinedGroupMembers, loading, error };
};
