import {
  AdminFamilyCharterProvider,
  useAdminFamilyCharterContext,
} from 'contexts/AdminFamilyCharterContext';

import { FamilyCharterPage } from './FamilyCharterPage';

export const AdminFamilyCharterPage = () => {
  return (
    <AdminFamilyCharterProvider>
      <FamilyCharterPage useFamilyCharter={useAdminFamilyCharterContext} />
    </AdminFamilyCharterProvider>
  );
};
