import { gql, MutationHookOptions, useMutation } from '@apollo/client';

import { useLocalStorageJWT } from 'hooks/useLocalStorageJWT';
import { Mutation, MutationVerifySecondFactorForTokenArgs } from 'types';

import { useWithMutationToast } from '../useWithMutationToast';

export const verifySecondFactorForTokenGql = gql`
  mutation VerifySecondFactorForToken(
    $tokenType: SecondFactorVerificationTypeEnum!
    $token: String!
    $secondFactor: Float!
  ) {
    verifySecondFactorForToken(
      tokenType: $tokenType
      token: $token
      secondFactor: $secondFactor
    ) {
      accessToken
    }
  }
`;

export const useVerifySecondFactorForToken = <
  TData extends Pick<Mutation, 'verifySecondFactorForToken'>,
  TVariables extends MutationVerifySecondFactorForTokenArgs
>(
  options?: MutationHookOptions<TData, TVariables>
) => {
  const { setJwtInLocalStorage } = useLocalStorageJWT();

  const [mutation, { loading, error }] = useMutation<TData, TVariables>(
    verifySecondFactorForTokenGql,
    {
      onCompleted: (data) => {
        setJwtInLocalStorage(data.verifySecondFactorForToken.accessToken);
      },
      ...options,
    }
  );

  const handleMutation = (variables: TVariables) => mutation({ variables });

  const verifySecondFactorForToken = useWithMutationToast(handleMutation, {
    errorMessage: 'Two factor authentication error',
    disableSuccessMessage: true,
  });

  return {
    verifySecondFactorForToken,
    loading,
    error,
  };
};
