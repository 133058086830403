import { useAuthContext } from 'contexts/AuthContext';
import { Conversation } from 'types';

export const useOtherConversationMembers = (conversation: Conversation) => {
  const { account } = useAuthContext();

  const otherMembers = conversation.members.filter(
    ({ id }) => id !== account?.id
  );

  return otherMembers;
};
