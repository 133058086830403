import { useTranslation } from 'react-i18next';

import { ViewButton } from 'components/Button';
import { Panel } from 'components/Layout/Panel';
import { RecentlyConnected } from 'components/RecentlyConnected';
import { RecommendedConnections } from 'components/RecommendedConnections';
import { useHasConnections } from 'hooks/connections/useHasConnections';
import { Links } from 'router/links';

import { useIsConnectionPage } from './useIsConnectionPage';

export const ConnectionsPanel = () => {
  const { t } = useTranslation(['common']);
  const isConnectionPage = useIsConnectionPage();
  const { hasConnections } = useHasConnections();

  if (!hasConnections) return null;

  return (
    <Panel
      title={t('common:connections')}
      action={
        <ViewButton variant="blue" to={Links.connections}>
          {t('common:view-all')}
        </ViewButton>
      }
      contentWrapperStyling={{ direction: 'column', gap: 4 }}
      h="fit-content"
    >
      <RecommendedConnections showList={isConnectionPage} />

      <RecentlyConnected />
    </Panel>
  );
};
