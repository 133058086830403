import { useCallback } from 'react';

import { gql, useMutation } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationCompleteFamilySdgConfiguratorArgs } from 'types';

export const completeFamilySdgConfiguratorGql = gql`
  mutation CompleteFamilySdgConfigurator($familyId: String!) {
    completeFamilySdgConfigurator(familyId: $familyId)
  }
`;

export const useCompleteSDGConfigurator = (familyId: string) => {
  const [completeSDGConfiguratorMutation] = useMutation<
    Pick<Mutation, 'completeFamilySdgConfigurator'>,
    MutationCompleteFamilySdgConfiguratorArgs
  >(completeFamilySdgConfiguratorGql, {
    refetchQueries: ['OnboardManagement'],
  });

  const completeSDGConfigurator = useCallback(async () => {
    const { errors } = await completeSDGConfiguratorMutation({
      variables: { familyId },
    });

    if (errors?.length) {
      throw new Error(errors[0]?.message);
    }
  }, [completeSDGConfiguratorMutation, familyId]);

  const completeSDGConfiguratorWithToast = useWithMutationToast(
    completeSDGConfigurator,
    {
      successMessage: 'Completed SDG Configurator process for family.',
      errorMessage: 'Failed completing SDG Configurator process.',
    }
  );

  return completeSDGConfiguratorWithToast;
};
