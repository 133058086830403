import { SdgOutput } from './generated-types';

export enum SdgsConfigId {
  Linkage = 'linkage',
  Ordering = 'ordering',
  Individual = 'individual',
  Families = 'families',
}

export type SDGDragItem = {
  id: string;
  sdg: SdgOutput;
  orderIndex: number;
};

export type SdgFormType = { selected: SDGDragItem[] };
