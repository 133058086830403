import { Box, Flex, Text } from '@chakra-ui/react';

import { WithChildren } from 'types';

import { SectionHeading } from '../../SectionHeader';

const StyledText = ({ children }: WithChildren) => (
  <Text size="p1">{children}</Text>
);

type Props = {
  isFromModal?: boolean;
};

export const HowToUse = ({ isFromModal }: Props) => {
  return (
    <Box w="full" zIndex={1}>
      <SectionHeading
        heading="How to use the Values Configurator"
        subheading="Prioritising four values"
        containerStyling={{ mr: 'none' }}
      />

      <Flex
        direction="column"
        gap={8}
        w={isFromModal ? 'full' : { base: 'full', lg: '540px' }}
        lineHeight={6}
      >
        <StyledText>
          You will be presented with 4 categories of 4 values which you will
          assess on 2 criteria.
        </StyledText>
        <StyledText>
          Your assessments will be based on your perception of each value’s
          importance to your family, and separately by its relevance.
        </StyledText>
        <StyledText>
          This enables us to prioritise the values, and to identify one from
          each category that you have said is the most important and relevant to
          you.
        </StyledText>

        <StyledText>You will now see 4 categories of 4 values.</StyledText>
        <StyledText>
          In each category you will allocate points to each of 4 values, based
          on the importance and separately the relevance of each value to you
          individually.
        </StyledText>
        <StyledText>
          Importance: The extent to which you view this value as desirable.
        </StyledText>
        <StyledText>
          Relevance: The extent to which you view the value as relevant, i.e.,
          where you and your family can use it to help create meaningful impact
          in a context that matters to you.
        </StyledText>
        <StyledText>
          In each of the categories, you will be given a budget of 100 points to
          allocate amongst the values as you see fit using the above criteria.
        </StyledText>
        <StyledText>
          We expect that you will allocate more from the budget of 100 to the
          value you deem to be either the most important or most relevant.
        </StyledText>
        <StyledText>
          A key requirement is that your assessment enables differentiation
          between values. For that reason, allocating the same figure to
          multiple values isn’t allowed.
        </StyledText>
      </Flex>
    </Box>
  );
};
