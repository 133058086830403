import { useQuery } from '@apollo/client';

import {
  LibraryMediaOutput,
  LibrarySharedMediaOutput,
  Query,
  QueryGetDownloadUrlForSharedLibraryFileArgs,
} from 'types';

import { getDownloadUrlForSharedLibraryFileGql } from '../documents';

export const useGetDownloadUrlForSharedLibraryFile = (
  item: LibraryMediaOutput | LibrarySharedMediaOutput
) => {
  const { data, error, loading } = useQuery<
    Pick<Query, 'getDownloadUrlForSharedLibraryFile'>,
    Partial<QueryGetDownloadUrlForSharedLibraryFileArgs>
  >(getDownloadUrlForSharedLibraryFileGql, {
    variables: {
      fileId: item.id,
    },
    skip: item.isDirectory,
  });

  const sharedDownloadUrl = data?.getDownloadUrlForSharedLibraryFile;

  return {
    sharedDownloadUrl,
    loading,
    error,
  };
};
