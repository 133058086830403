import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { Search24 } from '@carbon/icons-react';
import {
  Avatar,
  Box,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
  Text,
} from '@chakra-ui/react';

import { useGroups } from 'hooks/groups';
import { Links } from 'router/links';
import { generateUrlById } from 'utils/links';

export const GroupSearch = () => {
  const [value, setValue] = useState('');
  const { groups } = useGroups();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setValue(event.target.value);

  const filteredGroups = useMemo(
    () =>
      groups.filter(({ name }) =>
        name.toLowerCase().includes(value.toLowerCase())
      ),
    [groups, value]
  );

  return (
    <Box position="relative">
      <InputGroup>
        <Input
          value={value}
          onChange={handleChange}
          name="search"
          placeholder="search groups..."
          border="medium"
          background="ui.white"
          autoComplete="off"
        />
        <InputRightElement>
          <Search24 width={14} height={14} />
        </InputRightElement>
      </InputGroup>
      {!!value.length && (
        <Box
          position="absolute"
          top="100%"
          backgroundColor="ui.white"
          my={2}
          zIndex={10}
          minWidth={{ base: '100%', md: 500, lg: 500 }}
          boxShadow="md"
          right={0}
        >
          <List spacing={3}>
            {filteredGroups.map(({ name, avatarImage, id }) => {
              return (
                <ListItem
                  px={4}
                  py={3}
                  key={id}
                  display="flex"
                  alignItems="center"
                  gap={3}
                >
                  <Avatar src={avatarImage?.signedUrl} size="sm" />
                  <Text size="p1" flexGrow={1} noOfLines={2}>
                    {name}
                  </Text>
                  <Button
                    as={Link}
                    to={generateUrlById(Links.groupsSingle, id)}
                    variant="outline"
                    borderColor="primary.blue.default"
                    color="primary.blue.default"
                    borderRadius={4}
                    borderWidth={2}
                    h={10}
                    flexShrink={0}
                  >
                    View
                  </Button>
                </ListItem>
              );
            })}
          </List>
        </Box>
      )}
    </Box>
  );
};
