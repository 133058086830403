import { useTranslation } from 'react-i18next';

import { FolderAdd } from '@carbon/icons-react/next';
import { Button, useDisclosure } from '@chakra-ui/react';

import { FolderNameModal } from '../FolderNameModal';

export const NewFolder = () => {
  const { t } = useTranslation(['library', 'common']);
  const { onOpen, isOpen, onClose } = useDisclosure();

  return (
    <>
      <Button variant="ghost" leftIcon={<FolderAdd />} onClick={onOpen}>
        {t('library:folder.new')}
      </Button>
      <FolderNameModal type="create" onClose={onClose} isOpen={isOpen} />
    </>
  );
};
