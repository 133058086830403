import { FormikValues, validateYupSchema, yupToFormErrors } from 'formik';
import { array, Schema } from 'yup';

import { capitalize } from './string';

export enum Articles {
  A = 'a',
  An = 'an',
  The = 'the',
}

export const validateWithContext =
  <T extends FormikValues>(
    validationSchema: Schema,
    context: Record<string, unknown>
  ) =>
  async (values: T) => {
    try {
      await validateYupSchema(values, validationSchema, true, context);
    } catch (err) {
      return yupToFormErrors(err);
    }

    return {};
  };

// TODO: refactor validation error messages across application
// https://itriom.atlassian.net/browse/LEIP-1115
export const ErrorMessages = {
  TOO_LONG: (inputName: string, maxChars: string | number): string =>
    `${capitalize(inputName)} can't be longer than ${maxChars} characters`,

  IS_REQUIRED: (inputName: string): string =>
    `${capitalize(inputName)} is required`,

  MIN: (inputName: string, minElements: number): string =>
    `${capitalize(inputName)} must have at least ${minElements} elements`,

  MAX: (inputName: string, maxElements: number): string =>
    `${capitalize(inputName)} must have at most ${maxElements} elements`,

  BETWEEN: (
    inputName: string,
    minElements: number,
    maxElemets: number
  ): string =>
    `${capitalize(
      inputName
    )} must have at least ${minElements} and at most ${maxElemets} elements`,
  IS_REQUIRED_POLITELY: (article: string, inputName: string): string =>
    `Please provide ${article} ${inputName.toLowerCase()}.`,
};

export const validateImagesCount = (
  min = 0,
  max: number | undefined = undefined,
  isNullable = true
) => {
  return array().test({
    message: max
      ? ErrorMessages.BETWEEN('images', min, max)
      : ErrorMessages.MIN('images', min),
    test: (arr) => {
      if (!arr || arr.length === 0) {
        return isNullable;
      }

      const filteredArray = arr.filter(Boolean);

      const hasValidMin = min <= filteredArray.length;
      const hasValidMax = max ? filteredArray.length <= max : true;

      const hasValidImagesCount = hasValidMin && hasValidMax;

      return hasValidImagesCount;
    },
  });
};
