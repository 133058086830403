import { SdgIcon } from 'components/Icons/SdgIcon';

type Props = {
  internalName: string;
};

export const SdgElement = ({ internalName }: Props) => {
  return (
    <SdgIcon
      name={internalName}
      additionalStyling={{ alt: internalName, cursor: 'pointer' }}
    />
  );
};
