import { Box } from '@chakra-ui/react';

import { ANGLE_MODIFIER, OUTER_RADIUS, VALUE_SVG_WIDTH } from '../../constants';
import { useBuildingAnimationContext, useWheelState } from '../../contexts';
import { Layer, ValueCategory, ValuePosition } from '../../types';
import { CategoryClickOverlay } from './CategoryClickOverlay';
import { ValuePanel } from './ValuePanel';

const SVG_WIDTH = Math.floor(VALUE_SVG_WIDTH / ANGLE_MODIFIER);

type Props = {
  category: ValueCategory;
};

export const ValuesQuadrant = ({ category }: Props) => {
  const { hasFinishedAnimating } = useBuildingAnimationContext();
  const { selectedValue, handleValueChange, handleCategoryChange } =
    useWheelState();

  const { position, mainColor, secondaryColor, values, textColor } = category;

  const handleClick = (valueName: string) => {
    if (hasFinishedAnimating) {
      handleValueChange(valueName, category);
    }
  };

  const getValueBackground = (valuePosition: ValuePosition): string =>
    (valuePosition + category.position) % 2 === 1 ? mainColor : secondaryColor;

  return (
    <Box
      zIndex={Layer.Values}
      position="absolute"
      w={OUTER_RADIUS}
      h={OUTER_RADIUS}
    >
      <svg width="100%" height="100%" viewBox={`0 0 ${SVG_WIDTH} ${SVG_WIDTH}`}>
        {values.map((value, index) => (
          <ValuePanel
            key={value}
            valuePosition={index}
            backgroundColor={getValueBackground(index)}
            onClick={() => handleClick(value)}
            valueName={value}
            textColor={textColor}
            categoryPosition={position}
            isSelected={selectedValue === value}
          />
        ))}
        {/* Since it is svg shape with onClick, it has to be placed in the same svg as 
        ValuePanels, in order to not overlap */}
        <CategoryClickOverlay onClick={() => handleCategoryChange(category)} />
      </svg>
    </Box>
  );
};
