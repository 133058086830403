import { Form, FormikProvider, useFormik } from 'formik';

import { useTranslation } from 'react-i18next';

import { Add } from '@carbon/icons-react/next';
import { Button, Flex, useDisclosure } from '@chakra-ui/react';

import { Modal } from 'components/Modal';
import { useLibraryContext } from 'features/Library/context';
import { useLibraryUpload } from 'features/Library/hooks/useLibraryUpload';
import { humanFileSize } from 'utils/size';

import { UploadBox } from './UploadBox';
import { FIELD_NAME } from './constants';

interface FormValues {
  [FIELD_NAME]: File[];
}

export const Upload = () => {
  const { t } = useTranslation(['library', 'common']);
  const { upload } = useLibraryUpload();
  const { addUploadingItems } = useLibraryContext();

  const { onOpen, isOpen, onClose } = useDisclosure();
  const formik = useFormik<FormValues>({
    initialValues: { [FIELD_NAME]: [] },
    onSubmit: () => Promise.resolve(),
  });

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  const handleSubmit = () => {
    const files = formik.values[FIELD_NAME];
    addUploadingItems(
      files.map((file) => ({
        name: file.name,
        size: humanFileSize(file.size),
        progress: 0,
      }))
    );
    files.forEach(async (file: File) => {
      upload(file);
    });
    handleClose();
  };

  return (
    <>
      <Button
        onClick={onOpen}
        variant="primaryLime"
        width="full"
        height="full"
        rightIcon={<Add />}
      >
        {t('library:upload.button')}
      </Button>
      <Modal
        title={t('library:upload.modal-title')}
        isOpen={isOpen}
        onClose={onClose}
        closeButtonVisible={false}
        footer={
          <Flex justifyContent="space-between" w="full">
            <Button variant="secondaryBlue" onClick={handleClose}>
              {t('common:cancel')}
            </Button>
            <Button
              type="submit"
              variant="primaryBlue"
              isDisabled={!formik.values[FIELD_NAME].length}
              onClick={handleSubmit}
            >
              {t('common:upload')}
            </Button>
          </Flex>
        }
      >
        <FormikProvider value={formik}>
          <Form>
            <UploadBox />
          </Form>
        </FormikProvider>
      </Modal>
    </>
  );
};
