import { Heading } from '@chakra-ui/react';

import { SideImageTile } from 'components/ContentGrid/SideImageTile';
import { InfiniteList } from 'components/InfiniteList';
import { QueryStateGuard } from 'components/QueryStateGuard';
import { usePaginationState } from 'hooks/pagination';
import { Links } from 'router/links';
import { generateUrlById } from 'utils/links';
import { flattenTags } from 'utils/tags';

import { InitiativeType, TABS_WITH_FOLLOW_SECTION } from '../../types';
import { InitiativesEmptyState } from './InitiativesEmptyState';
import { useInitiativesPaginated } from './useInitiativesPaginated';

type Props = {
  type: InitiativeType;
  isOther?: boolean;
};

export const InitiativeTabBasePanel = ({ type, isOther }: Props) => {
  const showFollowedInitiatives = TABS_WITH_FOLLOW_SECTION.includes(type);

  const { loadMoreInitiatives, loadInitiatives, error, loading } =
    useInitiativesPaginated(type, isOther);

  const { hasMore, items, loadMoreItems } = usePaginationState({
    initialLoad: loadInitiatives,
    loadMore: loadMoreInitiatives,
  });

  return (
    <>
      {showFollowedInitiatives && (
        <Heading size="h4" mb={2}>
          {isOther ? 'Other initiatives' : 'Your followed initiatives'}
        </Heading>
      )}
      <QueryStateGuard loading={loading} error={error}>
        {items.length === 0 ? (
          <InitiativesEmptyState
            showFollowedInitiatives={showFollowedInitiatives}
          />
        ) : (
          <InfiniteList
            items={items}
            scrollWithRoot
            loadMoreItems={loadMoreItems}
            hasMoreItems={hasMore}
            scrollComponentStyle={{
              gap: 'var(--chakra-space-4)',
            }}
            renderItem={({ id, hero, tags }) => (
              <SideImageTile
                imagePosition="left"
                tags={flattenTags(tags)}
                image={hero.heroImage.signedUrl || ''}
                title={hero.headline}
                description={hero.summary}
                link={generateUrlById(Links.initiativesSingle, id)}
                key={id}
              />
            )}
          />
        )}
      </QueryStateGuard>
    </>
  );
};
