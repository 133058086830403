import { CSSProperties, ReactNode, useCallback, useId, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Flex, FlexProps } from '@chakra-ui/react';

import { Loader } from 'components/Loader';
import { WithChildren } from 'types';

import { ConditionalWrapper } from '../ConditionalWrapper/ConditionalWrapper';

export type InfiniteListProps<T> = {
  containerId?: string;
  scrollWithRoot?: boolean;
  items: T[];
  hasMoreItems: boolean;
  inverse?: boolean;
  loadMoreItems: () => void;
  renderItem: (item: T, index: number) => ReactNode;
  wrapperProps?: FlexProps;
  scrollComponentStyle?: CSSProperties;
  emptyListMessage?: string;

  sort?: (a: T, b: T) => number;
};

export const InfiniteList = <T,>({
  containerId,
  scrollWithRoot,
  items,
  hasMoreItems,
  inverse,
  loadMoreItems,
  renderItem,
  wrapperProps,
  scrollComponentStyle,
  emptyListMessage,
  sort,
}: InfiniteListProps<T>) => {
  const direction = inverse ? 'column-reverse' : 'column';

  const id = useId();

  const sortedItems = useMemo(
    () => (sort ? [...items].sort(sort) : items),
    [items, sort]
  );

  const Wrapper = useCallback(
    ({ children }: WithChildren) => (
      <Flex
        id={containerId || id}
        overflowY="scroll"
        direction={direction}
        sx={{
          '& .infinite-scroll-component__outerdiv': {
            width: '100%',
          },
        }}
        flex={1}
        maxH="500px"
        {...wrapperProps}
      >
        {children}
      </Flex>
    ),
    [containerId, direction, id, wrapperProps]
  );

  return (
    <ConditionalWrapper Wrapper={!scrollWithRoot && Wrapper}>
      <InfiniteScroll
        dataLength={sortedItems.length}
        next={loadMoreItems}
        hasMore={hasMoreItems}
        inverse={inverse}
        loader={<Loader pt={2} spinnerSize="md" />}
        style={{
          width: '100%',
          display: 'flex',
          overflow: 'hidden',
          flexDirection: direction,
          ...scrollComponentStyle,
        }}
        scrollableTarget={!scrollWithRoot && (containerId || id)}
      >
        {sortedItems.length ? (
          sortedItems.map((item, index) => renderItem(item, index))
        ) : (
          <Flex p={2} justify="center">
            {emptyListMessage}
          </Flex>
        )}
      </InfiniteScroll>
    </ConditionalWrapper>
  );
};
