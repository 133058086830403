import {
  FamilyCharterProvider,
  useFamilyCharterContext,
} from 'contexts/FamilyCharterContext';

import { FamilyCharterPage } from './FamilyCharterPage';

export const CuratorFamilyCharterPage = () => {
  return (
    <FamilyCharterProvider>
      <FamilyCharterPage useFamilyCharter={useFamilyCharterContext} />
    </FamilyCharterProvider>
  );
};
