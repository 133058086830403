import { useTranslation } from 'react-i18next';

import { Flex, Checkbox, Heading, Text } from '@chakra-ui/react';

interface Props {
  type: 'files' | 'folders';
  count: number;
  checkedCount: number;
  hasEveryItemSelected: boolean;
  onAllChecked: VoidFunction;
}

export const FilesFoldersHeading = ({
  type,
  count,
  hasEveryItemSelected,
  onAllChecked,
  checkedCount,
}: Props) => {
  const { t } = useTranslation(['library']);

  return (
    <Flex
      direction="row"
      py={3}
      pl={10}
      pr={4}
      gap={4}
      borderBottomWidth={1}
      alignItems="center"
    >
      <Checkbox isChecked={hasEveryItemSelected} onChange={onAllChecked} />
      <Heading size="h5" flex={1}>
        {t(`library:${type}`, {
          count,
        })}
      </Heading>
      {!!checkedCount && (
        <Text size="p2">
          {t(`library:checked.${type}`, { count: checkedCount })}
        </Text>
      )}
    </Flex>
  );
};
