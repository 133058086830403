import { Tag, TagCloseButton, TagLabel } from '@chakra-ui/react';

import { Profile } from 'types';
import { getFullName } from 'utils/string';

type Props = {
  interlocutor: Profile;
  onRemove: () => void;
};

export const InterlocutorTag = ({ interlocutor, onRemove }: Props) => (
  <Tag size="lg" bgColor="primary.blue.default" variant="solid">
    <TagLabel fontSize="sm">
      {getFullName(interlocutor.firstName, interlocutor.lastName)}
    </TagLabel>
    <TagCloseButton onClick={onRemove} />
  </Tag>
);
