import { ReactNode } from 'react';

import { Box, BoxProps, Button, ButtonProps } from '@chakra-ui/react';

type Props = {
  onClick: () => void;
  children: ReactNode;
  isChecked: boolean;
} & ButtonProps;

export const SwitchButton = ({
  onClick,
  children,
  isChecked,
  ...buttonProps
}: Props): JSX.Element => {
  const stateStyles: BoxProps = isChecked
    ? {
        left: '100%',
        transform: 'translateX(calc(-100% - 0.5rem))',
      }
    : {
        left: '0',
        transform: 'translateX(0)',
      };

  return (
    <Button
      onClick={onClick}
      borderRadius="50px"
      pos="relative"
      px={16}
      _focus={{
        bgColor: 'primary.blue.default',
      }}
      _hover={{
        backgroundColor: 'white',
        color: 'primary.blue.default',
        span: {
          bg: 'primary.blue.default',
        },
      }}
      aria-label="publish"
      {...buttonProps}
    >
      <Box
        as="span"
        h="calc(100% - 0.5rem)"
        w="auto"
        pos="absolute"
        sx={{ aspectRatio: '1/1' }}
        bg="base3"
        borderRadius="50px"
        transition="all 0.25s ease-in-out"
        m={1}
        {...stateStyles}
      />
      {children}
    </Button>
  );
};
