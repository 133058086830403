import { FamilySdgConfiguratorOutput, SdgOutput } from 'types';

import { useSdgs } from './useSdgs';

export const useSdgsFromConfigurator = (
  sdgConfiguratorOutput: FamilySdgConfiguratorOutput | undefined
): SdgOutput[] | null => {
  const { sdgs } = useSdgs();

  if (!sdgConfiguratorOutput || !sdgs) return null;

  const foundSdgs = sdgConfiguratorOutput.sdgIdsWithNumericScores
    .map(({ sdgId }) => {
      const foundSdg = sdgs.find(({ id }) => id === sdgId);

      if (!foundSdg) return null;

      return foundSdg;
    })
    .filter(Boolean) as SdgOutput[];

  return foundSdgs;
};
