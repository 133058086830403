import { gql, MutationHookOptions, useMutation } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationRequestNewOtpArgs } from 'types';

export const requestOTPforJWTGql = gql`
  mutation RequestNewOTP(
    $jwt: String!
    $sendLocation: VerificationCodeSendLocation
  ) {
    requestNewOTP(jwt: $jwt, sendLocation: $sendLocation)
  }
`;

export const useRequestOTPforJWT = <
  TData extends Pick<Mutation, 'requestNewOTP'>,
  TVariables extends MutationRequestNewOtpArgs
>(
  options?: MutationHookOptions<TData, TVariables>
) => {
  const [mutation] = useMutation<TData, TVariables>(requestOTPforJWTGql, {
    fetchPolicy: 'no-cache',
    ...options,
  });

  const handleMutation = (variables: TVariables) => mutation({ variables });

  const requestOTPforJWT = useWithMutationToast(handleMutation, {
    disableSuccessMessage: true,
    errorMessage: 'Failed updating data',
  });

  return { requestOTPforJWT };
};
