import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Flex, Heading, Text } from '@chakra-ui/react';

import { HeroMedia } from 'components/HeroMedia';

import { HeroService } from '../../types';

type Props = {
  services: HeroService[];
};

export const NextGenServicesSection = ({ services }: Props) => {
  const { t } = useTranslation(['services']);

  return (
    <Flex
      direction="column"
      justify="center"
      align="center"
      w="full"
      bg="#F5F5F5"
      py={24}
      gap={8}
    >
      <Heading size="h3" textAlign="center" w="full">
        {t('services:headings.additional-services')}
      </Heading>
      <Flex
        direction={{ md: 'row', base: 'column' }}
        w="full"
        align="baseline"
        justify="center"
        gap={{ md: '16px', base: '55px' }}
      >
        {services.map(({ src, title, subtitle, link }) => (
          <Link to={link} key={src}>
            <HeroMedia
              imageUrl={src}
              containerStyles={{ h: 272, w: 470 }}
              childrenContainerStyles={{ p: 6 }}
            >
              <Flex
                color="ui.white"
                direction="column"
                cursor="default"
                w={{ base: '100%', lg: '90%' }}
              >
                <Text size="p1b">Services</Text>
                <Heading size="h3">{title}</Heading>
                <Text size="p2" whiteSpace="pre-line">
                  {subtitle}
                </Text>
              </Flex>
            </HeroMedia>
          </Link>
        ))}
      </Flex>
    </Flex>
  );
};
