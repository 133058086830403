import { CheckIcon } from '@chakra-ui/icons';
import { Box, Center, Flex, Heading, Text } from '@chakra-ui/react';

import { useGetOnboardContent } from '../../hooks';

export const SdgSummary = () => {
  const {
    onboard: { hasFamilyCharter, selectionCompleted },
  } = useGetOnboardContent();

  return (
    <Flex color="ui.white" w="full" h="full" justifyContent="center">
      <Box maxW={540} textAlign="center">
        <Heading size="h4" mb={10}>
          Your selection is complete
        </Heading>
        <Flex justifyContent="center">
          <Center
            w={70}
            h={70}
            borderRadius="50%"
            borderColor="ui.white"
            borderWidth={1}
            mb={20}
          >
            <CheckIcon color="ui.white" fontSize="3xl" />
          </Center>
        </Flex>

        <Text size="p1">{selectionCompleted}</Text>
        {hasFamilyCharter && (
          <Text size="p1" my={10}>
            We will notify you when all your family members have completed this
            process and your family’s 3 SDG influences have been identified.
          </Text>
        )}
      </Box>
    </Flex>
  );
};
