import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectInput } from 'components/Form/SelectInput';
import { useSdgs } from 'hooks/sdgs/useSdgs';

import { selectInputStyles } from './styles';

export const GroupSdgFocusInput = () => {
  const { sdgs } = useSdgs();
  const { t } = useTranslation(['groups']);

  const sdgOptions = useMemo(
    () =>
      sdgs.map((sdg) => ({
        label: `SDG ${sdg.index} - ${sdg.name}`,
        value: sdg.id,
        shortLabel: `SDG ${sdg.index}`,
      })),
    [sdgs]
  );

  return (
    <SelectInput
      name="sdgId"
      label={t('groups:manage-modal.sdg-tag.title')}
      options={sdgOptions}
      inputStyles={selectInputStyles}
    />
  );
};
