import { ConversationsState, ConversationsSelectors } from './types';

const createSelectConversations =
  (state: ConversationsState): ConversationsSelectors['selectConversations'] =>
  () =>
    state.conversations;

const createSelectHasMoreConversations =
  (
    state: ConversationsState
  ): ConversationsSelectors['selectHasMoreConversations'] =>
  () =>
    state.conversations.length < state.conversationsTotalCount;

const createSelectActiveConversation =
  (
    state: ConversationsState
  ): ConversationsSelectors['selectActiveConversation'] =>
  () => {
    const conversationFound = state.conversations.find(
      ({ id }) => state.activeConversationId === id
    );

    return conversationFound || undefined;
  };

const createSelectUnreadConversationsIds =
  (
    state: ConversationsState
  ): ConversationsSelectors['selectUnreadConversationIds'] =>
  () =>
    state.unreadConversationIds;

const createSelectInterlocutorsForNewConversation =
  (
    state: ConversationsState
  ): ConversationsSelectors['selectInterlocutorsForNewConversation'] =>
  () =>
    state.interlocutorsForNewConversation || [];

export const composeConversationsSelectors = (
  state: ConversationsState
): ConversationsSelectors => ({
  selectConversations: createSelectConversations(state),
  selectHasMoreConversations: createSelectHasMoreConversations(state),
  selectActiveConversation: createSelectActiveConversation(state),
  selectUnreadConversationIds: createSelectUnreadConversationsIds(state),
  selectInterlocutorsForNewConversation:
    createSelectInterlocutorsForNewConversation(state),
});
