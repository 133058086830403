import { Flex, Text } from '@chakra-ui/react';

import { LibraryMediaOutput } from 'types';

const MAX_FILE_TYPE_LENGTH = 4;

export const FileIcon = ({
  mimeType,
  name,
}: Partial<Pick<LibraryMediaOutput, 'mimeType' | 'name'>>) => {
  const mimeName = mimeType?.split('/')?.[1];
  const iconText =
    mimeName && mimeName.length <= MAX_FILE_TYPE_LENGTH
      ? mimeName
      : name?.split('.').pop();

  return (
    <Flex
      borderRadius={2}
      boxShadow="0px 0px 4px 0px rgba(0, 0, 0, 0.25)"
      w={8}
      h={4}
      alignItems="center"
      justifyContent="center"
    >
      <Text size="p3b" fontSize="10px">
        {iconText?.toUpperCase() || 'unknown'}
      </Text>
    </Flex>
  );
};
