import i18n from 'i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';

import { initReactI18next } from 'react-i18next';

import advisors from './locales/en/advisors.json';
import cms from './locales/en/cms.json';
import common from './locales/en/common.json';
import connections from './locales/en/connections.json';
import events from './locales/en/events.json';
import family from './locales/en/family.json';
import groups from './locales/en/groups.json';
import insights from './locales/en/insights.json';
import library from './locales/en/library.json';
import navigation from './locales/en/navigation.json';
import network from './locales/en/network.json';
import notifications from './locales/en/notifications.json';
import onboarding from './locales/en/onboarding.json';
import profile from './locales/en/profile.json';
import services from './locales/en/services.json';
import tabs from './locales/en/tabs.json';
import values from './locales/en/values.json';

export const defaultNS = 'common';

export const resources = {
  en: {
    common,
    navigation,
    tabs,
    cms,
    family,
    profile,
    network,
    advisors,
    services,
    values,
    insights,
    events,
    onboarding,
    groups,
    notifications,
    library,
    connections,
  },
} as const;

i18n
  .use(intervalPlural)
  .use(initReactI18next)
  .init({
    lng: 'en',
    debug: process.env.NODE_ENV === 'development',
    defaultNS,
    resources,
  });

i18n.services.formatter?.add('wrapInParenthesis', (value) =>
  value ? `(${value})` : ''
);

i18n.services.formatter?.add('optional', (value) => value || '');

i18n.services.formatter?.add('isQuestion', (value, _, { isQuestion }) =>
  isQuestion ? `${value}?` : value
);

i18n.services.formatter?.add('personPlural', (value) =>
  value === 1 ? `${value} person` : `${value} people`
);

i18n.services.formatter?.add('inGroup', (value) =>
  value ? `in ${value} group` : ''
);

export default i18n;
