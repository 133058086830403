import { Role } from 'types';

const ROLES_WITHOUT_PROFILE = [
  Role.Admin,
  Role.ItriomCurator,
  Role.SystemAdmin,
];

export const isItriomStaff = (role?: Role) =>
  role &&
  [Role.ItriomStaff, Role.Admin, Role.ItriomCurator, Role.SystemAdmin].includes(
    role
  );

export const hasRoleWithProfile = (role?: Role) =>
  role && !ROLES_WITHOUT_PROFILE.includes(role);

export const hasSdg = (role?: Role) =>
  role &&
  [
    Role.HeadOfFamily,
    Role.FamilyMember,
    Role.FamilyAdmin,
    Role.ExpertAdvisor,
  ].includes(role);
