import { EventKeySpeakerOutput } from 'types';

export const isKeynoteSpeakerBlockVisible = (
  keySpeaker: EventKeySpeakerOutput | undefined
) => {
  if (!keySpeaker) {
    return null;
  }

  const values = Object.values(keySpeaker);

  return values.every((val) => !!val);
};
