import { COLORS } from 'constants/ValuesConfigurator';
import { ValueConfiguratorNormalizedOutput } from 'types';
import { isValueName, ValueCategoryName } from 'types/ValuesConfigurator';
import { stripTypenames } from 'utils/apollo';

import { CategoryChartData } from './CategoryChart';

const mapCategoryData = (
  category: Record<string, number>
): CategoryChartData[] =>
  Object.entries(category).map(([name, value], index) => {
    if (!isValueName(name)) {
      throw new Error(`Wrong value name: ${name}`);
    }

    return {
      name,
      value,
      index: index + 1,
      color: COLORS[index] || '#fff',
    };
  });

const parseCategoryName = (categoryName: string): ValueCategoryName =>
  categoryName.replace('Values', '').toLowerCase() as ValueCategoryName;

export const mapCategoriesData = (
  values: ValueConfiguratorNormalizedOutput
) => {
  const { __typename, ...categories } = values;

  const preparedData = Object.entries(
    categories as Required<ValueConfiguratorNormalizedOutput>
  ).map(([categoryName, categoryValues]) => {
    const categoryDataWithoutTypename = stripTypenames(categoryValues);
    const mappedData = mapCategoryData(categoryDataWithoutTypename);

    const parsedCategoryName = parseCategoryName(categoryName);

    return [parsedCategoryName, mappedData] as const;
  });

  return Object.fromEntries(preparedData) as Record<
    ValueCategoryName,
    CategoryChartData[]
  >;
};
