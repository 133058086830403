import {
  ApolloError,
  ApolloQueryResult,
  OperationVariables,
} from '@apollo/client';

import {
  LibraryMediaOutput,
  LibrarySharedMediaOutput,
  Query,
  QueryGetResourcesFromDirectoryInLibraryArgs,
  QueryGetTrashesFromLibraryArgs,
} from 'types';

import { LibraryLocation } from '../types';
import { useCurrentLibraryLocation } from './useCurrentLibraryLocation';

interface ResourceGqlResponse<Q, T> {
  resources: LibraryMediaOutput[] | LibrarySharedMediaOutput[] | undefined;
  error: ApolloError | undefined;
  loading: boolean;
  refetch: (variables?: Partial<Q>) => Promise<ApolloQueryResult<T>>;
}

interface CurrentResources {
  directory: ResourceGqlResponse<
    QueryGetResourcesFromDirectoryInLibraryArgs,
    Pick<Query, 'getResourcesFromDirectoryInLibrary'>
  >;
  trash: ResourceGqlResponse<
    QueryGetTrashesFromLibraryArgs,
    Pick<Query, 'getTrashesFromLibrary'>
  >;
  favourites: ResourceGqlResponse<
    OperationVariables,
    Pick<Query, 'getLibraryMediaFavorites'>
  >;
  shared: ResourceGqlResponse<
    OperationVariables,
    Pick<Query, 'getSharedResourcesFromLibrary'>
  >;
}

export const useCurrentResources = ({
  directory,
  trash,
  favourites,
  shared,
}: CurrentResources) => {
  const { libraryLocation } = useCurrentLibraryLocation();

  switch (libraryLocation) {
    case LibraryLocation.RecentlyDeleted:
      return trash;
    case LibraryLocation.Favourites:
      return favourites;
    case LibraryLocation.SharedWithMe:
      return shared;
    default:
      return directory;
  }
};
