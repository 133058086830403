import { Flex, Heading, Image, VStack } from '@chakra-ui/react';

import { LinkPreview } from 'components/LinkPreview';
import { MarkdownPreview } from 'components/MarkdownPreview';
import { MediaOutput } from 'types';
import { extractUrls } from 'utils/string';

type Props = {
  text: string;
  images: MediaOutput[];
  title?: string;
};

export const PostPreview = ({ images, text, title }: Props) => {
  const urls = extractUrls(text);

  return (
    <Flex direction="column" w="full" gap={4}>
      <Flex direction="column" gap={2}>
        {title && <Heading size="h5">{title}</Heading>}

        <MarkdownPreview content={text} />
        {urls ? <LinkPreview url={urls[0]} /> : null}
      </Flex>

      <VStack>
        {images.map(({ signedUrl }) => (
          <Image src={signedUrl} w="100%" key={signedUrl} />
        ))}
      </VStack>
    </Flex>
  );
};
