import { Flex } from '@chakra-ui/layout';
import { Box, Heading, Text } from '@chakra-ui/react';

import { ValueCategoryName } from 'types/ValuesConfigurator';
import { capitalize } from 'utils/string';

import { LIGHT_BLUE_5, Question, STEEL_BLUE_2, Variant } from './constants';

type Props = {
  label: ValueCategoryName;
  question: Question;
  variant: Variant;
  isActive: boolean;
};

export const Bubble = ({ label, question, variant, isActive }: Props) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      h={32}
      w={32}
      borderRadius="50%"
      direction="column"
      position="relative"
      bgColor={isActive ? STEEL_BLUE_2 : LIGHT_BLUE_5}
    >
      <Heading color="ui.white" pb={4} size="h6">
        {capitalize(label.valueOf())}
      </Heading>
      <Box position="absolute" bottom={0}>
        <Text size="p3b" color={variant} pb={2}>
          {question.toUpperCase()}
        </Text>
      </Box>
    </Flex>
  );
};
