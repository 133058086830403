import { useTranslation } from 'react-i18next';

import { Avatar } from 'components/Avatar';
import { Panel } from 'components/Layout/Panel';
import { Profile } from 'types';

type Props = {
  members: Profile[];
};

export const GroupMembers = ({ members }: Props) => {
  const { t } = useTranslation(['groups']);

  return (
    <Panel
      title={t('groups:group-members', { count: members.length })}
      contentWrapperStyling={{
        direction: 'column',
        overflowY: 'scroll',
        gap: 2,
      }}
      containerStyling={{ maxH: '340px', h: 'unset' }}
    >
      {members.map((member: Profile) => (
        <Avatar profile={member} key={member.id} variant="author" />
      ))}
    </Panel>
  );
};
