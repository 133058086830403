import {
  useConversationsActions,
  useConversationsSelectors,
} from './MessagingStateManager/hooks';
import { Conversations } from './components/Conversations/Conversations';
import { Messages } from './components/Messages';
import { MessagesLayout } from './components/MessagesLayout';
import { NewConversation } from './components/NewConversation';
import { NewConversationActionButton } from './components/NewConversationActionButton';
import { useSendMessage } from './hooks/useSendMessage';

export const MessagingCentre = () => {
  const {
    selectConversations,
    selectActiveConversation,
    selectInterlocutorsForNewConversation,
  } = useConversationsSelectors();

  const { handleConversationSelect, handleInterlocutorTrigger } =
    useConversationsActions();

  const sendMessage = useSendMessage();

  const conversations = selectConversations();

  const activeConversation = selectActiveConversation();

  const interlocutors = selectInterlocutorsForNewConversation();

  const onNewConversation = () => handleConversationSelect(undefined);

  const onCloseNewConversation = () => {
    handleConversationSelect(conversations[0]?.id || undefined);
  };

  return (
    <MessagesLayout
      action={
        <NewConversationActionButton
          handleNewConversation={onNewConversation}
        />
      }
      sideContent={<Conversations />}
      mainContent={
        interlocutors.length || !activeConversation ? (
          <NewConversation
            interlocutors={interlocutors}
            onInterlocutorTrigger={handleInterlocutorTrigger}
            handleCloseNewConversation={onCloseNewConversation}
          />
        ) : (
          !!conversations.length && (
            <Messages
              activeConversation={activeConversation}
              handleSendMessage={(text: string) =>
                sendMessage({ text, conversationId: activeConversation.id })
              }
            />
          )
        )
      }
    />
  );
};
