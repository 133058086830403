import { gql, useQuery } from '@apollo/client';

import { Query } from 'types';
import { AdvisorOutputDeepNestingFragment } from 'types/generated-fragments';

const listAdvisorGql = gql`
  ${AdvisorOutputDeepNestingFragment}
  query ListAdvisors($pagination: PaginationInput = { offset: 0, limit: 100 }) {
    listAdvisors(pagination: $pagination) {
      nodes {
        ...AdvisorOutputDeepNestingFragment
      }
      offset
      totalCount
    }
  }
`;

export const useListAdvisors = () => {
  const { data, error, loading } =
    useQuery<Pick<Query, 'listAdvisors'>>(listAdvisorGql);

  const advisors = data?.listAdvisors.nodes || [];

  return { advisors, error, loading };
};
