import { useCallback } from 'react';

import { NotificationCategoryEnum, NotificationUnion } from 'types';

import { useNotificationsContext } from '../NotificationsProviders/NotificationsContext';

export const useSubscriptionNotificationHandler = () => {
  const { addNotification: addConnectionNotification } =
    useNotificationsContext(NotificationCategoryEnum.Connection);

  const { addNotification: addMessageNotification } = useNotificationsContext(
    NotificationCategoryEnum.Message
  );

  const { addNotification: addOtherNotification } = useNotificationsContext(
    NotificationCategoryEnum.Other
  );

  const subscriptionNotificationHandler = useCallback(
    (payload?: NotificationUnion) => {
      if (!payload) {
        return;
      }

      if (payload.category === NotificationCategoryEnum.Connection) {
        addConnectionNotification(payload);
      }

      if (payload.category === NotificationCategoryEnum.Message) {
        addMessageNotification(payload);
      }

      if (payload.category === NotificationCategoryEnum.Other) {
        addOtherNotification(payload);
      }
    },
    [addConnectionNotification, addMessageNotification, addOtherNotification]
  );

  return { subscriptionNotificationHandler };
};
