import { useFormik } from 'formik';

export const useEntityNameSelectFormik = (entityIdFieldName: string) => {
  const formik = useFormik({
    initialValues: { [entityIdFieldName]: '' },
    onSubmit: () => Promise.resolve(),
  });

  return formik;
};
