import { VStack, Flex, Heading } from '@chakra-ui/react';

import { Links } from 'router/links';

import { SectionHeading } from '../components/SectionHeader';
import { ValuesLayout } from '../components/ValuesConfigurator';

export const WelcomePage = () => {
  return (
    <ValuesLayout
      showHeader
      renderBackground
      leftButton={{
        link: Links.valuesConfiguratorHowToUse,
        color: 'ui.white',
        text: 'Launch the configurator',
      }}
      rightButton={{ link: Links.valuesConfiguratorAbout }}
    >
      <Flex alignItems="flex-start" w="full" direction="column" h="full">
        <VStack
          alignItems="flex-start"
          flex={1}
          justifyContent="center"
          maxW={600}
        >
          <SectionHeading
            heading="Welcome to the Values Configurator"
            containerStyling={{ mr: 0 }}
          />

          <Heading size="h4">
            To identify and prioritise your family’s specific set of values you
            will now be guided through our online process.
          </Heading>
          <Heading size="h4">
            Once determined these values will act as central organising
            principles guiding action, initiatives and partnerships aimed at
            creating positive impact and a lasting legacy.
          </Heading>
        </VStack>
      </Flex>
    </ValuesLayout>
  );
};
