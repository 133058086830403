import { useTranslation } from 'react-i18next';

import { Box, Divider, Flex, Heading, VStack } from '@chakra-ui/react';

import { ConnectionProfile } from 'components/ProfileCard/ConnectionProfile';
import { QueryStateGuard } from 'components/QueryStateGuard';
import { useRecentConnections } from 'gql/connections/query/recentConnections';

const RECENT_CONNECTIONS_COUNT = 3;

export const RecentlyConnected = () => {
  const { t } = useTranslation(['network']);

  const { recentConnections, loading, error } = useRecentConnections();

  return (
    <Flex direction="column" w="full">
      <Heading size="h5">{t('network:connections.recently-connected')}</Heading>

      <Divider mt={4} mb={2} />

      <QueryStateGuard loading={loading} error={error}>
        <VStack divider={<Divider w="full" />} w="full">
          {recentConnections.length ? (
            recentConnections
              .slice(0, RECENT_CONNECTIONS_COUNT)
              .map((profile) => (
                <Box key={profile.id} w="full">
                  <ConnectionProfile profile={profile} />
                </Box>
              ))
          ) : (
            <Flex bgColor="ui.white" p={2} flex={1}>
              {t('network:connections.empty-state')}
            </Flex>
          )}
        </VStack>
      </QueryStateGuard>

      <Divider mt={2} />
    </Flex>
  );
};
