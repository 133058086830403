import { InitiativeBodyType, InsightBlockType, InsightBlockUnion } from 'types';
import { InitiativeBlockUnion } from 'types/Initiatives';
import { getFullName } from 'utils/string';

import { ArticleFullWidthMedia } from './ArticleFullWidthMedia';
import { ArticleGallery } from './ArticleGallery';
import { ArticleHeading } from './ArticleHeading';
import { ArticleQuote } from './ArticleQuote';
import { ArticleRichText } from './ArticleRichText';
import { ArticleSideImage } from './ArticleSideImage';

interface Props {
  body: InsightBlockUnion | InitiativeBlockUnion;
}

export const ArticleBody = ({ body }: Props) => {
  if (!body) {
    return null;
  }

  switch (body.type) {
    case InsightBlockType.FullMedia:
    case InitiativeBodyType.FullMedia:
      return <ArticleFullWidthMedia signedUrl={body.image?.signedUrl} />;

    case InsightBlockType.Heading:
    case InitiativeBodyType.Heading:
      return <ArticleHeading heading={body.heading} />;

    case InsightBlockType.LeftText:
    case InsightBlockType.RightText:
    case InitiativeBodyType.LeftText:
    case InitiativeBodyType.RightText:
      return (
        <ArticleSideImage
          image={body.image}
          content={body.text}
          heading={body.optionalHeading}
          imagePosition={
            body.type === InsightBlockType.LeftText ? 'right' : 'left'
          }
        />
      );

    case InsightBlockType.RichText:
    case InitiativeBodyType.RichText:
      return (
        <ArticleRichText heading={body.optionalHeading} richText={body.text} />
      );

    case InsightBlockType.Quote:
      return (
        <ArticleQuote
          quote={body.text}
          author={{
            name: body.account
              ? getFullName(body.account.firstName, body.account.lastName)
              : body.customName || '',
            title: body.account ? body.account.title : body.customTitle,
          }}
        />
      );

    case InitiativeBodyType.Gallery:
    case InsightBlockType.Gallery:
      return <ArticleGallery images={body.images} />;

    default:
      return null;
  }
};
