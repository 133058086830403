import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchIcon } from '@chakra-ui/icons';
import {
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
} from '@chakra-ui/react';

type Props = {
  loading: boolean;
  onQueryChange: (query: string) => void;
  placeholder: string;
};

export const ClearableSearchInput = ({
  loading,
  onQueryChange,
  placeholder,
}: Props) => {
  const [searchQuery, setSearchQuery] = useState('');
  const { t } = useTranslation(['common']);

  const handleQueryChange = (query: string) => {
    onQueryChange(query);
    setSearchQuery(query);
  };

  return (
    <InputGroup mb={2}>
      <InputLeftElement pointerEvents="none">
        {loading ? (
          <Spinner color="ui.grey2" />
        ) : (
          <SearchIcon color="ui.grey2" />
        )}
      </InputLeftElement>
      <Input
        type="text"
        placeholder={placeholder}
        value={searchQuery}
        onChange={(e) => {
          handleQueryChange(e.target.value);
        }}
      />
      <InputRightElement>
        <Button
          variant="ghost"
          mr={2}
          onClick={() => {
            handleQueryChange('');
          }}
        >
          {t('common:clear')}
        </Button>
      </InputRightElement>
    </InputGroup>
  );
};
