import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';

import { useSearch } from 'gql/search/query/search';
import { SearchLibraryResultOutput, SearchResult } from 'types';

import { makeContext } from './MakeContext';

const MAX_SEARCH_CHARACTERS = 200;
const MIN_SEARCH_CHARACTERS = 3;

export const [useSearchContext, SearchProvider] = makeContext(() => {
  const [isSearchInputOpen, setIsSearchInputOpen] = useState(false);
  // this will need a slight refactor, but awaiting UX decision on differences between
  // navbar and page page search to be clarified
  const [previewSearchTerm, setPreviewSearchTerm] = useState('');
  const [fixedSearchResults, setFixedSearchResults] =
    useState<SearchLibraryResultOutput[]>();
  const [fixedLibraryItemsCount, setFixedLibraryItemsCount] =
    useState<number>();
  const [pageSearchTerm, setPageSearchTerm] = useState('');
  const [isSearchPreviewOpen, setIsSearchPreviewOpen] = useState(false);
  const {
    searchResults: dbSearch,
    loading,
    handleSearch,
    refetch,
  } = useSearch();

  const searchResults: SearchResult = {
    connections: dbSearch?.connections,
    events: dbSearch?.events,
    initiatives: dbSearch?.initiatives,
    insights: dbSearch?.insights,
    library: dbSearch?.library,
  };

  useEffect(() => {
    handleSearch({ phrase: pageSearchTerm });
  }, [pageSearchTerm, handleSearch]);

  useEffect(() => {
    if (previewSearchTerm.length >= MIN_SEARCH_CHARACTERS) {
      setIsSearchPreviewOpen(true);
    } else {
      setIsSearchPreviewOpen(false);
    }
  }, [previewSearchTerm]);

  const clearSearchTerm = () => {
    setPreviewSearchTerm('');
    setPageSearchTerm('');
  };

  const toggleSearchInput = () => {
    if (isSearchInputOpen) {
      clearSearchTerm();
    }

    setIsSearchInputOpen((prev) => !prev);
  };

  const closeSearchPreview = () => {
    setIsSearchPreviewOpen(false);
  };

  const closeSearchPreviewOnClickOutside = () => {
    setIsSearchPreviewOpen(false);
    setIsSearchInputOpen(false);
  };

  const handleRefetchResults = ({
    event,
    isTopRightSearchBox,
  }: {
    event: KeyboardEvent<HTMLInputElement>;
    isTopRightSearchBox: boolean;
  }) => {
    if (event.key === 'Enter') {
      refetch();

      if (isTopRightSearchBox) {
        setIsSearchPreviewOpen(true);
      }
    }
  };

  const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (value.length > MAX_SEARCH_CHARACTERS) {
      return;
    }

    setPageSearchTerm(value);
    setPreviewSearchTerm(value);
  };

  const totalResults =
    (searchResults.connections?.totalCount || 0) +
    (searchResults.events?.totalCount || 0) +
    (searchResults.initiatives?.totalCount || 0) +
    (searchResults.insights?.totalCount || 0) +
    (searchResults.library?.totalCount || 0);
  const hasResults = !!totalResults;

  return {
    isSearchPreviewOpen,
    isSearchInputOpen,
    searchTerm: previewSearchTerm,
    pageSearchTerm,
    setPageSearchTerm,
    handleSearchInputChange,
    toggleSearchInput,
    loading,
    clearSearchTerm,
    closeSearchPreview,
    hasResults,
    totalResults,
    searchResults,
    fixedSearchResults,
    setFixedSearchResults,
    fixedLibraryItemsCount,
    setFixedLibraryItemsCount,
    refetch,
    handleRefetchResults,
    closeSearchPreviewOnClickOutside,
  };
});
