import { gql, useQuery } from '@apollo/client';

import { Query, QueryExtendedProfilesArgs } from 'types';
import { ExtendedProfileOutputFragment } from 'types/generated-fragments';

export const extendedProfilesGql = gql`
  ${ExtendedProfileOutputFragment}
  query ExtendedProfiles($familyId: String!) {
    extendedProfiles(familyId: $familyId) {
      ...ExtendedProfileOutputFragment
    }
  }
`;

export const useExtendedProfiles = (familyId: string | undefined) => {
  const { data, loading, error } = useQuery<
    Pick<Query, 'extendedProfiles'>,
    Partial<QueryExtendedProfilesArgs>
  >(extendedProfilesGql, {
    variables: { familyId },
    skip: !familyId,
    fetchPolicy: 'no-cache',
  });

  const extendedProfiles = data?.extendedProfiles;

  return { extendedProfiles, loading, error };
};
