import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { gql, useMutation } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Links } from 'router/links';
import { Mutation } from 'types';

import { groupGql } from './useGroup';
import { groupsGql } from './useGroups';
import { randomRecommendedGroupsGql } from './useRandomRecommendedGroups';
import { trendingGroupsGql } from './useTrendingGroups';
import { yourGroupsGql } from './useYourGroups';
import { yourMostActiveGroupsGql } from './useYourMostActiveGroups';

const deleteGroupGql = gql`
  mutation DeleteGroup($groupId: String!, $userValidationInput: String!) {
    deleteGroup(id: $groupId, userValidationInput: $userValidationInput)
  }
`;

export const useDeleteGroup = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation(['cms', 'common']);
  const [mutation] = useMutation<Pick<Mutation, 'deleteGroup'>>(
    deleteGroupGql,
    {
      refetchQueries: [
        groupGql,
        groupsGql,
        yourGroupsGql,
        yourMostActiveGroupsGql,
        trendingGroupsGql,
        randomRecommendedGroupsGql,
      ],
    }
  );

  const handleDeleteGroup = (groupId: string, userValidationInput: string) =>
    mutation({ variables: { groupId, userValidationInput } });

  const handleDeleteGroupWithToast = useWithMutationToast(handleDeleteGroup, {
    successMessage: t('cms:delete.fulfilled-message', {
      entity: t('common:group'),
    }),
    errorMessage: t('cms:delete.failed-message', { entity: t('common:group') }),
  });

  const handleDeleteGroupAndNavigate = async (
    groupId: string,
    userValidationInput: string
  ) => {
    await handleDeleteGroupWithToast(groupId, userValidationInput);

    // If we are on the group page, navigate to the groups page
    if (id) {
      navigate(Links.groups);
    }
  };

  return { handleDeleteGroup: handleDeleteGroupAndNavigate };
};
