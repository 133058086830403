import { Box, Progress, Text } from '@chakra-ui/react';

interface Props {
  value: number;
  max?: number;
  bottomText?: string;
}

export const ProgressBar = ({ value, bottomText, max }: Props) => {
  return (
    <Box>
      <Progress
        max={max}
        value={value}
        borderRadius="60px"
        h={2}
        variant="amber"
      />
      {bottomText && <Text fontSize={12}>{bottomText}</Text>}
    </Box>
  );
};
