import { Box, Flex, Grid, Heading, Text } from '@chakra-ui/react';

import { BarChart } from 'components/Charts';
import { ValueCategoryType } from 'types/ValuesConfigurator';
import { capitalize } from 'utils/string';

import { CategoryValueData } from './types';

type Props = {
  categoryName: string;
  type: ValueCategoryType;
  values: Record<string, CategoryValueData>;
};

const xGetter = ({ value }: CategoryValueData) => value;
const yGetter = ({ value }: CategoryValueData) => value;
const colorGetter = ({ color }: CategoryValueData) => color;

export const CategorySummary = ({ categoryName, type, values }: Props) => {
  const data = Object.entries(values);

  const backgroundColor =
    type === ValueCategoryType.Importance
      ? 'primary.blue.default'
      : 'darkBlue2';

  const barBackgroundColor =
    type === ValueCategoryType.Importance
      ? 'darkBlue2'
      : 'primary.blue.default';

  return (
    <Grid
      background={backgroundColor}
      color="ui.white"
      borderRadius={10}
      padding={4}
      shadow="8px 8px 11px 0px #00000059"
      my={4}
      maxW={760}
      w="auto"
      gridTemplateColumns="1fr 3fr"
    >
      <Flex direction="column" justifyContent="flex-end" mr={2}>
        <Heading size="h4">{categoryName}</Heading>
        <Heading size="h6" textTransform="uppercase">
          {type}
        </Heading>
      </Flex>
      <Flex gap={4}>
        {data.length === 0 ? (
          <Flex align="center" flex={1} justify="center">
            Not done yet
          </Flex>
        ) : (
          data.map(([valueName, valueData]) => (
            <Flex key={valueName} direction="column">
              <Box w="30%" mb={2}>
                <BarChart
                  data={[valueData]}
                  xGetter={xGetter}
                  yGetter={yGetter}
                  colorGetter={colorGetter}
                  dimensions={{
                    height: 360,
                    width: 120,
                    margin: { top: 0, left: 0, right: 0, bottom: 0 },
                  }}
                  config={{
                    xAxis: {
                      visible: false,
                    },
                    yAxis: {
                      visible: false,
                      domain: [0, 100],
                    },
                    barBackground: barBackgroundColor,
                    barWidthModifier: 1.2,
                  }}
                />
              </Box>

              <Text size="p2">{capitalize(valueName)}</Text>
              <Heading size="h6">{valueData.value}</Heading>
            </Flex>
          ))
        )}
      </Flex>
    </Grid>
  );
};
