import { ComponentStyleConfig } from '@chakra-ui/react';

export const sizes = {
  h1: {
    fontSize: '52px',
    lineHeight: '52px',
    fontFamily: 'Publico Headline Medium',
    fontWeight: 500,
  },
  h2: {
    // 12
    fontSize: '18px',
    lineHeight: '26px',
    fontFamily: 'Roboto',
    fontWeight: 400,
  },
  h3: {
    // 7
    fontSize: '32px',
    lineHeight: '38px',
    fontWeight: 400,
    fontFamily: 'Publico Headline Roman',
  },
  h4: {
    // 1
    fontSize: '24px',
    lineHeight: '30px',
    fontWeight: 400,
    fontFamily: 'Publico Headline Roman',
  },
  h5: {
    // 0
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: 400,
    fontFamily: 'Publico Headline Roman',
  },
  h6: {
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 400,
    fontFamily: 'Publico Headline Roman',
  },
};

export const Heading: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: 'normal',
    letterSpacing: '0.16px',

    _focus: {
      outline: 'none',
    },
  },
  sizes,
};
