import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Flex, Text } from '@chakra-ui/react';

import { ButtonsGroup } from 'components/Button/ButtonsGroup';
import { Links } from 'router/links';

import { SdgIntroLayout } from '../../components/Layout';
import { InteractiveCard } from '../../components/SdgConfigurator';
import { SectionHeading } from '../../components/SectionHeader';

const SubHeading = () => (
  <>
    <Text fontSize="lg" mb={8}>
      SDGs provide a tangible framework for creating positive impact and present
      a significant invesment opportunity.
    </Text>
    <Text fontSize="lg">
      This provides a tangible invesment proposition for you and your family.
    </Text>
  </>
);

enum Card {
  Impact,
  Opportunity,
}

interface Props {
  onBackButtonClicked?: VoidFunction;
  onNextButtonClicked?: VoidFunction;
}

export const WhyAlignToSdgsPage = ({
  onBackButtonClicked,
  onNextButtonClicked,
}: Props) => {
  const [openedCard, setOpenedCard] = useState<Card | null>(null);
  const { t } = useTranslation(['common']);

  const handleToggle = (card: Card) =>
    setOpenedCard((prevCard) => (prevCard === card ? null : card));

  return (
    <SdgIntroLayout background="base2" color="primary.blue.default">
      <Flex
        mx={{ lg: 32, base: 20 }}
        mt={{ lg: 16, base: 20 }}
        direction="column"
      >
        <SectionHeading
          heading="Why align to the Sustainable Development Goals?"
          subheading={<SubHeading />}
          color="primary.blue.default"
          size="h3"
          containerStyling={{
            w: { lg: '50%', base: 'full' },
          }}
        />
        <Flex gap={8} direction={{ base: 'column', lg: 'row' }}>
          <InteractiveCard
            isActive={openedCard === Card.Impact}
            toggle={() => handleToggle(Card.Impact)}
            title="Positive impact:"
            description="SDGs provide a blueprint for tackling the world's most pressing problems, including deprivation and climate change.  Focussing on SDGs therefore maximises your opportunities and ability to meaningfully contribute to solving these global challenges."
            image="/assets/sdg_why_align_forest.JPG"
          />
          <InteractiveCard
            isActive={openedCard === Card.Opportunity}
            toggle={() => handleToggle(Card.Opportunity)}
            title="Investment Opportunity:"
            description="The United Nations (UN) estimates that an additional US$5-$7 trillion annual investment is required to achieve the SDGs by their 2030 target date, which would in turn realise $12 trillion in market opportunities and create 380 million jobs. This provides a tangible investment proposition for you and your family."
            image="/assets/sdg_why_align_city.jpg"
          />
        </Flex>
      </Flex>
      <Box mt="auto" px={16} py={12}>
        <ButtonsGroup
          leftButton={{
            text: t('common:back'),
            color: 'primary.blue.default',
            ...(onBackButtonClicked
              ? { onClick: onBackButtonClicked }
              : { link: Links.sdgConfiguratorWhatAre }),
          }}
          rightButton={{
            text: t('common:next'),
            ...(onNextButtonClicked
              ? { onClick: onNextButtonClicked }
              : { link: Links.sdgConfigurator }),
          }}
        />
      </Box>
    </SdgIntroLayout>
  );
};
