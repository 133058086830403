import { useCallback } from 'react';

import { useAuthContext } from 'contexts/AuthContext';
import { Message } from 'types';

import {
  useConversationsActions,
  useMessagesActions,
  useMessagesSelectors,
} from '../MessagingStateManager/hooks';
import { isDeletedMessage } from '../utils';
import { useLoadConversations } from './useLoadConversations';

/** Returns handler for messaging subscriptions */
export const useOnSubscribtionMessageHandler = () => {
  const { account } = useAuthContext();

  const loadConversations = useLoadConversations();

  const { selectIsMessageInConversations } = useMessagesSelectors();
  const { addToUnread } = useConversationsActions();

  const { addMessage } = useMessagesActions();

  const handleNewMessage = useCallback(
    (payload: Message | undefined) => {
      if (!payload) return;

      if (isDeletedMessage(payload)) {
        addMessage(payload);

        return;
      }

      const isMessageInConversations = selectIsMessageInConversations(payload);

      if (!isMessageInConversations) {
        loadConversations();
        addToUnread(payload.conversationId);

        return;
      }

      addMessage(payload);

      const isAuthor = payload.createdBy === account?.id;

      if (!isAuthor) {
        addToUnread(payload.conversationId);
      }
    },
    [
      account?.id,
      addMessage,
      addToUnread,
      loadConversations,
      selectIsMessageInConversations,
    ]
  );

  return handleNewMessage;
};
