import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { VStack, Text } from '@chakra-ui/react';

import { Section } from 'components/Layout/Sections/Section';
import { FamilyCharterGoalOutput } from 'types';

import { GoalSection } from './GoalSection';

type Props = {
  goals: FamilyCharterGoalOutput[];
};

export const GoalsSection = ({ goals }: Props) => {
  const { t } = useTranslation(['family']);

  const [openedGoalsIds, setOpenedGoalsIds] = useState<string[]>([]);

  const toggleGoal = (id: string) => {
    setOpenedGoalsIds((prevState) =>
      prevState.includes(id)
        ? prevState.filter((goalId) => goalId !== id)
        : [...prevState, id]
    );
  };

  return (
    <Section
      title={t('family:charter.section.goals.title')}
      subtitleContent={
        <Text size="p2">{t('family:charter.section.goals.description')}</Text>
      }
    >
      <VStack spacing={6} mb={6} width="100%">
        {goals.map((goal) => (
          <GoalSection
            key={goal.id}
            goal={goal}
            toggleOpen={() => toggleGoal(goal.id)}
            isOpen={openedGoalsIds.includes(goal.id)}
          />
        ))}
      </VStack>
    </Section>
  );
};
