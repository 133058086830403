import {
  FamilyOnboardStateEnum,
  FamilyValueOutput,
  FamilyValuesOutput,
} from 'types';

import { stripTypenames } from './apollo';

export const flattenValuesOutput = (
  values: FamilyValuesOutput
): FamilyValueOutput[] => {
  const valuesWithoutTypename = stripTypenames(values);

  const valuesList = Object.values(valuesWithoutTypename);

  return valuesList.filter(Boolean) as FamilyValueOutput[];
};

export const isFamilyOnboardCompleted = (
  state: FamilyOnboardStateEnum | undefined,
  hasDone: FamilyOnboardStateEnum
) => {
  const scoreImportance = {
    [FamilyOnboardStateEnum.OnboardCompleted]: 4,
    [FamilyOnboardStateEnum.SdgConfiguratorCompleted]: 3,
    [FamilyOnboardStateEnum.ValueConfiguratorCompleted]: 2,
    [FamilyOnboardStateEnum.Initialized]: 1,
  };

  if (!state) return false;

  return scoreImportance[hasDone] <= scoreImportance[state];
};
