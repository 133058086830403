import { StackProps } from '@chakra-ui/layout';
import { Image, VStack } from '@chakra-ui/react';

import { FileWithPathAndPreview } from 'components/Form/Upload/types';
import { MediaOutput } from 'types';
import { isMediaOutput } from 'utils/file';

type OwnProps = {
  images?: (FileWithPathAndPreview | MediaOutput)[];
};

type Props = OwnProps & StackProps;

export const ImagesPreview = ({ images, ...stackProps }: Props) => {
  if (!images || !images.length) return null;

  return (
    <VStack spacing={2} {...stackProps}>
      {images.map((image, index) => {
        const imageIsMediaOutput = isMediaOutput(image);

        const id = imageIsMediaOutput ? image.id : image.path || index;
        const signedUrl = imageIsMediaOutput ? image.signedUrl : image.preview;

        return <Image src={signedUrl} mt={2} key={id} />;
      })}
    </VStack>
  );
};
