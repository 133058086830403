import { Box } from '@chakra-ui/react';

import { BarChart } from 'components/Charts/BarChart';
import { capitalize } from 'utils/string';

import { CategoryChartData } from './types';

const xGetter = (d: CategoryChartData): number => d.index;
const yGetter = (d: CategoryChartData): number => d.value;
const colorGetter = (d: CategoryChartData): string => d.color;
const labelGetter = (d: CategoryChartData): string => d.name;

type Props = {
  data: CategoryChartData[];
  categoryName: string;
  highlightElement: CategoryChartData;
};

export const CategoryChart = ({
  data,
  categoryName,
  highlightElement,
}: Props) => {
  return (
    <Box background="darkBlue2" borderRadius={10} color="ui.white" padding={2}>
      <BarChart
        data={data}
        xGetter={xGetter}
        yGetter={yGetter}
        colorGetter={colorGetter}
        labelGetter={labelGetter}
        highlightElement={highlightElement}
        dimensions={{
          width: 400,
          height: 400,
          margin: {
            top: 10,
            bottom: 60,
            left: 50,
            right: 26,
          },
          padding: {
            top: 100,
            left: 10,
            right: 10,
            bottom: 20,
          },
        }}
        config={{
          title: { text: categoryName, fontSize: 24, horizontal: 'start' },
          barWidthModifier: 0.5,
          barBackground: '#0A1E40',
          legend: {
            horizontal: 'end',
            vertical: 'start',
            color: '#fff',
            fontSize: 16,
            format: capitalize,
          },
          yAxis: {
            format: (d) => `${d}`,
            ticksCount: 5,
            hasTicks: false,
            axisStroke: '#9DB6C5',
            tickPadding: 25,
            fontSize: 16,
          },
          xAxis: {
            hasTicks: false,
            axisStroke: '#9DB6C5',
            tickPadding: 25,
            fontSize: 20,
          },
        }}
      />
    </Box>
  );
};
