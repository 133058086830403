import {
  Form,
  Field,
  FieldProps,
  getIn,
  FormikProvider,
  useFormik,
} from 'formik';
import { string, object } from 'yup';

import { useMutation, gql } from '@apollo/client';
import { Box, Flex, VStack } from '@chakra-ui/layout';
import {
  Input,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react';

import { useAuthContext } from 'contexts/AuthContext';
import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation } from 'types';

import { useUpdatePassword } from './useUpdatePassword';

// @TODO temporary disable update of these until BE is ready
const DISABLE_EMAIL_UPDATE = true;
const DISABLE_PASSWORD_UPDATE = false;

// TODO fix or refactor leip-726

export const updateEmailGql = gql`
  mutation UpdateEmail($updateEmailEmail2: String!) {
    updateEmail(email: $updateEmailEmail2) {
      email
    }
  }
`;

const ChangePasswordSchema = object().shape({
  oldPassword: string()
    .required('No password provided.')
    .min(8, 'Password is too short - should be 8 chars minimum.'),
  newPassword: string()
    .required('No password provided.')
    .min(8, 'Password is too short - should be 8 chars minimum.'),
  confirmNewPassword: string().test(
    'passwords-match',
    'Passwords must match',
    function test(value) {
      return this.parent.newPassword === value;
    }
  ),
});

type UpdatePasswordType = {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

const initialValues: UpdatePasswordType = {
  oldPassword: '',
  newPassword: '',
  confirmNewPassword: '',
};

export const SignInDetails = (): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { account } = useAuthContext();

  const { updatePassword } = useUpdatePassword({
    onCompleted: () => {
      onClose();
    },
  });

  const [updateEmail] =
    // @ts-expect-error TODO refactor this 'updateEmail' not exists in Mutation
    useMutation<Pick<Mutation, 'updateEmail'>>(updateEmailGql);

  const handleSubmit = (values: UpdatePasswordType) =>
    updatePassword({
      data: {
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      },
    });

  const saveEmail = () =>
    updateEmail({
      variables: {
        updateEmailEmail2: '',
      },
    });

  const saveEmailWithToast = useWithMutationToast(saveEmail, {
    successMessage: 'Your email has been updated.',
    errorMessage: 'Error updating email',
  });

  const formik = useFormik({
    initialValues,
    validationSchema: ChangePasswordSchema,
    onSubmit: handleSubmit,
  });

  const { isSubmitting } = formik;

  return (
    <Box minW="500px">
      <VStack align="flex-start" spacing="30px">
        {account?.email}
        <Input placeholder="Email Address" value={account?.email} isReadOnly />
        <Button w="300px" onClick={onOpen} disabled={DISABLE_PASSWORD_UPDATE}>
          Change Password
        </Button>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <FormikProvider value={formik}>
            <Form>
              <ModalContent>
                <ModalHeader>Change Password</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Field type="password" name="oldPassword">
                    {({ field, form }: FieldProps) => (
                      <FormControl
                        isInvalid={
                          !!(
                            form.errors.oldPassword && form.touched.oldPassword
                          )
                        }
                      >
                        <FormLabel htmlFor="oldPassword">
                          Enter your current password
                        </FormLabel>
                        <Input
                          {...field}
                          id="oldPassword"
                          placeholder="Current password"
                          type="password"
                        />
                        <FormErrorMessage>
                          {getIn(form.errors, 'oldPassword')}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field type="password" name="newPassword">
                    {({ field, form }: FieldProps) => (
                      <FormControl
                        isInvalid={
                          !!(
                            form.errors.newPassword && form.touched.newPassword
                          )
                        }
                      >
                        <FormLabel htmlFor="newPassword" mt={6}>
                          Enter your new password
                        </FormLabel>
                        <Input
                          {...field}
                          id="newPassword"
                          type="password"
                          placeholder="New password"
                        />
                        <FormErrorMessage>
                          {getIn(form.errors, 'newPassword')}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field type="password" name="confirmNewPassword">
                    {({ field, form }: FieldProps) => (
                      <FormControl
                        isInvalid={
                          !!(
                            form.errors.confirmNewPassword &&
                            form.touched.confirmNewPassword
                          )
                        }
                      >
                        {/* <FormLabel htmlFor="confirmNewPassword">
                            Confirm New Password
                          </FormLabel> */}
                        <Input
                          {...field}
                          id="confirmNewPassword"
                          type="password"
                          placeholder="Confirm new password"
                          mt={2}
                        />
                        <FormErrorMessage>
                          {getIn(form.errors, 'confirmNewPassword')}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </ModalBody>

                <ModalFooter>
                  <Button onClick={onClose}>Cancel</Button>
                  <Button
                    isLoading={isSubmitting}
                    type="submit"
                    w="100px"
                    ml={3}
                  >
                    Save
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Form>
          </FormikProvider>
        </Modal>

        <Flex w="100%" align="flex-end" justify="flex-end">
          <Button
            w="100px"
            onClick={saveEmailWithToast}
            disabled={DISABLE_EMAIL_UPDATE}
          >
            Save
          </Button>
        </Flex>
      </VStack>
    </Box>
  );
};
