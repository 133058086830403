import { format } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

import { TimezoneDateInput, TimezoneDateOutput } from 'types';

import { Timezone, timezones } from './timezonesList';

type ClientTimezoneDatetime = {
  date: string;
  time: string;
  datetime: string;
  timezone: Timezone | null;
};

export class TimezoneHandler {
  static apiToClient = (date?: TimezoneDateOutput): ClientTimezoneDatetime => {
    const { datetime, text = '' } = date || {};

    const timezone = TimezoneHandler.findTimezone({ text });

    const parsedDatetime =
      datetime && utcToZonedTime(datetime, timezone?.utc[0] || '', {});

    const formattedDate =
      parsedDatetime && format(parsedDatetime, 'yyyy-MM-dd');

    const formattedTime = parsedDatetime && format(parsedDatetime, 'HH:mm');

    const formattedDatetime = parsedDatetime && parsedDatetime.toISOString();

    return {
      date: formattedDate || '',
      time: formattedTime || '',
      datetime: formattedDatetime || '',
      timezone,
    };
  };

  static clientToApi = (
    clientTimezoneDatetime: Partial<ClientTimezoneDatetime>
  ): TimezoneDateInput => {
    const { timezone } = clientTimezoneDatetime;

    const zonedDatetime = TimezoneHandler.getZonedDatetime(
      clientTimezoneDatetime
    );

    const formattedDatetime = zonedDatetime.toISOString();

    return {
      datetime: formattedDatetime,
      text: timezone?.text || '',
      offset: timezone?.offset || 0,
    };
  };

  private static getZonedDatetime = ({
    date,
    datetime,
    time,
    timezone,
  }: Partial<ClientTimezoneDatetime>) => {
    if (datetime && timezone)
      return TimezoneHandler.getZonedDatetimeByDatetime({ datetime, timezone });

    if (date && time && timezone)
      return TimezoneHandler.getZonedDatetimeByDateAndTime({
        date,
        time,
        timezone,
      });

    throw new Error('Parsing error: either provide datetime, or date and time');
  };

  private static getZonedDatetimeByDatetime = ({
    datetime,
    timezone,
  }: Pick<ClientTimezoneDatetime, 'datetime' | 'timezone'>): Date => {
    return zonedTimeToUtc(datetime, timezone?.utc[0] || '');
  };

  private static getZonedDatetimeByDateAndTime = ({
    date,
    time,
    timezone,
  }: Pick<ClientTimezoneDatetime, 'date' | 'time' | 'timezone'>) => {
    const parsedDatetime = `${date}T${time}`;

    return zonedTimeToUtc(parsedDatetime, timezone?.utc[0] || '');
  };

  static findTimezone = ({
    abbr,
    text,
    id,
  }: Partial<Pick<Timezone, 'id' | 'abbr' | 'text'>>): Timezone | null => {
    if (id) {
      return timezones.find((timezone) => timezone.id === id) || null;
    }

    if (abbr) {
      return timezones.find((timezone) => timezone.abbr === abbr) || null;
    }

    if (text) {
      return timezones.find((timezone) => timezone.text === text) || null;
    }

    return null;
  };
}
