import { ReactNode } from 'react';

import { Center } from '@chakra-ui/react';

import { CATEGORY_RADIUS } from '../../constants';
import { Layer } from '../../types';
import { AnimatedCategoryBox } from './AnimatedCategoryBox';
import { AnimatedCategoryLabels } from './AnimatedCategoryLabels';
import { ValueCategoryTextPaths } from './ValueCategoryTextPaths';

const BOX_WIDTH = CATEGORY_RADIUS * 2;

type Props = {
  children: ReactNode;
};

export const CategoryWheel = ({ children }: Props) => {
  return (
    <Center
      zIndex={Layer.Category}
      w={BOX_WIDTH}
      h={BOX_WIDTH}
      transform="scale(0.99)"
      background="primary.blue.default"
      borderRadius="50%"
      position="relative"
    >
      <AnimatedCategoryBox />

      <svg
        width={BOX_WIDTH}
        height={BOX_WIDTH}
        style={{
          position: 'absolute',
        }}
        viewBox={`0 0 ${BOX_WIDTH} ${BOX_WIDTH}`}
      >
        <ValueCategoryTextPaths />
        <AnimatedCategoryLabels />
      </svg>

      {children}
    </Center>
  );
};
