import { gql, useMutation } from '@apollo/client';

import { postsGql } from 'hooks/posts';
import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationUpdateCommentArgs } from 'types';

const updateCommentGql = gql`
  mutation UpdateComment($comment: CommentUpdateInput!, $images: [Upload!]) {
    updateComment(comment: $comment, images: $images) {
      id
    }
  }
`;

export const useUpdateComment = () => {
  const [mutate] = useMutation<
    Pick<Mutation, 'updateComment'>,
    MutationUpdateCommentArgs
  >(updateCommentGql, { refetchQueries: [postsGql] });

  const handleUpdateComment = async (variables: MutationUpdateCommentArgs) => {
    await mutate({ variables });
  };

  const handleUpdateCommentWithToast = useWithMutationToast(
    handleUpdateComment,
    { disableSuccessMessage: true }
  );

  return { handleUpdateComment: handleUpdateCommentWithToast };
};
