import { useQuery } from '@apollo/client';

import { Query, QueryExtendedProfilesArgs } from 'types';

import { familyGql } from '../documents';

export const useFamilyById = (familyId?: string) => {
  const { data, error, loading, refetch } = useQuery<
    Pick<Query, 'family'>,
    Partial<QueryExtendedProfilesArgs>
  >(familyGql, {
    variables: {
      familyId,
    },
    skip: !familyId,
  });

  return { family: data?.family, error, loading, refetch };
};
