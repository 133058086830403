import { SelectInput } from 'components/Form/SelectInput';
import { Option } from 'types';

import { selectInputStyles } from './styles';

type Props = {
  membersOptions: Option[];
  isDisabled?: boolean;
};

export const GroupAdminInput = ({ membersOptions, isDisabled }: Props) => {
  return (
    <SelectInput
      name="adminId"
      label="Group Admin"
      options={membersOptions}
      defaultOptionText="Select"
      inputStyles={selectInputStyles}
      disabled={isDisabled}
    />
  );
};
