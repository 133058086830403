import { gql, MutationHookOptions, useMutation } from '@apollo/client';

import {
  Mutation,
  MutationRequestForgotPasswordVerificationSecondFactorArgs,
} from 'types';

const requestForgotPasswordVerificationSecondFactorGql = gql`
  mutation RequestForgotPasswordVerificationSecondFactor($token: String!) {
    requestForgotPasswordVerificationSecondFactor(token: $token)
  }
`;

export const useRequestForgotPasswordVerificationSecondFactor = <
  TData extends Pick<Mutation, 'requestForgotPasswordVerificationSecondFactor'>,
  TVariables extends MutationRequestForgotPasswordVerificationSecondFactorArgs
>(
  options?: MutationHookOptions<TData, TVariables>
) => {
  const [mutation, { loading, error }] = useMutation<TData, TVariables>(
    requestForgotPasswordVerificationSecondFactorGql,
    {
      ...options,
    }
  );

  const handleMutation = (variables: TVariables) => mutation({ variables });

  return {
    requestOtp: handleMutation,
    loading,
    error,
  };
};
