import { OnboardSdgsSummary } from 'components/OnboardSdgsSummary';
import { CmsSection } from 'features/Cms/components/CmsSection';
import { useGetOnboardContent } from 'features/Onboarding/hooks';
import { useFamilySdgsByFamilyId } from 'hooks/sdgs/useFamilySdgsByFamilyId';
import { useSdgsFromConfigurator } from 'hooks/sdgs/useSdgsFromConfigurator';
import { FamilyOnboardStateEnum, OnboardReferenceType } from 'types';
import { isFamilyOnboardCompleted } from 'utils/values';

import { PreviewLayout } from './PreviewLayout';

type Props = {
  referenceId: string;
  referenceType: OnboardReferenceType;
  state: FamilyOnboardStateEnum;
};

export const SdgPreviewSection = ({
  state,
  referenceId,
  referenceType,
}: Props) => {
  const { familySdgsChoices } = useFamilySdgsByFamilyId(
    referenceType,
    referenceId
  );
  const {
    sdg: { description, title },
  } = useGetOnboardContent();
  const sdgs = useSdgsFromConfigurator(familySdgsChoices);

  return (
    <CmsSection title="SDG">
      <PreviewLayout
        title="SDG"
        buttonText="View SDG results"
        isPreviewDisabled={!sdgs}
        isCompleted={isFamilyOnboardCompleted(
          state,
          FamilyOnboardStateEnum.SdgConfiguratorCompleted
        )}
        modalContent={
          sdgs && (
            <OnboardSdgsSummary
              sdgs={sdgs}
              description={description}
              title={title}
            />
          )
        }
      />
    </CmsSection>
  );
};
