import { useEffect, useMemo, useState } from 'react';

import { useAdvisorConnections } from 'gql/connections/query/advisorConnections';
import { useAllFamilyConnections } from 'gql/connections/query/allFamilyConnections';
import { usePeerConnections } from 'gql/connections/query/peerConnections';

export const useAllConnections = () => {
  const { familyConnections, ...familyConnectionsStatus } =
    useAllFamilyConnections();

  const { peerConnections, ...peerConnectionsStatus } = usePeerConnections();
  const { advisorConnections, ...advisorConnectionsStatus } =
    useAdvisorConnections();

  const [fConnections, setFConnections] = useState(familyConnections);
  const [pConnections, setPConnections] = useState(peerConnections);
  const [aConnections, setAConnections] = useState(advisorConnections);

  const filter = (searchQuery: string) => {
    setFConnections(
      familyConnections.filter((connection) =>
        new RegExp(searchQuery, 'i').test(
          `${connection.firstName} ${connection.lastName}`
        )
      )
    );
    setPConnections(
      peerConnections.filter((connection) =>
        new RegExp(searchQuery, 'i').test(
          `${connection.firstName} ${connection.lastName}`
        )
      )
    );
    setAConnections(
      advisorConnections.filter((connection) =>
        new RegExp(searchQuery, 'i').test(
          `${connection.firstName} ${connection.lastName}`
        )
      )
    );
  };

  const allConnections = useMemo(
    () =>
      [...fConnections, ...aConnections, ...pConnections].sort((a, b) =>
        a.firstName < b.lastName ? 1 : -1
      ),
    [fConnections, pConnections, aConnections]
  );

  useEffect(() => {
    setFConnections(familyConnections);
  }, [familyConnections]);

  useEffect(() => {
    setPConnections(peerConnections);
  }, [peerConnections]);

  useEffect(() => {
    setAConnections(advisorConnections);
  }, [advisorConnections]);

  return {
    allConnections,
    familyConnections: fConnections,
    peerConnections: pConnections,
    advisorConnections: aConnections,
    filter,
    loading:
      familyConnectionsStatus.loading ||
      peerConnectionsStatus.loading ||
      advisorConnectionsStatus.loading,
    error:
      familyConnectionsStatus.error ||
      peerConnectionsStatus.error ||
      advisorConnectionsStatus.error,
  };
};
