import { useTranslation } from 'react-i18next';

import { Flex } from '@chakra-ui/react';

import { Breadcrumbs } from 'components/Breadcrumbs';
import { TabPane } from 'components/TabPane';
import { Links } from 'router/links';

import { InsightsLandingPageHero } from '../components';
import { insightsTabsConfig } from '../insightsTabConfig';

export const InsightsLandingPage = () => {
  const { t } = useTranslation(['navigation']);

  return (
    <>
      <InsightsLandingPageHero />
      <Breadcrumbs
        items={[
          { link: Links.home, label: t('navigation:home') },
          { link: Links.insights, label: t('navigation:cms.insights') },
        ]}
      />
      <Flex direction="column" gap={10} mb={100}>
        <TabPane tabsConfig={insightsTabsConfig} />
      </Flex>
    </>
  );
};
