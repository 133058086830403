import { Link } from 'react-router-dom';

import { Flex } from '@chakra-ui/react';

import { ItriomMenuButton } from 'components/Button';
import { MostActiveGroups } from 'components/MostActiveGroups';
import { RecommendedGroups } from 'components/RecommendedGroups';
import { Links } from 'router/links';
import { Group } from 'types';

import { ManageGroup } from '../ManageGroup';
import { RecentlyJoined } from '../RecentlyJoined';
import { AboutGroup } from './AboutGroup';
import { AddMembersModal } from './AddMembersModal';
import { DeleteGroup } from './DeleteGroup';
import { GroupAdministrators } from './GroupAdministrators';
import { GroupAdvisors } from './GroupAdvisors';
import { GroupBanner } from './GroupBanner';
import { GroupMembersModal } from './GroupMembersModal';

type Props = {
  group: Group;
};

export const GroupSider = ({ group }: Props) => {
  const { id, description, admins, allowInvites, canBeModified, advisors } =
    group;

  return (
    <>
      <Flex direction="column" w="full">
        <GroupBanner group={group} />
        {canBeModified && (
          <>
            <ManageGroup group={group} />
            <GroupMembersModal group={group} />
          </>
        )}
        {(allowInvites || canBeModified) && <AddMembersModal groupId={id} />}
        <ItriomMenuButton as={Link} to={Links.groups}>
          View Groups
        </ItriomMenuButton>
        <DeleteGroup group={group} />
      </Flex>
      <AboutGroup description={description} />
      <GroupAdministrators admins={admins} />
      {!!advisors.length && <GroupAdvisors advisors={advisors} />}
      <RecentlyJoined />
      <MostActiveGroups />
      <RecommendedGroups withList={false} />
    </>
  );
};
