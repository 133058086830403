import { Close } from '@carbon/icons-react/next';
import { Flex, Text } from '@chakra-ui/layout';
import { Image, IconButton } from '@chakra-ui/react';

import { UploadFileType, PreviewBaseProps } from '../types';
import { isFileWithPathAndPreview } from '../utils';

type Props = PreviewBaseProps & {
  file: UploadFileType;
  isImage: boolean;
  onDelete: () => void;
  index: number;
};

export const FilePreview = ({
  file,
  previewsMaxWidth,
  isImage,
  onDelete,
  hideDeleteButton = false,
  index,
}: Props): JSX.Element => {
  const isFileWithPreview = isFileWithPathAndPreview(file);

  const mediaSrc = isFileWithPreview ? file.preview || '' : file.signedUrl;

  return (
    <Flex
      maxWidth={previewsMaxWidth || 'full'}
      gap={1}
      direction="column"
      w="full"
    >
      {isImage ? (
        <Image src={mediaSrc} alt={`uploaded-file-${index + 1}`} />
      ) : (
        <video src={mediaSrc} controls muted />
      )}

      <Flex align="center">
        {isFileWithPreview && <Text noOfLines={3}>{file.name}</Text>}
        {!hideDeleteButton && (
          <IconButton
            ml="auto"
            aria-label="Delete image"
            color="ui.white"
            cursor="pointer"
            as={Close}
            onClick={onDelete}
            data-testid="delete"
          />
        )}
      </Flex>
    </Flex>
  );
};
