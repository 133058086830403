import { SelectInput } from 'components/Form/SelectInput';

import { useAdvisorOptions } from '../../../hooks';

type Props = {
  advisorIdFieldName: string;
  label?: string;
};

export const AdvisorNameSelectSection = ({
  advisorIdFieldName,
  label,
}: Props) => {
  const { advisorOptions, loading, error } = useAdvisorOptions();

  return (
    <SelectInput
      name={advisorIdFieldName}
      label={label ?? 'Advisor Name'}
      options={advisorOptions}
      loading={loading}
      error={error}
      containerStyles={{ w: 208 }}
    />
  );
};
