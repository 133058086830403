import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { Button } from '@chakra-ui/react';

import { HeroBody } from 'components/HeroBody';
import { HeroMedia } from 'components/HeroMedia';
import { Links } from 'router/links';
import { Event } from 'types';
import { generateUrlById } from 'utils/links';

import { EventAttendButton } from '../EventAttendButton';

type Props = {
  event: Event;
  isLandingBlock?: boolean;
};

const ViewLocalEventButton = ({ id }: { id: string }) => (
  <Button
    as={Link}
    to={generateUrlById(Links.event, id)}
    bgColor="primary.lime.default"
    color="primary.blue.default"
    w={160}
    fontSize={14}
  >
    View event
  </Button>
);

const ViewExternalEventButton = ({ eventUrl }: { eventUrl: string }) => (
  <Button
    bgColor="primary.lime.default"
    w={160}
    h={42}
    fontSize={14}
    as="a"
    href={eventUrl}
    color="primary.blue.default"
    target="_blank"
    rel="noopener noreferrer"
    border="none"
  >
    View Event
  </Button>
);

export const EventHero = ({ event, isLandingBlock }: Props) => {
  const { hero, id, isAttending, tags, basicInfo } = event;

  const {
    heroImage,
    heroVideo,
    preferredHeroMediaToDisplay,
    name,
    eventUrl,
    subtitle = '',
  } = hero;

  const actionButton = useMemo(() => {
    if (isLandingBlock) {
      return <ViewLocalEventButton id={id} />;
    }

    if (eventUrl) {
      return <ViewExternalEventButton eventUrl={eventUrl} />;
    }

    return <EventAttendButton isAttending={isAttending} eventId={id} />;
  }, [id, isAttending, isLandingBlock]);

  return (
    <HeroMedia
      imageUrl={heroImage?.signedUrl}
      videoUrl={heroVideo?.signedUrl}
      preferredHeroMediaToDisplay={preferredHeroMediaToDisplay}
      {...(isLandingBlock && {
        as: Link,
        to: generateUrlById(Links.event, id),
      })}
    >
      <HeroBody
        title={name}
        subtitle={subtitle}
        tags={tags}
        calendarDate={{
          start: basicInfo?.startTime?.datetime,
          end: basicInfo?.endTime?.datetime,
          format: 'MMMM dd, yyyy',
        }}
      >
        <>{actionButton}</>
      </HeroBody>
    </HeroMedia>
  );
};
