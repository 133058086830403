import { ValuesCategorisation } from 'components/ValuesConfiguratorIntro/ValuesCategorisation';
import { Links } from 'router/links';

import { ValuesLayout } from '../components';

export const ValuesCategorisationPage = () => {
  return (
    <ValuesLayout
      color="primary.blue.default"
      background="base2"
      leftButton={{
        link: Links.valuesConfiguratorEssential,
      }}
      rightButton={{ link: Links.valuesConfiguratorImpact }}
    >
      <ValuesCategorisation />
    </ValuesLayout>
  );
};
