import { Forum } from '@carbon/icons-react/next';
import { Flex, Button, Grid } from '@chakra-ui/react';

import { Avatar } from 'components/Avatar';
import { useRedirectToConversationWithUser } from 'hooks/useRedirectToConversationWithUser';
import { Profile, ProfileConnectionStateEnum } from 'types';
import { isItriomStaff } from 'utils/roles';

import { ProfileActionMenu } from '../ProfileActionMenu';

interface Props {
  profile: Profile;
}

export const ConnectionProfile = ({ profile }: Props) => {
  const { id, family, connectionState, role } = profile;

  const handleRedirectToConversation =
    useRedirectToConversationWithUser(profile);

  const isConnected = connectionState === ProfileConnectionStateEnum.Connected;

  return (
    <Grid
      w="full"
      bgColor="ui.white"
      py={2}
      gridTemplateColumns="1fr auto minmax(2.25rem, min-content)"
    >
      <Avatar
        profile={profile}
        variant="author"
        subheadingInfo={family?.name}
      />

      {isConnected && (
        <Button
          onClick={handleRedirectToConversation}
          variant="ghost"
          size="xs"
        >
          <Forum />
        </Button>
      )}
      {!isItriomStaff(role) && isConnected && (
        <ProfileActionMenu id={id} connectionState={connectionState} />
      )}
    </Grid>
  );
};
