import { useTranslation } from 'react-i18next';

import { Grid, Image } from '@chakra-ui/react';

import { Sponsor } from 'types';

import { ContentWithHeading } from '../../ContentWithHeading';
import { EventBodyElement } from '../../EventBodyElement';

interface Props {
  sponsors: Sponsor[];
}

export const SponsorsSection = ({ sponsors }: Props) => {
  const { t } = useTranslation(['events']);

  if (!sponsors.length) return null;

  return (
    <EventBodyElement>
      <ContentWithHeading heading={t('events:sponsors')}>
        <Grid px={24} gap={20} gridTemplateColumns="repeat(3, 1fr)">
          {sponsors.map((sponsor) => (
            <Image
              width="full"
              height={16}
              objectFit="contain"
              src={sponsor.image?.signedUrl}
              alt={sponsor.name}
              key={sponsor.name}
            />
          ))}
        </Grid>
      </ContentWithHeading>
    </EventBodyElement>
  );
};
