import { useTranslation } from 'react-i18next';

import { Flex, Button, Text, Box } from '@chakra-ui/react';

import { useLibraryContext } from 'features/Library/context';
import { LibraryMediaOutput, LibrarySharedMediaOutput } from 'types';

import { INFO_SEPARATOR } from '../../constants';

interface Props {
  item: LibraryMediaOutput | LibrarySharedMediaOutput;
}

export const SharedWith = ({ item }: Props) => {
  const { t } = useTranslation(['library']);
  const { shareModal, setActionSingleItem } = useLibraryContext();

  if (!item.shares?.length) {
    return <Box />;
  }

  const handleClick = () => {
    setActionSingleItem(item);
    shareModal.onOpen();
  };

  return (
    <Flex gap={4}>
      <Text size="p2">{INFO_SEPARATOR}</Text>
      <Button
        variant="ghost"
        as={Text}
        size="p2"
        cursor="pointer"
        onClick={handleClick}
      >
        {t('library:shared-with', { number: item.shares.length })}
      </Button>
    </Flex>
  );
};
