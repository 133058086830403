import {
  FamilyCharter,
  FamilyCharterHeroOutput,
  FamilyCharterStatusEnum,
  FamilyCharterValueOutput,
  FamilyCharterValuesOutput,
  HeroMediaType,
} from 'types';

import { generateMedia } from './images.mock';

export const generateFamilyCharterValue = ({
  id = Date.now().toString(16),
  name = 'FamilyCharterValue',
  createdAt = Date.now(),
  updatedAt = Date.now(),
}: Partial<FamilyCharterValueOutput> = {}): FamilyCharterValueOutput => ({
  id,
  name,
  createdAt,
  updatedAt,
});

export const generateFamilyCharterValues = ({
  advancementValue = generateFamilyCharterValue({ name: 'AdvancementValue' }),
  contributionValue = generateFamilyCharterValue({ name: 'ContributionValue' }),
  organisationalValue = generateFamilyCharterValue({
    name: 'OrganisationalValue',
  }),
  societalValue = generateFamilyCharterValue({ name: 'SocietalValue' }),
}: Partial<FamilyCharterValuesOutput> = {}): FamilyCharterValuesOutput => ({
  advancementValue,
  contributionValue,
  organisationalValue,
  societalValue,
  __typename: 'FamilyCharterValuesOutput',
});

export const generateFamilyCharterHero = ({
  familyId = 'familyId',
  heading = 'heading',
  heroImage = generateMedia(),
  heroVideo = generateMedia(),
  preferredHeroMediaToDisplay = HeroMediaType.Image,
  purpose = 'purpose',
}: Partial<FamilyCharterHeroOutput> = {}): FamilyCharterHeroOutput => ({
  familyId,
  heading,
  heroImage,
  heroVideo,
  preferredHeroMediaToDisplay,
  purpose,
  __typename: 'FamilyCharterHeroOutput',
});

export const generateFamilyCharter = ({
  id = 'familyCharterId',
  hero = generateFamilyCharterHero(),
  status = FamilyCharterStatusEnum.Draft,
  values = generateFamilyCharterValues(),
  goals = [],
  createdAt = Date.now(),
  updatedAt = Date.now(),
  createdBy = Date.now().toString(16),
  updatedBy = Date.now().toString(16),
}: Partial<FamilyCharter> = {}): FamilyCharter => ({
  id,
  hero,
  status,
  values,
  goals,
  createdAt,
  updatedAt,
  createdBy,
  updatedBy,
  __typename: 'FamilyCharter',
});

export const familyCharterMock = generateFamilyCharter();
