import { gql, useMutation } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationUpdateEventHeroArgs } from 'types';
import { getUpdateFileValue } from 'utils/file';

import { UpdateEventHero } from '../../features/Cms/components/Events/EventBlocks/HeroBlock/types';
import { eventGql } from './useEvent';
import { eventsGql } from './useEvents';

export const updateEventHeroGql = gql`
  mutation UpdateEventHero(
    $event: EventHeroInput!
    $heroVideo: [Upload!]
    $heroImage: [Upload!]
  ) {
    updateEventHero(
      event: $event
      heroVideo: $heroVideo
      heroImage: $heroImage
    ) {
      id
    }
  }
`;

export const useUpdateEventHero = () => {
  const [updateEventHeroRequest, { loading }] = useMutation<
    Pick<Mutation, 'updateEventHero'>,
    MutationUpdateEventHeroArgs
  >(updateEventHeroGql, {
    refetchQueries: [eventsGql, eventGql],
  });

  const updateEventHero = (event: UpdateEventHero) => {
    const { heroImage, heroVideo, ...data } = event;

    const variables = {
      event: { ...data },
      heroImage: getUpdateFileValue(heroImage),
      heroVideo: getUpdateFileValue(heroVideo),
    };

    return updateEventHeroRequest({ variables });
  };

  const updateEventHeroWithToast = useWithMutationToast(updateEventHero, {
    successMessage: 'The event was successfully updated',
  });

  return {
    updateEventHero: updateEventHeroWithToast,
    loading,
  };
};
