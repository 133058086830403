import { ValueConfiguratorNormalizedOutput } from 'types';
import {
  OrganisationalValue,
  ContributionValue,
  AdvancementValue,
  SocietalValue,
} from 'types/ValuesConfigurator';

export const WINNING_VALUES = {
  organisational: OrganisationalValue.Resilience,
  contribution: ContributionValue.Inclusivity,
  advancement: AdvancementValue.Learning,
  societal: SocietalValue.Empowerment,
};

export const valueConfiguratorValuesMock: ValueConfiguratorNormalizedOutput = {
  organisationalValues: {
    resilience: 10,
    leadership: 3.9,
    transparency: 2.98,
    adaptability: 0,
    __typename: 'OrganisationalValuesNormalizedOutput',
  },
  contributionValues: {
    fairness: 3.15,
    stewardship: 1.03,
    inclusivity: 10,
    community: 0.6,
    __typename: 'ContributionValuesNormalizedOutput',
  },
  advancementValues: {
    progress: 0,
    learning: 10,
    innovation: 1.3,
    curiosity: 3.65,
    __typename: 'AdvancementValuesNormalizedOutput',
  },
  societalValues: {
    collaboration: 2.65,
    equality: 2.29,
    compassion: 2.27,
    empowerment: 10,
    __typename: 'SocietalValuesNormalizedOutput',
  },
  __typename: 'ValueConfiguratorNormalizedOutput',
};
