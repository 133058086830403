import { gql, useQuery } from '@apollo/client';

import { Event } from 'gql/fragments';
import { Query } from 'types';

const bannerEventGql = gql`
  ${Event}
  query BannerEvents {
    bannerEvents {
      ...Event
    }
  }
`;

export const useBannerEvent = () => {
  const { data, loading, error } =
    useQuery<Pick<Query, 'bannerEvents'>>(bannerEventGql);

  return { bannerEvent: data?.bannerEvents, loading, error };
};
