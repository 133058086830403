import { Flex, Image, Grid } from '@chakra-ui/react';

import { MediaOutput } from 'types';

type Props = {
  images: MediaOutput[];
};

export const ArticleGallery = ({ images }: Props) => (
  <Grid
    gridTemplateColumns={`repeat(min(${images.length}, 3), 1fr)`}
    width="full"
    gap={4}
  >
    {images.map((image) => (
      <Flex key={image.id}>
        <Image objectFit="cover" width="full" src={image.signedUrl} />
      </Flex>
    ))}
  </Grid>
);
