/* eslint-disable react/no-unstable-nested-components */
import MdPreview from '@uiw/react-markdown-preview';

import { ReactNode, useEffect } from 'react';

import { Heading, Text } from '@chakra-ui/react';

import { colors } from 'theme/colors';
import { sizes } from 'theme/typography/Heading';
import { textStyles } from 'theme/typography/Text';

type Props = {
  content?: string;
  style?: React.CSSProperties;
};

export const markdownPreviewStyles = {
  flex: 1,
  backgroundColor: 'transparent',
  color: colors.primary.blue.default,
  ...textStyles.sizes.p1,
};

const HeadingComponent = ({
  size,
  children,
}: {
  size: keyof typeof sizes;
  children: ReactNode;
}) => (
  <Heading size={size} style={sizes[size]}>
    {children}
  </Heading>
);

export const MarkdownPreview = ({ content, style }: Props) => {
  useEffect(() => {
    document.documentElement.setAttribute('data-color-mode', 'light');
  }, []);

  return (
    <MdPreview
      source={content}
      components={{
        p: ({ children }) => <Text size="p2">{children}</Text>,
        h1: ({ children }) => (
          <HeadingComponent size="h1">{children}</HeadingComponent>
        ),
        h2: ({ children }) => (
          <HeadingComponent size="h2">{children}</HeadingComponent>
        ),
        h3: ({ children }) => (
          <HeadingComponent size="h3">{children}</HeadingComponent>
        ),
        h4: ({ children }) => (
          <HeadingComponent size="h4">{children}</HeadingComponent>
        ),
        h5: ({ children }) => (
          <HeadingComponent size="h5">{children}</HeadingComponent>
        ),
        h6: ({ children }) => (
          <HeadingComponent size="h6">{children}</HeadingComponent>
        ),
      }}
      style={{ ...markdownPreviewStyles, ...style }}
    />
  );
};
