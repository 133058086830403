export enum ValueCategoryType {
  Importance = 'importance',
  Relevance = 'relevance',
}

export enum OrganisationalValue {
  Resilience = 'resilience',
  Leadership = 'leadership',
  Transparency = 'transparency',
  Adaptability = 'adaptability',
}

export enum ContributionValue {
  Fairness = 'fairness',
  Inclusivity = 'inclusivity',
  Stewardship = 'stewardship',
  Community = 'community',
}

export enum AdvancementValue {
  Progress = 'progress',
  Innovation = 'innovation',
  Learning = 'learning',
  Curiosity = 'curiosity',
}

export enum SocietalValue {
  Collaboration = 'collaboration',
  Compassion = 'compassion',
  Equality = 'equality',
  Empowerment = 'empowerment',
}

export enum ValueCategoryName {
  Organisational = 'organisational',
  Contribution = 'contribution',
  Advancement = 'advancement',
  Societal = 'societal',
}

export type ValueName =
  | OrganisationalValue
  | ContributionValue
  | AdvancementValue
  | SocietalValue;

export const VALUES: ValueName[] = [
  ...Object.values(OrganisationalValue),
  ...Object.values(ContributionValue),
  ...Object.values(AdvancementValue),
  ...Object.values(SocietalValue),
];

export const isValueName = (name: string): name is ValueName => {
  return VALUES.includes(name as ValueName);
};
