import { useUpdatePost } from 'hooks/posts/useUpdatePost';
import { Post } from 'types';
import { getUpdateFileValue } from 'utils/file';

import { PostFormBase } from './PostFormBase';
import { FormValues, PostFormConfigProps } from './types';

type Props = {
  post: Post;
  onUpdate?: VoidFunction;
} & PostFormConfigProps;

export const EditPostForm = ({ post, onUpdate, ...baseProps }: Props) => {
  const { updatePost } = useUpdatePost();

  const { id, referenceId, text, images = [], title = '' } = post;

  const handleSubmit = async (values: FormValues) => {
    await updatePost({
      images: getUpdateFileValue(values.upload),
      post: {
        id,
        referenceId,
        title: values.title,
        text: values.text,
      },
    });

    if (onUpdate) {
      onUpdate();
    }
  };

  const initialValues: FormValues = {
    text,
    title,
    upload: images,
  };

  return (
    <PostFormBase
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      {...baseProps}
    />
  );
};
