import { FreeMode, Keyboard, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ButtonProps, Flex, FlexProps, Heading } from '@chakra-ui/react';

import { PAGINATION_GAP_SIZE, SwiperButton } from './SwiperButton';
import { PAGINATION } from './constants';
import './paginationClass.css';

type CarouselBaseProps<T extends { id: string }> = {
  items: T[];
  ItemComponent: ({ item }: { item: T }) => JSX.Element;
  title?: string;
  containerStyles?: FlexProps['sx'];
  swiperStyles?: React.CSSProperties;
  slidesPerView?: number;
  buttonStyling?: ButtonProps;
  spaceBetween?: number;
  hasPagination?: boolean;
  slidesPerGroup?: number;
};

export const CarouselBase = <T extends { id: string }>({
  items,
  ItemComponent,
  title,
  containerStyles,
  swiperStyles,
  slidesPerView,
  buttonStyling,
  hasPagination = true,
  spaceBetween = 80,
  slidesPerGroup,
}: CarouselBaseProps<T>) => {
  if (items.length === 0) return null;

  return (
    <Flex
      overflow="visible"
      gap={6}
      position="relative"
      direction="column"
      sx={{
        '& [class^=swiper-pagination]': { bottom: '0px' },
        ...containerStyles,
      }}
    >
      {title && <Heading size="h3">{title}</Heading>}
      <Swiper
        slidesPerView={slidesPerView || 'auto'}
        spaceBetween={spaceBetween}
        grabCursor
        freeMode={{ sticky: true }}
        modules={[Keyboard, FreeMode, Navigation, Pagination]}
        pagination={hasPagination && PAGINATION}
        slidesPerGroup={slidesPerGroup}
        loop
        keyboard
        style={{
          width: '100%',
          paddingBottom: `${PAGINATION_GAP_SIZE}px`,
          ...swiperStyles,
        }}
      >
        {items.map((item) => (
          <SwiperSlide key={item.id} style={{ width: 'auto' }}>
            <ItemComponent item={item} />
          </SwiperSlide>
        ))}
        <SwiperButton type="prev" buttonStyling={buttonStyling} />
        <SwiperButton type="next" buttonStyling={buttonStyling} />
      </Swiper>
    </Flex>
  );
};
