export enum Category {
  Unity,
  Goals,
  Culture,
  Direction,
}

export type Value = {
  iconUrl: string;
  title: string;
  description: string;
  color: string;
};
