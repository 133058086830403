import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, Grid, GridItem, useOutsideClick } from '@chakra-ui/react';

import { Avatar } from 'components/Avatar';
import { ViewButton } from 'components/Button';
import { useSearchContext } from 'contexts/SearchContext';
import { Links } from 'router/links';
import { generateUrlById } from 'utils/links';

import { SearchPreview } from './SearchPreview';
import { TitlePreview } from './TitlePreview';

export const Results = () => {
  const { t } = useTranslation(['common']);
  const ref = useRef<HTMLDivElement | null>(null);

  const { searchResults, closeSearchPreview } = useSearchContext();

  useOutsideClick({ ref, handler: closeSearchPreview });

  return (
    <Grid ref={ref}>
      <GridItem as={Flex} p="4" flexDirection="column" gap="2">
        {!!searchResults.connections && searchResults.connections.nodes[0] && (
          <SearchPreview
            title={t('common:connections')}
            renderComponent={
              <Avatar
                profile={searchResults.connections.nodes[0].object}
                variant="horizontal"
              />
            }
          />
        )}
        {!!searchResults.events && searchResults.events.nodes[0] && (
          <SearchPreview
            title={t('common:events')}
            renderComponent={
              <TitlePreview
                text={searchResults.events.nodes[0].object.hero.name}
                locationUrl={generateUrlById(
                  Links.event,
                  searchResults.events.nodes[0].object.id
                )}
              />
            }
          />
        )}
        {!!searchResults.initiatives && searchResults.initiatives.nodes[0] && (
          <SearchPreview
            title={t('common:initiatives')}
            renderComponent={
              <TitlePreview
                text={searchResults.initiatives.nodes[0].object.hero.headline}
                locationUrl={generateUrlById(
                  Links.initiativesSingle,
                  searchResults.initiatives.nodes[0].object.id
                )}
              />
            }
          />
        )}
        {!!searchResults.insights && searchResults.insights.nodes[0] && (
          <SearchPreview
            title={t('common:insights')}
            renderComponent={
              <TitlePreview
                text={searchResults.insights.nodes[0].object.hero.headline}
                locationUrl={generateUrlById(
                  Links.insight,
                  searchResults.insights.nodes[0].object.id
                )}
              />
            }
          />
        )}
        {!!searchResults.library && searchResults.library.nodes[0] && (
          <SearchPreview
            title={t('common:library')}
            renderComponent={
              <TitlePreview
                text={searchResults.library.nodes[0].object.name}
                locationUrl={generateUrlById(
                  Links.library,
                  searchResults.library.nodes[0].object.id
                )}
              />
            }
          />
        )}
      </GridItem>
      <GridItem
        as={Flex}
        justifyContent="flex-end"
        py="1"
        px="3"
        borderTop="medium"
      >
        <ViewButton
          variant="blue"
          to={Links.search}
          onClick={closeSearchPreview}
        >
          {t('common:search.view-full-results')}
        </ViewButton>
      </GridItem>
    </Grid>
  );
};
