import { gql, useMutation } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationFollowInitiativeArgs } from 'types';

import { initiativeGql } from './useInitiative';

const followInitiativeGql = gql`
  mutation FollowInitiative($id: String!) {
    followInitiative(id: $id)
  }
`;

export const useFollowInitiative = (initiativeId: string) => {
  const [followInitiativeMutation, { loading }] = useMutation<
    Pick<Mutation, 'followInitiative'>,
    MutationFollowInitiativeArgs
  >(followInitiativeGql, {
    refetchQueries: [initiativeGql],
  });

  const followInitiative = () =>
    followInitiativeMutation({ variables: { id: initiativeId } });

  const followInitiativeWithMutationToast = useWithMutationToast(
    followInitiative,
    {
      successMessage: 'Followed initiative',
      errorMessage: 'Failed following initiative',
    }
  );

  return { followInitiative: followInitiativeWithMutationToast, loading };
};
