import { ReactNode } from 'react';

import { BorderedTile } from 'components/BorderedTile';
import { LayoutGridElement } from 'components/Layout/Grid';

interface Props {
  title: string;
  subtitleContent: ReactNode;
  children: ReactNode;
}

export const Section = ({ title, subtitleContent, children }: Props) => {
  return (
    <>
      <LayoutGridElement colSpan={4}>
        <BorderedTile title={title}>{subtitleContent}</BorderedTile>
      </LayoutGridElement>
      <LayoutGridElement colSpan={12}>{children}</LayoutGridElement>
    </>
  );
};
