import { useTranslation } from 'react-i18next';

import { Text } from '@chakra-ui/react';

import { Links } from 'router/links';
import { ExtendedProfileOutput } from 'types';

import { CmsSection } from '../CmsSection';
import { MemberRow } from './MemberRow';

type Props = {
  members: ExtendedProfileOutput[];
  adminType: 'itriom' | 'family';
};

export const EditFamilyMembersSection = ({ members, adminType }: Props) => {
  const { t } = useTranslation(['cms']);

  const isItriomAdmin = adminType === 'itriom';

  const title = isItriomAdmin ? t('cms:family.members.title') : undefined;

  const editButtonLink = isItriomAdmin
    ? Links.cmsUserEdit
    : Links.familyManagementUserEdit;

  return (
    <CmsSection title={title}>
      {members.length ? (
        members.map((member) => (
          <MemberRow
            key={member.id}
            member={member}
            hasActivationButton={isItriomAdmin}
            editButtonLink={editButtonLink}
          />
        ))
      ) : (
        <Text>{t('cms:family.members.no-members')}</Text>
      )}
    </CmsSection>
  );
};
