import { Panel } from 'components/Layout/Panel';

type Props = {
  description: string | undefined;
};

export const AboutGroup = ({ description }: Props) => (
  <Panel title="About this Group" variant="secondary">
    {description}
  </Panel>
);
