import { useAvailableSdgs } from 'hooks/onboarding/useAvailableSdgs';
import { usePersonalSdgs } from 'hooks/onboarding/usePersonalSdgs';
import { SdgOutput } from 'types';

// TODO: LEIP-1542
export const usePersonalMappedSdgs = () => {
  const { availableSdgs } = useAvailableSdgs();
  const { personalSdgs } = usePersonalSdgs();

  if (!personalSdgs || !availableSdgs) return [];

  const mappedPersonalSdgs = personalSdgs.sdgIdsWithNumericScores
    .map(({ sdgId }) => {
      const foundSdg = availableSdgs.find(({ id }) => id === sdgId);

      return foundSdg;
    })
    .filter(Boolean) as SdgOutput[];

  return mappedPersonalSdgs;
};
