import { gql } from '@apollo/client';

import {
  ConnectionInvitationNoNestingFragment,
  ProfileFragment,
} from 'types/generated-fragments';

export const advisorConnectionsGql = gql`
  ${ProfileFragment}
  query AdvisorConnections {
    advisorConnections {
      ...ProfileFragment
    }
  }
`;

export const connectionInvitationsGql = gql`
  ${ProfileFragment}
  ${ConnectionInvitationNoNestingFragment}
  query ConnectionInvitations {
    connectionInvitations {
      ...ConnectionInvitationNoNestingFragment
      sender {
        ...ProfileFragment
      }
    }
  }
`;

export const eventConnectionsGql = gql`
  ${ProfileFragment}
  query EventConnections {
    eventConnections {
      ...ProfileFragment
    }
  }
`;

export const familyConnectionsGql = gql`
  ${ProfileFragment}
  query FamilyConnections {
    familyConnections {
      ...ProfileFragment
    }
  }
`;

export const initiativeConnectionsGql = gql`
  ${ProfileFragment}
  query InitiativeConnections {
    initiativeConnections {
      ...ProfileFragment
    }
  }
`;

export const itriomConnectionsGql = gql`
  ${ProfileFragment}
  query ItriomConnections {
    itriomConnections {
      ...ProfileFragment
    }
  }
`;

export const peerConnectionsGql = gql`
  ${ProfileFragment}
  query PeerConnections {
    peerConnections {
      ...ProfileFragment
    }
  }
`;

export const allFamilyConnectionsGql = gql`
  ${ProfileFragment}
  query AllFamilyConnections {
    allFamilyConnections {
      ...ProfileFragment
    }
  }
`;

export const recentConnectionsGql = gql`
  ${ProfileFragment}
  query RecentConnections {
    recentConnections {
      ...ProfileFragment
    }
  }
`;

export const mutualConnectionsGql = gql`
  ${ProfileFragment}
  query MutualConnections(
    $accountId: String!
    $pagination: PaginationInput = { offset: 0, limit: 100 }
  ) {
    mutualConnections(accountId: $accountId, pagination: $pagination) {
      nodes {
        ...ProfileFragment
      }
      offset
      totalCount
    }
  }
`;
