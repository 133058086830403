import { Add20, Checkmark20, Subtract20 } from '@carbon/icons-react';

import { HoverButton } from 'components/Button';
import { useFollowInitiative, useUnfollowInitiative } from 'hooks/initiatives';

type ButtonProps = {
  initiativeId: string;
};

const FollowInitiativeButton = ({ initiativeId }: ButtonProps) => {
  const { followInitiative, loading } = useFollowInitiative(initiativeId);

  return (
    <HoverButton
      rightIcon={<Add20 />}
      onClick={followInitiative}
      isLoading={loading}
    >
      Follow
    </HoverButton>
  );
};

const UnfollowInitiativeButton = ({ initiativeId }: ButtonProps) => {
  const { loading, unfollowInitiative } = useUnfollowInitiative(initiativeId);

  return (
    <HoverButton
      isLoading={loading}
      onClick={unfollowInitiative}
      rightIcon={<Checkmark20 />}
      hover={{ content: 'Unfollow', rightIcon: <Subtract20 /> }}
    >
      Following
    </HoverButton>
  );
};

type CTAProps = {
  initiativeId: string;
  isFollowed: boolean;
};

export const InitiativeCTA = ({ initiativeId, isFollowed }: CTAProps) => {
  if (isFollowed)
    return <UnfollowInitiativeButton initiativeId={initiativeId} />;

  return <FollowInitiativeButton initiativeId={initiativeId} />;
};
