import { useSprings } from 'react-spring';

import { FADE_ANIMATION_STATES, VALUE_CATEGORIES } from '../../constants';
import { getBuildingAnimationTimeout } from '../../utils';
import { CategoryLabel } from './CategoryLabel';

export const AnimatedCategoryLabels = () => {
  const springs = useSprings(
    VALUE_CATEGORIES.length,
    VALUE_CATEGORIES.map((_, index) => ({
      from: FADE_ANIMATION_STATES.off,
      to: FADE_ANIMATION_STATES.on,
      config: { tension: 30, friction: 18 },
      delay: getBuildingAnimationTimeout(index),
    }))
  );

  return (
    <>
      {springs.map((styles, index) => {
        const category = VALUE_CATEGORIES[index];

        return category ? (
          <CategoryLabel
            key={category.name}
            textPathId={`#textPath-${category.name}`}
            category={category}
            animationStyles={styles}
          />
        ) : null;
      })}
    </>
  );
};
