import { Profile } from 'types';

type Props = {
  connections: Profile[];
  userInput: string;
  interlocutors: Profile[];
};

export const useFilteredConnections = ({
  connections,
  userInput,
  interlocutors,
}: Props) => {
  const againstUserInput = (profile: Profile) => {
    const lowerCaseInput = userInput.toLowerCase();

    return (
      profile.firstName?.toLowerCase().includes(lowerCaseInput) ||
      profile.lastName?.toLowerCase().includes(lowerCaseInput)
    );
  };

  const againstAlreadySelected = (profile: Profile) => {
    return !interlocutors.find((i) => i.id === profile.id);
  };

  return connections.filter(againstUserInput).filter(againstAlreadySelected);
};
