import { addDays } from 'date-fns';
import { Form, FormikProvider, useFormik } from 'formik';

import { Button, Flex } from '@chakra-ui/react';

import { SelectInput } from 'components/Form/SelectInput';
import { TextInput } from 'components/Form/TextInput';
import { useTimezoneOptions } from 'hooks/useTimezoneOptions';
import { OnboardManagementOutput } from 'types';
import { stringToInputDate, TimezoneHandler } from 'utils/date';

import { getMeetingInitialValues } from './getMeetingInitialValues';
import { FormType, FieldName } from './types';
import { useHandleSubmit } from './useHandleSubmit';

type Props = {
  onboardManagement: OnboardManagementOutput;
};

const BLOCKED_NEXT_DAYS = 4;

export const MeetingInviteForm = ({ onboardManagement }: Props) => {
  const { referenceId, meetingInvitation } = onboardManagement;
  const initialValues = getMeetingInitialValues(meetingInvitation);
  const handleSubmit = useHandleSubmit(referenceId);

  const formik = useFormik<FormType>({
    initialValues,
    onSubmit: handleSubmit,
  });

  const { timezoneOptions } = useTimezoneOptions();

  const handleTimezoneChange = (id: string) => {
    const foundTimezone = TimezoneHandler.findTimezone({ id });

    formik.setFieldValue(FieldName.Timezone, foundTimezone);
  };

  const minWorkshopDate = addDays(new Date(), BLOCKED_NEXT_DAYS).toISOString();

  return (
    <FormikProvider value={formik}>
      <Form>
        <Flex direction="column" w={{ base: 'full', lg: 540 }} gap={8}>
          <Flex gap={4}>
            <TextInput
              label="Date"
              name={FieldName.Date}
              size="md"
              type="date"
              min={stringToInputDate(minWorkshopDate)}
            />
            <TextInput
              label="Time"
              name={FieldName.Time}
              size="md"
              type="time"
            />
            <SelectInput
              label="Timezone"
              name={FieldName.Timezone}
              optionValueKey="id"
              options={timezoneOptions}
              onChange={handleTimezoneChange}
            />
          </Flex>

          <TextInput
            name={FieldName.Location}
            label="Location"
            placeholder="2115 Koch View, Elishafurt, Malta"
          />
          <TextInput
            name={FieldName.MeetingLink}
            label="Meeting link"
            placeholder="https://zoom.us/j/175829582"
          />
          <Flex gap={4}>
            <TextInput
              name={FieldName.MeetingId}
              label="Meeting I.D."
              placeholder="481 405 4132"
            />
            <TextInput name={FieldName.MeetingPasscode} label="Passcode" />
          </Flex>
          <Button type="submit">Submit</Button>
        </Flex>
      </Form>
    </FormikProvider>
  );
};
