import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Flex, Text, useDisclosure } from '@chakra-ui/react';

import { Modal } from 'components/Modal';
import { useAuthContext } from 'contexts/AuthContext';

export const UnauthorizedModal = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { logout } = useAuthContext();
  const { t } = useTranslation(['common']);

  const handleClose = () => {
    onClose();
    logout();
  };

  useEffect(() => {
    window.addEventListener('unauthorized', onOpen);

    return () => window.removeEventListener('unauthorized', onOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      containerStyling={{ p: 4 }}
      title={t('common:you-have-been-logged-out')}
      onClose={handleClose}
      footer={
        <Flex justifyContent="flex-end" w="full">
          <Button onClick={handleClose}>{t('common:ok')}</Button>
        </Flex>
      }
    >
      <Text size="p1">{t('common:you-will-be-redirected')}</Text>
    </Modal>
  );
};
