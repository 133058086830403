import { Flex } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';

import { Carousel, CarouselType } from 'components/Carousel';
import { QueryStateGuard } from 'components/QueryStateGuard';
import { useKeyEvents } from 'hooks/events';

export const KeyEvent = () => {
  const { keyEvents, loading, error } = useKeyEvents();

  return (
    <Flex
      position="relative"
      bgColor="ui.white"
      w="full"
      h="full"
      borderRadius={4}
      overflow="hidden"
    >
      <QueryStateGuard
        loading={loading}
        error={error}
        errorTitle="Cannot fetch event"
      >
        {keyEvents.length ? (
          <Carousel type={CarouselType.KeyEvents} />
        ) : (
          <Text p={2}>There is no event to show</Text>
        )}
      </QueryStateGuard>
    </Flex>
  );
};
