import { createContext, useContext, useMemo } from 'react';

import {
  Conversation,
  PaginatedConversationOutput,
  PaginatedMessageOutput,
  WithChildren,
} from 'types';

import {
  useCreateConversation,
  useDeleteConversation,
  useDeleteMessage,
  useLoadConversations,
  useLoadMessagesForConversation,
  useSendMessage,
} from './hooks';
import { NewConversationInput, NewMessageInput } from './types';

type MessagingComunicationApi = {
  loadConversations: () => Promise<PaginatedConversationOutput>;
  loadMoreConversations: () => Promise<PaginatedConversationOutput>;
  createNewConversation: (
    newConversationInput: NewConversationInput
  ) => Promise<Conversation['id'] | undefined>;
  deleteConversation: (conversationId: string) => Promise<void>;
  loadMessagesForConversation: (
    conversationId: string
  ) => Promise<PaginatedMessageOutput>;
  loadMoreMessagesForConversation: (
    conversationId: string
  ) => Promise<PaginatedMessageOutput>;
  sendMessage: (newMessageInput: NewMessageInput) => Promise<void>;
  deleteMessage: (messageId: string) => Promise<void>;
};

const MessagingCommunicationContext =
  createContext<MessagingComunicationApi | null>(null);

export const useMessagingCommunication = () => {
  const context = useContext(MessagingCommunicationContext);

  if (context === null) {
    throw new Error('useMessagingCommunication used outside of its provider');
  }

  return context;
};

export const MessagingCommunicationProvider = ({ children }: WithChildren) => {
  const { loadConversations, loadMoreConversations } = useLoadConversations();

  const createNewConversation = useCreateConversation();

  const deleteConversation = useDeleteConversation();

  const { loadMessagesForConversation, loadMoreMessagesForConversation } =
    useLoadMessagesForConversation();

  const sendMessage = useSendMessage();

  const deleteMessage = useDeleteMessage();

  const contextValue = useMemo(
    () => ({
      loadConversations,
      loadMoreConversations,
      createNewConversation,
      deleteConversation,
      loadMessagesForConversation,
      loadMoreMessagesForConversation,
      deleteMessage,
      sendMessage,
    }),
    [
      createNewConversation,
      deleteConversation,
      deleteMessage,
      loadConversations,
      loadMessagesForConversation,
      loadMoreConversations,
      loadMoreMessagesForConversation,
      sendMessage,
    ]
  );

  return (
    <MessagingCommunicationContext.Provider value={contextValue}>
      {children}
    </MessagingCommunicationContext.Provider>
  );
};
