import { gql, useQuery } from '@apollo/client';

import { GroupWithImages } from 'gql/fragments';
import { Query } from 'types';
import { ProfileFragment, SdgFragment } from 'types/generated-fragments';

export const groupGql = gql`
  ${GroupWithImages}
  ${ProfileFragment}
  ${SdgFragment}
  query Group($groupId: String!) {
    group(id: $groupId) {
      ...GroupWithImages
      owner {
        ...ProfileFragment
      }
      admins {
        ...ProfileFragment
      }
      advisors {
        ...ProfileFragment
      }
      members {
        ...ProfileFragment
      }
      sdg {
        ...SdgFragment
      }
    }
  }
`;

export const useGroup = (groupId?: string) => {
  const { data, loading, error } = useQuery<Pick<Query, 'group'>>(groupGql, {
    fetchPolicy: 'cache-and-network',
    variables: {
      groupId,
    },
    skip: !groupId,
  });

  const group = data?.group;

  return { group, loading, error };
};
