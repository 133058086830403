import { ReactNode } from 'react';

import { Flex, Heading, Text } from '@chakra-ui/react';

type Props = {
  heading: string;
  description: string;
  children: ReactNode;
};

export const ScoreSection = ({ heading, description, children }: Props) => {
  return (
    <Flex
      my={8}
      mx="auto"
      justify="space-between"
      flexWrap="wrap"
      gap={4}
      w="auto"
      maxW="7xl"
    >
      <Flex direction="column" maxW={400} verticalAlign="top">
        <Heading size="h3">{heading}</Heading>
        <Heading size="h4" mb={4}>
          Each Value Category shows 100 points
        </Heading>
        <Text size="p2">
          Itriom has grouped values relevant to families, into four categories
          that capture both the areas of purpose for the family and enablers for
          achieving the purpose.
        </Text>
        <Text size="p2" pt={3}>
          {description}
        </Text>
      </Flex>
      <Flex direction="column" textAlign="left" mx="auto">
        {children}
      </Flex>
    </Flex>
  );
};
