import { useAcceptConnectionInvitation } from 'gql/connections/mutation/acceptConnectionInvitation';

import { ProfileConnectionButton } from './ProfileConnectionButton';
import { ConnectCtaProps } from './types';

type Props = Pick<ConnectCtaProps, 'userName'> & {
  invitationId: string;
};

export const AcceptInvitationButton = ({ userName, invitationId }: Props) => {
  const { acceptConnectionInvitation } = useAcceptConnectionInvitation({
    customSuccessMessage: `You've accepted to connect with ${userName}`,
  });

  const handleClick = () => acceptConnectionInvitation(invitationId);

  return (
    <ProfileConnectionButton
      handleClick={handleClick}
      text="Invited you to connect. Accept invitation?"
    />
  );
};
