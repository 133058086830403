import { useEffect, useState } from 'react';

type WithId = { id: string | number };

export const useList = <T extends WithId>(initialIds: T[] = []) => {
  const [items, setItems] = useState<T[]>(initialIds);
  const [initiallyPopulated, setInitiallyPopulated] = useState(false);

  useEffect(() => {
    if (!initiallyPopulated && initialIds.length !== 0) {
      setItems(initialIds);
      setInitiallyPopulated(true);
    }
  }, [initialIds, initiallyPopulated]);

  const addItem = (item: T) => setItems((prev) => [...prev, item]);

  const removeItem = (item: T) =>
    setItems((prev) => prev.filter((prevItem) => prevItem.id !== item.id));

  const sort = (sortFunction: (a: T, b: T) => number) =>
    setItems((prev) => [...prev].sort(sortFunction));

  const contains = (item: T): boolean =>
    !!items.find(({ id }) => item.id === id);

  const clear = () => {
    setItems([]);
  };

  return { items, addItem, removeItem, sort, contains, clear };
};
