import { Flex, Heading } from '@chakra-ui/react';

import { WithChildren } from 'types';

const circleMaxWidth = 430;

export const Semicircle = ({ children }: WithChildren) => {
  return (
    <Flex
      mx="auto"
      overflow="hidden"
      borderBottomWidth={3}
      borderColor="darkGrey5"
      maxW={480}
      w="full"
      height={circleMaxWidth / 2}
    >
      <Flex position="relative" pt="100%" minW={280} overflow="hidden" w="full">
        <Flex
          position="absolute"
          w="full"
          h="full"
          top={0}
          left="50%"
          transform="translateX(-50%)"
          maxW={circleMaxWidth}
          maxH={circleMaxWidth}
          clipPath="polygon(0 0,0 50%,100% 50%,100% 0)"
        >
          {children}
          <Flex
            position="absolute"
            width="50%"
            height="50%"
            bgColor="ui.white"
            top="50%"
            left="50%"
            transform="translate(-50%,-50%)"
            borderRadius="50%"
            zIndex={5}
            alignItems="center"
            justifyContent="center"
          >
            <Heading size="h3" pb={20}>
              Benefits
            </Heading>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
