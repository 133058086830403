import { CATEGORY_RADIUS, VALUE_CATEGORIES } from '../../constants';
import { getQuarterPath } from '../../utils';
import { getCategoryTextPathId } from './utils';

export const ValueCategoryTextPaths = () => (
  <defs>
    {VALUE_CATEGORIES.map(({ name }, index) => (
      <path
        key={name}
        id={getCategoryTextPathId(name)}
        d={getQuarterPath(CATEGORY_RADIUS, index)}
      />
    ))}
  </defs>
);
