import { gql, useQuery } from '@apollo/client';

import {
  OnboardReferenceType,
  Query,
  QueryOnboardManagementByReferenceArgs,
} from 'types';
import { OnboardManagementOutputDeepNestingFragment } from 'types/generated-fragments';

export const onboardManagementGql = gql`
  ${OnboardManagementOutputDeepNestingFragment}
  query OnboardManagementByReference(
    $referenceId: String!
    $referenceType: OnboardReferenceType!
  ) {
    onboardManagementByReference(
      referenceId: $referenceId
      referenceType: $referenceType
    ) {
      ...OnboardManagementOutputDeepNestingFragment
    }
  }
`;

export const useOnboardingManagementByReference = (
  referenceType: OnboardReferenceType,
  referenceId?: string
) => {
  const { data, loading, error } = useQuery<
    Pick<Query, 'onboardManagementByReference'>,
    Partial<QueryOnboardManagementByReferenceArgs>
  >(onboardManagementGql, {
    variables: {
      referenceId,
      referenceType,
    },
    skip: !referenceId,
    fetchPolicy: 'no-cache',
  });

  return {
    error,
    loading,
    onboardManagement: data?.onboardManagementByReference,
  };
};
