import { MenuItem } from '@chakra-ui/react';

import { ActionMenuLayout } from '../ActionMenuLayout';

type Props = {
  handleDelete: () => void;
};

export const MessagesActionMenu = ({ handleDelete }: Props): JSX.Element => {
  return (
    <ActionMenuLayout
      containerStyling={{
        pos: 'absolute',
        top: 0,
        right: 0,
        visibility: 'hidden',
      }}
    >
      <MenuItem onClick={handleDelete}>Delete</MenuItem>
    </ActionMenuLayout>
  );
};
