import { animated } from 'react-spring';

import { Box } from '@chakra-ui/react';

import {
  CATEGORY_POSITION,
  CATEGORY_RADIUS,
  FADE_ANIMATION_STATES,
} from '../../constants';
import { useCategoryTransition } from './useCategoryTransition';

export const AnimatedCategoryBox = () => {
  const transition = useCategoryTransition(FADE_ANIMATION_STATES);

  return transition(
    (styles, animatedCategory) =>
      animatedCategory && (
        <animated.div style={{ ...styles }}>
          <Box
            {...CATEGORY_POSITION[animatedCategory.position]}
            position="absolute"
            width={CATEGORY_RADIUS}
            height={CATEGORY_RADIUS}
            borderTopLeftRadius="100%"
            background={animatedCategory.mainColor}
            transform={`rotate(${animatedCategory.position * 90}deg)`}
            boxShadow="0px 0px 16px 0px #00000026 inset"
          />
        </animated.div>
      )
  );
};
