import { capitalize } from 'lodash';

import { Honorific } from 'types';

export const HONORIFIC_OPTIONS: { value: Honorific; label: string }[] = [
  { value: Honorific.Mr, label: capitalize(Honorific.Mr) },
  { value: Honorific.Mrs, label: capitalize(Honorific.Mrs) },
  { value: Honorific.Miss, label: capitalize(Honorific.Miss) },
  { value: Honorific.Mx, label: capitalize(Honorific.Mx) },
  { value: Honorific.Baron, label: capitalize(Honorific.Baron) },
  { value: Honorific.Baroness, label: capitalize(Honorific.Baroness) },
  { value: Honorific.Dr, label: capitalize(Honorific.Dr) },
  { value: Honorific.Dame, label: capitalize(Honorific.Dame) },
  { value: Honorific.Earl, label: capitalize(Honorific.Earl) },
  { value: Honorific.Lord, label: capitalize(Honorific.Lord) },
  { value: Honorific.Lady, label: capitalize(Honorific.Lady) },
  { value: Honorific.Prof, label: capitalize(Honorific.Prof) },
  { value: Honorific.Sir, label: capitalize(Honorific.Sir) },
  { value: Honorific.Sheikh, label: capitalize(Honorific.Sheikh) },
  { value: Honorific.Sultan, label: capitalize(Honorific.Sultan) },
];
