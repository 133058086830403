import {
  AvatarProps as ChakraAvatarProps,
  GridItemProps,
} from '@chakra-ui/react';

import { AvatarVariant } from './types';

export const textAlign: Record<AvatarVariant, 'start' | 'center'> = {
  author: 'start',
  horizontal: 'start',
  vertical: 'center',
  verticalCompact: 'center',
};

export const gridStyles: Record<AvatarVariant, GridItemProps> = {
  author: { gridAutoFlow: 'column', alignItems: 'center' },
  horizontal: { gridAutoFlow: 'column', alignItems: 'center' },
  vertical: {
    gridAutoFlow: 'row',
    alignItems: 'flex-start',
    gridTemplateRows: 'auto 1fr',
  },
  verticalCompact: {
    gridAutoFlow: 'row',
    alignItems: 'flex-start',
    gridTemplateRows: 'auto 1fr',
  },
};

export const AVATAR_IMAGE_STYLING_PROPS: Record<
  AvatarVariant,
  ChakraAvatarProps
> = {
  vertical: { w: 24, h: 24, alignSelf: 'flex-start' },
  verticalCompact: {
    w: 16,
    h: 16,
    alignSelf: 'flex-start',
  },
  horizontal: { w: 12, h: 12, alignSelf: 'flex-start' },
  author: { w: 7, h: 7, alignSelf: 'center' },
};
