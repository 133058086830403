import { AspectRatio, Image, Box, Button } from '@chakra-ui/react';

import { MediaOutput } from 'types';

type Props = {
  heroImage: MediaOutput | undefined;
  name: string;
  handleJoinGroup: () => void;
};
export const PreviewHero = ({ heroImage, handleJoinGroup, name }: Props) => (
  <Box position="relative">
    <AspectRatio ratio={3} pos="relative">
      <Image src={heroImage?.signedUrl} alt={name} position="relative" />
    </AspectRatio>

    <Button
      onClick={handleJoinGroup}
      variant="primaryLime"
      position="absolute"
      left={2}
      bottom={4}
    >
      Join Group
    </Button>
  </Box>
);
