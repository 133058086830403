import { useCallback } from 'react';

import { gql, useMutation } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import {
  Mutation,
  MutationSendOnboardNotificationArgs,
  OnboardNotificationEnum,
  OnboardReferenceType,
} from 'types';

export const sendOnboardNotificationGql = gql`
  mutation SendOnboardNotification($data: SendOnboardNotificationInput!) {
    sendOnboardNotification(data: $data)
  }
`;

export const useSendNotification = (
  referenceId: string,
  referenceType: OnboardReferenceType
) => {
  const [sendOnboardNotificationMutation] = useMutation<
    Pick<Mutation, 'sendOnboardNotification'>,
    MutationSendOnboardNotificationArgs
  >(sendOnboardNotificationGql, { refetchQueries: ['OnboardManagement'] });

  const sendMail = useCallback(
    async (notificationType: OnboardNotificationEnum, isTest = false) => {
      const { errors } = await sendOnboardNotificationMutation({
        variables: {
          data: {
            referenceId,
            referenceType,
            isTest,
            notificationType,
          },
        },
      });

      if (errors?.length) {
        throw new Error(errors[0]?.message);
      }
    },
    [sendOnboardNotificationMutation, referenceId, referenceType]
  );

  const sendMailWithToast = useWithMutationToast(sendMail, {
    successMessage: 'Mail sent successfully',
    errorMessage: 'Mail sent failed',
  });

  return {
    sendFamilyProfileCompletedEmail: (isTest = false) =>
      sendMailWithToast(
        OnboardNotificationEnum.FamilyProfileCompletedEmail,
        isTest
      ),
    sendSdgConfiguratorCompletedEmail: (isTest = false) =>
      sendMailWithToast(
        OnboardNotificationEnum.SdgConfiguratorCompletedEmail,
        isTest
      ),
    sendSdgConfiguratorInvitationEmail: (isTest = false) =>
      sendMailWithToast(
        OnboardNotificationEnum.SdgConfiguratorInvitationEmail,
        isTest
      ),
    sendRsvpEmail: (isTest = false) =>
      sendMailWithToast(OnboardNotificationEnum.RsvpEmail, isTest),
    sendAdvisorInvite: (isTest = false) =>
      sendMailWithToast(
        OnboardNotificationEnum.AdvisorProfileCompletedEmail,
        isTest
      ),
  };
};
