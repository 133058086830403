import { useMemo } from 'react';
import { animated, useSpringRef, useChain, useTransition } from 'react-spring';

import { usePrevious } from 'hooks/usePrevious';

import { FADE_ANIMATION_STATES, SLIDE_ANIMATION_STATES } from '../../constants';
import { useWheelState } from '../../contexts';
import { ValueDescriptionTooltip } from './ValueDescriptionTooltip';

const WRAPPER_PROPS = {
  width: '100%',
  height: '100%',
  position: 'absolute',
} as const;

export const AnimatedValueDescriptionTooltip = () => {
  const { selectedValue, selectedCategory } = useWheelState();

  const prevCategory = usePrevious(selectedCategory);
  const prevValue = usePrevious(selectedValue);

  const slideRef = useSpringRef();

  const transitionObject = useMemo(
    () => ({ selectedValue, selectedCategory }),
    [selectedCategory, selectedValue]
  );

  const transition = useTransition(transitionObject, {
    from: SLIDE_ANIMATION_STATES.off,
    enter: SLIDE_ANIMATION_STATES.on,
    leave: SLIDE_ANIMATION_STATES.off,
    immediate: prevCategory === selectedCategory && prevValue !== null,
    trail:
      prevCategory !== null && prevCategory !== selectedCategory
        ? 400
        : undefined,
    ref: slideRef,
  });

  const fadeRef = useSpringRef();

  const fadeAnimation = useTransition(selectedValue, {
    from: FADE_ANIMATION_STATES.off,
    enter: FADE_ANIMATION_STATES.on,
    leave: FADE_ANIMATION_STATES.off,
    reset: prevValue !== selectedValue,
    ref: fadeRef,
  });

  useChain([slideRef, fadeRef]);

  return transition(
    (
      styles,
      { selectedCategory: animatedCategory, selectedValue: animatedValue }
    ) =>
      animatedValue && animatedCategory ? (
        <animated.div style={{ ...styles, ...WRAPPER_PROPS }}>
          <ValueDescriptionTooltip
            category={animatedCategory}
            renderValue={(renderValue) =>
              fadeAnimation((animationStyle, animationValue) => (
                <animated.div style={animationStyle}>
                  {renderValue(animationValue)}
                </animated.div>
              ))
            }
          />
        </animated.div>
      ) : null
  );
};
