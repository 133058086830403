import { useCallback, MouseEvent, SyntheticEvent } from 'react';

import { Close16 } from '@carbon/icons-react';
import { Tag, TagLabel, TagProps, IconButton } from '@chakra-ui/react';

export type Props = {
  children: string;
  onRemove(event: SyntheticEvent): void;
} & TagProps;

export const ItemTag = ({
  children,
  onRemove,
  ...props
}: Props): JSX.Element => {
  const handleClickTagCloseButton = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (event.isDefaultPrevented()) return;
      onRemove(event);
    },
    [onRemove]
  );

  return (
    <Tag
      {...props}
      bgColor="#E0E0E0"
      alignItems="center"
      borderRadius={50}
      aria-label="tag"
    >
      <TagLabel fontSize="Roboto" fontWeight={400}>
        {children}
      </TagLabel>
      <IconButton
        bg="inherit"
        borderColor="#E0E0E0"
        borderRadius={50}
        height="auto"
        minWidth={5}
        ml={1}
        aria-label="remove-tag"
        icon={<Close16 fill="#161616" />}
        onClick={handleClickTagCloseButton}
      />
    </Tag>
  );
};
