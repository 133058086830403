import { WelcomeLayout } from 'components/Layout/WelcomeLayout';
import { Cta } from 'features/Onboarding/components/Cta';

export const OtpSetPasswordSuccessPage = () => {
  return (
    <WelcomeLayout title="The Values Configurator">
      <Cta
        title="Password added successfully"
        subtitle="Thank you and welcome"
      />
    </WelcomeLayout>
  );
};
