import { gql, useQuery } from '@apollo/client';

import { Query } from 'types';
import { LinkMetadataFragment } from 'types/generated-fragments';

export const openGraphMetadataGql = gql`
  ${LinkMetadataFragment}
  query openGraphMetadata($url: String!) {
    openGraphMetadata(url: $url) {
      ...LinkMetadataFragment
    }
  }
`;

export const useOpenGraphMetadata = (url: string) => {
  const { data, loading, error } = useQuery<Pick<Query, 'openGraphMetadata'>>(
    openGraphMetadataGql,
    { variables: { url }, fetchPolicy: 'cache-and-network' }
  );

  return { metadata: data?.openGraphMetadata, loading, error };
};
