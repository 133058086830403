import { gql, useMutation } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationUnAttendToEventArgs } from 'types';

import { eventGql } from './useEvent';
import { eventsGql } from './useEvents';

const unAttendToEventGql = gql`
  mutation UnAttendToEvent($id: String!) {
    unAttendToEvent(id: $id)
  }
`;

export const useUnAttendToEvent = (eventId: string) => {
  const [unAttendToEventMutation, { loading }] = useMutation<
    Pick<Mutation, 'unAttendToEvent'>,
    MutationUnAttendToEventArgs
  >(unAttendToEventGql, { refetchQueries: [eventGql, eventsGql] });

  const handleUnAttendToEvent = () =>
    unAttendToEventMutation({ variables: { id: eventId } });

  const unAttendToEvent = useWithMutationToast(handleUnAttendToEvent, {
    successMessage: 'Unattend event',
    errorMessage: 'Failed unanattend event',
  });

  return { unAttendToEvent, loading };
};
