import { useTranslation } from 'react-i18next';

import { Text, Flex } from '@chakra-ui/react';

import { Post } from 'types';

import { PostCard } from './PostCard';
import { SystemMessagePostCard } from './PostCard/SystemMessagePostCard';

type Props = {
  posts?: Post[];
};

export const PostList = ({ posts }: Props) => {
  const { t } = useTranslation(['network']);

  if (!posts || !posts?.length) {
    return <Text>{t('network:post-list-empty-state')}</Text>;
  }

  return (
    <Flex gap={4} direction="column">
      {posts.map((post) =>
        post.subtype ? (
          <SystemMessagePostCard post={post} key={post.id} />
        ) : (
          <PostCard post={post} key={post.id} />
        )
      )}
    </Flex>
  );
};
