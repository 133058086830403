import { DocumentNode, gql } from '@apollo/client';

import {
  EventKeySpeakerOutputFragment,
  EventNoNestingFragment,
  EventVisibilityDetailsFragment,
  FamilyCharterGoalOutputFragment,
  FamilyCharterHeroOutputFragment,
  FamilyCharterNoNestingFragment,
  FamilyCharterValuesOutputFragment,
  FamilyValuesOutputFragment,
  GroupNoNestingFragment,
  InitiativeFullMediaBlockOutputFragment,
  InitiativeGalleryBlockOutputFragment,
  InitiativeHeadingBlockOutputFragment,
  InitiativeLeftTextBlockOutputFragment,
  InitiativeRichTextBlockOutputFragment,
  InitiativeRightTextBlockOutputFragment,
  InsightFullMediaBlockOutputFragment,
  InsightGalleryBlockOutputFragment,
  InsightHeadingBlockOutputFragment,
  InsightHeroOutputDeepNestingFragment,
  InsightLeftTextBlockOutputFragment,
  InsightNoNestingFragment,
  InsightQuoteBlockOutputFragment,
  InsightRichTextBlockOutputFragment,
  InsightRightTextBlockOutputFragment,
  InsightVisibilityDetailsOutputFragment,
  ItriomTagsV2OutputFragment,
  LocationDeepNestingFragment,
  OrganiserFragment,
  PrincipalAboutOutputFragment,
  PrincipalHeroOutputFragment,
  PrincipalPrivacySettingsOutputNoNestingFragment,
  ProfileNoNestingFragment,
  TimezoneDateOutputFragment,
} from 'types/generated-fragments';

export const AvatarShortCut = `
  avatarImage {
    ...MediaOutput
  }
`;
export const HeroShortCut = `
  heroImage {
    ...MediaOutput
  }
`;

export const PrincipalOutputDeepNestingFragment = gql`
  ${PrincipalHeroOutputFragment}
  ${PrincipalAboutOutputFragment}
  ${PrincipalPrivacySettingsOutputNoNestingFragment}
  fragment PrincipalOutputDeepNestingFragment on PrincipalOutput {
    id
    hero {
      ...PrincipalHeroOutputFragment
    }
    about {
      ...PrincipalAboutOutputFragment
    }
    createdAt
    createdBy
    # familyCharter {
    #   ...FamilyCharterNoNestingFragment
    # }
    isHeadOfFamily
    # memberRelations{
    #   ...FamilyMemberRelationOutputNoNestingFragment
    # }
    privacySettings {
      ...PrincipalPrivacySettingsOutputNoNestingFragment
    }
    status
    # tags {
    #   ...ItriomTagsOutputDeepNestingFragment
    # }
    updatedAt
    updatedBy
    # values {
    #   ...PrincipalValueOutputFragment
    # }
  }
`;

export const MediaOutput = gql`
  fragment MediaOutput on MediaOutput {
    id
    signedUrl
  }
`;

export const ProfileWithImages = gql`
    ${ProfileNoNestingFragment}
    ${MediaOutput}
    fragment ProfileWithImages on Profile {
        ...ProfileNoNestingFragment
        ${AvatarShortCut}
        ${HeroShortCut}
    }
`;

export const Profile = gql`
  ${MediaOutput}
  ${PrincipalOutputDeepNestingFragment}
  ${FamilyValuesOutputFragment}
  fragment Profile on Profile {
    id
    createdAt
    updatedAt
    avatarImage {
      ...MediaOutput
    }
    heroImage {
      ...MediaOutput
    }
    firstName
    lastName
    title
    description
    role
    family {
      id
      values {
        ...FamilyValuesOutputFragment
      }
    }
    principal {
      ...PrincipalOutputDeepNestingFragment
    }
  }
`;

export const ProfileFlat = gql`
  ${MediaOutput}
  fragment ProfileFlat on Profile {
    id
    createdAt
    updatedAt
    avatarImage {
      ...MediaOutput
    }
    heroImage {
      ...MediaOutput
    }
    firstName
    lastName
    title
    description
    family {
      id
      name
    }
  }
`;

export const GroupWithImages = gql`
    ${GroupNoNestingFragment}
    ${MediaOutput}
    fragment GroupWithImages on Group {
        ...GroupNoNestingFragment
        ${AvatarShortCut}
        ${HeroShortCut}
    }
`;

export const RelatedArticle = gql`
  ${MediaOutput}
  fragment RelatedArticle on Insight {
    id
    hero {
      headline
      heroImage {
        ...MediaOutput
      }
      author
      summary
    }
  }
`;

export const InitiativeBlocksFragment = gql`
  ${InitiativeFullMediaBlockOutputFragment}
  ${InitiativeGalleryBlockOutputFragment}
  ${InitiativeHeadingBlockOutputFragment}
  ${InitiativeLeftTextBlockOutputFragment}
  ${InitiativeRichTextBlockOutputFragment}
  ${InitiativeRightTextBlockOutputFragment}
  fragment InitiativeBlocksFragment on InitiativeBlockOutput {
    ... on InitiativeFullMediaBlockOutput {
      ...InitiativeFullMediaBlockOutputFragment
    }
    ... on InitiativeGalleryBlockOutput {
      ...InitiativeGalleryBlockOutputFragment
    }
    ... on InitiativeHeadingBlockOutput {
      ...InitiativeHeadingBlockOutputFragment
    }
    ... on InitiativeLeftTextBlockOutput {
      ...InitiativeLeftTextBlockOutputFragment
    }
    ... on InitiativeRichTextBlockOutput {
      ...InitiativeRichTextBlockOutputFragment
    }
    ... on InitiativeRightTextBlockOutput {
      ...InitiativeRightTextBlockOutputFragment
    }
  }
`;

const InsightBlocksFragment = gql`
  ${InsightFullMediaBlockOutputFragment}
  ${InsightGalleryBlockOutputFragment}
  ${InsightHeadingBlockOutputFragment}
  ${InsightLeftTextBlockOutputFragment}
  ${InsightRichTextBlockOutputFragment}
  ${InsightRightTextBlockOutputFragment}
  ${InsightQuoteBlockOutputFragment}
  fragment InsightBlocksFragment on InsightBlockOutput {
    ... on InsightFullMediaBlockOutput {
      ...InsightFullMediaBlockOutputFragment
    }
    ... on InsightGalleryBlockOutput {
      ...InsightGalleryBlockOutputFragment
    }
    ... on InsightHeadingBlockOutput {
      ...InsightHeadingBlockOutputFragment
    }
    ... on InsightLeftTextBlockOutput {
      ...InsightLeftTextBlockOutputFragment
    }
    ... on InsightRichTextBlockOutput {
      ...InsightRichTextBlockOutputFragment
    }
    ... on InsightRightTextBlockOutput {
      ...InsightRightTextBlockOutputFragment
    }
    ... on InsightQuoteBlockOutput {
      ...InsightQuoteBlockOutputFragment
    }
  }
`;

export const InsightDeepNestingFragment = gql`
  ${MediaOutput}
  ${RelatedArticle}
  ${ItriomTagsV2OutputFragment}
  ${InsightNoNestingFragment}
  ${InsightHeroOutputDeepNestingFragment}
  ${ProfileFlat}
  ${InsightBlocksFragment}
  ${InsightVisibilityDetailsOutputFragment}
  fragment InsightDeepNestingFragment on Insight {
    ...InsightNoNestingFragment
    relatedArticles {
      ...RelatedArticle
    }
    hero {
      ...InsightHeroOutputDeepNestingFragment
    }
    visibilityDetails {
      ...InsightVisibilityDetailsOutputFragment
    }
    tags {
      ...ItriomTagsV2OutputFragment
    }
    writtenBy {
      ...ProfileFlat
    }
    blocks {
      ...InsightBlocksFragment
    }
  }
`;

export const CallToAction = (
  output: 'JoinOutput' | 'HelpRequestOutput'
): DocumentNode => gql`
  ${MediaOutput}
  fragment CTA${output} on ${output} {
    image {
      ...MediaOutput
    }
    name
    description
    email
    isEnabled
  }
`;

export const Event = gql`
  ${CallToAction('JoinOutput')}
  ${CallToAction('HelpRequestOutput')}
  ${MediaOutput}
  ${OrganiserFragment}
  ${EventVisibilityDetailsFragment}
  ${ProfileWithImages}
  ${ItriomTagsV2OutputFragment}
  ${LocationDeepNestingFragment}
  ${EventNoNestingFragment}
  ${TimezoneDateOutputFragment}
  ${EventKeySpeakerOutputFragment}
  fragment Event on Event {
    ...EventNoNestingFragment
    hero {
      name
      summary
      description
      heroImage {
        ...MediaOutput
      }
      heroVideo {
        ...MediaOutput
      }
    }
    basicInfo {
      startTime {
        ...TimezoneDateOutputFragment
      }
      endTime {
        ...TimezoneDateOutputFragment
      }
      location {
        ...LocationDeepNestingFragment
      }
    }

    topics {
      description
      heroImage {
        ...MediaOutput
      }
    }

    keySpeaker {
      ...EventKeySpeakerOutputFragment
    }
    agenda {
      heading
      description
      image {
        ...MediaOutput
      }
      agendaItems {
        heading
        description
        image {
          ...MediaOutput
        }
        startTime
        endTime
        speakers {
          profile {
            ...ProfileWithImages
          }
          isKeySpeaker
        }
      }
    }

    organisers {
      ...OrganiserFragment
      profile {
        ...ProfileWithImages
      }
    }
    sponsors {
      name
      title
      showName
      isCompany
      profile {
        ...ProfileWithImages
      }
      image {
        ...MediaOutput
      }
    }
    objectives {
      heading
      description
    }
    callToAction {
      joinInfo {
        ...CTAJoinOutput
      }
      helpRequest {
        ...CTAHelpRequestOutput
      }
    }
    ultimateGoal {
      title
      description
      sdIcon
      image {
        ...MediaOutput
      }
    }
    tags {
      ...ItriomTagsV2OutputFragment
    }
    visibilityDetails {
      ...EventVisibilityDetailsFragment
    }
  }
`;

export const FamilyCharterDeepNestingFragment = gql`
  ${FamilyCharterGoalOutputFragment}
  ${FamilyCharterHeroOutputFragment}
  ${FamilyCharterValuesOutputFragment}
  ${FamilyCharterNoNestingFragment}
  fragment FamilyCharterDeepNestingFragment on FamilyCharter {
    ...FamilyCharterNoNestingFragment
    hero {
      ...FamilyCharterHeroOutputFragment
    }
    goals {
      ...FamilyCharterGoalOutputFragment
    }
    values {
      ...FamilyCharterValuesOutputFragment
    }
  }
`;

export const GroupInternalFragment = gql`
  ${MediaOutput}

  fragment GroupInternalFragment on Group {
    name
    category
    id
    type
    isMember
    familyId
    familyOnly
    createdBy
    owner {
      id
    }
    avatarImage {
      ...MediaOutput
    }
    heroImage {
      ...MediaOutput
    }
  }
`;
