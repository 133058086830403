import { useNavigate } from 'react-router-dom';

import { Flex } from '@chakra-ui/layout';
import { Heading, Text, Image, Skeleton } from '@chakra-ui/react';

import { ViewButton } from 'components/Button';
import { QueryStateGuard } from 'components/QueryStateGuard';
import { useUpcomingEvent } from 'hooks/events';
import { Links } from 'router/links';
import { underlay } from 'theme/mixins/underlay';
import { Event } from 'types';
import { formatDate } from 'utils/date';
import { generateUrlById } from 'utils/links';

const Content = ({ event }: { event: Event }) => {
  const startTime = event.basicInfo?.startTime
    ? new Date(event.basicInfo.startTime.datetime)
    : null;

  const link = generateUrlById(Links.event, event.id);
  const navigate = useNavigate();

  const handleEventClick = () => {
    navigate(link);
  };

  return (
    <Flex
      onClick={handleEventClick}
      color="ui.white"
      pos="relative"
      cursor="pointer"
      w="full"
      h="full"
      {...underlay({ direction: 'column' })}
    >
      <Image
        src={event.hero.heroImage?.signedUrl}
        w="full"
        h="full"
        objectFit="cover"
        objectPosition="center"
      />
      <Flex
        direction="column"
        pos="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={1}
        p={4}
      >
        <Heading size="h5" mb="auto" h={62}>
          Attending Event
        </Heading>
        {startTime && (
          <>
            <Heading size="h1" maxW={200}>
              {formatDate(startTime, 'do')}
            </Heading>
            <Heading size="h3" mb={4} maxW={200}>
              {formatDate(startTime, 'MMMM.')}
            </Heading>
          </>
        )}
        <Text size="p1" mb={6}>
          {event.hero.name}
        </Text>
        <ViewButton to={link}>View event</ViewButton>
      </Flex>
    </Flex>
  );
};

const LoaderComponent = () => <Skeleton w="full" h="full" />;

export const UpcomingEvent = () => {
  const { upcomingEvent, loading, error } = useUpcomingEvent();

  return (
    <Flex
      h="full"
      w="full"
      direction="column"
      gap={2}
      bgColor="ui.white"
      borderRadius={4}
      overflow="hidden"
    >
      <QueryStateGuard
        loading={loading}
        error={error}
        errorTitle="Cannot fetch upcoming event"
        LoaderComponent={LoaderComponent}
      >
        {upcomingEvent ? (
          <Content event={upcomingEvent} />
        ) : (
          <Text p={4}>There is no event to show</Text>
        )}
      </QueryStateGuard>
    </Flex>
  );
};
