import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { Menu24 } from '@carbon/icons-react';
import {
  Avatar,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';

import { useHasConnections } from 'hooks/connections/useHasConnections';
import { isLinkMenuItem } from 'router/guards';
import { Account } from 'types';
import { getAvatarSrc } from 'utils/file';

import { MobileDropdownItems } from '../../MobileDropdownItems';
import { AccountMenu } from './AccountMenu';

type Props = {
  account?: Account;
  isMobile?: boolean;
};

export const UserMenu = ({ account, isMobile }: Props) => {
  const { filterMenuLinks } = useHasConnections();
  const navMenuLinks = filterMenuLinks();

  const menuButton = useMemo(() => {
    if (isMobile) {
      return (
        <MenuButton
          as={IconButton}
          aria-label="mobile-menu"
          variant="outline"
          p={2}
        >
          <Menu24 />
        </MenuButton>
      );
    }

    if (account) {
      return (
        <MenuButton aria-label="desktop-menu">
          <Avatar src={getAvatarSrc(account.avatarImage)} size="sm" />
        </MenuButton>
      );
    }

    return null;
  }, [account, isMobile]);

  return (
    <Menu>
      {menuButton}

      <MenuList zIndex="dropdown">
        {isMobile &&
          navMenuLinks.map((menuItem) => {
            if (isLinkMenuItem(menuItem)) {
              const { label, link } = menuItem;

              return (
                <MenuItem key={label} as={Link} to={link}>
                  {label}
                </MenuItem>
              );
            }

            return (
              <MobileDropdownItems menuItem={menuItem} key={menuItem.label} />
            );
          })}
        {account && <AccountMenu account={account} />}
      </MenuList>
    </Menu>
  );
};
