import { FormikProvider } from 'formik';

import { useTranslation } from 'react-i18next';

import { Button, Flex, Text } from '@chakra-ui/react';

import { ItriomButtonOutline } from 'components/Button';
import { SelectInput } from 'components/Form/SelectInput';
import { Modal } from 'components/Modal';
import { useEntityNameSelectFormik } from 'features/Cms/hooks';
import { ReassignActionType } from 'features/Cms/types';
import { useExtendedProfiles } from 'hooks/profiles/useExtendedProfiles';
import { AccountStatus, ExtendedProfileOutput, Role } from 'types';
import { getFullName } from 'utils/string';

import { NEW_FAMILY_HEAD_ID_FIELD_NAME } from '../constants';

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
  onConfirm: (memberId: string) => void;
  familyId?: string;
  action?: ReassignActionType;
  currentHead: Pick<ExtendedProfileOutput, 'id'>;
};

export const AssignFamilyHeadModal = ({
  isOpen,
  onClose,
  onConfirm,
  familyId,
  action = 'deactivate',
  currentHead,
}: Props) => {
  const { t } = useTranslation(['cms', 'common']);

  const { extendedProfiles, loading, error } = useExtendedProfiles(familyId);
  const formik = useEntityNameSelectFormik(NEW_FAMILY_HEAD_ID_FIELD_NAME);
  const newFamilyHeadUserId = formik.values[NEW_FAMILY_HEAD_ID_FIELD_NAME];

  const profileOptions = extendedProfiles
    ? extendedProfiles
        .filter(
          (profile) =>
            profile.id !== currentHead.id &&
            profile.status === AccountStatus.Active &&
            profile.role === Role.FamilyMember
        )
        .map((profile) => ({
          value: profile.id,
          label: getFullName(profile.firstName, profile.lastName),
        }))
    : [];

  const handleConfirm = () => {
    if (newFamilyHeadUserId) {
      onConfirm(newFamilyHeadUserId);
    }
  };

  return (
    <FormikProvider value={formik}>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={t('cms:family.change-head.title')}
        containerStyling={{ p: 4 }}
        footer={
          <Flex w="full" justify="space-between">
            <Button
              onClick={handleConfirm}
              isDisabled={!newFamilyHeadUserId}
              size="lg"
              color="ui.white"
              background="status.green.default"
              borderColor="status.green.default"
            >
              {t('common:save')}
            </Button>
            <ItriomButtonOutline onClick={onClose}>
              {t('common:cancel')}
            </ItriomButtonOutline>
          </Flex>
        }
      >
        <Flex direction="column" gap={8}>
          <Text size="p1">
            {t('cms:family.change-head.content', {
              action: t(`cms:${action}.action`).toLowerCase(),
            })}
          </Text>
          <SelectInput
            name={NEW_FAMILY_HEAD_ID_FIELD_NAME}
            label={t('cms:family.change-head.select-label')}
            options={profileOptions}
            loading={loading}
            error={error}
          />
        </Flex>
      </Modal>
    </FormikProvider>
  );
};
