import { useMessagingCommunication } from '../MessagingCommunication';
import { useConversationsActions } from '../MessagingStateManager/hooks';

export const useLoadMoreConversations = () => {
  const { loadMoreConversations } = useMessagingCommunication();

  const { addConversation, setConversationsTotalCount } =
    useConversationsActions();

  const handleLoadMoreConversations = () =>
    loadMoreConversations().then((conversations) => {
      conversations.nodes.forEach((conversation) =>
        addConversation(conversation)
      );

      setConversationsTotalCount(conversations.totalCount);
    });

  return handleLoadMoreConversations;
};
