import { debounce } from 'lodash';

import { useMemo } from 'react';

import { Box, useDisclosure } from '@chakra-ui/react';

import { ItriomMenuButton } from 'components/Button';
import { ClearableSearchInput } from 'components/Form/ClearableSearchInput';
import { Modal } from 'components/Modal';
import { QueryStateGuard } from 'components/QueryStateGuard';
import { useMembersToAddToGroup } from 'hooks/groups';

import { AddMemberRow } from './AddMemberRow';

type Props = { groupId: string };

export const AddMembersModal = ({ groupId }: Props) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { error, loading, membersToAdd, handleFetchUsers } =
    useMembersToAddToGroup();

  const debouncedFetchUsers = useMemo(
    () =>
      debounce((searchQuery) => {
        handleFetchUsers({ groupId, memberName: searchQuery });
      }, 300),
    [groupId, handleFetchUsers]
  );

  return (
    <>
      <ItriomMenuButton onClick={onOpen}>Add Members</ItriomMenuButton>
      <Modal isOpen={isOpen} onClose={onClose} title="Add members">
        <QueryStateGuard error={error}>
          <ClearableSearchInput
            placeholder="Add Member"
            onQueryChange={debouncedFetchUsers}
            loading={loading}
          />

          <Box p={2} left={0} right={0} maxH={200} overflowY="scroll">
            {membersToAdd.map((profile) => (
              <AddMemberRow
                key={profile.id}
                profile={profile}
                groupId={groupId}
              />
            ))}
            {!membersToAdd.length && <Box textAlign="center">No Results</Box>}
          </Box>
        </QueryStateGuard>
      </Modal>
    </>
  );
};
