import i18n from 'i18n/config';
import { string, object } from 'yup';

import { ErrorMessages, Articles } from 'utils/validation';

export const getPhoneFormValidation = (
  countryCodeFieldName: string,
  baseNumberFieldName: string
) =>
  object().shape({
    [countryCodeFieldName]: string()
      .trim()
      .required(
        ErrorMessages.IS_REQUIRED_POLITELY(
          Articles.A,
          i18n.t('common:phone-country-code')
        )
      ),
    [baseNumberFieldName]: string()
      .trim()
      .required(
        ErrorMessages.IS_REQUIRED_POLITELY(
          Articles.A,
          i18n.t('common:phone-number')
        )
      ),
  });
