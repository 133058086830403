import { useField } from 'formik';

import { StackDivider, VStack } from '@chakra-ui/layout';

import { UploadProps, PreviewBaseProps, UploadFileType } from '../types';
import { getFileKey } from '../utils';
import { FilePreview } from './FilePreview';

type Props = PreviewBaseProps &
  Pick<UploadProps, 'name'> & {
    isImage: boolean;
  };

export const UploadPreview = ({ name, isImage, ...previewProps }: Props) => {
  const [{ value }, , { setValue }] = useField<
    UploadFileType | UploadFileType[] | undefined | null
  >({ name });

  if (!value) return null;

  const validFiles = (Array.isArray(value) ? value : [value]).filter(
    (v): v is UploadFileType => !!v
  );

  if (!validFiles.length) return null;

  const removeFile = (index: number) => {
    const newFields = [...validFiles];
    newFields.splice(index, 1);

    setValue(newFields.length ? newFields : null);
  };

  return (
    <VStack
      divider={<StackDivider borderColor="gray.400" />}
      gridGap={2}
      bg="lightGrey3"
      justify="space-between"
      align="flex-start"
      maxWidth="256px"
      p={4}
    >
      {validFiles.map((file, fileIndex) => (
        <FilePreview
          key={getFileKey(file) || fileIndex}
          file={file}
          {...previewProps}
          isImage={isImage}
          index={fileIndex}
          onDelete={() => removeFile(fileIndex)}
        />
      ))}
    </VStack>
  );
};
