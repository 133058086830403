import { gql, MutationHookOptions, useMutation } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationUpdatePasswordArgs } from 'types';

export const updatePasswordGql = gql`
  mutation UpdatePassword($data: UpdatePasswordInput!) {
    updatePassword(data: $data)
  }
`;

type TData = Pick<Mutation, 'updatePassword'>;
type TVariables = MutationUpdatePasswordArgs;

export const useUpdatePassword = (
  options?: MutationHookOptions<TData, TVariables>
) => {
  const [mutation] = useMutation<TData, TVariables>(updatePasswordGql, options);

  const handleUpdate = (variables: MutationUpdatePasswordArgs) =>
    mutation({ variables });

  const updatePassword = useWithMutationToast(handleUpdate, {
    successMessage: 'Password updated successfully',
    errorMessage: 'Error updating password',
  });

  return { updatePassword };
};
