export enum InitiativeType {
  All = 'All',
  Family = 'family',
  Joint = 'joint',
  Peer = 'peer',
  Public = 'public',
  Followed = 'followed',
}

export const TABS_WITH_FOLLOW_SECTION = [InitiativeType.Followed];
