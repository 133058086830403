import { Flex, Text } from '@chakra-ui/react';

import { HeroMedia } from 'components/HeroMedia';
import { LayoutGrid, LayoutGridElement } from 'components/Layout/Grid';

import { MailLink, Paragraph, WebsiteLink } from '../components';

const TermsOfUseContent = () => (
  <Flex direction="column" justify="flex-start">
    <Paragraph heading="Introduction">
      These terms and conditions apply between you, the User of this Platform
      (including any sub-domains, unless expressly excluded by their own terms
      and conditions), and Itriom Limited, the owner and operator of this
      Platform. Please read these terms and conditions carefully, as they affect
      your legal rights. Your agreement to comply with and be bound by these
      terms and conditions is deemed to occur upon your first use of the
      Platform. If you do not agree to be bound by these terms and conditions,
      you should stop using the Platform immediately.
      <br /> <br />
      In these terms and conditions, User or Users means any third party that
      accesses the Platform and is not either (i) employed by Itriom Limited and
      acting in the course of their employment or (ii) engaged as a consultant
      or otherwise providing services to Itriom Limited and accessing the
      Platform in connection with the provision of such services.
    </Paragraph>

    <Paragraph heading="Intellectual property and acceptable use">
      1. All Content included on the Platform, unless uploaded by Registered
      Platform Users, is the property of Itriom Limited, our affiliates or other
      relevant third parties. In these terms and conditions, Content means any
      text, graphics, images, audio, video, software, data compilations, page
      layout, underlying code and software and any other form of information
      capable of being stored in a computer that appears on or forms part of
      this Platform, including any such content uploaded by Users. By continuing
      to use the Platform you acknowledge that such Content is protected by
      copyright, trademarks, database rights and other intellectual property
      rights. Nothing on this site shall be construed as granting, by
      implication, estoppel, or otherwise, any license or right to use any
      trademark, logo or service mark displayed on the site without the owner’s
      prior written permission.
      <br /> <br /> <br />
      2. You may, for your own personal, non-commercial use only, do the
      following:
      <br /> <br />
      a. retrieve, display and view the Content on a computer screen
      <br /> <br />
      b. download and store the Content in electronic form on a disk (but not on
      any server or other storage device connected to a network)
      <br /> <br />
      c. print one copy of the Content.
      <br /> <br /> <br />
      3. You must not otherwise reproduce, modify, copy, distribute or use for
      commercial purposes any Content without the written permission of Itriom
      Limited.
      <br /> <br /> <br />
      4. You acknowledge that you are responsible for any Content you may submit
      via the Platform, including the legality, reliability, appropriateness,
      originality and copyright of any such Content. You may not upload to,
      distribute or otherwise publish through the Platform any Content that (i)
      is confidential, proprietary, false, fraudulent, libellous, defamatory,
      obscene, threatening, invasive of privacy or publicity rights, infringing
      on intellectual property rights, abusive, illegal or otherwise
      objectionable; (ii) may constitute or encourage a criminal offence,
      violate the rights of any party or otherwise give rise to liability or
      violate any law; or (iii) may contain software viruses, political
      campaigning, chain letters, mass mailings, or any form of “spam.” You may
      not use a false email address or other identifying information,
      impersonate any person or entity or otherwise mislead as to the origin of
      any content. You may not upload commercial content onto the Platform.
      <br /> <br /> <br />
      5. You represent and warrant that you own or otherwise control all the
      rights to the Content you post; that the Content is accurate; that use of
      the Content you supply does not violate any provision of these terms and
      conditions and will not cause injury to any person; and that you will
      indemnify Itriom Limited for all claims resulting from Content you supply.
    </Paragraph>

    <Paragraph heading="Prohibited use">
      6. You may not use the Platform for any of the following purposes:
      <br /> <br />
      <Text ml={2} size="p1">
        a. in any way which causes, or may cause, damage to the Platform or
        interferes with any other person’s use or enjoyment of the Platform;
        <br /> <br />
        b. in any way which is harmful, unlawful, illegal, abusive, harassing,
        threatening or otherwise objectionable or in breach of any applicable
        law, regulation, governmental order;
        <br /> <br />
        c. making, transmitting or storing electronic copies of Content
        protected by copyright without the permission of the owner.
      </Text>
    </Paragraph>

    <Paragraph heading="Registration">
      7. You must ensure that the details provided by you on registration as
      part of your platform onboarding or at any time are correct and complete.
      <br /> <br />
      8. You must inform us immediately of any changes to the information that
      you provide when registering for the Platform by updating your personal
      details to ensure we can communicate with you effectively.
      <br /> <br />
      9. We may suspend or cancel your Platform registration with immediate
      effect for any reasonable purposes or if you breach these terms and
      conditions.
      <br /> <br />
      10. You may cancel your registration in line with the agreements held
      under the Master Services Agreement by informing us in writing to the
      address at the end of these terms and conditions. Cancellation or
      suspension of your registration does not affect any statutory rights.
    </Paragraph>

    <Paragraph heading="Password and security">
      11. When you register on this Platform, you will be asked to create a
      password, which you should keep confidential and not disclose or share
      with anyone.
      <br /> <br />
      12. If we have reason to believe that there is or is likely to be any
      misuse of the Platform or breach of security, we may require you to change
      your password or suspend your account.
    </Paragraph>

    <Paragraph heading="Links to other Platforms">
      13. This Platform may contain links to other sites. Unless expressly
      stated, these sites are not under the control of Itriom Limited or that of
      our affiliates.
      <br /> <br />
      14. We assume no responsibility for the content of such websites and
      disclaim liability for any and all forms of loss or damage arising out of
      the use of them.
      <br /> <br />
      15. The inclusion of a link to another site on this Platform does not
      imply any endorsement of the sites themselves or of those in control of
      them.
    </Paragraph>

    <Paragraph heading="Privacy Policy and Cookies Policy">
      16. Use of the Platform is also governed by our Privacy Policy and Cookies
      Policy, which are incorporated into these terms and conditions by this
      reference. To view the Privacy Policy and Cookies Policy, please click on
      the following: www. itriom/privacy-policy and www.itriom/cookie-policy.
    </Paragraph>

    <Paragraph heading="Availability of the Platform and disclaimers">
      17. Any online facilities, tools, services or information that Itriom
      Limited makes available through the Platform (the Service) is provided “as
      is” and on an “as available” basis. We give no warranty that the Service
      will be free of defects and/or faults. To the maximum extent permitted by
      the law, we provide no warranties (express or implied) of fitness for a
      particular purpose, accuracy of information, compatibility and
      satisfactory quality. Itriom Limited is under no obligation to update
      information on the Platform.
      <br /> <br />
      18. Whilst Itriom Limited uses reasonable endeavours to ensure that the
      Platform is secure and free of errors, viruses and other malware, we give
      no warranty or guaranty in that regard and all Users take responsibility
      for their own security, that of their personal details and their
      computers.
      <br /> <br />
      19. Itriom Limited accepts no liability for any disruption or
      non-availability of the Platform.
      <br /> <br />
      20. Itriom Limited reserves the right to alter, suspend or discontinue any
      part (or the whole of) the Platform including, but not limited to, any
      products and/or services available. These terms and conditions shall
      continue to apply to any modified version of the Platform unless it is
      expressly stated otherwise.
    </Paragraph>

    <Paragraph heading="Limitation of liability">
      21. Nothing in these terms and conditions will: (a) limit or exclude our
      or your liability for death or personal injury resulting from our or your
      negligence, as applicable; (b) limit or exclude our or your liability for
      fraud or fraudulent misrepresentation; or (c) limit or exclude any of our
      or your liabilities in any way that is not permitted under applicable law.
      <br /> <br />
      22. We will not be liable to you in respect of any losses arising out of
      events beyond our reasonable control.
      <br /> <br />
      23. To the maximum extent permitted by law, Itriom Limited accepts no
      liability for any of the following:
      <br /> <br />
      a. any business losses, such as loss of profits, income, revenue,
      anticipated savings, business, contracts, goodwill or commercial
      opportunities;
      <br /> <br />
      b. loss or corruption of any data, database or software;
      <br /> <br />
      c. any special, indirect or consequential loss or damage.
    </Paragraph>

    <Paragraph heading="Content Moderation and Reporting">
      24. You are responsible for all Content that you post to the Community.
      Itriom does not control or take responsibility for this Content or any
      other Content posted by other users.
      <br /> <br />
      Itriom reserves the right to moderate Community activity and may remove or
      edit any Content for any reason, including but not limited to Content
      that, in Itriom's sole discretion, violates these Community Terms. Itriom
      may remove or modify such Content without prior notice to the responsible
      user.
      <br /> <br />
      Reminder: Use of the Community that conflicts with the Terms may result in
      the immediate termination or suspension of accounts.
      <br /> <br />
      Please help Itriom protect the integrity of the Community and ensure that
      the Community remains as useful as possible by reporting any inappropriate
      user activity or postings in the Community. Inappropriate Content or
      activity can be reported by reporting concern direct to Itriom at{' '}
      <MailLink />
    </Paragraph>

    <Paragraph heading="General">
      25. You may not transfer any of your rights under these terms and
      conditions to any other person. We may transfer our rights under these
      terms and conditions where we reasonably believe your rights will not be
      affected.
      <br /> <br />
      26. These terms and conditions may be varied by us from time to time. Such
      revised terms will apply to the Platform from the date of publication.
      Users should check the terms and conditions regularly to ensure
      familiarity with the then current version.
      <br /> <br />
      27. These terms and conditions together with the Privacy Policy and
      Cookies Policy contain the whole agreement between the parties relating to
      its subject matter and supersede all prior discussions, arrangements or
      agreements that might have taken place in relation to the terms and
      conditions.
      <br /> <br />
      28. The Contracts (Rights of Third Parties) Act 1999 shall not apply to
      these terms and conditions and no third party will have any right to
      enforce or rely on any provision of these terms and conditions.
      <br /> <br />
      29. If any court or competent authority finds that any provision of these
      terms and conditions (or part of any provision) is invalid, illegal or
      unenforceable, that provision or part-provision will, to the extent
      required, be deemed to be deleted, and the validity and enforceability of
      the other provisions of these terms and conditions will not be affected.
      <br /> <br />
      30. Unless otherwise agreed, no delay, act or omission by a party in
      exercising any right or remedy will be deemed a waiver of that, or any
      other, right or remedy.
      <br /> <br />
      31. This Agreement shall be governed by and interpreted according to the
      law of England and Wales and all disputes arising under the Agreement
      (including non-contractual disputes or claims) shall be subject to the
      exclusive jurisdiction of the English and Welsh courts.
    </Paragraph>

    <Paragraph heading="Itriom Limited details">
      32. Itriom Limited is a company incorporated in Jersey with registered
      number 134815 whose registered address is 9 Bond Street, St Helier,
      Jersey, JE2 3NP and it operates the Platform <WebsiteLink />. You can
      contact Itriom Limited by email on <MailLink />
    </Paragraph>
  </Flex>
);

export const TermsOfUsePage = () => (
  <LayoutGrid>
    <LayoutGridElement>
      <HeroMedia imageUrl="/assets/Terms_Banner.png" heading="Terms Of Use" />
    </LayoutGridElement>

    <LayoutGridElement
      gridColumn={{ lg: '3 / 15', md: '1 / span 8', base: '1 / span 4' }}
    >
      <TermsOfUseContent />
    </LayoutGridElement>
  </LayoutGrid>
);
