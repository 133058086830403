import { debounce } from 'lodash';

import { useCallback } from 'react';

import { gql, useLazyQuery } from '@apollo/client';

import { Query, QuerySearchArgs } from 'types';
import {
  ItriomTagsV2OutputFragment,
  ProfileFragment,
} from 'types/generated-fragments';

export const searchGql = gql`
  ${ItriomTagsV2OutputFragment}
  ${ProfileFragment}
  query Search(
    $phrase: String!
    $categories: [SearchCategoryEnum!]
    $pagination: PaginationInput = { offset: 0, limit: 100 }
  ) {
    search(phrase: $phrase, categories: $categories, pagination: $pagination) {
      connections {
        nodes {
          object {
            ...ProfileFragment
          }
        }
        totalCount
      }
      insights {
        nodes {
          object {
            id
            hero {
              headline
              summary
              author
              sourceUrl
              heroImage {
                signedUrl
              }
            }
            tags {
              ...ItriomTagsV2OutputFragment
            }
          }
        }
        totalCount
      }
      initiatives {
        nodes {
          object {
            id
            createdAt
            status
            tags {
              ...ItriomTagsV2OutputFragment
            }
            hero {
              heroImage {
                signedUrl
              }
              headline
              summary
            }
          }
        }
        totalCount
      }
      events {
        nodes {
          object {
            id
            hero {
              name
              summary
              heroImage {
                signedUrl
              }
            }

            tags {
              ...ItriomTagsV2OutputFragment
            }
          }
        }
        totalCount
      }
      library {
        nodes {
          object {
            id
            createdAt
            cdnKey
            name
            isDirectory
            isFavorite
            isMovable
            isRemovable
            isRenamable
            isShareable
            libraryOwner
            libraryType
            mimeType
            path
            removedAt
            shares {
              ...ProfileFragment
            }
            resources {
              id
            }
            size
            updatedAt
          }
        }
        totalCount
      }
    }
  }
`;

export const useSearch = () => {
  const [search, { data, error, loading, refetch }] = useLazyQuery<
    Pick<Query, 'search'>,
    Partial<QuerySearchArgs>
  >(searchGql);
  const handleSearch = useCallback(
    debounce((variables: QuerySearchArgs) => {
      search({ variables });
    }, 500),
    [search]
  );

  return {
    searchResults: data?.search,
    error,
    loading,
    handleSearch,
    refetch,
  };
};
