import { ReactNode } from 'react';

import { accountGql } from 'gql/accounts/query/account';
import { useVerifyForgotPasswordSecondFactor } from 'hooks/authorization';
import { SecondFactorVerificationTypeEnum } from 'types';

import { OtpValidator } from './OtpValidator';

type Props = {
  onBack?: () => void;
  token: string;
  buttonAfterSubmit?: ReactNode;
};

export const ForgotPasswordOtpValidator = ({
  onBack,
  token,
  buttonAfterSubmit,
}: Props) => {
  const { verifyForgotPasswordSecondFactor, loading } =
    useVerifyForgotPasswordSecondFactor({
      refetchQueries: [accountGql],
    });

  const handleSubmitCode = (otpCode: string, onError: () => void) => {
    verifyForgotPasswordSecondFactor({
      secondFactor: Number(otpCode),
      token,
    }).catch(onError);
  };

  return (
    <OtpValidator
      handleSubmit={handleSubmitCode}
      buttonAfterSubmit={buttonAfterSubmit}
      token={token}
      tokenType={SecondFactorVerificationTypeEnum.ResetPassword}
      onBack={onBack}
      loading={loading}
    />
  );
};
