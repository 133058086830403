import { gql, useMutation } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { BookingTypeEnum } from 'types';

import { isDemoBookedGql } from '../documents';

const bookDemoGql = gql`
  mutation BookDemo($type: BookingTypeEnum!) {
    bookDemo(type: $type)
  }
`;

type UseBookDemoProps = {
  withToast?: boolean;
  type: BookingTypeEnum;
};

export const useBookDemo = ({ withToast, type }: UseBookDemoProps) => {
  const [bookDemo] = useMutation(bookDemoGql, {
    refetchQueries: [isDemoBookedGql],
    variables: { type },
  });

  const bookDemoWithToast = useWithMutationToast(bookDemo);

  if (withToast) {
    return bookDemoWithToast;
  }

  return bookDemo;
};
