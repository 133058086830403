import { CalendarHeatMap, Time, Location } from '@carbon/icons-react/next';
import { Flex, Text } from '@chakra-ui/react';

import { SectionHeading } from 'features/Onboarding/components/SectionHeader';
import {
  AttendanceStatusEnum,
  OnboardManagementMeetingInvitationOutput,
} from 'types';
import { formatDate, TimezoneHandler } from 'utils/date';

type Props = {
  status: AttendanceStatusEnum;
  setAttendanceResponse: OnboardManagementMeetingInvitationOutput;
};

export const LeftContent = ({ status, setAttendanceResponse }: Props) => {
  const { date: outputDate, location } = setAttendanceResponse;

  const { date, time, timezone } = TimezoneHandler.apiToClient(outputDate);

  return (
    <Flex direction="column">
      <SectionHeading heading="RSVP & mobile confirmation" />
      {status === AttendanceStatusEnum.UnableToAttend ? (
        <Text size="p1b" maxWidth={540}>
          We are sorry you are unable to attend the Itriom workshop in person.
          <br />
          <br />
          We will send you another email with your unique log-in link to the
          Itriom Values Configurator.
          <br />
          <br />
          Please also confirm your mobile number.
        </Text>
      ) : (
        <>
          <Text size="p1b" maxWidth={540}>
            Thank you for your confirmation to attend the Itriom workshop.
            {status === AttendanceStatusEnum.AttendingOnline &&
              'We will send you another email with the Zoom link.'}
            <br />
            Please also confirm your mobile number.
            <br />
            <br />
            Details of your workshop:
          </Text>
          <Flex direction="column" gap={4} py={8}>
            <Flex align="center" gap={4}>
              <Flex>
                <CalendarHeatMap size={24} />
              </Flex>
              {formatDate(new Date(date), 'do MMMM y')}
            </Flex>
            <Flex align="center" gap={4}>
              <Flex>
                <Time size={24} />
              </Flex>
              {time} {timezone?.text || ''}
            </Flex>
            <Flex align="center" gap={4}>
              <Flex>
                <Location size={24} />
              </Flex>
              {location}
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  );
};
