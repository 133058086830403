import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from '@chakra-ui/react';

import { HeroBanner } from 'components/HeroBanner';
import { QueryStateGuard } from 'components/QueryStateGuard';
import { useBannerInitiative } from 'hooks/initiatives';
import { Links } from 'router/links';
import { generateUrlById } from 'utils/links';

export const InitiativesLandingPageHero = () => {
  const { bannerInitiative, error, loading } = useBannerInitiative();
  const { t } = useTranslation(['common']);

  return (
    <QueryStateGuard error={error} loading={loading}>
      {bannerInitiative && (
        <HeroBanner
          tags={bannerInitiative.tags}
          title={bannerInitiative.hero.headline}
          subtitle={bannerInitiative.hero.summary}
          src={bannerInitiative.hero.heroImage.signedUrl}
        >
          <Button
            variant="primaryLime"
            as={Link}
            to={generateUrlById(Links.initiativesSingle, bannerInitiative.id)}
          >
            {t('common:view', { entity: t('common:initiative') })}
          </Button>
        </HeroBanner>
      )}
    </QueryStateGuard>
  );
};
