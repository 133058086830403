import { Flex, Image, Text, VStack } from '@chakra-ui/react';

import type { Comment as CommentType } from 'types';

type Props = { comment: CommentType };

export const CommentPreview = ({ comment }: Props) => {
  const { text, images } = comment;

  return (
    <Flex direction="column" w="full">
      <Text size="p2" whiteSpace="pre-wrap">
        {text}
      </Text>

      <VStack mt={2} w="full">
        {images?.map(({ signedUrl }) => (
          <Image src={signedUrl} w="100%" key={signedUrl} />
        ))}
      </VStack>
    </Flex>
  );
};
