import { CheckmarkOutline } from '@carbon/icons-react/next';
import {
  Button,
  Heading,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  isSubmitting: boolean;
};

export const PublicGroupModal = ({
  isOpen,
  onClose,
  onSubmit,
  isSubmitting,
}: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay bg="rgba(10, 30, 64, 0.75)" backdropFilter="blur(8px)" />
      <ModalContent
        display="flex"
        flexDirection="column"
        p={10}
        gap={4}
        w={480}
        maxW={480}
        h={320}
        bg="ui.white"
        boxShadow="0px 8px 24px rgba(59, 75, 102, 0.2)"
        borderRadius={8}
      >
        <ModalHeader p={0}>
          <Heading size="h5">
            Are you sure you want to want to make this Family group Public?
          </Heading>
        </ModalHeader>
        <Text size="p2" mb={12}>
          This action cannot be undone.
        </Text>
        <ModalFooter p={0} justifyContent="space-between" h={12}>
          <Button
            w={98}
            onClick={onClose}
            variant="outline"
            borderRadius={2}
            borderColor="primary.blue.default"
            fontSize={16}
            h="full"
            isDisabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            w={123}
            onClick={onSubmit}
            bgColor="status.green.default"
            border="none"
            borderRadius={2}
            rightIcon={<CheckmarkOutline />}
            alignItems="center"
            fontSize={16}
            h="full"
            isLoading={isSubmitting}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
