import { Flex, Text } from '@chakra-ui/react';

import { HeroMedia } from 'components/HeroMedia';
import { LayoutGrid, LayoutGridElement } from 'components/Layout/Grid';

import { MailLink, Paragraph } from '../components';
import { ExternalLink } from '../components/Links';

const PrivacyPolicyContent = () => (
  <Flex direction="column" justify="flex-start">
    <Paragraph>
      This privacy policy applies between you, the User of this Website, and
      Itriom Limited, the owner and provider of this Website. Itriom Limited
      takes the privacy of your information very seriously. This privacy policy
      applies to our use of any and all Data collected by us or provided by you
      in relation to your use of the Website.
      <br /> <br />
      Please read this privacy policy carefully.
    </Paragraph>

    <Paragraph heading="Definitions and interpretation">
      In this privacy policy, the following definitions are used:
      <strong>Data</strong>
      Collectively all information that you submit to Itriom Limited via the
      Website. This definition incorporates, where applicable, the definitions
      provided in the Data Protection Laws;
      <strong>Cookies</strong>A small text file placed on your computer by
      thisWebsite when you visit certain parts of the Website and/or when you
      use certain features of the Website. Details of the cookies used by this
      Website are set outing the clause below (Cookies);
      <strong>Data Protection Laws </strong>
      any applicable law relating to the processing of personal Data, including
      but not limited to the GDPR, and any national implementing and
      supplementary laws, regulations and secondary legislation;
      <strong>GDPR</strong>
      The UK General Data Protection Regulation;
      <strong>Itriom Limited, we or us</strong>
      Itriom Limited, a company incorporated in England and Wales with
      registered number 13481 whose registered office is at 9 Bond Street, St
      Helier, Jersey, JE2 3NP;
      <strong>UK and EU Cookie Law</strong>
      The Privacy and Electronic Communications (EC Directive) Regulations 2003
      as amended by the Privacy and Electronic Communications (EC Directive)
      (Amendment) Regulations 2011 & the Privacy and Electronic Communications
      (EC Directive) (Amendment) Regulations 2018;
      <strong>User or you</strong>
      Any third party that accesses the Website and is not either (i) employed
      by Itriom Limited and acting in the course of their employment or (ii)
      engaged as a consultant or otherwise providing services to Itriom Limited
      and accessing the Website in connection with the provision of such
      services; and
      <strong>Website</strong>
      The website that you are currently using, www.itriom.com, and any
      sub-domains of this site unless expressly excluded by their own terms and
      conditions.
      <br /> <br />
      2. In this privacy policy, unless the context requires a different
      interpretation:
      <br /> <br />
      <Text ml={2} size="p1">
        a. the singular includes the plural and vice versa;
        <br /> <br />
        b. references to sub-clauses, clauses, schedules or appendices are to
        sub-clauses, clauses, schedules or appendices of this privacy policy;
        <br /> <br />
        c. a reference to a person includes firms, companies, government
        entities, trusts and partnerships;
        <br /> <br />
        d. “including” is understood to mean “including without limitation”;
        <br /> <br />
        e. reference to any statutory provision includes any modification or
        amendment of it;
        <br /> <br />
        f. the headings and sub-headings do not form part of this privacy
        policy.
      </Text>
    </Paragraph>

    <Paragraph heading="Scope of this privacy policy">
      3. This privacy policy applies only to the actions of Itriom Limited and
      Users with respect to this Website. It does not extend to any websites
      that can be accessed from this Website including, but not limited to, any
      links we may provide to social media websites.
      <br /> <br />
      4. For purposes of the applicable Data Protection Laws, Itriom Limited is
      the “data controller”. This means that Itriom Limited determines the
      purposes for which, and the manner in which, your Data is processed.
    </Paragraph>

    <Paragraph heading="Data Collected">
      5. We may collect the following Data, which includes personal Data, from
      you:
      <Text ml={2} size="p1">
        <br />
        a. name;
        <br /> <br />
        b. date of birth;
        <br /> <br />
        c. gender;
        <br /> <br />
        d. job title;
        <br /> <br />
        e. profession;
        <br /> <br />
        f. contact Information such as email addresses and telephone numbers;
        <br /> <br />
        g. demographic information such as postcode, preferences and interests;
        <br /> <br />
        h. financial information such as credit / debit card numbers;
        <br /> <br />
        i. IP address (automatically collected);
        <br /> <br />
        j. web browser type and version (automatically collected);
        <br /> <br />
        k. operating system (automatically collected);
        <br /> <br />
        l. a list of URLs starting with a referring site, your activity on this
        Website, and the site you exit to (automatically collected);
      </Text>
      <br />
      in each case, in accordance with this privacy policy.
    </Paragraph>

    <Paragraph heading="How we collect Data">
      6. We collect Data in the following ways:
      <br /> <br />
      <Text ml={2} size="p1">
        a. data is given to us by you; and
        <br /> <br />
        b. data is collected automatically.
      </Text>
    </Paragraph>

    <Paragraph heading="Data that is given to us by you">
      7. Itriom Limited will collect your Data in a number of ways, for example:
      <br /> <br />
      <Text ml={2} size="p1">
        a. when you contact us through the Website, by telephone, post, e-mail
        or through any other means;
        <br /> <br />
        b. when you register with us and set up an account to receive our
        products/services;
        <br /> <br />
        c. when you complete surveys that we use for research purposes (although
        you are not obliged to respond to them);
        <br /> <br />
        d. when you make payments to us, through this Website or otherwise;
        <br /> <br />
        e. when you elect to receive marketing communications from us;
        <br /> <br />
        f. when you use our services;
        <br />
      </Text>
      in each case, in accordance with this privacy policy.
    </Paragraph>

    <Paragraph heading="Data that is collected automatically">
      8. To the extent that you access the Website, we will collect your Data
      automatically, for example:
      <br /> <br />
      <Text ml={2} size="p1">
        a. we automatically collect some information about your visit to the
        Website. This information helps us to make improvements to Website
        content and navigation, and includes your IP address, the date, times
        and frequency with which you access the Website and the way you use and
        interact with its content.
        <br /> <br />
        b. we will collect your Data automatically via cookies, in line with the
        cookie settings on your browser. For more information about cookies, and
        how we use them on the Website, see the section below, headed “Cookies”.
      </Text>
    </Paragraph>

    <Paragraph heading="Our use of Data">
      9. Any or all of the above Data may be required by us from time to time in
      order to provide you with the best possible service and experience when
      using our Website. Specifically, Data may be used by us for the following
      reasons:
      <br /> <br />
      <Text ml={2} size="p1">
        a. internal record keeping;
        <br /> <br />
        b. improvement of our products / services;
        <br /> <br />
        c. transmission by email of marketing materials that may be of interest
        to you;
        <br /> <br />
        d. contact for market research purposes which may be done using email,
        telephone, fax or mail. Such information may be used to customise or
        update the Website; in each case, in accordance with this privacy
        policy.
      </Text>
      <br /> <br />
      10. We may use your Data for the above purposes if we deem it necessary to
      do so for our legitimate interests. If you are not satisfied with this,
      you have the right to object in certain circumstances (see the section
      headed “Your rights” below).
      <br /> <br />
      11. For the delivery of direct marketing to you via e-mail, we’ll need
      your consent, whether via an opt-in or soft-opt-in:
      <br /> <br />
      <Text ml={2} size="p1">
        a. soft opt-in consent is a specific type of consent which applies when
        you have previously engaged with us (for example, you contact us to ask
        us for more details about a particular product/service, and we are
        marketing similar products/services). Under “soft opt-in” consent, we
        will take your consent as given unless you opt-out.
        <br /> <br />
        b. for other types of e-marketing, we are required to obtain your
        explicit consent; that is, you need to take positive and affirmative
        action when consenting by, for example, checking a tick box that we’ll
        provide.
        <br /> <br />
        c. if you are not satisfied with our approach to marketing, you have the
        right to withdraw consent at any time. To find out how to withdraw your
        consent, see the section headed “Your rights” below.
      </Text>
      <br /> <br />
      12. When you register with us and set up an account to receive our
      services, the legal basis for this processing is the performance of a
      contract between you and us and/or taking steps, at your request, to enter
      into such a contract.
    </Paragraph>

    <Paragraph heading="Who we share Data with">
      13. We may share your Data with the following groups of people for the
      following reasons:
      <br /> <br />
      <Text ml={2} size="p1">
        a. our employees, agents and/or professional advisors – to obtain advice
        from professional advisers; in each case, in accordance with this
        privacy policy.
      </Text>
    </Paragraph>

    <Paragraph heading="Keeping Data secure">
      14. We will use technical and organisational measures to safeguard your
      Data, for example:
      <br /> <br />
      <Text ml={2} size="p1">
        a. access to your account is controlled by a password and a user name
        that is unique to you.
        <br /> <br />
        b. we store your Data on secure servers.
        <br /> <br />
        c. payment details are encrypted using SSL technology (typically you
        will see a lock icon or green address bar (or both) in your browser when
        we use this technology.
      </Text>
      <br /> <br />
      15. We are certified to SOC 2. This family of standards helps us manage
      your Data and keep it secure.
      <br /> <br />
      16. Technical and organisational measures include measures to deal with
      any suspected data breach. If you suspect any misuse or loss or
      unauthorised access to your Data, please let us know immediately by
      contacting us via this e-mail
      <br /> <br />
      address: <MailLink />.
      <br /> <br />
      17. If you want detailed information from Get Safe Online on how to
      protect your information and your computers and devices against fraud,
      identity theft, viruses and many other online problems, please visit
      www.getsafeonline.org. Get Safe Online is supported by HM Government and
      leading businesses.
    </Paragraph>

    <Paragraph heading="Data retention">
      18. Unless a longer retention period is required or permitted by law, we
      will only hold your Data on our systems for the period necessary to fulfil
      the purposes outlined in this privacy policy or until you request that the
      Data be deleted.
      <br /> <br />
      19. Even if we delete your Data, it may persist on backup or archival
      media for legal, tax or regulatory purposes.
    </Paragraph>

    <Paragraph heading="Your rights">
      20. You have the following rights in relation to your Data:
      <br /> <br />
      <Text ml={2} size="p1">
        a. Right to access - the right to request (i) copies of the information
        we hold about you at any time, or (ii) that we modify, update or delete
        such information. If we provide you with access to the information we
        hold about you, we will not charge you for this, unless your request is
        “manifestly unfounded or excessive.” Where we are legally permitted to
        do so, we may refuse your request. If we refuse your request, we will
        tell you the reasons why.
        <br /> <br />
        b. Right to correct - the right to have your Data rectified if it is
        inaccurate or incomplete.
        <br /> <br />
        c. Right to erase - the right to request that we delete or remove your
        Data from our systems.
        <br /> <br />
        d. Right to restrict our use of your Data - the right to “block” us from
        using your Data or limit the way in which we can use it.
        <br /> <br />
        e. Right to data portability - the right to request that we move, copy
        or transfer your Data.
        <br /> <br />
        f. Right to object - the right to object to our use of your Data
        including where we use it for our legitimate interests.
      </Text>
      <br /> <br />
      21. To make enquiries, exercise any of your rights set out above, or
      withdraw your consent to the processing of your Data (where consent is our
      legal basis for processing your Data), please contact us via this e-mail
      address: <MailLink />.
      <br /> <br />
      22. If you are not satisfied with the way a complaint you make in relation
      to your Data is handled by us, you may be able to refer your complaint to
      the relevant data protection authority. For the UK, this is the
      Information Commissioner’s Office (ICO). The ICO’s contact details can be
      found on their website at{' '}
      <ExternalLink href="https://ico.org.uk/">
        https://ico.org.uk/
      </ExternalLink>
      .
      <br /> <br />
      23. It is important that the Data we hold about you is accurate and
      current. Please keep us informed if your Data changes during the period
      for which we hold it.
    </Paragraph>

    <Paragraph heading="Links to other websites">
      24. This Website may, from time to time, provide links to other websites.
      We have no control over such websites and are not responsible for the
      content of these websites. This privacy policy does not extend to your use
      of such websites. You are advised to read the privacy policy or statement
      of other websites prior to using them.
    </Paragraph>

    <Paragraph heading="Changes of business ownership and control">
      25. Itriom Limited may, from time to time, expand or reduce our business
      and this may involve the sale and/or the transfer of control of all or
      part of Itriom Limited. Data provided by Users will, where it is relevant
      to any part of our business so transferred, be transferred along with that
      part and the new owner or newly controlling party will, under the terms of
      this privacy policy, be permitted to use the Data for the purposes for
      which it was originally supplied to us.
      <br /> <br />
      26. We may also disclose Data to a prospective purchaser of our business
      or any part of it.
      <br /> <br />
      27. In the above instances, we will take steps with the aim of ensuring
      your privacy is protected.
    </Paragraph>

    <Paragraph heading="Cookies">
      28. This Website may place and access certain Cookies on your computer.
      Itriom Limited uses Cookies to improve your experience of using the
      Website and to improve our range of services. Itriom Limited has carefully
      chosen these Cookies and has taken steps to ensure that your privacy is
      protected and respected at all times.
      <br /> <br />
      29. All Cookies used by this Website are used in accordance with current
      UK and EU Cookie Law.
      <br /> <br />
      30. Before the Website places Cookies on your computer, you will be
      presented with a message bar requesting your consent to set those Cookies.
      By giving your consent to the placing of Cookies, you are enabling Itriom
      Limited to provide a better experience and service to you. You may, if you
      wish, deny consent to the placing of Cookies; however certain features of
      the Website may not function fully or as intended.
      <br /> <br />
      31. This Website may place the following Cookies:
      <strong>Strictly necessary cookies</strong>
      These are cookies that are required for the operation of our website. They
      include, for example, cookies that enable you to log into secure areas of
      our website, use a shopping cart or make use of e-billing services.
      <strong>Analytical/performance cookies</strong>
      They allow us to recognise and count the number of visitors and to see how
      visitors move around our website when they are using it. This helps us to
      improve the way our website works, for example, by ensuring that users are
      finding what they are looking for easily.
      <strong>Functionality cookies</strong>
      These are used to recognise you when you return to our website. This
      enables us to personalise our content for you, greet you by name and
      remember your preferences (for example, your choice of language or
      region). By using the Website, you agree to our placement of functionality
      cookie.
      <br /> <br />
      32. You can find a list of Cookies that we use in the Cookies Schedule.
      <br /> <br />
      33. You can choose to enable or disable Cookies in your internet browser.
      By default, most internet browsers accept Cookies but this can be changed.
      For further details, please see the help menu in your internet browser.
      You can switch off Cookies at any time, however, you may lose any
      information that enables you to access the Website more quickly and
      efficiently.
      <br /> <br />
      34. You can choose to delete Cookies at any time; however, you may lose
      any information that enables you to access the Website more quickly and
      efficiently including, but not limited to, personalisation settings.
      <br /> <br />
      35. It is recommended that you ensure that your internet browser is
      up-to-date and that you consult the help and guidance provided by the
      developer of your internet browser if you are unsure about adjusting your
      privacy settings.
      <br /> <br />
      36. For more information generally on cookies, including how to disable
      them, please refer to aboutcookies.org. You will also find details on how
      to delete cookies from your computer.
    </Paragraph>

    <Paragraph heading="General">
      37. You may not transfer any of your rights under this privacy policy to
      any other person. We may transfer our rights under this privacy policy
      where we reasonably believe your rights will not be affected.
      <br /> <br />
      38. If any court or competent authority finds that any provision of this
      privacy policy (or part of any provision) is invalid, illegal or
      unenforceable, that provision or part-provision will, to the extent
      required, be deemed to be deleted, and the validity and enforceability of
      the other provisions of this privacy policy will not be affected.
      <br /> <br />
      39. Unless otherwise agreed, no delay, act or omission by a party in
      exercising any right or remedy will be deemed a waiver of that, or any
      other, right or remedy.
      <br /> <br />
      40. This Agreement will be governed by and interpreted according to the
      law of Jersey. All disputes arising under the Agreement will be subject to
      the exclusive jurisdiction of the Jersey courts.
    </Paragraph>

    <Paragraph heading="Changes to this privacy policy">
      41. Itriom Limited reserves the right to change this privacy policy as we
      may deem necessary from time to time or as may be required by law. Any
      changes will be immediately posted on the Website and you are deemed to
      have accepted the terms of the privacy policy on your first use of the
      Website following the alterations.
      <br /> <br />
      You may contact Itriom Limited by email at <MailLink />.
      <br /> <br />
      This privacy policy was created on <b>14 November 2022</b>.
    </Paragraph>
  </Flex>
);

export const PrivacyPolicyPage = () => (
  <LayoutGrid>
    <LayoutGridElement>
      <HeroMedia
        imageUrl="/assets/Privacy_Policy_Banner.png"
        heading="Privacy Policy"
      />
    </LayoutGridElement>
    <LayoutGridElement
      gridColumn={{ lg: '3 / 15', md: '1 / span 8', base: '1 / span 4' }}
    >
      <PrivacyPolicyContent />
    </LayoutGridElement>
  </LayoutGrid>
);
