import { SvgElement, TextElement, Position } from '../types';
import { Dimensions } from '../types/Dimensions';
import { TitleConfig as Title } from '../types/Title';
import { getVerticalPosition, getHorizontalPosition } from './position';

type TOptions = {
  title: Title;
  dimensions: Dimensions;
};

const setTextAnchor = (
  el: TextElement,
  { position }: { position: Position }
): TextElement => {
  switch (position) {
    case 'end':
      el.attr('text-anchor', 'end');
      break;
    case 'center':
      el.attr('text-anchor', 'middle');
      break;
    case 'start':
    default:
      el.attr('text-anchor', 'start');
      break;
  }

  return el;
};

export const addTitle = (
  el: SvgElement,
  { title, dimensions }: TOptions
): SvgElement => {
  const {
    text,
    color = 'currentColor',
    fontSize = 16,
    horizontal = 'center',
    vertical = 'start',
  } = title;

  const textEl = el
    .append('text')
    .attr('font-size', fontSize)
    .attr('font-weight', 'bold')
    .attr('fill', color)
    .text(text);

  textEl
    .attr('x', getHorizontalPosition({ position: horizontal, dimensions }))
    .attr(
      'y',
      getVerticalPosition({ position: vertical, dimensions }) + fontSize
    );

  setTextAnchor(textEl, { position: horizontal });

  return el;
};
