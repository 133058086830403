import { Flex } from '@chakra-ui/react';

import { Avatar } from 'components/Avatar';
import { SearchConnectionResultOutput } from 'types';

import { MAX_CONNECTIONS_PREVIEW } from '../constants';

interface Props {
  results: SearchConnectionResultOutput[];
  showAllResults?: boolean;
}

export const ConnectionResults = ({
  results,
  showAllResults = false,
}: Props) => {
  return (
    <Flex gap={6} px={6} justifyContent="space-around" w="full" flexWrap="wrap">
      {results
        .slice(0, showAllResults ? results.length : MAX_CONNECTIONS_PREVIEW)
        .map((result) => (
          <Avatar key={result.object.id} profile={result.object} />
        ))}
    </Flex>
  );
};
