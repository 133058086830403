import { FormikHelpers, FormikValues } from 'formik';

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

import { Upload } from './Form/Upload/Upload';

/** Placeholder, will get final vallue form business */
const MAX_IMAGES_COUNT = 3;

type Props = {
  isOpen: boolean;
  setFieldValue: FormikHelpers<FormikValues>['setFieldValue'];
  onClose: () => void;
};

export const FileUploadModal = ({ isOpen, setFieldValue, onClose }: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setFieldValue('upload', null);
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Attach images</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Upload
            name="upload"
            label="File upload"
            maxFiles={MAX_IMAGES_COUNT}
          />
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Attach
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
