import { Links } from 'router/links';

import { NextGenService, ServiceCard, SolutionCards } from '../types';

const cards: SolutionCards[] = [
  {
    title: 'Self Awareness',
    description:
      'Bring awareness to what is happening in our lives and highlight positive and negative aspects, from leadership, to our wellbeing and resilience.',
    iconSrc: '/assets/nextGenServices/solutionServices/lr/self-awareness.svg',
  },
  {
    title: 'Influence',
    description:
      'Provide the tools and skills enabling our clients to thrive in a VUCA world, empowering them to make sound decisions with a rational decision-making process.',
    iconSrc: '/assets/nextGenServices/solutionServices/lr/influence.svg',
  },
  {
    title: 'Control',
    description:
      'To be at the forefront of any discussion, relationship or interaction and leading from a place of clarity, focus and control.',
    iconSrc: '/assets/nextGenServices/solutionServices/lr/control.svg',
  },
];

const services: ServiceCard[] = [
  {
    src: '/assets/nextGenServices/interServices/lr/leadership.png',
    title: 'Leadership',
    description:
      'Using market leading experience for a modular approach to preparing the next generation for the responsibility and accountability of leading the Family Office. Then fulfilling a more extensive leadership as the Family Office contributes to wider society.',
  },
  {
    src: '/assets/nextGenServices/interServices/lr/wellbeing.png',
    title: 'Health and Wellbeing',
    description:
      'A practical and theoretical solution to ensuring the next generation have the solid foundations of good health and insightful wellbeing to lead effectively.',
  },
  {
    src: '/assets/nextGenServices/interServices/lr/finance.png',
    title: 'Finance',
    description:
      'A modular approach to providing the next generation with the skills, understanding and insight to assume responsibility for all elements of the financial portfolio of a Family Office.',
  },
  {
    src: '/assets/nextGenServices/interServices/lr/transition.png',
    title: 'Transition',
    description:
      'A bespoke service supporting the planning and execution of the transition to the next generation. Guiding the Principal and team towards a seamless shift across all areas of the Family Office, harnessing the benefits and mitigating any risks.',
  },
  {
    src: '/assets/nextGenServices/interServices/lr/resilience.png',
    title: 'Resilience',
    description:
      'To instil the mindfulness, mental strength, breadth and flexibility required to anticipate, recognise and usefully turn to advantage the threats and challenges inevitably involved in delivering the family’s initiatives. As well as incorporating the opportunities that will arise.',
  },
  {
    src: '/assets/nextGenServices/interServices/lr/awareness.png',
    title: 'Awareness',
    description:
      'Creating a truly informed approach to delivering our services, by using every technology, network and skill set open to us, feeding the curiosity, learning and appetite for understanding that underpins better, more knowledgeable and successful decisions.',
  },
];

export const leadership: NextGenService = {
  hero: {
    src: '/assets/nextGenServices/heroImages/lr.png',
    title: 'Next Generation Leadership & Resilience',
    subtitle: 'Delivering the way forward',
    link: Links.nextGenLR,
  },
  heroDescription:
    'We believe that lived experience and diversity of leadership are key to success. We don’t just teach. We show, immerse and then learn in everything we do. This leads to a unique and psychologically robust experience to prepare our clients for a Volatile, Uncertain, Complex and Ambiguous (VUCA) world.',
  interConnectedServices: {
    serviceCards: services,
  },
  solutions: {
    sectionTitle:
      'How our next generation leadership & resilience services help you',
    cards,
  },
  leader: {
    src: '/assets/nextGenServices/leaders/tim.png',
    title: 'Tim Boughton',
    subtitle: 'Senior Partner Practice Leader - Leadership & Resilience',
    bodyText:
      'Renowned family office thought leader, Tim works with UHNW families to ensure they are fully equipped to deliver their legacies inter-generationally and effectively.',
    findOutMoreUrl: '',
  },
  quote: {
    quote:
      '“Tim is quite simply inspirational – if not unconventional in his approach. We brought him in to change the way our board interacted and made decisions. From the outset he brought clarity as to the ‘Why’, focus to the ‘How’ and enabled us to make and form decisions from a place of informed reasoning. Our board meetings are so much more effective, my team are so much more in the ‘present moment’ and as such my organisation is thriving because of it”',
    author: '— CEO FTSE 100 Company',
  },
  services: [
    {
      title: 'Next Generation Sustainability',
      subtitle: 'Accelerating and maintaining sustainability',
      src: '/assets/nextGenServices/heroImages/sustainability.png',
      link: Links.nextGenSustainability,
    },
    {
      title: 'Next Generation Geostrategy',
      subtitle: 'Shaping the future',
      src: '/assets/nextGenServices/heroImages/geostrategy.png',
      link: Links.nextGenGeoStrategy,
    },
  ],
};
