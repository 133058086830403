import { ReactNode, useMemo } from 'react';

import { Flex, Grid, Heading } from '@chakra-ui/react';

import { CalendarBlock } from 'components/CalendarBlock';
import { HeroMedia, HeroMediaProps } from 'components/HeroMedia';
import { ItriomTag } from 'components/ItriomTag';
import { HeroMediaType, ItriomTagsV2Output } from 'types';
import { flattenTags } from 'utils/tags';

type Props = {
  src?: string;
  isVideo?: boolean;
  title: string;
  subtitle?: ReactNode;
  tags?: ItriomTagsV2Output;
  children?: ReactNode;
  calendarDate?: {
    start?: string;
    end?: string;
  };
  calendarPosition?: 'left' | 'bottom';
  height?: 'tall' | 'short';
};

export const HeroBanner = ({
  src,
  isVideo,
  title,
  subtitle,
  tags,
  children,
  calendarDate,
  calendarPosition = 'left',
  height = 'tall',
}: Props) => {
  const heroMediaProps: HeroMediaProps = useMemo(
    () =>
      isVideo
        ? {
            hasControls: true,
            videoAutoPlay: true,
            videoUrl: src,
            preferredHeroMediaToDisplay: HeroMediaType.Video,
          }
        : {
            imageUrl: src,
            preferredHeroMediaToDisplay: HeroMediaType.Image,
          },
    [isVideo, src]
  );

  return (
    <HeroMedia
      {...heroMediaProps}
      containerStyles={{ h: height === 'tall' ? 700 : 328 }}
      childrenContainerStyles={{
        maxW: height === 'tall' ? '65%' : '100%',
        py: height === 'tall' ? 28 : 8,
      }}
    >
      <Flex color="ui.white" direction="column" gap={4} cursor="default">
        <Heading
          size="h1"
          w="100%"
          noOfLines={height === 'short' ? 2 : undefined}
        >
          {title}
        </Heading>
        {subtitle && <Heading size="h2">{subtitle}</Heading>}
        <Grid
          gridTemplateAreas={
            calendarPosition === 'bottom'
              ? `"tags" "calendar"`
              : `"calendar tags"`
          }
          alignItems="center"
          pb={1}
          gap={calendarDate ? 4 : 0}
          width="fit-content"
        >
          {calendarDate && (
            <CalendarBlock
              containerStyling={{ gridArea: 'calendar' }}
              startDate={calendarDate.start}
              endDate={calendarDate.end}
            />
          )}
          <Grid gap={2} gridAutoFlow="column" gridArea="tags">
            {tags &&
              flattenTags(tags).map((tag) => (
                <ItriomTag key={tag} customVariant="outlineLight">
                  {tag}
                </ItriomTag>
              ))}
          </Grid>
        </Grid>
      </Flex>
      {children}
    </HeroMedia>
  );
};
