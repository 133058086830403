import {
  Flex,
  PinInput,
  PinInputField,
  PinInputFieldProps,
} from '@chakra-ui/react';

export const MAX_OTP_LENGTH = 6;

type Props = {
  setIsValid: (isValid: boolean) => void;
  isValid: boolean;
  setOtpCode?: (otpCode: string) => void;
  onComplete?: (fullCode: string) => void;
} & PinInputFieldProps;

export const VerificationCodeInput = ({
  setOtpCode,
  setIsValid,
  isValid,
  onComplete,
  ...inlineInputStyles
}: Props) => {
  const handleChange = (currentOtpCode: string) => {
    setIsValid(true);

    if (setOtpCode) {
      setOtpCode(currentOtpCode);
    }
  };

  return (
    <Flex justify="space-between" mb={2}>
      <PinInput
        otp
        placeholder="0"
        onChange={handleChange}
        onComplete={onComplete}
      >
        {Array.from({ length: MAX_OTP_LENGTH })
          .map((_, i) => i)
          .map((key) => (
            <PinInputField
              autoFocus={key === 0}
              key={key}
              placeholder="0"
              borderColor="transparent"
              color={isValid ? 'primary.blue.default' : 'status.red.default'}
              {...inlineInputStyles}
            />
          ))}
      </PinInput>
    </Flex>
  );
};
