import { gql, useMutation } from '@apollo/client';

import { postsGql } from 'hooks/posts';
import { extendedProfilesGql } from 'hooks/profiles/useExtendedProfiles';
import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationChangeAccountStatusArgs } from 'types';

import { cmsProfilesGql } from './useCmsProfiles';
import { extendedProfileGql } from './useExtendedProfile';

export const changeAccountStatusGql = gql`
  mutation ChangeAccountStatus($accountId: String!, $status: AccountStatus!) {
    changeAccountStatus(accountId: $accountId, status: $status) {
      id
      status
    }
  }
`;

export const useChangeAccountStatus = <
  TData extends Pick<Mutation, 'changeAccountStatus'>,
  TVariables extends MutationChangeAccountStatusArgs
>() => {
  const [mutation, { loading, error }] = useMutation<TData, TVariables>(
    changeAccountStatusGql,
    {
      fetchPolicy: 'no-cache',
      refetchQueries: [
        extendedProfilesGql,
        extendedProfileGql,
        cmsProfilesGql,
        postsGql,
      ],
    }
  );

  const handleMutation = (variables: TVariables) => mutation({ variables });

  const changeAccountStatus = useWithMutationToast(handleMutation, {
    disableSuccessMessage: true,
  });

  return { changeAccountStatus, loading, error };
};
