import { GroupsListSection } from 'components/GroupsListSection';
import { SingleGroupCard } from 'components/SingleGroupCard';
import { useYourMostActiveGroups } from 'hooks/groups';

export const MostActiveGroups = () => {
  const { yourMostActiveGroups, loading, error } = useYourMostActiveGroups();

  return (
    <GroupsListSection
      heading="Most active"
      loading={loading}
      error={error}
      showChildren={!!yourMostActiveGroups.length}
    >
      {yourMostActiveGroups.slice(0, 3).map((group) => (
        <SingleGroupCard
          key={group.id}
          group={group}
          withEsgCategory
          variant="secondary"
        />
      ))}
    </GroupsListSection>
  );
};
