import { ReactNode, useState } from 'react';

import { Button, ButtonGroup, Text } from '@chakra-ui/react';

import {
  MAX_OTP_LENGTH,
  VerificationCodeInput,
} from 'components/VerificationCodeInput';
import { SecondFactorVerificationTypeEnum } from 'types';

import { ResendOtp } from './ResendOtp';

type Props = {
  onBack?: () => void;
  loading: boolean;
  token: string;
  tokenType: SecondFactorVerificationTypeEnum;
  buttonAfterSubmit?: ReactNode;
  handleSubmit: (otp: string, onError: () => void) => void;
};

export const OtpValidator = ({
  onBack,
  token,
  tokenType,
  buttonAfterSubmit,
  loading,
  handleSubmit,
}: Props) => {
  const [otpCode, setOtpCode] = useState('');
  const [isValid, setIsValid] = useState(true);

  const handleSubmitCode = () => {
    handleSubmit(otpCode, () => setIsValid(false));
  };

  return (
    <>
      <Text size="p3" mb={3}>
        Enter the verification code
      </Text>
      <VerificationCodeInput
        setOtpCode={setOtpCode}
        setIsValid={setIsValid}
        isValid={isValid}
        h={12}
        w={43}
        borderRadius="2px 2px 0 0"
        bgColor="ui.grey4"
        borderBottom="2px"
      />
      {!isValid && (
        <Text color="status.red.default" size="p3" mb={3}>
          Verification code not valid. Please try again.
        </Text>
      )}
      <ResendOtp setOtpCode={setOtpCode} token={token} tokenType={tokenType} />
      <ButtonGroup w="full" justifyContent="space-between" mb={8}>
        <Button
          h={12}
          bgColor="primary.lime.default"
          color="primary.blue.default"
          variant="solid"
          borderRadius={2}
          w={123}
          fontSize="md"
          mb={4}
          onClick={handleSubmitCode}
          disabled={otpCode.length !== MAX_OTP_LENGTH}
          isLoading={loading}
        >
          Submit code
        </Button>
        {buttonAfterSubmit ?? (
          <Button
            h={12}
            variant="outline"
            borderRadius={2}
            borderColor="primary.blue.default"
            color="primary.blue.default"
            fontSize="md"
            onClick={onBack}
          >
            Back
          </Button>
        )}
      </ButtonGroup>
    </>
  );
};
