import { ReactNode } from 'react';

import { Center } from '@chakra-ui/react';

import { VALUE_CATEGORIES, OUTER_RADIUS } from '../../constants';
import { AnimatedQuadrant } from './AnimatedQuadrant';
import { AnimatedValueDescriptionTooltip } from './AnimatedValueDescriptionTooltip';
import { ValuesQuadrant } from './ValuesQuadrant';

type Props = {
  children: ReactNode;
};

export const ValuesWheel = ({ children }: Props) => {
  return (
    <Center w={OUTER_RADIUS * 2} h={OUTER_RADIUS * 2} position="relative">
      <AnimatedValueDescriptionTooltip />
      {VALUE_CATEGORIES.map((category) => (
        <AnimatedQuadrant category={category} key={category.name}>
          <ValuesQuadrant category={category} />
        </AnimatedQuadrant>
      ))}
      {children}
    </Center>
  );
};
