import { useTranslation } from 'react-i18next';

import { Flex, Heading, Text } from '@chakra-ui/react';

type Props = {
  description: string;
  name: string;
  question: string;
  handleOpenActions: VoidFunction;
};

export const ValueDescription = ({
  description,
  name,
  question,
  handleOpenActions,
}: Props) => {
  const { t } = useTranslation(['common']);

  return (
    <Flex direction="column" p={4} bgColor="ui.white" gap={4}>
      <Heading size="h4">{name}</Heading>
      <Flex direction="column" gap={2}>
        <Text size="p1" fontWeight="bold">
          {question}
        </Text>
        <Text size="p1">{description}</Text>
      </Flex>
      <Text
        onClick={handleOpenActions}
        size="p2"
        mt="auto"
        cursor="pointer"
        color="primary.blue.default"
        _hover={{ textDecor: 'underline' }}
      >
        {t('common:learnMore')}
      </Text>
    </Flex>
  );
};
