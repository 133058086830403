import { Flex } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/react';

import {
  useAcceptGroupInvitation,
  useRejectGroupInvitation,
} from 'hooks/groups';
import { GroupInvitationSentMetadata } from 'types';

import { buttonActionStyles } from '../utils';

type Props = {
  metadata: GroupInvitationSentMetadata;
};

export const GroupActions = ({ metadata }: Props) => {
  const { groupId, actionStatus } = metadata;
  const { acceptGroupInvitation } = useAcceptGroupInvitation();
  const { rejectGroupInvitation } = useRejectGroupInvitation();

  const handleAcceptInvitation = () => acceptGroupInvitation(groupId);
  const handleRejectInvitation = () => rejectGroupInvitation(groupId);

  if (actionStatus) return null;

  return (
    <Flex gap={2} mt={1}>
      <Button onClick={handleAcceptInvitation} {...buttonActionStyles}>
        Accept
      </Button>
      <Button onClick={handleRejectInvitation} {...buttonActionStyles}>
        Decline
      </Button>
    </Flex>
  );
};
