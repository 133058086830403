import { useTranslation } from 'react-i18next';

import { Button, Text, Flex, useDisclosure } from '@chakra-ui/react';

import { Modal } from 'components/Modal';

interface Props {
  onConfirm: VoidFunction;
  waitCounter: number;
}

export const MoreOptions = ({ onConfirm, waitCounter }: Props) => {
  const { t } = useTranslation(['common']);
  const { onOpen, isOpen, onClose } = useDisclosure();

  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <>
      <Button onClick={onOpen} variant="link" width="fit-content">
        <Text size="p3" color="primary.blue.default">
          {t('common:otp.more-options')}
        </Text>
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={t('common:otp.more-options')}
        footer={
          <Flex justifyContent="space-between" width="full">
            <Button onClick={onClose} variant="outline">
              {t('common:no')}
            </Button>
            <Button isDisabled={waitCounter > 0} onClick={handleConfirm}>
              {waitCounter
                ? t('common:otp.wait-before-resend', { number: waitCounter })
                : t('common:yes')}
            </Button>
          </Flex>
        }
      >
        <Text size="p1">{t('common:otp.send-via-email')}</Text>
      </Modal>
    </>
  );
};
