import { FormikProvider, useFormik } from 'formik';

import { useTranslation } from 'react-i18next';

import { Flex, Button } from '@chakra-ui/react';

import { ItriomButtonOutline } from 'components/Button';
import { TextInput } from 'components/Form/TextInput';
import { Modal } from 'components/Modal';
import { getFullName } from 'utils/string';

import { NAME_CONFIRMATION_FIELD, Props } from './types';
import { handleValidate } from './validation';

interface FormValues {
  [NAME_CONFIRMATION_FIELD]: string;
}

const useDeletionName = ({
  entity,
  name,
  secondaryName,
}: Pick<Props, 'entity' | 'name' | 'secondaryName'>) => {
  const { t } = useTranslation(['cms', 'common']);

  switch (entity) {
    case 'user':
    case 'advisor':
      return getFullName(name, secondaryName);
    case 'family':
      return `${name} ${t('common:family').toLowerCase()}`;
    case 'group':
      return name;
    default:
      throw new Error(`entity [${entity}] not handled`);
  }
};

const ENTITIES_WITH_FIRST_NAME = ['user', 'advisor'];

export const DeleteConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  entity,
  name,
  secondaryName,
}: Props) => {
  const { t } = useTranslation(['cms', 'common']);
  const formik = useFormik({
    initialValues: { [NAME_CONFIRMATION_FIELD]: '' },
    onSubmit: () => Promise.resolve(),
    validate: (formValues: FormValues) =>
      handleValidate({
        formValues,
        name,
        errorMessage: t('cms:delete.confirmation-error'),
      }),
    validateOnChange: true,
    validateOnMount: true,
  });

  const handleSubmit = () => {
    onConfirm(formik.values.deletionConfirmationField);
  };

  const hasFistName = ENTITIES_WITH_FIRST_NAME.includes(entity);

  const deletionName = useDeletionName({ entity, name, secondaryName });

  return (
    <FormikProvider value={formik}>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={t('cms:delete.action', {
          entity: deletionName,
          isQuestion: true,
        })}
        containerStyling={{ p: 3 }}
        bodyStyling={{ py: 4 }}
        footer={
          <Flex w="full" justify="space-between">
            <Button
              isDisabled={!!formik.errors[NAME_CONFIRMATION_FIELD]}
              onClick={handleSubmit}
              size="lg"
              color="ui.white"
              background="status.red.default"
              borderColor="status.red.default"
              _disabled={{
                backgroundColor: 'ui.grey1',
                borderColor: 'ui.grey1',
                cursor: 'not-allowed',
                _hover: {
                  backgroundColor: 'ui.grey1',
                  borderColor: 'ui.grey1',
                  color: 'ui.white',
                  opacity: '0.9',
                },
              }}
            >
              {t(`cms:delete.confirm`)}
            </Button>
            <ItriomButtonOutline onClick={onClose}>
              {t('common:no')}
            </ItriomButtonOutline>
          </Flex>
        }
      >
        <Flex direction="column" gap={8}>
          {t(`cms:delete.action-explanation`, {
            entity: t(`common:${entity}`).toLowerCase(),
            relatedEntities: t('cms:all-related-content'),
          })}
          <TextInput
            name={NAME_CONFIRMATION_FIELD}
            label={t('cms:delete.input-label', {
              entity: t(`common:${entity}`).toLowerCase(),
              suffix: hasFistName && t(`common:first`),
            })}
          />
        </Flex>
      </Modal>
    </FormikProvider>
  );
};
