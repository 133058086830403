import { useEffect, useState } from 'react';

/** This hook is responsible for delay handling, for example if you need to render something
 * after set ammount of time.
 */
export const useDelay = (offset: number) => {
  const [hasDelayElapsed, setHasDelayElapsed] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  const startDelay = () => {
    setTimeoutId(setTimeout(() => setHasDelayElapsed(true), offset));
  };

  return { hasDelayElapsed, startDelay };
};
