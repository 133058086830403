import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectInput } from 'components/Form/SelectInput';
import { useAuthContext } from 'contexts/AuthContext';
import { GroupType, Role } from 'types';

import { selectInputStyles } from './styles';

const tagsForAll = [GroupType.Initiative, GroupType.Event] as const;
const tagsForAdmins = [GroupType.Advisor, GroupType.Itriom] as const;
const adminRoles = [Role.ItriomCurator, Role.ExpertAdvisor];

export const GroupTagsInput = () => {
  const { account } = useAuthContext();
  const { t } = useTranslation(['groups']);

  const groupTagsValues = useMemo(() => {
    return [
      ...tagsForAll,
      ...(account?.role && adminRoles.includes(account.role)
        ? tagsForAdmins
        : []),
    ];
  }, [account?.role]);

  const tagsOptions = useMemo(
    () =>
      groupTagsValues.map((type) => ({
        label: type,
        value: type,
      })),
    [groupTagsValues]
  );

  return (
    <SelectInput
      name="type"
      label={t('groups:manage-modal.tags.title')}
      labelHint={t('groups:manage-modal.tags.title-hint')}
      options={tagsOptions}
      defaultOptionText="Select"
      inputStyles={selectInputStyles}
      isClearable
    />
  );
};
