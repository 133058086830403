import { VStack } from '@chakra-ui/react';

import {
  OrderedSdgs,
  SdgLinkage,
  SdgOrder,
  SdgSection,
  SdgsDetail,
  SdgSummary,
} from '../../components/SdgConfigurator';
import { SdgInfluences } from '../../components/SdgConfigurator/SdgFamilyInfluences';
import { useGetOnboardContent } from '../../hooks';
import { useOpenedSdgSections } from './useOpenedSdgSections';

export const SdgPage = () => {
  const { isThirdSectionVisible, isFourthSectionVisible } =
    useOpenedSdgSections();
  const {
    sdg: { description, title },
  } = useGetOnboardContent();

  return (
    <VStack alignItems="flex-stretch">
      <SdgSection>
        <SdgsDetail />
      </SdgSection>
      <SdgSection>
        <SdgLinkage />
      </SdgSection>
      <SdgSection variant="secondary">
        <SdgOrder />
      </SdgSection>
      {isThirdSectionVisible && (
        <>
          <SdgSection scrollToTop>
            <OrderedSdgs />
          </SdgSection>
          <SdgSection variant="secondary">
            <SdgSummary />
          </SdgSection>
        </>
      )}
      {isFourthSectionVisible && (
        <SdgSection variant="secondary">
          <SdgInfluences description={description} title={title} />
        </SdgSection>
      )}
    </VStack>
  );
};
