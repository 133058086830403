import { useTranslation } from 'react-i18next';

import { Flex } from '@chakra-ui/react';

import { Breadcrumbs } from 'components/Breadcrumbs';
import { EditContentButton } from 'components/EditContentButton';
import { LayoutGridElement } from 'components/Layout/Grid';
import { Links } from 'router/links';
import { Insight } from 'types';
import { generateUrlById } from 'utils/links';

import { InsightHero } from '../InsightHero';

interface Props {
  insight: Pick<Insight, 'hero' | 'id' | 'tags'>;
}

export const HeroSection = ({ insight }: Props) => {
  const { t } = useTranslation(['navigation']);

  const { tags, hero, id } = insight;

  return (
    <LayoutGridElement>
      <Flex direction="column" gap={4}>
        <InsightHero hero={hero} tags={tags} />
        <Flex justifyContent="space-between">
          <Breadcrumbs
            items={[
              { link: Links.home, label: t('navigation:home') },
              { link: Links.insights, label: t('navigation:cms.insights') },
              {
                link: generateUrlById(Links.insight, id),
                label: hero.headline,
              },
            ]}
          />
          <EditContentButton
            item={insight}
            editUrl={generateUrlById(Links.editInsight, id)}
          />
        </Flex>
      </Flex>
    </LayoutGridElement>
  );
};
