import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Flex } from '@chakra-ui/layout';
import { useDisclosure } from '@chakra-ui/react';

import { ConfirmationModal } from 'components/Modal';
import { MenuElement, ThreeDotsMenu } from 'components/ThreeDotsMenu';
import { useRemoveGroupMember } from 'hooks/groups';
import { Links } from 'router/links';
import { Group, Profile } from 'types';
import { generateUrlById } from 'utils/links';
import { getFullName } from 'utils/string';

type Props = {
  group: Group;
  profile: Profile;
};

export const GroupMemberMenu = ({ profile, group }: Props): JSX.Element => {
  const { t } = useTranslation(['network', 'common']);

  const navigate = useNavigate();

  const fullName = getFullName(profile.firstName, profile.lastName);

  const removeGroupMember = useRemoveGroupMember({
    groupId: group.id,
    memberId: profile.id,
    fullName,
  });

  const confirmationModal = useDisclosure();

  const isAdmin = group.admins.some((admin) => admin.id === profile.id);

  const isOwner = group.owner.id === profile.id;

  const elements: MenuElement[] = [
    {
      handleClick: confirmationModal.onOpen,
      text: t('network:remove-from-group.button'),
      hidden: isAdmin || isOwner,
    },
    {
      handleClick: () => navigate(generateUrlById(Links.profile, profile.id)),
      text: t('common:view-entity', {
        entity: t('common:profile').toLowerCase(),
      }),
    },
  ];

  return (
    <Flex p={2}>
      <ThreeDotsMenu elements={elements} iconVariant="dark" />
      <ConfirmationModal
        actionText={t('network:remove-from-group.action')}
        title={t('network:remove-from-group.title')}
        question={t('network:remove-from-group.confirm', {
          name: fullName,
        })}
        handleClick={removeGroupMember}
        isOpen={confirmationModal.isOpen}
        onClose={confirmationModal.onClose}
      />
    </Flex>
  );
};
