import { createContext, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthContext } from 'contexts/AuthContext';
import { NavigationItem, PageConfig } from 'router/types';
import { Role, WithChildren } from 'types';

type CmsNavigationContextType = {
  links: NavigationItem[];
};

const CmsNavigationContext = createContext<CmsNavigationContextType | null>(
  null
);

export const useCmsNavigationContext = () => {
  const value = useContext(CmsNavigationContext);

  if (value === null) {
    throw new Error('useNavigationContext used outside of its provider');
  }

  return value;
};

export const CmsNavigationContextProvider = ({
  children,
  pagesConfig,
}: WithChildren<{ pagesConfig: PageConfig[] }>) => {
  const { account } = useAuthContext();
  const { t } = useTranslation(['navigation']);

  const role = account?.role || Role.FamilyMember;

  const value = useMemo(() => {
    const userPages = pagesConfig.filter((pageConfig) =>
      pageConfig.roles.includes(role)
    );

    const menuItems: NavigationItem[] = userPages.map((pageConfig) => ({
      link: pageConfig.link,
      label: t(`navigation:${pageConfig.navigationLabelKey}`),
    }));

    return { links: menuItems };
  }, [pagesConfig, role, t]);

  return (
    <CmsNavigationContext.Provider value={value}>
      {children}
    </CmsNavigationContext.Provider>
  );
};
