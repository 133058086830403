import { gql, useMutation } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationInviteToGroupArgs } from 'types';

import { groupGql } from './useGroup';
import { membersToAddToGroupGql } from './useMembersToAddToGroup';

export const inviteToGroupGql = gql`
  mutation InviteToGroup($accountId: String!, $groupId: String!) {
    inviteToGroup(accountId: $accountId, groupId: $groupId)
  }
`;

export const useInviteToGroup = (fullName?: string) => {
  const [inviteToGroupMutation, { loading }] = useMutation<
    Pick<Mutation, 'inviteToGroup'>,
    MutationInviteToGroupArgs
  >(inviteToGroupGql, {
    refetchQueries: [groupGql, membersToAddToGroupGql],
  });

  const handleInviteToGroup = ({
    groupId,
    accountId,
  }: MutationInviteToGroupArgs) =>
    inviteToGroupMutation({
      variables: {
        groupId,
        accountId,
      },
    });

  const handleInviteToGroupWithToast = useWithMutationToast(
    handleInviteToGroup,
    {
      successConfig: {
        position: 'top',
        status: 'success',
        title: 'Member added',
        description: `${fullName} has been added to this group!`,
        isClosable: true,
        duration: 5000,
      },
    }
  );

  return { inviteToGroup: handleInviteToGroupWithToast, loading };
};
