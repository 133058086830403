import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { Links } from 'router/links';

import { LibraryLocation } from '../types';

export const useCurrentLibraryLocation = () => {
  const { pathname } = useLocation();
  const { id } = useParams();

  const libraryLocation = useMemo(() => {
    if (pathname.includes(Links.libraryFamily)) {
      return LibraryLocation.Family;
    }

    if (pathname.includes(Links.libraryMy)) {
      return LibraryLocation.My;
    }

    if (pathname.includes(Links.libraryItriom)) {
      return LibraryLocation.Itriom;
    }

    if (pathname.includes(Links.libraryFavourites)) {
      return LibraryLocation.Favourites;
    }

    if (pathname.includes(Links.libraryRecentlyDeleted)) {
      return LibraryLocation.RecentlyDeleted;
    }

    if (pathname.includes(Links.librarySharedWithMe)) {
      return LibraryLocation.SharedWithMe;
    }

    return LibraryLocation.SearchLibraryContext;
  }, [pathname]);

  return { libraryLocation, folderId: id };
};
