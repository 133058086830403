import { useNavigate } from 'react-router-dom';

import { gql, useMutation } from '@apollo/client';

import { CreateInsightForm } from 'features/Cms/types';
import { listInsightsGql } from 'hooks/insights';
import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Links } from 'router/links';
import {
  Mutation,
  MutationCreateInsightArgs,
  MutationDeleteInsightArgs,
} from 'types';
import { getImageId } from 'utils/file';
import { generateUrlById } from 'utils/links';

export const createInsightGql = gql`
  mutation CreateInsight($insight: CreateInsightInput!) {
    createInsight(insight: $insight) {
      id
    }
  }
`;

export const removeInsightGql = gql`
  mutation RemoveInsight($insightId: String!) {
    deleteInsight(insightId: $insightId)
  }
`;

export const useInsightActions = () => {
  const navigate = useNavigate();

  const [createInsightMutation] = useMutation<
    Pick<Mutation, 'createInsight'>,
    MutationCreateInsightArgs
  >(createInsightGql, {
    refetchQueries: [listInsightsGql],
  });

  const [removeInsightMutation] = useMutation<
    Pick<Mutation, 'deleteInsight'>,
    MutationDeleteInsightArgs
  >(removeInsightGql, {
    refetchQueries: [listInsightsGql],
  });

  const removeInsightMutationWithToast = useWithMutationToast(
    (insightId: string) => removeInsightMutation({ variables: { insightId } }),
    {
      successMessage: 'The insight was successfully deleted',
      errorMessage: 'Deleting failed',
    }
  );

  const createInsightMutationWithToast = useWithMutationToast(
    async (hero: CreateInsightForm) => {
      const { heroImage, firstPublishedAt, ...data } = hero;
      const variables = {
        insight: {
          hero: {
            ...data,
            firstPublishedAt: firstPublishedAt || '',
            heroImageId: getImageId(heroImage) ?? '',
          },
        },
      };

      const newInsight = await createInsightMutation({ variables });

      const id = newInsight.data?.createInsight.id;

      if (id) {
        navigate(generateUrlById(Links.editInsight, id));
      }
    },
    {
      successMessage: 'The insight was successfully created',
      errorMessage: 'Creation failed',
    }
  );

  return {
    deleteInsight: removeInsightMutationWithToast,
    createInsight: createInsightMutationWithToast,
  };
};
