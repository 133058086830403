import { useTranslation } from 'react-i18next';

import { Button } from '@chakra-ui/react';

import { HeroBanner } from 'components/HeroBanner';
import { QueryStateGuard } from 'components/QueryStateGuard';
import { useBannerEvent } from 'hooks/events';

import { EventAttendButton } from './EventAttendButton';

export const EventsLandingPageHero = () => {
  const { bannerEvent, error, loading } = useBannerEvent();
  const { t } = useTranslation(['common']);

  const { hero, basicInfo } = bannerEvent || {};

  return (
    <QueryStateGuard loading={loading} error={error}>
      {bannerEvent && hero && (
        <HeroBanner
          tags={bannerEvent.tags}
          calendarDate={{
            start: basicInfo?.startTime?.datetime,
            end: basicInfo?.endTime?.datetime,
          }}
          calendarPosition="bottom"
          title={hero.name}
          subtitle={hero.subtitle}
          src={hero.heroImage?.signedUrl}
        >
          {bannerEvent.visibilityDetails?.externalUrl ? (
            <a
              href={bannerEvent.visibilityDetails?.externalUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button variant="primaryLime">
                {t('common:view-entity', { entity: t('common:event') })}
              </Button>
            </a>
          ) : (
            <EventAttendButton
              eventId={bannerEvent.id}
              isAttending={bannerEvent.isAttending}
            />
          )}
        </HeroBanner>
      )}
    </QueryStateGuard>
  );
};
