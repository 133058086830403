import { ReactNode } from 'react';
import { useDrop } from 'react-dnd';

import { Box } from '@chakra-ui/react';

type UseDropProps = {
  isDragging: boolean;
  isOver: boolean;
};

const getBackground = ({ isDragging, isOver }: UseDropProps) => {
  if (isDragging && isOver) return '#061227';
  if (isDragging) return '#0f2e65';

  return 'inherit';
};

type Props<T> = {
  children: ReactNode;
  accept: string;
  onDrop: (item: T) => void;
};

export const DropContainer = <T extends { id: string | number }>({
  accept,
  children,
  onDrop,
}: Props<T>) => {
  const [{ isDragging, isOver }, drop] = useDrop<T, unknown, UseDropProps>(
    () => ({
      accept,
      drop: (item) => {
        onDrop(item);
      },
      collect: (monitor) => ({
        isDragging: monitor.canDrop(),
        isOver: monitor.isOver(),
      }),
    }),
    [onDrop]
  );

  return (
    <Box background={getBackground({ isDragging, isOver })} ref={drop}>
      {children}
    </Box>
  );
};
