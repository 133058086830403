import { useTranslation } from 'react-i18next';

import { Grid } from '@chakra-ui/react';

import { Section } from 'components/Layout/Sections/Section';
import { ValueCard } from 'components/ValueCard/ValueCard';
import { FamilyValuesOutput } from 'types';
import { flattenValuesOutput } from 'utils/values';

import { ValueSubtitle } from './ValueSubtitle';

type Props = {
  values: FamilyValuesOutput;
};

export const ValuesSection = ({ values }: Props) => {
  const valuesList = flattenValuesOutput(values);
  const { t } = useTranslation(['family']);

  if (valuesList.length === 0) return null;

  return (
    <Section
      title={t('family:charter.section.values.title')}
      subtitleContent={<ValueSubtitle />}
    >
      <Grid
        w="full"
        gap={4}
        templateColumns="repeat(2, 1fr)"
        templateRows="repeat(2, 1fr)"
      >
        {valuesList.map((value) => (
          <ValueCard value={value} key={value.id} />
        ))}
      </Grid>
    </Section>
  );
};
