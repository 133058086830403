import { ComponentStyleConfig } from '@chakra-ui/react';

import { Button as NewButton } from './Button';
import { OldButton } from './OldButton';

export const Button: ComponentStyleConfig = {
  ...OldButton,
  variants: {
    ...OldButton.variants,
    ...NewButton.variants,
  },
  sizes: {
    ...OldButton.sizes,
    ...NewButton.sizes,
  },
  baseStyle: NewButton.baseStyle,
  defaultProps: OldButton.defaultProps,
};
