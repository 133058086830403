import { useNavigate } from 'react-router-dom';

import { gql, useMutation } from '@apollo/client';

import { CreateEventForm } from 'features/Cms/types';
import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Links } from 'router/links';
import { Mutation, MutationCreateEventArgs } from 'types';
import { getUpdateFileValue } from 'utils/file';
import { generateUrlById } from 'utils/links';

import { eventsGql } from './useEvents';

export const createEventGql = gql`
  mutation CreateEvent(
    $event: CreateEventInput!
    $heroImage: [Upload!]
    $heroVideo: [Upload!]
  ) {
    createEvent(event: $event, heroImage: $heroImage, heroVideo: $heroVideo) {
      id
    }
  }
`;

export const useCreateEvent = () => {
  const navigate = useNavigate();

  const [createEventRequest, { loading }] = useMutation<
    Pick<Mutation, 'createEvent'>,
    MutationCreateEventArgs
  >(createEventGql, {
    refetchQueries: [eventsGql],
    onCompleted: ({ createEvent: { id } }) => {
      navigate(generateUrlById(Links.editEvent, id));
    },
  });

  const handleCreateEvent = async (event: CreateEventForm) => {
    const { heroImage, heroVideo, ...hero } = event;

    const variables = {
      event: { hero },
      heroImage: getUpdateFileValue(heroImage),
      heroVideo: getUpdateFileValue(heroVideo),
    };

    return createEventRequest({ variables });
  };

  const createEvent = useWithMutationToast(handleCreateEvent, {
    successMessage: 'The event was successfully created',
    errorMessage: 'Creating failed',
  });

  return { createEvent, loading };
};
