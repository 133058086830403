import { FamilyValuesCategoryEnum } from 'types';
import { SdgInternalName } from 'types/Sdg';

export const colors = {
  primary: {
    lime: {
      highlight: '#E2F250',
      default: '#C6D344',
      shadow: '#AAB639',
    },
    blue: {
      highlight: '#083A8E',
      default: '#0A1E40',
      shadow: '#041024',
    },
  },
  ui: {
    background: '#F8F8F8',
    black: '#000000',
    white: '#FFFFFF',
    grey1: '#4E4E4E',
    grey2: '#6C788C',
    grey3: '#D3D3DD',
    grey4: '#ECECF4',
  },
  status: {
    red: {
      tint: '#FFF2F3',
      default: '#A70700',
    },
    green: {
      tint: '#E3FFD6',
      default: '#1A8800',
    },
    amber: {
      tint: '#FFF0C3',
      default: '#FDC20D',
    },
    neutral: {
      tint: '#EDEEFF',
      default: '#0007A5',
    },
  },
  valueCategories: {
    [FamilyValuesCategoryEnum.Organisational]: '#7C9EB1',
    [FamilyValuesCategoryEnum.Contribution]: '#F6C750',
    [FamilyValuesCategoryEnum.Advancement]: '#76C5A2',
    [FamilyValuesCategoryEnum.Societal]: '#89C3D3',
  },
  sdg: {
    [SdgInternalName.Sdg1]: {
      tint: '#E9BEC3',
      default: '#E5243B',
    },
    [SdgInternalName.Sdg2]: {
      tint: '#DFCFAF',
      default: '#DDA63A',
    },
    [SdgInternalName.Sdg3]: {
      tint: '#C1DCBB',
      default: '#4C9F38',
    },
    [SdgInternalName.Sdg4]: {
      tint: '#E2BEBE',
      default: '#C5192D',
    },
    [SdgInternalName.Sdg5]: {
      tint: '#FBC7C0',
      default: '#FF3A21',
    },
    [SdgInternalName.Sdg6]: {
      tint: '#C8E3EA',
      default: '#26BDE2',
    },
    [SdgInternalName.Sdg7]: {
      tint: '#FFEFBC',
      default: '#FCC30B',
    },
    [SdgInternalName.Sdg8]: {
      tint: '#C8B0B7',
      default: '#A21942',
    },
    [SdgInternalName.Sdg9]: {
      tint: '#FDD4C2',
      default: '#FD6925',
    },
    [SdgInternalName.Sdg10]: {
      tint: '#E8C2D2',
      default: '#DD1367',
    },
    [SdgInternalName.Sdg11]: {
      tint: '#FFE4C2',
      default: '#FD9D24',
    },
    [SdgInternalName.Sdg12]: {
      tint: '#E7D6B8',
      default: '#BF8B2E',
    },
    [SdgInternalName.Sdg13]: {
      tint: '#BFCDC0',
      default: '#3F7E44',
    },
    [SdgInternalName.Sdg14]: {
      tint: '#C9DEE8',
      default: '#0A97D9',
    },
    [SdgInternalName.Sdg15]: {
      tint: '#CFE1C7',
      default: '#56C02B',
    },
    [SdgInternalName.Sdg16]: {
      tint: '#C3D9E3',
      default: '#00689D',
    },
    [SdgInternalName.Sdg17]: {
      tint: '#C5D0D9',
      default: '#19486A',
    },
  },
};
