import { gql, useQuery } from '@apollo/client';

import { Query, QueryExtendedProfileArgs } from 'types';
import { ExtendedProfileOutputFragment } from 'types/generated-fragments';

export const extendedProfileGql = gql`
  ${ExtendedProfileOutputFragment}
  query ExtendedProfile($accountId: String!) {
    extendedProfile(accountId: $accountId) {
      ...ExtendedProfileOutputFragment
    }
  }
`;

export const useExtendedProfile = (accountId?: string) => {
  const { data, loading, error } = useQuery<
    Pick<Query, 'extendedProfile'>,
    Partial<QueryExtendedProfileArgs>
  >(extendedProfileGql, { variables: { accountId }, skip: !accountId });

  const extendedProfile = data?.extendedProfile;

  return { extendedProfile, loading, error };
};
