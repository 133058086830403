import { ComponentStyleConfig } from '@chakra-ui/react';

export const textStyles = {
  baseStyle: {
    fontSize: 'sm',
  },
  sizes: {
    p1: {
      fontSize: '16px',
      lineHeight: '22px',
      fontFamily: 'Roboto',
      fontWeight: 400,
    },
    p1b: {
      fontSize: '16px',
      lineHeight: '22px',
      fontFamily: 'Roboto',
      fontWeight: 700,
    },
    p2: {
      fontSize: '14px',
      lineHeight: '18px',
      fontFamily: 'Roboto',
    },
    p2b: {
      fontSize: '14px',
      lineHeight: '18px',
      fontFamily: 'Roboto',
      fontWeight: 700,
    },
    p3: {
      fontSize: '12px',
      lineHeight: '16px',
      fontFamily: 'Roboto',
    },
    p3b: {
      fontSize: '12px',
      lineHeight: '16px',
      fontFamily: 'Roboto',
      fontWeight: 700,
    },
  },
};

export const Text: ComponentStyleConfig = textStyles;
