import { Box } from '@chakra-ui/react';

import { ButtonsGroup } from 'components/Button/ButtonsGroup';
import { Header } from 'components/Layout/Header';
import { Links } from 'router/links';

import { SdgIntroLayout } from '../../components/Layout';
import { WelcomeToSdgs } from '../../components/SdgConfigurator';
import { SectionHeading } from '../../components/SectionHeader';

export const WelcomeSdgPage = () => {
  return (
    <SdgIntroLayout>
      <Box
        top="0"
        left="0"
        position="fixed"
        as="video"
        w="full"
        h="full"
        autoPlay
        muted
        loop
        objectFit="cover"
        src="/assets/video/sdgs_welcome.mov"
      />

      <Box
        top="0"
        left="0"
        position="fixed"
        w="full"
        h="full"
        background="linear-gradient(51.57deg, #0A1E40 12.43%, rgba(92, 103, 122, 0.561294) 32.59%, rgba(196, 196, 196, 0) 58.39%)"
        mix-blend-mode="multiply"
      />

      <Header
        showDivider
        color="ui.white"
        containerStyling={{ pl: 20, pr: 20 }}
        isSticky={false}
      />

      <Box
        mx={{ base: 20 }}
        mr={{ lg: 'full' }}
        pt={16}
        w={{ lg: '590px', base: 'auto' }}
        zIndex="10"
      >
        <SectionHeading
          heading="Welcome to the Itriom Sustainable Development Goals (SDGs) Configurator"
          subheading="We will now guide you through our platform's online process."
          color="ui.white"
          containerStyling={{ mr: 'none' }}
        />
        <WelcomeToSdgs />
      </Box>
      <Box mt="auto" px={16} py={12}>
        <ButtonsGroup
          rightButton={{ text: 'Next', link: Links.sdgConfiguratorWhatAre }}
        />
      </Box>
    </SdgIntroLayout>
  );
};
