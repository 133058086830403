import React from 'react';

import { Conversation, Profile } from 'types';

import {
  ConversationsActions,
  ConversationsActionsUnion,
  ConversationsActionTypes,
} from './types';

export const composeConversationsActions = (
  dispatch: React.Dispatch<ConversationsActionsUnion>
): ConversationsActions => ({
  addConversation: (conversation: Conversation) =>
    dispatch({
      type: ConversationsActionTypes.AddConversation,
      payload: conversation,
    }),

  addToUnread: (conversationId: Conversation['id']) =>
    dispatch({
      type: ConversationsActionTypes.AddToUnread,
      payload: conversationId,
    }),

  setConversations: (conversations: Conversation[]) =>
    dispatch({
      type: ConversationsActionTypes.SetConversations,
      payload: conversations,
    }),

  setConversationsTotalCount: (conversationsTotalCount: number) =>
    dispatch({
      type: ConversationsActionTypes.SetConversationsTotalCount,
      payload: conversationsTotalCount,
    }),

  deleteConversation: (conversationId: Conversation['id']) =>
    dispatch({
      type: ConversationsActionTypes.DeleteConversation,
      payload: conversationId,
    }),

  handleConversationSelect: (conversationId: Conversation['id'] | undefined) =>
    dispatch({
      type: ConversationsActionTypes.HandleConversationSelect,
      payload: conversationId,
    }),

  handleSelectConversationByUser: (user: Profile) =>
    dispatch({
      type: ConversationsActionTypes.HandleSelectConversationByUser,
      payload: user,
    }),

  handleInterlocutorTrigger: (user: Profile) =>
    dispatch({
      type: ConversationsActionTypes.HandleInterlocutorTrigger,
      payload: user,
    }),
});
