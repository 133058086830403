import { Avatar, Flex, Heading } from '@chakra-ui/react';

type Props = {
  avatarSrc?: string;
  name: string;
};

export const PageHeader = ({ avatarSrc, name }: Props) => (
  <Flex align="center" gap={2}>
    {avatarSrc && (
      <Avatar size="sm" src={avatarSrc} name={name} mt="auto" display="block" />
    )}

    <Heading size="h3" mt={6}>
      {name}
    </Heading>
  </Flex>
);
