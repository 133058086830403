import { useNavigate } from 'react-router-dom';

import { gql, MutationHookOptions, useMutation } from '@apollo/client';

import { Links } from 'router/links';
import { Mutation, MutationForgotPasswordArgs } from 'types';

import { useWithMutationToast } from '../useWithMutationToast';

const forgotPasswordGql = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

export const useForgotPassword = <
  TData extends Pick<Mutation, 'forgotPassword'>,
  TVariables extends MutationForgotPasswordArgs
>(
  options?: MutationHookOptions<TData, TVariables>
) => {
  const navigate = useNavigate();

  const [mutation, { loading, error }] = useMutation<TData, TVariables>(
    forgotPasswordGql,
    {
      ...options,
    }
  );

  const handleMutation = (variables: TVariables) =>
    mutation({ variables }).then(() => {
      sessionStorage.setItem('userEmail', variables.email);
      navigate(Links.forgotPasswordCheckMail);
    });
  const forgotPassword = useWithMutationToast(handleMutation, {
    successMessage: 'Successful sent email with further instructions',
    disableErrorMessage: true,
  });

  return {
    forgotPassword,
    loading,
    error,
  };
};
