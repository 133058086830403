import { useEffect } from 'react';

import { useGetLibraryMediaFavorites } from 'gql/library/query/getLibraryMediaFavorites';
import { useGetResourcesFromDirectoryInLibrary } from 'gql/library/query/getResourcesFromDirectoryInLibrary';
import { useGetSharedResourcesFromLibrary } from 'gql/library/query/getSharedResourcesFromLibrary';
import { useGetTrashesFromLibrary } from 'gql/library/query/getTrashesFromLibrary';
import {
  LibraryMediaOutput,
  LibrarySharedMediaOutput,
  LibraryTypeEnum,
} from 'types';
import { groupBy } from 'utils/groupBy';

import { LibraryLocation } from '../types';
import { mapLibraryLocationToLibraryType } from '../utils/mapLibraryLocationToLibraryType';
import { useCurrentLibraryLocation } from './useCurrentLibraryLocation';
import { useCurrentResources } from './useCurrentResources';

export const useLibrary = () => {
  const { libraryLocation, folderId } = useCurrentLibraryLocation();

  const favourites = useGetLibraryMediaFavorites({
    skip: libraryLocation !== LibraryLocation.Favourites,
  });

  const directory = useGetResourcesFromDirectoryInLibrary({
    libraryType: mapLibraryLocationToLibraryType(libraryLocation),
    destinationId: folderId,
    skip:
      libraryLocation &&
      [
        LibraryLocation.RecentlyDeleted,
        LibraryLocation.Favourites,
        LibraryLocation.SharedWithMe,
      ].includes(libraryLocation),
  });

  const trash = useGetTrashesFromLibrary({
    skip: libraryLocation !== LibraryLocation.RecentlyDeleted,
    libraryType: LibraryTypeEnum.Own,
  });

  const shared = useGetSharedResourcesFromLibrary({
    destinationId: folderId,
  });

  const { resources, error, loading, refetch } = useCurrentResources({
    directory,
    trash,
    favourites,
    shared,
  });

  const { files, folders } =
    resources && !error && !loading
      ? groupBy<LibraryMediaOutput | LibrarySharedMediaOutput>(
          resources,
          (value) => (value.isDirectory ? 'folders' : 'files')
        )
      : { files: [], folders: [] };

  useEffect(() => {
    refetch();
  }, [folderId, refetch]);

  return { files, folders, error, loading };
};
