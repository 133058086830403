import { useTranslation } from 'react-i18next';

import { CheckboxInput } from 'components/Form/CheckboxInput';
import { UserFormFieldName } from 'features/Cms/types';

type Props = {
  onChange?: VoidFunction;
};

export const HeadOfFamilyCheckboxBase = ({ onChange }: Props) => {
  const { t } = useTranslation(['cms', 'common']);

  return (
    <CheckboxInput
      name={UserFormFieldName.IsHeadOfFamily}
      label={t('cms:user.family-head-checkbox-label')}
      containerStyles={{ marginBottom: 8 }}
      onChange={onChange}
    />
  );
};
