import { useFormik } from 'formik';

import { useDisclosure } from '@chakra-ui/react';

import { useAuthContext } from 'contexts/AuthContext';
import { useCreateGroup, useUpdateGroup } from 'hooks/groups';
import { Group, GroupCreateInput, GroupUpdateInput, Media } from 'types';
import { getUpdateFileValue, mediaOutputToArray } from 'utils/file';

import { validationGroupSchema } from './validationGroupSchema';

type Props = {
  group?: Group;
  isOpen?: boolean;
  onClose(): void;
};

type GroupFormikValues = {
  heroImage?: Media;
  avatarImage?: Media;
  isPublic: boolean;
} & (GroupCreateInput & GroupUpdateInput);

export const useGroupDialog = ({ onClose, group }: Props) => {
  const { account } = useAuthContext();
  const publicGroupConfirmationModal = useDisclosure();

  const isNewGroup = !group;

  const { createGroup } = useCreateGroup();
  const { updateGroup } = useUpdateGroup();

  const initialValues: GroupFormikValues = {
    id: group?.id ?? '',
    name: group?.name ?? '',
    description: group?.description || '',
    allowInvites: group?.allowInvites ?? false,
    familyOnly: group?.familyOnly ?? false,
    familyId: account?.familyId,
    category: group?.category ?? '',
    type: group?.type,
    isPublic: !group?.familyOnly,
    heroImage: mediaOutputToArray(group?.heroImage),
    avatarImage: mediaOutputToArray(group?.avatarImage),
    automaticallyAddFamilyMembers:
      group?.automaticallyAddFamilyMembers ?? false,
    sdgId: group?.sdg.id ?? '',
    adminId: group?.admins[0]?.id,
    advisorIds: group?.advisors.map((advisor) => advisor.id),
  };

  const handleSubmit = async (values: GroupFormikValues) => {
    const {
      heroImage,
      avatarImage,
      isPublic,
      adminId,
      id,
      type,
      ...groupValues
    } = values;

    const shouldShowConfirmationModal =
      isPublic && initialValues.familyOnly !== values.familyOnly;

    if (shouldShowConfirmationModal && !publicGroupConfirmationModal.isOpen) {
      publicGroupConfirmationModal.onOpen();

      return;
    }

    if (isNewGroup) {
      const heroImageFile = getUpdateFileValue(heroImage);

      if (!heroImageFile) {
        throw new Error('A banner image is required when creating a group.');
      }

      await createGroup({
        heroImage: heroImageFile,
        avatarImage: getUpdateFileValue(avatarImage) || [],
        group: {
          ...groupValues,
          type: type || undefined,
          ...(groupValues.automaticallyAddFamilyMembers && {
            familyId: account?.familyId,
          }),
        },
      });
    } else if (id) {
      await updateGroup({
        heroImage: getUpdateFileValue(heroImage) || [],
        avatarImage: getUpdateFileValue(avatarImage) || [],
        group: {
          id,
          ...groupValues,
          type: type || undefined,
          adminId: group?.canMakeAdmins ? adminId : undefined,
        },
      });
    }

    publicGroupConfirmationModal.onClose();
    onClose();
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationGroupSchema,
    onSubmit: handleSubmit,
  });

  return {
    formik,
    publicGroupConfirmationModal,
    isNewGroup,
  };
};
