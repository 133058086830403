import { ComponentType, WithChildren } from 'types';

type Props<T extends object> = {
  Wrapper?: ComponentType<WithChildren<T>> | null | false;
  props?: T;
};

export const ConditionalWrapper = <T extends object>({
  Wrapper,
  children,
  props = {} as T,
}: WithChildren<Props<T>>) => {
  if (!Wrapper) return <>{children}</>;

  return <Wrapper {...props}>{children}</Wrapper>;
};
