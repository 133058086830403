import { useTranslation } from 'react-i18next';

import { StarFilled } from '@carbon/icons-react/next';
import { Button } from '@chakra-ui/react';

import { useLibraryContext } from 'features/Library/context';

export const FavouritesFilter = () => {
  const { t } = useTranslation(['library']);
  const { toggleFavouritesFilter, favouritesFilterEnabled } =
    useLibraryContext();

  return (
    <Button
      variant="ghost"
      isActive={favouritesFilterEnabled}
      leftIcon={<StarFilled />}
      onClick={toggleFavouritesFilter}
    >
      {t('library:favourites')}
    </Button>
  );
};
