import { gql, useMutation } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationSubmitPersonalSdgsArgs, SdgFormType } from 'types';
import { SdgConfiguratorOutputFragment } from 'types/generated-fragments';

export const submitPersonalSdgsGql = gql`
  ${SdgConfiguratorOutputFragment}
  mutation SubmitPersonalSdgs($data: SubmitPersonalSdgsInput!) {
    submitPersonalSdgs(data: $data) {
      ...SdgConfiguratorOutputFragment
    }
  }
`;

export const useSubmitPersonalSdgs = () => {
  const [submitPersonalSdgsMutation] = useMutation<
    Pick<Mutation, 'submitPersonalSdgs'>,
    MutationSubmitPersonalSdgsArgs
  >(submitPersonalSdgsGql, {
    refetchQueries: [
      'PersonalSdgsConfiguration',
      'SdgIdsWithNumericScores',
      'FamilySdgsChoices',
    ],
  });

  const handleSubmit = ({ selected }: SdgFormType) =>
    submitPersonalSdgsMutation({
      variables: {
        data: {
          ids: [...selected]
            .sort((a, b) => a.orderIndex - b.orderIndex)
            .map(({ id }) => id),
        },
      },
    });

  return useWithMutationToast(handleSubmit, {
    successMessage: 'SDG order submitted successfully',
  });
};
