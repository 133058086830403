import { useBreakpointValue } from '@chakra-ui/react';

import { Breakpoints } from './breakpoints';

export const useBreakpoint = () => {
  const value =
    useBreakpointValue({
      sm: Breakpoints.Mobile,
      md: Breakpoints.Tablet,
      lg: Breakpoints.SmallDesktop,
      xl: Breakpoints.Desktop,
    }) || Breakpoints.Mobile;

  return value;
};
