import { useCallback, useState } from 'react';

import { ApolloError, ApolloQueryResult } from '@apollo/client';

/**
 * This is done manually, because apollo is not handling `loading` state
 * with `refetch` and `fetchMore` functions. Furthermore, using
 * `skip` param in `useQuery` breaks `networkState` flag as well
 * https://github.com/apollographql/react-apollo/issues/321
 */
export const useApolloNetworkStateWrapper = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApolloError | undefined>();

  const withNetworkState = useCallback(
    async <TQuery>(fetchCallback: () => Promise<ApolloQueryResult<TQuery>>) => {
      setLoading(true);

      const { data, error: apolloError } = await fetchCallback();

      setLoading(false);

      if (apolloError) {
        setError(apolloError);
      }

      return data;
    },
    []
  );

  return { loading, error, withNetworkState };
};
