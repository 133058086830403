import { animated, useSpring } from 'react-spring';

import { useDelay } from 'hooks/useDelay';
import { WithChildren } from 'types';

import {
  OUTER_RADIUS,
  SCALE_ANIMATION_STATES,
  ANIMATION_VALUE_DELAY,
} from '../../constants';
import { useBuildingAnimationContext } from '../../contexts';
import { Layer, ValueCategory } from '../../types';
import { getBuildingAnimationTimeout } from '../../utils';

type Props = {
  category: ValueCategory;
};

export const AnimatedQuadrant = ({
  children,
  category,
}: WithChildren<Props>) => {
  const { hasDelayElapsed, startDelay } = useDelay(ANIMATION_VALUE_DELAY);
  const { hasFinishedAnimating } = useBuildingAnimationContext();

  const { transform } = useSpring({
    from: SCALE_ANIMATION_STATES.off,
    to: SCALE_ANIMATION_STATES.on,
    config: { tension: 30, friction: 18 },
    onStart: startDelay,
    delay:
      getBuildingAnimationTimeout(category.position) + ANIMATION_VALUE_DELAY,
  });

  const animatedTransform = transform?.to(
    (scale) =>
      `${scale} rotate(${category.position * 90}deg) translate(-50%, -50%)`
  );

  /** This trick fixes bug on Chrome, with wrong font size during animation process,
   * idea is that content should be rendered only after animation starts. This way
   * problem with fonts during animation doesn't occur */
  const content = hasDelayElapsed && children;

  return (
    <animated.div
      key={category.position}
      style={{
        transform: animatedTransform,
        zIndex: hasFinishedAnimating ? Layer.Values : Layer.Category,
        position: 'absolute',
        width: OUTER_RADIUS,
        height: OUTER_RADIUS,
      }}
    >
      {content}
    </animated.div>
  );
};
