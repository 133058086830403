import { Link } from 'react-router-dom';

import { ArrowForwardIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  ButtonProps as ChakraButtonProps,
} from '@chakra-ui/react';

import { ConditionalWrapper } from 'components/ConditionalWrapper';
import { Links } from 'router/links';
import { WithChildren } from 'types';

type ButtonProps = {
  link?: string;
  onClick?: () => void;
  text?: string;
  color?: ChakraButtonProps['color'];
};

const withLinkWrapper = (to: string) => {
  const Wrapper = ({ children }: WithChildren) => (
    <Link to={to}>{children}</Link>
  );

  return Wrapper;
};

export type ButtonsGroupProps = {
  leftButton?: ButtonProps;
  rightButton?: ButtonProps;
};

export const ButtonsGroup = ({
  leftButton,
  rightButton,
}: ButtonsGroupProps) => {
  const {
    link: leftButtonLink,
    color: leftButtonColor = 'ui.white',
    text: leftButtonText = 'Back',
    onClick: leftOnClick,
  } = leftButton || {};

  const {
    link: rightButtonLink,
    color: rightButtonColor = 'primary.blue.default',
    text: rightButtonText = 'Next',
    onClick: rightOnClick,
  } = rightButton || {};

  return (
    <Flex justify="space-between" w="full" fontFamily="Roboto" mt={4}>
      {leftButton && (
        <ConditionalWrapper
          Wrapper={
            !!leftButtonLink && withLinkWrapper(leftButtonLink || Links.home)
          }
        >
          <Button
            variant="outline"
            color={leftButtonColor}
            borderColor={leftButtonColor}
            borderRadius={3}
            onClick={leftOnClick}
          >
            {leftButtonText}
          </Button>
        </ConditionalWrapper>
      )}

      <Flex flex={1} />
      {rightButton && (
        <ConditionalWrapper
          Wrapper={
            !!rightButtonLink && withLinkWrapper(rightButtonLink || Links.home)
          }
        >
          <Button
            onClick={rightOnClick}
            rightIcon={<ArrowForwardIcon />}
            bgColor="primary.lime.default"
            color={rightButtonColor}
            borderRadius={3}
            borderColor="primary.lime.default"
          >
            {rightButtonText}
          </Button>
        </ConditionalWrapper>
      )}
    </Flex>
  );
};
