import { createIcon } from '@chakra-ui/icons';

import { FamilyValuesCategoryEnum } from 'types';

import { DEFAULT_VIEWBOX } from './constants';
import { ICONS } from './icons';

type Props = {
  category: FamilyValuesCategoryEnum;
};

export const ValueCategoryIcon = ({ category }: Props) => {
  const Icon = createIcon({
    viewBox: DEFAULT_VIEWBOX,
    ...ICONS[category],
  });

  return <Icon />;
};
