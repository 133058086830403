import { Link } from 'react-router-dom';

import { Grid, Image, Text, GridItem, Heading, Flex } from '@chakra-ui/react';

import { ItriomTag } from 'components/ItriomTag';
import { Links } from 'router/links';
import { Event } from 'types';
import { formatDate } from 'utils/date';
import { generateUrlById } from 'utils/links';
import { flattenTags } from 'utils/tags';

interface Props {
  result: Event;
}

export const EventResult = ({ result }: Props) => {
  return (
    <Grid
      templateColumns="1fr 1fr"
      templateRows="repeat(2, auto) 1fr"
      gap={4}
      as={Link}
      to={generateUrlById(Links.event, result.id)}
    >
      <GridItem rowSpan={3}>
        <Image
          src={result.hero.heroImage?.signedUrl}
          w="full"
          css={{ aspectRatio: '1/1' }}
          fit="cover"
        />
      </GridItem>
      <GridItem>
        <Heading size="h4">{result.hero.name}</Heading>
      </GridItem>
      <GridItem as={Flex} gap="2" alignItems="center">
        {result.basicInfo?.startTime && (
          <Text size="p2">
            {formatDate(result.basicInfo.startTime.datetime, 'dd/MM')}
          </Text>
        )}
        {flattenTags(result.tags).map((tag) => (
          <ItriomTag key={tag}>{tag}</ItriomTag>
        ))}
      </GridItem>
      <GridItem>
        <Text size="p1" noOfLines={6}>
          {result.hero.summary}
        </Text>
      </GridItem>
    </Grid>
  );
};
