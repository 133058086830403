import { CheckIcon } from '@chakra-ui/icons';
import { Box, BoxProps, Flex, Tooltip } from '@chakra-ui/react';

import { OnboardMemberActionEnum } from 'types';

import { Cell } from './Cell';

const stylingStatus: Record<OnboardMemberActionEnum, BoxProps> = {
  [OnboardMemberActionEnum.Sent]: {
    borderColor: 'transparent',
    background: 'darkBlue5',
  },
  [OnboardMemberActionEnum.Scheduled]: {
    borderColor: 'positive',
    background: 'transparent',
  },
  [OnboardMemberActionEnum.NotScheduled]: {
    borderColor: 'darkBlue4',
    background: 'transparent',
  },
};

type RsvpCellProps = {
  status: OnboardMemberActionEnum;
};

export const RsvpCell = ({ status }: RsvpCellProps) => (
  <Cell center>
    <Flex w="100%" h="100%">
      <Box
        margin="auto"
        width="24px"
        height="24px"
        borderRadius="50%"
        border="1px solid"
        {...stylingStatus[status]}
      >
        {[
          OnboardMemberActionEnum.Scheduled,
          OnboardMemberActionEnum.Sent,
        ].includes(status) && (
          <CheckIcon
            w="14px"
            h="14px"
            color={
              status === OnboardMemberActionEnum.Sent
                ? 'primary.blue.default'
                : 'positive'
            }
          />
        )}
      </Box>
    </Flex>
  </Cell>
);

type RsvpHeaderCellProps = {
  title: string;
  description: string;
};

export const RsvpHeaderCell = ({ title, description }: RsvpHeaderCellProps) => (
  <Cell center width={50}>
    <Tooltip hasArrow label={description} aria-label={title}>
      {title}
    </Tooltip>
  </Cell>
);
