import { useTranslation } from 'react-i18next';

import { Flex, Text } from '@chakra-ui/react';

import { CarouselBase } from 'components/Carousel/CarouselBase';
import { Section } from 'components/Layout/Sections/Section';
import { MarkdownPreview } from 'components/MarkdownPreview';
import { ProfileImageCard } from 'components/ProfileImageCard';
import { PrincipalAboutOutput } from 'types';

export const AboutSection = ({ about }: { about?: PrincipalAboutOutput }) => {
  const { t } = useTranslation(['profile']);

  if (!about || (!about.content && !about.images)) return null;

  return (
    <Section
      title={t('profile:sections.about-you.title')}
      subtitleContent={
        <Text size="p2">{t('profile:sections.about-you.description')}</Text>
      }
    >
      <Flex direction="column" gap={8}>
        <MarkdownPreview content={about.content} />
        <CarouselBase
          items={about.images || []}
          spaceBetween={16}
          ItemComponent={ProfileImageCard}
        />
      </Flex>
    </Section>
  );
};
