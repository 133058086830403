import { gql, useQuery } from '@apollo/client';

import { Query, QueryPostsArgs } from 'types';
import {
  CommentFragment,
  PostNoNestingFragment,
  TagOutputNoNestingFragment,
} from 'types/generated-fragments';

export const postsGql = gql`
  ${PostNoNestingFragment}
  ${TagOutputNoNestingFragment}
  ${CommentFragment}
  query Posts($pagination: PaginationInput!, $filters: PostFilters) {
    posts(pagination: $pagination, filters: $filters) {
      nodes {
        ...PostNoNestingFragment
        tags {
          ...TagOutputNoNestingFragment
        }
        images {
          signedUrl
        }
        postedBy {
          id
          status
          firstName
          lastName
          title
          avatarImage {
            signedUrl
          }
        }
        comments {
          ...CommentFragment
          comments {
            ...CommentFragment
            postedBy {
              id
              status
              firstName
              lastName
              title
              avatarImage {
                signedUrl
              }
            }
          }
          postedBy {
            id
            status
            firstName
            lastName
            title
            avatarImage {
              signedUrl
            }
          }
        }
      }
    }
  }
`;

export const usePosts = (variables: QueryPostsArgs) => {
  const { data, loading, error, refetch } = useQuery<
    Pick<Query, 'posts'>,
    QueryPostsArgs
  >(postsGql, { variables, fetchPolicy: 'cache-and-network' });
  const posts = data?.posts?.nodes ?? [];

  return { posts, loading, error, refetch };
};
