import { OrderedList } from '@chakra-ui/react';

import { useSendNotification } from 'hooks/onboarding/useSendNotification';
import { OnboardManagementOutput } from 'types';

import { CmsSection } from '../../../CmsSection';
import { NotificationRow } from './NotificationRow';

type Props = {
  onboardManagement: OnboardManagementOutput;
};

export const AdvisorTriggersAndNotificationsSection = ({
  onboardManagement,
}: Props) => {
  const { actions, referenceId, referenceType } = onboardManagement;
  const { sendAdvisorInvite } = useSendNotification(referenceId, referenceType);

  const handleClick = () => sendAdvisorInvite(true);

  return (
    <CmsSection title="Triggers and notifications">
      <OrderedList>
        <NotificationRow
          label="Send emails inviting Advisors to see their profile in the Itriom Web app"
          handleTestClick={handleClick}
          handleSendClick={sendAdvisorInvite}
          isSent={actions.profileCompletedEmailSent}
          question="Ask Advisors to see their profile in the Itriom Web app`"
        />
      </OrderedList>
    </CmsSection>
  );
};
