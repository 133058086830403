import { Account, InitiativeOutput, InitiativeStatusEnum } from 'types';
import { isItriomStaff } from 'utils/roles';

export const shouldForbidContentDisplay = (
  loading: boolean,
  initiative?: InitiativeOutput,
  user?: Account
) => {
  if (loading) return false;
  if (!initiative) return false;
  if (!user) return true;

  const isDraft = initiative.status === InitiativeStatusEnum.Draft;
  if (!isDraft) return false;

  if (isItriomStaff(user.role)) return false;

  if (initiative.createdBy === user.id) return false;

  if (
    initiative.visibilityDetails?.family &&
    initiative.visibilityDetails.familyOwnersIds.some(
      (familyId) => familyId === user.familyId
    )
  ) {
    return false;
  }

  return true;
};
