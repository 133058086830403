import { useMessagingCommunication } from '../MessagingCommunication';
import { useMessagesActions } from '../MessagingStateManager/hooks';

export const useLoadMessages = () => {
  const { loadMessagesForConversation } = useMessagingCommunication();

  const { setMessages, setMessagesTotalCount } = useMessagesActions();

  const loadMessages = (conversationId: string) => {
    loadMessagesForConversation(conversationId).then((messages) => {
      setMessages(conversationId, messages.nodes);
      setMessagesTotalCount(conversationId, messages.totalCount);
    });
  };

  return loadMessages;
};
