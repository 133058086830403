import { Center, Heading } from '@chakra-ui/react';

import { Loader } from 'components/Loader';
import { useAuthContext } from 'contexts/AuthContext';

export const Page404 = () => {
  const { loading } = useAuthContext();

  if (loading) return <Loader />;

  return (
    <Center my={10}>
      <Heading>404 | Page not found</Heading>
    </Center>
  );
};
