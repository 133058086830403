import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Links } from 'router/links';
import { LocalStorageKeys } from 'types';

const NON_REDIRECTABLE_URLS = [
  Links.twoFA,
  Links.newPassword,
  Links.signIn,
  Links.forgotPassword,
  Links.forgotPasswordCheckMail,
  Links.forgotPasswordGoToPlatform,
  Links.forgotPasswordOtp,
  Links.onboardingOtpSetPassword,
  Links.onboardingOtpSetPasswordSuccess,
];

export const useRedirectUrl = () => {
  const navigate = useNavigate();
  const redirectUrl = localStorage.getItem(LocalStorageKeys.REDIRECT_URL);

  const navigateToRedirectUrl = useCallback(() => {
    navigate(redirectUrl || Links.home);
    localStorage.removeItem(LocalStorageKeys.REDIRECT_URL);
  }, [navigate, redirectUrl]);

  const memorizeCurrentUrl = () => {
    const url = window.location.pathname + window.location.search;

    const isNonRedirectableUrl = NON_REDIRECTABLE_URLS.some(
      (nonRedirectableUrl) => nonRedirectableUrl.includes(url)
    );

    if (isNonRedirectableUrl) return;

    localStorage.setItem(LocalStorageKeys.REDIRECT_URL, url);
  };

  return { redirectUrl, memorizeCurrentUrl, navigateToRedirectUrl };
};
