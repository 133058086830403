import {
  Goal,
  MediaOutput,
  Media,
  CreateEventInput,
  InsightHeroWithoutIdInput,
  PrincipalHeroWithoutIdInput,
  PrincipalHeroInput,
  CreateAccountByAdminInput,
  OnboardUpdateMemberDetailsInput,
  CreateAdvisorAccountInput,
} from 'types';

export type TAddGoal = (goal: Goal) => void;
export type TMoveGoal = (goal: Goal, move: 'up' | 'down') => void;
export type TDeleteGoal = (id: Goal['id']) => void;
export type TEditGoal = (goal: Goal) => void;

type MediaInForm = {
  heroImage?: Media;
  heroVideo?: Media;
};

export type EventForm<T> = T & MediaInForm;
export type CreateEventForm = EventForm<CreateEventInput['hero']>;

export type ImageFormType = MediaOutput[] | null | undefined;
export type ImageForm = {
  imageForm?: ImageFormType;
};

export type CreateInsightForm = InsightHeroWithoutIdInput & {
  heroImage: ImageFormType;
};

type PrincipalHeroImages = {
  avatarRound?: ImageFormType;
  avatarSquare?: ImageFormType;
  banner?: ImageFormType;
};

export type CreatePrincipalForm = PrincipalHeroWithoutIdInput &
  PrincipalHeroImages;
export type EditPrincipalForm = PrincipalHeroInput & PrincipalHeroImages;

export type ReassignActionType =
  | 'deactivate'
  | 'activate'
  | 'delete'
  | 'unassign';

type UserFormAdditionalFields = {
  isHeadOfFamily?: boolean;
  isFamilyAdmin?: boolean;
};

export type CreateUserFormFields = UserFormAdditionalFields &
  CreateAccountByAdminInput;

export type UpdateUserFormFields = UserFormAdditionalFields &
  Omit<OnboardUpdateMemberDetailsInput, 'id'>;

export type UserFormType = CreateUserFormFields | UpdateUserFormFields;

export type CreateAdvisorFormFields = CreateAdvisorAccountInput;

export type AdvisorFormType = CreateAdvisorFormFields | UpdateUserFormFields;

export enum UserFormFieldName {
  FirstName = 'firstName',
  LastName = 'lastName',
  Honorific = 'honorific',
  PhoneCountryCode = 'countryCode',
  PhoneBase = 'number',
  Email = 'email',
  IsHeadOfFamily = 'isHeadOfFamily',
  FamilyName = 'familyName',
  FamilyId = 'familyId',
  IsFamilyAdmin = 'isFamilyAdmin',
  dateOfBirth = 'dateOfBirth',
}
