import { gql, useQuery } from '@apollo/client';

import { Query } from 'types';
import { EsgCategoryNoNestingFragment } from 'types/generated-fragments';

export const esgCategoriesGql = gql`
  ${EsgCategoryNoNestingFragment}
  query EsgCategories {
    esgCategories {
      ...EsgCategoryNoNestingFragment
    }
  }
`;

export const useEsgCategories = () => {
  const { data, loading, error } =
    useQuery<Pick<Query, 'esgCategories'>>(esgCategoriesGql);

  const esgCategories = data?.esgCategories ?? [];

  const getEsgCategory = (id: string) =>
    esgCategories.find((esgCategory) => esgCategory.id === id);

  return { esgCategories, getEsgCategory, loading, error };
};
