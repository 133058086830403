import { Flex, useBreakpointValue } from '@chakra-ui/react';

import { useAuthContext } from 'contexts/AuthContext';
import { useHasConnections } from 'hooks/connections/useHasConnections';
import { isLinkMenuItem } from 'router/guards';
import { Links } from 'router/links';
import { Role } from 'types';

import { DropdownMenuItems } from '../DropdownMenuItem';
import { HeaderLink } from './HeaderLink';
import { MenuIcons } from './MenuIcons';
import { UserMenu } from './UserMenu';

const AVAILABLE_CHARTER_ROLES = [
  Role.FamilyAdmin,
  Role.FamilyMember,
  Role.HeadOfFamily,
];

export const HeaderNav = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const { account } = useAuthContext();
  const { filterMenuLinks } = useHasConnections();
  const navMenuLinks = filterMenuLinks();

  return (
    <Flex w="full" h="full" alignItems="center" justifyContent="space-between">
      {(!account || isMobile) && <Flex flex={1} />}

      {!isMobile && (
        <Flex
          as="nav"
          role="navigation"
          alignItems="center"
          aria-label="Main Navigation"
          gap={6}
          h="full"
        >
          {navMenuLinks.map((menuItem) => {
            if (isLinkMenuItem(menuItem)) {
              if (
                menuItem.link === Links.charter &&
                account?.role &&
                !AVAILABLE_CHARTER_ROLES.includes(account?.role)
              ) {
                return null;
              }

              return (
                <HeaderLink
                  to={menuItem.link}
                  key={menuItem.label}
                  label={menuItem.label}
                />
              );
            }

            return <DropdownMenuItems item={menuItem} key={menuItem.label} />;
          })}
        </Flex>
      )}

      <Flex height="100%" alignItems="center" gap={2}>
        {account && <MenuIcons />}
        <UserMenu account={account} isMobile={isMobile} />
      </Flex>
    </Flex>
  );
};
