import { Grid } from '@chakra-ui/react';

import { InitiativeHeroOutput } from 'types';

import { InitiativeChart } from './InitiativeChart';

type Props = {
  initiativeHero: InitiativeHeroOutput;
};

export const InitiativeCharts = ({ initiativeHero }: Props) => (
  <Grid
    w="100%"
    ml="auto"
    templateColumns={{
      base: '1fr',
      lg: 'repeat(3, 1fr)',
    }}
    gap={4}
  >
    <InitiativeChart
      header={initiativeHero.leftChartHeadline}
      subheader={initiativeHero.leftChartSubheadline}
      url={initiativeHero.leftChartImage?.signedUrl}
    />
    <InitiativeChart url={initiativeHero.locationImage?.signedUrl} />
    <InitiativeChart
      header={initiativeHero.rightChartHeadline}
      subheader={initiativeHero.rightChartSubheadline}
      url={initiativeHero.rightChartImage?.signedUrl}
    />
  </Grid>
);
