import { format } from 'date-fns';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Flex, Heading, Image, Text } from '@chakra-ui/react';

import { ViewButton } from 'components/Button';
import { ItriomTag } from 'components/ItriomTag';
import { Links } from 'router/links';
import { Event, EventHero } from 'types';
import { generateUrlById } from 'utils/links';
import { flattenTags } from 'utils/tags';

export const EventCard = ({
  event,
  hero,
}: {
  event: Event;
  hero: EventHero;
}) => {
  const { id, basicInfo, tags } = event;
  const { name, summary, heroImage, eventUrl } = hero;
  const { t } = useTranslation(['events']);
  const startDateValue = basicInfo?.startTime?.datetime || 0;
  const startDate = new Date(startDateValue);
  // const endDate = new Date(endTime?.datetime || 0);
  const to = generateUrlById(Links.event, id);

  return (
    <Flex
      justify="space-between"
      py={10}
      borderColor="primary.blue.default"
      borderBottomWidth={1}
      gap={4}
      direction={{ base: 'column', sm: 'row' }}
    >
      <Flex direction="column" gap={4}>
        <Heading size="h3" maxW={{ base: 100, md: 150 }} w="full">
          {format(startDate, 'dd/MM')}
        </Heading>
        {eventUrl && (
          <ItriomTag customVariant="outlineDark">
            {t('events:external-event')}
          </ItriomTag>
        )}
      </Flex>
      <Flex direction={{ base: 'column', md: 'row' }} gap={4} flex={1}>
        <Flex direction="column" gap={2} flex={1}>
          <Heading size="h4" marginLeft={8}>
            {name}
          </Heading>
          <Flex gap={1} wrap="wrap">
            {flattenTags(tags).map((tag) => (
              <ItriomTag key={tag}>{tag}</ItriomTag>
            ))}
          </Flex>
        </Flex>
        <Flex direction="column" justify="space-between" flex={1} gap={8}>
          <Text size="p2">{summary}</Text>
          <ViewButton variant="blue" to={to}>
            View event
          </ViewButton>
          {/* check what's do if times have different days
          <Text>
            {format(startDate, 'HH:mm')} - {format(endDate, 'HH:mm')}
          </Text> */}
        </Flex>
      </Flex>
      <Flex as={Link} to={to} flex={{ base: 1, md: 'none' }}>
        <Image
          src={heroImage?.signedUrl}
          w={{ base: '100%', md: 264, lg: 308 }}
          h={{ base: 204, md: 226, lg: 204 }}
          objectFit="cover"
          objectPosition="center"
        />
      </Flex>
    </Flex>
  );
};
