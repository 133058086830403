import { Form, FormikProvider, useFormik } from 'formik';

import { useRef } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import { Flex } from '@chakra-ui/layout';
import {
  Button,
  Alert,
  AlertIcon,
  AlertDescription,
  AlertTitle,
  CloseButton,
  Box,
  Text,
} from '@chakra-ui/react';

import { TextInput } from 'components/Form/TextInput';
import { RightBlock } from 'components/Layout/RightBlock';
import { useSignIn } from 'hooks/authorization';
import { Links } from 'router/links';

import { signInSchema } from '../components/validation';
import { SignInPageFormValues } from '../types';

const generateRedirectLink = (values: SignInPageFormValues) =>
  `${Links.forgotPassword}${values.email && `?email=${values.email}`}`;

export const SignInPage = () => {
  const submittedEmail = useRef('');
  const { signIn } = useSignIn();
  const [searchParams] = useSearchParams();

  const onSubmit = async ({ email, password }: SignInPageFormValues) => {
    submittedEmail.current = email;
    await signIn({
      email,
      password,
    });
  };

  const formik = useFormik({
    initialValues: { email: '', password: '' },
    validationSchema: signInSchema,
    onSubmit,
  });

  const { isSubmitting } = formik;

  return (
    <>
      {searchParams.get('error') === 'Unauthorized' && (
        <Box p={10}>
          <Alert status="error">
            <AlertIcon />
            <AlertTitle mr={2}>Session expired!</AlertTitle>
            <AlertDescription>Please log in again</AlertDescription>
            <CloseButton position="absolute" right="8px" top="8px" />
          </Alert>
        </Box>
      )}

      <RightBlock title="Sign In">
        <FormikProvider value={formik}>
          <Form style={{ height: '100%' }}>
            <Flex direction="column" height="100%">
              <Flex
                flexGrow={1}
                direction="column"
                gap={4}
                justifyContent="center"
              >
                <TextInput
                  label="Email"
                  name="email"
                  type="email"
                  preventCopy
                  preventPaste
                />
                <TextInput
                  label="Password"
                  name="password"
                  type="password"
                  preventCopy
                  preventPaste
                />
                <Link to={generateRedirectLink(formik.values)}>
                  <Text size="p3">Forgot your password?</Text>
                </Link>
              </Flex>

              <Button
                bgColor="primary.lime.default"
                color="primary.blue.default"
                variant="primaryLime"
                borderRadius={2}
                h={12}
                fontSize="md"
                px={6}
                type="submit"
                justifySelf="self-end"
                alignSelf="self-start"
                isLoading={isSubmitting}
              >
                Sign In
              </Button>
            </Flex>
          </Form>
        </FormikProvider>
      </RightBlock>
    </>
  );
};
