import countryList from 'country-list';

import { Address } from './types';

const parseLocation = (location: Address) => {
  const locationParts = [
    location.addressLine1,
    location.addressLine2,
    location.city,
    location.state,
    countryList.getName(location.country || ''),
  ].filter(Boolean);

  return locationParts.join(',');
};

export const generateMapUrl = (location: Address) => {
  const EMBEDED_MAP_API_KEY = process.env.REACT_APP_EMBEDED_MAPS_API_KEY;
  if (!EMBEDED_MAP_API_KEY || !location) return undefined;

  const url = new URL('/maps/embed/v1/place', 'https://www.google.com');
  url.searchParams.append('key', EMBEDED_MAP_API_KEY);
  url.searchParams.append('q', parseLocation(location));

  return url;
};
