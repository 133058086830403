import { Links } from 'router/links';
import {
  FamilyOnboardStateEnum,
  OnboardAccountStatus,
  OnboardManagementOutput,
} from 'types';

export const getRedirectLink = (
  onboardManagement: OnboardManagementOutput | undefined,
  onboardingStatus: OnboardAccountStatus | undefined,
  isAdvisor?: boolean // FIXME: probably should be removed when we implement a proper advisor onboarding
) => {
  if (!onboardManagement || !onboardingStatus) return undefined;

  const hasFamilyFinishedOnboarding =
    onboardManagement.actions.profileCompletedEmailSent;

  const shouldSetPassword =
    hasFamilyFinishedOnboarding &&
    onboardingStatus === OnboardAccountStatus.InProgress;

  const hasFamilyFinishedSdg =
    onboardManagement.state === FamilyOnboardStateEnum.SdgConfiguratorCompleted;

  const hasFamilyFinishedValuesConfig =
    onboardManagement.state ===
    FamilyOnboardStateEnum.ValueConfiguratorCompleted;

  const hasReceiviedRsvp = onboardManagement.actions.rsvpEmailSent || isAdvisor;

  if (shouldSetPassword) return Links.onboardingOtpSetPassword;

  if (hasFamilyFinishedOnboarding) return Links.home;

  if (hasFamilyFinishedSdg) return Links.sdgConfiguratorFamiliesSection;

  if (hasFamilyFinishedValuesConfig) return Links.valuesConfiguratorScores;

  if (hasReceiviedRsvp) return Links.valuesConfigurator;

  return undefined;
};
