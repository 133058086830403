import { ReactNode } from 'react';

import { Box, Flex, FlexProps } from '@chakra-ui/react';

import { Header } from 'components/Layout/Header';
import { WelcomeLayoutFooter } from 'components/Layout/WelcomeLayout/WelcomeLayoutFooter';
import { WithChildren } from 'types';

type Props = {
  buttons?: ReactNode;
  height?: string;
} & FlexProps;

export const ValuesIntroLayout = ({
  children,
  buttons,
  height = 'auto',
  color,
  ...restProps
}: WithChildren<Props>) => {
  return (
    <Flex color={color} {...restProps}>
      <Flex
        direction="column"
        minH="100vh"
        align="center"
        maxW="1350px"
        w="full"
        mx="auto"
      >
        <Header color={color} showDivider isSticky={false} />
        <Flex
          justify="center"
          align="center"
          direction="column"
          w="full"
          h={height}
          px={12}
          py={8}
        >
          {children}
        </Flex>
        <Box mt="auto" w="full" mb={16} mx={16} px={8} maxW="1350px">
          {buttons}
        </Box>
        <WelcomeLayoutFooter color={color} />
      </Flex>
    </Flex>
  );
};
