import { gql, useQuery } from '@apollo/client';

import { Query } from 'types';
import { ValueConfiguratorOutputDeepNestingFragment } from 'types/generated-fragments';

export const valueConfiguratorGql = gql`
  ${ValueConfiguratorOutputDeepNestingFragment}
  query ValueConfigurator {
    valueConfigurator {
      ...ValueConfiguratorOutputDeepNestingFragment
    }
  }
`;

export const useValueConfiguratorValues = () => {
  const { data, loading, error } =
    useQuery<Pick<Query, 'valueConfigurator'>>(valueConfiguratorGql);
  const valueConfigurator = data?.valueConfigurator;

  return { valueConfigurator, loading, error };
};
