import { LibraryTypeEnum } from 'types';

import { LibraryLocation } from '../types';

export const mapLibraryLocationToLibraryType = (
  location?: LibraryLocation
): LibraryTypeEnum => {
  switch (location) {
    case LibraryLocation.My:
      return LibraryTypeEnum.Own;
    case LibraryLocation.Family:
      return LibraryTypeEnum.Family;
    case LibraryLocation.Itriom:
      return LibraryTypeEnum.Itriom;
    default:
      return LibraryTypeEnum.Own;
  }
};
