import { useAllRecommendedConnections } from 'components/RecommendedConnections/useAllRecommendedConnections';
import { useAdvisorConnections } from 'gql/connections/query/advisorConnections';
import { useFamilyConnections } from 'gql/connections/query/familyConnections';
import { usePeerConnections } from 'gql/connections/query/peerConnections';

import { ConnectionTab } from './types';

export const useConnections = (type: ConnectionTab) => {
  const { familyConnections, ...familyConnectionsStatus } =
    useFamilyConnections();

  const { advisorConnections, ...advisorConnectionsStatus } =
    useAdvisorConnections();

  const { peerConnections, ...peerConnectionsStatus } = usePeerConnections();

  const { recommendedConnections, ...recommendedConnectionsStatus } =
    useAllRecommendedConnections(40);

  /**
   * Commended out as:
   * According to this ticket: https://itriom.atlassian.net/browse/LEIP-83 some sections will
   * be implemented post launch
   */
  // const { eventConnections, ...eventConnectionsStatus } = useEventConnections();

  // const { initiativeConnections, ...initiativeConnectionsStatus } =
  //   useInitiativeConnections();

  // const { itriomConnections, ...itriomConnectionsStatus } =
  //   useItriomConnections();

  switch (type) {
    case ConnectionTab.Advisor:
      return { connections: advisorConnections, ...advisorConnectionsStatus };
    case ConnectionTab.Family:
      return { connections: familyConnections, ...familyConnectionsStatus };
    case ConnectionTab.Peers:
      return { connections: peerConnections, ...peerConnectionsStatus };
    case ConnectionTab.Recommended:
      return {
        connections: recommendedConnections,
        ...recommendedConnectionsStatus,
      };
    default:
      throw new Error(`type [${type}] not implemented`);
  }
};
