import { useFormikContext } from 'formik';

import { Button, Flex } from '@chakra-ui/react';

import { ItriomButtonOutline } from 'components/Button';
import { Modal } from 'components/Modal';
import { AttendanceStatusEnum } from 'types';

import { ATTENDANCE_VALUE, AttendFormValues } from './constants';
import { useSetAttendance } from './useSetAttendance';

type Props = {
  accountId: string;
  onClose: () => void;
  status: AttendanceStatusEnum | null;
};

export const ChangeAttendModal = ({ accountId, onClose, status }: Props) => {
  const { changeStatus, loading } = useSetAttendance(accountId);
  const { setFieldValue } = useFormikContext<AttendFormValues>();

  return (
    <Modal
      isOpen={!!status}
      onClose={onClose}
      title="Setting the attendance of a customer"
      containerStyling={{ p: 4 }}
      footer={
        <Flex w="full" justify="space-between">
          <ItriomButtonOutline onClick={onClose}>Cancel</ItriomButtonOutline>
          <Button
            onClick={() => {
              if (status) {
                changeStatus(status).then(() => {
                  setFieldValue('attendanceStatus', status);
                  onClose();
                });
              }
            }}
            size="lg"
            color="primary.blue.default"
            bgColor="primary.lime.default"
            border="none"
            isLoading={loading}
          >
            {status && ATTENDANCE_VALUE[status]}
          </Button>
        </Flex>
      }
    >
      Please confirm that you would like to mark the customer’s attendance as:
    </Modal>
  );
};
