import { useEffect, useState } from 'react';

import { useDisclosure } from '@chakra-ui/react';

import { makeContext } from 'contexts/MakeContext';
import { useList } from 'hooks/useList';
import { LibraryMediaOutput, LibrarySharedMediaOutput } from 'types';

import { useCurrentLibraryLocation } from './hooks/useCurrentLibraryLocation';

export interface UploadingFile {
  name: string;
  size: string;
  progress?: number;
  hasError?: boolean;
}

export const [useLibraryContext, LibraryProvider] = makeContext(() => {
  const [favouritesFilterEnabled, setFavouritesFilterEnabled] = useState(false);
  const shareModal = useDisclosure();
  const previewModal = useDisclosure();
  const renameModal = useDisclosure();
  const { libraryLocation, folderId } = useCurrentLibraryLocation();

  const [previewItem, setPreviewItem] = useState<
    | ((LibraryMediaOutput | LibrarySharedMediaOutput) & {
        downloadUrl: string;
      })
    | undefined
  >();
  const [renameItem, setRenameItem] = useState<
    (LibraryMediaOutput | LibrarySharedMediaOutput) | undefined
  >();
  const [actionSingleItem, setActionSingleItem] = useState<
    (LibraryMediaOutput | LibrarySharedMediaOutput) | undefined
  >();
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('DESC');
  const { items, addItem, removeItem, clear, contains } = useList<
    LibraryMediaOutput | LibrarySharedMediaOutput
  >();
  const [uploadingItems, setUploadingItems] = useState<UploadingFile[]>([]);
  const [uploadingBubbleState, setUploadingBubbleState] = useState<
    'HIDDEN' | 'VISIBLE' | 'MINIMIZED'
  >('HIDDEN');

  const toggleFavouritesFilter = () =>
    setFavouritesFilterEnabled((prev) => !prev);

  const toggleSortDirection = () => {
    setSortDirection((prev) => (prev === 'ASC' ? 'DESC' : 'ASC'));
  };

  const closeShareModal = () => {
    setActionSingleItem(undefined);
    shareModal.onClose();
  };

  const openPreviewModal = (
    item: LibraryMediaOutput | LibrarySharedMediaOutput,
    downloadUrl: string
  ) => {
    setPreviewItem({ ...item, downloadUrl });
    previewModal.onOpen();
  };

  const closePreviewModal = () => {
    setPreviewItem(undefined);
    previewModal.onClose();
  };

  const addUploadingItems = (newItems: UploadingFile[]) => {
    setUploadingItems((prev) => [...prev, ...newItems]);
  };

  const updateUploadingItem = (item: UploadingFile) => {
    setUploadingItems((prev) =>
      prev.map((existingItem) =>
        existingItem.name === item.name ? item : existingItem
      )
    );
  };

  const removeUploadingItem = (item: UploadingFile) => {
    setUploadingItems((prev) =>
      prev.filter((existingItem) => existingItem.name !== item.name)
    );
  };

  const toggleMinimizeUploadingBubble = () => {
    setUploadingBubbleState((prev) =>
      prev === 'VISIBLE' ? 'MINIMIZED' : 'VISIBLE'
    );
  };

  const hideUploadingBubble = () => {
    setUploadingItems([]);
    setUploadingBubbleState('HIDDEN');
  };

  const showUploadingBubble = () => {
    setUploadingBubbleState('VISIBLE');
  };

  const openRenameModal = (
    item: LibraryMediaOutput | LibrarySharedMediaOutput
  ) => {
    setRenameItem(item);
    renameModal.onOpen();
  };

  useEffect(() => {
    if (!!uploadingItems.length && uploadingBubbleState !== 'MINIMIZED') {
      showUploadingBubble();
    } else if (!uploadingItems.length) {
      hideUploadingBubble();
    }
  }, [uploadingItems.length, uploadingBubbleState]);

  useEffect(() => {
    clear();
  }, [folderId, libraryLocation]);

  return {
    updateUploadingItem,
    uploadingBubbleState,
    hideUploadingBubble,
    showUploadingBubble,
    toggleMinimizeUploadingBubble,
    addUploadingItems,
    removeUploadingItem,
    uploadingItems,
    actionBarSelectedItems: items,
    addActionBarSelectedItem: addItem,
    removeActionBarSelectedItems: removeItem,
    clearActionBarSelectedItems: clear,
    actionBarSelectedItemsContains: contains,
    favouritesFilterEnabled,
    toggleFavouritesFilter,
    sortDirection,
    toggleSortDirection,
    renameModal: {
      item: renameItem,
      onOpen: openRenameModal,
      onClose: () => {
        setRenameItem(undefined);
        renameModal.onClose();
      },
      isOpen: renameModal.isOpen,
    },
    previewModal: {
      item: previewItem,
      onOpen: openPreviewModal,
      onClose: closePreviewModal,
      isOpen: previewModal.isOpen,
    },
    shareModal: {
      onOpen: shareModal.onOpen,
      onClose: closeShareModal,
      isOpen: shareModal.isOpen,
    },
    actionSingleItem,
    setActionSingleItem: (
      item: LibraryMediaOutput | LibrarySharedMediaOutput
    ) => setActionSingleItem(item),
  };
});
