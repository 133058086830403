import { useTranslation, TFuncKey } from 'react-i18next';

import { Flex } from '@chakra-ui/react';

import { BackgroundImageTile } from 'components/ContentGrid/BackgroundImageTile';
import { IntroTile } from 'components/ContentGrid/IntroTile';
import { SideImageTile } from 'components/ContentGrid/SideImageTile';
import { EmptyState } from 'components/EmptyState';
import { InfiniteList } from 'components/InfiniteList';
import { QueryStateGuard } from 'components/QueryStateGuard';
import { useAuthContext } from 'contexts/AuthContext';
import { mapInsightsToGridInsights } from 'features/Insight/mapInsightsToGridInsights';
import { usePaginatedListInsights } from 'hooks/insights';
import { usePaginationState } from 'hooks/pagination';
import { Links } from 'router/links';
import { Insight, InsightItriomVisibilityTypes } from 'types';
import { generateUrlById } from 'utils/links';
import { flattenTags } from 'utils/tags';

import { InsightsGrid } from '../InsightsGrid';

export type InsightTabPanelProps = {
  type: InsightItriomVisibilityTypes;
};

const mapTypeToKey: Record<
  InsightItriomVisibilityTypes,
  Extract<
    TFuncKey<'insights'>,
    'family-news' | 'itriom-news' | 'peers-news' | 'public-news' | 'all-news'
  >
> = {
  [InsightItriomVisibilityTypes.Family]: 'family-news',
  [InsightItriomVisibilityTypes.Itriom]: 'itriom-news',
  [InsightItriomVisibilityTypes.Peers]: 'peers-news',
  [InsightItriomVisibilityTypes.Public]: 'public-news',
  [InsightItriomVisibilityTypes.All]: 'all-news',
};

export const InsightTabPanel = ({ type }: InsightTabPanelProps) => {
  const { account } = useAuthContext();
  const { t } = useTranslation(['insights']);

  const { loadInsights, loadMoreInsights, error, loading } =
    usePaginatedListInsights({
      familyId: account?.familyId,
      type,
    });

  const { hasMore, items, loadMoreItems } = usePaginationState({
    initialLoad: loadInsights,
    loadMore: loadMoreInsights,
  });

  return (
    <QueryStateGuard
      /* loading is handled by `InfiniteList` component */
      error={error}
    >
      {!loading && !error && items.length === 0 ? (
        <EmptyState
          title={t('insights:empty-state')}
          buttonName={t('insights:view-itriom-insights')}
          buttonLink={`${Links.insights}#Itriom`}
        />
      ) : (
        <Flex direction="column" gap={4}>
          <InsightsGrid
            type="other"
            loading={loading}
            gridInsights={mapInsightsToGridInsights(items.slice(0, 4))}
            headingContent={
              <IntroTile
                title={t(`insights:${mapTypeToKey[type]}.title`)}
                subtitle={t(`insights:${mapTypeToKey[type]}.subtitle`)}
              />
            }
          />
          <InfiniteList
            items={items.slice(4)}
            hasMoreItems={hasMore}
            loadMoreItems={loadMoreItems}
            scrollComponentStyle={{
              gap: 'var(--chakra-space-4)',
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
            }}
            scrollWithRoot
            renderItem={(insight, index) =>
              renderAlternatingItem(index, insight)
            }
          />
        </Flex>
      )}
    </QueryStateGuard>
  );
};

const renderAlternatingItem = (index: number, { hero, id, tags }: Insight) => {
  switch (index % 4) {
    case 0:
    case 3:
      return (
        <BackgroundImageTile
          key={id}
          justify="flex-start"
          align="flex-start"
          tags={flattenTags(tags)}
          image={hero.heroImage.signedUrl || ''}
          title={hero.headline}
          description={hero.summary}
          link={generateUrlById(Links.insight, id)}
        />
      );
    case 1:
    case 2:
    default:
      return (
        <SideImageTile
          imagePosition="right"
          tags={flattenTags(tags)}
          image={hero.heroImage.signedUrl || ''}
          title={hero.headline}
          description={hero.summary}
          link={generateUrlById(Links.insight, id)}
          key={id}
        />
      );
  }
};
