import { Button } from '@chakra-ui/react';

import { HeroBanner } from 'components/HeroBanner';
import { HeroService } from 'features/NextGenServices/types';
import { useBookDemo } from 'gql/services/mutation/bookDemo';
import { useIsDemoBooked } from 'gql/services/query/isDemoBooked';
import { BookingTypeEnum } from 'types';

export const NextGenHeaderSection = ({
  title,
  subtitle,
  src,
}: Omit<HeroService, 'link'>) => {
  const bookDemo = useBookDemo({ type: BookingTypeEnum.SpeakToAdvisor });
  const { isDemoBooked } = useIsDemoBooked(BookingTypeEnum.SpeakToAdvisor);

  const handleClick = () => {
    bookDemo();
  };

  return (
    <HeroBanner src={src} title={title} subtitle={subtitle}>
      <Button
        mt={12}
        px={6}
        alignSelf="start"
        variant="primaryLime"
        onClick={handleClick}
        _disabled={{
          opacity: 'unset',
          cursor: 'not-allowed',
        }}
      >
        {isDemoBooked ? 'Itriom will be in touch' : 'Speak to an advisor'}
      </Button>
    </HeroBanner>
  );
};
