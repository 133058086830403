import { QueryStateGuard } from 'components/QueryStateGuard';
import { useValueConfiguratorValues } from 'hooks/onboarding/useValueConfiguratorValues';
import { ValueCategoryType } from 'types/ValuesConfigurator';

import { CategorySummary } from '../CategorySummary';
import { mapToSummary } from './utils';

export const RelevanceScores = () => {
  const { valueConfigurator, error, loading } = useValueConfiguratorValues();

  const advancementValues = mapToSummary(
    valueConfigurator?.advancementValues?.relevance
  );
  const organisationalValues = mapToSummary(
    valueConfigurator?.organisationalValues?.relevance
  );
  const societalValues = mapToSummary(
    valueConfigurator?.societalValues?.relevance
  );
  const contributionValues = mapToSummary(
    valueConfigurator?.contributionValues?.relevance
  );

  return (
    <QueryStateGuard error={error} loading={loading}>
      <CategorySummary
        categoryName="Organisational"
        type={ValueCategoryType.Relevance}
        values={organisationalValues}
      />
      <CategorySummary
        categoryName="Contribution"
        type={ValueCategoryType.Relevance}
        values={contributionValues}
      />
      <CategorySummary
        categoryName="Advancement"
        type={ValueCategoryType.Relevance}
        values={advancementValues}
      />

      <CategorySummary
        categoryName="Societal"
        type={ValueCategoryType.Relevance}
        values={societalValues}
      />
    </QueryStateGuard>
  );
};
