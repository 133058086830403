import { Grid } from '@chakra-ui/layout';
import { GridProps } from '@chakra-ui/react';

import { Breakpoints, useBreakpoint } from 'theme';

import { COLUMNS } from '../constants';
import { LayoutProps } from '../types';

type LayoutGridProps = LayoutProps &
  Pick<GridProps, 'gridTemplateRows' | 'rowGap'>;

export const LayoutGrid = ({
  children,
  isOldDesign = false,
  gridTemplateRows,
  rowGap,
}: LayoutGridProps) => {
  const breakpoint = useBreakpoint();

  // Old design makes all components full width. New design will only make
  // single-component pages full width.
  const containerSelector = isOldDesign ? '& > *' : '& > *:only-child';

  return (
    <Grid
      maxW={{
        base: Breakpoints.Mobile,
        md: Breakpoints.Tablet,
        lg: Breakpoints.Desktop,
      }}
      mx="auto"
      gridTemplateColumns={{
        base: `repeat(${COLUMNS[Breakpoints.Mobile]}, 1fr)`,
        md: `repeat(${COLUMNS[Breakpoints.Tablet]}, 1fr)`,
        lg: `repeat(${COLUMNS[Breakpoints.Desktop]}, 1fr)`,
      }}
      gridTemplateRows={gridTemplateRows}
      gap={4}
      sx={{
        [containerSelector]: {
          gridColumn: `span ${COLUMNS[breakpoint]}`,
        },
      }}
      rowGap={rowGap}
    >
      {children}
    </Grid>
  );
};
