import { useAddConfiguratorValues } from 'hooks/onboarding/useAddConfiguratorValues';
import { ContributionValuesInput, ValueConfiguratorCategoryEnum } from 'types';

import {
  ValuesConfiguratorForm,
  contributionValues,
} from '../ValuesConfugratorForm';

type Props = {
  valuesCategory: ValueConfiguratorCategoryEnum;
};

export const ContributionStep = ({ valuesCategory }: Props): JSX.Element => {
  const addConfiguratorValues = useAddConfiguratorValues();

  const onSubmit = (values: ContributionValuesInput) =>
    addConfiguratorValues({ valuesCategory, contributionValues: values });

  return (
    <ValuesConfiguratorForm
      onSubmit={onSubmit}
      category={contributionValues}
      valuesCategory={valuesCategory}
    />
  );
};
