import { AttendanceStatusEnum } from 'types';

export const ATTENDANCE_VALUE = {
  [AttendanceStatusEnum.Attending]: 'In person',
  [AttendanceStatusEnum.AttendingOnline]: 'By Zoom',
  [AttendanceStatusEnum.UnableToAttend]: 'In private',
} as const;

export type AttendFormValues = {
  attendanceStatus?: AttendanceStatusEnum;
};
