import { useDrop } from 'react-dnd';
import { Link } from 'react-router-dom';

import { Box, Flex, Text } from '@chakra-ui/react';

import { DragItemType, BREADCRUMB_SEPARATOR } from 'features/Library/constants';
import { getBgDropColor } from 'features/Library/utils/getBgDropColor';
import { Links } from 'router/links';

export interface BreadcrumbItem {
  link: Links | string;
  label: string;
  id?: string;
}
export interface Props {
  item: BreadcrumbItem;
  isLastItem: boolean;
}

export const BreadcrumbItem = ({ item, isLastItem }: Props) => {
  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: [DragItemType.FILE, DragItemType.FOLDER],
    drop: () => ({
      id: item.id,
      isDirectory: true,
    }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  return (
    <Flex ref={drop} alignItems="center">
      <Box
        as={isLastItem ? Box : Link}
        p={4}
        borderColor={isOver && !isLastItem ? 'ui.grey2' : 'transparent'}
        borderWidth="1px"
        backgroundColor={
          isLastItem ? 'transparent' : getBgDropColor(true, canDrop, isOver)
        }
        to={item.link}
        {...(isLastItem
          ? {
              _hover: { textDecoration: 'none' },
              cursor: 'default',
            }
          : { _hover: { textDecoration: 'underline' }, cursor: 'pointer' })}
      >
        <Text size="p2" {...(isLastItem && { color: 'ui.grey2' })}>
          {item.label}
        </Text>
      </Box>
      {!isLastItem && <Text size="p2">{BREADCRUMB_SEPARATOR}</Text>}
    </Flex>
  );
};
