import { useTranslation } from 'react-i18next';

import { Button, Heading } from '@chakra-ui/react';

import { JoinOutput, TriggeredCtaEnum } from 'types';

import { ElementWithBackgroundImage } from '../../ElementWithBackgroundImage';
import { useCtaEmail } from './hooks/useCtaEmail';

type Props = {
  eventId: string;
  eventHeading: string;
  cta: JoinOutput;
};

const ATTEND_EVENT_SECTION_MIN_H = 320;

export const AttendEvent = ({ eventId, eventHeading, cta }: Props) => {
  const { image, name, description } = cta;
  const { t } = useTranslation(['events']);
  const { sendEmail } = useCtaEmail(
    TriggeredCtaEnum.Join,
    eventId,
    eventHeading
  );

  return (
    <ElementWithBackgroundImage image={image} minH={ATTEND_EVENT_SECTION_MIN_H}>
      <Heading size="h3" color="ui.white">
        {name}
      </Heading>
      <Heading size="h6" color="ui.white" maxW="66%">
        {description}
      </Heading>
      <Button variant="primaryLime" w="max-content" onClick={() => sendEmail()}>
        {t('events:attend-event')}
      </Button>
    </ElementWithBackgroundImage>
  );
};
