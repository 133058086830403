import { useTranslation } from 'react-i18next';

import { Sprout24 } from '@carbon/icons-react';
import { Box, Button, Flex } from '@chakra-ui/react';

import { colors } from 'theme/colors';
import { AppreciationType } from 'types';

import { useToggleAppreciation } from './useToggleAppreciation';

type Props = {
  count?: number;
  referenceId: string;
  type: AppreciationType;
  isSelfAppreciated: boolean;
};

export const Appreciate = ({
  count = 0,
  referenceId,
  type,
  isSelfAppreciated,
}: Props): JSX.Element => {
  const { toggleAppreciate, loading } = useToggleAppreciation();
  const { t } = useTranslation(['network']);

  const multiplier = 20;

  const percentageFilled = Math.min((Number(count) || 0) * multiplier, 100);

  const handleAppreciation = () =>
    toggleAppreciate({
      type,
      targetId: referenceId,
    });

  return (
    <Flex justify="flex-start" align="center" gap={2}>
      <Box pos="relative">
        <Sprout24 fill="lightgrey" />
        <Box
          pos="absolute"
          top="0"
          overflow="hidden"
          clipPath={`inset(${100 - percentageFilled}% 0 0)`}
          data-cy="sprout-progress"
        >
          <Sprout24 fill={colors.status.green.default} />
        </Box>
      </Box>

      <Button
        px={0}
        variant="ghost"
        isLoading={loading}
        color={
          isSelfAppreciated ? 'status.green.default' : 'primary.blue.default'
        }
        onClick={handleAppreciation}
      >
        {t(
          isSelfAppreciated
            ? 'network:appreciated_interval'
            : 'network:appreciate_interval',
          { postProcess: 'interval', count }
        )}
      </Button>
    </Flex>
  );
};
