export const maxSize = (size: number | string): string =>
  `min(${typeof size === 'string' ? size : `${size}px`}, calc(100dvw - 2rem))`;

export const getBytesSize = (sizeWithUnit: string): number => {
  const size = sizeWithUnit.match(/\d+/)?.[0];
  const unit = sizeWithUnit.match(/[a-zA-Z]+/)?.[0]?.toUpperCase();
  if (!size || !unit) return 0;

  const muliplier =
    {
      B: 1,
      KB: 1024,
      MB: 1024 * 1024,
      GB: 1024 * 1024 * 1024,
    }[unit] || 1;

  return parseInt(size, 10) * muliplier;
};

export const humanFileSize = (bytes: number, decimalPlaces = 1) => {
  const thresh = 1024;

  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`;
  }

  const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let u = -1;
  const r = 10 ** decimalPlaces;

  do {
    bytes /= thresh;
    // eslint-disable-next-line no-plusplus
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return `${bytes.toFixed(decimalPlaces)} ${units[u]}`;
};
