import { compact } from 'lodash';

import { FamilyCharterGoalOutput } from 'types';

export const sortInitiatives = (goals?: FamilyCharterGoalOutput[]) => {
  if (!goals || !goals.length) return [];

  const [goal1, goal2, goal3] = goals;
  const [latestFirstGoalInitiative, ...firstGoalInitiatives] =
    goal1?.initiatives || [];
  const [latestSecondGoalInitiative, ...secondGoalInitiatives] =
    goal2?.initiatives || [];
  const [latestThirdGoalInitiative, ...thirdGoalInitiatives] =
    goal3?.initiatives || [];

  return compact([
    latestFirstGoalInitiative,
    latestSecondGoalInitiative,
    latestThirdGoalInitiative,
    ...firstGoalInitiatives,
    ...secondGoalInitiatives,
    ...thirdGoalInitiatives,
  ]).filter((initiative) => !!initiative.initiativeId);
};
