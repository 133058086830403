import { useState } from 'react';

import { CloseButton, Flex, Input, Text } from '@chakra-ui/react';

import { useAuthContext } from 'contexts/AuthContext';
import { Profile } from 'types';

import { useCreateConversation } from '../../hooks/useCreateConversation';
import { NewMessageInput } from '../NewMessageInput';
import { InputSuggestions } from './ConnectionsSuggestions';
import { InterlocutorTag } from './InterlocutorTag';
import { useFilteredConnections } from './useFilteredConnections';
import { useOnFocus } from './useOnFocus';

type Props = {
  interlocutors: Profile[];
  onInterlocutorTrigger: (user: Profile) => void;
  handleCloseNewConversation: () => void;
};

export const NewConversation = ({
  handleCloseNewConversation,
  interlocutors,
  onInterlocutorTrigger,
}: Props) => {
  const { account } = useAuthContext();

  const [userInput, setUserInput] = useState('');

  const { focusOnInput, inputRef } = useOnFocus();

  const afterAction = () => {
    focusOnInput();
    setUserInput('');
  };

  const onConnectionSelect = (profile: Profile) => {
    onInterlocutorTrigger(profile);
    afterAction();
  };

  const onConnectionRemove = (profile: Profile) => {
    onInterlocutorTrigger(profile);
    afterAction();
  };

  const onInput = (e: React.FormEvent<HTMLInputElement>) => {
    setUserInput(e.currentTarget.value);
  };

  const memberIds: string[] = interlocutors
    .map((i) => i.id)
    .filter((id) => id !== account?.id);

  const createNewConversation = useCreateConversation();

  const handleCreateNewConversation = (message: string) =>
    createNewConversation({ text: message, recipientIds: memberIds });

  const filteredConnections = useFilteredConnections({
    connections: account?.connections || [],
    userInput,
    interlocutors,
  });

  return (
    <Flex
      direction="column"
      bgColor="ui.white"
      data-cy="new-conversation-component"
      flex={1}
    >
      <Flex width="100%" justify="space-between">
        <Text size="p2b" p={3}>
          New message
        </Text>
        <CloseButton p={3} onClick={handleCloseNewConversation} />
      </Flex>

      <Flex direction="column" justify="space-between" h="100%" w="100%">
        <Flex direction="column">
          <Flex direction="row" flexWrap="wrap" mx={3} gridGap={2}>
            {interlocutors.map((interlocutor) => (
              <InterlocutorTag
                interlocutor={interlocutor}
                key={interlocutor.id}
                onRemove={() => onConnectionRemove(interlocutor)}
              />
            ))}
            <Input
              ref={inputRef}
              size="sm"
              minW="300px"
              border="none"
              outline="none"
              _focus={{ boxShadow: 'none' }}
              value={userInput}
              placeholder={interlocutors.length === 0 ? 'Type a name' : ''}
              onChange={onInput}
              flex={1}
              p="0px"
            />
          </Flex>
          {userInput.length > 0 && (
            <InputSuggestions
              filteredConnections={filteredConnections}
              onConnectionSelect={onConnectionSelect}
            />
          )}
        </Flex>
        <NewMessageInput
          sendMessage={handleCreateNewConversation}
          disabled={interlocutors.length === 0}
        />
      </Flex>
    </Flex>
  );
};
