import { OrderedList } from '@chakra-ui/react';

import { useCompleteSDGConfigurator } from 'hooks/onboarding/useCompleteSDGConfigurator';
import { useCompleteValuesConfigurator } from 'hooks/onboarding/useCompleteValuesConfigurator';
import { useSendNotification } from 'hooks/onboarding/useSendNotification';
import { FamilyOnboardStateEnum, OnboardManagementOutput } from 'types';
import { isFamilyOnboardCompleted } from 'utils/values';

import { CmsSection } from '../../../CmsSection';
import { NotificationRow } from './NotificationRow';
import { TriggerRow } from './TriggerRow';

type Props = {
  onboardManagement: OnboardManagementOutput;
};

export const TriggersAndNotificationsSection = ({
  onboardManagement,
}: Props) => {
  const { state, actions, members, referenceId, referenceType } =
    onboardManagement;
  const {
    sendFamilyProfileCompletedEmail,
    sendSdgConfiguratorCompletedEmail,
    sendSdgConfiguratorInvitationEmail,
    sendRsvpEmail,
  } = useSendNotification(referenceId, referenceType);

  const completeValuesConfigurator = useCompleteValuesConfigurator(referenceId);
  const completeSDGConfigurator = useCompleteSDGConfigurator(referenceId);

  const isIndividual = members.length === 1;

  return (
    <CmsSection title="Triggers and notifications">
      <OrderedList>
        <NotificationRow
          label="Send emails asking customers to start Values Configurator"
          handleTestClick={() => sendRsvpEmail(true)}
          handleSendClick={sendRsvpEmail}
          isSent={actions.rsvpEmailSent}
          question="Ask customers to start Values Configurator"
        />
        <TriggerRow
          label="Complete the Family’s Value Configurator"
          handleClick={completeValuesConfigurator}
          isCompleted={isFamilyOnboardCompleted(
            state,
            FamilyOnboardStateEnum.ValueConfiguratorCompleted
          )}
          isButtonDisabled={isIndividual}
        />
        <NotificationRow
          label="Send emails asking members to continue with SDG Configurator"
          handleTestClick={() => sendSdgConfiguratorInvitationEmail(true)}
          handleSendClick={sendSdgConfiguratorInvitationEmail}
          isSent={actions.sdgConfiguratorInvitationEmailSent || isIndividual}
          question="Ask customers to start SDG Configurator"
        />
        <TriggerRow
          label="Complete the Family’s SDG Configurator"
          handleClick={completeSDGConfigurator}
          isCompleted={isFamilyOnboardCompleted(
            state,
            FamilyOnboardStateEnum.SdgConfiguratorCompleted
          )}
          isButtonDisabled={isIndividual}
        />
        <NotificationRow
          label="Send emails to members showing their Family’s 3 SDG Influences"
          handleTestClick={() => sendSdgConfiguratorCompletedEmail(true)}
          handleSendClick={sendSdgConfiguratorCompletedEmail}
          isSent={actions.sdgConfiguratorCompletedEmailSent}
          question="Email asking customers to review their Family’s 3 SDG Influences"
        />
        <NotificationRow
          label="Send emails inviting Customers to see their Charter in the Itriom Web app"
          handleTestClick={() => sendFamilyProfileCompletedEmail(true)}
          handleSendClick={sendFamilyProfileCompletedEmail}
          isSent={actions.profileCompletedEmailSent}
          question="Email asking customers to review their Charter in the Itriom Web app"
        />
      </OrderedList>
    </CmsSection>
  );
};
