import { gql, useMutation } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationDeleteConversationArgs } from 'types';

import { sendMessageGql } from './sendMessageGql';
import { deleteMessageGql } from './useDeleteMessage';
import { getConversationsGql } from './useLoadConversations';
import { getMessagesInConversation } from './useLoadMessagesForConversation';

export const deleteConversationGql = gql`
  mutation DeleteConversation($id: String!) {
    deleteConversation(id: $id)
  }
`;

export const useDeleteConversation = () => {
  const [deleteConversationMutation] = useMutation<
    Pick<Mutation, 'deleteConversation'>,
    MutationDeleteConversationArgs
  >(deleteConversationGql, {
    refetchQueries: [
      getConversationsGql,
      getMessagesInConversation,
      deleteMessageGql,
      sendMessageGql,
    ],
  });

  const deleteConversation = async (conversationId: string) => {
    await deleteConversationMutation({
      variables: {
        id: conversationId,
      },
    });
  };

  const deleteConversationWithToast = useWithMutationToast(deleteConversation, {
    successMessage: 'Conversation successfully deleted',
    errorMessage: 'Failed removing conversation',
  });

  return deleteConversationWithToast;
};
