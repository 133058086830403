import { useCallback, useEffect, useMemo, useState } from 'react';

import { BasePaginationOutput } from 'types';

export type FetchItems<TOut extends BasePaginationOutput, TIn> = (
  params?: Omit<TIn, 'pagination'>
) => Promise<TOut>;

type Params<TOut extends BasePaginationOutput, TIn = void> = {
  loadMore: FetchItems<TOut, TIn>;
  initialLoad: FetchItems<TOut, TIn>;
  reload?: FetchItems<TOut, TIn>;
  shouldLoadInitially?: boolean;
  onLoadCallback?: (output: TOut) => void;
};

export const usePaginationState = <
  TOut extends BasePaginationOutput,
  TIn = void
>({
  initialLoad,
  loadMore,
  reload,
  shouldLoadInitially = true,
  onLoadCallback,
}: Params<TOut, TIn>) => {
  const [items, setItems] = useState<TOut['nodes']>([]);
  const [totalCount, setTotalCount] = useState(0);

  const hasMore = useMemo(
    () => items.length < totalCount,
    [items.length, totalCount]
  );

  useEffect(() => {
    if (!shouldLoadInitially) return;

    initialLoad().then((result) => {
      const { nodes, totalCount: initialTotalCount } = result;
      setItems(nodes);
      setTotalCount(initialTotalCount);

      if (onLoadCallback) {
        onLoadCallback(result);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldLoadInitially]);

  const loadMoreItems = useCallback(() => {
    loadMore().then((result) => {
      const { nodes, totalCount: freshTotalCount } = result;

      setItems((prev) => [...prev, ...nodes]);
      setTotalCount(freshTotalCount);

      if (onLoadCallback) {
        onLoadCallback(result);
      }
    });
  }, [loadMore, onLoadCallback]);

  const reloadItems = useCallback(() => {
    if (!reload) return;

    reload().then((result) => {
      const { nodes, totalCount: freshTotalCount } = result;

      setItems(nodes);
      setTotalCount(freshTotalCount);

      if (onLoadCallback) {
        onLoadCallback(result);
      }
    });
  }, [onLoadCallback, reload]);

  return {
    items,
    hasMore,
    loadMoreItems,
    reloadItems,
    setItems, // use only when necessary
    setTotalCount, // use only when necessary
  };
};
