import { GElement } from '../types';

export const addYLabel = (
  el: GElement,
  label: string,
  marginLeft = 0
): GElement => {
  el.call((g) =>
    g
      .append('text')
      .attr('x', -marginLeft)
      .attr('y', 10)
      .attr('fill', 'currentColor')
      .attr('text-anchor', 'start')
      .text(label)
  );

  return el;
};

export const addXLabel = (
  el: GElement,
  label: string,
  width: number,
  marginBottom = 10
): GElement => {
  el.call((g) =>
    g
      .append('text')
      .attr('x', width / 2)
      .attr('y', marginBottom)
      .attr('fill', 'currentColor')
      .attr('text-anchor', 'start')
      .text(label)
  );

  return el;
};
