import { Heading, Box } from '@chakra-ui/react';

import { WithChildren } from 'types';

type Props = {
  heading: string;
};

export const DetailsSection = ({ heading, children }: WithChildren<Props>) => (
  <Box>
    <Heading
      fontWeight="bold"
      fontSize="md"
      lineHeight="22px"
      fontFamily="Roboto"
    >
      {heading}
    </Heading>

    {children}
  </Box>
);
