import { ReactNode } from 'react';

import {
  CloseButtonProps,
  FlexProps,
  Heading,
  Modal as ChakraModal,
  ModalBody,
  ModalBodyProps,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalHeaderProps,
  ModalOverlay,
  ModalOverlayProps,
} from '@chakra-ui/react';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  children: ReactNode;
  footer?: ReactNode;
  containerStyling?: FlexProps;
  overlayStyling?: ModalOverlayProps;
  bodyStyling?: ModalBodyProps;
  headerStyling?: ModalHeaderProps;
  closeButtonStyling?: CloseButtonProps;
  isFull?: boolean;
  closeButtonVisible?: boolean;
};

export const Modal = ({
  isOpen,
  onClose,
  title,
  children,
  footer,
  containerStyling,
  headerStyling,
  overlayStyling,
  bodyStyling,
  closeButtonStyling,
  isFull,
  closeButtonVisible = true,
}: Props): JSX.Element => {
  return (
    <ChakraModal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={isFull ? 'full' : 'md'}
    >
      <ModalOverlay {...overlayStyling} />
      <ModalContent {...containerStyling}>
        {title && (
          <ModalHeader {...headerStyling}>
            <Heading size={closeButtonVisible ? 'h5' : 'h3'}>{title}</Heading>
          </ModalHeader>
        )}
        {closeButtonVisible && <ModalCloseButton t {...closeButtonStyling} />}
        <ModalBody {...bodyStyling}>{children}</ModalBody>

        {footer && <ModalFooter>{footer}</ModalFooter>}
      </ModalContent>
    </ChakraModal>
  );
};
