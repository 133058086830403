import { Flex, Heading, Image, Text } from '@chakra-ui/react';

type Props = {
  title: string;
  description: string;
  iconSrc: string;
};

export const SolutionCard = ({ description, iconSrc, title }: Props) => (
  <Flex direction="column" align="center" maxW={450} mx="auto">
    <Image src={iconSrc} w={100} h={100} alt={title} />
    <Text size="p1b" mb={8}>
      {title}
    </Text>
    <Heading size="h6">{description}</Heading>
  </Flex>
);
