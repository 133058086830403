import { Avatar } from 'components/Avatar';
import { Panel } from 'components/Layout/Panel';
import { Profile } from 'types';

type Props = { admins: Profile[] };

export const GroupAdministrators = ({ admins }: Props) => (
  <Panel title="Administrators" variant="secondary">
    {admins.map((profile: Profile) => (
      <Avatar
        subheadingInfo={profile.family?.name}
        profile={profile}
        key={profile.id}
        variant="horizontal"
      />
    ))}
  </Panel>
);
