import { useEffect } from 'react';

import { useList } from 'hooks/useList';
import { SDGDragItem } from 'types';

type Props = {
  initialAvailable?: SDGDragItem[];
  initialSelected?: SDGDragItem[];
  changeObserver?: (items: SDGDragItem[]) => void;
};

export const useSelectionItems = ({
  initialAvailable = [],
  initialSelected = [],
  changeObserver,
}: Props) => {
  const available = useList<SDGDragItem>(initialAvailable);
  const selected = useList<SDGDragItem>(initialSelected);

  const moveToSelected = (item: SDGDragItem, cellIndex: number): void => {
    const prevItem = selected.items.find(
      ({ orderIndex }) => orderIndex === cellIndex
    );

    const newItem: SDGDragItem = { ...item, orderIndex: cellIndex };

    if (prevItem) {
      rearrangeItems(prevItem, item);

      return;
    }

    available.removeItem(newItem);
    selected.removeItem(newItem);

    selected.addItem(newItem);
  };

  const moveToAvailable = (item: SDGDragItem): void => {
    selected.removeItem(item);
    available.removeItem(item);

    available.addItem(item);

    available.sort((a, b) => a.sdg.index - b.sdg.index);
  };

  const rearrangeItems = (item1: SDGDragItem, item2: SDGDragItem): void => {
    // rearrangement in available list is not permitted
    if (available.contains(item1) && available.contains(item2)) return;

    const newItem1 = { ...item1, orderIndex: item2.orderIndex };
    const newItem2 = { ...item2, orderIndex: item1.orderIndex };

    const listFrom = available.contains(item1) ? available : selected;
    const listTo = available.contains(item2) ? available : selected;

    listFrom.removeItem(item1);
    listTo.addItem(newItem1);

    listTo.removeItem(item2);
    listFrom.addItem(newItem2);
  };

  useEffect(() => {
    if (changeObserver) {
      changeObserver(selected.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected.items]);

  return {
    availableItems: available.items,
    selectedItems: selected.items,
    moveToSelected,
    moveToAvailable,
  };
};
