export const Tabs = {
  variants: {
    line: {
      tablist: {
        borderBottomColor: 'darkBlue2',
        borderBottomWidth: '1px',
      },
      tab: {
        color: 'primary.blue.default',
        _selected: {
          color: 'primary.blue.default',
          fontWeight: 'bold',
        },
      },
    },
  },
};
