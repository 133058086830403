import { FlexProps } from '@chakra-ui/react';

import { ProfileActionMenu } from 'components/ProfileActionMenu';
import { useEsgCategories } from 'hooks/useEsgCategories';
import { Links } from 'router/links';
import { Group, Profile } from 'types';
import { getAvatarSrc } from 'utils/file';
import { generateUrlById } from 'utils/links';
import { isItriomStaff } from 'utils/roles';
import { getFullName, getTitleAndFamily } from 'utils/string';

import { CardWithImageBase } from './CardWithImageBase';

type ProfileCardProps = {
  data: Pick<
    Profile,
    | 'id'
    | 'firstName'
    | 'lastName'
    | 'title'
    | 'role'
    | 'connectionState'
    | 'avatarImage'
    | 'family'
  >;
} & FlexProps;

const ProfileCard = ({ data, ...flexProps }: ProfileCardProps) => {
  const {
    id,
    firstName,
    lastName,
    title,
    role,
    connectionState,
    avatarImage,
    family,
  } = data;

  return (
    <CardWithImageBase
      key={id}
      href={generateUrlById(Links.profile, id)}
      title={getFullName(firstName, lastName)}
      subTitle={getTitleAndFamily(title, family?.name)}
      canRedirect={!isItriomStaff(role)}
      actionMenu={
        !isItriomStaff(role) && (
          <ProfileActionMenu id={id} connectionState={connectionState} />
        )
      }
      heroImageUrl={getAvatarSrc(avatarImage)}
      {...flexProps}
    />
  );
};

type GroupCardProps = {
  data: Pick<Group, 'id' | 'category' | 'heroImage' | 'name' | 'heroImage'>;
} & FlexProps;

const GroupCard = ({ data, ...flexProps }: GroupCardProps) => {
  const { id, category, name, heroImage } = data;

  const { getEsgCategory } = useEsgCategories();

  return (
    <CardWithImageBase
      key={id}
      href={generateUrlById(Links.groupsSingle, id)}
      title={name}
      subTitle={getEsgCategory(category)?.name}
      canRedirect
      heroImageUrl={heroImage?.signedUrl}
      {...flexProps}
    />
  );
};

type ProfileCardType = {
  type: 'Profile';
} & ProfileCardProps;

type GroupCardType = {
  type: 'Group';
} & GroupCardProps;

type CardWithImageProps = ProfileCardType | GroupCardType;

export const CardWithImage = ({
  type,
  data,
  ...flexProps
}: CardWithImageProps) => {
  if (type === 'Profile') {
    return <ProfileCard data={data} {...flexProps} />;
  }

  if (type === 'Group') {
    return <GroupCard data={data} {...flexProps} />;
  }

  return null;
};
