import { useTranslation } from 'react-i18next';

import { Flex } from '@chakra-ui/react';

import { Breadcrumbs } from 'components/Breadcrumbs';
import { Links } from 'router/links';

import {
  FamilyValuesSection,
  IpadImage,
  LegacySection,
  MarketplaceHeaderSection,
  MarketplaceHeroSection,
  MeasuringChangeSection,
  OurPartnersSection,
  SolutionSection,
  QuoteSection,
} from '../components';
import { SECTION_SPACING } from '../constants';

export const MarketplacePage = () => {
  const { t } = useTranslation(['navigation']);

  return (
    <>
      <MarketplaceHeaderSection />
      <Breadcrumbs
        items={[
          { link: Links.home, label: t('navigation:home') },
          {
            link: Links.marketplace,
            label: t('navigation:marketplace'),
          },
        ]}
      />
      <MarketplaceHeroSection />
      <IpadImage />
      <SolutionSection />
      <QuoteSection />
      <Flex direction="column" gap={90} bg="ui.white" pb={SECTION_SPACING}>
        <FamilyValuesSection />
        <MeasuringChangeSection />
      </Flex>
      <LegacySection />
      <OurPartnersSection />
    </>
  );
};
