import { SliderInputVariant } from 'components/Form/SliderInput';
import {
  CATEGORIES_DESCRIPTIONS,
  VALUES_DESCRIPTIONS,
} from 'constants/ValuesConfigurator';
import {
  AdvancementValuesInput,
  ContributionValuesInput,
  OrganisationalValuesInput,
  SocietalValuesInput,
  ValueConfiguratorCategoryEnum,
} from 'types';
import {
  OrganisationalValue,
  ContributionValue,
  AdvancementValue,
  SocietalValue,
  ValueCategoryName,
} from 'types/ValuesConfigurator';

import { ValuesForm } from './types';

// TODO: refactor and move to i18n

export const VALUES_CATEGORY_DEFINITION: Record<
  ValueConfiguratorCategoryEnum,
  string
> = {
  [ValueConfiguratorCategoryEnum.Relevance]:
    'The extent to which you view the value as relevant, i.e., where you and your family can use it to help create meaningful impact in a context that matters to you.',
  [ValueConfiguratorCategoryEnum.Importance]:
    'The extent to which you view a value as desirable.',
};

export const organisationalValues: ValuesForm<OrganisationalValuesInput> = {
  valueCategoryName: ValueCategoryName.Organisational,
  description: CATEGORIES_DESCRIPTIONS[ValueCategoryName.Organisational],
  fields: [
    {
      name: OrganisationalValue.Resilience,
      definition: VALUES_DESCRIPTIONS[OrganisationalValue.Resilience],
      variant: SliderInputVariant.Green4,
    },
    {
      name: OrganisationalValue.Leadership,
      definition: VALUES_DESCRIPTIONS[OrganisationalValue.Leadership],
      variant: SliderInputVariant.Green5,
    },
    {
      name: OrganisationalValue.Transparency,
      definition: VALUES_DESCRIPTIONS[OrganisationalValue.Transparency],
      variant: SliderInputVariant.Orange1,
    },
    {
      name: OrganisationalValue.Adaptability,
      definition: VALUES_DESCRIPTIONS[OrganisationalValue.Adaptability],
      variant: SliderInputVariant.PrimaryBlue,
    },
  ],
};

export const contributionValues: ValuesForm<ContributionValuesInput> = {
  valueCategoryName: ValueCategoryName.Contribution,
  description: CATEGORIES_DESCRIPTIONS[ValueCategoryName.Contribution],
  fields: [
    {
      name: ContributionValue.Fairness,
      definition: VALUES_DESCRIPTIONS[ContributionValue.Fairness],
      variant: SliderInputVariant.Green4,
    },
    {
      name: ContributionValue.Inclusivity,
      definition: VALUES_DESCRIPTIONS[ContributionValue.Inclusivity],
      variant: SliderInputVariant.Green5,
    },
    {
      name: ContributionValue.Stewardship,
      definition: VALUES_DESCRIPTIONS[ContributionValue.Stewardship],
      variant: SliderInputVariant.Orange1,
    },
    {
      name: ContributionValue.Community,
      definition: VALUES_DESCRIPTIONS[ContributionValue.Community],
      variant: SliderInputVariant.PrimaryBlue,
    },
  ],
};

export const advancementValues: ValuesForm<AdvancementValuesInput> = {
  valueCategoryName: ValueCategoryName.Advancement,
  description: CATEGORIES_DESCRIPTIONS[ValueCategoryName.Advancement],
  fields: [
    {
      name: AdvancementValue.Progress,
      definition: VALUES_DESCRIPTIONS[AdvancementValue.Progress],
      variant: SliderInputVariant.Green4,
    },
    {
      name: AdvancementValue.Innovation,
      definition: VALUES_DESCRIPTIONS[AdvancementValue.Innovation],
      variant: SliderInputVariant.Green5,
    },
    {
      name: AdvancementValue.Learning,
      definition: VALUES_DESCRIPTIONS[AdvancementValue.Learning],
      variant: SliderInputVariant.Orange1,
    },
    {
      name: AdvancementValue.Curiosity,
      definition: VALUES_DESCRIPTIONS[AdvancementValue.Curiosity],
      variant: SliderInputVariant.PrimaryBlue,
    },
  ],
};

export const societalValues: ValuesForm<SocietalValuesInput> = {
  valueCategoryName: ValueCategoryName.Societal,
  description: CATEGORIES_DESCRIPTIONS[ValueCategoryName.Societal],
  fields: [
    {
      name: SocietalValue.Collaboration,
      definition: VALUES_DESCRIPTIONS[SocietalValue.Collaboration],
      variant: SliderInputVariant.Green4,
    },
    {
      name: SocietalValue.Compassion,
      definition: VALUES_DESCRIPTIONS[SocietalValue.Compassion],
      variant: SliderInputVariant.Green5,
    },
    {
      name: SocietalValue.Equality,
      definition: VALUES_DESCRIPTIONS[SocietalValue.Equality],
      variant: SliderInputVariant.Orange1,
    },
    {
      name: SocietalValue.Empowerment,
      definition: VALUES_DESCRIPTIONS[SocietalValue.Empowerment],
      variant: SliderInputVariant.PrimaryBlue,
    },
  ],
};
