import { useTranslation } from 'react-i18next';

import { Flex, Heading, Image } from '@chakra-ui/react';

export const NextGenOurPartnersSection = () => {
  const { t } = useTranslation(['services']);

  return (
    <Flex
      direction="column"
      justify="center"
      align="center"
      w="full"
      bg="#F5F5F5"
      py={24}
      gap={8}
    >
      <Heading size="h3" textAlign="center" w="full">
        {t('services:headings.partners')}
      </Heading>
      <Flex
        direction={{ md: 'row', base: 'column' }}
        w="full"
        align="center"
        justify="center"
        gap={{ md: '140px', base: '55px' }}
      >
        <Image
          src="/assets/marketplace/partners/envestors_logo.png"
          width={250}
        />
        <Image src="/assets/nextGenServices/actual.png" width={250} />
        <Image src="/assets/nextGenServices/aidonic.png" width={250} />
      </Flex>
    </Flex>
  );
};
