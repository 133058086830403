import { gql, useMutation } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { MediaOutput, Mutation, MutationUpdateEventTopicsArgs } from 'types';

import { eventGql } from './useEvent';

export const updateEventTopicsGql = gql`
  mutation UpdateEventTopics($event: UpdateTopicsInput!) {
    updateEventTopics(event: $event) {
      id
    }
  }
`;

export type TopicForm = {
  id: string;
  description: string;
  image: MediaOutput[];
};

export type UpdateEventTopicsForm = {
  id: string;
  topics: Array<TopicForm>;
};

export const useUpdateEventTopics = () => {
  const [updateEventTopicsMutation] = useMutation<
    Pick<Mutation, 'updateEventTopics'>,
    MutationUpdateEventTopicsArgs
  >(updateEventTopicsGql, {
    refetchQueries: [eventGql],
  });

  const handleSubmit = (values: UpdateEventTopicsForm) => {
    const { id, topics } = values;

    return updateEventTopicsMutation({
      variables: {
        event: {
          id,
          topics: topics.map((topic) => ({
            description: topic.description,
            imageId: topic.image[0]?.id,
          })),
        },
      },
    });
  };

  const updateEventTopics = useWithMutationToast(handleSubmit, {
    successMessage: 'Updating topics succeeded',
    errorMessage: 'Updating topics failed',
  });

  return { updateEventTopics };
};
