import { useTranslation } from 'react-i18next';

import { Flex } from '@chakra-ui/react';

import { Breadcrumbs } from 'components/Breadcrumbs';
import { CharterWithGoals } from 'components/CharterWithGoals';
import { LayoutGrid, LayoutGridElement } from 'components/Layout/Grid';
import { Links } from 'router/links';

import { InitiativesLandingPageHero, InitiativesTabs } from '../components';

export const InitiativesLandingPage = () => {
  const { t } = useTranslation(['navigation']);

  return (
    <LayoutGrid gridTemplateRows="auto 80px auto auto auto">
      <LayoutGridElement colSpan={16} gridRow="1 / span 1">
        <Flex direction="column" gap={4}>
          <InitiativesLandingPageHero />
          <Breadcrumbs
            items={[
              { link: Links.home, label: t('navigation:home') },
              {
                link: Links.initiatives,
                label: t('navigation:cms.initiatives'),
              },
            ]}
          />
        </Flex>
      </LayoutGridElement>

      <LayoutGridElement colSpan={4} gridRow="2 / span 2">
        <CharterWithGoals />
      </LayoutGridElement>

      <InitiativesTabs />
    </LayoutGrid>
  );
};
