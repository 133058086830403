import { ValueConfiguratorCategoryEnum } from 'types';

import { ContributionStep } from '../../components/ValuesConfigurator';

type Props = {
  valuesCategory?: ValueConfiguratorCategoryEnum;
};

export const ContributionValues = ({
  valuesCategory = ValueConfiguratorCategoryEnum.Importance,
}: Props): JSX.Element => {
  return <ContributionStep valuesCategory={valuesCategory} />;
};
