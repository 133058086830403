import { Flex } from '@chakra-ui/react';

import { LayoutGrid, LayoutGridElement } from 'components/Layout/Grid';
import { PageHeader } from 'components/PageHeader';
import { NewPostForm } from 'components/Posts/PostForm';
import { PostList } from 'components/Posts/PostList';
import { QueryStateGuard } from 'components/QueryStateGuard';
import { TrendingGroups } from 'components/TrendingGroups';
import { usePosts } from 'hooks/posts';
import { PostSource } from 'types';

import { useGroupContext } from '../GroupContext';
import { GroupSider, GroupMembers } from '../components';

export const MemberGroupPage = () => {
  const { group, loading, error } = useGroupContext();

  const {
    posts,
    loading: postLoading,
    error: postError,
    refetch,
  } = usePosts({
    filters: { source: PostSource.Group, referenceId: group?.id },
    pagination: { limit: 100, offset: 0 },
  });

  return (
    <QueryStateGuard
      loading={loading || postLoading}
      error={error || postError}
    >
      {group && (
        <LayoutGrid>
          <LayoutGridElement>
            <PageHeader
              avatarSrc={group?.avatarImage?.signedUrl}
              name={group.name}
            />
          </LayoutGridElement>

          <LayoutGridElement colSpan={4}>
            <Flex direction="column" gap={4}>
              <GroupSider group={group} />
            </Flex>
          </LayoutGridElement>

          <LayoutGridElement colSpan={8}>
            <Flex flex={1} direction="column" gap={4}>
              <NewPostForm
                referenceId={group.id}
                onAdd={refetch}
                source={PostSource.Group}
                withTitle
              />

              <PostList posts={posts} />
            </Flex>
          </LayoutGridElement>

          <LayoutGridElement colSpan={4}>
            <Flex direction="column" gap={4}>
              <GroupMembers members={group.members} />
              <TrendingGroups />
            </Flex>
          </LayoutGridElement>
        </LayoutGrid>
      )}
    </QueryStateGuard>
  );
};
