import { extendTheme, Theme as ChakraTheme } from '@chakra-ui/react';

import { Button } from './Button';
import { breakpoints } from './breakpoints';
import { colors } from './colors';
import { oldColors } from './colors/oldColors';
import { Link, Tab, Tabs, Alert } from './components';
import { Progress } from './components/Progress';
import { fonts, fontSizes, Heading, Text } from './typography';

const customTheme = {
  styles: {
    global: {
      'html, body': {
        color: colors.primary.blue.default,
      },

      '*::placeholder': {
        color: colors.ui.grey2,
      },
      label: {
        color: colors.primary.blue.default,
      },
    },
  },
  fonts,
  fontSizes,
  colors: {
    ...oldColors,
    ...colors,
  },
  breakpoints,
  borders: {
    default: `1px solid ${colors.ui.grey4}`,
    medium: `1px solid ${colors.ui.grey3}`,
    blue: `1px solid ${colors.primary.blue.default}`,
  },
  components: {
    Button,
    Heading,
    Link,
    Tab,
    Tabs,
    Text,
    Alert,
    Progress,
  },
};

export const theme = extendTheme(customTheme);

export type ItriomTheme = ChakraTheme & typeof customTheme;

export { Fonts } from './typography';
export { Breakpoints, breakpoints, useBreakpoint } from './breakpoints';

// fSizes

// xl - 38
// 2xl: "1.5rem" // 25
// 3xl: "1.875rem" // 29
// 4xl: "2.25rem" // 8
// 5xl: "3rem" // 10
// 6xl: "3.75rem" // 6
// 7xl: "4.5rem" // 1
// 8xl: "6rem" // 0
// 9xl: "8rem" //0
// lg: "1.125rem" // 25
// md: "1rem" // 57
// sm: "0.875rem" // 34
// xl: "1.25rem" // 14
// xs: "0.75rem" // 59
// 2xs: "0.5rem" // 3

// Spaces

// 0.5: "0.125rem" // 1
// 1: "0.25rem" // ~150
// 1.5: "0.375rem" // 3
// 2: "0.5rem" // ~250
// 2.5: "0.625rem" // 2
// 3: "0.75rem" // ~120
// 3.5: "0.875rem" // 1
// 4: "1rem" // ~230
// 5: "1.25rem" // ~80
// 6: "1.5rem" // ~100
// 7: "1.75rem" // 4
// 8: "2rem" // ~110
// 9: "2.25rem" // 2
// 10: "2.5rem" // ~100
// 12: "3rem" // ~50
// 14: "3.5rem" // ~40
// 16: "4rem" // ~60
// 20: "5rem" // ~30
// 24: "6rem" // ~25
// 28: "7rem" // 0
// 32: "8rem" // ~10
// 36: "9rem" // 0
// 40: "10rem" // 5
// 44: "11rem" // 0
// 48: "12rem" // 0
// 52: "13rem" // 0
// 56: "14rem" // 0
// 60: "15rem" // 0
// 64: "16rem" // 0
// 72: "18rem" // 0
// 80: "20rem" // 0
// 96: "24rem" // 0
// px: "1px"

// raw px values - ~150
