import { Reset20 } from '@carbon/icons-react';
import { CSSObject } from '@chakra-ui/react';

import { IconButton } from '../IconButton';

type Props = {
  onClick: () => void;
  sx?: CSSObject;
};

export const ResetButton = ({ onClick, sx }: Props) => (
  <IconButton
    aria-label="reset value"
    icon={Reset20}
    variant="iconButton"
    onClick={onClick}
    sx={sx}
  />
);
