import { gql, useQuery } from '@apollo/client';

import { GroupInternalFragment } from 'gql/fragments';
import { GroupInternalType, QueryRandomRecommendedGroupsArgs } from 'types';

export const randomRecommendedGroupsGql = gql`
  ${GroupInternalFragment}
  query RandomRecommendedGroups($data: RandomRecommendedGroupsInput!) {
    randomRecommendedGroups(data: $data) {
      ...GroupInternalFragment
    }
  }
`;

export const useRandomRecommendedGroups = () => {
  const { data, loading, error } = useQuery<
    { randomRecommendedGroups: GroupInternalType[] },
    QueryRandomRecommendedGroupsArgs
  >(randomRecommendedGroupsGql, { variables: { data: { sampleSize: 5 } } });

  const randomRecommendedGroups = data?.randomRecommendedGroups ?? [];

  return { randomRecommendedGroups, loading, error };
};
