import { gql, useMutation } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationRenameResourceInLibraryArgs } from 'types';

import {
  getLibraryMediaFavorites,
  getResourcesFromDirectoryInLibraryGql,
} from '../documents';

const renameResourceInLibraryGql = gql`
  mutation RenameResourceInLibrary(
    $libraryType: LibraryTypeEnum!
    $resourceId: String!
    $newName: String!
  ) {
    renameResourceInLibrary(
      libraryType: $libraryType
      resourceId: $resourceId
      newName: $newName
    ) {
      id
    }
  }
`;

export const useRenameResourceInLibrary = () => {
  const [mutation] = useMutation<
    Pick<Mutation, 'renameResourceInLibrary'>,
    MutationRenameResourceInLibraryArgs
  >(renameResourceInLibraryGql, {
    fetchPolicy: 'no-cache',
    refetchQueries: [
      getResourcesFromDirectoryInLibraryGql,
      getLibraryMediaFavorites,
    ],
  });

  const handleMutation = (variables: MutationRenameResourceInLibraryArgs) =>
    mutation({ variables });

  const renameResourceInLibrary = useWithMutationToast(handleMutation, {
    disableSuccessMessage: true,
  });

  return { renameResourceInLibrary };
};
