import { useEffect, useState } from 'react';

export const useCountdown = (seconds: number) => {
  const [counter, setCounter] = useState(seconds);

  useEffect(() => {
    const timer = setInterval(() => setCounter((prev) => prev - 1), 1000);

    if (counter <= 0) clearInterval(timer);

    return () => clearInterval(timer);
  }, [counter]);

  const resetCounter = () => {
    setCounter(seconds);
  };

  return { counter, resetCounter };
};
