import { Link, useLocation } from 'react-router-dom';

import { VStack, HStack, Box, Text } from '@chakra-ui/react';

import { NavigationItem } from 'router/types';

type Props = {
  links: NavigationItem[];
  strictLink?: boolean;
};

export const CmsMenu = ({ links, strictLink }: Props): JSX.Element => {
  const { pathname } = useLocation();

  return (
    <VStack
      minW={230}
      align="flex-start"
      spacing={0}
      mt={5}
      aria-label="CMS Menu"
    >
      {links.map(({ link, label }) => {
        const isActive = strictLink
          ? link === pathname
          : pathname.includes(link);

        return (
          <HStack key={label}>
            <Box
              height="50px"
              width="5px"
              backgroundColor={isActive ? 'primary.blue.default' : 'ui.grey3'}
              m={0}
              mr={4}
            />
            <Link to={link}>
              <Text
                color={isActive ? 'primary.blue.default' : 'ui.grey2'}
                size={isActive ? 'p2b' : 'p2'}
              >
                {label}
              </Text>
            </Link>
          </HStack>
        );
      })}
    </VStack>
  );
};
