import { useFormikContext } from 'formik';

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react';

type Props = {
  onClose: () => void;
  setHasBeenSubmitted: (isSubmitted: boolean) => void;
  isOpen: boolean;
};

export const SocietalSubmitModal = ({
  onClose,
  isOpen,
  setHasBeenSubmitted,
}: Props) => {
  const { submitForm } = useFormikContext();

  const handleClick = () =>
    submitForm()
      .then(() => {
        onClose();
        setHasBeenSubmitted(true);
      })
      .catch((error) => {
        throw new Error(error);
      });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Submit Points allocation?</ModalHeader>
        <ModalBody>
          Are you sure you want to submit your Value Points allocations? Once
          submitted, you will no longer be able to change the previous
          allocation for the 4 Value Categories.
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          <Button onClick={onClose} backgroundColor="gray">
            Cancel
          </Button>
          <Button onClick={handleClick} backgroundColor="status.green.default">
            Yes, submit it.
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
