import { Flex, Heading } from '@chakra-ui/react';

import { CONTENT_PADDING_X } from '../../constants';

type Props = {
  text: string;
};

export const MarketplaceHeroSection = ({ text }: Props) => (
  <Flex
    bg="ui.white"
    px={CONTENT_PADDING_X}
    py={{ md: '64px', base: '44px' }}
    gap={{ md: '66px', base: '40px' }}
    justifyContent="center"
  >
    <Heading size="h3" textAlign="center" w="full" maxWidth={960}>
      {text}
    </Heading>
  </Flex>
);
