import { useTranslation } from 'react-i18next';

import { Grid, Flex, Heading } from '@chakra-ui/react';

import { ImageTextTile } from 'components/ImageTextTile';
import { LayoutGridElement } from 'components/Layout/Grid';
import { Links } from 'router/links';
import { Insight } from 'types';
import { generateUrlById } from 'utils/links';

interface Props {
  relatedArticles: Pick<Insight, 'hero' | 'id'>[];
}

export const RelatedArticlesSection = ({ relatedArticles }: Props) => {
  const { t } = useTranslation(['insights']);

  if (!relatedArticles.length) {
    return null;
  }

  return (
    <LayoutGridElement gridColumn="3/ span 12">
      <Flex direction="column" gap={4}>
        <Heading size="h4">{t('insights:related-articles')}</Heading>
        <Grid gridTemplateColumns="repeat(3, 1fr)" gap={4}>
          {relatedArticles.map((insight) => (
            <ImageTextTile
              key={insight.id}
              src={insight.hero.heroImage.signedUrl}
              title={insight.hero.headline}
              bodyText={insight.hero.summary}
              author={insight.hero.author}
              findOutMoreUrl={generateUrlById(Links.insight, insight.id)}
            />
          ))}
        </Grid>
      </Flex>
    </LayoutGridElement>
  );
};
