import { useMessagingCommunication } from '../MessagingCommunication';
import { useConversationsActions } from '../MessagingStateManager/hooks';

export const useLoadConversations = (isInitialLoad?: boolean) => {
  const {
    setConversations,
    setConversationsTotalCount,
    handleConversationSelect,
  } = useConversationsActions();

  const { loadConversations } = useMessagingCommunication();

  const handleLoadConversations = () =>
    loadConversations().then((conversations) => {
      setConversations(conversations.nodes);

      setConversationsTotalCount(conversations.totalCount);

      if (isInitialLoad) {
        handleConversationSelect(conversations.nodes[0]?.id);
      }
    });

  return handleLoadConversations;
};
