import { gql, MutationHookOptions, useMutation } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationRequestSecondFactorForTokenArgs } from 'types';

export const requestSecondFactorForTokenGql = gql`
  mutation RequestSecondFactorForToken(
    $tokenType: SecondFactorVerificationTypeEnum!
    $token: String!
    $sendLocation: VerificationCodeSendLocation
  ) {
    requestSecondFactorForToken(
      tokenType: $tokenType
      token: $token
      sendLocation: $sendLocation
    )
  }
`;

export const useRequestSecondFactorForToken = <
  TData extends Pick<Mutation, 'requestSecondFactorForToken'>,
  TVariables extends MutationRequestSecondFactorForTokenArgs
>(
  options?: MutationHookOptions<TData, TVariables>
) => {
  const [mutation] = useMutation<TData, TVariables>(
    requestSecondFactorForTokenGql,
    { fetchPolicy: 'no-cache', ...options }
  );

  const handleMutation = (variables: TVariables) => mutation({ variables });

  const requestSecondFactorForToken = useWithMutationToast(handleMutation, {
    disableSuccessMessage: true,
  });

  return { requestSecondFactorForToken };
};
