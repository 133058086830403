import { gql, useMutation } from '@apollo/client';

import { extendedProfileGql } from 'hooks/profiles/useExtendedProfile';
import { extendedProfilesGql } from 'hooks/profiles/useExtendedProfiles';
import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { MutationUpdateFamilyMemberArgs } from 'types';

const updateFamilyMemberGql = gql`
  mutation UpdateFamilyMember(
    $updateAccount: OnboardUpdateMemberDetailsInput!
  ) {
    updateFamilyMember(updateAccount: $updateAccount)
  }
`;

export const useUpdateFamilyMember = () => {
  const [updateFamilyMemberMutation] = useMutation<
    boolean,
    MutationUpdateFamilyMemberArgs
  >(updateFamilyMemberGql);

  const updateFamilyMember = async ({
    updateAccount,
  }: MutationUpdateFamilyMemberArgs) => {
    await updateFamilyMemberMutation({
      variables: { updateAccount },
      refetchQueries: [extendedProfileGql, extendedProfilesGql],
    });
  };

  const updateFamilyMemberWithToast = useWithMutationToast(updateFamilyMember);

  return { updateFamilyMember: updateFamilyMemberWithToast };
};
