import { ReactNode, Suspense } from 'react';
import { Route } from 'react-router-dom';

import { Loader } from 'components/Loader';
import { SecureRoute } from 'router/SecureRoute';
import { Links } from 'router/links';
import { CreatePageOptions } from 'router/types';
import { LayoutType } from 'types';
import { ValueCategoryName } from 'types/ValuesConfigurator';

import { layouts } from './layouts';

type Path = Links | ValueCategoryName | '*';

export type CreatePage = (
  path: Path | Path[],
  element: ReactNode,
  options?: CreatePageOptions
) => ReactNode;

export const createPage: CreatePage = (path, element, options) => {
  const {
    layout = LayoutType.Default,
    mustBeLogin = true,
    roles,
    onlyAfterOnboarding,
  } = options ?? {};

  const link = typeof path === 'string' ? path : path.join('/');

  const LocalLayout = layouts[layout];

  return (
    <Route
      path={link}
      element={
        <SecureRoute
          mustBeLogin={mustBeLogin}
          roles={roles}
          onlyAfterOnboarding={onlyAfterOnboarding}
        >
          <LocalLayout>
            <Suspense fallback={<Loader />}>{element}</Suspense>
          </LocalLayout>
        </SecureRoute>
      }
    />
  );
};
