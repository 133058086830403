import { useTranslation } from 'react-i18next';

import { Flex, Text } from '@chakra-ui/react';

import { Carousel, CarouselType } from 'components/Carousel';
import { useAuthContext } from 'contexts/AuthContext';
import { hasSdg } from 'utils/roles';

import { FeaturedInsightsContent } from '../FeaturedInsightsContent';
import { OtherInsightsContent } from '../OtherInsightsContent';

export const AllTabContent = () => {
  const { t } = useTranslation(['insights']);
  const { account } = useAuthContext();

  if (!hasSdg(account?.role)) {
    return <Text>{t('insights:no-results-sdg')}</Text>;
  }

  return (
    <Flex direction="column" gap={4}>
      <FeaturedInsightsContent />
      <Carousel type={CarouselType.Insights} />
      <OtherInsightsContent />
    </Flex>
  );
};
