import { ReactNode, useState } from 'react';

import { CloseButtonProps } from '@chakra-ui/react';

import { Modal } from 'components/Modal';
import {
  WhatAreSdgsPage,
  WhyAlignToSdgsPage,
} from 'features/Onboarding/routes/SdgPages';

import { OverviewSection } from './OverviewModalSection';
import { SdgScreen } from './types';

interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
}

export const SdgsModal = ({ isOpen, onClose }: Props) => {
  const [currentScreen, setCurrentScreen] = useState(SdgScreen.What);

  const handleClose = () => {
    setCurrentScreen(SdgScreen.What);
    onClose();
  };

  const goToNextScreen = () => {
    setCurrentScreen((prev) => {
      if (prev === SdgScreen.Overview) {
        handleClose();

        return SdgScreen.What;
      }

      return prev + 1;
    });
  };

  const goToPreviousScreen = () => {
    setCurrentScreen((prev) => {
      if (prev === SdgScreen.What) {
        handleClose();

        return SdgScreen.What;
      }

      return prev - 1;
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      isFull
      onClose={handleClose}
      bodyStyling={{ p: 0 }}
      containerStyling={{ background: 'base2' }}
      closeButtonStyling={{
        ...getCloseButtonColorStyling(currentScreen),
        borderRadius: 0,
        zIndex: 20,
      }}
    >
      {screenContent(goToNextScreen, goToPreviousScreen)[currentScreen]}
    </Modal>
  );
};

const screenContent = (
  goToNextScreen: VoidFunction,
  goToPreviousScreen: VoidFunction
): Record<SdgScreen, ReactNode> => ({
  [SdgScreen.What]: (
    <WhatAreSdgsPage
      onBackButtonClicked={goToPreviousScreen}
      onNextButtonClicked={goToNextScreen}
      withBackButton={false}
    />
  ),
  [SdgScreen.Why]: (
    <WhyAlignToSdgsPage
      onBackButtonClicked={goToPreviousScreen}
      onNextButtonClicked={goToNextScreen}
    />
  ),
  [SdgScreen.Overview]: (
    <OverviewSection
      onBackButtonClicked={goToPreviousScreen}
      onNextButtonClicked={goToNextScreen}
    />
  ),
});

const getCloseButtonColorStyling = (
  currentScreen: SdgScreen
): CloseButtonProps => {
  switch (currentScreen) {
    case SdgScreen.What:
      return { color: 'primary.blue.default', background: 'ui.white' };
    default:
      return { color: 'ui.white', background: 'primary.blue.default' };
  }
};
