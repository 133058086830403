import { Box, CSSObject, SliderMark as Mark } from '@chakra-ui/react';

type Props = {
  value: number;
  label?: string;
  styles: CSSObject;
};

export const SliderMark = ({ value, label, styles }: Props) => {
  return (
    <Mark value={value} sx={styles}>
      <Box>
        {label || value}
        <Box pt={7}>
          <Box
            position="absolute"
            bottom={0}
            left="50%"
            h={3}
            borderLeft="solid"
            borderColor="darkblue"
            borderLeftWidth={1}
          />
        </Box>
      </Box>
    </Mark>
  );
};
