import { useBuildingAnimationContext } from '../../contexts';
import { Position, ValuePosition } from '../../types';
import {
  PATH_TRANSFORM_STYLE,
  BOX_PATHS,
  TEXT_PATHS_BOTTOM,
  TEXT_PATHS_UPPER,
} from './constants';

type Props = {
  valueName: string;
  onClick: () => void;
  valuePosition: ValuePosition;
  backgroundColor: string;
  textColor: string;
  categoryPosition: Position;
  isSelected: boolean;
};

export const ValuePanel = ({
  onClick,
  valueName,
  valuePosition,
  backgroundColor,
  textColor,
  categoryPosition,
  isSelected,
}: Props) => {
  const { hasFinishedAnimating } = useBuildingAnimationContext();

  const pathId = `textPath-${valueName}`;

  const isBottom = [Position.LeftBottom, Position.RightBottom].includes(
    categoryPosition
  );

  const boxPathTranslate = PATH_TRANSFORM_STYLE[valuePosition];
  const textPathTranslate = PATH_TRANSFORM_STYLE[valuePosition];

  const boxPath = BOX_PATHS[valuePosition];
  const textPath = isBottom
    ? TEXT_PATHS_BOTTOM[valuePosition]
    : TEXT_PATHS_UPPER[valuePosition];

  const cursor = hasFinishedAnimating ? 'pointer' : 'inherit';

  return (
    <>
      <path
        style={{ transform: boxPathTranslate }}
        cursor={cursor}
        onClick={onClick}
        d={boxPath}
        fill={isSelected ? 'white' : backgroundColor}
      />
      <path
        id={pathId}
        d={textPath}
        visibility="hidden"
        style={{ transform: textPathTranslate }}
      />
      <text
        dy="7px"
        stroke="transparent"
        fill={isSelected ? 'black' : textColor}
        textAnchor="middle"
      >
        <textPath
          stroke="transparent"
          onClick={onClick}
          style={{ userSelect: 'none' }}
          startOffset="50%"
          xlinkHref={`#${pathId}`}
          fontSize={20}
          fontFamily="Roboto"
          cursor={cursor}
          alignmentBaseline="before-edge"
        >
          {valueName}
        </textPath>
      </text>
    </>
  );
};
