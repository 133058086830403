import { VStack } from '@chakra-ui/react';

import { CallToActionOutput } from 'types';

import { EventBodyElement } from '../../EventBodyElement';
import { AttendEvent } from './AttendEvent';
import { RequestHelpCTA } from './RequestHelpCTA ';

type Props = {
  eventId: string;
  eventHeading: string;
  callToAction?: CallToActionOutput;
};

export const EventCTASection = ({
  eventId,
  eventHeading,
  callToAction,
}: Props): JSX.Element | null => {
  if (!callToAction) return null;

  return (
    <EventBodyElement>
      <VStack spacing={4} display="contents">
        {callToAction?.joinInfo?.isEnabled && (
          <AttendEvent
            cta={callToAction?.joinInfo}
            eventId={eventId}
            eventHeading={eventHeading}
          />
        )}

        {callToAction?.helpRequest?.isEnabled && (
          <RequestHelpCTA
            cta={callToAction?.helpRequest}
            eventId={eventId}
            eventHeading={eventHeading}
          />
        )}
      </VStack>
    </EventBodyElement>
  );
};
