import { createUploadLink } from 'apollo-upload-client';
import { createClient } from 'graphql-ws';

import { ApolloClient, ApolloLink, InMemoryCache, split } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';

import { LocalStorageKeys } from 'types';
import { ErrorCode } from 'types/ErrorCode';
import { stripTypenames } from 'utils/apollo';

const HTTPS_API = process.env.REACT_APP_API as string;
const WS_API = process.env.REACT_APP_WEBSOCKET_API as string;

export const composeClient = () => {
  const cache = new InMemoryCache();

  const cleanTypeName = new ApolloLink((operation, forward) => {
    if (operation.variables) {
      operation.variables = stripTypenames(operation.variables);
    }

    operation.setContext({
      headers: {
        Authorization: localStorage.getItem(LocalStorageKeys.JWT),
      },
    });

    return forward(operation);
  });

  const uploadLink = createUploadLink({
    uri: HTTPS_API,
  });

  const subscriptionClient = createClient({
    url: WS_API,
    lazy: true,
    connectionAckWaitTimeout: 3600000,
    connectionParams: () => ({
      Authorization: localStorage.getItem(LocalStorageKeys.JWT),
    }),
  });

  const wsLink = new GraphQLWsLink(subscriptionClient);

  const terminatingLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);

      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    uploadLink
  );

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        const isUnauthorized = graphQLErrors.some(
          ({ extensions: { code } }) => {
            return code === ErrorCode.UNAUTHENTICATED;
          }
        );
        const hasJWT = !!localStorage.getItem(LocalStorageKeys.JWT);

        if (isUnauthorized && hasJWT) {
          window.dispatchEvent(new Event('unauthorized'));

          return undefined;
        }

        return forward(operation);
      }

      if (networkError) {
        if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line no-console
          console.log(`[Network error]: ${networkError}`);
        }
      }

      return undefined;
    }
  );

  return new ApolloClient({
    link: ApolloLink.from([errorLink, cleanTypeName, terminatingLink]),
    cache,
  });
};
