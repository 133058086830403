import { Message } from 'types';

import { useMessagingCommunication } from '../MessagingCommunication';
import { useMessagesActions } from '../MessagingStateManager/hooks';

export const useDeleteMessage = () => {
  const { deleteMessage } = useMessagingCommunication();

  const { deleteMessage: deleteMessageFromState } = useMessagesActions();

  const handleDelete = (message: Message) =>
    deleteMessage(message.id).then(() => deleteMessageFromState(message));

  return handleDelete;
};
