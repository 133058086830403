import { useTranslation } from 'react-i18next';

import { Box, Flex } from '@chakra-ui/react';

import { Avatar } from 'components/Avatar';
import { ManagePostContextProvider } from 'contexts/ManagePostContext';
import { Comment as CommentType } from 'types';
import { dateDistance } from 'utils/date';

import { EditCommentForm } from '../CommentForm';
import { useDeleteComment } from '../CommentForm/useDeleteComment';
import { DeleteEntityConfirmationModal } from '../DeleteEntityConfirmationModal';
import { ManagePostMenuButton } from '../ManagePostMenuButton';
import { CommentPreview } from './CommentPreview';

interface Props {
  comment: CommentType;
}

export const CommentBase = ({ comment }: Props) => {
  const { handleDeleteComment } = useDeleteComment();
  const { t } = useTranslation(['network', 'cms']);

  return (
    <Flex
      key={comment.id}
      direction="column"
      position="relative"
      w="full"
      gap={2}
      aria-label="comment"
    >
      <Avatar
        profile={comment.postedBy}
        variant="author"
        subheadingInfo={dateDistance(comment.createdAt)}
      />
      <ManagePostContextProvider
        entityName={t('network:comment')}
        createdBy={comment.createdBy}
      >
        {({
          handleCancelDeleting,
          handleCancelEditing,
          isDeleteConfirmationModalOpen,
          isEditing,
          menuElements,
        }) => (
          <>
            <Flex ml={9}>
              {isEditing ? (
                <EditCommentForm
                  comment={comment}
                  onUpdate={handleCancelEditing}
                />
              ) : (
                <CommentPreview comment={comment} />
              )}
            </Flex>
            <Box position="absolute" right={0} top={4}>
              <ManagePostMenuButton
                menuElements={menuElements}
                handleCancelEditing={handleCancelEditing}
                isEditing={isEditing}
              />
            </Box>

            <DeleteEntityConfirmationModal
              handleDelete={() => handleDeleteComment(comment.id)}
              isOpen={isDeleteConfirmationModalOpen}
              onClose={handleCancelDeleting}
              entity={t('network:comment')}
            >
              {t('cms:delete.action-explanation', {
                entity: t('network:comment'),
              })}
            </DeleteEntityConfirmationModal>
          </>
        )}
      </ManagePostContextProvider>
    </Flex>
  );
};
