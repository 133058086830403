export const NAME_CONFIRMATION_FIELD = 'deletionConfirmationField';

export interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
  onConfirm: (validationInput: string) => void;
  entity: 'user' | 'family' | 'advisor' | 'group';
  name: string;
  secondaryName?: string;
}

export interface FormValues {
  [NAME_CONFIRMATION_FIELD]: string;
}
