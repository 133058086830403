import { ComponentClass, FC } from 'react';

import { CarbonIconType } from '@carbon/icons-react';

/** This HOC is very ugly, but needed in order to remove Carbon icons error.
 *
 *  ``` Warning: Failed prop type: Invalid prop `aria-hidden` of type `boolean` supplied to `Icon`,
 *  expected `string`. ```
 *
 * This error is caused by Carbon Icons prop types, more specifically, 'aria-hidden' prop on Icon,
 * which is 'string', but Chakra passes boolean value
 *
 * TODO: refactor other places where Carbon icons are used, that causes this error
 * https://itriom.atlassian.net/browse/LEIP-1130
 */
export const withProperAriaHidden = <T extends { ['aria-hidden']?: boolean }>(
  Component: CarbonIconType | FC<T> | ComponentClass<T>
): FC<T> => {
  const ComponentIconWithProperProps = (props: T) => {
    // eslint-disable-next-line react/destructuring-assignment
    const ariaHidden = props['aria-hidden'];

    return (
      <Component
        {...props}
        aria-hidden={ariaHidden ? String(ariaHidden) : undefined}
      />
    );
  };

  return ComponentIconWithProperProps;
};
