import { Conversation, Message } from 'types';

import { MessagesSelectors, MessagesState } from './types';

const createSelectMessagesForConversations =
  (state: MessagesState): MessagesSelectors['selectMessagesForConversation'] =>
  (conversationId: Conversation['id']) => {
    const messages = state.messagesMap[conversationId] || [];

    const sortedMessages = [...messages].sort(
      (a, b) => b.createdAt - a.createdAt
    );

    return sortedMessages;
  };

const createSelectHasMoreMessages =
  (state: MessagesState): MessagesSelectors['selectHasMoreMessages'] =>
  (conversationId: Conversation['id']) => {
    const messages = state.messagesMap[conversationId];
    const messagesCount = state.messagesTotalCountMap[conversationId];

    if (!messages || messagesCount === undefined) return false;

    return messages?.length < messagesCount;
  };

const createSelectIsMessageInConversations =
  (state: MessagesState): MessagesSelectors['selectIsMessageInConversations'] =>
  (message: Message) =>
    !!Object.keys(state.messagesMap).includes(message.conversationId);

export const composeMessagesSelectors = (
  state: MessagesState
): MessagesSelectors => ({
  selectMessagesForConversation: createSelectMessagesForConversations(state),
  selectIsMessageInConversations: createSelectIsMessageInConversations(state),
  selectHasMoreMessages: createSelectHasMoreMessages(state),
});
