import { Flex, GridItem } from '@chakra-ui/react';

import { SdgInternalName } from 'types/Sdg';

import { SdgElement } from '../SdgElement';

const SDGS = Object.values(SdgInternalName).map((internalName) => ({
  internalName,
}));

export const SdgIcons = () => {
  return (
    <Flex
      ml={{ lg: 20, base: 'inherit' }}
      mb="auto"
      mt={{ lg: 0, base: 20 }}
      maxW={{ lg: '268px' }}
      gap={1}
      flexWrap="wrap"
    >
      {SDGS.map((sdg) => (
        <GridItem w={16} h={16} key={sdg.internalName}>
          <SdgElement internalName={sdg.internalName} />
        </GridItem>
      ))}
    </Flex>
  );
};
