import { Conversation, Profile } from 'types';

export type AddConversationAction = {
  type: ConversationsActionTypes.AddConversation;
  payload: Conversation;
};

export type SetConversationsAction = {
  type: ConversationsActionTypes.SetConversations;
  payload: Conversation[];
};

export type AddConversationToUnreadAction = {
  type: ConversationsActionTypes.AddToUnread;
  payload: Conversation['id'];
};

export type SetConversationsTotalCountAction = {
  type: ConversationsActionTypes.SetConversationsTotalCount;
  payload: number;
};

export type DeleteConversationAction = {
  type: ConversationsActionTypes.DeleteConversation;
  payload: Conversation['id'];
};

export type HandleConversationSelectAction = {
  type: ConversationsActionTypes.HandleConversationSelect;
  payload: Conversation['id'] | undefined;
};

export type HandleInterlocutorTrigger = {
  type: ConversationsActionTypes.HandleInterlocutorTrigger;
  payload: Profile;
};

export type HandleSelectConversationByUserAction = {
  type: ConversationsActionTypes.HandleSelectConversationByUser;
  payload: Profile;
};

export enum ConversationsActionTypes {
  AddConversation = 'addConversation',
  AddToUnread = 'addToUnread',
  SetConversations = 'setConversations',
  SetConversationsTotalCount = 'setConversationsTotalCount',
  DeleteConversation = 'deleteConversation',
  HandleConversationSelect = 'handleConversationSelect',
  HandleInterlocutorTrigger = 'handleInterlocutorTrigger',
  HandleSelectConversationByUser = 'handleSelectConversationByUser',
}

export type ConversationsActionsUnion =
  | AddConversationAction
  | AddConversationToUnreadAction
  | DeleteConversationAction
  | HandleConversationSelectAction
  | HandleInterlocutorTrigger
  | SetConversationsAction
  | SetConversationsTotalCountAction
  | HandleSelectConversationByUserAction;

export type ConversationsState = {
  conversations: Conversation[];
  conversationsTotalCount: number;
  activeConversationId: Conversation['id'] | undefined;
  interlocutorsForNewConversation: Profile[];
  unreadConversationIds: Array<Conversation['id']>;
};

export type ConversationsActions = {
  addConversation: (conversation: Conversation) => void;
  addToUnread: (conversationId: Conversation['id']) => void;
  setConversations: (conversations: Conversation[]) => void;
  setConversationsTotalCount: (conversationsTotalCount: number) => void;
  deleteConversation: (conversationId: Conversation['id']) => void;
  handleConversationSelect: (
    conversationId: Conversation['id'] | undefined
  ) => void;
  handleInterlocutorTrigger: (user: Profile) => void;
  handleSelectConversationByUser: (user: Profile) => void;
};

export type ConversationsSelectors = {
  selectConversations: () => Conversation[];
  selectHasMoreConversations: () => boolean;
  selectActiveConversation: () => Conversation | undefined;
  selectUnreadConversationIds: () => Array<Conversation['id']>;
  selectInterlocutorsForNewConversation: () => Profile[];
};
