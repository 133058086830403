import { Flex, Heading, Text } from '@chakra-ui/react';

import { Quote } from '../../types';

export const QuoteSection = ({ quote, author }: Quote) => (
  <Flex w="full" h="fit-content" position="relative" bg="white" pt={16}>
    <Flex
      bgImage="/assets/marketplace/quote-section-background.png"
      direction="column"
      bgSize="cover"
      bgPosition={{ lg: 'right', md: '75%', base: '75%' }}
      zIndex={10}
      py={{ lg: '50px', md: '68px', base: '44px' }}
      pl={{ lg: '162px', md: '92px', base: '30px' }}
      pr={{ lg: '166px', md: '171px', base: '30px' }}
      gap={8}
    >
      <Heading size="h3" textAlign="left" w="full" maxW={960} color="ui.white">
        {quote}
      </Heading>
      <Flex direction="column" justifyContent="flex-start" mr="auto">
        <Text size="p2" textAlign="left" color="ui.white">
          {author}
        </Text>
      </Flex>
    </Flex>
  </Flex>
);
