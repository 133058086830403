import { useAcceptConnectionInvitation } from 'gql/connections/mutation/acceptConnectionInvitation';
import { useInviteToConnections } from 'gql/connections/mutation/inviteToConnections';
import { useRemoveFromConnections } from 'gql/connections/mutation/removeFromConnections';
import { useInvitationForMe } from 'hooks/connections/useInvitationForMe';
import { ProfileConnectionStateEnum } from 'types';

import { Props } from './ConnectMenuButton';

const getButtonText = (connectionState?: ProfileConnectionStateEnum) => {
  switch (connectionState) {
    case ProfileConnectionStateEnum.InvitationPending:
      return 'Pending';
    case ProfileConnectionStateEnum.Connected:
      return 'Remove connection';
    case ProfileConnectionStateEnum.NotConnected:
    case undefined:
    default:
      return 'Connect';
  }
};

export const useConnectMenuButtonProps = (
  userId: string,
  connectionState?: ProfileConnectionStateEnum
): Props => {
  const invitationForMe = useInvitationForMe(userId);

  const { removeConnection } = useRemoveFromConnections();
  const { inviteToConnections } = useInviteToConnections();
  const { acceptConnectionInvitation } = useAcceptConnectionInvitation();

  const handleClick = () => {
    if (invitationForMe) {
      acceptConnectionInvitation(invitationForMe.id);
    } else if (
      !connectionState ||
      connectionState === ProfileConnectionStateEnum.NotConnected
    ) {
      inviteToConnections(userId);
    } else if (connectionState === ProfileConnectionStateEnum.Connected) {
      removeConnection(userId);
    }
  };

  const text = invitationForMe
    ? 'Accept Connect'
    : getButtonText(connectionState);

  return { text, handleClick };
};
