import { MenuList } from '@chakra-ui/react';

import { QueryStateGuard } from 'components/QueryStateGuard';
import { useSearchContext } from 'contexts/SearchContext';

import { Loading } from './Loading';
import { NoMatchedResults } from './NoMatchedResults';
import { Results } from './Results';

export const SearchResults = () => {
  const { loading, hasResults } = useSearchContext();

  return (
    <MenuList
      borderTopRadius="none"
      borderBottomRadius="sm"
      zIndex="dropdown"
      p={0}
      w={374}
    >
      <QueryStateGuard loading={loading} LoaderComponent={Loading}>
        {hasResults ? <Results /> : <NoMatchedResults />}
      </QueryStateGuard>
    </MenuList>
  );
};
