import { useTranslation } from 'react-i18next';

import { Flex, Heading, Grid } from '@chakra-ui/react';

import { EventObjective } from 'types';

import { ContentWithHeading } from '../../ContentWithHeading';
import { EventBodyElement } from '../../EventBodyElement';

interface Props {
  objectives?: EventObjective[];
}

export const ObjectivesSection = ({ objectives }: Props) => {
  const { t } = useTranslation(['events']);

  if (!objectives || !objectives.length) return null;

  return (
    <EventBodyElement>
      <ContentWithHeading heading={t('events:objectives')}>
        <Grid gridTemplateColumns="repeat(3, 1fr)" gap={4}>
          {objectives.map((objective) => (
            <Flex
              key={objective.heading}
              bgColor="ui.white"
              p={4}
              gap={2}
              direction="column"
            >
              <Heading size="h1" color="green1">
                {objective.heading}
              </Heading>
              <Heading size="h5">{objective.description}</Heading>
            </Flex>
          ))}
        </Grid>
      </ContentWithHeading>
    </EventBodyElement>
  );
};
