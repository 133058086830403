import { Flex } from '@chakra-ui/react';

import { Conversation } from 'types';

import { ConversationHeader } from '../ConversationHeader';
import { NewMessageInput } from '../NewMessageInput';
import { MessagesInfiniteList } from './MessagesInfiniteList';

type Props = {
  activeConversation: Conversation;
  handleSendMessage: (content: string) => Promise<void>;
};

export const Messages = ({ activeConversation, handleSendMessage }: Props) => {
  return (
    <Flex direction="column" bgColor="ui.white" w="full">
      <ConversationHeader conversation={activeConversation} />
      {activeConversation && (
        <MessagesInfiniteList conversation={activeConversation} />
      )}
      {activeConversation && (
        <NewMessageInput sendMessage={handleSendMessage} />
      )}
    </Flex>
  );
};
