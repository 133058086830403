import { ValueConfiguratorCategoryEnum } from 'types';

import { SocietalStep } from '../../components/ValuesConfigurator';

type Props = {
  valuesCategory?: ValueConfiguratorCategoryEnum;
};

export const SocietalValues = ({
  valuesCategory = ValueConfiguratorCategoryEnum.Importance,
}: Props): JSX.Element => {
  return <SocietalStep valuesCategory={valuesCategory} />;
};
