import { gql, useMutation } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationRemoveResourceInLibraryArgs } from 'types';

import {
  getLibraryMediaFavorites,
  getResourcesFromDirectoryInLibraryGql,
} from '../documents';

const removeResourceInLibraryGql = gql`
  mutation RemoveResourceInLibrary(
    $libraryType: LibraryTypeEnum!
    $resourceId: String!
  ) {
    removeResourceInLibrary(libraryType: $libraryType, resourceId: $resourceId)
  }
`;

export const useRemoveResourceInLibrary = () => {
  const [mutation] = useMutation<
    Pick<Mutation, 'removeResourceInLibrary'>,
    MutationRemoveResourceInLibraryArgs
  >(removeResourceInLibraryGql, {
    fetchPolicy: 'no-cache',
    refetchQueries: [
      getResourcesFromDirectoryInLibraryGql,
      getLibraryMediaFavorites,
    ],
  });

  const handleMutation = (variables: MutationRemoveResourceInLibraryArgs) =>
    mutation({ variables });

  const removeResourceInLibrary = useWithMutationToast(handleMutation, {
    disableSuccessMessage: true,
  });

  return { removeResourceInLibrary };
};
