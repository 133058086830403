import { useLazyQuery } from '@apollo/client';

import { Query, QueryGetDownloadUrlForLibraryFileArgs } from 'types';

import { getDownloadUrlForLibraryFileGql } from '../documents';

export const useGetDownloadUrlForLibraryFileLazy = () => {
  const [getDownloadUrl, { data, error, loading }] = useLazyQuery<
    Pick<Query, 'getDownloadUrlForLibraryFile'>,
    QueryGetDownloadUrlForLibraryFileArgs
  >(getDownloadUrlForLibraryFileGql);

  const downloadUrl = data?.getDownloadUrlForLibraryFile;

  return {
    getDownloadUrl,
    downloadUrl,
    loading,
    error,
  };
};
