import { useBuildingAnimationContext, useWheelState } from '../../contexts';
import { useBuildingAnimation } from '../../useBuildingAnimation';
import { AnimatedCenterWheel } from './AnimatedCenterWheel';

export const CenterWheel = () => {
  const { currentCategory } = useBuildingAnimationContext();
  const { selectedCategory } = useWheelState();

  useBuildingAnimation();

  const category =
    currentCategory === undefined ? selectedCategory : currentCategory;

  return <AnimatedCenterWheel category={category} />;
};
