import { gql, OperationVariables, useQuery } from '@apollo/client';

import { createMock } from 'mocks';
import { accountMock } from 'mocks/account.mock';
import { AccountFragment } from 'types/generated-fragments';
import { Query } from 'types/generated-types';

export const accountGql = gql`
  ${AccountFragment}
  query Account {
    account {
      ...AccountFragment
    }
  }
`;

export const useAccount = (options?: OperationVariables) => {
  const { data, loading, refetch, error } = useQuery<Pick<Query, 'account'>>(
    accountGql,
    options
  );

  return {
    account: data?.account,
    loading,
    error,
    refetch,
  };
};

export const accountGqlMock = createMock<Pick<Query, 'account'>>(accountGql, {
  data: { account: accountMock },
});
