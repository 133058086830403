import { gql, useMutation } from '@apollo/client';

import { onboardManagementGql } from 'hooks/onboarding/useOnboardingManagementByReference';
import { useWithMutationToast } from 'hooks/useWithMutationToast';
import {
  AttendanceStatusEnum,
  Mutation,
  MutationSetAttendanceResponseAsAdminArgs,
} from 'types';

export const setAttendanceResponseAsAdminGql = gql`
  mutation SetAttendanceResponseAsAdmin(
    $status: AttendanceStatusEnum!
    $accountId: String!
  ) {
    setAttendanceResponseAsAdmin(status: $status, accountId: $accountId)
  }
`;

export const useSetAttendance = (accountId: string) => {
  const [mutate, { loading }] = useMutation<
    Pick<Mutation, 'setAttendanceResponseAsAdmin'>,
    MutationSetAttendanceResponseAsAdminArgs
  >(setAttendanceResponseAsAdminGql, {
    refetchQueries: [onboardManagementGql],
  });

  const handleChange = (status: AttendanceStatusEnum) =>
    mutate({
      variables: {
        status,
        accountId,
      },
    });

  const changeStatus = useWithMutationToast(handleChange, {
    successMessage: 'Attendance has been changed',
    errorMessage: 'Changing attendance failed',
  });

  return {
    changeStatus,
    loading,
  };
};
