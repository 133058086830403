import { useQuery } from '@apollo/client';

import { Query } from 'types';

import { advisorByRelatedAccountIdGql } from '../documents';

export const useAdvisorByRelatedAccountId = (id: string | undefined) => {
  const { data, loading, error } = useQuery<
    Pick<Query, 'advisorByRelatedAccountId'>
  >(advisorByRelatedAccountIdGql, {
    variables: { advisorByRelatedAccountId: id },
    skip: !id,
  });
  const advisor = data?.advisorByRelatedAccountId;

  return { advisor, loading, error };
};
