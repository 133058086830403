import { Tab } from 'components/TabPane';
import { EventType } from 'types';

import { EventList } from './EventList';

type EventTabs = EventType;

export const eventTabsConfig: Record<EventTabs, Tab> = {
  [EventType.All]: {
    content: <EventList type={EventType.All} />,
    navigateTo: `#ALL`,
    labelKey: 'all',
  },
  [EventType.Family]: {
    content: <EventList type={EventType.Family} />,
    navigateTo: `#FAMILY`,
    labelKey: 'family',
  },
  [EventType.Peers]: {
    content: <EventList type={EventType.Peers} />,
    navigateTo: `#PEERS`,
    labelKey: 'peers',
  },
  [EventType.Itriom]: {
    content: <EventList type={EventType.Itriom} />,
    navigateTo: `#ITRIOM`,
    labelKey: 'itriom',
  },
  [EventType.Public]: {
    content: <EventList type={EventType.Public} />,
    navigateTo: `#PUBLIC`,
    labelKey: 'public',
  },
  [EventType.My]: {
    content: <EventList type={EventType.My} />,
    navigateTo: `#MY`,
    labelKey: 'my',
  },
};
