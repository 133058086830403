import { useTranslation } from 'react-i18next';

import { Flex, Box } from '@chakra-ui/react';

import { useAuthContext } from 'contexts/AuthContext';
import { useCurrentLibraryLocation } from 'features/Library/hooks/useCurrentLibraryLocation';
import { LibraryLocation } from 'features/Library/types';
import { Links } from 'router/links';

import { LibraryDataUsage } from './LibraryDataUsage';
import { NavigationLink } from './NavigationLink';

export const SideNavigation = () => {
  const { t } = useTranslation(['library']);
  const { account } = useAuthContext();

  const { libraryLocation } = useCurrentLibraryLocation();

  return (
    <Flex
      flexDirection="column"
      bg="ui.white"
      borderColor="ui.grey4"
      borderWidth={1}
      borderRadius={2}
    >
      <NavigationLink to={Links.libraryMy}>
        <Flex flexDirection="column" gap={4}>
          <Box>{t('library:side-navigation.header')}</Box>
          {libraryLocation === LibraryLocation.My ? <LibraryDataUsage /> : null}
        </Flex>
      </NavigationLink>
      {!!account?.familyId && (
        <NavigationLink to={Links.libraryFamily} withBorder>
          <Flex flexDirection="column" gap={4}>
            <Box>{t('library:side-navigation.items.family')}</Box>
            {libraryLocation === LibraryLocation.Family ? (
              <LibraryDataUsage />
            ) : null}
          </Flex>
        </NavigationLink>
      )}
      <NavigationLink to={Links.libraryFavourites} withBorder>
        {t('library:side-navigation.items.favourites')}
      </NavigationLink>
      <NavigationLink to={Links.librarySharedWithMe} withBorder>
        {t('library:side-navigation.items.shared')}
      </NavigationLink>
      <NavigationLink to={Links.libraryItriom} withBorder>
        {t('library:side-navigation.items.itriom')}
      </NavigationLink>
      <NavigationLink to={Links.libraryRecentlyDeleted} withBorder>
        {t('library:side-navigation.items.deleted')}
      </NavigationLink>
    </Flex>
  );
};
