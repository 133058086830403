import { useTranslation } from 'react-i18next';

import { ConfirmationModal } from 'components/Modal';
import { WithChildren } from 'types';

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
  entity: string;
  handleDelete: VoidFunction;
};

export const DeleteEntityConfirmationModal = ({
  isOpen,
  onClose,
  entity,
  children,
  handleDelete,
}: WithChildren<Props>) => {
  const { t } = useTranslation(['cms']);

  return (
    <ConfirmationModal
      isOpen={isOpen}
      onClose={onClose}
      title={t('cms:delete.action', { entity, isQuestion: true })}
      question={children}
      actionText={t('cms:delete.confirm')}
      handleClick={handleDelete}
    />
  );
};
