import { useTranslation } from 'react-i18next';

import { Box, Flex } from '@chakra-ui/layout';
import { Grid, Heading, Text } from '@chakra-ui/react';

import { underlay } from 'theme/mixins/underlay';
import { EventKeySpeakerOutput } from 'types';

import { isKeynoteSpeakerBlockVisible } from '../../utils';

type Props = {
  keySpeaker?: EventKeySpeakerOutput;
};

export const EventKeynoteSpeakerCard = ({
  keySpeaker,
}: Props): JSX.Element | null => {
  const isVisible = isKeynoteSpeakerBlockVisible(keySpeaker);
  const { t } = useTranslation(['events']);
  if (!keySpeaker || !isVisible) return null;
  const { about, name, image, keynoteSpeakerHeading, title } = keySpeaker;

  return (
    <Grid
      gridTemplateColumns="repeat(2, 1fr)"
      backgroundImage={image?.signedUrl}
      position="relative"
      backgroundSize="contain"
      backgroundRepeat="no-repeat"
      minH={264}
    >
      <Box
        position="absolute"
        inset={0}
        {...underlay({ direction: 'row-reverse' })}
      />
      <Flex gridColumn="2/2" direction="column" zIndex={2} p={6} gap={8}>
        <Text color="ui.white" size="p1b">
          {t('events:keynote-speaker')}
          {keynoteSpeakerHeading && ` | ${keynoteSpeakerHeading}`}
        </Text>
        <Flex direction="column" gap={4}>
          <Flex direction="column">
            <Heading color="ui.white" size="h3">
              {name}
            </Heading>
            <Text color="ui.white" size="p3">
              {title}
            </Text>
          </Flex>
          <Text color="ui.white" size="p1">
            {about}
          </Text>
        </Flex>
      </Flex>
    </Grid>
  );
};
