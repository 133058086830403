import { Flex, Grid, Heading, Text } from '@chakra-ui/react';

import { ServiceCard } from '../../types';
import { InterService } from './components/InterService';

type Props = {
  sectionTitle?: string;
  description?: string;
  serviceCards: ServiceCard[];
};

export const InterConnectedServicesSection = ({
  sectionTitle,
  description,
  serviceCards,
}: Props) => {
  return (
    <Flex
      bg="primary.blue.default"
      w="full"
      py={12}
      direction="column"
      justifyContent="center"
    >
      {sectionTitle && (
        <Heading size="h3" textAlign="center" w="full">
          {sectionTitle}
        </Heading>
      )}

      {description && (
        <Text
          size="p1"
          maxWidth={800}
          textAlign="center"
          mx="auto"
          pt={6}
          pb={14}
        >
          {description}
        </Text>
      )}

      <Grid
        templateColumns={{
          base: '1fr',
          lg: 'repeat(3, 1fr)',
        }}
        w="full"
        px="123px"
        gap={{ md: '16px', base: '55px' }}
      >
        {serviceCards.map((service) => (
          <InterService {...service} key={service.src} />
        ))}
      </Grid>
    </Flex>
  );
};
