import { useEffect } from 'react';

import { VALUE_CATEGORIES } from './constants';
import { useBuildingAnimationContext } from './contexts';
import { getBuildingAnimationTimeout } from './utils';

const CATEGORIES_SEQUENCE = [...VALUE_CATEGORIES];

const LAST_TIMEOUT = getBuildingAnimationTimeout(CATEGORIES_SEQUENCE.length);

export const useBuildingAnimation = () => {
  const { setCategory, setFinishedAnimating } = useBuildingAnimationContext();

  useEffect(() => {
    const timeouts = CATEGORIES_SEQUENCE.map((category, index) =>
      setTimeout(
        () => setCategory(category),
        getBuildingAnimationTimeout(index)
      )
    );

    const lastTimeout = setTimeout(() => {
      setCategory(undefined);
      setFinishedAnimating();
    }, LAST_TIMEOUT);

    return () => {
      [...timeouts, lastTimeout].forEach((timeoutId) =>
        clearTimeout(timeoutId)
      );
    };
  }, [setCategory, setFinishedAnimating]);
};
