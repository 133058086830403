import { ReactNode, useEffect, useRef } from 'react';

import { Box } from '@chakra-ui/react';

type SdgSectionProps = {
  variant?: 'primary' | 'secondary';
  children?: ReactNode;
  scrollToTop?: boolean;
};

export const SdgSection = ({
  variant = 'primary',
  children,
  scrollToTop,
}: SdgSectionProps) => {
  const isPrimary = variant === 'primary';
  const color = isPrimary ? 'primary.blue.default' : 'white';
  const sectionRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (scrollToTop) {
      sectionRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <Box ref={sectionRef}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        color={color}
        bgColor={isPrimary ? 'transparent' : 'primary.blue.default'}
        width="100%"
        minHeight="80vh"
        position="relative"
      >
        {children}
      </Box>
    </Box>
  );
};
