import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AspectRatio, Box, SimpleGrid } from '@chakra-ui/react';

import { SdgOverview, SGDS_OVERVIEW } from 'constants/Sdgs';

import { SectionHeading } from '../../SectionHeader';
import { FlipCard } from './FlipCard';

export const SdgsDetail = () => {
  const { t } = useTranslation(['family']);
  const [sdg, setSdg] = useState<SdgOverview | null>();

  const handleClick = (clickedSdg: SdgOverview) => {
    setSdg(sdg === clickedSdg ? null : clickedSdg);
  };

  return (
    <Box w={1080}>
      <SectionHeading
        heading={t('family:charter.section.sdgInfluences.heading')}
        subheading={t('family:charter.section.sdgInfluences.subheading')}
        color="primary.blue.default"
      />

      <SimpleGrid columns={{ base: 2, md: 3, lg: 4 }}>
        {Object.values(SGDS_OVERVIEW).map((sdgItem) => (
          <AspectRatio key={sdgItem.internalName} ratio={27 / 70} h="full">
            <Box onClick={() => handleClick(sdgItem)}>
              <FlipCard isFlipped={sdgItem === sdg} sdgOverview={sdgItem} />
            </Box>
          </AspectRatio>
        ))}
      </SimpleGrid>
    </Box>
  );
};
