import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Text } from '@chakra-ui/react';

import { LayoutGrid } from 'components/Layout/Grid/LayoutGrid';
import { Map } from 'components/Map';
import { QueryStateGuard } from 'components/QueryStateGuard';
import { useAuthContext } from 'contexts/AuthContext';
import { useEvent } from 'hooks/events';

import { ContentWithHeading } from '../components/ContentWithHeading';
import { EventBodyElement } from '../components/EventBodyElement';
import { EventKeynoteSpeakerCard } from '../components/EventKeynoteSpeakerCard';
import { EventUpdateList } from '../components/EventUpdateList';
import { AgendaSection } from '../components/Sections/Agenda';
import { EventCTASection } from '../components/Sections/EventCTA/EventCTASection';
import { HeroSection } from '../components/Sections/Hero';
import { LocationSection } from '../components/Sections/Location';
import { ObjectivesSection } from '../components/Sections/Objectives';
import { OrganisersSection } from '../components/Sections/Organisers/Organisers';
import { SpeakersSection } from '../components/Sections/Speakers';
import { SponsorsSection } from '../components/Sections/Sponsors';
import { SummarySection } from '../components/Sections/Summary';
import { TopicsSection } from '../components/Sections/Topics';
import { UltimateGoalCard } from '../components/UltimateGoalCard';
import { shouldForbidContentDisplay } from '../utils/shouldForbidContentDisplay';

export const EventPage = (): JSX.Element => {
  const { id } = useParams();
  const { account, loading: accountLoading } = useAuthContext();
  const { t } = useTranslation(['events', 'common']);

  if (!id) throw new TypeError('Event id is required');
  const { event, loading: eventLoading, error } = useEvent(id);

  if (
    shouldForbidContentDisplay(eventLoading || accountLoading, event, account)
  ) {
    return (
      <LayoutGrid>
        <Text>{t('common:content-unavailable')}</Text>
      </LayoutGrid>
    );
  }

  return (
    <QueryStateGuard loading={eventLoading} error={error}>
      {event && (
        <LayoutGrid rowGap={14}>
          <HeroSection event={event} />
          <SummarySection event={event} />
          <OrganisersSection organisers={event.organisers} />
          <EventBodyElement>
            <EventKeynoteSpeakerCard keySpeaker={event.keySpeaker} />
          </EventBodyElement>
          <TopicsSection topics={event.topics} />
          <ObjectivesSection objectives={event.objectives} />
          <EventBodyElement>
            <UltimateGoalCard ultimateGoal={event.ultimateGoal} />
          </EventBodyElement>
          <AgendaSection agenda={event.agenda} />
          <EventCTASection
            callToAction={event.callToAction}
            eventId={event.id}
            eventHeading={event.hero.name}
          />
          <SpeakersSection agenda={event.agenda} />
          <LocationSection location={event.basicInfo?.location} />
          <SponsorsSection sponsors={event.sponsors} />

          {!!event.basicInfo?.location && (
            <EventBodyElement>
              <Map location={event.basicInfo?.location} />
            </EventBodyElement>
          )}

          <EventBodyElement>
            <ContentWithHeading heading={t('events:updates')}>
              <EventUpdateList />
            </ContentWithHeading>
          </EventBodyElement>
        </LayoutGrid>
      )}
    </QueryStateGuard>
  );
};
