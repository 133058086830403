import { InfiniteList } from 'components/InfiniteList';
import { NotificationUnion } from 'types';

import { NotificationItem } from './NotificationItem/NotificationItem';

type NotificationsInfiniteListProps = {
  hasMore: boolean;
  notifications: NotificationUnion[];
  loadMore: () => void;
  markAsRead: (notificationId: string) => void;
};

export const NotificationsInfiniteList = ({
  hasMore,
  notifications,
  loadMore,
  markAsRead,
}: NotificationsInfiniteListProps) => {
  return (
    <InfiniteList
      hasMoreItems={hasMore}
      items={notifications}
      loadMoreItems={loadMore}
      wrapperProps={{ maxH: '350px' }}
      emptyListMessage="No notifications"
      renderItem={(notification) => (
        <NotificationItem
          notificationData={notification}
          key={notification.id}
          markAsRead={markAsRead}
        />
      )}
    />
  );
};
