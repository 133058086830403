import { WithChildren } from 'types';

import { MessagingCommunicationProvider } from './MessagingCommunication';
import { MessagingStateContextProvider } from './MessagingStateManager/MessagingStateProvider';
import { MessagesSubscriber } from './components/MessagesSubscriber';

export const MessagingProviders = ({ children }: WithChildren) => {
  return (
    <MessagingStateContextProvider>
      <MessagingCommunicationProvider>
        <MessagesSubscriber>{children}</MessagesSubscriber>
      </MessagingCommunicationProvider>
    </MessagingStateContextProvider>
  );
};
