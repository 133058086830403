import { Box, Flex, SimpleGrid } from '@chakra-ui/react';

import { SDGDragItem } from 'types';

import { SdgRelevance } from './SdgRelevance';
import { SelectCell } from './SelectCell';

type Props = {
  selectedItems: SDGDragItem[];
  maxItems: number;
  onSelect: (item: SDGDragItem, index: number) => void;
};

export const SdgInputSlots = ({ selectedItems, maxItems, onSelect }: Props) => {
  const slotsIndices = Array(maxItems)
    .fill(0)
    .map((_, i) => i);

  return (
    <Flex
      direction="column"
      gap={2}
      mt={24}
      w="100%"
      data-testid="sdg-input-slots"
    >
      <SimpleGrid
        w="100%"
        columns={{
          base: 4,
          lg: slotsIndices.length < 9 ? slotsIndices.length : 9,
        }}
      >
        {slotsIndices.map((i) => {
          const sdg = selectedItems.find((item) => item.orderIndex === i);

          return (
            <Box
              key={i}
              mx="auto"
              bgColor="darkBlue2"
              position="relative"
              mt={4}
            >
              <SelectCell
                item={sdg}
                onSelect={(item) => onSelect(item, i)}
                idx={Number(i + 1).toString()}
              />
            </Box>
          );
        })}
      </SimpleGrid>
      <SdgRelevance />
    </Flex>
  );
};
