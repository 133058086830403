export enum ConnectionTab {
  All = 'All',
  Family = 'Family',
  Peers = 'Peers',
  Advisor = 'Advisors',
  Recommended = 'Recommended',
  // Initiatives = 'Initiatives',
  // Events = 'Events',
  // Itriom = 'Itriom',
}
