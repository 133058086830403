import i18n from 'i18n/config';
import { string, object, date, boolean } from 'yup';

import { getPhoneFormValidation } from 'components/PhoneWithCountrySelect/validation';
import { UserFormFieldName } from 'features/Cms/types';
import { ErrorMessages, Articles } from 'utils/validation';

export const FIRST_NAME_MAX_LENGTH = 100;
export const LAST_NAME_MAX_LENGTH = 100;
export const GROUP_NAME_MAX_LENGTH = 100;

export const userSchema = object().shape({
  [UserFormFieldName.Honorific]: string().required(),
  [UserFormFieldName.FirstName]: string()
    .trim()
    .required(
      ErrorMessages.IS_REQUIRED_POLITELY(
        Articles.A,
        i18n.t('cms:user.first-name')
      )
    )
    .max(
      FIRST_NAME_MAX_LENGTH,
      ErrorMessages.TOO_LONG(
        i18n.t('cms:user.first-name'),
        FIRST_NAME_MAX_LENGTH
      )
    ),
  [UserFormFieldName.LastName]: string()
    .trim()
    .required(
      ErrorMessages.IS_REQUIRED_POLITELY(
        Articles.A,
        i18n.t('cms:user.last-name')
      )
    )
    .max(
      LAST_NAME_MAX_LENGTH,
      ErrorMessages.TOO_LONG(i18n.t('cms:user.last-name'), LAST_NAME_MAX_LENGTH)
    ),
  [UserFormFieldName.Email]: string()
    .email('Invalid email format.')
    .required(
      ErrorMessages.IS_REQUIRED_POLITELY(Articles.An, i18n.t('common:email'))
    ),
  [UserFormFieldName.FamilyId]: string().when(
    ['isHeadOfFamily', 'isFamilyAdmin'],
    {
      is: (isHeadOfFamily: boolean, isFamilyAdmin: boolean) =>
        !isHeadOfFamily || isFamilyAdmin,
      then: (schema) =>
        schema.trim().required('Please provide the Family Name.'),
    }
  ),
  [UserFormFieldName.FamilyName]: string().when('isHeadOfFamily', {
    is: (isHeadOfFamily: boolean) => isHeadOfFamily,
    then: (schema) =>
      schema
        .trim()
        .required(
          ErrorMessages.IS_REQUIRED_POLITELY(
            Articles.A,
            i18n.t('cms:family.name')
          )
        )
        .max(
          GROUP_NAME_MAX_LENGTH,
          ErrorMessages.TOO_LONG(
            i18n.t('cms:family.name'),
            GROUP_NAME_MAX_LENGTH
          )
        ),
  }),
  phone: getPhoneFormValidation(
    UserFormFieldName.PhoneCountryCode,
    UserFormFieldName.PhoneBase
  ),

  [UserFormFieldName.IsFamilyAdmin]: boolean().when('isHeadOfFamily', {
    is: (isHeadOfFamily: boolean) => isHeadOfFamily,
    then: (schema) =>
      schema.isFalse(
        "You can't be a family admin if you're the head of family."
      ),
  }),
  [UserFormFieldName.dateOfBirth]: date().required(
    ErrorMessages.IS_REQUIRED_POLITELY(
      Articles.The,
      i18n.t('cms:date-of-birth')
    )
  ),
});
