import { useMemo } from 'react';

import { AspectRatio } from '@chakra-ui/react';

import { Address } from './types';
import { generateMapUrl } from './utils';

interface Props {
  location: Address;
}

export const Map = ({ location }: Props) => {
  const mapSrc = useMemo(() => {
    const url = generateMapUrl(location);

    return url?.href;
  }, [location]);

  if (!mapSrc) return null;

  return (
    <AspectRatio ratio={16 / 9}>
      <iframe
        title={`map-${location?.city}`}
        loading="lazy"
        allowFullScreen
        referrerPolicy="no-referrer-when-downgrade"
        src={mapSrc}
      />
    </AspectRatio>
  );
};
