import { useTranslation } from 'react-i18next';

import { Logout } from '@carbon/icons-react/next';
import { Button, Flex, FlexProps } from '@chakra-ui/react';

import { useAuthContext } from 'contexts/AuthContext';
import { Links } from 'router/links';
import { Breakpoints } from 'theme';

import { HeaderNav } from './HeaderNav';
import { LogoMenuItem } from './LogoMenuItem';
import { useIsOnboarding } from './useIsOnboarding';

type Props = Pick<FlexProps, 'color' | 'bgColor'> & {
  showDivider?: boolean;
  containerStyling?: FlexProps;
  isSticky?: boolean;
};

export const Header = ({
  color = 'primary.blue.default',
  showDivider,
  containerStyling,
  isSticky = true,
}: Props): JSX.Element => {
  const isOnboarding = useIsOnboarding();
  const { logout } = useAuthContext();
  const { t } = useTranslation(['navigation']);

  return (
    <Flex
      h={16}
      as="header"
      justify="center"
      align="center"
      top={0}
      zIndex={20}
      px={4}
      bgColor={isSticky ? 'white' : 'transparent'}
      position={isSticky ? 'sticky' : 'relative'}
      {...(showDivider && {
        borderBottom: `1px solid `,
        borderColor: 'inherit',
      })}
      {...containerStyling}
    >
      <Flex
        color={color}
        gap={5}
        h="full"
        w={{
          base: Breakpoints.Mobile,
          md: Breakpoints.Tablet,
          lg: Breakpoints.Desktop,
        }}
      >
        <LogoMenuItem />
        {isOnboarding ? (
          <Button
            variant="ghost"
            gap={2}
            ml="auto"
            onClick={async () => {
              await logout(Links.onboardingLogout);
            }}
          >
            {t('navigation:sign-out')} <Logout />
          </Button>
        ) : (
          <HeaderNav />
        )}
      </Flex>
    </Flex>
  );
};
