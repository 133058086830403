import { Flex, Text, Avatar } from '@chakra-ui/react';

import { HeroBanner } from 'components/HeroBanner';
import { InsightHeroOutput, ItriomTagsV2Output } from 'types';

type Props = {
  hero: InsightHeroOutput;
  tags?: ItriomTagsV2Output;
};

export const InsightHero = ({ hero, tags }: Props) => {
  const { heroImage, firstPublishedAt, headline, author } = hero;

  return (
    <HeroBanner
      title={headline}
      src={heroImage?.signedUrl}
      tags={tags}
      calendarPosition="left"
      calendarDate={{ start: firstPublishedAt }}
      height="short"
    >
      <Flex gap={3} align="center">
        <Avatar size="sm" name={author} />
        <Text size="p2">{author}</Text>
      </Flex>
    </HeroBanner>
  );
};
