import { GroupType } from 'types';

export const AllType = 'All';

export const GROUP_TABS = [
  AllType,
  GroupType.Family,
  GroupType.Peer,
  GroupType.Advisor,
  GroupType.Initiative,
  GroupType.Event,
  GroupType.Itriom,
] as const;

export type GroupTab = (typeof GROUP_TABS)[number];
