import { gql, useQuery } from '@apollo/client';

import { Query } from 'types';
import { ValueConfiguratorNormalizedOutputDeepNestingFragment } from 'types/generated-fragments';

export const familyNormalizedValuesGql = gql`
  ${ValueConfiguratorNormalizedOutputDeepNestingFragment}
  query ValueConfiguratorFamilyNormalized {
    valueConfiguratorFamilyNormalized {
      ...ValueConfiguratorNormalizedOutputDeepNestingFragment
    }
  }
`;

export const useFamilyNormalizedValues = () => {
  const { data, error, loading } = useQuery<
    Pick<Query, 'valueConfiguratorFamilyNormalized'>
  >(familyNormalizedValuesGql);

  return {
    normalizedFamilyValues: data?.valueConfiguratorFamilyNormalized || {},
    error,
    loading,
  };
};
