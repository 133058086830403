import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { TrashCan } from '@carbon/icons-react/next';
import { useDisclosure } from '@chakra-ui/react';

import { DeleteConfirmationModal } from 'components/DeleteConfirmationModal';
import {
  MenuElement,
  ThreeDotsIconVariant,
  ThreeDotsMenu,
} from 'components/ThreeDotsMenu';
import { useAuthContext } from 'contexts/AuthContext';
import { useJoinGroup, useLeaveGroup } from 'hooks/groups';
import { useDeleteGroup } from 'hooks/groups/useDeleteGroup';
import { Links } from 'router/links';
import { GroupInternalType, Role } from 'types';
import { generateUrlById } from 'utils/links';

type Props = {
  group: GroupInternalType;
  iconVariant?: ThreeDotsIconVariant;
};

export const GroupMenu = ({ group, iconVariant = 'light' }: Props) => {
  const { t } = useTranslation(['common', 'cms']);

  const navigate = useNavigate();
  const { account } = useAuthContext();

  const { joinGroup } = useJoinGroup();
  const { leaveGroup } = useLeaveGroup();
  const { handleDeleteGroup } = useDeleteGroup();

  const confirmationModal = useDisclosure();

  const handleDelete = async (validationInput: string) => {
    await handleDeleteGroup(group.id, validationInput);
    confirmationModal.onClose();
  };

  const elements = useMemo<MenuElement[]>(() => {
    const handleLeaveGroup = () => {
      leaveGroup(group.id);
    };

    const handleJoinGroup = () => {
      joinGroup(group.id);
    };

    const handleViewGroup = () => {
      navigate(generateUrlById(Links.groupsSingle, group.id));
    };

    return [
      { text: t('common:view'), handleClick: handleViewGroup },
      group.isMember
        ? { text: t('common:leave'), handleClick: handleLeaveGroup }
        : { text: t('common:join'), handleClick: handleJoinGroup },
      {
        text: t('cms:delete.action', { entity: t('common:group') }),
        handleClick: confirmationModal.onOpen,
        icon: TrashCan,
        validator: () => {
          if (account?.role !== Role.Admin) {
            return account?.id === group.owner?.id;
          }

          return true;
        },
      },
    ];
  }, [
    t,
    group.isMember,
    group.id,
    group.owner?.id,
    confirmationModal.onOpen,
    leaveGroup,
    joinGroup,
    navigate,
    account?.role,
    account?.id,
  ]);

  const filteredMenuElements = useMemo(() => {
    return elements.filter((element) => {
      if (!element.allowedRoles) {
        if (element.validator) {
          return element.validator();
        }

        return true;
      }

      if (!account?.role) {
        return false;
      }

      const hasAllowedRole = element.allowedRoles.includes(account.role);

      return hasAllowedRole;
    });
  }, [elements, account?.role]);

  return (
    <>
      <DeleteConfirmationModal
        isOpen={confirmationModal.isOpen}
        onClose={confirmationModal.onClose}
        onConfirm={handleDelete}
        entity="group"
        name={group.name}
      />
      <ThreeDotsMenu
        elements={filteredMenuElements}
        iconVariant={iconVariant}
        id={`group-menu-${group.id}`}
      />
    </>
  );
};
