import { ValuesEssential } from 'components/ValuesConfiguratorIntro/ValuesEssential';
import { Links } from 'router/links';

import { ValuesLayout } from '../../components';

export const ValuesEssentialPage = () => {
  return (
    <ValuesLayout
      color="primary.blue.default"
      background="ui.white"
      leftButton={{ link: Links.valuesConfiguratorAbout }}
      rightButton={{ link: Links.valuesConfiguratorCategorisation }}
    >
      <ValuesEssential />
    </ValuesLayout>
  );
};
