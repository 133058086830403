import { useAuthContext } from 'contexts/AuthContext';
import { useHasConnections } from 'hooks/connections/useHasConnections';
import { useInvitationForMe } from 'hooks/connections/useInvitationForMe';

import { AcceptInvitationButton } from './AcceptInvitationButton';
import { ConnectButton } from './ConnectButton';
import { ConnectCtaProps } from './types';

export const ConnectCta = ({
  userId,
  userName,
  connectionState,
}: ConnectCtaProps) => {
  const { hasConnections } = useHasConnections();
  const { account } = useAuthContext();
  const invitationForMe = useInvitationForMe(userId);

  if (account?.id === userId || !hasConnections) {
    return null;
  }

  return invitationForMe ? (
    <AcceptInvitationButton
      invitationId={invitationForMe.id}
      userName={userName}
    />
  ) : (
    <ConnectButton
      userId={userId}
      userName={userName}
      connectionState={connectionState}
    />
  );
};
