import { ItriomTagsV2Output } from 'types';

export const flattenTags = (tags?: ItriomTagsV2Output): string[] => {
  if (!tags) {
    return [];
  }

  const sdgTagName = tags.sdGoalTag.name.split('-')[1] || tags.sdGoalTag.name;
  const sdgTag = { ...tags.sdGoalTag, name: sdgTagName };

  return [tags.countryFocusTag.name, sdgTag.name, tags.esgFocus];
};
