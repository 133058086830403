import { useQuery, gql } from '@apollo/client';

import { GroupInternalFragment } from 'gql/fragments';
import { GroupInternalType } from 'types';

export const yourGroupsGql = gql`
  ${GroupInternalFragment}
  query YourGroups {
    yourGroups {
      ...GroupInternalFragment
    }
  }
`;

export const useYourGroups = () => {
  const { data, loading, error } = useQuery<{
    yourGroups: GroupInternalType[];
  }>(yourGroupsGql);

  const yourGroups = data?.yourGroups || [];

  return { yourGroups, loading, error };
};
