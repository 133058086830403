import { ReactNode } from 'react';

import { Flex, useDisclosure } from '@chakra-ui/react';

import { ItriomButtonOutline } from 'components/Button';
import { Modal } from 'components/Modal';

import { CompletionIndicator } from './CompletionIndicator';

type Props = {
  modalContent: ReactNode;
  isCompleted: boolean;
  title: string;
  buttonText: string;
  isPreviewDisabled?: boolean;
};

export const PreviewLayout = ({
  modalContent,
  isCompleted,
  title,
  buttonText,
  isPreviewDisabled,
}: Props) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Flex justify="space-between">
      <Modal
        isOpen={isOpen && !isPreviewDisabled}
        onClose={onClose}
        title={title}
        containerStyling={{
          w: 'auto',
          maxW: 'unset',
          maxH: '90%',
          overflowY: 'scroll',
        }}
      >
        {modalContent}
      </Modal>
      <ItriomButtonOutline
        onClick={onOpen}
        isDisabled={isPreviewDisabled}
        border="2px"
        borderRadius="2px"
      >
        {buttonText}
      </ItriomButtonOutline>
      <CompletionIndicator isCompleted={isCompleted} />
    </Flex>
  );
};
