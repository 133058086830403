import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Box, Flex } from '@chakra-ui/react';

import { ItriomMenuButton } from 'components/Button';
import { ConnectionsPanel } from 'components/ConnectionsPanel';
import { MenuUserProfile } from 'components/MenuUserProfile';
import { YourAdvisors } from 'components/YourAdvisors';
import { Links } from 'router/links';

export const ForumSider = () => {
  const { t } = useTranslation(['common']);

  return (
    <Flex direction="column" gap={4}>
      <MenuUserProfile>
        <Box />

        <Link to={Links.groups}>
          <ItriomMenuButton w="full">
            {t('common:view-entity', { entity: t('common:groups') })}
          </ItriomMenuButton>
        </Link>
      </MenuUserProfile>

      <ConnectionsPanel />

      <YourAdvisors direction="column" variant="transparent" />
    </Flex>
  );
};
