import { Link } from 'react-router-dom';

import { Tab, TabList } from '@chakra-ui/react';

import { GROUP_TABS } from '../types';
import { getGroupTabLink } from '../utils';

export const GroupTabList = () => {
  return (
    <TabList w="full">
      {GROUP_TABS.map((value) => (
        <Tab key={value} as={Link} to={getGroupTabLink(value)} w="full">
          {value}
        </Tab>
      ))}
    </TabList>
  );
};
