export type Maybe<T> = T;
export type InputMaybe<T> = T | undefined | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: string;
  /** The javascript `Date` as integer. Type represents date and time as number of milliseconds from start of UNIX epoch. */
  Timestamp: number;
  /** The `Upload` scalar type represents a file upload. */
  Upload: File;
};

/** An Account respresent an Itriom user. */
export type Account = {
  __typename?: 'Account';
  avatarImage?: Maybe<MediaOutput>;
  connections: Array<Profile>;
  createdAt: Scalars['Timestamp'];
  description?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  familyId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  heroImage?: Maybe<MediaOutput>;
  honorific: Honorific;
  id: Scalars['ID'];
  isFamilyAdmin: Scalars['Boolean'];
  lastName: Scalars['String'];
  onboardingStatus: OnboardAccountStatus;
  role: Role;
  status: AccountStatus;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Timestamp'];
};

export type AccountInput = {
  description?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  honorific?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  role: Role;
  title?: InputMaybe<Scalars['String']>;
};

export enum AccountRecommendationEnum {
  MatchingAtLeastOneSdg = 'MatchingAtLeastOneSdg',
  NotMatchingAnySdg = 'NotMatchingAnySdg'
}

export enum AccountStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum ActionStatusEnum {
  Accepted = 'Accepted',
  Rejected = 'Rejected'
}

export type AdvancementValuesCategoriesOutput = {
  __typename?: 'AdvancementValuesCategoriesOutput';
  importance?: Maybe<AdvancementValuesOutput>;
  relevance?: Maybe<AdvancementValuesOutput>;
};

export type AdvancementValuesInput = {
  curiosity: Scalars['Int'];
  innovation: Scalars['Int'];
  learning: Scalars['Int'];
  progress: Scalars['Int'];
};

export type AdvancementValuesNormalizedOutput = {
  __typename?: 'AdvancementValuesNormalizedOutput';
  curiosity: Scalars['Float'];
  innovation: Scalars['Float'];
  learning: Scalars['Float'];
  progress: Scalars['Float'];
};

export type AdvancementValuesOutput = {
  __typename?: 'AdvancementValuesOutput';
  curiosity: Scalars['Int'];
  innovation: Scalars['Int'];
  learning: Scalars['Int'];
  progress: Scalars['Int'];
};

export type AdvisorAboutInput = {
  content: Scalars['String'];
  id: Scalars['ID'];
  imagesIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type AdvisorAboutOutput = {
  __typename?: 'AdvisorAboutOutput';
  content: Scalars['String'];
  images: Array<MediaOutput>;
};

export type AdvisorAdvocacyInput = {
  content: Scalars['String'];
  heading: Scalars['String'];
  heroImageId?: InputMaybe<Scalars['String']>;
  heroVideoId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  imagesIds?: InputMaybe<Array<Scalars['String']>>;
  preferredHeroMediaToDisplay?: InputMaybe<HeroMediaType>;
};

export type AdvisorAdvocacyOutput = {
  __typename?: 'AdvisorAdvocacyOutput';
  content: Scalars['String'];
  heading: Scalars['String'];
  heroImage?: Maybe<MediaOutput>;
  heroVideo?: Maybe<MediaOutput>;
  images: Array<MediaOutput>;
  preferredHeroMediaToDisplay?: Maybe<HeroMediaType>;
};

export type AdvisorHeroInput = {
  advisorAccountId: Scalars['String'];
  headline: Scalars['String'];
  heroImageId: Scalars['ID'];
  id: Scalars['ID'];
  mission: Scalars['String'];
};

export type AdvisorHeroInputWithoutId = {
  advisorAccountId: Scalars['String'];
  headline: Scalars['String'];
  heroImageId: Scalars['ID'];
  mission: Scalars['String'];
};

export type AdvisorHeroOutput = {
  __typename?: 'AdvisorHeroOutput';
  advisorAccountId: Scalars['String'];
  headline: Scalars['String'];
  heroImage: MediaOutput;
  mission: Scalars['String'];
};

export type AdvisorInput = {
  id: Scalars['ID'];
};

export type AdvisorLocationInput = {
  description: Scalars['String'];
  id: Scalars['ID'];
  imageId: Scalars['ID'];
  name: Scalars['String'];
};

export type AdvisorLocationOutput = {
  __typename?: 'AdvisorLocationOutput';
  description: Scalars['String'];
  image: MediaOutput;
  name: Scalars['String'];
};

export type AdvisorOutput = {
  __typename?: 'AdvisorOutput';
  about?: Maybe<AdvisorAboutOutput>;
  advocacy?: Maybe<AdvisorAdvocacyOutput>;
  awards?: Maybe<Scalars['String']>;
  createdAt: Scalars['Timestamp'];
  createdBy: Scalars['String'];
  firstName: Scalars['String'];
  hero: AdvisorHeroOutput;
  id: Scalars['ID'];
  lastName: Scalars['String'];
  location?: Maybe<AdvisorLocationOutput>;
  sdGoals: Array<SdgOutput>;
  status: AdvisorStatusEnum;
  tags?: Maybe<ItriomTagsOutput>;
  updatedAt: Scalars['Timestamp'];
  updatedBy: Scalars['String'];
  values: FamilyValuesOutput;
};

export enum AdvisorStatusEnum {
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
}

export type AdvisorsTagsInput = {
  countryFocusTagsIds: Array<Scalars['ID']>;
  environmentFocusTagsIds: Array<Scalars['ID']>;
  governanceFocusTagsIds: Array<Scalars['ID']>;
  id: Scalars['ID'];
  sdGoalsTagsIds: Array<Scalars['ID']>;
  socialFocusTagsIds: Array<Scalars['ID']>;
};

/** An Agenda represents the scheduled activities during an Event. */
export type Agenda = {
  __typename?: 'Agenda';
  agendaItems: Array<AgendaItem>;
  description: Scalars['String'];
  heading: Scalars['String'];
  image?: Maybe<MediaOutput>;
};

export type AgendaInput = {
  agendaItems?: InputMaybe<Array<AgendaItemInput>>;
  description: Scalars['String'];
  heading: Scalars['String'];
  id: Scalars['ID'];
  imageId?: InputMaybe<Scalars['ID']>;
};

/** An AgendaItem represents a scheduled activitiy during an Event. */
export type AgendaItem = {
  __typename?: 'AgendaItem';
  description: Scalars['String'];
  endTime: Scalars['DateTime'];
  heading: Scalars['String'];
  image?: Maybe<MediaOutput>;
  speakers: Array<SpeakerOutput>;
  startTime: Scalars['DateTime'];
};

export type AgendaItemInput = {
  description: Scalars['String'];
  endTime: Scalars['DateTime'];
  heading: Scalars['String'];
  imageId?: InputMaybe<Scalars['ID']>;
  speakers?: InputMaybe<Array<AgendaSpeaker>>;
  startTime: Scalars['DateTime'];
};

export type AgendaSpeaker = {
  id: Scalars['ID'];
  isKeySpeaker?: InputMaybe<Scalars['Boolean']>;
};

export type AppreciateInput = {
  targetId: Scalars['String'];
  type: AppreciationType;
};

/** An Appreciate is provides to Posts, Events and Comments. */
export type Appreciation = {
  __typename?: 'Appreciation';
  createdAt: Scalars['Timestamp'];
  createdBy: Scalars['String'];
  id: Scalars['ID'];
  targetId: Scalars['String'];
  type: AppreciationType;
  updatedAt: Scalars['Timestamp'];
  updatedBy: Scalars['String'];
};

export enum AppreciationType {
  Comment = 'COMMENT',
  Event = 'EVENT',
  Initiative = 'INITIATIVE',
  Post = 'POST'
}

export enum AttendanceStatusEnum {
  Attending = 'Attending',
  AttendingOnline = 'AttendingOnline',
  UnableToAttend = 'UnableToAttend'
}

export enum AvailableAccountTypes {
  Admin = 'Admin',
  FamilyMember = 'FamilyMember',
  HeadOfFamily = 'HeadOfFamily'
}

export enum AvailableNotFamilyRelatedRegistrationRoles {
  CharityManager = 'CHARITY_MANAGER',
  ExpertAdvisor = 'EXPERT_ADVISOR',
  InitiativePrincipal = 'INITIATIVE_PRINCIPAL',
  InvestmentFundManager = 'INVESTMENT_FUND_MANAGER',
  InvestmentPrincipal = 'INVESTMENT_PRINCIPAL',
  ItriomCurator = 'ITRIOM_CURATOR',
  ItriomStaff = 'ITRIOM_STAFF'
}

export enum BookingTypeEnum {
  MarketplaceDemo = 'MarketplaceDemo',
  SpeakToAdvisor = 'SpeakToAdvisor'
}

export type CallToActionInput = {
  helpRequest?: InputMaybe<HelpRequestInput>;
  id: Scalars['ID'];
  joinInfo?: InputMaybe<JoinInput>;
};

export type CallToActionOutput = {
  __typename?: 'CallToActionOutput';
  helpRequest?: Maybe<HelpRequestOutput>;
  joinInfo?: Maybe<JoinOutput>;
};

export type ChangeAccountFamilyInput = {
  accountId: Scalars['ID'];
  familyId?: InputMaybe<Scalars['String']>;
};

export type ChangeEventStatusInput = {
  id: Scalars['ID'];
  status: EventStatusEnum;
};

export type CmsProfileFiltersInput = {
  familyId?: InputMaybe<Scalars['ID']>;
  includeOnlyOwnFamily?: InputMaybe<Scalars['Boolean']>;
  onboardingStatus?: InputMaybe<Array<OnboardAccountStatus>>;
  roles?: InputMaybe<Array<Role>>;
  status?: InputMaybe<Array<AccountStatus>>;
};

/** A Comment is a text and media message that provides an updates to Groups, Events and Initiatives. */
export type Comment = {
  __typename?: 'Comment';
  appreciationCount: Scalars['Float'];
  comments: Array<Comment>;
  createdAt: Scalars['Timestamp'];
  createdBy: Scalars['String'];
  id: Scalars['ID'];
  images?: Maybe<Array<MediaOutput>>;
  link?: Maybe<Scalars['String']>;
  postedBy: Profile;
  referenceId: Scalars['String'];
  tags: Array<TagOutput>;
  text: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt: Scalars['Timestamp'];
  updatedBy: Scalars['String'];
};

export type CommentCreateInput = {
  link?: InputMaybe<Scalars['String']>;
  referenceId: Scalars['String'];
  text: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type CommentUpdateInput = {
  id: Scalars['ID'];
  link?: InputMaybe<Scalars['String']>;
  referenceId?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type ConnectionAcceptedMetadata = NotificationMetadataOutput & {
  __typename?: 'ConnectionAcceptedMetadata';
  accountId: Scalars['ID'];
};

export type ConnectionAcceptedNotificationOutput = NotificationOutput & {
  __typename?: 'ConnectionAcceptedNotificationOutput';
  category: NotificationCategoryEnum;
  createdAt: Scalars['Timestamp'];
  id: Scalars['ID'];
  isRead: Scalars['Boolean'];
  metadata: ConnectionAcceptedMetadata;
  sender?: Maybe<Profile>;
  type: NotificationTypeEnum;
};

export type ConnectionInvitation = {
  __typename?: 'ConnectionInvitation';
  createdAt: Scalars['Timestamp'];
  id: Scalars['ID'];
  recipient?: Maybe<Profile>;
  recipientId: Scalars['ID'];
  sender?: Maybe<Profile>;
  senderId: Scalars['ID'];
};

export type ConnectionRejectedMetadata = NotificationMetadataOutput & {
  __typename?: 'ConnectionRejectedMetadata';
  accountId: Scalars['ID'];
};

export type ConnectionSentMetadata = NotificationMetadataOutput & {
  __typename?: 'ConnectionSentMetadata';
  accountId: Scalars['ID'];
  actionStatus?: Maybe<ActionStatusEnum>;
  invitationId: Scalars['ID'];
};

export type ConnectionSentNotificationOutput = NotificationOutput & {
  __typename?: 'ConnectionSentNotificationOutput';
  category: NotificationCategoryEnum;
  createdAt: Scalars['Timestamp'];
  id: Scalars['ID'];
  isRead: Scalars['Boolean'];
  metadata: ConnectionSentMetadata;
  sender?: Maybe<Profile>;
  type: NotificationTypeEnum;
};

export type ContentDeletedByAdminMetadata = NotificationMetadataOutput & {
  __typename?: 'ContentDeletedByAdminMetadata';
  accountId: Scalars['ID'];
  entityName: DeletedEntityName;
};

export type ContentDeletedByAdminOutput = NotificationOutput & {
  __typename?: 'ContentDeletedByAdminOutput';
  category: NotificationCategoryEnum;
  createdAt: Scalars['Timestamp'];
  id: Scalars['ID'];
  isRead: Scalars['Boolean'];
  metadata: ContentDeletedByAdminMetadata;
  sender?: Maybe<Profile>;
  type: NotificationTypeEnum;
};

export enum ContentEntityType {
  Event = 'EVENT',
  Initiative = 'INITIATIVE',
  Insight = 'INSIGHT'
}

export type ContributionValuesCategoriesOutput = {
  __typename?: 'ContributionValuesCategoriesOutput';
  importance?: Maybe<ContributionValuesOutput>;
  relevance?: Maybe<ContributionValuesOutput>;
};

export type ContributionValuesInput = {
  community: Scalars['Int'];
  fairness: Scalars['Int'];
  inclusivity: Scalars['Int'];
  stewardship: Scalars['Int'];
};

export type ContributionValuesNormalizedOutput = {
  __typename?: 'ContributionValuesNormalizedOutput';
  community: Scalars['Float'];
  fairness: Scalars['Float'];
  inclusivity: Scalars['Float'];
  stewardship: Scalars['Float'];
};

export type ContributionValuesOutput = {
  __typename?: 'ContributionValuesOutput';
  community: Scalars['Int'];
  fairness: Scalars['Int'];
  inclusivity: Scalars['Int'];
  stewardship: Scalars['Int'];
};

/** A Conversation is a collection of Itriom users that can communicate with each other. */
export type Conversation = {
  __typename?: 'Conversation';
  createdAt: Scalars['Timestamp'];
  createdBy: Scalars['String'];
  id: Scalars['ID'];
  isMember: Scalars['Boolean'];
  members: Array<Profile>;
  messages: Array<Message>;
  updatedAt: Scalars['Timestamp'];
  updatedBy: Scalars['String'];
};

export type CreateAccountByAdminInput = {
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  familyId?: InputMaybe<Scalars['String']>;
  familyName?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  honorific: Honorific;
  lastName: Scalars['String'];
  phone: PhoneInput;
  type: AvailableAccountTypes;
};

export type CreateAdvisorAccountInput = {
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  honorific: Honorific;
  lastName: Scalars['String'];
  phone: PhoneInput;
};

export type CreateAdvisorInput = {
  hero: AdvisorHeroInputWithoutId;
};

export type CreateEventInput = {
  hero: EventHeroInputWithoutId;
};

export type CreateFamilyCharterInput = {
  hero: FamilyCharterHero;
};

export type CreateGlobalTagInput = {
  name: Scalars['String'];
  order: Scalars['Int'];
  type: TagType;
};

export type CreateInitiativeInput = {
  hero: InitiativeHeroWithoutIdInput;
};

export type CreateInsightInput = {
  hero: InsightHeroWithoutIdInput;
};

export type CreateNotFamilyRelatedAccountInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  honorific: Honorific;
  lastName: Scalars['String'];
  phone: PhoneInput;
  role: AvailableNotFamilyRelatedRegistrationRoles;
};

export type CreatePrincipalInput = {
  hero: PrincipalHeroWithoutIdInput;
};

export type CreateRawAccountInput = {
  email: Scalars['String'];
  familyId?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  honorific: Honorific;
  lastName: Scalars['String'];
  password: Scalars['String'];
  phone: PhoneInput;
  role: Role;
};

export enum DeletedEntityName {
  Comment = 'COMMENT',
  Group = 'GROUP',
  Post = 'POST'
}

export type EnableCommentsInput = {
  id: Scalars['ID'];
  isCommentsEnabled: Scalars['Boolean'];
};

/** An ESG Category is either Environment, Society or Governance. */
export type EsgCategory = {
  __typename?: 'EsgCategory';
  createdAt: Scalars['Timestamp'];
  description: Scalars['String'];
  iconId: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['Timestamp'];
};

export enum EsgCategoryEnum {
  Environmental = 'Environmental',
  Governance = 'Governance',
  Social = 'Social'
}

export type EsgCategoryOutputInput = {
  description: Scalars['String'];
  iconId: Scalars['String'];
  name: Scalars['String'];
};

/** An Event is something that is scheduled and can be anything from a simple call to a conference. */
export type Event = {
  __typename?: 'Event';
  agenda?: Maybe<Agenda>;
  appreciationCount: Scalars['Float'];
  basicInfo?: Maybe<EventBasicInfo>;
  callToAction?: Maybe<CallToActionOutput>;
  createdAt: Scalars['Timestamp'];
  createdBy?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  endTime?: Maybe<TimezoneDateOutput>;
  hero: EventHero;
  id: Scalars['ID'];
  isAttending: Scalars['Boolean'];
  isCommentsEnabled: Scalars['Boolean'];
  keySpeaker?: Maybe<EventKeySpeakerOutput>;
  location?: Maybe<Location>;
  mainTopicDescription?: Maybe<Scalars['String']>;
  objectives?: Maybe<Array<EventObjective>>;
  organisers: Array<Organiser>;
  preferredHeroMediaToDisplay?: Maybe<HeroMediaType>;
  sponsors: Array<Sponsor>;
  startTime?: Maybe<TimezoneDateOutput>;
  status: EventStatusEnum;
  tags?: Maybe<ItriomTagsV2Output>;
  topics: Array<Topic>;
  ultimateGoal?: Maybe<UltimateGoalOutput>;
  updatedAt: Scalars['Timestamp'];
  updatedBy?: Maybe<Scalars['String']>;
  visibilityDetails?: Maybe<EventVisibilityOutput>;
};

export type EventBasicInfo = {
  __typename?: 'EventBasicInfo';
  endTime?: Maybe<TimezoneDateOutput>;
  isVirtual: Scalars['Boolean'];
  location?: Maybe<Location>;
  startTime?: Maybe<TimezoneDateOutput>;
};

export type EventBasicInfoInput = {
  endTime: TimezoneDateInput;
  id: Scalars['ID'];
  isVirtual: Scalars['Boolean'];
  location?: InputMaybe<LocationBasicDataInput>;
  startTime: TimezoneDateInput;
};

export type EventFilters = {
  createdBy?: InputMaybe<Array<Scalars['String']>>;
  from?: InputMaybe<Scalars['Timestamp']>;
  statuses?: InputMaybe<Array<EventStatusEnum>>;
  tags?: InputMaybe<ItriomTagsFilter>;
  to?: InputMaybe<Scalars['Timestamp']>;
  visibility?: InputMaybe<EventVisibilityFiltersInput>;
};

export type EventHero = {
  __typename?: 'EventHero';
  description?: Maybe<Scalars['String']>;
  eventUrl?: Maybe<Scalars['String']>;
  heroImage?: Maybe<MediaOutput>;
  heroVideo?: Maybe<MediaOutput>;
  name: Scalars['String'];
  preferredHeroMediaToDisplay: HeroMediaType;
  subtitle: Scalars['String'];
  summary: Scalars['String'];
};

export type EventHeroInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  preferredHeroMediaToDisplay: HeroMediaType;
  subtitle: Scalars['String'];
  summary: Scalars['String'];
};

export type EventHeroInputWithoutId = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  preferredHeroMediaToDisplay: HeroMediaType;
  subtitle: Scalars['String'];
  summary: Scalars['String'];
};

export type EventKeySpeakerOutput = {
  __typename?: 'EventKeySpeakerOutput';
  about: Scalars['String'];
  image: MediaOutput;
  keynoteSpeakerHeading?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  title: Scalars['String'];
};

/** An EventObjective represents the purpose of the Event. */
export type EventObjective = {
  __typename?: 'EventObjective';
  description: Scalars['String'];
  heading: Scalars['String'];
};

export type EventObjectiveInput = {
  description: Scalars['String'];
  heading: Scalars['String'];
};

export type EventObjectivesUpdateInput = {
  id: Scalars['ID'];
  objectives: Array<EventObjectiveInput>;
};

export type EventPaginatedOutput = {
  __typename?: 'EventPaginatedOutput';
  nodes: Array<Event>;
  offset: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export enum EventStatusEnum {
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
}

export type EventTagsInput = {
  countryFocusTagId: Scalars['String'];
  esgFocus: EsgCategoryEnum;
  id: Scalars['ID'];
  sdGoalTagId: Scalars['String'];
};

export type EventVisibilityFiltersInput = {
  connections?: InputMaybe<Scalars['Boolean']>;
  external?: InputMaybe<Scalars['Boolean']>;
  family?: InputMaybe<Scalars['Boolean']>;
  familyOwnersIds?: InputMaybe<Array<Scalars['String']>>;
  familyOwnersIdsToExclude?: InputMaybe<Array<Scalars['String']>>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  itriom?: InputMaybe<Scalars['Boolean']>;
  userHasAccessTo?: InputMaybe<Scalars['Boolean']>;
};

export type EventVisibilityInput = {
  connections?: InputMaybe<Scalars['Boolean']>;
  externalUrl?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['Boolean']>;
  familyOwnersIds?: InputMaybe<Array<Scalars['ID']>>;
  id: Scalars['ID'];
  isPublic?: InputMaybe<Scalars['Boolean']>;
  itriom?: InputMaybe<Scalars['Boolean']>;
};

export type EventVisibilityOutput = {
  __typename?: 'EventVisibilityOutput';
  connections: Scalars['Boolean'];
  externalUrl?: Maybe<Scalars['String']>;
  family: Scalars['Boolean'];
  familyOwnersIds: Array<Scalars['ID']>;
  isPublic: Scalars['Boolean'];
  itriom: Scalars['Boolean'];
  joint: Scalars['Boolean'];
};

export type EventsPublishedPaginatedFiltersInput = {
  /** Property checks if startTime of event is greater or equal the value */
  fromDate?: InputMaybe<Scalars['DateTime']>;
  sortByEndDateDescending?: InputMaybe<Scalars['Boolean']>;
  sortByStartDateAscending?: InputMaybe<Scalars['Boolean']>;
  /** Property checks if endTime of event is lower or equal the value */
  toDate?: InputMaybe<Scalars['DateTime']>;
  visibilityFilters?: InputMaybe<EventVisibilityFiltersInput>;
};

export type ExtendedProfileOutput = ProfileInterfaceOutput & {
  __typename?: 'ExtendedProfileOutput';
  avatarImage?: Maybe<MediaOutput>;
  canBeDeleted: Scalars['Boolean'];
  canBeEdited: Scalars['Boolean'];
  canBeInvited: Scalars['Boolean'];
  connectionState: ProfileConnectionStateEnum;
  createdAt: Scalars['Timestamp'];
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  family?: Maybe<Family>;
  firstName: Scalars['String'];
  heroImage?: Maybe<MediaOutput>;
  honorific?: Maybe<Honorific>;
  id: Scalars['ID'];
  /** @deprecated Not used */
  isConnected: Scalars['Boolean'];
  isFamilyAdmin: Scalars['Boolean'];
  isHeadOfFamily: Scalars['Boolean'];
  lastName: Scalars['String'];
  phone: Phone;
  principal?: Maybe<PrincipalOutput>;
  role: Role;
  status: AccountStatus;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Timestamp'];
};

/** A Family is a group of related users representing a family of UHNW individuals. */
export type Family = {
  __typename?: 'Family';
  admins: Array<Profile>;
  advisors: Array<Profile>;
  createdAt: Scalars['Timestamp'];
  familyCharter?: Maybe<FamilyCharter>;
  goals: Array<Goal>;
  heroImage?: Maybe<MediaOutput>;
  heroVideo?: Maybe<MediaOutput>;
  id: Scalars['ID'];
  influences: Array<SdgOutput>;
  members: Array<Profile>;
  name: Scalars['String'];
  purpose: Scalars['String'];
  sdGoals: Array<SdgOutput>;
  status: FamilyStatusEnum;
  updatedAt: Scalars['Timestamp'];
  values: FamilyValuesOutput;
};

/** A Family charter is a family page */
export type FamilyCharter = {
  __typename?: 'FamilyCharter';
  createdAt: Scalars['Timestamp'];
  createdBy: Scalars['String'];
  goals: Array<FamilyCharterGoalOutput>;
  hero: FamilyCharterHeroOutput;
  id: Scalars['ID'];
  status: FamilyCharterStatusEnum;
  updatedAt: Scalars['Timestamp'];
  updatedBy: Scalars['String'];
  /** @deprecated Please use values from family */
  values?: Maybe<FamilyCharterValuesOutput>;
};

export type FamilyCharterGoalInitiativeInput = {
  id: Scalars['ID'];
  initiativeId: Scalars['String'];
};

export type FamilyCharterGoalInitiativeOutput = {
  __typename?: 'FamilyCharterGoalInitiativeOutput';
  id: Scalars['String'];
  initiative: InitiativeOutput;
  initiativeId: Scalars['String'];
};

export type FamilyCharterGoalInput = {
  description: Scalars['String'];
  id: Scalars['ID'];
  initiatives: Array<FamilyCharterGoalInitiativeInput>;
  objectives: Array<FamilyCharterGoalObjectiveInput>;
  sdgId: Scalars['String'];
};

export type FamilyCharterGoalObjectiveInput = {
  description: Scalars['String'];
  id: Scalars['ID'];
  label: Scalars['String'];
};

export type FamilyCharterGoalObjectiveOutput = {
  __typename?: 'FamilyCharterGoalObjectiveOutput';
  description: Scalars['String'];
  id: Scalars['String'];
  label: Scalars['String'];
};

export type FamilyCharterGoalOutput = {
  __typename?: 'FamilyCharterGoalOutput';
  description: Scalars['String'];
  id: Scalars['String'];
  initiatives: Array<FamilyCharterGoalInitiativeOutput>;
  objectives: Array<FamilyCharterGoalObjectiveOutput>;
  sdg: SdgOutput;
  sdgId: Scalars['String'];
};

export type FamilyCharterHero = {
  familyId: Scalars['ID'];
  heading: Scalars['String'];
  heroImageId?: InputMaybe<Scalars['ID']>;
  heroVideoId?: InputMaybe<Scalars['ID']>;
  preferredHeroMediaToDisplay: HeroMediaType;
  purpose: Scalars['String'];
};

export type FamilyCharterHeroOutput = {
  __typename?: 'FamilyCharterHeroOutput';
  familyId: Scalars['String'];
  heading: Scalars['String'];
  heroImage?: Maybe<MediaOutput>;
  heroVideo?: Maybe<MediaOutput>;
  preferredHeroMediaToDisplay: HeroMediaType;
  purpose: Scalars['String'];
};

export enum FamilyCharterStatusEnum {
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
}

export type FamilyCharterValueOutput = {
  __typename?: 'FamilyCharterValueOutput';
  createdAt: Scalars['Timestamp'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['Timestamp'];
};

export type FamilyCharterValuesOutput = {
  __typename?: 'FamilyCharterValuesOutput';
  advancementValue?: Maybe<FamilyCharterValueOutput>;
  contributionValue?: Maybe<FamilyCharterValueOutput>;
  organisationalValue?: Maybe<FamilyCharterValueOutput>;
  societalValue?: Maybe<FamilyCharterValueOutput>;
};

export type FamilyMemberRelationOutput = {
  __typename?: 'FamilyMemberRelationOutput';
  familyMemberId: Scalars['ID'];
  memberAccount: Profile;
  relatedFamilyMemberId: Scalars['ID'];
  relatedMemberAccount: Profile;
  relationType?: Maybe<FamilyRelationTypeEnum>;
};

export type FamilyOnboardMemberActionsOutput = {
  __typename?: 'FamilyOnboardMemberActionsOutput';
  oneDayBeforeWorkshopReminderEmailState: OnboardMemberActionEnum;
  oneHourBeforeWorkshopReminderEmailState: OnboardMemberActionEnum;
  rsvp2ReminderEmailState: OnboardMemberActionEnum;
  tenDaysBeforeWorkshopReminderEmailState: OnboardMemberActionEnum;
  threeDaysBeforeEventReminderEmailState: OnboardMemberActionEnum;
  twoDaysBeforeWorkshopReminderEmailState: OnboardMemberActionEnum;
  workshopAbsenceConfirmedEmailState: OnboardMemberActionEnum;
  workshopAttendanceConfirmedEmailState: OnboardMemberActionEnum;
};

export type FamilyOnboardMemberDetailsOutput = {
  __typename?: 'FamilyOnboardMemberDetailsOutput';
  accountId: Scalars['String'];
  actions: FamilyOnboardMemberActionsOutput;
  attendanceStatus?: Maybe<AttendanceStatusEnum>;
  firstName: Scalars['String'];
  isHeadOfFamily: Scalars['Boolean'];
  lastName: Scalars['String'];
  sdgConfiguratorStatus: OnboardManagementSdgConfiguratorStatusEnum;
  valueConfiguratorStatus: OnboardManagementValueConfiguratorStatusEnum;
};

export enum FamilyOnboardStateEnum {
  Initialized = 'Initialized',
  OnboardCompleted = 'OnboardCompleted',
  SdgConfiguratorCompleted = 'SdgConfiguratorCompleted',
  ValueConfiguratorCompleted = 'ValueConfiguratorCompleted'
}

export enum FamilyRelationTypeEnum {
  Aunt = 'AUNT',
  Brother = 'BROTHER',
  BrotherInLaw = 'BROTHER_IN_LAW',
  Cousin = 'COUSIN',
  Daughter = 'DAUGHTER',
  DaughterInLaw = 'DAUGHTER_IN_LAW',
  FamilyAdmin = 'FAMILY_ADMIN',
  FamilyFinancialAdvisor = 'FAMILY_FINANCIAL_ADVISOR',
  FamilyLegalAdvisor = 'FAMILY_LEGAL_ADVISOR',
  FatherInLaw = 'FATHER_IN_LAW',
  Granddaughter = 'GRANDDAUGHTER',
  Grandfather = 'GRANDFATHER',
  Grandmother = 'GRANDMOTHER',
  Grandson = 'GRANDSON',
  Husband = 'HUSBAND',
  MotherInLaw = 'MOTHER_IN_LAW',
  Nephew = 'NEPHEW',
  Niece = 'NIECE',
  PersonalAssistant = 'PERSONAL_ASSISTANT',
  RelationNotAcknowledged = 'RELATION_NOT_ACKNOWLEDGED',
  Sister = 'SISTER',
  SisterInLaw = 'SISTER_IN_LAW',
  Son = 'SON',
  SonInLaw = 'SON_IN_LAW',
  Uncle = 'UNCLE',
  Wife = 'WIFE'
}

export type FamilySdgConfiguratorOutput = {
  __typename?: 'FamilySdgConfiguratorOutput';
  sdgIdsWithNumericScores: Array<SdgIdWithNumericScoreOutput>;
};

export enum FamilyStatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type FamilyValueOutput = {
  __typename?: 'FamilyValueOutput';
  category: FamilyValuesCategoryEnum;
  createdAt: Scalars['Timestamp'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['Timestamp'];
};

export enum FamilyValuesCategoryEnum {
  Advancement = 'Advancement',
  Contribution = 'Contribution',
  Organisational = 'Organisational',
  Societal = 'Societal'
}

export type FamilyValuesOutput = {
  __typename?: 'FamilyValuesOutput';
  advancementValue?: Maybe<FamilyValueOutput>;
  contributionValue?: Maybe<FamilyValueOutput>;
  organisationalValue?: Maybe<FamilyValueOutput>;
  societalValue?: Maybe<FamilyValueOutput>;
};

export type FamilyValuesWithAvailableSdgOutput = {
  __typename?: 'FamilyValuesWithAvailableSdgOutput';
  category: FamilyValuesCategoryEnum;
  createdAt: Scalars['Timestamp'];
  id: Scalars['ID'];
  name: Scalars['String'];
  sdg: Array<SdgOutput>;
  updatedAt: Scalars['Timestamp'];
};

export type FindGroupMembersInput = {
  groupId: Scalars['String'];
  memberName?: InputMaybe<Scalars['String']>;
};

export enum FollowedInitiativesTreatment {
  ExcludeFollowed = 'EXCLUDE_FOLLOWED',
  LimitToFollowed = 'LIMIT_TO_FOLLOWED'
}

/** A Goal represents an aim that the Family wishes to achieve. */
export type Goal = {
  __typename?: 'Goal';
  createdAt: Scalars['Timestamp'];
  description: Scalars['String'];
  id: Scalars['ID'];
  image?: Maybe<MediaOutput>;
  name: Scalars['String'];
  number: Scalars['Int'];
  objectives?: Maybe<Array<Objective>>;
  sdg?: Maybe<Sdg>;
  shortDescription: Scalars['String'];
  updatedAt: Scalars['Timestamp'];
};

/** A Group is a collection of Itriom users that can be associated with various other entities. */
export type Group = {
  __typename?: 'Group';
  admins: Array<Profile>;
  advisors: Array<Profile>;
  allowInvites: Scalars['Boolean'];
  automaticallyAddFamilyMembers?: Maybe<Scalars['Boolean']>;
  avatarImage?: Maybe<MediaOutput>;
  canBeModified: Scalars['Boolean'];
  canDeleteMembers: Scalars['Boolean'];
  canMakeAdmins: Scalars['Boolean'];
  category: Scalars['String'];
  createdAt: Scalars['Timestamp'];
  createdBy: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  familyId?: Maybe<Scalars['String']>;
  familyOnly: Scalars['Boolean'];
  heroImage?: Maybe<MediaOutput>;
  id: Scalars['ID'];
  isAdmin: Scalars['Boolean'];
  isInvitationPending: Scalars['Boolean'];
  isMember: Scalars['Boolean'];
  members: Array<Profile>;
  name: Scalars['String'];
  owner: Profile;
  posts: Array<Post>;
  sdg: Sdg;
  type?: Maybe<GroupType>;
  updatedAt: Scalars['Timestamp'];
  updatedBy: Scalars['String'];
};


/** A Group is a collection of Itriom users that can be associated with various other entities. */
export type GroupPostsArgs = {
  pagination: PaginationInput;
};

export type GroupCreateInput = {
  allowInvites?: InputMaybe<Scalars['Boolean']>;
  automaticallyAddFamilyMembers?: InputMaybe<Scalars['Boolean']>;
  category: Scalars['String'];
  description: Scalars['String'];
  familyId?: InputMaybe<Scalars['String']>;
  familyOnly?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  sdgId: Scalars['String'];
  /** Not passing a value here means that you want to unset group type */
  type?: InputMaybe<GroupType>;
};

export type GroupData = {
  __typename?: 'GroupData';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type GroupInvitationSentMetadata = NotificationMetadataOutput & {
  __typename?: 'GroupInvitationSentMetadata';
  accountId: Scalars['ID'];
  actionStatus?: Maybe<ActionStatusEnum>;
  groupId: Scalars['ID'];
  invitationId: Scalars['ID'];
};

export type GroupInvitationSentNotificationOutput = NotificationOutput & {
  __typename?: 'GroupInvitationSentNotificationOutput';
  category: NotificationCategoryEnum;
  createdAt: Scalars['Timestamp'];
  id: Scalars['ID'];
  isRead: Scalars['Boolean'];
  metadata: GroupInvitationSentMetadata;
  sender?: Maybe<Profile>;
  type: NotificationTypeEnum;
};

export type GroupNotificationMetadata = NotificationMetadataOutput & {
  __typename?: 'GroupNotificationMetadata';
  accountId: Scalars['ID'];
  group: GroupData;
};

export type GroupNotificationOutput = NotificationOutput & {
  __typename?: 'GroupNotificationOutput';
  category: NotificationCategoryEnum;
  createdAt: Scalars['Timestamp'];
  id: Scalars['ID'];
  isRead: Scalars['Boolean'];
  metadata: GroupNotificationMetadata;
  sender?: Maybe<Profile>;
  type: NotificationTypeEnum;
};

export enum GroupType {
  Advisor = 'Advisor',
  Event = 'Event',
  Family = 'Family',
  Initiative = 'Initiative',
  Itriom = 'Itriom',
  Peer = 'Peer'
}

export type GroupUpdateInput = {
  adminId?: InputMaybe<Scalars['String']>;
  advisorIds?: InputMaybe<Array<Scalars['String']>>;
  allowInvites?: InputMaybe<Scalars['Boolean']>;
  automaticallyAddFamilyMembers?: InputMaybe<Scalars['Boolean']>;
  category: Scalars['String'];
  description: Scalars['String'];
  familyId?: InputMaybe<Scalars['String']>;
  familyOnly?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  sdgId: Scalars['String'];
  /** Not passing a value here means that you want to unset group type */
  type?: InputMaybe<GroupType>;
};

export type HelpRequestInput = {
  description?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  imageId?: InputMaybe<Scalars['ID']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type HelpRequestOutput = {
  __typename?: 'HelpRequestOutput';
  description?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  image?: Maybe<MediaOutput>;
  isEnabled: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
};

export enum HeroMediaType {
  Image = 'IMAGE',
  Video = 'VIDEO'
}

export enum Honorific {
  Baron = 'BARON',
  Baroness = 'BARONESS',
  Dame = 'DAME',
  Dr = 'DR',
  Earl = 'EARL',
  Lady = 'LADY',
  Lord = 'LORD',
  Miss = 'MISS',
  Mr = 'MR',
  Mrs = 'MRS',
  Ms = 'MS',
  Mx = 'MX',
  Prof = 'PROF',
  Sheikh = 'SHEIKH',
  Sir = 'SIR',
  Sultan = 'SULTAN'
}

export type InitiativeBlockOutput = {
  order: Scalars['Float'];
  type: InitiativeBodyType;
};

export type InitiativeBlockUnionOutput = InitiativeFullMediaBlockOutput | InitiativeGalleryBlockOutput | InitiativeHeadingBlockOutput | InitiativeLeftTextBlockOutput | InitiativeRichTextBlockOutput | InitiativeRightTextBlockOutput;

export enum InitiativeBodyType {
  FullMedia = 'FULL_MEDIA',
  Gallery = 'GALLERY',
  Heading = 'HEADING',
  LeftText = 'LEFT_TEXT',
  RichText = 'RICH_TEXT',
  RightText = 'RIGHT_TEXT'
}

export type InitiativeFullMediaBlockInput = {
  image: Scalars['ID'];
  order: Scalars['Float'];
};

export type InitiativeFullMediaBlockOutput = InitiativeBlockOutput & {
  __typename?: 'InitiativeFullMediaBlockOutput';
  image: MediaOutput;
  order: Scalars['Float'];
  type: InitiativeBodyType;
};

export type InitiativeGalleryBlockInput = {
  images: Array<Scalars['ID']>;
  order: Scalars['Float'];
};

export type InitiativeGalleryBlockOutput = InitiativeBlockOutput & {
  __typename?: 'InitiativeGalleryBlockOutput';
  images: Array<MediaOutput>;
  order: Scalars['Float'];
  type: InitiativeBodyType;
};

export type InitiativeHeadingBlockInput = {
  heading: Scalars['String'];
  order: Scalars['Float'];
};

export type InitiativeHeadingBlockOutput = InitiativeBlockOutput & {
  __typename?: 'InitiativeHeadingBlockOutput';
  heading: Scalars['String'];
  order: Scalars['Float'];
  type: InitiativeBodyType;
};

export type InitiativeHeroInput = {
  headline: Scalars['String'];
  heroImageId: Scalars['String'];
  id: Scalars['ID'];
  leftChartHeadline?: InputMaybe<Scalars['String']>;
  leftChartImageId: Scalars['String'];
  leftChartSubheadline?: InputMaybe<Scalars['String']>;
  locationImageId: Scalars['String'];
  rightChartHeadline?: InputMaybe<Scalars['String']>;
  rightChartImageId: Scalars['String'];
  rightChartSubheadline?: InputMaybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  summary: Scalars['String'];
};

export type InitiativeHeroOutput = {
  __typename?: 'InitiativeHeroOutput';
  headline: Scalars['String'];
  heroImage: MediaOutput;
  leftChartHeadline?: Maybe<Scalars['String']>;
  leftChartImage: MediaOutput;
  leftChartSubheadline?: Maybe<Scalars['String']>;
  locationImage: MediaOutput;
  rightChartHeadline?: Maybe<Scalars['String']>;
  rightChartImage: MediaOutput;
  rightChartSubheadline?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  summary: Scalars['String'];
};

export type InitiativeHeroWithoutIdInput = {
  headline: Scalars['String'];
  heroImageId: Scalars['String'];
  leftChartHeadline?: InputMaybe<Scalars['String']>;
  leftChartImageId: Scalars['String'];
  leftChartSubheadline?: InputMaybe<Scalars['String']>;
  locationImageId: Scalars['String'];
  rightChartHeadline?: InputMaybe<Scalars['String']>;
  rightChartImageId: Scalars['String'];
  rightChartSubheadline?: InputMaybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  summary: Scalars['String'];
};

export type InitiativeLeftTextBlockInput = {
  image: Scalars['ID'];
  optionalHeading?: InputMaybe<Scalars['String']>;
  order: Scalars['Float'];
  text: Scalars['String'];
};

export type InitiativeLeftTextBlockOutput = InitiativeBlockOutput & {
  __typename?: 'InitiativeLeftTextBlockOutput';
  image: MediaOutput;
  optionalHeading?: Maybe<Scalars['String']>;
  order: Scalars['Float'];
  text: Scalars['String'];
  type: InitiativeBodyType;
};

export type InitiativeNotificationMetadata = NotificationMetadataOutput & {
  __typename?: 'InitiativeNotificationMetadata';
  accountId: Scalars['ID'];
  initiativeId: Scalars['ID'];
};

export type InitiativeNotificationOutput = NotificationOutput & {
  __typename?: 'InitiativeNotificationOutput';
  category: NotificationCategoryEnum;
  createdAt: Scalars['Timestamp'];
  id: Scalars['ID'];
  isRead: Scalars['Boolean'];
  metadata: InitiativeNotificationMetadata;
  sender?: Maybe<Profile>;
  type: NotificationTypeEnum;
};

export type InitiativeOutput = {
  __typename?: 'InitiativeOutput';
  appreciationCount: Scalars['Float'];
  blocks: Array<InitiativeBlockUnionOutput>;
  createdAt: Scalars['Timestamp'];
  createdBy: Scalars['String'];
  hero: InitiativeHeroOutput;
  id: Scalars['ID'];
  isFollowed: Scalars['Boolean'];
  relatedArticles: Array<Insight>;
  status: InitiativeStatusEnum;
  tags?: Maybe<ItriomTagsV2Output>;
  updatedAt: Scalars['Timestamp'];
  updatedBy: Scalars['String'];
  visibilityDetails?: Maybe<InitiativeVisibilityOutput>;
};

export type InitiativeRichTextBlockInput = {
  optionalHeading?: InputMaybe<Scalars['String']>;
  order: Scalars['Float'];
  text: Scalars['String'];
};

export type InitiativeRichTextBlockOutput = InitiativeBlockOutput & {
  __typename?: 'InitiativeRichTextBlockOutput';
  optionalHeading?: Maybe<Scalars['String']>;
  order: Scalars['Float'];
  text: Scalars['String'];
  type: InitiativeBodyType;
};

export type InitiativeRightTextBlockInput = {
  image: Scalars['ID'];
  optionalHeading?: InputMaybe<Scalars['String']>;
  order: Scalars['Float'];
  text: Scalars['String'];
};

export type InitiativeRightTextBlockOutput = InitiativeBlockOutput & {
  __typename?: 'InitiativeRightTextBlockOutput';
  image: MediaOutput;
  optionalHeading?: Maybe<Scalars['String']>;
  order: Scalars['Float'];
  text: Scalars['String'];
  type: InitiativeBodyType;
};

export enum InitiativeStatusEnum {
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
}

export type InitiativeTagsInput = {
  countryFocusTagId: Scalars['String'];
  esgFocus: EsgCategoryEnum;
  id: Scalars['ID'];
  sdGoalTagId: Scalars['String'];
};

export type InitiativeVisibilityInput = {
  connections?: InputMaybe<Scalars['Boolean']>;
  family?: InputMaybe<Scalars['Boolean']>;
  familyOwnersIds?: InputMaybe<Array<Scalars['ID']>>;
  featured?: InputMaybe<Scalars['Boolean']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  itriom?: InputMaybe<Scalars['Boolean']>;
};

export type InitiativeVisibilityOutput = {
  __typename?: 'InitiativeVisibilityOutput';
  connections: Scalars['Boolean'];
  family: Scalars['Boolean'];
  familyOwnersIds: Array<Scalars['ID']>;
  featured: Scalars['Boolean'];
  isPublic: Scalars['Boolean'];
  itriom: Scalars['Boolean'];
  joint: Scalars['Boolean'];
};

/** An Insight is curated content that is relevant to the user. */
export type Insight = {
  __typename?: 'Insight';
  blocks: Array<InsightBlockUnionOutput>;
  createdAt: Scalars['Timestamp'];
  createdBy: Scalars['String'];
  hero: InsightHeroOutput;
  id: Scalars['ID'];
  relatedArticles: Array<Insight>;
  status: InsightStatusEnum;
  tags?: Maybe<ItriomTagsV2Output>;
  updatedAt: Scalars['Timestamp'];
  updatedBy: Scalars['String'];
  visibilityDetails?: Maybe<InsightVisibilityOutput>;
  writtenBy?: Maybe<Profile>;
};

export type InsightBlockOutput = {
  order: Scalars['Float'];
  type: InsightBlockType;
};

export enum InsightBlockType {
  FullMedia = 'FULL_MEDIA',
  Gallery = 'GALLERY',
  Heading = 'HEADING',
  LeftText = 'LEFT_TEXT',
  Quote = 'QUOTE',
  RichText = 'RICH_TEXT',
  RightText = 'RIGHT_TEXT'
}

export type InsightBlockUnionOutput = InsightFullMediaBlockOutput | InsightGalleryBlockOutput | InsightHeadingBlockOutput | InsightLeftTextBlockOutput | InsightQuoteBlockOutput | InsightRichTextBlockOutput | InsightRightTextBlockOutput;

export type InsightFullMediaBlockInput = {
  image: Scalars['ID'];
  order: Scalars['Float'];
};

export type InsightFullMediaBlockOutput = InsightBlockOutput & {
  __typename?: 'InsightFullMediaBlockOutput';
  image: MediaOutput;
  order: Scalars['Float'];
  type: InsightBlockType;
};

export type InsightGalleryBlockInput = {
  images: Array<Scalars['ID']>;
  order: Scalars['Float'];
};

export type InsightGalleryBlockOutput = InsightBlockOutput & {
  __typename?: 'InsightGalleryBlockOutput';
  images: Array<MediaOutput>;
  order: Scalars['Float'];
  type: InsightBlockType;
};

export type InsightHeadingBlockInput = {
  heading: Scalars['String'];
  order: Scalars['Float'];
};

export type InsightHeadingBlockOutput = InsightBlockOutput & {
  __typename?: 'InsightHeadingBlockOutput';
  heading: Scalars['String'];
  order: Scalars['Float'];
  type: InsightBlockType;
};

export type InsightHeroInput = {
  author?: InputMaybe<Scalars['String']>;
  firstPublishedAt: Scalars['DateTime'];
  headline: Scalars['String'];
  heroImageId: Scalars['String'];
  id: Scalars['ID'];
  sourceUrl?: InputMaybe<Scalars['String']>;
  summary: Scalars['String'];
};

export type InsightHeroOutput = {
  __typename?: 'InsightHeroOutput';
  author?: Maybe<Scalars['String']>;
  firstPublishedAt: Scalars['DateTime'];
  headline: Scalars['String'];
  heroImage: MediaOutput;
  sourceUrl?: Maybe<Scalars['String']>;
  summary: Scalars['String'];
};

export type InsightHeroWithoutIdInput = {
  author?: InputMaybe<Scalars['String']>;
  firstPublishedAt: Scalars['DateTime'];
  headline: Scalars['String'];
  heroImageId: Scalars['String'];
  sourceUrl?: InputMaybe<Scalars['String']>;
  summary: Scalars['String'];
};

export type InsightLeftTextBlockInput = {
  image: Scalars['ID'];
  optionalHeading?: InputMaybe<Scalars['String']>;
  order: Scalars['Float'];
  text: Scalars['String'];
};

export type InsightLeftTextBlockOutput = InsightBlockOutput & {
  __typename?: 'InsightLeftTextBlockOutput';
  image: MediaOutput;
  optionalHeading?: Maybe<Scalars['String']>;
  order: Scalars['Float'];
  text: Scalars['String'];
  type: InsightBlockType;
};

export type InsightQuoteBlockInput = {
  accountId?: InputMaybe<Scalars['ID']>;
  customName?: InputMaybe<Scalars['String']>;
  customTitle?: InputMaybe<Scalars['String']>;
  order: Scalars['Float'];
  text: Scalars['String'];
};

export type InsightQuoteBlockOutput = InsightBlockOutput & {
  __typename?: 'InsightQuoteBlockOutput';
  account?: Maybe<Profile>;
  customName?: Maybe<Scalars['String']>;
  customTitle?: Maybe<Scalars['String']>;
  order: Scalars['Float'];
  text: Scalars['String'];
  type: InsightBlockType;
};

export type InsightRichTextBlockInput = {
  optionalHeading?: InputMaybe<Scalars['String']>;
  order: Scalars['Float'];
  text: Scalars['String'];
};

export type InsightRichTextBlockOutput = InsightBlockOutput & {
  __typename?: 'InsightRichTextBlockOutput';
  optionalHeading?: Maybe<Scalars['String']>;
  order: Scalars['Float'];
  text: Scalars['String'];
  type: InsightBlockType;
};

export type InsightRightTextBlockInput = {
  image: Scalars['ID'];
  optionalHeading?: InputMaybe<Scalars['String']>;
  order: Scalars['Float'];
  text: Scalars['String'];
};

export type InsightRightTextBlockOutput = InsightBlockOutput & {
  __typename?: 'InsightRightTextBlockOutput';
  image: MediaOutput;
  optionalHeading?: Maybe<Scalars['String']>;
  order: Scalars['Float'];
  text: Scalars['String'];
  type: InsightBlockType;
};

export enum InsightStatusEnum {
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
}

export type InsightTagsInput = {
  countryFocusTagId: Scalars['String'];
  esgFocus: EsgCategoryEnum;
  id: Scalars['ID'];
  sdGoalTagId: Scalars['String'];
};

export type InsightVisibilityInput = {
  advisorIds?: InputMaybe<Array<Scalars['ID']>>;
  connections?: InputMaybe<Scalars['Boolean']>;
  family?: InputMaybe<Scalars['Boolean']>;
  familyOwnersIds?: InputMaybe<Array<Scalars['ID']>>;
  id: Scalars['ID'];
  isPublic?: InputMaybe<Scalars['Boolean']>;
  itriom?: InputMaybe<Scalars['Boolean']>;
};

export type InsightVisibilityOutput = {
  __typename?: 'InsightVisibilityOutput';
  advisorIds: Array<Scalars['ID']>;
  connections: Scalars['Boolean'];
  family: Scalars['Boolean'];
  familyOwner?: Maybe<Family>;
  familyOwnersIds: Array<Scalars['ID']>;
  individual: Scalars['Boolean'];
  isPublic: Scalars['Boolean'];
  itriom: Scalars['Boolean'];
};

export enum InsightsRecommendationEnum {
  MatchingAtLeastOneSdg = 'MatchingAtLeastOneSdg',
  NotMatchingAnySdg = 'NotMatchingAnySdg'
}

export type ItriomTagsFilter = {
  countryFocusTagId?: InputMaybe<Scalars['String']>;
  esgFocus?: InputMaybe<EsgCategoryEnum>;
  sdGoalTagId?: InputMaybe<Scalars['String']>;
};

export type ItriomTagsOutput = {
  __typename?: 'ItriomTagsOutput';
  countryFocusTags: Array<TagOutput>;
  environmentFocusTags: Array<TagOutput>;
  governanceFocusTags: Array<TagOutput>;
  sdGoalsTags: Array<SdgOutput>;
  socialFocusTags: Array<TagOutput>;
};

export type ItriomTagsV2Output = {
  __typename?: 'ItriomTagsV2Output';
  countryFocusTag: TagOutput;
  esgFocus: EsgCategoryEnum;
  sdGoalTag: SdgOutput;
};

export type JoinInput = {
  description?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  imageId?: InputMaybe<Scalars['ID']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type JoinOutput = {
  __typename?: 'JoinOutput';
  description?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  image?: Maybe<MediaOutput>;
  isEnabled: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
};

export type KeyEventSpeakerInput = {
  about: Scalars['String'];
  eventId: Scalars['String'];
  keynoteSpeakerHeading?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  title: Scalars['String'];
};

export type LibraryDetails = {
  __typename?: 'LibraryDetails';
  /** Unlimited if null */
  maximumSize?: Maybe<Scalars['Float']>;
  /** Unlimited if null */
  maximumSizePerFile?: Maybe<Scalars['Float']>;
  usedSize: Scalars['Float'];
};

/** Media can be files or directories. */
export type LibraryMediaOutput = {
  __typename?: 'LibraryMediaOutput';
  cdnKey?: Maybe<Scalars['String']>;
  createdAt: Scalars['Timestamp'];
  id: Scalars['ID'];
  isDirectory: Scalars['Boolean'];
  isFavorite: Scalars['Boolean'];
  isMovable: Scalars['Boolean'];
  isRemovable: Scalars['Boolean'];
  isRenamable: Scalars['Boolean'];
  isShareable: Scalars['Boolean'];
  libraryOwner: Scalars['String'];
  libraryType: LibraryTypeEnum;
  mimeType: Scalars['String'];
  name: Scalars['String'];
  path: Scalars['String'];
  removedAt?: Maybe<Scalars['Timestamp']>;
  resolvedPath: Array<LibraryMediaOutput>;
  resources: Array<LibraryMediaOutput>;
  shares: Array<Profile>;
  size: Scalars['Float'];
  updatedAt: Scalars['Timestamp'];
};

/** Media can be files or directories. */
export type LibraryMediaSimplifiedOutput = {
  __typename?: 'LibraryMediaSimplifiedOutput';
  createdAt: Scalars['Timestamp'];
  id: Scalars['ID'];
  isDirectory: Scalars['Boolean'];
  libraryOwner: Scalars['String'];
  libraryType: LibraryTypeEnum;
  mimeType: Scalars['String'];
  name: Scalars['String'];
  updatedAt: Scalars['Timestamp'];
};

export type LibraryResourceSharedMetadata = NotificationMetadataOutput & {
  __typename?: 'LibraryResourceSharedMetadata';
  accountId: Scalars['ID'];
  resourceId: Scalars['ID'];
};

export type LibraryResourceSharedOutput = NotificationOutput & {
  __typename?: 'LibraryResourceSharedOutput';
  category: NotificationCategoryEnum;
  createdAt: Scalars['Timestamp'];
  id: Scalars['ID'];
  isRead: Scalars['Boolean'];
  metadata: LibraryResourceSharedMetadata;
  resource?: Maybe<LibraryMediaSimplifiedOutput>;
  sender?: Maybe<Profile>;
  type: NotificationTypeEnum;
};

/** Media can be files or directories. */
export type LibrarySharedMediaOutput = {
  __typename?: 'LibrarySharedMediaOutput';
  cdnKey?: Maybe<Scalars['String']>;
  createdAt: Scalars['Timestamp'];
  id: Scalars['ID'];
  isDirectory: Scalars['Boolean'];
  isFavorite: Scalars['Boolean'];
  libraryOwner: Scalars['String'];
  libraryType: LibraryTypeEnum;
  mimeType: Scalars['String'];
  name: Scalars['String'];
  path: Scalars['String'];
  removedAt?: Maybe<Scalars['Timestamp']>;
  resolvedPath: Array<LibrarySharedMediaOutput>;
  resources: Array<LibrarySharedMediaOutput>;
  shares: Array<Profile>;
  size: Scalars['Float'];
  updatedAt: Scalars['Timestamp'];
};

export enum LibraryTypeEnum {
  Family = 'Family',
  Itriom = 'Itriom',
  Own = 'Own'
}

/** Metadata for link preview used in posts */
export type LinkMetadata = {
  __typename?: 'LinkMetadata';
  description: Scalars['String'];
  hostname: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type ListAvailableTagsInput = {
  types?: InputMaybe<Array<TagType>>;
};

export type ListEditableAdvisorInput = {
  statuses?: InputMaybe<Array<AdvisorStatusEnum>>;
};

export type ListEditableInsightInput = {
  createdBy?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<InsightStatusEnum>>;
};

export type ListFamilyCharterInput = {
  statuses?: InputMaybe<Array<FamilyCharterStatusEnum>>;
};

export type ListFamilyInput = {
  includeOnlyWithFinishedOnboard?: InputMaybe<Scalars['Boolean']>;
};

export type ListInitiativesFiltersInput = {
  createdBy?: InputMaybe<Array<Scalars['String']>>;
  followedInitiativesTreatment?: InputMaybe<FollowedInitiativesTreatment>;
  status?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<ItriomTagsFilter>;
  visibilityFilters?: InputMaybe<ListInitiativesVisibilityFiltersInput>;
};

export type ListInitiativesVisibilityFiltersInput = {
  connections?: InputMaybe<Scalars['Boolean']>;
  family?: InputMaybe<Scalars['Boolean']>;
  familyIdHasAccessTo?: InputMaybe<Scalars['String']>;
  familyOwnersIds?: InputMaybe<Array<Scalars['String']>>;
  familyOwnersIdsToExclude?: InputMaybe<Array<Scalars['String']>>;
  featured?: InputMaybe<Scalars['Boolean']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  itriom?: InputMaybe<Scalars['Boolean']>;
  joint?: InputMaybe<Scalars['Boolean']>;
};

export type ListInsightInput = {
  createdBy?: InputMaybe<Array<Scalars['String']>>;
  familyId?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<InsightStatusEnum>>;
  tags?: InputMaybe<ItriomTagsFilter>;
  visibility?: InputMaybe<ListInsightVisibilityFiltersInput>;
};

export type ListInsightVisibilityFiltersInput = {
  advisorIds?: InputMaybe<Array<Scalars['String']>>;
  advisorIdsToExclude?: InputMaybe<Array<Scalars['String']>>;
  connections?: InputMaybe<Scalars['Boolean']>;
  family?: InputMaybe<Scalars['Boolean']>;
  familyOwnersIds?: InputMaybe<Array<Scalars['String']>>;
  familyOwnersIdsToExclude?: InputMaybe<Array<Scalars['String']>>;
  individual?: InputMaybe<Scalars['Boolean']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  itriom?: InputMaybe<Scalars['Boolean']>;
  joint?: InputMaybe<Scalars['Boolean']>;
  userHasAccessTo?: InputMaybe<Scalars['Boolean']>;
};

/** A Location represents the physical location of the Event. */
export type Location = {
  __typename?: 'Location';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  button?: Maybe<LocationButtonOutput>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  heroImage?: Maybe<MediaOutput>;
  images: Array<LocationImageOutput>;
  isGalleryEnabled: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
};

export type LocationBasicDataInput = {
  addressLine1: Scalars['String'];
  addressLine2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  postcode: Scalars['String'];
  state: Scalars['String'];
};

export type LocationButtonInput = {
  buttonLabel: Scalars['String'];
  buttonLink: Scalars['String'];
};

export type LocationButtonOutput = {
  __typename?: 'LocationButtonOutput';
  buttonLabel: Scalars['String'];
  buttonLink: Scalars['String'];
};

export type LocationImageInput = {
  description: Scalars['String'];
  imageId: Scalars['ID'];
};

export type LocationImageOutput = {
  __typename?: 'LocationImageOutput';
  description: Scalars['String'];
  image: MediaOutput;
};

export type LocationInput = {
  buttonData?: InputMaybe<LocationButtonInput>;
  description: Scalars['String'];
  heroImageId: Scalars['ID'];
  id: Scalars['ID'];
  images?: InputMaybe<Array<LocationImageInput>>;
  isGalleryEnabled?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type MediaInput = {
  signedUrl?: InputMaybe<Scalars['String']>;
};

/** Media can be images, videos or files attached to other entities to provide content. */
export type MediaOutput = {
  __typename?: 'MediaOutput';
  createdAt: Scalars['Timestamp'];
  id: Scalars['ID'];
  signedUrl?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Timestamp'];
};

export type MemberToAddOutput = ProfileInterfaceOutput & {
  __typename?: 'MemberToAddOutput';
  avatarImage?: Maybe<MediaOutput>;
  canBeInvited: Scalars['Boolean'];
  connectionState: ProfileConnectionStateEnum;
  createdAt: Scalars['Timestamp'];
  description?: Maybe<Scalars['String']>;
  family?: Maybe<Family>;
  firstName: Scalars['String'];
  heroImage?: Maybe<MediaOutput>;
  honorific?: Maybe<Honorific>;
  id: Scalars['ID'];
  /** @deprecated Not used */
  isConnected: Scalars['Boolean'];
  isInvited: Scalars['Boolean'];
  lastName: Scalars['String'];
  principal?: Maybe<PrincipalOutput>;
  role: Role;
  status: AccountStatus;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Timestamp'];
};

/** A Message is text sent from one Itriom account to one or more other Accounts. */
export type Message = {
  __typename?: 'Message';
  conversationId: Scalars['String'];
  createdAt: Scalars['Timestamp'];
  createdBy?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  recipientIds: Array<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Timestamp'];
  updatedBy?: Maybe<Scalars['String']>;
};

export type MessageInput = {
  conversationId?: InputMaybe<Scalars['ID']>;
  recipientIds?: InputMaybe<Array<Scalars['ID']>>;
  text: Scalars['String'];
};

export type MessageNotification = {
  __typename?: 'MessageNotification';
  payload: Message;
};

export type MessageSentMetadata = NotificationMetadataOutput & {
  __typename?: 'MessageSentMetadata';
  accountId: Scalars['ID'];
  conversationId: Scalars['ID'];
};

export type MessageSentNotificationOutput = NotificationOutput & {
  __typename?: 'MessageSentNotificationOutput';
  category: NotificationCategoryEnum;
  createdAt: Scalars['Timestamp'];
  id: Scalars['ID'];
  isRead: Scalars['Boolean'];
  metadata: MessageSentMetadata;
  sender?: Maybe<Profile>;
  type: NotificationTypeEnum;
};

export type MinimalAccountDataOutput = {
  __typename?: 'MinimalAccountDataOutput';
  email: Scalars['String'];
  firstName: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptConnectionInvitation: Scalars['ID'];
  acceptGroupInvitation: Scalars['Boolean'];
  addFamilyAdmin: Family;
  addFamilyAdvisor: Family;
  addFamilyMember: Family;
  addLibraryMediaToFavorite: Scalars['Boolean'];
  addValueConfiguratorScores: ValueConfiguratorOutput;
  attendToEvent: Scalars['Boolean'];
  bookDemo: Scalars['Boolean'];
  changeAccountFamily: Account;
  changeAccountStatus: Account;
  changeAdvisorPublicationState: AdvisorOutput;
  changeEventStatus: Event;
  changeFamilyCharterPublicationState: FamilyCharter;
  changeFamilyStatus: Family;
  changeInitiativePublicationState: InitiativeOutput;
  changeInsightPublicationState: Insight;
  changePrincipalPublicationState: PrincipalOutput;
  commitFileInLibrary: LibraryMediaOutput;
  completeFamilySdgConfigurator: Scalars['Boolean'];
  completeFamilyValueConfigurator: Scalars['Boolean'];
  createAccountBySuperAdmin: Account;
  createAdvisor: AdvisorOutput;
  createAdvisorAccount: Account;
  createComment: Comment;
  createDirectoryInLibrary: LibraryMediaOutput;
  createEvent: Event;
  createFamilyCharter: FamilyCharter;
  createGlobalTag: TagOutput;
  createGroup: Group;
  createInitiative: InitiativeOutput;
  createInsight: Insight;
  createMedia: MediaOutput;
  createNotFamilyRelatedAccount: Account;
  createPost: Post;
  createPrincipal: PrincipalOutput;
  createUserAccountByAdmin: Account;
  deleteAccount: Scalars['Boolean'];
  deleteAdvisor: Scalars['Boolean'];
  deleteComment: Scalars['ID'];
  deleteConversation: Scalars['ID'];
  deleteEvent: Scalars['Boolean'];
  deleteFamily: Scalars['Boolean'];
  deleteFamilyAdmin: Family;
  deleteFamilyAdvisor: Family;
  deleteGroup: Scalars['ID'];
  deleteInitiative: Scalars['Boolean'];
  deleteInsight: Scalars['Boolean'];
  deleteMessage: Scalars['ID'];
  deletePost: Scalars['ID'];
  deleteTag: Scalars['Boolean'];
  eventToggleComments: Event;
  followInitiative: Scalars['Boolean'];
  forgotPassword: Scalars['Boolean'];
  inviteToConnections: ConnectionInvitation;
  inviteToGroup: Scalars['Boolean'];
  joinGroup: Group;
  leaveGroup: Group;
  markAllNotificationsAsRead: Scalars['Boolean'];
  markNotificationsAsRead: Scalars['Boolean'];
  moveResourceInLibrary: LibraryMediaOutput;
  refreshToken: TokensOutput;
  rejectConnectionInvitation: Scalars['ID'];
  rejectGroupInvitation: Scalars['Boolean'];
  removeAdvisorLocation: AdvisorOutput;
  removeFamilyMember: Family;
  removeFromConnections: Scalars['ID'];
  /** Removes any type of member in group either it is an admin or member or other role */
  removeGroupMember: Group;
  removeLibraryMediaFromFavorite: Scalars['Boolean'];
  removePrincipalByAccountId: Scalars['Boolean'];
  removeResourceInLibrary: Scalars['Boolean'];
  removeTrashFromLibrary: Scalars['Boolean'];
  renameResourceInLibrary: LibraryMediaOutput;
  requestForgotPasswordVerificationSecondFactor: Scalars['Boolean'];
  requestNewOTP: Scalars['Boolean'];
  requestSecondFactorForToken: Scalars['Boolean'];
  restoreResourceInLibrary: Scalars['Boolean'];
  sendCta: TriggeredCtaOutput;
  sendMessage: Message;
  sendOnboardNotification: Scalars['Boolean'];
  setAttendanceResponse?: Maybe<OnboardManagementMeetingInvitationOutput>;
  setAttendanceResponseAsAdmin: Scalars['Boolean'];
  setConfigStatusAsAdmin: Scalars['Boolean'];
  setNewPasswordWithToken: Scalars['Boolean'];
  setPasswordForFirstTime: Scalars['Boolean'];
  shareResourceInLibrary: Scalars['Boolean'];
  signIn: Scalars['String'];
  submitPersonalSdgs: SdgConfiguratorOutput;
  supportPhoneVerification: Scalars['Boolean'];
  toggleAppreciation: Appreciation;
  unAttendToEvent: Scalars['Boolean'];
  unfollowInitiative: Scalars['Boolean'];
  unshareResourceInLibrary: Scalars['Boolean'];
  updateAccount: Account;
  updateAccountDetails: Account;
  updateAdvisorAbout: AdvisorOutput;
  updateAdvisorAdvocacy: AdvisorOutput;
  updateAdvisorAwards: AdvisorOutput;
  updateAdvisorHero: AdvisorOutput;
  updateAdvisorLocation: AdvisorOutput;
  updateAdvisorTags: AdvisorOutput;
  updateComment: Comment;
  updateEventAgenda: Event;
  updateEventBasicInfo: Event;
  updateEventCallToAction: Event;
  updateEventHero: Event;
  updateEventKeySpeaker: Event;
  updateEventLocation: Event;
  updateEventObjectives: Event;
  updateEventOrganisers: Event;
  updateEventSponsors: Event;
  updateEventTags: Event;
  updateEventTopics: Event;
  updateEventVisibility: Event;
  updateFamily: Family;
  updateFamilyCharterGoals: FamilyCharter;
  updateFamilyCharterHero: FamilyCharter;
  updateFamilyHead: Scalars['Boolean'];
  updateFamilyMember: Scalars['Boolean'];
  updateFamilyOnboardManagementMeetingInvitation: OnboardManagementOutput;
  updateGroup: Group;
  updateInitiativeBlocks: InitiativeOutput;
  updateInitiativeHero: InitiativeOutput;
  updateInitiativeItriomTags: InitiativeOutput;
  updateInitiativeRelatedArticles: InitiativeOutput;
  updateInitiativeVisibility: InitiativeOutput;
  updateInsightBlocks: Insight;
  updateInsightHero: Insight;
  updateInsightRelatedArticles: Insight;
  updateInsightTags: Insight;
  updateInsightVisibility: Insight;
  updateMemberRelation: FamilyMemberRelationOutput;
  updatePassword: Scalars['Boolean'];
  updatePost: Post;
  updatePrincipalAbout: PrincipalOutput;
  updatePrincipalHero: PrincipalOutput;
  updatePrincipalTags: PrincipalOutput;
  updatePrincipalValues: PrincipalOutput;
  updatePrincipalVisibilitySettings: PrincipalOutput;
  updateUltimateGoalEvent: Event;
  verifyForgotPasswordSecondFactor: Scalars['String'];
  verifySecondFactor: TokensOutput;
  verifySecondFactorForToken: TokensOutput;
};


export type MutationAcceptConnectionInvitationArgs = {
  invitationId: Scalars['String'];
};


export type MutationAcceptGroupInvitationArgs = {
  groupId: Scalars['String'];
};


export type MutationAddLibraryMediaToFavoriteArgs = {
  resourceId: Scalars['String'];
};


export type MutationAddValueConfiguratorScoresArgs = {
  data: ValueConfiguratorInput;
};


export type MutationAttendToEventArgs = {
  id: Scalars['String'];
};


export type MutationBookDemoArgs = {
  type: BookingTypeEnum;
};


export type MutationChangeAccountFamilyArgs = {
  account: ChangeAccountFamilyInput;
};


export type MutationChangeAccountStatusArgs = {
  accountId: Scalars['String'];
  status: AccountStatus;
};


export type MutationChangeAdvisorPublicationStateArgs = {
  id: Scalars['String'];
};


export type MutationChangeEventStatusArgs = {
  event: ChangeEventStatusInput;
};


export type MutationChangeFamilyCharterPublicationStateArgs = {
  id: Scalars['String'];
};


export type MutationChangeFamilyStatusArgs = {
  familyId: Scalars['String'];
  status: FamilyStatusEnum;
};


export type MutationChangeInitiativePublicationStateArgs = {
  id: Scalars['String'];
};


export type MutationChangeInsightPublicationStateArgs = {
  id: Scalars['String'];
};


export type MutationChangePrincipalPublicationStateArgs = {
  id: Scalars['String'];
};


export type MutationCommitFileInLibraryArgs = {
  destinationId?: InputMaybe<Scalars['String']>;
  fileName: Scalars['String'];
  libraryType: LibraryTypeEnum;
  temporaryFileKey: Scalars['String'];
};


export type MutationCompleteFamilySdgConfiguratorArgs = {
  familyId: Scalars['String'];
};


export type MutationCompleteFamilyValueConfiguratorArgs = {
  familyId: Scalars['String'];
};


export type MutationCreateAccountBySuperAdminArgs = {
  account: CreateRawAccountInput;
};


export type MutationCreateAdvisorArgs = {
  data: CreateAdvisorInput;
};


export type MutationCreateAdvisorAccountArgs = {
  data: CreateAdvisorAccountInput;
};


export type MutationCreateCommentArgs = {
  comment: CommentCreateInput;
  images?: InputMaybe<Array<Scalars['Upload']>>;
};


export type MutationCreateDirectoryInLibraryArgs = {
  destinationId?: InputMaybe<Scalars['String']>;
  directoryName: Scalars['String'];
  libraryType: LibraryTypeEnum;
};


export type MutationCreateEventArgs = {
  event: CreateEventInput;
  heroImage?: InputMaybe<Array<Scalars['Upload']>>;
  heroVideo?: InputMaybe<Array<Scalars['Upload']>>;
};


export type MutationCreateFamilyCharterArgs = {
  data: CreateFamilyCharterInput;
};


export type MutationCreateGlobalTagArgs = {
  input: CreateGlobalTagInput;
};


export type MutationCreateGroupArgs = {
  avatarImage: Array<Scalars['Upload']>;
  group: GroupCreateInput;
  heroImage: Array<Scalars['Upload']>;
};


export type MutationCreateInitiativeArgs = {
  initiative: CreateInitiativeInput;
};


export type MutationCreateInsightArgs = {
  insight: CreateInsightInput;
};


export type MutationCreateMediaArgs = {
  media: Scalars['Upload'];
};


export type MutationCreateNotFamilyRelatedAccountArgs = {
  data: CreateNotFamilyRelatedAccountInput;
};


export type MutationCreatePostArgs = {
  images?: InputMaybe<Array<Scalars['Upload']>>;
  post: PostCreateInput;
};


export type MutationCreatePrincipalArgs = {
  principal: CreatePrincipalInput;
};


export type MutationCreateUserAccountByAdminArgs = {
  data: CreateAccountByAdminInput;
};


export type MutationDeleteAccountArgs = {
  accountId: Scalars['String'];
  securityPhrase: Scalars['String'];
};


export type MutationDeleteAdvisorArgs = {
  data: AdvisorInput;
};


export type MutationDeleteCommentArgs = {
  id: Scalars['String'];
};


export type MutationDeleteConversationArgs = {
  id: Scalars['String'];
};


export type MutationDeleteEventArgs = {
  eventId: Scalars['String'];
};


export type MutationDeleteFamilyArgs = {
  familyId: Scalars['String'];
  securityPhrase: Scalars['String'];
};


export type MutationDeleteGroupArgs = {
  id: Scalars['String'];
  userValidationInput: Scalars['String'];
};


export type MutationDeleteInitiativeArgs = {
  id: Scalars['String'];
};


export type MutationDeleteInsightArgs = {
  insightId: Scalars['String'];
};


export type MutationDeleteMessageArgs = {
  id: Scalars['String'];
};


export type MutationDeletePostArgs = {
  id: Scalars['String'];
};


export type MutationDeleteTagArgs = {
  tagId: Scalars['String'];
};


export type MutationEventToggleCommentsArgs = {
  data: EnableCommentsInput;
};


export type MutationFollowInitiativeArgs = {
  id: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationInviteToConnectionsArgs = {
  accountId: Scalars['String'];
};


export type MutationInviteToGroupArgs = {
  accountId: Scalars['String'];
  groupId: Scalars['String'];
};


export type MutationJoinGroupArgs = {
  groupId: Scalars['String'];
};


export type MutationLeaveGroupArgs = {
  groupId: Scalars['String'];
};


export type MutationMarkAllNotificationsAsReadArgs = {
  category?: InputMaybe<NotificationCategoryEnum>;
};


export type MutationMarkNotificationsAsReadArgs = {
  data: NotificationIdsInput;
};


export type MutationMoveResourceInLibraryArgs = {
  destinationId?: InputMaybe<Scalars['String']>;
  libraryType: LibraryTypeEnum;
  resourceId: Scalars['String'];
};


export type MutationRefreshTokenArgs = {
  token: Scalars['String'];
};


export type MutationRejectConnectionInvitationArgs = {
  invitationId: Scalars['String'];
};


export type MutationRejectGroupInvitationArgs = {
  groupId: Scalars['String'];
};


export type MutationRemoveAdvisorLocationArgs = {
  data: AdvisorInput;
};


export type MutationRemoveFromConnectionsArgs = {
  accountId: Scalars['String'];
};


export type MutationRemoveGroupMemberArgs = {
  groupId: Scalars['String'];
  memberId: Scalars['String'];
};


export type MutationRemoveLibraryMediaFromFavoriteArgs = {
  resourceId: Scalars['String'];
};


export type MutationRemovePrincipalByAccountIdArgs = {
  id: Scalars['String'];
};


export type MutationRemoveResourceInLibraryArgs = {
  libraryType: LibraryTypeEnum;
  resourceId: Scalars['String'];
};


export type MutationRemoveTrashFromLibraryArgs = {
  libraryType: LibraryTypeEnum;
  resourceId: Scalars['String'];
};


export type MutationRenameResourceInLibraryArgs = {
  libraryType: LibraryTypeEnum;
  newName: Scalars['String'];
  resourceId: Scalars['String'];
};


export type MutationRequestForgotPasswordVerificationSecondFactorArgs = {
  token: Scalars['String'];
};


export type MutationRequestNewOtpArgs = {
  jwt: Scalars['String'];
  sendLocation?: InputMaybe<VerificationCodeSendLocation>;
};


export type MutationRequestSecondFactorForTokenArgs = {
  sendLocation?: InputMaybe<VerificationCodeSendLocation>;
  token: Scalars['String'];
  tokenType: SecondFactorVerificationTypeEnum;
};


export type MutationRestoreResourceInLibraryArgs = {
  libraryType: LibraryTypeEnum;
  resourceId: Scalars['String'];
};


export type MutationSendCtaArgs = {
  data: TriggeredCtaInput;
};


export type MutationSendMessageArgs = {
  message: MessageInput;
};


export type MutationSendOnboardNotificationArgs = {
  data: SendOnboardNotificationInput;
};


export type MutationSetAttendanceResponseArgs = {
  status: AttendanceStatusEnum;
  token: Scalars['String'];
};


export type MutationSetAttendanceResponseAsAdminArgs = {
  accountId: Scalars['String'];
  status: AttendanceStatusEnum;
};


export type MutationSetConfigStatusAsAdminArgs = {
  data: SetConfigStatusAsAdminInput;
};


export type MutationSetNewPasswordWithTokenArgs = {
  data: SetNewPasswordWithTokenInput;
};


export type MutationSetPasswordForFirstTimeArgs = {
  data: SetPasswordForFirstTimeInput;
};


export type MutationShareResourceInLibraryArgs = {
  consumerId: Scalars['String'];
  libraryType: LibraryTypeEnum;
  resourceId: Scalars['String'];
};


export type MutationSignInArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationSubmitPersonalSdgsArgs = {
  data: SubmitPersonalSdgsInput;
};


export type MutationSupportPhoneVerificationArgs = {
  token: Scalars['String'];
};


export type MutationToggleAppreciationArgs = {
  appreciate: AppreciateInput;
};


export type MutationUnAttendToEventArgs = {
  id: Scalars['String'];
};


export type MutationUnfollowInitiativeArgs = {
  id: Scalars['String'];
};


export type MutationUnshareResourceInLibraryArgs = {
  consumerId: Scalars['String'];
  libraryType: LibraryTypeEnum;
  resourceId: Scalars['String'];
};


export type MutationUpdateAccountArgs = {
  account: AccountInput;
  avatarImage?: InputMaybe<Array<Scalars['Upload']>>;
  heroImage?: InputMaybe<Array<Scalars['Upload']>>;
};


export type MutationUpdateAccountDetailsArgs = {
  data: OnboardUpdateMemberDetailsInput;
};


export type MutationUpdateAdvisorAboutArgs = {
  data: AdvisorAboutInput;
};


export type MutationUpdateAdvisorAdvocacyArgs = {
  data: AdvisorAdvocacyInput;
};


export type MutationUpdateAdvisorAwardsArgs = {
  data: UpdateAdvisorAwardsInput;
};


export type MutationUpdateAdvisorHeroArgs = {
  data: AdvisorHeroInput;
};


export type MutationUpdateAdvisorLocationArgs = {
  data: AdvisorLocationInput;
};


export type MutationUpdateAdvisorTagsArgs = {
  data: AdvisorsTagsInput;
};


export type MutationUpdateCommentArgs = {
  comment: CommentUpdateInput;
  images?: InputMaybe<Array<Scalars['Upload']>>;
};


export type MutationUpdateEventAgendaArgs = {
  event: AgendaInput;
};


export type MutationUpdateEventBasicInfoArgs = {
  event: EventBasicInfoInput;
};


export type MutationUpdateEventCallToActionArgs = {
  data: CallToActionInput;
};


export type MutationUpdateEventHeroArgs = {
  event: EventHeroInput;
  heroImage?: InputMaybe<Array<Scalars['Upload']>>;
  heroVideo?: InputMaybe<Array<Scalars['Upload']>>;
};


export type MutationUpdateEventKeySpeakerArgs = {
  image?: InputMaybe<Array<Scalars['Upload']>>;
  speaker: KeyEventSpeakerInput;
};


export type MutationUpdateEventLocationArgs = {
  location: LocationInput;
};


export type MutationUpdateEventObjectivesArgs = {
  event: EventObjectivesUpdateInput;
};


export type MutationUpdateEventOrganisersArgs = {
  data: UpdateEventOrganisersInput;
};


export type MutationUpdateEventSponsorsArgs = {
  data: UpdateSponsorsInput;
};


export type MutationUpdateEventTagsArgs = {
  event: EventTagsInput;
};


export type MutationUpdateEventTopicsArgs = {
  event: UpdateTopicsInput;
};


export type MutationUpdateEventVisibilityArgs = {
  visibility: EventVisibilityInput;
};


export type MutationUpdateFamilyCharterGoalsArgs = {
  data: UpdateFamilyCharterGoalsInput;
};


export type MutationUpdateFamilyCharterHeroArgs = {
  data: UpdateFamilyCharterHeroInput;
};


export type MutationUpdateFamilyHeadArgs = {
  familyId: Scalars['String'];
  newFamilyHeadUserId: Scalars['String'];
};


export type MutationUpdateFamilyMemberArgs = {
  updateAccount: OnboardUpdateMemberDetailsInput;
};


export type MutationUpdateFamilyOnboardManagementMeetingInvitationArgs = {
  data: OnboardManagementUpdateInvitationInput;
};


export type MutationUpdateGroupArgs = {
  avatarImage?: InputMaybe<Array<Scalars['Upload']>>;
  group: GroupUpdateInput;
  heroImage?: InputMaybe<Array<Scalars['Upload']>>;
};


export type MutationUpdateInitiativeBlocksArgs = {
  data: UpdateInitiativeBlocksInput;
};


export type MutationUpdateInitiativeHeroArgs = {
  hero: InitiativeHeroInput;
};


export type MutationUpdateInitiativeItriomTagsArgs = {
  tags: InitiativeTagsInput;
};


export type MutationUpdateInitiativeRelatedArticlesArgs = {
  data: UpdateInitiativeRelatedArticlesInput;
};


export type MutationUpdateInitiativeVisibilityArgs = {
  id: Scalars['String'];
  visibility: InitiativeVisibilityInput;
};


export type MutationUpdateInsightBlocksArgs = {
  data: UpdateInsightBlocksInput;
};


export type MutationUpdateInsightHeroArgs = {
  insight: InsightHeroInput;
};


export type MutationUpdateInsightRelatedArticlesArgs = {
  data: UpdateInsightRelatedArticlesInput;
};


export type MutationUpdateInsightTagsArgs = {
  insight: InsightTagsInput;
};


export type MutationUpdateInsightVisibilityArgs = {
  data: InsightVisibilityInput;
};


export type MutationUpdateMemberRelationArgs = {
  data: UpdateMemberRelationInput;
};


export type MutationUpdatePasswordArgs = {
  data: UpdatePasswordInput;
};


export type MutationUpdatePostArgs = {
  images?: InputMaybe<Array<Scalars['Upload']>>;
  post: PostUpdateInput;
};


export type MutationUpdatePrincipalAboutArgs = {
  about: PrincipalAboutInput;
};


export type MutationUpdatePrincipalHeroArgs = {
  hero: PrincipalHeroInput;
};


export type MutationUpdatePrincipalTagsArgs = {
  tags: PrincipalTagsInput;
};


export type MutationUpdatePrincipalValuesArgs = {
  principal: UpdatePrincipalValuesInput;
};


export type MutationUpdatePrincipalVisibilitySettingsArgs = {
  data: UpdatePrincipalPrivacySettingsInput;
};


export type MutationUpdateUltimateGoalEventArgs = {
  data: UltimateGoalInput;
};


export type MutationVerifyForgotPasswordSecondFactorArgs = {
  secondFactor: Scalars['Float'];
  token: Scalars['String'];
};


export type MutationVerifySecondFactorArgs = {
  jwt: Scalars['String'];
  secondFactor: Scalars['Float'];
};


export type MutationVerifySecondFactorForTokenArgs = {
  secondFactor: Scalars['Float'];
  token: Scalars['String'];
  tokenType: SecondFactorVerificationTypeEnum;
};

export type NewFamilyContentMetadata = NotificationMetadataOutput & {
  __typename?: 'NewFamilyContentMetadata';
  accountId: Scalars['ID'];
  entityId: Scalars['ID'];
  entityType: ContentEntityType;
};

export type NewFamilyContentOutput = NotificationOutput & {
  __typename?: 'NewFamilyContentOutput';
  category: NotificationCategoryEnum;
  createdAt: Scalars['Timestamp'];
  id: Scalars['ID'];
  isRead: Scalars['Boolean'];
  metadata: NewFamilyContentMetadata;
  sender?: Maybe<Profile>;
  type: NotificationTypeEnum;
};

export enum NotificationCategoryEnum {
  Connection = 'Connection',
  Message = 'Message',
  Other = 'Other'
}

export type NotificationIdsInput = {
  notificationIds: Array<Scalars['String']>;
};

export type NotificationMetadataOutput = {
  accountId: Scalars['ID'];
};

export type NotificationOutput = {
  category: NotificationCategoryEnum;
  createdAt: Scalars['Timestamp'];
  id: Scalars['ID'];
  isRead: Scalars['Boolean'];
  metadata: NotificationMetadataOutput;
  sender?: Maybe<Profile>;
  type: NotificationTypeEnum;
};

export type NotificationSubscriptionOutput = {
  __typename?: 'NotificationSubscriptionOutput';
  payload: NotificationUnionOutput;
};

export enum NotificationTypeEnum {
  CommentNewResponse = 'CommentNewResponse',
  ConnectionInvitationAccepted = 'ConnectionInvitationAccepted',
  ConnectionInvitationSent = 'ConnectionInvitationSent',
  ContentPublishedByFamily = 'ContentPublishedByFamily',
  ContentRemovedByAdmin = 'ContentRemovedByAdmin',
  GroupAdminLeft = 'GroupAdminLeft',
  GroupInvitationAccepted = 'GroupInvitationAccepted',
  GroupInvitationRejected = 'GroupInvitationRejected',
  GroupInvitationSent = 'GroupInvitationSent',
  GroupNewAdmin = 'GroupNewAdmin',
  GroupNewMember = 'GroupNewMember',
  InitiativeFollowed = 'InitiativeFollowed',
  InitiativeNewComment = 'InitiativeNewComment',
  LibraryResourceShared = 'LibraryResourceShared',
  MessageSent = 'MessageSent',
  PostNewAppreciation = 'PostNewAppreciation',
  PostNewComment = 'PostNewComment'
}

export type NotificationUnionOutput = ConnectionAcceptedNotificationOutput | ConnectionSentNotificationOutput | ContentDeletedByAdminOutput | GroupInvitationSentNotificationOutput | GroupNotificationOutput | InitiativeNotificationOutput | LibraryResourceSharedOutput | MessageSentNotificationOutput | NewFamilyContentOutput | PostNotificationOutput;

/** An Objective represents an specific aim that the Family wishes to achieve. */
export type Objective = {
  __typename?: 'Objective';
  createdAt: Scalars['Timestamp'];
  description: Scalars['String'];
  id: Scalars['ID'];
  image?: Maybe<MediaOutput>;
  initiatives?: Maybe<Array<InitiativeOutput>>;
  name: Scalars['String'];
  updatedAt: Scalars['Timestamp'];
};

export enum OnboardAccountStatus {
  InProgress = 'IN_PROGRESS',
  Onboarded = 'ONBOARDED'
}

export type OnboardManagementActions = {
  __typename?: 'OnboardManagementActions';
  profileCompletedEmailSent: Scalars['Boolean'];
  rsvpEmailSent: Scalars['Boolean'];
  sdgConfiguratorCompletedEmailSent: Scalars['Boolean'];
  sdgConfiguratorInvitationEmailSent: Scalars['Boolean'];
};

export type OnboardManagementInvitationInput = {
  date: TimezoneDateInput;
  location?: InputMaybe<Scalars['String']>;
  meetingId?: InputMaybe<Scalars['String']>;
  meetingLink?: InputMaybe<Scalars['String']>;
  meetingPasscode?: InputMaybe<Scalars['String']>;
};

export type OnboardManagementMeetingInvitationOutput = {
  __typename?: 'OnboardManagementMeetingInvitationOutput';
  date: TimezoneDateOutput;
  location?: Maybe<Scalars['String']>;
  meetingId?: Maybe<Scalars['String']>;
  meetingLink?: Maybe<Scalars['String']>;
  meetingPasscode?: Maybe<Scalars['String']>;
};

export type OnboardManagementOutput = {
  __typename?: 'OnboardManagementOutput';
  actions: OnboardManagementActions;
  createdAt: Scalars['Timestamp'];
  id: Scalars['ID'];
  meetingInvitation?: Maybe<OnboardManagementMeetingInvitationOutput>;
  members: Array<FamilyOnboardMemberDetailsOutput>;
  referenceId: Scalars['String'];
  referenceType: OnboardReferenceType;
  state: FamilyOnboardStateEnum;
  updatedAt: Scalars['Timestamp'];
};

export enum OnboardManagementSdgConfiguratorStatusEnum {
  Completed = 'Completed',
  NotStarted = 'NotStarted',
  OptOut = 'OptOut'
}

export type OnboardManagementUpdateInvitationInput = {
  familyId: Scalars['ID'];
  meetingInvitation: OnboardManagementInvitationInput;
};

export enum OnboardManagementValueConfiguratorStatusEnum {
  Completed = 'Completed',
  InProgress = 'InProgress',
  NotStarted = 'NotStarted',
  OptOut = 'OptOut'
}

export enum OnboardMemberActionEnum {
  NotScheduled = 'NotScheduled',
  Scheduled = 'Scheduled',
  Sent = 'Sent'
}

export enum OnboardNotificationEnum {
  AdvisorProfileCompletedEmail = 'AdvisorProfileCompletedEmail',
  FamilyProfileCompletedEmail = 'FamilyProfileCompletedEmail',
  MeetingRescheduled = 'MeetingRescheduled',
  RsvpEmail = 'RsvpEmail',
  SdgConfiguratorCompletedEmail = 'SdgConfiguratorCompletedEmail',
  SdgConfiguratorInvitationEmail = 'SdgConfiguratorInvitationEmail'
}

export enum OnboardReferenceType {
  ExpertAdvisor = 'EXPERT_ADVISOR',
  Family = 'FAMILY'
}

export type OnboardUpdateMemberDetailsInput = {
  dateOfBirth: Scalars['DateTime'];
  email: Scalars['String'];
  honorific: Honorific;
  id: Scalars['ID'];
  phone: PhoneInput;
};

export type OrganisationalValuesCategoriesOutput = {
  __typename?: 'OrganisationalValuesCategoriesOutput';
  importance?: Maybe<OrganisationalValuesOutput>;
  relevance?: Maybe<OrganisationalValuesOutput>;
};

export type OrganisationalValuesInput = {
  adaptability: Scalars['Int'];
  leadership: Scalars['Int'];
  resilience: Scalars['Int'];
  transparency: Scalars['Int'];
};

export type OrganisationalValuesNormalizedOutput = {
  __typename?: 'OrganisationalValuesNormalizedOutput';
  adaptability: Scalars['Float'];
  leadership: Scalars['Float'];
  resilience: Scalars['Float'];
  transparency: Scalars['Float'];
};

export type OrganisationalValuesOutput = {
  __typename?: 'OrganisationalValuesOutput';
  adaptability: Scalars['Int'];
  leadership: Scalars['Int'];
  resilience: Scalars['Int'];
  transparency: Scalars['Int'];
};

/** An Objective represents an specific aim that the Family wishes to achieve. */
export type Organiser = {
  __typename?: 'Organiser';
  image?: Maybe<MediaOutput>;
  name: Scalars['String'];
  profile: Profile;
  title?: Maybe<Scalars['String']>;
};

export type OrganiserInput = {
  referenceId: Scalars['ID'];
  title?: InputMaybe<Scalars['String']>;
};

export type PaginatedAdvisorOutput = {
  __typename?: 'PaginatedAdvisorOutput';
  nodes: Array<AdvisorOutput>;
  offset: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PaginatedConversationOutput = {
  __typename?: 'PaginatedConversationOutput';
  nodes: Array<Conversation>;
  offset: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PaginatedExtendedProfileOutput = {
  __typename?: 'PaginatedExtendedProfileOutput';
  nodes: Array<ExtendedProfileOutput>;
  offset: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PaginatedFamilyCharterOutput = {
  __typename?: 'PaginatedFamilyCharterOutput';
  nodes: Array<FamilyCharter>;
  offset: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PaginatedFamilyOutput = {
  __typename?: 'PaginatedFamilyOutput';
  nodes: Array<Family>;
  offset: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PaginatedGroupOutput = {
  __typename?: 'PaginatedGroupOutput';
  nodes: Array<Group>;
  offset: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PaginatedInitiativeOutput = {
  __typename?: 'PaginatedInitiativeOutput';
  nodes: Array<InitiativeOutput>;
  offset: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PaginatedInsightOutput = {
  __typename?: 'PaginatedInsightOutput';
  nodes: Array<Insight>;
  offset: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PaginatedMembersToAddOutput = {
  __typename?: 'PaginatedMembersToAddOutput';
  nodes: Array<MemberToAddOutput>;
  offset: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PaginatedMessageOutput = {
  __typename?: 'PaginatedMessageOutput';
  nodes: Array<Message>;
  offset: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PaginatedNotificationUnionOutput = {
  __typename?: 'PaginatedNotificationUnionOutput';
  nodes: Array<NotificationUnionOutput>;
  offset: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalUnreadCount: Scalars['Int'];
};

export type PaginatedPostOutput = {
  __typename?: 'PaginatedPostOutput';
  nodes: Array<Post>;
  offset: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PaginatedProfileOutput = {
  __typename?: 'PaginatedProfileOutput';
  nodes: Array<Profile>;
  offset: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PaginatedTagOutput = {
  __typename?: 'PaginatedTagOutput';
  nodes: Array<TagOutput>;
  offset: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PaginationInput = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** Object representing a phone related data (number, country code, etc). */
export type Phone = {
  __typename?: 'Phone';
  countryCode: Scalars['String'];
  number: Scalars['String'];
};

export type PhoneInput = {
  countryCode: Scalars['String'];
  number: Scalars['String'];
};

export type PhoneOutput = {
  countryCode: Scalars['String'];
  number: Scalars['String'];
};

/** A Post is a text and media message that provides an updates to Groups, Events and Initiatives. */
export type Post = {
  __typename?: 'Post';
  appreciationCount: Scalars['Float'];
  comments: Array<Comment>;
  createdAt: Scalars['Timestamp'];
  createdBy: Scalars['String'];
  id: Scalars['ID'];
  images: Array<MediaOutput>;
  isSelfAppreciated: Scalars['Boolean'];
  link?: Maybe<Scalars['String']>;
  postedBy: Profile;
  referenceId?: Maybe<Scalars['String']>;
  subtype?: Maybe<Scalars['String']>;
  tags: Array<TagOutput>;
  text: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  type: PostType;
  updatedAt: Scalars['Timestamp'];
  updatedBy: Scalars['String'];
};

export type PostCreateInput = {
  link?: InputMaybe<Scalars['String']>;
  referenceId?: InputMaybe<Scalars['String']>;
  source: PostSource;
  text: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<PostType>;
};

export type PostFilters = {
  authorsRoles?: InputMaybe<Array<Role>>;
  createdBy?: InputMaybe<Scalars['ID']>;
  /** Limits posts only to from family member(true), outside family(false) or everyone(not set) */
  isFromFamily?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['String']>;
  referenceId?: InputMaybe<Scalars['String']>;
  source: PostSource;
  text?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<PostType>;
};

export type PostNotificationMetadata = NotificationMetadataOutput & {
  __typename?: 'PostNotificationMetadata';
  accountId: Scalars['ID'];
  postId?: Maybe<Scalars['ID']>;
  postInGroupId?: Maybe<Scalars['ID']>;
};

export type PostNotificationOutput = NotificationOutput & {
  __typename?: 'PostNotificationOutput';
  category: NotificationCategoryEnum;
  createdAt: Scalars['Timestamp'];
  id: Scalars['ID'];
  isRead: Scalars['Boolean'];
  metadata: PostNotificationMetadata;
  sender?: Maybe<Profile>;
  type: NotificationTypeEnum;
};

export enum PostSource {
  Event = 'Event',
  Forum = 'Forum',
  Group = 'Group',
  Initiative = 'Initiative'
}

export enum PostType {
  Advisor = 'Advisor',
  Event = 'Event',
  Family = 'Family',
  Group = 'Group',
  Initiative = 'Initiative',
  Itriom = 'Itriom'
}

export type PostUpdateInput = {
  id: Scalars['ID'];
  link?: InputMaybe<Scalars['String']>;
  referenceId?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<PostType>;
};

export type PrincipalAboutInput = {
  content?: InputMaybe<Scalars['String']>;
  familyId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  imagesIds: Array<Scalars['ID']>;
  isHeadOfFamily?: InputMaybe<Scalars['Boolean']>;
};

export type PrincipalAboutOutput = {
  __typename?: 'PrincipalAboutOutput';
  content?: Maybe<Scalars['String']>;
  images: Array<MediaOutput>;
};

export type PrincipalHeroInput = {
  accountId: Scalars['ID'];
  avatarRoundId?: InputMaybe<Scalars['ID']>;
  avatarSquareId: Scalars['ID'];
  bannerId: Scalars['ID'];
  id: Scalars['ID'];
  purpose: Scalars['String'];
  title: Scalars['String'];
};

export type PrincipalHeroOutput = {
  __typename?: 'PrincipalHeroOutput';
  /** If a round avatar does not exist in the database, the backend will return a square avatar */
  avatarRound: MediaOutput;
  avatarSquare: MediaOutput;
  banner: MediaOutput;
  profile?: Maybe<Profile>;
  purpose: Scalars['String'];
  title: Scalars['String'];
};

export type PrincipalHeroWithoutIdInput = {
  accountId: Scalars['ID'];
  avatarRoundId?: InputMaybe<Scalars['ID']>;
  avatarSquareId: Scalars['ID'];
  bannerId: Scalars['ID'];
  purpose: Scalars['String'];
  title: Scalars['String'];
};

export type PrincipalOutput = {
  __typename?: 'PrincipalOutput';
  about?: Maybe<PrincipalAboutOutput>;
  createdAt: Scalars['Timestamp'];
  createdBy: Scalars['String'];
  familyCharter?: Maybe<FamilyCharter>;
  hero: PrincipalHeroOutput;
  id: Scalars['ID'];
  isHeadOfFamily: Scalars['Boolean'];
  memberRelations?: Maybe<Array<FamilyMemberRelationOutput>>;
  privacySettings?: Maybe<PrincipalPrivacySettingsOutput>;
  status: PrincipalStatusEnum;
  tags?: Maybe<ItriomTagsV2Output>;
  updatedAt: Scalars['Timestamp'];
  updatedBy: Scalars['String'];
  values: Array<PrincipalValueOutput>;
};

export type PrincipalPrivacySettingsOutput = {
  __typename?: 'PrincipalPrivacySettingsOutput';
  connections: VisibilitySettingsEnum;
};

export enum PrincipalStatusEnum {
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
}

export type PrincipalTagsInput = {
  countryFocusTagId: Scalars['String'];
  esgFocus: EsgCategoryEnum;
  id: Scalars['ID'];
  sdGoalTagId: Scalars['String'];
};

export type PrincipalValueInput = {
  description: Scalars['String'];
  heading: Scalars['String'];
};

export type PrincipalValueOutput = {
  __typename?: 'PrincipalValueOutput';
  description: Scalars['String'];
  heading: Scalars['String'];
};

/** A Profile respresents the public information of an Itriom user. */
export type Profile = ProfileInterfaceOutput & {
  __typename?: 'Profile';
  avatarImage?: Maybe<MediaOutput>;
  canBeInvited: Scalars['Boolean'];
  connectionState: ProfileConnectionStateEnum;
  createdAt: Scalars['Timestamp'];
  description?: Maybe<Scalars['String']>;
  family?: Maybe<Family>;
  firstName: Scalars['String'];
  heroImage?: Maybe<MediaOutput>;
  honorific?: Maybe<Honorific>;
  id: Scalars['ID'];
  /** @deprecated Not used */
  isConnected: Scalars['Boolean'];
  lastName: Scalars['String'];
  principal?: Maybe<PrincipalOutput>;
  role: Role;
  status: AccountStatus;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Timestamp'];
};

export enum ProfileConnectionStateEnum {
  Connected = 'Connected',
  InvitationPending = 'InvitationPending',
  NotConnected = 'NotConnected'
}

export type ProfileFiltersInput = {
  familyId?: InputMaybe<Scalars['ID']>;
  includeOnlyOwnFamily?: InputMaybe<Scalars['Boolean']>;
  roles?: InputMaybe<Array<Role>>;
};

/** A Profile respresents the public information of an Itriom user. */
export type ProfileInterfaceOutput = {
  avatarImage?: Maybe<MediaOutput>;
  canBeInvited: Scalars['Boolean'];
  connectionState: ProfileConnectionStateEnum;
  createdAt: Scalars['Timestamp'];
  description?: Maybe<Scalars['String']>;
  family?: Maybe<Family>;
  firstName: Scalars['String'];
  heroImage?: Maybe<MediaOutput>;
  id: Scalars['ID'];
  /** @deprecated Not used */
  isConnected: Scalars['Boolean'];
  lastName: Scalars['String'];
  principal?: Maybe<PrincipalOutput>;
  status: AccountStatus;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Timestamp'];
};

export type Query = {
  __typename?: 'Query';
  account: Account;
  advisor: AdvisorOutput;
  advisorByRelatedAccountId: AdvisorOutput;
  /** @deprecated Use paginated version of the query! */
  advisorConnections: Array<Profile>;
  advisorConnectionsPaginated: PaginatedProfileOutput;
  allFamilyConnections: Array<Profile>;
  bannerEvents?: Maybe<Event>;
  bannerInitiative?: Maybe<InitiativeOutput>;
  bannerInsight?: Maybe<Insight>;
  cmsProfiles: PaginatedProfileOutput;
  comment: Comment;
  comments: Array<Comment>;
  connectionInvitations: Array<ConnectionInvitation>;
  conversation: Conversation;
  conversations: PaginatedConversationOutput;
  esgCategories: Array<EsgCategory>;
  esgCategory: EsgCategory;
  event: Event;
  /** @deprecated Not implemented */
  eventConnections: Array<Profile>;
  eventPublished: Event;
  events: EventPaginatedOutput;
  eventsPublishedPaginated: EventPaginatedOutput;
  extendedProfile: ExtendedProfileOutput;
  extendedProfiles: Array<ExtendedProfileOutput>;
  families: PaginatedFamilyOutput;
  familiesCharter: PaginatedFamilyCharterOutput;
  family: Family;
  familyCharter: FamilyCharter;
  familyCharterByFamilyId?: Maybe<FamilyCharter>;
  familyCharterForFamilyMember?: Maybe<FamilyCharter>;
  /** @deprecated Use paginated version of the query! */
  familyConnections: Array<Profile>;
  familyConnectionsPaginated: PaginatedProfileOutput;
  familyMemberRelations: Array<FamilyMemberRelationOutput>;
  familyPossibleMemberRelations: Array<FamilyMemberRelationOutput>;
  familySdgsChoices: FamilySdgConfiguratorOutput;
  familyValuesWithRelatedSdg: Array<FamilyValuesWithAvailableSdgOutput>;
  /** @deprecated Temporary deprecation */
  findProfiles: Array<Profile>;
  followedInitiatives: Array<InitiativeOutput>;
  getDownloadUrlForLibraryFile: Scalars['String'];
  getDownloadUrlForSharedLibraryFile: Scalars['String'];
  getLibraryDetails: LibraryDetails;
  getLibraryMediaFavorites: Array<LibraryMediaOutput>;
  getResourceDetailsInLibrary: LibraryMediaOutput;
  getResourcesFromDirectoryInLibrary: Array<LibraryMediaOutput>;
  getSharedResourceFromLibrary: LibrarySharedMediaOutput;
  getSharedResourcesFromLibrary: Array<LibrarySharedMediaOutput>;
  getTrashesFromLibrary: Array<LibraryMediaOutput>;
  group: Group;
  groupMembersPaginated: PaginatedProfileOutput;
  groups: Array<Group>;
  groupsByType: PaginatedGroupOutput;
  initiative: InitiativeOutput;
  /** @deprecated Not implemented */
  initiativeConnections: Array<Profile>;
  insight: Insight;
  isDemoBooked: Scalars['Boolean'];
  /** @deprecated Not implemented */
  itriomConnections: Array<Profile>;
  keyEvents: EventPaginatedOutput;
  listAdvisors: PaginatedAdvisorOutput;
  /** Pagination is turned off - always returns 500 tags */
  listAvailableTags: PaginatedTagOutput;
  listEditableAdvisors: PaginatedAdvisorOutput;
  listEditableInsights: PaginatedInsightOutput;
  listInitiatives: PaginatedInitiativeOutput;
  listInsights: PaginatedInsightOutput;
  media: MediaOutput;
  membersToAddPaginated: PaginatedMembersToAddOutput;
  messagesInConversation: PaginatedMessageOutput;
  minimalAccountDataByToken: MinimalAccountDataOutput;
  mutualConnections: PaginatedProfileOutput;
  newlyJoinedGroupMembers: Array<Profile>;
  notifications: PaginatedNotificationUnionOutput;
  onboardManagement: OnboardManagementOutput;
  onboardManagementByReference: OnboardManagementOutput;
  openGraphMetadata?: Maybe<LinkMetadata>;
  /** @deprecated Use paginated version of the query! */
  peerConnections: Array<Profile>;
  peerConnectionsPaginated: PaginatedProfileOutput;
  personalSdgsConfiguration: SdgConfiguratorOutput;
  post: Post;
  posts: PaginatedPostOutput;
  postsByReferenceId: PaginatedPostOutput;
  /** @deprecated Post are without type */
  postsByType: Array<Post>;
  principal: PrincipalOutput;
  principalByAccountId?: Maybe<PrincipalOutput>;
  profile: Profile;
  profiles: PaginatedProfileOutput;
  /** Returns "data.sampleSize" randomly selected recommended groups */
  randomRecommendedGroups: Array<Group>;
  /** @deprecated Use paginated version of the query! */
  recentConnections: Array<Profile>;
  recentConnectionsPaginated: PaginatedProfileOutput;
  recommendedConnections: PaginatedProfileOutput;
  recommendedInsights: PaginatedInsightOutput;
  sdgConfiguratorAvailableSdgs: Array<SdgOutput>;
  sdgs: Array<SdgOutput>;
  sdgsByReference: FamilySdgConfiguratorOutput;
  search: SearchOutput;
  signedUploadUrl: UploadSignedUrlOutput;
  /** Pagination currently is disable, query return all users */
  suggestedAgendaSpeakers: PaginatedProfileOutput;
  trendingGroups: Array<Group>;
  upcomingEvents: EventPaginatedOutput;
  usersThatBookedDemo: PaginatedExtendedProfileOutput;
  valueConfigurator: ValueConfiguratorOutput;
  valueConfiguratorCategoryNormalized: ValueConfiguratorNormalizedOutput;
  valueConfiguratorFamilyNormalized: ValueConfiguratorNormalizedOutput;
  valueConfiguratorNormalizedByReference: ValueConfiguratorNormalizedOutput;
  yourGroups: Array<Group>;
  yourMostActiveGroups: Array<Group>;
};


export type QueryAdvisorArgs = {
  id: Scalars['String'];
};


export type QueryAdvisorByRelatedAccountIdArgs = {
  id: Scalars['String'];
};


export type QueryAdvisorConnectionsPaginatedArgs = {
  accountId?: InputMaybe<Scalars['ID']>;
  pagination: PaginationInput;
};


export type QueryCmsProfilesArgs = {
  filters?: InputMaybe<CmsProfileFiltersInput>;
  pagination: PaginationInput;
};


export type QueryCommentArgs = {
  id: Scalars['String'];
};


export type QueryCommentsArgs = {
  referenceId: Scalars['String'];
};


export type QueryConversationArgs = {
  id: Scalars['String'];
};


export type QueryConversationsArgs = {
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryEsgCategoryArgs = {
  name: Scalars['String'];
};


export type QueryEventArgs = {
  id: Scalars['String'];
};


export type QueryEventPublishedArgs = {
  id: Scalars['String'];
};


export type QueryEventsArgs = {
  filters?: InputMaybe<EventFilters>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryEventsPublishedPaginatedArgs = {
  filters?: InputMaybe<EventsPublishedPaginatedFiltersInput>;
  pagination: PaginationInput;
};


export type QueryExtendedProfileArgs = {
  accountId: Scalars['String'];
};


export type QueryExtendedProfilesArgs = {
  familyId: Scalars['String'];
};


export type QueryFamiliesArgs = {
  filters?: InputMaybe<ListFamilyInput>;
  pagination: PaginationInput;
};


export type QueryFamiliesCharterArgs = {
  filters: ListFamilyCharterInput;
  pagination: PaginationInput;
};


export type QueryFamilyArgs = {
  id: Scalars['String'];
};


export type QueryFamilyCharterArgs = {
  id: Scalars['String'];
};


export type QueryFamilyCharterByFamilyIdArgs = {
  id: Scalars['String'];
};


export type QueryFamilyConnectionsPaginatedArgs = {
  accountId?: InputMaybe<Scalars['ID']>;
  pagination: PaginationInput;
};


export type QueryFamilyMemberRelationsArgs = {
  accountId: Scalars['String'];
};


export type QueryFamilyPossibleMemberRelationsArgs = {
  accountId: Scalars['String'];
};


export type QueryFindProfilesArgs = {
  searchString: Scalars['String'];
};


export type QueryGetDownloadUrlForLibraryFileArgs = {
  fileId: Scalars['String'];
  libraryType: LibraryTypeEnum;
};


export type QueryGetDownloadUrlForSharedLibraryFileArgs = {
  fileId: Scalars['String'];
};


export type QueryGetLibraryDetailsArgs = {
  libraryType: LibraryTypeEnum;
};


export type QueryGetResourceDetailsInLibraryArgs = {
  libraryType: LibraryTypeEnum;
  resourceId: Scalars['String'];
};


export type QueryGetResourcesFromDirectoryInLibraryArgs = {
  destinationId?: InputMaybe<Scalars['String']>;
  libraryType: LibraryTypeEnum;
};


export type QueryGetSharedResourceFromLibraryArgs = {
  resourceId: Scalars['String'];
};


export type QueryGetSharedResourcesFromLibraryArgs = {
  destinationId?: InputMaybe<Scalars['String']>;
};


export type QueryGetTrashesFromLibraryArgs = {
  libraryType: LibraryTypeEnum;
};


export type QueryGroupArgs = {
  id: Scalars['String'];
};


export type QueryGroupMembersPaginatedArgs = {
  data: FindGroupMembersInput;
  pagination: PaginationInput;
};


export type QueryGroupsByTypeArgs = {
  pagination: PaginationInput;
  type: Scalars['String'];
};


export type QueryInitiativeArgs = {
  id: Scalars['String'];
};


export type QueryInsightArgs = {
  id: Scalars['String'];
};


export type QueryIsDemoBookedArgs = {
  type: BookingTypeEnum;
};


export type QueryKeyEventsArgs = {
  pagination: PaginationInput;
};


export type QueryListAdvisorsArgs = {
  pagination: PaginationInput;
};


export type QueryListAvailableTagsArgs = {
  filters: ListAvailableTagsInput;
  pagination: PaginationInput;
};


export type QueryListEditableAdvisorsArgs = {
  filters: ListEditableAdvisorInput;
  pagination: PaginationInput;
};


export type QueryListEditableInsightsArgs = {
  filters: ListEditableInsightInput;
  pagination: PaginationInput;
};


export type QueryListInitiativesArgs = {
  filters?: InputMaybe<ListInitiativesFiltersInput>;
  pagination: PaginationInput;
};


export type QueryListInsightsArgs = {
  filters: ListInsightInput;
  pagination: PaginationInput;
};


export type QueryMediaArgs = {
  id: Scalars['String'];
};


export type QueryMembersToAddPaginatedArgs = {
  data: FindGroupMembersInput;
  pagination: PaginationInput;
};


export type QueryMessagesInConversationArgs = {
  conversationId: Scalars['String'];
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryMinimalAccountDataByTokenArgs = {
  token: Scalars['String'];
};


export type QueryMutualConnectionsArgs = {
  accountId: Scalars['String'];
  pagination: PaginationInput;
};


export type QueryNewlyJoinedGroupMembersArgs = {
  id: Scalars['String'];
  limit?: InputMaybe<Scalars['Float']>;
};


export type QueryNotificationsArgs = {
  category?: InputMaybe<NotificationCategoryEnum>;
  pagination: PaginationInput;
};


export type QueryOnboardManagementByReferenceArgs = {
  referenceId: Scalars['String'];
  referenceType: OnboardReferenceType;
};


export type QueryOpenGraphMetadataArgs = {
  url: Scalars['String'];
};


export type QueryPeerConnectionsPaginatedArgs = {
  accountId?: InputMaybe<Scalars['ID']>;
  pagination: PaginationInput;
};


export type QueryPostArgs = {
  id: Scalars['String'];
};


export type QueryPostsArgs = {
  filters?: InputMaybe<PostFilters>;
  pagination: PaginationInput;
};


export type QueryPostsByReferenceIdArgs = {
  pagination: PaginationInput;
  referenceId: Scalars['String'];
  source: PostSource;
};


export type QueryPrincipalArgs = {
  id: Scalars['String'];
};


export type QueryPrincipalByAccountIdArgs = {
  id: Scalars['String'];
};


export type QueryProfileArgs = {
  id: Scalars['String'];
};


export type QueryProfilesArgs = {
  filters?: InputMaybe<ProfileFiltersInput>;
  pagination: PaginationInput;
};


export type QueryRandomRecommendedGroupsArgs = {
  data: RandomRecommendedGroupsInput;
};


export type QueryRecentConnectionsPaginatedArgs = {
  pagination: PaginationInput;
};


export type QueryRecommendedConnectionsArgs = {
  pagination: PaginationInput;
  recommendationType: AccountRecommendationEnum;
};


export type QueryRecommendedInsightsArgs = {
  familyId?: InputMaybe<Scalars['String']>;
  matchingType: InsightsRecommendationEnum;
  pagination: PaginationInput;
  visibility?: InputMaybe<ListInsightVisibilityFiltersInput>;
};


export type QuerySdgsByReferenceArgs = {
  referenceId: Scalars['String'];
  referenceType: OnboardReferenceType;
};


export type QuerySearchArgs = {
  categories?: InputMaybe<Array<SearchCategoryEnum>>;
  pagination?: InputMaybe<PaginationInput>;
  phrase: Scalars['String'];
};


export type QuerySignedUploadUrlArgs = {
  fileType: Scalars['String'];
};


export type QueryUpcomingEventsArgs = {
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryUsersThatBookedDemoArgs = {
  pagination: PaginationInput;
  type: BookingTypeEnum;
};


export type QueryValueConfiguratorNormalizedByReferenceArgs = {
  referenceId: Scalars['String'];
  referenceType: OnboardReferenceType;
};

export type RandomRecommendedGroupsInput = {
  sampleSize: Scalars['Float'];
};

export enum Role {
  Admin = 'ADMIN',
  CharityManager = 'CHARITY_MANAGER',
  ExpertAdvisor = 'EXPERT_ADVISOR',
  FamilyAdmin = 'FAMILY_ADMIN',
  FamilyMember = 'FAMILY_MEMBER',
  HeadOfFamily = 'HEAD_OF_FAMILY',
  InitiativePrincipal = 'INITIATIVE_PRINCIPAL',
  InvestmentFundManager = 'INVESTMENT_FUND_MANAGER',
  InvestmentPrincipal = 'INVESTMENT_PRINCIPAL',
  ItriomCurator = 'ITRIOM_CURATOR',
  ItriomStaff = 'ITRIOM_STAFF',
  SystemAdmin = 'SYSTEM_ADMIN'
}

/** An SDG represents an official UN Sustainability Development Goal. */
export type Sdg = {
  __typename?: 'Sdg';
  createdAt: Scalars['Timestamp'];
  description: Scalars['String'];
  esgCategories?: Maybe<Array<Maybe<EsgCategory>>>;
  iconId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['Timestamp'];
};

export type SdgConfiguratorOutput = {
  __typename?: 'SdgConfiguratorOutput';
  sdgIdsWithNumericScores: Array<SdgIdWithNumericScoreOutput>;
};

export enum SdgConfiguratorStatusEnum {
  Completed = 'Completed',
  NotCompleted = 'NotCompleted',
  OptOut = 'OptOut'
}

export type SdgIdWithNumericScoreOutput = {
  __typename?: 'SdgIdWithNumericScoreOutput';
  numericScore: Scalars['Float'];
  sdgId: Scalars['String'];
};

export type SdgOutput = {
  __typename?: 'SdgOutput';
  createdAt: Scalars['Timestamp'];
  description: Scalars['String'];
  icon: Scalars['String'];
  id: Scalars['ID'];
  index: Scalars['Float'];
  internalName: Scalars['String'];
  name: Scalars['String'];
  updatedAt: Scalars['Timestamp'];
};

export enum SearchCategoryEnum {
  Connections = 'Connections',
  Events = 'Events',
  Initiatives = 'Initiatives',
  Insights = 'Insights',
  Library = 'Library'
}

export type SearchConnectionHighlightsOutput = {
  __typename?: 'SearchConnectionHighlightsOutput';
  firstName?: Maybe<Array<Scalars['String']>>;
  lastName?: Maybe<Array<Scalars['String']>>;
};

export type SearchConnectionOutput = {
  __typename?: 'SearchConnectionOutput';
  nodes: Array<SearchConnectionResultOutput>;
  totalCount: Scalars['Float'];
};

export type SearchConnectionResultOutput = {
  __typename?: 'SearchConnectionResultOutput';
  highlights: SearchConnectionHighlightsOutput;
  object: Profile;
};

export type SearchEventHighlightsOutput = {
  __typename?: 'SearchEventHighlightsOutput';
  agenda_agendaItems_description?: Maybe<Array<Scalars['String']>>;
  agenda_agendaItems_name?: Maybe<Array<Scalars['String']>>;
  agenda_description?: Maybe<Array<Scalars['String']>>;
  agenda_heading?: Maybe<Array<Scalars['String']>>;
  description?: Maybe<Array<Scalars['String']>>;
  hero_name?: Maybe<Array<Scalars['String']>>;
  name?: Maybe<Array<Scalars['String']>>;
  topics_description?: Maybe<Array<Scalars['String']>>;
};

export type SearchEventOutput = {
  __typename?: 'SearchEventOutput';
  nodes: Array<SearchEventResultOutput>;
  totalCount: Scalars['Float'];
};

export type SearchEventResultOutput = {
  __typename?: 'SearchEventResultOutput';
  highlights: SearchEventHighlightsOutput;
  object: Event;
};

export type SearchInitiativeHighlightsOutput = {
  __typename?: 'SearchInitiativeHighlightsOutput';
  blocks_heading?: Maybe<Array<Scalars['String']>>;
  blocks_optionalHeading?: Maybe<Array<Scalars['String']>>;
  blocks_text?: Maybe<Array<Scalars['String']>>;
  hero_headline?: Maybe<Array<Scalars['String']>>;
  hero_summary?: Maybe<Array<Scalars['String']>>;
};

export type SearchInitiativeOutput = {
  __typename?: 'SearchInitiativeOutput';
  nodes: Array<SearchInitiativeResultOutput>;
  totalCount: Scalars['Float'];
};

export type SearchInitiativeResultOutput = {
  __typename?: 'SearchInitiativeResultOutput';
  highlights: SearchInitiativeHighlightsOutput;
  object: InitiativeOutput;
};

export type SearchInsightHighlightsOutput = {
  __typename?: 'SearchInsightHighlightsOutput';
  blocks_heading?: Maybe<Array<Scalars['String']>>;
  blocks_optionalHeading?: Maybe<Array<Scalars['String']>>;
  blocks_text?: Maybe<Array<Scalars['String']>>;
  hero_author?: Maybe<Array<Scalars['String']>>;
  hero_headline?: Maybe<Array<Scalars['String']>>;
  hero_summary?: Maybe<Array<Scalars['String']>>;
};

export type SearchInsightOutput = {
  __typename?: 'SearchInsightOutput';
  nodes: Array<SearchInsightResultOutput>;
  totalCount: Scalars['Float'];
};

export type SearchInsightResultOutput = {
  __typename?: 'SearchInsightResultOutput';
  highlights: SearchInsightHighlightsOutput;
  object: Insight;
};

export type SearchLibraryHighlightsOutput = {
  __typename?: 'SearchLibraryHighlightsOutput';
  name?: Maybe<Array<Scalars['String']>>;
};

export type SearchLibraryOutput = {
  __typename?: 'SearchLibraryOutput';
  nodes: Array<SearchLibraryResultOutput>;
  totalCount: Scalars['Float'];
};

export type SearchLibraryResultOutput = {
  __typename?: 'SearchLibraryResultOutput';
  highlights: SearchLibraryHighlightsOutput;
  object: LibraryMediaOutput;
};

export type SearchOutput = {
  __typename?: 'SearchOutput';
  connections?: Maybe<SearchConnectionOutput>;
  events?: Maybe<SearchEventOutput>;
  initiatives?: Maybe<SearchInitiativeOutput>;
  insights?: Maybe<SearchInsightOutput>;
  library?: Maybe<SearchLibraryOutput>;
};

export enum SecondFactorVerificationTypeEnum {
  OnboardSignIn = 'OnboardSignIn',
  ResetPassword = 'ResetPassword',
  Rsvp = 'Rsvp'
}

export type SendOnboardNotificationInput = {
  isTest?: InputMaybe<Scalars['Boolean']>;
  notificationType: OnboardNotificationEnum;
  referenceId: Scalars['ID'];
  referenceType: OnboardReferenceType;
};

export type SetConfigStatusAsAdminInput = {
  accountId: Scalars['String'];
  sdgConfigStatus?: InputMaybe<SdgConfiguratorStatusEnum>;
  valueConfigStatus?: InputMaybe<ValueConfiguratorStatusEnum>;
};

export type SetNewPasswordWithTokenInput = {
  jwt: Scalars['String'];
  password: Scalars['String'];
};

export type SetPasswordForFirstTimeInput = {
  password: Scalars['String'];
};

export type SocietalValuesCategoriesOutput = {
  __typename?: 'SocietalValuesCategoriesOutput';
  importance?: Maybe<SocietalValuesOutput>;
  relevance?: Maybe<SocietalValuesOutput>;
};

export type SocietalValuesInput = {
  collaboration: Scalars['Int'];
  compassion: Scalars['Int'];
  empowerment: Scalars['Int'];
  equality: Scalars['Int'];
};

export type SocietalValuesNormalizedOutput = {
  __typename?: 'SocietalValuesNormalizedOutput';
  collaboration: Scalars['Float'];
  compassion: Scalars['Float'];
  empowerment: Scalars['Float'];
  equality: Scalars['Float'];
};

export type SocietalValuesOutput = {
  __typename?: 'SocietalValuesOutput';
  collaboration: Scalars['Int'];
  compassion: Scalars['Int'];
  empowerment: Scalars['Int'];
  equality: Scalars['Int'];
};

export type SpeakerOutput = {
  __typename?: 'SpeakerOutput';
  isKeySpeaker: Scalars['Boolean'];
  profile: Profile;
};

/** An Objective represents an specific aim that the Family wishes to achieve. */
export type Sponsor = {
  __typename?: 'Sponsor';
  image?: Maybe<MediaOutput>;
  isCompany: Scalars['Boolean'];
  name: Scalars['String'];
  profile?: Maybe<Profile>;
  showName: Scalars['Boolean'];
  title?: Maybe<Scalars['String']>;
};

export type SponsorInput = {
  imageId?: InputMaybe<Scalars['ID']>;
  isCompany?: InputMaybe<Scalars['Boolean']>;
  /** Required for company */
  name?: InputMaybe<Scalars['String']>;
  referenceId?: InputMaybe<Scalars['ID']>;
  showName?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};

export type SubmitPersonalSdgsInput = {
  ids: Array<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  messageSubscription: MessageNotification;
  notificationAdded: NotificationSubscriptionOutput;
};

/** A Tag is a term with a link that can be associated with various other entities. */
export type TagOutput = {
  __typename?: 'TagOutput';
  createdAt: Scalars['Timestamp'];
  id: Scalars['ID'];
  name: Scalars['String'];
  order: Scalars['Int'];
  type: TagType;
  updatedAt: Scalars['Timestamp'];
};

export enum TagType {
  Basic = 'Basic',
  CountryFocus = 'COUNTRY_FOCUS',
  Environmental = 'ENVIRONMENTAL',
  Governance = 'GOVERNANCE',
  Goal = 'Goal',
  Initiative = 'INITIATIVE',
  Insight = 'INSIGHT',
  RegionFocus = 'REGION_FOCUS',
  SdGoal = 'SD_GOAL',
  Social = 'SOCIAL',
  Sdg = 'Sdg'
}

export type TimezoneDateInput = {
  /** Remember to push here date that represent UTC+0! */
  datetime: Scalars['DateTime'];
  offset: Scalars['Float'];
  text: Scalars['String'];
};

export type TimezoneDateOutput = {
  __typename?: 'TimezoneDateOutput';
  /** This is datetime in UTC+0! */
  datetime: Scalars['DateTime'];
  offset: Scalars['Float'];
  text: Scalars['String'];
};

export type TokensOutput = {
  __typename?: 'TokensOutput';
  accessToken: Scalars['String'];
};

/** A Topic represents a main focus of the Event. */
export type Topic = {
  __typename?: 'Topic';
  description: Scalars['String'];
  heroImage?: Maybe<MediaOutput>;
};

export type TopicInput = {
  description: Scalars['String'];
  imageId?: InputMaybe<Scalars['ID']>;
};

export enum TriggeredCtaEnum {
  Help = 'HELP',
  Join = 'JOIN'
}

export type TriggeredCtaInput = {
  content: Scalars['String'];
  id: Scalars['ID'];
  type: TriggeredCtaEnum;
};

export type TriggeredCtaOutput = {
  __typename?: 'TriggeredCtaOutput';
  content: Scalars['String'];
  createdAt: Scalars['Timestamp'];
  createdBy: Scalars['String'];
  id: Scalars['ID'];
  profile: Profile;
  updatedAt: Scalars['Timestamp'];
};

export type UltimateGoalInput = {
  description: Scalars['String'];
  id: Scalars['ID'];
  imageId?: InputMaybe<Scalars['ID']>;
  sdIcon: Scalars['String'];
  title: Scalars['String'];
};

export type UltimateGoalOutput = {
  __typename?: 'UltimateGoalOutput';
  description: Scalars['String'];
  image?: Maybe<MediaOutput>;
  sdIcon: Scalars['String'];
  title: Scalars['String'];
};

export type UpdateAdvisorAwardsInput = {
  awards?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type UpdateEventOrganisersInput = {
  id: Scalars['ID'];
  organisers?: InputMaybe<Array<OrganiserInput>>;
};

export type UpdateFamilyCharterGoalsInput = {
  goals: Array<FamilyCharterGoalInput>;
  id: Scalars['ID'];
};

export type UpdateFamilyCharterHeroInput = {
  hero: FamilyCharterHero;
  id: Scalars['ID'];
};

export type UpdateInitiativeBlocksInput = {
  fullMediaBlocks?: InputMaybe<Array<InitiativeFullMediaBlockInput>>;
  galleryBlocks?: InputMaybe<Array<InitiativeGalleryBlockInput>>;
  headingBlocks?: InputMaybe<Array<InitiativeHeadingBlockInput>>;
  id: Scalars['ID'];
  leftTextBlocks?: InputMaybe<Array<InitiativeLeftTextBlockInput>>;
  richTextBlocks?: InputMaybe<Array<InitiativeRichTextBlockInput>>;
  rightTextBlocks?: InputMaybe<Array<InitiativeRightTextBlockInput>>;
};

export type UpdateInitiativeRelatedArticlesInput = {
  id: Scalars['ID'];
  relatedArticles?: InputMaybe<Array<Scalars['ID']>>;
};

export type UpdateInsightBlocksInput = {
  fullMediaBlocks?: InputMaybe<Array<InsightFullMediaBlockInput>>;
  galleryBlocks?: InputMaybe<Array<InsightGalleryBlockInput>>;
  headingBlocks?: InputMaybe<Array<InsightHeadingBlockInput>>;
  id: Scalars['ID'];
  leftTextBlocks?: InputMaybe<Array<InsightLeftTextBlockInput>>;
  quoteBlocks?: InputMaybe<Array<InsightQuoteBlockInput>>;
  richTextBlocks?: InputMaybe<Array<InsightRichTextBlockInput>>;
  rightTextBlocks?: InputMaybe<Array<InsightRightTextBlockInput>>;
};

export type UpdateInsightRelatedArticlesInput = {
  id: Scalars['ID'];
  relatedArticles?: InputMaybe<Array<Scalars['ID']>>;
};

export type UpdateMemberRelationInput = {
  memberId: Scalars['ID'];
  relatedMemberId: Scalars['ID'];
  relationType?: InputMaybe<FamilyRelationTypeEnum>;
};

export type UpdatePasswordInput = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type UpdatePrincipalPrivacySettingsInput = {
  connections: VisibilitySettingsEnum;
  id: Scalars['ID'];
};

export type UpdatePrincipalValuesInput = {
  id: Scalars['ID'];
  values: Array<PrincipalValueInput>;
};

export type UpdateSponsorsInput = {
  id: Scalars['ID'];
  sponsors: Array<SponsorInput>;
};

export type UpdateTopicsInput = {
  id: Scalars['ID'];
  topics: Array<TopicInput>;
};

export type UploadSignedUrlOutput = {
  __typename?: 'UploadSignedUrlOutput';
  signedUrl: Scalars['String'];
  temporaryFileKey: Scalars['String'];
};

export enum ValueConfiguratorCategoryEnum {
  Importance = 'Importance',
  Relevance = 'Relevance'
}

export type ValueConfiguratorInput = {
  advancementValues?: InputMaybe<AdvancementValuesInput>;
  contributionValues?: InputMaybe<ContributionValuesInput>;
  organisationalValues?: InputMaybe<OrganisationalValuesInput>;
  societalValues?: InputMaybe<SocietalValuesInput>;
  valuesCategory: ValueConfiguratorCategoryEnum;
};

export type ValueConfiguratorNormalizedOutput = {
  __typename?: 'ValueConfiguratorNormalizedOutput';
  advancementValues?: Maybe<AdvancementValuesNormalizedOutput>;
  contributionValues?: Maybe<ContributionValuesNormalizedOutput>;
  organisationalValues?: Maybe<OrganisationalValuesNormalizedOutput>;
  societalValues?: Maybe<SocietalValuesNormalizedOutput>;
};

export type ValueConfiguratorOutput = {
  __typename?: 'ValueConfiguratorOutput';
  advancementValues?: Maybe<AdvancementValuesCategoriesOutput>;
  contributionValues?: Maybe<ContributionValuesCategoriesOutput>;
  organisationalValues?: Maybe<OrganisationalValuesCategoriesOutput>;
  societalValues?: Maybe<SocietalValuesCategoriesOutput>;
  status: ValueConfiguratorStatusEnum;
};

export enum ValueConfiguratorStatusEnum {
  Completed = 'Completed',
  ImportanceFinished = 'ImportanceFinished',
  InProgress = 'InProgress',
  NotCompleted = 'NotCompleted',
  OptOut = 'OptOut'
}

export enum VerificationCodeSendLocation {
  Email = 'EMAIL',
  Sms = 'SMS'
}

export enum VisibilitySettingsEnum {
  Everyone = 'EVERYONE',
  None = 'NONE'
}
