import { Divider, Flex } from '@chakra-ui/react';

import { useCurrentLibraryLocation } from 'features/Library/hooks/useCurrentLibraryLocation';
import { LibraryLocation } from 'features/Library/types';

import { Actions } from './Actions';
import { FavouritesFilter } from './FavouritesFilter';
import { NewFolder } from './NewFolder';
import { Sort } from './Sort';

export const ActionBar = () => {
  const { libraryLocation } = useCurrentLibraryLocation();
  const isFavoritesLibrary = libraryLocation === LibraryLocation.Favourites;

  return (
    <Flex
      alignItems="center"
      bgColor="ui.white"
      borderWidth="1px"
      borderColor="ui.grey4"
      h="3rem"
      borderRadius={2}
    >
      {!isFavoritesLibrary && (
        <>
          <NewFolder />
          <Divider orientation="vertical" />
          <FavouritesFilter />
          <Divider orientation="vertical" />
        </>
      )}
      <Actions />
      <Sort />
    </Flex>
  );
};
