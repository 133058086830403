import { useTranslation } from 'react-i18next';

import { VStack, Text } from '@chakra-ui/react';

import { Profile } from 'types';

import { ConnectionTab } from '../ConnectionTabs/types';
import { ConnectionListItem } from './ConnectionListItem';

interface Props {
  connections: Profile[];
  type: ConnectionTab;
}

export const ConnectionList = ({ connections, type }: Props) => {
  const { t } = useTranslation(['connections']);
  if (!connections) return null;

  return (
    <VStack align="flex-start" spacing={0} pt={8}>
      {connections.length === 0 ? (
        <Text>{t('connections:no-connection-of-type', { type })}</Text>
      ) : (
        connections.map((connection) => (
          <ConnectionListItem
            connection={connection}
            key={connection.id}
            type={type}
          />
        ))
      )}
    </VStack>
  );
};
