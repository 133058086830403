import { InfiniteList } from 'components/InfiniteList';
import { useDeleteConversation } from 'features/Messaging/hooks/useDeleteConversation';
import { useLoadMoreConversations } from 'features/Messaging/hooks/useLoadMoreConversations';
import { Conversation as ConversationType } from 'types';

import {
  useConversationsActions,
  useConversationsSelectors,
  useMessagesSelectors,
} from '../../MessagingStateManager/hooks';
import { Conversation } from './Conversation';

export const ConversationsInfiniteList = (): JSX.Element => {
  const {
    selectHasMoreConversations,
    selectConversations,
    selectActiveConversation,
    selectUnreadConversationIds,
  } = useConversationsSelectors();

  const { handleConversationSelect } = useConversationsActions();

  const { selectMessagesForConversation } = useMessagesSelectors();

  const loadMoreConversations = useLoadMoreConversations();

  const conversations = selectConversations();
  const hasMore = selectHasMoreConversations();
  const activeConversation = selectActiveConversation();
  const unreadConversationIds = selectUnreadConversationIds();

  const getFirstMessageForConversation = (conversation: ConversationType) =>
    selectMessagesForConversation(conversation.id)[0] ||
    conversation.messages[0];

  const deleteConversation = useDeleteConversation();

  const handleSetActive = (conversationId: string) =>
    handleConversationSelect(conversationId);

  const sortConversations = (
    a: ConversationType,
    b: ConversationType
  ): number => {
    const aFirstMessage = getFirstMessageForConversation(a);
    const bFirstMessage = getFirstMessageForConversation(b);

    if (!aFirstMessage || !bFirstMessage) return 0;

    return bFirstMessage.createdAt - aFirstMessage.createdAt;
  };

  return (
    <InfiniteList
      hasMoreItems={hasMore}
      items={conversations}
      loadMoreItems={loadMoreConversations}
      wrapperProps={{ maxH: '560px' }}
      sort={sortConversations}
      renderItem={(conversation) => (
        <Conversation
          conversation={conversation}
          firstMessage={getFirstMessageForConversation(conversation)}
          handleDelete={() => deleteConversation(conversation.id)}
          handleSetActive={() => handleSetActive(conversation.id)}
          isActive={activeConversation?.id === conversation.id}
          isUnread={unreadConversationIds.includes(conversation.id)}
          key={conversation.id}
        />
      )}
    />
  );
};
