import { Button, Tooltip } from '@chakra-ui/react';

import { ITRIOM_EMAIL_SUPPORT } from 'constants/itriom';

export const SupportEmailButton = () => (
  <Button
    variant="link"
    mx={1}
    fontSize="xs"
    as="a"
    href={`mailto:${ITRIOM_EMAIL_SUPPORT}`}
    textDecoration="underline"
    m={0}
  >
    <Tooltip label={ITRIOM_EMAIL_SUPPORT} aria-label="itriom support">
      Itriom support
    </Tooltip>
  </Button>
);
