import { Flex } from '@chakra-ui/layout';
import {
  AspectRatio,
  Box,
  ListItem,
  OrderedList,
  Text,
} from '@chakra-ui/react';

import { SdgIcon } from 'components/Icons/SdgIcon';
import { SGDS_OVERVIEW } from 'constants/Sdgs';
import { SdgOutput } from 'types';
import { SdgInternalName } from 'types/Sdg';

type Props = {
  sdg: SdgOutput;
  isHorizontal?: boolean;
};

export const SdgCard = ({ sdg, isHorizontal = false }: Props) => {
  const { description, keyTargets, color } =
    SGDS_OVERVIEW[sdg.internalName as SdgInternalName];

  const { internalName, index } = sdg;

  const direction = isHorizontal ? 'row' : 'column';

  const maxW = isHorizontal
    ? { lg: 352 + 320, md: 352 + 160 }
    : { lg: 320, md: 560 };

  const ratio = isHorizontal ? undefined : { lg: 1, md: 7 / 4 };

  const objectFit = isHorizontal ? 'cover' : 'inherit';

  const imageW = isHorizontal ? { lg: 320, md: 160 } : 'full';

  const contentW = isHorizontal ? 352 : 320;

  return (
    <Flex direction={direction} bg="ui.white" maxW={maxW} h="full">
      <AspectRatio ratio={ratio} w={imageW}>
        <Box position="relative">
          <SdgIcon
            name={internalName}
            variant="bgImage"
            additionalStyling={{ w: 'full', h: 'full', objectFit }}
          />

          <SdgIcon
            name={internalName}
            variant="bgImage"
            additionalStyling={{
              w: 128,
              left: 4,
              bottom: 4,
              position: 'absolute',
            }}
          />
        </Box>
      </AspectRatio>

      <Box w={contentW} bgColor={color} p={6} h="inherit" color="ui.white">
        <Text size="p2b" mb={2}>
          Goal {index}
        </Text>
        <Text size="p2" mb={6}>
          {description}
        </Text>
        <Text size="p2b" mb={2}>
          Key targets
        </Text>
        <OrderedList>
          {keyTargets.map((keyTarget) => (
            <ListItem key={keyTarget}>
              <Text size="p2">{keyTarget}</Text>
            </ListItem>
          ))}
        </OrderedList>
      </Box>
    </Flex>
  );
};
