/* eslint-disable no-nested-ternary */
import { ReactNode } from 'react';

import { Flex, Heading, StackDivider } from '@chakra-ui/react';

import { Error } from 'components/Error';
import { Loader } from 'components/Loader';

type Props = {
  title?: string;
  subtitle?: string;
  children?: ReactNode;
  loading?: boolean;
  error?: string;
};

const Content = ({ title, subtitle, children, loading, error }: Props) => {
  if (loading) return <Loader />;
  if (error) return <Error title={error} />;

  return (
    <>
      {title && <Heading size="h3">{title}</Heading>}
      {subtitle && (
        <>
          <Heading size="h5" mt={14}>
            {subtitle}
          </Heading>
          <StackDivider
            borderColor="primary.blue.default"
            borderBottomWidth={3}
            w={14}
            h={4}
            mb={10}
          />
        </>
      )}
      {children}
    </>
  );
};

export const RightBlock = (props: Props) => {
  return (
    <Flex w={400} minW={350} bgColor="ui.white" p={10} direction="column">
      <Content {...props} />
    </Flex>
  );
};
