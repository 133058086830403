import { ReactElement } from 'react';

import {
  Network_1 as NetworkIcon,
  UserMultiple,
} from '@carbon/icons-react/next';
import { Grid, Text, Box } from '@chakra-ui/react';

type Props = {
  isFamilyGroup?: boolean;
};

type GroupTag = {
  name: string;
  description: string;
  icon: ReactElement;
};

const FAMILY_GROUP: GroupTag = {
  name: 'Family only',
  description: 'Restrict this group to family members only',
  icon: <UserMultiple size={32} />,
};

const PUBLIC_GROUP: GroupTag = {
  name: 'Public',
  description: 'Allow anyone on the entire Itriom platform to join',
  icon: <NetworkIcon size={32} />,
};

export const GroupTypeTag = ({ isFamilyGroup }: Props) => {
  const { name, description, icon } = isFamilyGroup
    ? FAMILY_GROUP
    : PUBLIC_GROUP;

  return (
    <Grid gridTemplateColumns="auto 5fr" w="340px" mt={2}>
      <Box m="auto">{icon}</Box>
      <Box ml={2}>
        <Text size="p1b">{name}</Text>
        <Text size="p3">{description}</Text>
      </Box>
    </Grid>
  );
};
