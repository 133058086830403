import { Heading } from '@chakra-ui/react';

type Props = {
  heading?: string;
};

export const ArticleHeading = ({ heading }: Props) => {
  if (!heading) return null;

  return <Heading size="h5">{heading}</Heading>;
};
