import { Link } from 'react-router-dom';

import { Button, Flex, Text } from '@chakra-ui/react';

import { RightBlock } from 'components/Layout/RightBlock';
import { SupportEmailButton } from 'components/SupportEmailButton';
import { Links } from 'router/links';

export const ConfirmationPasswordResetPage = () => {
  return (
    <RightBlock title="Forgot Password">
      <Flex height="100%" justifyContent="center" direction="column">
        <Text size="p1" mb={10}>
          Your password has been successfully reset.
        </Text>
        <Button
          bgColor="primary.lime.default"
          color="primary.blue.default"
          variant="primaryLime"
          as={Link}
          to={Links.home}
          borderRadius={2}
          h={12}
          px={6}
          type="submit"
          alignSelf="flex-start"
        >
          <Text size="p1">Go to platform</Text>
        </Button>
      </Flex>
      <Text size="p3" mt="auto">
        Please contact <SupportEmailButton /> for technical issues or if you
        need further assistance.
      </Text>
    </RightBlock>
  );
};
