import { Box, Flex } from '@chakra-ui/react';

import { ButtonsGroup } from 'components/Button/ButtonsGroup';
import { Links } from 'router/links';
import { ValueCategoryName } from 'types/ValuesConfigurator';

import { ValuesIntroLayout } from '../../components/Layout';
import {
  BubbleStepper,
  ImportanceToRelevance,
} from '../../components/ValuesConfigurator';

const buttons = (
  <ButtonsGroup
    rightButton={{
      link: `${Links.valuesConfiguratorRelevance}/${ValueCategoryName.Organisational}`,
    }}
  />
);

export const ImportanceToRelevancePage = () => {
  return (
    <ValuesIntroLayout height="full" buttons={buttons}>
      <Flex
        direction="column"
        w="full"
        h="full"
        justify="center"
        align="center"
      >
        <Box ml="auto" mb="auto">
          <BubbleStepper />
        </Box>
        <ImportanceToRelevance />
      </Flex>
    </ValuesIntroLayout>
  );
};
