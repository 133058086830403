export const getBgDropColor = (
  isDirectory: boolean,
  canDrop: boolean,
  isOver: boolean
) => {
  if ((isOver || canDrop) && isDirectory) {
    return 'status.neutral.tint';
  }

  return 'transparent';
};
