import { Timezone as TimezoneType } from 'utils/date';

export enum FieldName {
  Date = 'date',
  Time = 'time',
  Timezone = 'timezone',
  Location = 'location',
  MeetingLink = 'meetingLink',
  MeetingId = 'meetingId',
  MeetingPasscode = 'meetingPasscode',
}

export type FormType = {
  [FieldName.Date]: string;
  [FieldName.Time]: string;
  [FieldName.Timezone]: TimezoneType | null;
  [FieldName.Location]: string;
  [FieldName.MeetingId]: string;
  [FieldName.MeetingLink]: string;
  [FieldName.MeetingPasscode]: string;
};
