import { CreateUserFormFields, UpdateUserFormFields } from 'features/Cms/types';
import { AvailableAccountTypes, ExtendedProfileOutput, Honorific } from 'types';

export const useEditUserInitialValues = (
  extendedProfile: ExtendedProfileOutput
): CreateUserFormFields & UpdateUserFormFields => {
  const {
    email,
    firstName,
    lastName,
    phone,
    honorific,
    family,
    isFamilyAdmin,
    isHeadOfFamily,
    dateOfBirth,
  } = extendedProfile;

  const getType = () => {
    if (isHeadOfFamily) return AvailableAccountTypes.HeadOfFamily;
    if (isFamilyAdmin) return AvailableAccountTypes.Admin;

    return AvailableAccountTypes.FamilyMember;
  };

  const formattedDateOfBirth = dateOfBirth?.split('T')[0];

  return {
    email,
    firstName,
    isFamilyAdmin,
    isHeadOfFamily,
    honorific: honorific || Honorific.Mx,
    lastName,
    type: getType(),
    dateOfBirth: formattedDateOfBirth ?? '',
    familyId: family?.id || '',
    familyName: family?.name || '',
    phone,
  };
};
