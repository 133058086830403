import { Links } from 'router/links';
import { generateUrlById } from 'utils/links';

import { GridInsight } from './types';

export const getTilePropsFromInsight = (insight: GridInsight) => {
  return {
    image: insight.image,
    title: insight.heading,
    description: insight.summary,
    tags: insight.tags,
    link: generateUrlById(Links.insight, insight.id),
  };
};
