import React from 'react';

import { Conversation, Message } from 'types';

import { MessagesActionsUnion, MessagesActionTypes } from './types';

export const composeMessagesActions = (
  dispatch: React.Dispatch<MessagesActionsUnion>
) => ({
  addMessage: (message: Message) =>
    dispatch({
      type: MessagesActionTypes.AddMessage,
      payload: message,
    }),

  deleteMessage: (message: Message) =>
    dispatch({ type: MessagesActionTypes.DeleteMessage, payload: message }),

  deleteAllMessages: (conversationId: string) =>
    dispatch({
      type: MessagesActionTypes.DeleteAllMessages,
      payload: { conversationId },
    }),

  setMessages: (conversationId: Conversation['id'], messages: Message[]) =>
    dispatch({
      type: MessagesActionTypes.SetMessages,
      payload: { messages, conversationId },
    }),

  setMessagesTotalCount: (
    conversationId: Conversation['id'],
    messagesTotalCount: number
  ) =>
    dispatch({
      type: MessagesActionTypes.SetMessagesTotalCount,
      payload: { conversationId, messagesTotalCount },
    }),
});
