import { gql } from '@apollo/client';

import { usePaginatedQuery } from 'hooks/pagination';
import { Query, QueryMessagesInConversationArgs } from 'types';
import { MessageDeepNestingFragment } from 'types/generated-fragments';

import {
  MESSAGING_DEFAULT_LIMIT,
  MESSAGING_DEFAULT_OFFSET,
} from '../constants';

export const getMessagesInConversation = gql`
  ${MessageDeepNestingFragment}
  query MessagesInConversation(
    $conversationId: String!
    $pagination: PaginationInput
  ) {
    messagesInConversation(
      conversationId: $conversationId
      pagination: $pagination
    ) {
      nodes {
        ...MessageDeepNestingFragment
      }
      offset
      totalCount
    }
  }
`;

export const useLoadMessagesForConversation = () => {
  const { initialLoad, loadMore } = usePaginatedQuery<
    Pick<Query, 'messagesInConversation'>,
    QueryMessagesInConversationArgs
  >(getMessagesInConversation, {
    limit: MESSAGING_DEFAULT_LIMIT,
    offset: MESSAGING_DEFAULT_OFFSET,
  });

  const loadMessagesForConversation = (conversationId: string) =>
    initialLoad({ conversationId }).then(
      (result) => result.messagesInConversation
    );

  const loadMoreMessagesForConversation = (conversationId: string) =>
    loadMore({ conversationId }).then(
      (result) => result.messagesInConversation
    );

  return { loadMessagesForConversation, loadMoreMessagesForConversation };
};
