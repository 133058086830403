import { Flex, Image } from '@chakra-ui/react';

import { MediaOutput } from 'types';

export const ProfileImageCard = ({ item }: { item: MediaOutput }) => {
  const { id, signedUrl } = item;

  return (
    <Flex
      key={id}
      _hover={{ '& img': { transform: 'scale(1.05)' } }}
      overflow="hidden"
    >
      <Image
        src={signedUrl}
        w={308}
        h={243}
        objectFit="cover"
        objectPosition="center"
        transition="transform ease-out 0.2s"
      />
    </Flex>
  );
};
