import { Flex } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/react';

import { useAcceptConnectionInvitation } from 'gql/connections/mutation/acceptConnectionInvitation';
import { useRejectConnectionInvitation } from 'gql/connections/mutation/rejectConnectionInvitation';
import { ConnectionSentMetadata } from 'types';

import { buttonActionStyles } from '../utils';

type Props = {
  metadata: ConnectionSentMetadata;
};

export const ConnectionActions = ({ metadata }: Props) => {
  const { invitationId, actionStatus } = metadata;
  const { acceptConnectionInvitation } = useAcceptConnectionInvitation();
  const { rejectConnectionInvitation } = useRejectConnectionInvitation();

  const handleAcceptInvitation = () => acceptConnectionInvitation(invitationId);
  const handleRejectInvitation = () => rejectConnectionInvitation(invitationId);

  if (actionStatus) return null;

  return (
    <Flex gap={2} mt={1}>
      <Button onClick={handleAcceptInvitation} {...buttonActionStyles}>
        Accept
      </Button>
      <Button onClick={handleRejectInvitation} {...buttonActionStyles}>
        Decline
      </Button>
    </Flex>
  );
};
