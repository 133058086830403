import { getUpdateFileValue } from 'utils/file';

import { CommentFormBase } from './CommentFormBase';
import { CommentFormConfigProps, FormValues } from './types';
import { useCreateComment } from './useCreateComment';

export type Props = CommentFormConfigProps & {
  referenceId: string;
};

export const NewCommentForm = ({
  referenceId,
  ...baseProps
}: Props): JSX.Element => {
  const { createComment } = useCreateComment();

  const onSubmit = async (values: FormValues) => {
    await createComment({
      comment: {
        text: values.text,
        referenceId,
      },
      images: getUpdateFileValue(values.upload),
    });
  };

  const initialValues = { text: '', upload: undefined };

  return (
    <CommentFormBase
      initialValues={initialValues}
      handleSubmit={onSubmit}
      {...baseProps}
    />
  );
};
