import { MenuItem } from '@chakra-ui/react';

export type Props = {
  text: string;
  handleClick: () => void;
};

export const ConnectMenuButton = ({ handleClick, text }: Props) => (
  <MenuItem onClick={handleClick} color="primary.blue.default">
    {text}
  </MenuItem>
);
