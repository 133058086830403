import { gql } from '@apollo/client';

export const getResourcesFromDirectoryInLibraryGql = gql`
  query GetResourcesFromDirectoryInLibrary(
    $libraryType: LibraryTypeEnum!
    $destinationId: String
  ) {
    getResourcesFromDirectoryInLibrary(
      libraryType: $libraryType
      destinationId: $destinationId
    ) {
      cdnKey
      createdAt
      isMovable
      isRemovable
      isRenamable
      isShareable
      resources {
        id
        createdAt
        updatedAt
        path
        name
        mimeType
        isDirectory
        libraryType
        libraryOwner
        cdnKey
        resolvedPath {
          name
        }
      }
      id
      isDirectory
      libraryType
      mimeType
      name
      libraryOwner
      isFavorite
      path
      size
      shares {
        id
        firstName
        lastName
        title
        description
        isConnected
        connectionState
        canBeInvited
        honorific
        role
        heroImage {
          signedUrl
        }
      }
      resolvedPath {
        name
        isDirectory
      }
      updatedAt
    }
  }
`;

export const qetResourceDetailsInLibraryGql = gql`
  query GetResourceDetailsInLibrary(
    $libraryType: LibraryTypeEnum!
    $resourceId: String!
  ) {
    getResourceDetailsInLibrary(
      libraryType: $libraryType
      resourceId: $resourceId
    ) {
      id
      createdAt
      updatedAt
      path
      name
      mimeType
      isMovable
      isRemovable
      isRenamable
      isShareable
      isDirectory
      libraryType
      size
      shares {
        id
        firstName
        lastName
        title
        description
        isConnected
        connectionState
        canBeInvited
        honorific
        role
        heroImage {
          signedUrl
        }
      }
      libraryOwner
      cdnKey
      resolvedPath {
        id
        createdAt
        updatedAt
        path
        name
        mimeType
        isDirectory
        libraryType
        libraryOwner
        cdnKey
        isFavorite
      }
      resources {
        id
        createdAt
        updatedAt
        path
        name
        mimeType
        isDirectory
        libraryType
        libraryOwner
        cdnKey
        isFavorite
      }
      isFavorite
    }
  }
`;

export const getDownloadUrlForLibraryFileGql = gql`
  query Query($libraryType: LibraryTypeEnum!, $fileId: String!) {
    getDownloadUrlForLibraryFile(libraryType: $libraryType, fileId: $fileId)
  }
`;

export const getDownloadUrlForSharedLibraryFileGql = gql`
  query Query($fileId: String!) {
    getDownloadUrlForSharedLibraryFile(fileId: $fileId)
  }
`;

export const getLibraryMediaFavorites = gql`
  query GetLibraryMediaFavorites {
    getLibraryMediaFavorites {
      id
      createdAt
      updatedAt
      path
      name
      isMovable
      isRemovable
      isRenamable
      isShareable
      mimeType
      isDirectory
      libraryType
      libraryOwner
      size
      cdnKey
      shares {
        id
        firstName
        lastName
        title
        description
        isConnected
        connectionState
        canBeInvited
        honorific
        role
        heroImage {
          signedUrl
        }
      }
      resolvedPath {
        id
        createdAt
        updatedAt
        path
        name
        mimeType
        isDirectory
        libraryType
        libraryOwner
        cdnKey
        isFavorite
      }
      resources {
        id
        createdAt
        updatedAt
        path
        name
        mimeType
        isDirectory
        libraryType
        libraryOwner
        cdnKey
        isFavorite
      }
      isFavorite
    }
  }
`;

export const getLibraryDetailsGql = gql`
  query GetLibraryDetails($libraryType: LibraryTypeEnum!) {
    getLibraryDetails(libraryType: $libraryType) {
      maximumSize
      usedSize
      maximumSizePerFile
    }
  }
`;

export const getTrashesFromLibraryGql = gql`
  query GetTrashesFromLibrary($libraryType: LibraryTypeEnum!) {
    getTrashesFromLibrary(libraryType: $libraryType) {
      id
      createdAt
      updatedAt
      path
      name
      isMovable
      isRemovable
      isRenamable
      isShareable
      mimeType
      isDirectory
      libraryType
      libraryOwner
      cdnKey
      size
      removedAt
      resolvedPath {
        id
        createdAt
        updatedAt
        path
        name
        mimeType
        isDirectory
        libraryType
        libraryOwner
        cdnKey
        size
        removedAt
        isFavorite
      }
      resources {
        id
        createdAt
        updatedAt
        path
        name
        mimeType
        isDirectory
        libraryType
        libraryOwner
        cdnKey
        size
        removedAt
        isFavorite
      }
      isFavorite
    }
  }
`;

export const getSharedResourcesFromLibrary = gql`
  query GetSharedResourcesFromLibrary($destinationId: String) {
    getSharedResourcesFromLibrary(destinationId: $destinationId) {
      id
      createdAt
      updatedAt
      path
      name
      mimeType
      isDirectory
      libraryType
      libraryOwner
      cdnKey
      size
      removedAt
      resolvedPath {
        id
        createdAt
        updatedAt
        path
        name
        mimeType
        isDirectory
        libraryType
        libraryOwner
        cdnKey
        size
        removedAt
        isFavorite
      }
      resources {
        id
        createdAt
        updatedAt
        path
        name
        mimeType
        isDirectory
        libraryType
        libraryOwner
        cdnKey
        size
        removedAt
        isFavorite
      }
      isFavorite
    }
  }
`;

export const getSharedResourceFromLibrary = gql`
  query GetSharedResourceFromLibrary($resourceId: String!) {
    getSharedResourceFromLibrary(resourceId: $resourceId) {
      id
      createdAt
      updatedAt
      path
      name
      mimeType
      isDirectory
      libraryType
      libraryOwner
      cdnKey
      size
      removedAt
      resolvedPath {
        id
        createdAt
        updatedAt
        path
        name
        mimeType
        isDirectory
        libraryType
        libraryOwner
        cdnKey
        size
        removedAt
        isFavorite
      }
      resources {
        id
        createdAt
        updatedAt
        path
        name
        mimeType
        isDirectory
        libraryType
        libraryOwner
        cdnKey
        size
        removedAt
        isFavorite
      }
      isFavorite
    }
  }
`;
