import { gql, useQuery } from '@apollo/client';

import { createMock } from 'mocks';
import { Query, QueryMinimalAccountDataByTokenArgs } from 'types';
import { MinimalAccountDataOutputFragment } from 'types/generated-fragments';

export const minimalAccountDataByTokenGql = gql`
  ${MinimalAccountDataOutputFragment}
  query MinimalAccountDataByToken($token: String!) {
    minimalAccountDataByToken(token: $token) {
      ...MinimalAccountDataOutputFragment
    }
  }
`;

export const useAccountByToken = (token?: string) => {
  const { data, error, loading } = useQuery<
    Pick<Query, 'minimalAccountDataByToken'>,
    Partial<QueryMinimalAccountDataByTokenArgs>
  >(minimalAccountDataByTokenGql, {
    variables: { token },
    skip: !token,
  });

  return {
    account: data?.minimalAccountDataByToken,
    error,
    loading,
    token,
  };
};

export const mockMinimalAccountDataByTokenGql = createMock(
  minimalAccountDataByTokenGql,
  {
    data: {
      minimalAccountDataByToken: {
        email: 'email@email.com',
        firstName: 'firstName',
        phoneNumber: '+48123456789',
      },
    },
  },
  { token: 'token' }
);
