import { useTranslation } from 'react-i18next';

import { gql, useMutation } from '@apollo/client';

import { useCurrentLibraryLocation } from 'features/Library/hooks/useCurrentLibraryLocation';
import { LibraryLocation } from 'features/Library/types';
import { searchGql } from 'gql/search/query/search';
import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationRemoveLibraryMediaFromFavoriteArgs } from 'types';

import {
  getLibraryMediaFavorites,
  getResourcesFromDirectoryInLibraryGql,
  getSharedResourcesFromLibrary,
  getTrashesFromLibraryGql,
} from '../documents';

const removeLibraryMediaFromFavoriteGql = gql`
  mutation RemoveLibraryMediaFromFavorite($resourceId: String!) {
    removeLibraryMediaFromFavorite(resourceId: $resourceId)
  }
`;

export const useRemoveLibraryMediaFromFavorite = () => {
  const { libraryLocation } = useCurrentLibraryLocation();
  const { t } = useTranslation(['library']);
  let refetchQuesriesArgument;

  switch (libraryLocation) {
    case LibraryLocation.SharedWithMe:
      refetchQuesriesArgument = [getSharedResourcesFromLibrary];
      break;
    case LibraryLocation.RecentlyDeleted:
      refetchQuesriesArgument = [getTrashesFromLibraryGql];
      break;
    case LibraryLocation.SearchLibraryContext:
      refetchQuesriesArgument = [searchGql];
      break;
    default:
      refetchQuesriesArgument = [
        getLibraryMediaFavorites,
        getResourcesFromDirectoryInLibraryGql,
      ];
      break;
  }

  const [mutation] = useMutation<
    Pick<Mutation, 'removeLibraryMediaFromFavorite'>,
    MutationRemoveLibraryMediaFromFavoriteArgs
  >(removeLibraryMediaFromFavoriteGql, {
    fetchPolicy: 'no-cache',
    refetchQueries: refetchQuesriesArgument,
  });

  const handleMutation = (
    variables: MutationRemoveLibraryMediaFromFavoriteArgs
  ) => mutation({ variables });

  const removeLibraryMediaFromFavorite = useWithMutationToast(handleMutation, {
    successMessage: t('library:toasts.remove-from-favourites-success'),
    errorMessage: t('library:toasts.remove-from-favourites-fail'),
  });

  return { removeLibraryMediaFromFavorite };
};
