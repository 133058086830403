import { Edit32 } from '@carbon/icons-react';
import { IconButton } from '@chakra-ui/react';

type Props = {
  handleNewConversation: () => void;
};

export const NewConversationActionButton = ({
  handleNewConversation,
}: Props) => (
  <IconButton
    fill="primary.blue.default"
    _hover={{ fill: 'base1' }}
    variant="iconButton"
    aria-label="start-new-conversation-button"
    as={Edit32}
    onClick={handleNewConversation}
  />
);
