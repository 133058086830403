import { gql, useMutation } from '@apollo/client';

import { postsGql } from 'hooks/posts';
import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationDeleteAdvisorArgs } from 'types';

import { listEditableAdvisorsGql } from '../documents';

const deleteAdvisorGql = gql`
  mutation DeleteAdvisor($data: AdvisorInput!) {
    deleteAdvisor(data: $data)
  }
`;

export const useDeleteAdvisor = () => {
  const [deleteAdvisorMutation] = useMutation<
    Pick<Mutation, 'deleteAdvisor'>,
    MutationDeleteAdvisorArgs
  >(deleteAdvisorGql, {
    refetchQueries: [listEditableAdvisorsGql, postsGql],
  });

  const deleteAdvisor = (advisorId: string) =>
    deleteAdvisorMutation({
      variables: { data: { id: advisorId } },
    });

  return useWithMutationToast(deleteAdvisor, {
    successMessage: 'The advisor was successfully deleted',
    errorMessage: 'Deleting failed',
  });
};
