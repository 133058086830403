import { Flex, Image } from '@chakra-ui/react';

type Props = {
  signedUrl?: string;
};

export const ArticleFullWidthMedia = ({ signedUrl }: Props) => {
  if (!signedUrl) return null;

  return (
    <Flex justifyContent="center">
      <Image src={signedUrl} width="full" />
    </Flex>
  );
};
