import { BarConfig, GElement, Position, SvgElement } from '../types';
import { Dimensions } from '../types/Dimensions';
import { LegendConfig } from '../types/Legend';
import { getHorizontalPosition, getVerticalPosition } from './position';

const LEGEND_ROW_OFFSET = 20;

const BAR_WIDTH = 20;
const BAR_HEIGHT = 10;

const getHorizontalOffset = (el: GElement, position?: Position): number => {
  switch (position) {
    case 'end':
      return el.node()?.getBBox?.().width ?? 0;
    case 'center':
      return (el.node()?.getBBox?.().width ?? 0) / 2;
    case 'start':
    default:
      return 0;
  }
};

const getVerticalOffset = (el: GElement, position?: Position): number => {
  switch (position) {
    case 'center':
      return (el.node()?.getBBox?.().height ?? 0) / 2;
    case 'end':
      return el.node()?.getBBox?.().height ?? 0;
    case 'start':
    default:
      return 0;
  }
};

type TOptions = {
  dimensions: Dimensions;
  legend: LegendConfig;
  indices: number[];
  getBarConfig: (i: number) => BarConfig;
};

export const addLegend = (
  el: SvgElement,
  { legend, dimensions, getBarConfig, indices }: TOptions
): SvgElement => {
  const {
    horizontal,
    vertical,
    format,
    color = '#000',
    fontSize = 16,
  } = legend;

  el.append('g').call((gWrapper) => {
    indices.forEach((i) => {
      const bar = getBarConfig(i);

      gWrapper.append('g').call((gInner) => {
        gInner.attr('text-anchor', 'start');

        gInner
          .append('rect')
          .attr('fill', bar.color || '#000')
          .attr('width', BAR_WIDTH)
          .attr('height', BAR_HEIGHT);

        gInner
          .append('text')
          .attr('fill', color)
          .attr('font-size', fontSize)
          .attr('font-family', 'Roboto, Arial')
          .attr('font-weight', '500')
          .attr('transform', `translate(${BAR_WIDTH + 5}, ${BAR_HEIGHT})`)
          .text(format ? format(bar.label || '') : bar.label || '');

        gInner.attr('transform', `translate(0,${i * LEGEND_ROW_OFFSET})`);
      });
    });

    const y = getVerticalPosition({ dimensions, position: vertical });
    const x = getHorizontalPosition({ dimensions, position: horizontal });

    const xOffset = getHorizontalOffset(gWrapper, horizontal);
    const yOffset = getVerticalOffset(gWrapper, vertical);

    gWrapper.attr('transform', `translate(${x - xOffset}, ${y - yOffset})`);
  });

  return el;
};
