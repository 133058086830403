import { Calendar16 } from '@carbon/icons-react';
import { Flex, FlexProps, Text } from '@chakra-ui/react';

import { formatDate, getIsMultipleDay } from 'utils/date';

type Props = {
  startDate?: string | number | Date;
  endDate?: string | number | Date;
  format?: string;
  containerStyling?: FlexProps;
};

export const CalendarBlock = ({
  startDate,
  endDate,
  format,
  containerStyling,
}: Props) => {
  if (!startDate) return null;

  const isMultipleDay = getIsMultipleDay(startDate, endDate);

  return (
    <Flex fontSize={14} gap={2} align="center" {...containerStyling}>
      <Calendar16 display="inline" />
      <Text size="p2">
        {formatDate(new Date(startDate), format)}
        {endDate &&
          isMultipleDay &&
          ` - ${formatDate(new Date(endDate), format)}`}
      </Text>
    </Flex>
  );
};
