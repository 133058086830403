import { Flex, Heading } from '@chakra-ui/react';

import { Links } from 'router/links';
import { underlay } from 'theme/mixins/underlay';
import { Insight } from 'types';
import { generateUrlById } from 'utils/links';

import { ViewButton } from '../CarouselBaseV2/ViewButton';

export const FeaturedNewsCard = ({ item }: { item: Insight }) => {
  const { id, hero } = item;

  return (
    <Flex
      bgColor="rgba(0,0,0,0.5)"
      bgImage={hero.heroImage?.signedUrl}
      h="full"
      w="full"
      bgSize="cover"
      bgPosition="center"
      position="relative"
      {...underlay()}
    >
      <Flex pos="absolute" bottom={3} left={4} direction="column" zIndex={2}>
        <Heading size="h3" color="ui.white" maxW={405} noOfLines={3} mb={3}>
          {hero.headline}
        </Heading>
        <ViewButton to={generateUrlById(Links.insight, id)}>
          View article
        </ViewButton>
      </Flex>
    </Flex>
  );
};
