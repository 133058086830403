import { gql, useQuery } from '@apollo/client';

import { Query } from 'types';
import { OnboardManagementOutputDeepNestingFragment } from 'types/generated-fragments';

const onboardManagementGql = gql`
  ${OnboardManagementOutputDeepNestingFragment}
  query OnboardManagement {
    onboardManagement {
      ...OnboardManagementOutputDeepNestingFragment
    }
  }
`;

export const useOnboardingManagement = () => {
  const { data, loading, error } = useQuery<Pick<Query, 'onboardManagement'>>(
    onboardManagementGql,
    {
      fetchPolicy: 'no-cache',
    }
  );

  return { error, loading, onboardManagement: data?.onboardManagement };
};
