import { useFormikContext } from 'formik';

import { Checkbox } from '@chakra-ui/react';

import { HeroMediaType, MediaOutput } from 'types';

type FormValues = {
  heroVideo?: MediaOutput | MediaOutput[] | null;
  heroImage?: MediaOutput | MediaOutput[] | null;
  preferredHeroMediaToDisplay?: HeroMediaType;
  [key: PropertyKey]: unknown;
};

export const PREFERRED_MEDIA_OPTIONS = {
  [HeroMediaType.Image]: {
    formKey: 'heroImage',
    text: 'click this checkbox to display the image.',
  },
  [HeroMediaType.Video]: {
    formKey: 'heroVideo',
    text: 'click this checkbox to display the video.',
  },
} as const;

export const UploadCheckBox = ({ type }: { type: HeroMediaType }) => {
  const { values, errors, setFieldValue } = useFormikContext<FormValues>();

  const { formKey, text } = PREFERRED_MEDIA_OPTIONS[type];

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(
      'preferredHeroMediaToDisplay',
      e.target.checked ? type : null
    );
  };

  const { preferredHeroMediaToDisplay, [formKey]: hero } = values;

  const currentValue = Boolean(
    preferredHeroMediaToDisplay ||
      (hero && Array.isArray(hero) ? hero.length > 0 : hero)
  );

  const isDisabled = !currentValue;

  const isInvalid = !isDisabled && Boolean(errors.preferredHeroMediaToDisplay);

  const isChecked = !isDisabled && preferredHeroMediaToDisplay === type;

  return (
    <Checkbox
      name="preferredHeroMediaToDisplay"
      ml={4}
      size="sm"
      fontWeight="normal"
      isChecked={isChecked}
      onChange={handleChange}
      color={isInvalid ? 'red' : 'inherit'}
      isInvalid={isInvalid}
      isDisabled={isDisabled}
    >
      {text}
    </Checkbox>
  );
};
