import { LibraryTypeEnum } from 'types';

type T =
  | 'library:side-navigation.items.family'
  | 'library:side-navigation.items.itriom'
  | 'library:side-navigation.header';

export const libraryTypeToLabelMap: Record<LibraryTypeEnum, T> = {
  [LibraryTypeEnum.Family]: 'library:side-navigation.items.family',
  [LibraryTypeEnum.Itriom]: 'library:side-navigation.items.itriom',
  [LibraryTypeEnum.Own]: 'library:side-navigation.header',
};
