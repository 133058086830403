import { Flex, Text, Heading } from '@chakra-ui/react';

type Props = {
  quote: string;
  author?: {
    name?: string;
    title?: string;
  };
};

export const ArticleQuote = ({ quote, author }: Props) => (
  <Flex
    direction="column"
    padding={4}
    gap={6}
    borderLeftWidth="2px"
    borderLeftStyle="solid"
    borderLeftColor="primary.blue.default"
  >
    <Heading size="h5">{quote}</Heading>
    {author && (
      <Flex direction="column">
        <Text size="p2">{author?.name}</Text>
        <Text size="p3">{author?.title}</Text>
      </Flex>
    )}
  </Flex>
);
