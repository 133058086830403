import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Flex, Grid, Heading } from '@chakra-ui/react';

import { Breakpoints, useBreakpoint } from 'theme';

import { CONTENT_PADDING_X } from '../../constants';
import { MarketplaceHeading } from '../MarketplaceHeading';
import { SolutionCard } from './SolutionCard';

const SOLUTION_CARDS = [
  <SolutionCard
    key={1}
    title="Values-led"
    description="Our values-led approach provide a family-specific focus to impact-investment"
    iconSrc="/assets/marketplace/solutionIcons/values-led.svg"
  />,
  <SolutionCard
    key={2}
    title="SDG specific"
    description="Family-values are matched to specific and targeted SDG's to ensure opportunities are relevant"
    iconSrc="/assets/marketplace/solutionIcons/sdg-specific.svg"
  />,
  <SolutionCard
    key={3}
    title="Evidence based performance"
    description="We access relevant data to track performance"
    iconSrc="/assets/marketplace/solutionIcons/evidence-based-performance.svg"
  />,
  <SolutionCard
    key={4}
    title="Impact reporting"
    description="We ensure accurate reporting via our platforms family-specific dashboards"
    iconSrc="/assets/marketplace/solutionIcons/impact-reporting.svg"
  />,
];

export const SolutionSection = () => {
  const breakpoint = useBreakpoint();

  const shouldShowSlider = breakpoint === Breakpoints.Mobile;

  return (
    <Flex
      w="full"
      direction="column"
      gap={16}
      px={CONTENT_PADDING_X}
      pb="80px"
      sx={{
        '& .swiper-pagination-bullet': {
          backgroundColor: '#d9d9d9',
        },
        '& .swiper-pagination-bullet-active': {
          backgroundColor: '#c6d344',
        },
      }}
    >
      <MarketplaceHeading>The Marketplace Solution</MarketplaceHeading>
      <Flex direction="column" gap={8}>
        <Heading size="h4">
          Our Itriom marketplace takes the core goals of the sustainability
          strategy as developed as part of the charter and matches them to
          specific and relevant impact investment opportunities.
        </Heading>
        <Heading size="h4">
          It’s the starting point for a lasting, meaningful and measurable
          impact and legacy of which future family generations can be proud.
        </Heading>
      </Flex>
      {shouldShowSlider ? (
        <Swiper
          pagination
          modules={[Pagination]}
          style={{
            width: '100%',
            height: '100%',
            paddingBottom: '40px',
          }}
        >
          {SOLUTION_CARDS.map((solutionCard) => (
            <SwiperSlide key={solutionCard.key}>{solutionCard}</SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <Grid
          w="full"
          gridTemplateColumns="auto auto"
          gridTemplateRows="auto auto"
          columnGap={8}
          rowGap={16}
          justifyItems="center"
        >
          {SOLUTION_CARDS}
        </Grid>
      )}
    </Flex>
  );
};
