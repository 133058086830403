import { useQuery } from '@apollo/client';

import { Query, QueryGetLibraryDetailsArgs } from 'types';

import { getLibraryDetailsGql } from '../documents';

export const useGetLibraryDetails = ({
  skip,
  libraryType,
}: QueryGetLibraryDetailsArgs & {
  skip?: boolean;
}) => {
  const { data, error, loading, refetch } = useQuery<
    Pick<Query, 'getLibraryDetails'>,
    QueryGetLibraryDetailsArgs
  >(getLibraryDetailsGql, { skip, variables: { libraryType } });

  return { libraryDetails: data?.getLibraryDetails, error, loading, refetch };
};
