import { Box, Text, Image } from '@chakra-ui/react';

type Props = {
  header?: string;
  subheader?: string;
  url?: string;
};

export const InitiativeChart = ({
  header = '',
  subheader = '',
  url = '',
}: Props) => (
  <Box width="100%" border="default">
    <Box position="absolute" m={3}>
      <Text size="p1b">{header}</Text>
      <Text size="p3">{subheader}</Text>
    </Box>
    <Image src={url} h="100%" />
  </Box>
);
