import { useEffect, useState } from 'react';

export const usePrevious = <T>(value: T | null): T | null => {
  const [prevValue, setPrevValue] = useState<T | null>(null);

  useEffect(() => {
    setPrevValue(value);
  }, [value]);

  return prevValue;
};
