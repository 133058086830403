import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Close20, Search20 } from '@carbon/icons-react';
import {
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  useOutsideClick,
} from '@chakra-ui/react';

import { useSearchContext } from 'contexts/SearchContext';
import { FeatureFlag, useFeatureFlag } from 'hooks/useFeatureFlag';

import { SearchResults } from './components/SearchResults';

export const Search = () => {
  const { t } = useTranslation(['common']);

  const {
    isSearchPreviewOpen,
    toggleSearchInput,
    isSearchInputOpen,
    searchTerm,
    handleSearchInputChange,
    clearSearchTerm,
    handleRefetchResults,
    closeSearchPreviewOnClickOutside,
  } = useSearchContext();

  const searchInputRef = useRef(null);

  useOutsideClick({
    ref: searchInputRef,
    handler: () => closeSearchPreviewOnClickOutside(),
  });

  // TODO: REMOVE FEATURE FLAG WHEN ENTIRE FEATURE IMPLEMENTED
  const isEnabled = useFeatureFlag(FeatureFlag.SEARCH);

  if (!isEnabled) {
    return null;
  }

  return (
    <Menu
      isLazy
      isOpen={isSearchPreviewOpen}
      placement="bottom-end"
      offset={[10, 23]}
    >
      {isSearchInputOpen ? (
        <InputGroup ref={searchInputRef}>
          <Input
            px={3}
            type="text"
            autoFocus
            fontSize="sm"
            value={searchTerm}
            onChange={handleSearchInputChange}
            onKeyDown={(e) =>
              handleRefetchResults({ event: e, isTopRightSearchBox: true })
            }
            placeholder={t('common:search.placeholder')}
          />
          <InputRightElement>
            <MenuButton
              aria-label={t('common:search.close')}
              onClick={clearSearchTerm}
            >
              <Close20 />
            </MenuButton>
          </InputRightElement>
        </InputGroup>
      ) : (
        <MenuButton
          as={IconButton}
          isRound
          variant="ghost"
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="relative"
          aria-label={t('common:search.open')}
          onClick={toggleSearchInput}
          icon={<Search20 />}
        />
      )}
      <SearchResults />
    </Menu>
  );
};
