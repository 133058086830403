import { gql, useMutation } from '@apollo/client';

import { accountGql } from 'gql/accounts/query/account';
import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationUpdateAccountArgs } from 'types';

export const updateAccountGql = gql`
  mutation UpdateAccount(
    $account: AccountInput!
    $heroImage: [Upload!]
    $avatarImage: [Upload!]
  ) {
    updateAccount(
      account: $account
      heroImage: $heroImage
      avatarImage: $avatarImage
    ) {
      id
      firstName
      lastName
      description
      title
    }
  }
`;

export const useUpdateAccount = () => {
  const [updateAccountMutation] = useMutation<
    Pick<Mutation, 'updateAccount'>,
    MutationUpdateAccountArgs
  >(updateAccountGql, { refetchQueries: [accountGql] });

  const updateAccount = async (variables: MutationUpdateAccountArgs) => {
    await updateAccountMutation({ variables });
  };

  const updateAccountWithToast = useWithMutationToast(updateAccount, {
    successMessage: 'Your account has been updated.',
    errorMessage: 'Error updating account',
  });

  return { updateAccount: updateAccountWithToast };
};
