import { Fragment } from 'react';

import { LayoutGridElement } from 'components/Layout/Grid';
import { TabPane } from 'components/TabPane';
import { WithChildren } from 'types';

import { initiativesTabsConfig } from './initiativesTabConfig';

const TabNavigationWrapper = ({ children }: WithChildren) => (
  <LayoutGridElement colSpan={12}>{children}</LayoutGridElement>
);

export const InitiativesTabs = () => {
  return (
    <TabPane
      tabsConfig={initiativesTabsConfig}
      TabContentWrapper={Fragment}
      TabNavigationWrapper={TabNavigationWrapper}
    />
  );
};
