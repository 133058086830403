import { animated, useTransition } from 'react-spring';

import { SCALE_ANIMATION_STATES } from '../../constants';
import { StateValueCategory } from '../../types';
import { CenterWheelTemplate } from './CenterWheelTemplate';

type Props = {
  category: StateValueCategory;
};

export const AnimatedCenterWheel = ({ category }: Props) => {
  const transition = useTransition(category, {
    from: SCALE_ANIMATION_STATES.off,
    enter: SCALE_ANIMATION_STATES.on,
    leave: SCALE_ANIMATION_STATES.off,
    config: { tension: 100 },
  });

  return transition(({ transform }, animatedCategory) => (
    <animated.div style={{ transform, position: 'absolute' }}>
      <CenterWheelTemplate category={animatedCategory} />
    </animated.div>
  ));
};
