import { useQuery } from '@apollo/client';

import { Query } from 'types';

import { advisorConnectionsGql } from '../documents';

export const useAdvisorConnections = () => {
  const { data, loading, error } = useQuery<Pick<Query, 'advisorConnections'>>(
    advisorConnectionsGql
  );

  const advisorConnections = data?.advisorConnections ?? [];

  return { advisorConnections, loading, error };
};
