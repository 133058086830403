import { gql } from '@apollo/client';

import { MediaOutput, Profile } from 'gql/fragments';
import {
  FamilyValuesOutputFragment,
  SdgOutputNoNestingFragment,
  GoalFragment,
} from 'types/generated-fragments';

export const familyOptionsGql = gql`
  query Families($filters: ListFamilyInput, $pagination: PaginationInput!) {
    families(filters: $filters, pagination: $pagination) {
      nodes {
        id
        name
        status
      }
    }
  }
`;

export const familyGql = gql`
  ${MediaOutput}
  ${GoalFragment}
  ${SdgOutputNoNestingFragment}
  ${Profile}
  ${FamilyValuesOutputFragment}
  query Family($familyId: String!) {
    family(id: $familyId) {
      id
      name
      purpose
      createdAt
      updatedAt
      status
      heroImage {
        ...MediaOutput
      }
      heroVideo {
        ...MediaOutput
      }
      goals {
        ...GoalFragment
      }
      influences {
        ...SdgOutputNoNestingFragment
      }
      members {
        ...Profile
      }
      admins {
        ...Profile
      }
      advisors {
        ...Profile
      }
      values {
        ...FamilyValuesOutputFragment
      }
      sdGoals {
        ...SdgOutputNoNestingFragment
      }
    }
  }
`;
