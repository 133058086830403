import { useTranslation } from 'react-i18next';

import { Heading, Text } from '@chakra-ui/react';

import { Section } from 'components/Layout/Sections/Section';

export const PurposeSection = ({ purpose }: { purpose?: string }) => {
  const { t } = useTranslation(['profile']);

  if (!purpose) return null;

  return (
    <Section
      title={t('profile:sections.purpose.title')}
      subtitleContent={
        <Text size="p2">{t('profile:sections.purpose.description')}</Text>
      }
    >
      <Heading size="h3">{purpose}</Heading>
    </Section>
  );
};
