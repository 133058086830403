import { useQuery } from '@apollo/client';

import { createMock } from 'mocks';
import { familyOptionsMock } from 'mocks/family.mock';
import {
  BasePaginationOutput,
  Family,
  FamilyStatusEnum,
  Option,
  QueryFamiliesArgs,
} from 'types';

import { familyOptionsGql } from '../documents';

type FamilyOptionSubset = Pick<Family, 'id' | 'name' | 'status'>;

type QueryType = {
  families: BasePaginationOutput<FamilyOptionSubset>;
};

export const familyOptionsGqlMock = createMock<QueryType, QueryFamiliesArgs>(
  familyOptionsGql,
  {
    data: { families: familyOptionsMock },
  },
  { pagination: { limit: 100, offset: 0 } }
);

export const useFamilyOptions = ({
  pagination = { offset: 0, limit: 100 },
  filters,
}: Partial<QueryFamiliesArgs> = {}) => {
  const { data, loading, error } = useQuery<QueryType, QueryFamiliesArgs>(
    familyOptionsGql,
    { variables: { pagination, filters } }
  );

  const mapNodes = (nodes: FamilyOptionSubset[]): Option[] =>
    nodes.map((node) => ({
      value: node.id,
      label: node.name,
    }));

  const familyOptions = mapNodes(data?.families?.nodes ?? []);

  const activeFamilyOptions = mapNodes(
    data?.families?.nodes.filter(
      (family) => family.status === FamilyStatusEnum.Active
    ) ?? []
  );

  return { familyOptions, activeFamilyOptions, loading, error };
};
