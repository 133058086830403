import { gql, useQuery } from '@apollo/client';

import { createMock } from 'mocks';
import { mockedSdgs } from 'mocks/sdgs/sdgs.mock';
import { Query } from 'types';
import { SdgOutputFragment } from 'types/generated-fragments';

export const sdgsGql = gql`
  ${SdgOutputFragment}
  query Sdgs {
    sdgs {
      ...SdgOutputFragment
    }
  }
`;

export const sdgsGqlMock = createMock<Pick<Query, 'sdgs'>>(sdgsGql, {
  data: mockedSdgs,
});

export const useSdgs = () => {
  const { data, loading, error } = useQuery<Pick<Query, 'sdgs'>>(sdgsGql);

  const sdgs = data?.sdgs ?? [];

  return { sdgs, loading, error };
};
