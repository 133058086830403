import { useQuery, gql } from '@apollo/client';

import { Query } from 'types';
import { FamilyValuesWithAvailableSdgOutputFragment } from 'types/generated-fragments';

export const familyValuesWithRelatedSdgGql = gql`
  ${FamilyValuesWithAvailableSdgOutputFragment}
  query FamilyValuesWithRelatedSdg {
    familyValuesWithRelatedSdg {
      ...FamilyValuesWithAvailableSdgOutputFragment
    }
  }
`;

export const useFamilyValuesWithRelatedSdg = () => {
  const { data, loading, error } = useQuery<
    Pick<Query, 'familyValuesWithRelatedSdg'>
  >(familyValuesWithRelatedSdgGql);

  const familyValuesWithRelatedSdg = data?.familyValuesWithRelatedSdg;

  return { familyValuesWithRelatedSdg, loading, error };
};
