import { Box } from '@chakra-ui/react';

import { ImageTextTile } from 'components/ImageTextTile';

import { NextGenCardProps } from '../../types';

export const SingleLeaderSection = ({
  title,
  author,
  src,
  bodyText,
  findOutMoreUrl,
  subtitle,
}: NextGenCardProps) => {
  return (
    <Box bgColor="grey4" py={16}>
      <Box maxW={800} maxH={312} mx="auto">
        <ImageTextTile
          title={title}
          direction="row"
          author={author}
          src={src}
          bodyText={bodyText}
          findOutMoreUrl={findOutMoreUrl}
          subtitle={subtitle}
        />
      </Box>
    </Box>
  );
};
