import { CarouselInitiatives } from './CarouselInitiatives';
import { CarouselInsights } from './CarouselInsights';
import { CarouselKeyEvents } from './CarouselKeyEvents';

export enum CarouselType {
  Insights = 'insights',
  Initiatives = 'initiatives',
  KeyEvents = 'keyEvents',
}

type CarouselProps = {
  type: CarouselType;
};

const carousel = {
  [CarouselType.Insights]: <CarouselInsights />,
  [CarouselType.Initiatives]: <CarouselInitiatives />,
  [CarouselType.KeyEvents]: <CarouselKeyEvents />,
};

export const Carousel = ({ type }: CarouselProps) => carousel[type];
