import { gql, useQuery } from '@apollo/client';

import { FamilyCharterDeepNestingFragment } from 'gql/fragments';
import { createMock } from 'mocks';
import { familyCharterMock } from 'mocks/familyCharter.mock';
import { Query } from 'types';

export const familyCharterForMemberGql = gql`
  ${FamilyCharterDeepNestingFragment}
  query FamilyCharterForFamilyMember {
    familyCharterForFamilyMember {
      ...FamilyCharterDeepNestingFragment
    }
  }
`;

export const familyCharterForFamilyMember = createMock<
  Pick<Query, 'familyCharterForFamilyMember'>
>(familyCharterForMemberGql, {
  data: { familyCharterForFamilyMember: familyCharterMock },
});

export const useFamilyCharterForMember = () => {
  const { data, error, loading } = useQuery<
    Pick<Query, 'familyCharterForFamilyMember'>
  >(familyCharterForMemberGql);

  return { familyCharter: data?.familyCharterForFamilyMember, error, loading };
};
