import { Account, Insight, InsightStatusEnum } from 'types';
import { isItriomStaff } from 'utils/roles';

export const shouldForbidContentDisplay = (
  loading: boolean,
  insight?: Insight,
  user?: Account
) => {
  if (loading) return false;
  if (!insight) return false;
  if (!user) return true;

  const isDraft = insight.status === InsightStatusEnum.Draft;
  if (!isDraft) return false;

  if (isItriomStaff(user.role)) return false;

  if (insight.createdBy === user.id) return false;

  if (
    insight.visibilityDetails?.family &&
    user.familyId &&
    insight.visibilityDetails?.familyOwnersIds?.includes(user.familyId)
  )
    return false;

  return true;
};
