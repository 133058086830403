import { DEFAULT_COLOR } from '../constants';
import { BarConfig, SvgElement } from '../types';
import { Dimensions } from '../types/Dimensions';

type TOptions = {
  indices: number[];
  getBarConfig: (i: number) => BarConfig;
  barBackground?: string; // color
  dimensions: Dimensions;
};

const addRects = (el: SvgElement, indices: number[]) => {
  return el.append('g').selectAll('rect').data(indices).join('rect');
};

export const addBars = (
  el: SvgElement,
  { getBarConfig, indices, barBackground, dimensions }: TOptions
): SvgElement => {
  const bars = indices.map((i) => getBarConfig(i));

  const { height, margin, padding } = dimensions;

  const maxHeight =
    height - margin.bottom - margin.top - padding.top - padding.bottom;

  if (barBackground) {
    addRects(el, indices)
      .attr('fill', barBackground)
      .attr('x', (i) => bars[i]?.x ?? 0)
      .attr('y', margin.top + padding.top)
      .attr('height', maxHeight)
      .attr('width', (i) => bars[i]?.width || 0);
  }

  addRects(el, indices)
    .attr('fill', (i) => bars[i]?.color || DEFAULT_COLOR)
    .attr('x', (i) => bars[i]?.x ?? 0)
    .attr('y', (i) => bars[i]?.y ?? 0)
    .attr('height', (i) => bars[i]?.height ?? 0)
    .attr('width', (i) => bars[i]?.width ?? 0);

  return el;
};
