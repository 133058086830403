import { useTranslation } from 'react-i18next';

import { ActivationConfirmationModal } from 'features/Cms/components/ActivationConfirmationModal';
import { AccountStatus, ExtendedProfileOutput } from 'types';
import { getFullName } from 'utils/string';

import { AssignFamilyHeadModal } from '../AssignFamilyHeadModal';
import { ModalType } from '../constants';

interface Props {
  familyId?: string;
  member: ExtendedProfileOutput;
  modalType: ModalType;
  isOpen: boolean;
  onClose: VoidFunction;
  handleConfirm: (newFamilyHeadUserId?: string) => void;
}

export const ChangeModal = ({
  member,
  modalType,
  isOpen,
  onClose,
  handleConfirm,
  familyId,
}: Props) => {
  const { t } = useTranslation(['common']);

  return modalType === 'assignment' ? (
    <AssignFamilyHeadModal
      isOpen={isOpen}
      currentHead={member}
      familyId={familyId}
      onClose={onClose}
      onConfirm={handleConfirm}
    />
  ) : (
    <ActivationConfirmationModal
      name={getFullName(member.firstName, member.lastName)}
      isOpen={isOpen}
      onClose={onClose}
      isActive={member.status === AccountStatus.Active}
      entity={t('common:user').toLowerCase()}
      onConfirm={handleConfirm}
    />
  );
};
