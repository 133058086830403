import { TagOutput } from 'types';

export const TAG_COLORS: Record<TagOutput['name'], string> = {
  'No Poverty': '#E199A2',
  'Zero Hunger': '#E9D3AB',
  'Good Health and Well-Being': '#B0CDA4',
  'Quality Education': '#D4939B',
  'Gender Equality': '#EFA19E',
  'Clean Water and Sanitation': '#ABDCEC',
  'Affordable and Clean Energy': '#F7E0A8',
  'Decent Work and Economic Growth': '#C592A1',
  'Industry, Innovation and Infrastructure': '#F0B6A1',
  'Reduced Inequalities': '#DF95B2',
  'Sustainable Cities and Communities': '#F5D0A6',
  'Responsible Consumption and Production': '#DAC5A3',
  'Climate Action': '#A6BCA5',
  'Life Below Water': '#9FCAE6',
  'Life on Land': '#B8DCA5',
  'Peace, Justice and Strong Institutions': '#93B3C9',
  'Partnerships for the Goals': '#92A3B1',
};
