import { CheckmarkOutline24 } from '@carbon/icons-react';
import { Box, Button, Flex, useDisclosure } from '@chakra-ui/react';

import { Modal } from 'components/Modal';
import { useBookDemo } from 'gql/services/mutation/bookDemo';
import { useIsDemoBooked } from 'gql/services/query/isDemoBooked';
import { BookingTypeEnum } from 'types';

export const BookDemoButton = () => {
  const { isDemoBooked } = useIsDemoBooked(BookingTypeEnum.MarketplaceDemo);

  const bookDemo = useBookDemo({ type: BookingTypeEnum.MarketplaceDemo });

  const modal = useDisclosure();

  const handleClick = () => bookDemo().then(modal.onOpen);

  return (
    <>
      <Button
        mx="auto"
        bg={isDemoBooked ? 'base2' : 'primary.lime.default'}
        border="none"
        color="primary.blue.default"
        fontWeight={600}
        borderRadius={2}
        onClick={handleClick}
        _disabled={{
          opacity: 'unset',
          cursor: 'not-allowed',
        }}
        disabled={!!isDemoBooked}
      >
        {isDemoBooked ? "You've booked" : 'Book a demo'}
      </Button>
      <Modal
        containerStyling={{
          background: 'primary.blue.default',
          h: 408,
          w: 592,
          maxW: 592,
          color: 'ui.white',
        }}
        closeButtonStyling={{
          sx: { svg: { width: '25px', height: '25px' } },
          top: '21.5px',
          right: '23.5px',
        }}
        overlayStyling={{ background: 'rgba(10, 30, 64, 0.75)' }}
        isOpen={modal.isOpen}
        onClose={modal.onClose}
      >
        <Flex
          direction="column"
          gap={75}
          justify="center"
          align="center"
          h="full"
        >
          <CheckmarkOutline24 width={50} height={50} />
          <Box textAlign="center">
            Thank you for requesting a Marketplace demo. <br />
            An Itriom advisor will be in touch shortly.
          </Box>
        </Flex>
      </Modal>
    </>
  );
};
