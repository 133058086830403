import { Heading } from '@chakra-ui/react';

import { ContentSection } from 'components/CmsLayout';

type Props = {
  mission: string;
};

export const Mission = ({ mission }: Props): JSX.Element => {
  return (
    <ContentSection title="Mission">
      <Heading size="h3">{mission}</Heading>
    </ContentSection>
  );
};
