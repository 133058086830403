import { useFormik } from 'formik';

import { useNavigate } from 'react-router-dom';

import { gql, useMutation } from '@apollo/client';

import { newPasswordSchema } from 'hooks/authorization/validation';
import { Links } from 'router/links';
import { Mutation, MutationSetPasswordForFirstTimeArgs } from 'types';

type FormikValues = {
  password: string;
  confirmPassword: string;
};

export const setPasswordForFirstTimeGql = gql`
  mutation SetPasswordForFirstTime($data: SetPasswordForFirstTimeInput!) {
    setPasswordForFirstTime(data: $data)
  }
`;

export const useSetPasswordForFirstTime = () => {
  const [mutate, { error, loading }] = useMutation<
    Pick<Mutation, 'setPasswordForFirstTime'>,
    MutationSetPasswordForFirstTimeArgs
  >(setPasswordForFirstTimeGql, { refetchQueries: ['Account'] });
  const navigate = useNavigate();

  const formik = useFormik<FormikValues>({
    initialValues: { password: '', confirmPassword: '' },
    onSubmit: ({ password }) => {
      mutate({ variables: { data: { password } } }).then(() => {
        navigate(Links.onboardingOtpSetPasswordSuccess);
      });
    },
    validationSchema: newPasswordSchema,
  });

  return { formik, error, loading };
};
