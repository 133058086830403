export const oldColors = {
  base1: '#A0AAB0',
  base2: '#C7CFD4',
  base3: '#F6F8F7',

  darkBlue2: '#3B4B66',
  darkBlue3: '#6C788C',
  darkBlue4: '#9DA5B3',
  darkBlue5: '#CED2D9',

  green1: '#56BD8F',

  lightBlue1: '#89C3D3',

  lightGrey3: '#C5CECD',
  lightGrey5: '#ECEFEE',

  steelBlue1: '#5B869E',
  steelBlue6: '#5D889F',

  darkGrey1: '#637675',
  darkGrey2: '#829191',
  darkGrey3: '#A1ADAC',
  darkGrey4: '#C1C8C8',
  darkGrey5: '#E0E4E3',

  orange1: '#F9C950',
};
