import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from '@chakra-ui/react';

import { HeroBanner } from 'components/HeroBanner';
import { QueryStateGuard } from 'components/QueryStateGuard';
import { useBannerInsight } from 'hooks/insights';
import { Links } from 'router/links';
import { generateUrlById } from 'utils/links';

export const InsightsLandingPageHero = () => {
  const { bannerInsight, error, loading } = useBannerInsight();
  const { t } = useTranslation(['common']);

  return (
    <QueryStateGuard error={error} loading={loading}>
      {bannerInsight && (
        <HeroBanner
          calendarDate={{ start: bannerInsight.hero.firstPublishedAt }}
          tags={bannerInsight.tags}
          title={bannerInsight.hero.headline}
          src={bannerInsight.hero.heroImage.signedUrl}
        >
          <Link to={generateUrlById(Links.insight, bannerInsight.id)}>
            <Button variant="primaryLime" px={6}>
              {t('common:view-entity', { entity: t('common:article') })}
            </Button>
          </Link>
        </HeroBanner>
      )}
    </QueryStateGuard>
  );
};
