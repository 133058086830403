import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { GridItem } from '@chakra-ui/react';

import { ViewButton } from 'components/Button';
import { Panel } from 'components/Layout/Panel';
import { Links } from 'router/links';
import { SearchOutput } from 'types';
import { generateUrlByType } from 'utils/links';
import { capitalize } from 'utils/string';

import { MAX_CONNECTIONS_PREVIEW, MAX_PREVIEW } from '../constants';

interface Props {
  type: keyof SearchOutput;
  resultsLength: number;
  renderComponent: ReactNode;
}

export const ResultBlock = ({
  type,
  renderComponent,
  resultsLength,
}: Props) => {
  const { t } = useTranslation(['common']);

  const showViewAll =
    resultsLength >
    (type === 'connections' ? MAX_CONNECTIONS_PREVIEW : MAX_PREVIEW);

  return (
    <GridItem
      as={Panel}
      key={type}
      title={`${capitalize(type)} (${resultsLength})`}
      action={
        showViewAll && (
          <ViewButton
            to={generateUrlByType(Links.searchSingleType, type.toLowerCase())}
            variant="blue"
          >
            {t('common:search.view-all', {
              type: type.toLowerCase(),
            })}
          </ViewButton>
        )
      }
    >
      {renderComponent}
    </GridItem>
  );
};
