import { SectionHeading } from '../../SectionHeader';

const HEADER = {
  heading: 'Next',
  subheading:
    'Now that you have completed scoring based on importance, you will be asked to rank the same four categories of values based on the relevance criteria. Relevance refers to the extent you view a value as relevant to you and your family, meaning it can be used to help create meaningful impact in a context that matters to you.',
};

export const ImportanceToRelevance = () => {
  return (
    <SectionHeading
      containerStyling={{ mr: 'none', mb: 'auto', w: 480 }}
      color="primary.blue.default"
      heading={HEADER.heading}
      subheading={HEADER.subheading}
    />
  );
};
