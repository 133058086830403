import { ReactNode } from 'react';

import { Flex, Heading, Text } from '@chakra-ui/react';

import { useValuesCopy } from 'hooks/copy/useValueCopy';

import { OUTER_RADIUS } from '../../constants';
import { Position, ValueCategory } from '../../types';
import { TOOLTIP_POSITIONING, TOOLTIP_STYLING } from './constants';

type Props = {
  category: ValueCategory;
  renderValue: (renderFn: (value: string | null) => ReactNode) => ReactNode;
};

export const ValueDescriptionTooltip = ({ category, renderValue }: Props) => {
  const { getValueCopy } = useValuesCopy();

  const isLeft = [Position.LeftBottom, Position.LeftTop].includes(
    category.position
  );

  const stripePosition = isLeft ? { left: 0 } : { right: 0 };

  const contentMargin = isLeft ? { mr: 'auto' } : { ml: 'auto' };

  const tooltipStyling = TOOLTIP_STYLING[category.position];
  const tooltipPositioning = TOOLTIP_POSITIONING[category.position];

  return (
    <Flex
      {...tooltipStyling}
      {...tooltipPositioning}
      position="absolute"
      w={OUTER_RADIUS * 2}
      h={OUTER_RADIUS}
      background="darkGrey1"
      color="ui.white"
      _before={{
        position: 'absolute',
        top: 0,
        width: '16px',
        height: '100%',
        background: category.mainColor,
        content: '""',
        ...stripePosition,
      }}
    >
      {renderValue(
        (value) =>
          value && (
            <Flex
              p={4}
              direction="column"
              width="45%"
              gap="16px"
              {...contentMargin}
            >
              <Heading size="h1">{value}</Heading>
              <Text fontSize="md">{getValueCopy(value).description}</Text>
            </Flex>
          )
      )}
    </Flex>
  );
};
