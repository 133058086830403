import { SdgConfiguratorOutput, SdgOutput, SDGDragItem } from 'types';

export const mapSdgToDragItem = (sdg: SdgOutput): SDGDragItem => ({
  id: sdg.id,
  sdg,
  orderIndex: sdg.index,
});

export const getInitialValues = (
  sdgs: SDGDragItem[],
  idsWithNumericScore: SdgConfiguratorOutput['sdgIdsWithNumericScores'] = []
) => {
  const sortedIds = [...idsWithNumericScore]
    .sort((a, b) => b.numericScore - a.numericScore)
    .map(({ sdgId }) => sdgId);

  const sortedSdgs = sortedIds
    .map((sdgId) => sdgs.find(({ id }) => id === sdgId))
    .filter(Boolean) as SDGDragItem[];

  const initialSelected = sortedSdgs.map(({ id, sdg }, index) => ({
    id,
    sdg,
    orderIndex: index,
  }));

  const initialAvailable = sdgs.filter(
    (sdg) => !initialSelected.find(({ id }) => id === sdg.id)
  );

  return { initialAvailable, initialSelected };
};
