import { useState } from 'react';

import { ChevronRight } from '@carbon/icons-react/next';
import { Flex, Text } from '@chakra-ui/react';

import { FolderLink } from './FolderLink';

export const HoverablePaths = ({
  isDeep,
  isShared,
  paths,
}: {
  isDeep: boolean;
  isShared: boolean;
  paths: { url: string; label: string }[];
}) => {
  const [isMouseOver, setIsMouseOver] = useState(false);

  return (
    <Flex
      position="relative"
      onMouseEnter={() => {
        setIsMouseOver(true);
      }}
      onMouseLeave={() => {
        setIsMouseOver(false);
      }}
    >
      <Text>...</Text>
      {isMouseOver && (
        <Flex
          position="absolute"
          bottom="50%"
          left="50%"
          bgColor="ui.white"
          border="1px solid"
          paddingY={2}
          paddingX={4}
          borderColor="ui.grey4"
          borderRadius={2}
          width="max-content"
          zIndex={21}
          boxShadow="0px 2px 5px 0px rgba(0, 0, 0, 0.10);"
        >
          {paths.map((path, index) => (
            <Flex key={path.url} gap={2} alignItems="center">
              {!!index && <ChevronRight />}
              <FolderLink isShared={isShared} isDeep={isDeep} path={path} />
            </Flex>
          ))}
        </Flex>
      )}
    </Flex>
  );
};
