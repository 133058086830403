import { useAddConfiguratorValues } from 'hooks/onboarding/useAddConfiguratorValues';
import {
  OrganisationalValuesInput,
  ValueConfiguratorCategoryEnum,
} from 'types';

import {
  ValuesConfiguratorForm,
  organisationalValues,
} from '../ValuesConfugratorForm';

type Props = {
  valuesCategory: ValueConfiguratorCategoryEnum;
};

export const OrganisationalStep = ({ valuesCategory }: Props) => {
  const addConfiguratorValues = useAddConfiguratorValues();

  const onSubmit = (values: OrganisationalValuesInput) =>
    addConfiguratorValues({ valuesCategory, organisationalValues: values });

  return (
    <ValuesConfiguratorForm
      onSubmit={onSubmit}
      category={organisationalValues}
      valuesCategory={valuesCategory}
    />
  );
};
