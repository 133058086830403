import { ButtonsGroup } from 'components/Button/ButtonsGroup';
import { Links } from 'router/links';

import { ValuesIntroLayout } from '../../components/Layout';
import { HowToUse } from '../../components/ValuesConfigurator';

const buttons = (
  <ButtonsGroup rightButton={{ link: Links.valuesConfiguratorFirstValue }} />
);

export const HowToUsePage = () => {
  return (
    <ValuesIntroLayout
      buttons={buttons}
      bgImage="/assets/how-to-background.jpg"
      bgPosition="center"
      bgSize="cover"
      color="ui.white"
      sx={{
        _before: {
          content: '""',
          bgGradient:
            'linear-gradient(90deg, rgba(10, 30, 64, 0.75) 45.58%, rgba(10, 30, 64, 0.25) 100%);',
          position: 'absolute',
          width: '100%',
          height: '100%',
        },
      }}
    >
      <HowToUse />
    </ValuesIntroLayout>
  );
};
