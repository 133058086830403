import { gql, useMutation } from '@apollo/client';

import {
  Mutation,
  MutationMarkAllNotificationsAsReadArgs,
  NotificationCategoryEnum,
} from 'types';

import { notificationsGql } from './useLoadNotifications';

export const markAllNotificationsAsReadGql = gql`
  mutation MarkAllNotificationsAsRead($category: NotificationCategoryEnum) {
    markAllNotificationsAsRead(category: $category)
  }
`;

export const useMarkAllNotificationsAsRead = (
  category?: NotificationCategoryEnum
) => {
  const [markAllNotificationsAsReadMutation, { loading, error }] = useMutation<
    Pick<Mutation, 'markAllNotificationsAsRead'>,
    MutationMarkAllNotificationsAsReadArgs
  >(markAllNotificationsAsReadGql, {
    refetchQueries: [notificationsGql],
    variables: { category },
  });

  return {
    markAllNotificationsAsReadMutation,
    error,
    loading,
  };
};
