import { Conversation, Message, Profile } from 'types';
import { formatDate } from 'utils/date';

export const isDeletedMessage = (payload: Message) => {
  return (
    payload.__typename === 'Message' &&
    payload.conversationId &&
    payload.deletedBy &&
    payload.createdAt &&
    !payload.text &&
    payload.recipientIds
  );
};

export const formatMessageDateTime = (messageDate: number) => {
  const date = new Date(messageDate);
  const today = new Date();

  if (date.getDate() === today.getDate()) {
    return formatDate(date, 'p, ccc');
  }

  return formatDate(date, 'PP');
};

export const getGroupChatTitle = (
  otherMembers: Profile[],
  isLongFormat?: boolean
) => {
  const [firstInterlocutor] = otherMembers;

  if (isLongFormat) {
    const names = otherMembers.slice(0, 6).map(({ firstName }) => firstName);

    const comma = (index: number) => (index !== 0 ? ',' : '');

    return names.reduce((p, c, i) => `${c}${comma(i)} ${p}`, 'and more...');
  }

  return `${firstInterlocutor?.firstName} & more...`;
};

export const getGroupChatSubtitle = (conversation: Conversation) =>
  `${conversation.members.length} people in this conversation`;

export const isGroupChat = (conversation: Conversation) =>
  conversation.members.length > 2;
