import { VStack } from '@chakra-ui/react';

import { QueryStateGuard } from 'components/QueryStateGuard';
import { useHasFamilyFinishedValuesConfigurator } from 'hooks/onboarding/useHasFamilyFinishedValuesConfigurator';

import {
  FamilyValues,
  IndividualValues,
  ScoresPageHeader,
} from '../components';
import { DarkBlueCenter } from '../components/Layout';
import {
  IndividualScores,
  ThankYouSection,
  ThankYouWithSdgLink,
} from '../components/ValuesConfigurator';
import { useGetOnboardContent } from '../hooks';

export const ValuesSummaryPage = () => {
  const { hasFamilyFinished, error, loading } =
    useHasFamilyFinishedValuesConfigurator();
  const {
    onboard: {
      completedValuesTitle,
      completedValuesDescription,
      hasFamilyCharter,
    },
  } = useGetOnboardContent();

  return (
    <QueryStateGuard loading={loading} error={error}>
      <VStack gap={16}>
        <ScoresPageHeader />
        <IndividualScores />
        <IndividualValues />
        {hasFamilyFinished ? (
          <>
            {hasFamilyCharter && <FamilyValues />}
            <DarkBlueCenter>
              <ThankYouWithSdgLink
                title={completedValuesTitle}
                description={completedValuesDescription}
              />
            </DarkBlueCenter>
          </>
        ) : (
          <DarkBlueCenter>
            <ThankYouSection />
          </DarkBlueCenter>
        )}
      </VStack>
    </QueryStateGuard>
  );
};
