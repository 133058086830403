import { ReactNode } from 'react';

import { Box, Flex, Heading } from '@chakra-ui/react';

interface Props {
  title: string;
  children: ReactNode;
}

export const BorderedTile = ({ title, children }: Props) => {
  return (
    <Flex
      direction="column"
      borderColor="ui.grey4"
      borderWidth={1}
      borderRadius={2}
    >
      <Box p={4} borderBottomWidth={1}>
        <Heading size="h5">{title}</Heading>
      </Box>
      <Box p={4}>{children}</Box>
    </Flex>
  );
};
