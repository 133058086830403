import { Image } from '@chakra-ui/react';

import { ContentSection } from 'components/CmsLayout';
import { MarkdownPreview } from 'components/MarkdownPreview';
import { MultiCarousel } from 'components/MultiCarousel';
import { AdvisorAboutOutput } from 'types';

export const About = ({ about }: { about: AdvisorAboutOutput }) => {
  const { content, images } = about;

  return (
    <>
      <ContentSection title="About">
        <MarkdownPreview content={content} />
      </ContentSection>
      <MultiCarousel
        items={images}
        renderItem={(item) => (
          <Image
            src={item.signedUrl}
            w={400}
            h={266}
            objectFit="cover"
            objectPosition="center"
          />
        )}
      />
    </>
  );
};
