export const OldButton = {
  baseStyle: {
    fontWeight: '400',
    _focus: {
      outline: 'none',
    },
  },
  sizes: {
    sm: {
      fontSize: '12px',
    },
    md: {
      fontSize: '14px',
    },
    lg: {
      fontSize: '18px',
    },
  },
  variants: {
    standard: {
      border: '1px solid',
      borderColor: 'primary.blue.default',
      backgroundColor: 'primary.blue.default',
      color: 'white',
      borderRadius: 0,
      _hover: {
        backgroundColor: 'white',
        color: 'primary.blue.default',
      },
      _focus: {
        backgroundColor: 'white',
        color: 'primary.blue.default',
      },
    },
    clear: {
      border: '1px solid',
      borderColor: 'primary.blue.default',
      color: 'primary.blue.default',
      borderRadius: 0,
      _hover: {
        backgroundColor: 'white',
        border: 'none',
      },
      _focus: {
        backgroundColor: 'white',
        border: 'none',
      },
    },
    retroYellow: {
      border: '1px solid',
      borderColor: 'primary.lime.highlight',
      backgroundColor: 'primary.lime.highlight',
      color: 'primary.blue.default',
      borderRadius: 0,
      _hover: {
        border: '1px solid',
        borderColor: 'primary.lime.highlight',
        background: 'none',
        color: 'primary.lime.highlight',
      },
      _focus: {
        backgroundColor: 'white',
        color: 'primary.blue.default',
      },
    },
    retroYellowClear: {
      border: '1px solid',
      borderColor: 'primary.lime.highlight',
      color: 'primary.lime.highlight',
      borderRadius: 0,
      _hover: {
        color: 'primary.blue.default',
        backgroundColor: 'white',
        border: 'none',
      },
      _focus: {
        color: 'primary.blue.default',
        backgroundColor: 'white',
        border: 'none',
      },
    },
    primaryLime: {
      height: '48px',
      border: '1px solid',
      borderColor: '#C6D344',
      backgroundColor: '#C6D344',
      fontSize: 'md',
      color: 'primary.blue.default',
      borderRadius: '4px',
      _hover: {
        color: 'primary.blue.default',
        backgroundColor: '#E2F250',
        border: 'none',
      },
      _focus: {
        color: 'primary.blue.default',
        backgroundColor: '#AAB639',
        border: 'none',
      },
    },
    iconButton: {
      fill: 'primary.blue.default',
      bg: 'none',
      border: 'none',
      cursor: 'pointer',
      minWidth: 'auto',
      _hover: {
        bg: 'none',
        fill: 'darkBlue3',
      },
      w: 6,
      h: 6,
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'standard',
  },
};
