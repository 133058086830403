import { useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const scrollToTop = (): void => {
  if (window) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
  }
};

export const ScrollToTop = (): null => {
  const observerRef = useRef<MutationObserver | null>(null);
  const { pathname, hash } = useLocation();

  const removeObserver = (): void => {
    if (observerRef.current) {
      observerRef.current.disconnect();
    }
  };

  const scrollToHash = useCallback((): void => {
    try {
      const hashElem = document.querySelector(hash);

      if (hashElem) {
        hashElem.scrollIntoView();
        removeObserver();
      }
    } catch (err) {
      // ignore
    }
  }, [hash]);

  useEffect(() => {
    if (!hash) {
      scrollToTop();

      return undefined;
    }

    scrollToHash();
    const observer = new MutationObserver(() => {
      scrollToHash();
    });

    observerRef.current = observer;
    observer.observe(document, {
      childList: true,
      subtree: true,
    });

    return () => {
      removeObserver();
    };
  }, [pathname, hash, scrollToHash]);

  return null;
};
