export enum Category {
  Purpose,
  Governance,
  Legacy,
  Engagement,
}

export type CategoryDescription = {
  color: string;
  index: number;
  clipPath: string;
  translate: string;
  name: string;
  title: string;
  subtitle1: string;
  description1: string;
  subtitle2: string;
  description2: string;
};
