import { OnboardManagementMeetingInvitationOutput } from 'types';
import { TimezoneHandler } from 'utils/date';

import { FormType } from './types';

export const getMeetingInitialValues = (
  meetingInvitation?: OnboardManagementMeetingInvitationOutput
): FormType => {
  const {
    date: outputDate,
    location = '',
    meetingId = '',
    meetingLink = '',
    meetingPasscode = '',
  } = meetingInvitation || {};

  const { date, time, timezone } = TimezoneHandler.apiToClient(outputDate);

  return {
    date,
    time,
    timezone,
    location,
    meetingId,
    meetingLink,
    meetingPasscode,
  };
};
