import { Flex } from '@chakra-ui/react';

import { ConversationsInfiniteList } from './ConversationsInfiniteList';

export const Conversations = (): JSX.Element => {
  return (
    <Flex
      bgColor="ui.white"
      flex={1}
      borderRightWidth="1px"
      borderStyle="solid"
      borderColor="ui.grey4"
    >
      <ConversationsInfiniteList />
    </Flex>
  );
};
