import { Flex } from '@chakra-ui/react';

import { ViewButton } from 'components/Button';
import { InsightCard } from 'components/InsightCard';
import { Panel } from 'components/Layout/Panel';
import { QueryStateGuard } from 'components/QueryStateGuard';
import { useListInsights } from 'hooks/insights';
import { Links } from 'router/links';
import { InsightStatusEnum } from 'types';

export const NewsList = (): JSX.Element => {
  const { listInsights, loading, error } = useListInsights({
    filters: { statuses: [InsightStatusEnum.Published] },
  });

  return (
    <Panel
      title="Insights"
      action={
        <ViewButton variant="blue" to={Links.insights}>
          View all news & insights
        </ViewButton>
      }
    >
      <Flex w="full" direction={{ base: 'column', md: 'row' }} gap={4}>
        <QueryStateGuard
          loading={loading}
          error={error}
          errorTitle="Cannot fetch latest news"
        >
          {listInsights.slice(0, 2).map((insight) => (
            <InsightCard insight={insight} key={insight.id} />
          ))}
        </QueryStateGuard>
      </Flex>
    </Panel>
  );
};
