import { useState } from 'react';

import { StackDivider, Flex, Heading } from '@chakra-ui/react';

import { CategorySection } from './CategorySection';
import { Semicircle } from './Semicircle';
import { ValueInfo } from './ValueInfo';
import { categoryDescriptions } from './content';
import { Category } from './types';

export const ValuesEssential = () => {
  const [activeCategory, setActiveCategory] = useState(Category.Purpose);

  return (
    <Flex
      w="full"
      direction="column"
      alignItems="center"
      justifyContent="center"
      maxW="5xl"
      flex={1}
    >
      <Flex direction="column" w="full" mb={20}>
        <Heading size="h3">Why are family values essential?</Heading>
        <StackDivider
          borderColor="primary.blue.default"
          borderBottomWidth={3}
          w={14}
          mt={5}
          mb={8}
        />
        <Heading size="h2">Click each category to discover more</Heading>
      </Flex>
      <Flex
        justifyContent="space-evenly"
        w="full"
        alignItems="center"
        gap={20}
        direction={{ base: 'column', lg: 'row' }}
      >
        <Semicircle
          activeValue={activeCategory}
          setActiveValue={setActiveCategory}
        >
          {Object.entries(categoryDescriptions).map(
            ([category, description]) => (
              <CategorySection
                key={category}
                valueDescription={description}
                isActive={category === activeCategory}
                handleSelect={() => setActiveCategory(category)}
              />
            )
          )}
        </Semicircle>
        <ValueInfo category={activeCategory} />
      </Flex>
    </Flex>
  );
};
