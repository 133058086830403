import { useTranslation } from 'react-i18next';

import { QueryStateGuard } from 'components/QueryStateGuard';
import { useAdvisorByRelatedAccountId } from 'gql/advisors';
import { useProfile } from 'hooks/profiles/useProfile';
import { Links } from 'router/links';
import { AccountStatus, AdvisorOutput, Profile } from 'types';
import { generateUrlById } from 'utils/links';
import { getFullName } from 'utils/string';

import { AdvisorCardTemplate } from './AdvisorCardTemplate';

export const AdvisorCardByProfile = ({ profile }: { profile: Profile }) => {
  const { advisor, error, loading } = useAdvisorByRelatedAccountId(profile.id);

  return (
    <QueryStateGuard error={error} loading={loading}>
      {advisor && (
        <AdvisorCardTemplate
          image={advisor.hero.heroImage.signedUrl}
          headline={advisor.hero.headline}
          name={getFullName(profile.firstName, profile.lastName)}
          profileUrl={generateUrlById(Links.profile, profile.id)}
        />
      )}
    </QueryStateGuard>
  );
};

export const AdvisorCard = ({ advisor }: { advisor: AdvisorOutput }) => {
  const {
    hero: { heroImage, advisorAccountId, headline },
  } = advisor;
  const { profile, error, loading } = useProfile(advisorAccountId);
  const { t } = useTranslation(['common']);
  const isActive = profile && profile.status === AccountStatus.Active;

  return (
    <QueryStateGuard error={error} loading={loading}>
      <AdvisorCardTemplate
        image={heroImage.signedUrl}
        headline={headline}
        name={`${getFullName(profile?.firstName, profile?.lastName)} ${
          !isActive ? t('common:inactive-user') : ''
        }`}
        profileUrl={generateUrlById(Links.profile, profile?.id || '')}
      />
    </QueryStateGuard>
  );
};
