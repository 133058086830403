import { useTranslation } from 'react-i18next';

import { Flex, Text, Heading } from '@chakra-ui/react';

import { ImageTextTile } from 'components/ImageTextTile/ImageTextTile';

import { SERVICES } from '../../content/services';

export const FindService = () => {
  const { t } = useTranslation(['services']);

  return (
    <Flex
      bg="primary.blue.default"
      w="full"
      py={12}
      direction="column"
      justifyContent="center"
    >
      <Heading size="h3" textAlign="center" w="full" color="ui.white">
        {t('services:headings.find-service')}
      </Heading>

      <Text
        size="p1"
        color="ui.white"
        maxWidth={800}
        textAlign="center"
        mx="auto"
        pt={6}
        pb={14}
      >
        We are a mix of advisors, innovators, educators, experts and motivators.
        Under the direction of globally renowned practice leaders, we offer a
        range of bespoke services including immersive and challenging leadership
        development courses. All our services are directed at enhancing the key
        skills in leadership, sustainability and global strategy that we believe
        you and your family need to fulfil your unifying purpose.
      </Text>

      <Flex
        direction={{ md: 'row', base: 'column' }}
        w="full"
        justify="center"
        gap={{ md: '16px', base: '55px' }}
      >
        {SERVICES.map((service) => (
          <ImageTextTile {...service} key={service.src} variant="dark" />
        ))}
      </Flex>
    </Flex>
  );
};
