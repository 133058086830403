import { useFormik } from 'formik';

import { useNavigate } from 'react-router-dom';

import { gql, useMutation } from '@apollo/client';
import { useDisclosure } from '@chakra-ui/react';

import { useAuthContext } from 'contexts/AuthContext';
import { Links } from 'router/links';
import { Mutation, MutationSetNewPasswordWithTokenArgs } from 'types';

import { useWithMutationToast } from '../useWithMutationToast';
import { newPasswordSchema } from './validation';

type FormikValues = {
  password: string;
  confirmPassword: string;
};

export const setNewPasswordWithTokenGql = gql`
  mutation SetNewPasswordWithToken($data: SetNewPasswordWithTokenInput!) {
    setNewPasswordWithToken(data: $data)
  }
`;

const useSetNewPasswordWithTokenMutation = () => {
  const [mutate, { error, loading }] = useMutation<
    Pick<Mutation, 'setNewPasswordWithToken'>,
    MutationSetNewPasswordWithTokenArgs
  >(setNewPasswordWithTokenGql, { refetchQueries: ['Account'] });

  const handleMutate = useWithMutationToast(mutate, {
    successMessage: 'Successfully changed a password',
    errorMessage: 'Failed changing a password',
  });

  return { handleMutate, error, loading };
};

export const useSetNewPasswordWithToken = () => {
  const { handleMutate, error, loading } = useSetNewPasswordWithTokenMutation();

  const navigate = useNavigate();
  const { secondFactorJWT } = useAuthContext();
  const { onOpen, onClose, isOpen } = useDisclosure();

  const formik = useFormik<FormikValues>({
    initialValues: { password: '', confirmPassword: '' },
    onSubmit: ({ password }) => {
      handleMutate({
        variables: { data: { password, jwt: secondFactorJWT || '' } },
      })
        .then(() => {
          navigate(Links.forgotPasswordGoToPlatform);
        })
        .catch(() => {
          onOpen();
        });
    },
    validationSchema: newPasswordSchema,
  });

  return { formik, error, loading, onClose, isOpen };
};
