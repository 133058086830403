import { useQuery } from '@apollo/client';

import { Query, QueryGetTrashesFromLibraryArgs } from 'types';

import { getTrashesFromLibraryGql } from '../documents';

export const useGetTrashesFromLibrary = ({
  skip,
  libraryType,
}: QueryGetTrashesFromLibraryArgs & {
  skip?: boolean;
}) => {
  const { data, error, loading, refetch } = useQuery<
    Pick<Query, 'getTrashesFromLibrary'>,
    QueryGetTrashesFromLibraryArgs
  >(getTrashesFromLibraryGql, { skip, variables: { libraryType } });

  return { resources: data?.getTrashesFromLibrary, error, loading, refetch };
};
