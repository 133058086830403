import {
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

import { ItriomButtonOutline } from 'components/Button';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  message: string;
};

export const ResetPasswordFailedModal = ({
  onClose,
  isOpen,
  message,
}: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay bg="#0A1E40BF" backdropFilter="auto" backdropBlur="6px" />
      <ModalContent p={4} borderRadius={8}>
        <ModalHeader>
          <Heading size="h5">Reset Password</Heading>
        </ModalHeader>
        <ModalBody>{message}</ModalBody>
        <ModalFooter justifyContent="space-between">
          <ItriomButtonOutline onClick={onClose}>Cancel</ItriomButtonOutline>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
