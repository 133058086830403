import { Flex, Heading, Wrap, Text } from '@chakra-ui/react';

import { Avatar } from 'components/Avatar';
import { ColouredTag } from 'components/ItriomTag';
import { Panel } from 'components/Layout/Panel';
import { useAcceptGroupInvitation, useJoinGroup } from 'hooks/groups';
import { Group } from 'types';

import { DetailsSection } from './DetailsSection';
import { GroupTypeTag } from './GroupTypeTag';
import { PreviewHero } from './PreviewHero';

export const GroupPreview = ({ group }: { group: Group }) => {
  const { acceptGroupInvitation } = useAcceptGroupInvitation();
  const { joinGroup } = useJoinGroup();

  const {
    heroImage,
    name,
    admins = [],
    members,
    description,
    familyOnly,
    sdg,
    isInvitationPending,
    id,
  } = group;

  const handleJoinGroup = () => {
    if (isInvitationPending) {
      return acceptGroupInvitation(id);
    }

    return joinGroup(id);
  };

  return (
    <Flex direction="column" pt={4} gap={4}>
      <PreviewHero
        name={name}
        heroImage={heroImage}
        handleJoinGroup={handleJoinGroup}
      />

      <Panel
        title="Admins"
        contentWrapperStyling={{
          justify: admins.length === 1 ? 'center' : 'space-between',
          px: 8,
        }}
      >
        {admins.map((admin) => (
          <Avatar key={admin.id} profile={admin} />
        ))}
      </Panel>

      <Panel
        title="Group Details"
        contentWrapperStyling={{ direction: 'column', gap: 4 }}
      >
        <DetailsSection heading="Members">
          <Heading size="h3">{members.length}</Heading>
        </DetailsSection>

        <DetailsSection heading="About">
          <Text size="p1">{description}</Text>
        </DetailsSection>

        <DetailsSection heading="Privacy">
          <GroupTypeTag isFamilyGroup={familyOnly} />
        </DetailsSection>

        <DetailsSection heading="SDGs">
          <Wrap mt={2}>
            <ColouredTag tag={sdg.name} key={sdg.id} />
          </Wrap>
        </DetailsSection>
      </Panel>
    </Flex>
  );
};
