import { Fragment, useRef, useState } from 'react';

import { Text, Flex } from '@chakra-ui/react';

import { LightBoxWrapper } from 'components/LightBoxWrapper';

import { Arrow } from './Arrow';
import { Direction } from './types';

type Props<T> = {
  items: T[];
  renderItem: (item: T) => JSX.Element;
  spaceBetween?: number;
};

// if you want improve MultiCarousel, there are already improved with "swiper"
// on branch "LEIP-2073-events-created-for-future-are-displayed-in-past-events-section"

export const MultiCarousel = <T,>({
  items,
  renderItem,
  spaceBetween = 3,
}: Props<T>) => {
  const [currentImage, setCurrentImage] = useState(0);
  const imagesWrapper = useRef<HTMLDivElement>(null);
  const disabledLeft = currentImage === 0;
  const disabledRight = currentImage === items.length - 1;

  const handleClick = (direction: Direction) => {
    const isLeft = direction === Direction.Left;

    if (
      !imagesWrapper.current ||
      (disabledLeft && isLeft) ||
      (disabledRight && !isLeft)
    ) {
      return;
    }

    const step = isLeft ? -1 : 1;
    const nextImage = currentImage + step;
    setCurrentImage(nextImage);

    const imagesElements = imagesWrapper.current.children;
    const nextImageElem = imagesElements[nextImage] as HTMLElement;
    const nextImagePosition = nextImageElem?.offsetLeft;

    imagesWrapper.current.scrollTo({
      left: nextImagePosition,
      behavior: 'smooth',
    });
  };

  if (items.length === 0) return null;

  return (
    <Flex flexDir="column">
      <LightBoxWrapper
        as={Flex}
        ref={imagesWrapper}
        position="relative"
        py={4}
        gap={spaceBetween}
        overflow="auto"
      >
        {items.map((item, index) => (
          <Fragment key={index}>{renderItem(item)}</Fragment>
        ))}
      </LightBoxWrapper>

      <Flex alignSelf="flex-end" mt={2} alignItems="center" gap={2}>
        <Arrow
          direction={Direction.Left}
          onClick={() => handleClick(Direction.Left)}
          disabled={disabledLeft}
        />

        <Text>
          {currentImage + 1}/{items.length}
        </Text>

        <Arrow
          direction={Direction.Right}
          onClick={() => handleClick(Direction.Right)}
          disabled={disabledRight}
        />
      </Flex>
    </Flex>
  );
};
