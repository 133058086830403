import { useAddConfiguratorValues } from 'hooks/onboarding/useAddConfiguratorValues';
import { SocietalValuesInput, ValueConfiguratorCategoryEnum } from 'types';

import {
  ValuesConfiguratorForm,
  societalValues,
} from '../ValuesConfugratorForm';

type Props = {
  valuesCategory: ValueConfiguratorCategoryEnum;
};

export const SocietalStep = ({ valuesCategory }: Props): JSX.Element => {
  const addConfiguratorValues = useAddConfiguratorValues();

  const onSubmit = (values: SocietalValuesInput) =>
    addConfiguratorValues({ valuesCategory, societalValues: values });

  return (
    <ValuesConfiguratorForm
      onSubmit={onSubmit}
      category={societalValues}
      valuesCategory={valuesCategory}
    />
  );
};
