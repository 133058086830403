/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { gql, useMutation } from '@apollo/client';
import { Button, Tooltip } from '@chakra-ui/react';

import {
  LayoutWithBlockProps,
  LayoutWithBlock,
} from 'components/Layout/LayoutWithBlock';
import { RightBlock } from 'components/Layout/RightBlock';
import { ITRIOM_EMAIL_SUPPORT } from 'constants/itriom';
import { Cta } from 'features/Onboarding/components/Cta';
import { OtpForm } from 'features/Onboarding/components/OtpForm';
import { Links } from 'router/links';
import {
  AttendanceStatusEnum,
  Mutation,
  MutationSetAttendanceResponseArgs,
  SecondFactorVerificationTypeEnum,
} from 'types';
import { OnboardManagementMeetingInvitationOutputFragment } from 'types/generated-fragments';

import { LeftContent } from './LeftContent';
import { SupportBlock } from './SupportBlock';

export const setAttendanceResponseGql = gql`
  ${OnboardManagementMeetingInvitationOutputFragment}
  mutation SetAttendanceResponse(
    $token: String!
    $status: AttendanceStatusEnum!
  ) {
    setAttendanceResponse(token: $token, status: $status) {
      ...OnboardManagementMeetingInvitationOutputFragment
    }
  }
`;

const isValidAttendanceStatus = (
  status: string | null
): status is AttendanceStatusEnum => !!status && status in AttendanceStatusEnum;

export const RsvpPage = (props: LayoutWithBlockProps) => {
  const [showSupportMessage, setShowSupportMessage] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const choice = searchParams.get('choice');
  const token = searchParams.get('token');

  const [mutate, { data, error, loading }] = useMutation<
    Pick<Mutation, 'setAttendanceResponse'>,
    MutationSetAttendanceResponseArgs
  >(setAttendanceResponseGql);

  useEffect(() => {
    if (token && isValidAttendanceStatus(choice)) {
      mutate({ variables: { token, status: choice } }).catch((res) => {
        if (!res.data?.setAttendanceResponse) navigate(Links.rsvpExpired);
      });
    }
  }, [mutate, choice, token, navigate]);

  return (
    <LayoutWithBlock
      bgImage="/assets/backgrounds/rsvp.jfif"
      {...props}
      leftContent={
        data?.setAttendanceResponse &&
        isValidAttendanceStatus(choice) && (
          <LeftContent
            status={choice}
            setAttendanceResponse={data.setAttendanceResponse}
          />
        )
      }
    >
      {!token ? (
        <RightBlock error="Token is needed" />
      ) : !choice ? (
        <RightBlock error="Choice is needed" />
      ) : showSupportMessage ? (
        <SupportBlock token={token} />
      ) : loading || error ? (
        <RightBlock loading={loading} error={error?.message} />
      ) : (
        <OtpForm
          token={token}
          tokenType={SecondFactorVerificationTypeEnum.Rsvp}
          title="Mobile confirmation"
          subtitle="Confirm your mobile number with a One-time passcode"
          successBlock={
            <Cta title="You have successfully confirmed your mobile number and your RSVP has been received." />
          }
          footerBlock={
            <>
              Please click on
              <Button
                variant="link"
                mx={1}
                fontSize="xs"
                as="a"
                href={`mailto:${ITRIOM_EMAIL_SUPPORT}`}
                title={ITRIOM_EMAIL_SUPPORT}
              >
                <Tooltip label={ITRIOM_EMAIL_SUPPORT} aria-label="A tooltip">
                  Support
                </Tooltip>
              </Button>
              if you do not recognise the last 3 digits of the mobile number.
            </>
          }
          buttonAfterSubmit={
            <Button
              h={12}
              variant="outline"
              borderRadius={2}
              borderColor="primary.blue.default"
              color="primary.blue.default"
              fontSize="md"
              onClick={() => setShowSupportMessage(true)}
            >
              Support
            </Button>
          }
        />
      )}
    </LayoutWithBlock>
  );
};
