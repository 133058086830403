import { FormikHelpers, FormikProvider, useFormik } from 'formik';

import { SelectInput } from 'components/Form/SelectInput';
import { SetConfigStatusAsAdminInput } from 'types';
import { configuratorStatus, OnboardManagementStatus } from 'utils/status';

import { ChangeStatusModal } from './ChangeStatusModal';
import { options } from './options';
import { ConfigFormValues } from './types';
import { useSetConfigStatusAsAdmin } from './useSetConfigStatusAsAdmin';

type Props = {
  accountId: string;
  statusType: keyof Omit<SetConfigStatusAsAdminInput, 'accountId'>;
  initialStatus: OnboardManagementStatus;
};

const isEditable = (status: string) =>
  status === 'NotStarted' || status === 'OptOut';

export const InsideBox = ({ accountId, statusType, initialStatus }: Props) => {
  const { changeStatus } = useSetConfigStatusAsAdmin();

  const onSubmit = async (
    { newStatus }: ConfigFormValues,
    helpers: FormikHelpers<ConfigFormValues>
  ) => {
    await changeStatus({ accountId, [statusType]: newStatus });

    helpers.setFieldValue(initialStatus, newStatus);
    helpers.setFieldValue('newStatus', '');
  };

  const formik = useFormik<ConfigFormValues>({
    initialValues: {
      accountId,
      statusType,
      initialStatus,
      newStatus: '',
    },
    enableReinitialize: true,
    onSubmit,
  });

  const canEdit = isEditable(initialStatus);

  return (
    <FormikProvider value={formik}>
      <SelectInput<string>
        name="initialStatus"
        options={
          canEdit
            ? options
            : [
                {
                  label: configuratorStatus[initialStatus],
                  value: initialStatus,
                },
              ]
        }
        inputStyles={{
          minWidth: 130,
        }}
        disabled={!canEdit}
        onChange={(status) => {
          formik.setFieldValue('newStatus', status);
        }}
      />
      <ChangeStatusModal />
    </FormikProvider>
  );
};
