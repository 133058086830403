import { useTranslation } from 'react-i18next';

import { ArrowDown20, ArrowUp20 } from '@carbon/icons-react';
import { Button, Flex, Grid, Heading } from '@chakra-ui/react';

import { SdgIcon } from 'components/Icons/SdgIcon';
import { FamilyCharterGoalOutput } from 'types';

import { Indicator } from './Indicator';

type Props = {
  goal: FamilyCharterGoalOutput;
  toggleOpen: VoidFunction;
  isOpen: boolean;
};

export const GoalCard = ({ goal, toggleOpen, isOpen }: Props) => {
  const { t } = useTranslation(['common']);

  const { sdg, objectives, initiatives, description } = goal;

  return (
    <Grid
      position="relative"
      w="full"
      bg="ui.white"
      border="default"
      gridTemplateRows={{ base: '1fr 1fr', md: '1fr' }}
      gridTemplateColumns={{ base: '1fr', md: '2fr 4fr' }}
    >
      <SdgIcon
        name={sdg.internalName}
        variant="bgImage"
        additionalStyling={{
          gridRowStart: 1,
          gridRowEnd: 3,
          h: '100%',
          w: '100%',
          fit: 'cover',
          objectFit: 'cover',
          objectPosition: 'center',
        }}
      />
      <Grid
        p={8}
        w="full"
        gridAutoFlow="rows"
        gridTemplateRows="repeat(4, auto)"
      >
        <SdgIcon
          name={sdg.internalName}
          variant="outline"
          additionalStyling={{ w: 12, h: 12 }}
        />

        <Flex direction="column" gap={1} py={6}>
          <Heading size="h3">
            {t('common:goal_one')} {sdg.index}
          </Heading>
          <Heading size="h4">{description}</Heading>
        </Flex>

        <Flex>
          <Indicator
            label={t('common:objective', { count: objectives.length })}
            count={objectives.length}
          />
          <Indicator
            label={t('common:initiative', {
              count: initiatives.length,
            })}
            count={initiatives.length}
          />
        </Flex>

        <Flex justifyContent="flex-end">
          <Button
            variant="primaryLime"
            onClick={toggleOpen}
            p={0}
            w={12}
            h={12}
          >
            {isOpen ? <ArrowUp20 /> : <ArrowDown20 />}
          </Button>
        </Flex>
      </Grid>
    </Grid>
  );
};
