import { SliderMark } from './SliderMark';

type Props = {
  values: number[];
};

const labelStyles = {
  mt: -7,
  ml: -5,
  fontSize: 'sm',
} as const;

export const SliderLegend = ({ values }: Props): JSX.Element => {
  return (
    <>
      {values.map((value, idx, array) => {
        const styles =
          idx === 0
            ? { ...labelStyles, ml: -4 }
            : idx === array.length - 1 && { ...labelStyles, ml: -6 };

        return (
          <SliderMark
            value={value}
            styles={styles || labelStyles}
            key={value}
          />
        );
      })}
    </>
  );
};
