import { useTranslation } from 'react-i18next';

import { Share, Edit, Download, TrashCan } from '@carbon/icons-react/next';

import { MenuElement } from 'components/ThreeDotsMenu';
import { useAuthContext } from 'contexts/AuthContext';
import { useSearchContext } from 'contexts/SearchContext';
import { mapLibraryLocationToLibraryType } from 'features/Library/utils/mapLibraryLocationToLibraryType';
import { useRemoveResourceInLibrary } from 'gql/library/mutations/removeResourceInLibrary';
import { useRemoveTrashFromLibrary } from 'gql/library/mutations/removeTrashFromLibrary';
import { useRestoreResourceInLibrary } from 'gql/library/mutations/restoreResourceInLibrary';
import { useGetResourceDetailsInLibrary } from 'gql/library/query/getResourceDetailsInLibrary';
import {
  LibraryMediaOutput,
  LibrarySharedMediaOutput,
  LibraryTypeEnum,
  Role,
} from 'types';

import { useLibraryContext } from '../../context';
import { useCurrentLibraryLocation } from '../../hooks/useCurrentLibraryLocation';
import { LibraryLocation } from '../../types';
import { downloadFile } from '../../utils/downloadFile';

interface Props {
  item: LibraryMediaOutput | LibrarySharedMediaOutput;
  downloadUrl: string | undefined;
}

export const MenuElements = ({ item, downloadUrl }: Props): MenuElement[] => {
  const { account } = useAuthContext();
  const { removeResourceInLibrary } = useRemoveResourceInLibrary();
  const { restoreResourceInLibrary } = useRestoreResourceInLibrary();
  const { removeTrashFromLibrary } = useRemoveTrashFromLibrary();
  const { shareModal, setActionSingleItem, renameModal } = useLibraryContext();
  const {
    searchResults,
    setFixedSearchResults,
    setFixedLibraryItemsCount,
    fixedSearchResults,
  } = useSearchContext();
  const { t } = useTranslation(['library']);
  const { libraryLocation } = useCurrentLibraryLocation();
  const { refetch } = useGetResourceDetailsInLibrary(
    mapLibraryLocationToLibraryType(libraryLocation),
    undefined
  );

  const handleDelete = async () => {
    await removeResourceInLibrary({
      libraryType: item.libraryType,
      resourceId: item.id,
    });

    try {
      await refetch({
        libraryType: item.libraryType,
        resourceId: item.id,
      });
    } catch (err) {
      if (searchResults && fixedSearchResults) {
        const fixedResults = fixedSearchResults.filter(
          (element) => element.object.id !== item.id
        );
        setFixedSearchResults(fixedResults);
        setFixedLibraryItemsCount(fixedResults?.length);
      } else if (searchResults && !fixedSearchResults) {
        const fixedResults = searchResults.library?.nodes.filter(
          (element) => element.object.id !== item.id
        );
        setFixedSearchResults(fixedResults);
        setFixedLibraryItemsCount(fixedResults?.length);
      }
    }
  };

  const handleRename = () => {
    renameModal.onOpen(item);
  };

  const handleDownload = () => {
    if (downloadUrl) {
      downloadFile(downloadUrl, item);
    }
  };

  const handleDeleteForever = () => {
    removeTrashFromLibrary({
      libraryType: item.libraryType,
      resourceId: item.id,
    });
  };

  const handleRestore = () => {
    restoreResourceInLibrary({
      libraryType: item.libraryType,
      resourceId: item.id,
    });
  };

  const SHARE = (shareItem: LibraryMediaOutput | LibrarySharedMediaOutput) => ({
    text: t('library:actions.share'),
    icon: Share,
    handleClick: () => {
      setActionSingleItem(shareItem);
      shareModal.onOpen();
    },
  });

  const familyActionsButtons = () => {
    return [
      {
        ...SHARE(item),
        hidden: isLibraryMediaOutput(item) ? !item.isShareable : true,
      },
      DOWNLOAD,
      {
        ...RENAME,
        hidden: isLibraryMediaOutput(item) ? !item.isRenamable : true,
      },
      {
        ...DELETE,
        hidden: isLibraryMediaOutput(item) ? !item.isRemovable : true,
      },
    ];
  };

  const itriomActionButtons = () => {
    if (!account) {
      return [DOWNLOAD];
    }

    return [
      { ...SHARE(item), hidden: account.role !== Role.Admin },
      DOWNLOAD,
      { ...RENAME, hidden: account.role !== Role.Admin },
      { ...DELETE, hidden: account.role !== Role.Admin },
    ];
  };

  const ownActionsButtons = () => {
    return [SHARE(item), DOWNLOAD, RENAME, DELETE];
  };

  const favoritesActionsButtons = () => {
    if (!account) {
      return [DOWNLOAD];
    }

    const areActionsHidden = !shouldFavouritesActionBeVisible(
      item,
      isFamilyAdminOrOwner,
      account.role !== Role.Admin
    );

    return [
      DOWNLOAD,
      {
        ...SHARE(item),
        hidden: areActionsHidden,
      },
      { ...RENAME, hidden: areActionsHidden },
      { ...DELETE, hidden: areActionsHidden },
    ];
  };

  const returnOutsideLibraryType = () => {
    if (account) {
      const isResourceShared =
        item.shares.length > 0
          ? item.shares.some((member) => member.id === account.id)
          : false;

      if (isResourceShared) {
        return [DOWNLOAD];
      }

      return [
        {
          ...SHARE(item),
          hidden: isLibraryMediaOutput(item) ? !item.isShareable : true,
        },
        DOWNLOAD,
        {
          ...RENAME,
          hidden: isLibraryMediaOutput(item) ? !item.isRenamable : true,
        },
        {
          ...DELETE,
          hidden: isLibraryMediaOutput(item) ? !item.isRemovable : true,
        },
      ];
    }

    return [DOWNLOAD];
  };

  const RENAME = {
    text: t('library:actions.rename'),
    icon: Edit,
    handleClick: handleRename,
  };

  const DOWNLOAD = {
    text: t('library:actions.download'),
    icon: Download,
    handleClick: handleDownload,
  };

  const DELETE = {
    text: t('library:actions.delete'),
    icon: TrashCan,
    handleClick: handleDelete,
  };

  const RESTORE = {
    text: t('library:actions.restore'),
    icon: Download,
    handleClick: handleRestore,
  };

  const DELETE_FOREVER = {
    text: t('library:actions.delete-forever'),
    icon: TrashCan,
    handleClick: handleDeleteForever,
  };

  if (!account) {
    return [];
  }

  const isLibraryMediaOutput = (
    input: LibraryMediaOutput | LibrarySharedMediaOutput
  ): input is LibraryMediaOutput =>
    (input as LibraryMediaOutput).isRemovable !== undefined &&
    (input as LibraryMediaOutput).isRenamable !== undefined &&
    (input as LibraryMediaOutput).isShareable !== undefined;

  const isFamilyAdminOrOwner =
    account.role === Role.FamilyAdmin || account.id === item.libraryOwner;

  switch (libraryLocation) {
    case LibraryLocation.My:
      return ownActionsButtons();

    case LibraryLocation.Family: {
      return familyActionsButtons();
    }

    case LibraryLocation.SearchLibraryContext: {
      return returnOutsideLibraryType();
    }

    case LibraryLocation.Favourites: {
      return favoritesActionsButtons();
    }

    case LibraryLocation.Itriom:
      return itriomActionButtons();
    case LibraryLocation.SharedWithMe:
      return [DOWNLOAD];
    case LibraryLocation.RecentlyDeleted:
      return [RESTORE, DELETE_FOREVER];
    default:
      return [];
  }
};

const shouldFavouritesActionBeVisible = (
  item: LibraryMediaOutput | LibrarySharedMediaOutput,
  isFamilyAdminOrOwner: boolean,
  isAdmin: boolean
) => {
  if (item.libraryType === LibraryTypeEnum.Own) {
    return true;
  }

  if (item.libraryType === LibraryTypeEnum.Family) {
    return isFamilyAdminOrOwner;
  }

  if (item.libraryType === LibraryTypeEnum.Itriom) {
    return isAdmin;
  }

  return false;
};
