import { Flex, Text } from '@chakra-ui/react';

import { SECTION_SPACING, CONTENT_PADDING_X } from '../constants';
import { MarketplaceHeading } from './MarketplaceHeading';

export const QuoteSection = () => (
  <Flex
    py={SECTION_SPACING}
    px={CONTENT_PADDING_X}
    w="full"
    h="fit-content"
    color="ui.white"
    position="relative"
    bg="ui.white"
  >
    <Flex
      bgImage="/assets/marketplace/quote-section-background.png"
      direction="column"
      bgSize="cover"
      bgPosition={{ lg: 'right', md: '75%', base: '75%' }}
      zIndex={10}
      py={{ lg: '83px', md: '68px', base: '44px' }}
      pl={{ lg: '97px', md: '72px', base: '30px' }}
      pr={{ lg: '166px', md: '171px', base: '30px' }}
      gap={8}
    >
      <MarketplaceHeading
        lineHeight={{ lg: '44px', md: '40px', base: '34px' }}
        fontStyle="italic"
        fontSize={{ md: 30, base: 24 }}
        fontWeight="medium"
        textAlign="left"
      >
        “We are delighted to partner with Envestors to provide a seamless
        marketplace for targeted and meaningful impact investment which aligns
        with the families charter and goals. Through our dashboards we will also
        ensure accurate reporting of these investments to demonstrate the
        measurable, tangible, sustainable and positive change”
      </MarketplaceHeading>
      <Flex direction="column" justifyContent="flex-start" mr="auto">
        <Text size="p1b">Matthew Millard-Beer</Text>
        <Text size="p1">Chief Executive | Itriom</Text>
      </Flex>
    </Flex>
  </Flex>
);
