import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonProps, useDisclosure } from '@chakra-ui/react';

import { ItriomButtonOutline } from 'components/Button';
import { useUpdateFamilyHead } from 'gql/family/mutation/updateFamilyHead';
import { useChangeAccountStatus } from 'hooks/profiles/useChangeAccountStatus';
import { AccountStatus, ExtendedProfileOutput } from 'types';

import { ChangeModal } from '../Onboarding/ChangeModal';
import { ModalType } from '../Onboarding/constants';

interface Props {
  member: ExtendedProfileOutput;
  buttonStyle?: ButtonProps;
}

export const UserActivationButton = ({ member, buttonStyle }: Props) => {
  const [modalType, setModalType] = useState<ModalType>('activation');
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { updateFamilyHead } = useUpdateFamilyHead();
  const { changeAccountStatus } = useChangeAccountStatus();
  const { t } = useTranslation(['cms']);

  const familyId = member.family?.id;
  const isActive = member.status === AccountStatus.Active;

  const openModal = () => {
    if (member.isHeadOfFamily) {
      setModalType('assignment');
    } else {
      setModalType('activation');
    }

    onOpen();
  };

  const handleAssign = async (newFamilyHeadUserId?: string) => {
    if (!newFamilyHeadUserId || !familyId) {
      return;
    }

    await updateFamilyHead({ familyId, newFamilyHeadUserId });
    setModalType('activation');
  };

  const handleActivationStatusChange = async () => {
    await changeAccountStatus({
      accountId: member.id,
      status: isActive ? AccountStatus.Inactive : AccountStatus.Active,
    });

    onClose();
  };

  return (
    <>
      <ItriomButtonOutline onClick={openModal} {...buttonStyle}>
        {t(`cms:${isActive ? 'deactivate' : 'activate'}.action`)}
      </ItriomButtonOutline>
      <ChangeModal
        handleConfirm={
          modalType === 'assignment'
            ? handleAssign
            : handleActivationStatusChange
        }
        familyId={familyId}
        isOpen={isOpen}
        modalType={modalType}
        member={member}
        onClose={onClose}
      />
    </>
  );
};
