import { ReactNode } from 'react';

import { Center, Flex, FlexProps } from '@chakra-ui/react';

import { ButtonsGroup } from 'components/Button/ButtonsGroup';
import { About } from 'components/ValuesConfiguratorIntro/About';
import { ValuesCategorisation } from 'components/ValuesConfiguratorIntro/ValuesCategorisation';
import { ValuesEssential } from 'components/ValuesConfiguratorIntro/ValuesEssential';
import { WheelModel } from 'components/ValuesConfiguratorIntro/WheelModel';

const commonProps: FlexProps = {
  w: '100dvw',
  h: '100vh',
  direction: 'column',
  justify: 'center',
  align: 'center',
  p: { base: 4, md: 12 },
};

export enum ValuesIntroScreen {
  AboutPage,
  Essentials,
  Wheel,
  Categorisation,
}

type MultistepConfig = Record<ValuesIntroScreen, ReactNode>;

export const getMultistepConfig = (
  onClose: VoidFunction,
  setCurrentScreen: (screen: ValuesIntroScreen) => void
): MultistepConfig => ({
  [ValuesIntroScreen.AboutPage]: (
    <Flex color="ui.white" background="primary.blue.default" {...commonProps}>
      <About />
      <ButtonsGroup
        rightButton={{
          onClick: () => setCurrentScreen(ValuesIntroScreen.Essentials),
        }}
      />
    </Flex>
  ),
  [ValuesIntroScreen.Essentials]: (
    <Flex background="ui.white" color="primary.blue.default" {...commonProps}>
      <ValuesEssential />
      <ButtonsGroup
        leftButton={{
          color: 'primary.blue.default',
          onClick: () => setCurrentScreen(ValuesIntroScreen.AboutPage),
        }}
        rightButton={{
          onClick: () => setCurrentScreen(ValuesIntroScreen.Categorisation),
        }}
      />
    </Flex>
  ),
  [ValuesIntroScreen.Categorisation]: (
    <Flex background="#C7CFD4" color="primary.blue.default" {...commonProps}>
      <ValuesCategorisation />
      <ButtonsGroup
        leftButton={{
          color: 'primary.blue.default',
          onClick: () => setCurrentScreen(ValuesIntroScreen.Essentials),
        }}
        rightButton={{
          onClick: () => setCurrentScreen(ValuesIntroScreen.Wheel),
        }}
      />
    </Flex>
  ),
  [ValuesIntroScreen.Wheel]: (
    <Flex background="#C7CFD4" color="primary.blue.default" {...commonProps}>
      <Center w="full" h="full">
        <WheelModel />
      </Center>
      <ButtonsGroup
        leftButton={{
          color: 'primary.blue.default',
          onClick: () => setCurrentScreen(ValuesIntroScreen.Categorisation),
        }}
        rightButton={{
          text: 'Go to your Charter page',
          onClick: onClose,
        }}
      />
    </Flex>
  ),
});
