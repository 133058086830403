import { Link as RouterLink, useLocation } from 'react-router-dom';

import { Flex, Link, Text } from '@chakra-ui/react';

import { Links } from 'router/links';

type Props = {
  to: string;
  label: string;
};

export const HeaderLink = ({ to, label }: Props): JSX.Element => {
  const { pathname } = useLocation();

  const active =
    (to === Links.home && pathname.length === 1) || pathname === to;

  return (
    <Link
      as={RouterLink}
      h="full"
      to={to}
      borderBottom="thick"
      borderBottomStyle="solid"
      borderBottomColor={active ? 'primary.lime.highlight' : 'transparent'}
      transition="border-color 200ms linear"
      {...(!active && {
        color: 'ui.grey2',
      })}
      _hover={{
        textDecoration: 'none',
        color: 'primary.blue.default',
        borderBottom: 'thick',
        borderBottomStyle: 'solid',
        borderBottomColor: 'primary.lime.highlight',
      }}
    >
      <Flex h="full" alignItems="center" pt={2}>
        <Text as="span" size="p1">
          {label}
        </Text>
      </Flex>
    </Link>
  );
};
