import { useNavigate, useParams } from 'react-router-dom';

import { Grid } from '@chakra-ui/react';

import { Avatar } from 'components/Avatar';
import { DraggableProvider } from 'components/Draggable';
import { LayoutGrid } from 'components/Layout/Grid';
import { Panel } from 'components/Layout/Panel';
import { useSearchContext } from 'contexts/SearchContext';
import { PreviewModal } from 'features/Library/components/PreviewModal';
import { RenameModal } from 'features/Library/components/RenameModal';
import { ShareModal } from 'features/Library/components/ShareModal';
import { LibraryProvider } from 'features/Library/context';
import { EventResult } from 'features/Search/components/EventResult';
import { Heading } from 'features/Search/components/Heading';
import { SimpleResult } from 'features/Search/components/SimpleResult';
import {
  MAX_CONNECTIONS_PREVIEW,
  MAX_PREVIEW,
} from 'features/Search/constants';
import { isSearchResultType } from 'features/Search/guards';
import { Links } from 'router/links';
import {
  Profile,
  Event,
  InitiativeOutput,
  SearchOutput,
  Insight,
  LibraryMediaOutput,
  SearchLibraryResultOutput,
} from 'types';

import { LibraryResults } from '../components/LibraryResults';
import { Loading } from '../components/Loading';

const getGridColumns = (searchType: keyof NonNullable<SearchOutput>) => {
  if (searchType === 'connections')
    return `repeat(${MAX_CONNECTIONS_PREVIEW}, 1fr)`;

  return `repeat(${MAX_PREVIEW}, 1fr)`;
};

export const SearchPageSingleType = () => {
  const { type } = useParams();
  const { searchResults, loading, fixedSearchResults } = useSearchContext();
  const searchType = type?.toLowerCase();
  const navigate = useNavigate();

  if (!searchType || !isSearchResultType(searchType, searchResults)) {
    navigate(Links.search);

    return null;
  }

  return (
    <LibraryProvider>
      <DraggableProvider>
        <LayoutGrid>
          <Grid py={6} gap={6} w="full">
            <Heading />
            {loading && <Loading />}
            <Panel>
              {searchType === 'library' ? (
                <Grid w="inherit" px={3}>
                  <LibraryResults
                    results={
                      (searchResults.library
                        ?.nodes as SearchLibraryResultOutput[]) ?? []
                    }
                    fixedResults={fixedSearchResults}
                    showAllResults
                  />
                </Grid>
              ) : (
                <Grid
                  w="inherit"
                  px={6}
                  justifyContent="space-evenly"
                  templateColumns={getGridColumns(searchType)}
                  gap={12}
                >
                  {searchResults[searchType]?.nodes.map((node) =>
                    getResultComponent(searchType, node.object)
                  )}
                </Grid>
              )}
            </Panel>
          </Grid>
          <ShareModal />
          <PreviewModal />
          <RenameModal />
        </LayoutGrid>
      </DraggableProvider>
    </LibraryProvider>
  );
};

const getResultComponent = (
  searchType: keyof SearchOutput,
  result: Insight | InitiativeOutput | Profile | Event | LibraryMediaOutput
) => {
  switch (searchType) {
    case 'connections':
      return <Avatar profile={result as Profile} key={result.id} />;
    case 'events':
      return <EventResult result={result as Event} key={result.id} />;
    case 'initiatives':
    case 'insights':
      return (
        <SimpleResult
          result={result as InitiativeOutput | Insight}
          key={result.id}
        />
      );
    case 'library':
      return null;
    default:
      return null;
  }
};
