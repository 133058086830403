import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { StateValueCategory } from '../types';

type BuildingAnimationState = {
  currentCategory: StateValueCategory | undefined;
  setCategory: (category: StateValueCategory | undefined) => void;
  hasFinishedAnimating: boolean;
  setFinishedAnimating: () => void;
};

const BuildingAnimationStateContext =
  createContext<BuildingAnimationState | null>(null);

type Props = {
  children: ReactNode;
};

export const BuildingAnimationStateContextProvider = ({ children }: Props) => {
  const [currentCategory, setCurrentCategory] = useState<
    StateValueCategory | undefined
  >(null);

  const [hasFinishedAnimating, setFinishedAnimating] = useState(false);

  const handleFinishAnimating = useCallback(
    () => setFinishedAnimating(true),
    []
  );

  const setCategory = useCallback(
    (category: StateValueCategory | undefined) => setCurrentCategory(category),
    []
  );

  const value: BuildingAnimationState = useMemo(
    () => ({
      currentCategory,
      hasFinishedAnimating,
      setCategory,
      setFinishedAnimating: handleFinishAnimating,
    }),
    [currentCategory, hasFinishedAnimating, handleFinishAnimating, setCategory]
  );

  return (
    <BuildingAnimationStateContext.Provider value={value}>
      {children}
    </BuildingAnimationStateContext.Provider>
  );
};

export const useBuildingAnimationContext = (): BuildingAnimationState => {
  const context = useContext(BuildingAnimationStateContext);

  if (!context) {
    throw new Error("useAnimationContext used outside of it's provider");
  }

  return context;
};
