import { Flex, List, ListIcon, ListItem, Text } from '@chakra-ui/react';

import { WithChildren } from 'types';

import { CheckIcon } from '../../CheckIcon';

const StyledCheckIcon = () => (
  <CheckIcon color="primary.lime.default" diameter={6} fontSize="sm" />
);

const ListItemWithCheck = ({ children }: WithChildren) => (
  <ListItem mb={4}>
    <Flex gap={3}>
      <ListIcon as={StyledCheckIcon} />
      {children}
    </Flex>
  </ListItem>
);

export const WhatAreSdgs = () => {
  return (
    <Flex direction="column" maxW={500} gap={2}>
      <Text size="p1">
        Central to the 2030 Agenda for Sustainable Development adopted by all
        United Nations Member States in 2015
      </Text>
      <Text size="p1">
        17 global goals with 169 measurable targets, formulated to help solve
        the world's greatest challenges by 2030 through global partnership
      </Text>
      <Text size="p1">
        Combine the cross-cutting themes of ending deprivation with making
        measurable improvements in:
      </Text>
      <List mt={8}>
        <ListItemWithCheck>
          Improving health and education worldwide
        </ListItemWithCheck>
        <ListItemWithCheck>Tackling climate change</ListItemWithCheck>
        <ListItemWithCheck>Promoting economic growth for all</ListItemWithCheck>
      </List>
    </Flex>
  );
};
