import { EmptyState } from 'components/EmptyState/EmptyState';
import { InitiativeType } from 'features/Initiative/types';
import { Links } from 'router/links';

export const InitiativesEmptyState = ({
  showFollowedInitiatives,
}: {
  showFollowedInitiatives: boolean;
}) => {
  return showFollowedInitiatives ? (
    <EmptyState
      title="You are not following any initiatives"
      buttonName="Find initiatives to follow"
      buttonLink={`${Links.initiatives}#${InitiativeType.All}`}
      containerStyles={{ '&>img': { filter: 'grayscale(1)' } }}
    />
  ) : (
    <EmptyState
      title="There are no initiatives to display"
      buttonName="View Itriom initiatives"
      // TODO: use enum but after Tabs refactor https://itriom.atlassian.net/browse/LEIP-2374
      buttonLink={`${Links.initiatives}#Itriom`}
    />
  );
};
