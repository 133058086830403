import { Flex, IconButton, MenuButton } from '@chakra-ui/react';

type NotificationsButtonProps = {
  notificationsUnreadCount: number;
  icon: React.ReactNode;
};

export const NotificationsButton = ({
  notificationsUnreadCount,
  icon,
}: NotificationsButtonProps) => (
  <MenuButton
    as={IconButton}
    isRound
    aria-label="Open notifications"
    variant="ghost"
    display="flex"
    justifyContent="center"
    alignItems="center"
    position="relative"
    icon={
      <>
        {icon}
        {!!notificationsUnreadCount && (
          <Flex
            w={4}
            h={4}
            borderRadius={10}
            bgColor="primary.lime.highlight"
            color="primary.blue.default"
            position="absolute"
            justify="center"
            align="center"
            fontSize={8}
            fontWeight={700}
            top={-0.5}
            right={-0.5}
          >
            {notificationsUnreadCount}
          </Flex>
        )}
      </>
    }
  />
);
