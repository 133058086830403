import { ReactNode, useEffect, useRef, useState } from 'react';

import { Button, ButtonProps } from '@chakra-ui/react';

import { WithChildren } from 'types';

type RightIcon = ButtonProps['rightIcon'];

type HoverButtonProps = {
  hover?: {
    content?: ReactNode;
    rightIcon?: RightIcon;
  };
} & ButtonProps;

export const HoverButton = ({
  children,
  rightIcon,
  hover,
  ...props
}: WithChildren<HoverButtonProps>) => {
  const [isHover, setIsHover] = useState(false);

  const ref = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    const buttonRef = ref.current;

    const mouseEnterListener = () => {
      if (!hover) return;

      setIsHover(true);
    };

    const mouseLeaveListener = () => {
      if (!hover) return;

      setIsHover(false);
    };

    buttonRef?.addEventListener('mouseenter', mouseEnterListener);
    buttonRef?.addEventListener('mouseleave', mouseLeaveListener);

    return () => {
      buttonRef?.removeEventListener('mouseenter', mouseEnterListener);
      buttonRef?.removeEventListener('mouseleave', mouseLeaveListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shouldDisplayHover = isHover && !!hover;

  return (
    <Button
      ref={ref}
      bg="primary.lime.default"
      color="primary.blue.default"
      border="none"
      _hover={{
        bg: 'primary.lime.highlight',
      }}
      _focus={{
        outline: 'primary.lime.highlight',
      }}
      rightIcon={shouldDisplayHover ? hover.rightIcon : rightIcon}
      {...props}
    >
      {shouldDisplayHover ? hover.content : children}
    </Button>
  );
};
