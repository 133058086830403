import { QueryStateGuard } from 'components/QueryStateGuard';

import { GroupContextProvider, useGroupContext } from '../GroupContext';
import { MemberGroupPage } from './MemberGroupPage';
import { PreviewGroupPage } from './PreviewGroupPage';

const GroupPageContent = () => {
  const { canSeeGroup, loading, error, isMember } = useGroupContext();
  return (
    <QueryStateGuard loading={loading} error={error}>
      {isMember || canSeeGroup ? <MemberGroupPage /> : <PreviewGroupPage />}
    </QueryStateGuard>
  );
};

export const GroupPage = () => {
  return (
    <GroupContextProvider>
      <GroupPageContent />
    </GroupContextProvider>
  );
};
