import { mixed, object, string, boolean, array } from 'yup';

import { ErrorMessages } from 'utils/validation';

const NAME_MAX_LENGTH = 100;
const DESCRIPTION_MAX_LENGTH = 200;
const MAX_ADVISORS_COUNT = 3;

export const validationGroupSchema = object().shape({
  heroImage: mixed().required('Please upload a banner image for your group.'),
  avatarImage: mixed().required('Please upload a avatar image for your group.'),
  name: string()
    .required('Please provide a Name for the Group.')
    .max(NAME_MAX_LENGTH, ErrorMessages.MAX('Group Name', NAME_MAX_LENGTH)),
  description: string()
    .required('Please provide a short description for About this Group.')
    .max(
      DESCRIPTION_MAX_LENGTH,
      ErrorMessages.MAX('Short Description', DESCRIPTION_MAX_LENGTH)
    ),
  category: string().required('Please choose a category for your group'),
  type: string().nullable(),
  isPublic: boolean(),
  familyOnly: boolean(),
  automaticallyAddFamilyMembers: boolean(),
  allowInvites: boolean(),
  sdgId: string().required('Please choose a SDG for your group'),
  advisorIds: array().max(
    MAX_ADVISORS_COUNT,
    `You can choose maximum ${MAX_ADVISORS_COUNT} Advisors for group.`
  ),
});
