import {
  AdvisorContextProvider,
  useAdvisorContext,
} from 'contexts/AdvisorContext';

import { AdvisorPreview } from '../components/AdvisorPreview';

const Content = () => {
  const advisor = useAdvisorContext();

  return <AdvisorPreview advisor={advisor} />;
};

export const AdvisorPage = (): JSX.Element => {
  return (
    <AdvisorContextProvider>
      <Content />
    </AdvisorContextProvider>
  );
};
