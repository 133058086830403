import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useIsOnboarding = () => {
  const { pathname } = useLocation();

  const isOnboarding = useMemo(() => {
    const moduleFromPath = pathname.split('/')[1];

    return moduleFromPath === 'onboarding';
  }, [pathname]);

  return isOnboarding;
};
