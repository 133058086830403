import { Link } from 'react-router-dom';

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
} from '@chakra-ui/react';

import { Links } from 'router/links';

interface BreadcrumbItem {
  link: Links | string;
  label: string;
}
interface Props {
  items: BreadcrumbItem[];
}

export const Breadcrumbs = ({ items }: Props) => {
  return (
    <Breadcrumb>
      {items.map((item, i) => {
        const isLastItem = items.length - 1 === i;

        return (
          <BreadcrumbItem key={item.link} isCurrentPage={isLastItem}>
            <BreadcrumbLink
              as={Link}
              to={item.link}
              {...(isLastItem && {
                _hover: { textDecoration: 'none' },
                cursor: 'default',
              })}
            >
              <Text size="p2" {...(isLastItem && { color: 'ui.grey2' })}>
                {item.label}
              </Text>
            </BreadcrumbLink>
          </BreadcrumbItem>
        );
      })}
    </Breadcrumb>
  );
};
