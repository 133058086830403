import { Flex, Text, VStack, Heading } from '@chakra-ui/react';

type Props = {
  name: string;
  description: string;
};

export const ValueDescription = ({ description, name }: Props) => {
  return (
    <VStack
      background="darkBlue2"
      color="ui.white"
      padding={6}
      gap={1}
      borderRadius={10}
      w="100%"
      minH={150}
    >
      <Flex
        justifyContent="center"
        background="primary.blue.default"
        w="100%"
        p={1}
      >
        <Heading size="h4">{name}</Heading>
      </Flex>

      <Text size="p2">{description}</Text>
    </VStack>
  );
};
