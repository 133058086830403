import { ReactNode } from 'react';
import { Link, To } from 'react-router-dom';

import { ArrowRight } from '@carbon/icons-react/next';
import { Text, Flex, Image, FlexProps } from '@chakra-ui/react';

import { underlay } from 'theme/mixins/underlay';

type Props = {
  heroImageUrl?: string;
  title: string;
  subTitle?: string;
  href: To;
  actionMenu?: ReactNode;
  canRedirect?: boolean;
} & FlexProps;

export const CardWithImageBase = ({
  heroImageUrl,
  title,
  subTitle,
  href,
  actionMenu,
  canRedirect,
  ...flexProps
}: Props) => {
  return (
    <Flex w={145} h={200} pos="relative" {...flexProps}>
      <Flex
        pos="relative"
        as={Link}
        to={canRedirect ? href : '#'}
        w="full"
        h="full"
        {...underlay({ direction: 'column' })}
      >
        <Image
          src={heroImageUrl}
          w="full"
          h="full"
          objectFit="cover"
          objectPosition="center"
          alt={title}
        />

        <Flex
          direction="column"
          p={3}
          w="full"
          pos="absolute"
          left={0}
          bottom={0}
          color="ui.white"
          h={90}
          justify="flex-end"
          gap={2}
        >
          <Text size="p2">{title}</Text>

          <Flex align="flex-end" gap={2}>
            <Text size="p3" noOfLines={2} flex={1}>
              {subTitle}
            </Text>
            {canRedirect && (
              <Flex color="ui.white">
                <ArrowRight />
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>

      {actionMenu && (
        <Flex pos="absolute" top={2} right={2} color="ui.white">
          {actionMenu}
        </Flex>
      )}
    </Flex>
  );
};
