import { useNavigate } from 'react-router-dom';

import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { useToast } from '@chakra-ui/react';

import { useAuthContext } from 'contexts/AuthContext';

import { Links } from '../../router/links';
import { Mutation, MutationSignInArgs } from '../../types';

const signInGql = gql`
  mutation SignIn($password: String!, $email: String!) {
    signIn(password: $password, email: $email)
  }
`;

export const useSignIn = <
  TData extends Pick<Mutation, 'signIn'>,
  TVariables extends MutationSignInArgs
>(
  options?: MutationHookOptions<TData, TVariables>
) => {
  const { setSecondFactorJWT } = useAuthContext();
  const toast = useToast();
  const navigate = useNavigate();

  const [mutation, { loading, error }] = useMutation<TData, TVariables>(
    signInGql,
    {
      onCompleted: (data) => {
        setSecondFactorJWT(data.signIn);
        navigate(Links.twoFA);
      },
      onError(err) {
        toast({
          title: 'Sign-in error',
          description: err.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      },
      ...options,
    }
  );

  const signIn = (variables: TVariables) => mutation({ variables });

  return {
    signIn,
    loading,
    error,
  };
};
