import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { ArrowRight } from '@carbon/icons-react/next';
import { As, Flex, FlexProps, Link as ChakraLink } from '@chakra-ui/react';

import { ConditionalWrapper } from 'components/ConditionalWrapper';
import { WithOptionalChildren } from 'types';

type Props = {
  to?: string;
  onClick?: () => void;
  variant?: 'blue' | 'white';
  containerStyling?: FlexProps;
  rightIcon?: ReactNode;
  leftIcon?: ReactNode;
};

export const ViewButton = ({
  to,
  onClick,
  children,
  variant = 'white',
  containerStyling,
  leftIcon,
  rightIcon = <ArrowRight />,
}: WithOptionalChildren<Props>) => {
  const baseColor: FlexProps['color'] =
    variant === 'white' ? 'ui.white' : 'primary.blue.default';

  const hoverColor: FlexProps['color'] =
    variant === 'white' ? 'ui.white' : 'primary.blue.highlight';

  const withAnchorWrapper = to?.includes('http');

  const getRedirectProps = () => {
    if (withAnchorWrapper) return {};

    if (!to) return { onClick };

    return { as: Link, to, onClick };
  };

  const redirectProps = getRedirectProps();

  return (
    <ConditionalWrapper
      Wrapper={withAnchorWrapper && ChakraLink}
      props={{ as: 'a' as As, href: to, onClick }}
    >
      <Flex
        color={baseColor}
        gap={2}
        align="center"
        fontSize={14}
        lineHeight={1}
        cursor="pointer"
        _hover={{
          textDecoration: 'underline',
          color: hoverColor,
        }}
        as="button"
        {...redirectProps}
        {...containerStyling}
      >
        {leftIcon && leftIcon}
        {children}
        {rightIcon && rightIcon}
      </Flex>
    </ConditionalWrapper>
  );
};
