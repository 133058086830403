import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectInput } from 'components/Form/SelectInput';
import { useEsgCategories } from 'hooks/useEsgCategories';

import { selectInputStyles } from './styles';

export const GroupEsgFocusInput = () => {
  const { t } = useTranslation(['groups']);
  const { esgCategories, loading, error } = useEsgCategories();

  const esgOptions = useMemo(
    () =>
      esgCategories.map((esgCategory) => ({
        label: esgCategory.name,
        value: esgCategory.id,
      })),
    [esgCategories]
  );

  return (
    <SelectInput
      name="category"
      label={t('groups:manage-modal.esg-focus.title')}
      options={esgOptions}
      loading={loading}
      error={error}
      defaultOptionText="Select"
      inputStyles={selectInputStyles}
    />
  );
};
