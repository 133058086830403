import { useTranslation } from 'react-i18next';

import { Flex, Grid, Text } from '@chakra-ui/react';

import { ViewButton } from 'components/Button';
import { Section } from 'components/Layout/Sections/Section';
import { useAuthContext } from 'contexts/AuthContext';
import { useFamilyCharterByFamilyId } from 'hooks/familyCharter/useFamilyCharterByFamilyId';
import { Links } from 'router/links';

import { InitiativeCard } from './InitiativeCard';
import { sortInitiatives } from './sortInitiatives';

export const InitiativesSection = ({
  familyId,
  profileId,
}: {
  familyId?: string;
  profileId: string;
}) => {
  const { t } = useTranslation(['profile']);

  const { account } = useAuthContext();
  const { familyCharter, error, loading } =
    useFamilyCharterByFamilyId(familyId);

  const isOwnProfile = account && account.id === profileId;

  if (!familyId || (!familyCharter?.goals && (!loading || !error))) return null;

  const initiatives = sortInitiatives(familyCharter?.goals).splice(0, 3);

  if (!initiatives.length) return null;

  return (
    <Section
      title={t('profile:sections.family-initiatives.title')}
      subtitleContent={
        <Flex direction="column" gap={4}>
          <Text size="p2">
            {t('profile:sections.family-initiatives.description')}
          </Text>
          {isOwnProfile && (
            <ViewButton to={`${Links.initiatives}#family`} variant="blue">
              {t('profile:sections.family-initiatives.action-button')}
            </ViewButton>
          )}
        </Flex>
      }
    >
      <Grid gridTemplateColumns="repeat(3, 1fr)" gap={4}>
        {initiatives?.map((initiative, index) => (
          <InitiativeCard
            initiativeId={initiative.initiativeId}
            key={initiative.id}
            imagePosition={index % 2 === 0 ? 'top' : 'bottom'}
          />
        ))}
      </Grid>
    </Section>
  );
};
