import { useGetSharedResourceFromLibrary } from 'gql/library/query/getSharedResourceFromLibrary';

import { useBreadcrumbsLinks } from './useBreadcrumbsLinks';
import { useCurrentLibraryLocation } from './useCurrentLibraryLocation';

export const useBreadcrumbItemsShared = () => {
  const { folderId } = useCurrentLibraryLocation();

  const { details, loading, error } = useGetSharedResourceFromLibrary({
    skip: !folderId,
    resourceId: folderId || '',
  });

  return useBreadcrumbsLinks({ details, loading, error, shared: true });
};
