import {
  EsgCategoryEnum,
  ItriomTagsOutput,
  ItriomTagsV2Output,
  TagOutput,
  TagType,
} from 'types';

import { generateSdgOutput } from './sdgs';

export const generateItriomTags = ({
  countryFocusTags = [],
  environmentFocusTags = [],
  governanceFocusTags = [],
  sdGoalsTags = [],
  socialFocusTags = [],
}: Partial<ItriomTagsOutput> = {}): ItriomTagsOutput => ({
  countryFocusTags,
  environmentFocusTags,
  governanceFocusTags,
  sdGoalsTags,
  socialFocusTags,
});

export const generateTagOutput = ({
  __typename = 'TagOutput',
  id = '',
  name = '',
  type = TagType.Basic,
  order = 0,
  createdAt = 1649846215962,
  updatedAt = 1649846215962,
}: Partial<TagOutput> = {}): TagOutput => ({
  __typename,
  id,
  name,
  type,
  order,
  updatedAt,
  createdAt,
});

export const generateItriomTagsV2 = ({
  countryFocusTag = generateTagOutput(),
  esgFocus = EsgCategoryEnum.Social,
  sdGoalTag = generateSdgOutput(),
}: Partial<ItriomTagsV2Output> = {}): ItriomTagsV2Output => ({
  countryFocusTag,
  esgFocus,
  sdGoalTag,
});

export const TEST_VALUES = {
  tagNames: {
    sdGoal: 'SDG 17 - Partnerships for the Goals',
    governanceFocus: 'Corporate Compliance',
    socialFocus: 'Education',
    environmental: 'Forests',
  },
  tagIds: {
    sdGoal: '6256a7c7fc3a1d9cb38ce703',
    governanceFocus: '6256a7c8fc3a1d9cb38ce745',
    socialFocus: '6256a7c8fc3a1d9cb38ce730',
    environmental: '6256a7c7fc3a1d9cb38ce719',
  },
};

const sdGoalsTags = [
  generateTagOutput({
    id: TEST_VALUES.tagIds.sdGoal,
    name: TEST_VALUES.tagNames.sdGoal,
    type: TagType.SdGoal,
  }),
];

const governanceFocusTags = [
  generateTagOutput({
    id: TEST_VALUES.tagIds.governanceFocus,
    name: TEST_VALUES.tagNames.governanceFocus,
    type: TagType.Governance,
  }),
];

const socialFocusTags = [
  generateTagOutput({
    id: TEST_VALUES.tagIds.socialFocus,
    name: TEST_VALUES.tagNames.socialFocus,
    type: TagType.Social,
  }),
];

const countryFocusTags: TagOutput[] = [];

const environmentFocusTags: TagOutput[] = [
  generateTagOutput({
    id: TEST_VALUES.tagIds.environmental,
    name: TEST_VALUES.tagNames.environmental,
    type: TagType.Environmental,
  }),
];

type AllTagsMock = {
  listAvailableTags: {
    nodes: TagOutput[];
    __typename?: 'PaginatedTagOutput';
  };
};

export const allTagsMock: AllTagsMock = {
  listAvailableTags: {
    nodes: [
      ...sdGoalsTags,
      ...governanceFocusTags,
      ...socialFocusTags,
      ...countryFocusTags,
      ...environmentFocusTags,
    ],
    __typename: 'PaginatedTagOutput',
  },
};
