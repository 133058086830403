import { useState } from 'react';

import {
  Text,
  Center,
  Flex,
  FlexProps,
  Heading,
  Tooltip,
  Grid,
} from '@chakra-ui/react';

import { ValueCategoryIcon } from 'components/ItriomIcon';
import { useCategoryCopy } from 'hooks/copy/useCategoryCopy';
import { useValuesCopy } from 'hooks/copy/useValueCopy';
import { FamilyValueOutput, FamilyValuesCategoryEnum } from 'types';
import { capitalize } from 'utils/string';

import { ValueActions } from './ValueActions';
import { ValueDescription } from './ValueDescription';

const CATEGORY_FONT_COLOR: Record<
  FamilyValuesCategoryEnum,
  FlexProps['color']
> = {
  [FamilyValuesCategoryEnum.Advancement]: 'ui.white',
  [FamilyValuesCategoryEnum.Organisational]: 'ui.white',
  [FamilyValuesCategoryEnum.Contribution]: 'primary.blue.default',
  [FamilyValuesCategoryEnum.Societal]: 'primary.blue.default',
};

type Props = {
  value: FamilyValueOutput;
};

export const ValueCard = ({ value }: Props) => {
  const [shouldDisplayActions, setShouldDisplayActions] = useState(false);

  const { getValueCopy } = useValuesCopy();
  const { getCategoryCopy } = useCategoryCopy();

  const { actions, description: valueDescription } = getValueCopy(value.name);
  const { question, description: categoryDescription } = getCategoryCopy(
    value.category
  );

  const categoryFontColor = CATEGORY_FONT_COLOR[value.category];

  return (
    <Grid gridAutoFlow="row" gridTemplateRows="auto minmax(14.25rem, 1fr)">
      <Flex
        p={4}
        justify="flex-start"
        align="center"
        w="full"
        bgColor={`valueCategories.${value.category}`}
        color={categoryFontColor}
      >
        <Tooltip
          bg="ui.white"
          p={3}
          label={
            <Text size="p3" color="primary.blue.default">
              {categoryDescription}
            </Text>
          }
        >
          <Heading
            _hover={{ textDecor: 'underline', cursor: 'pointer' }}
            size="h5"
          >
            {capitalize(value.category)}
          </Heading>
        </Tooltip>

        <Center
          bg="ui.white"
          ml="auto"
          w={6}
          h={6}
          borderRadius="50%"
          color="ui.grey2"
        >
          <ValueCategoryIcon category={value.category} />
        </Center>
      </Flex>
      {shouldDisplayActions ? (
        <ValueActions
          name={value.name}
          actions={actions}
          handleCloseActions={() => setShouldDisplayActions(false)}
        />
      ) : (
        <ValueDescription
          name={value.name}
          description={valueDescription}
          question={question}
          handleOpenActions={() => setShouldDisplayActions(true)}
        />
      )}
    </Grid>
  );
};
