import { TimezoneDateOutput } from 'types';

export const generateTimezoneDateOutput = ({
  datetime = '',
  offset = 0,
  text = '',
}: Partial<TimezoneDateOutput> = {}) => ({
  datetime,
  offset,
  text,
});
