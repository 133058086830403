import { Link } from 'react-router-dom';

import {
  Button,
  Flex,
  FlexProps,
  Heading,
  Image,
  Text,
} from '@chakra-ui/react';

type Props = {
  title: string;
  buttonName: string;
  buttonLink: string;
  containerStyles?: FlexProps['sx'];
};

export const EmptyState = ({
  title,
  buttonName,
  buttonLink,
  containerStyles,
}: Props) => {
  return (
    <Flex
      h={520}
      w="full"
      marginTop={5}
      position="relative"
      sx={containerStyles}
    >
      <Image
        src="/assets/empty-state.jpg"
        alt="Empty state"
        w="full"
        h="full"
        objectFit="cover"
        objectPosition="center"
      />
      <Flex
        align="center"
        justify="center"
        direction="column"
        gap={4}
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
      >
        <Heading size="h4" color="ui.white">
          {title}
        </Heading>
        <Button
          as={Link}
          to={buttonLink}
          bg="primary.lime.default"
          color="primary.blue.default"
          px={6}
          border="none"
        >
          <Text size="p1">{buttonName}</Text>
        </Button>
      </Flex>
    </Flex>
  );
};
