import { Flex, Text, StackDivider, Heading } from '@chakra-ui/react';

import { RightBlock } from 'components/Layout/RightBlock';
import { WelcomeLayout } from 'components/Layout/WelcomeLayout';
import { SupportEmailButton } from 'components/SupportEmailButton';

export const OnboardingLogoutPage = () => {
  return (
    <WelcomeLayout title="The Values Configurator">
      <RightBlock title="See you soon.">
        <Flex direction="column" flex={1}>
          <Flex direction="column" flex={1} justify="center" pb={40}>
            <Heading size="h5" mt={14}>
              Please use the login link you have received by email to log back
              in.
              <br />
              Thank you.
            </Heading>
            <StackDivider
              borderColor="primary.blue.default"
              borderBottomWidth={3}
              w={14}
              h={4}
              mb={10}
            />
          </Flex>
          <Text size="p3">
            Please contact <SupportEmailButton /> for technical issues or if you
            need further assistance.
          </Text>
        </Flex>
      </RightBlock>
    </WelcomeLayout>
  );
};
