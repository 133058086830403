import { ReactNode } from 'react';

import {
  ButtonProps,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

import { ItriomButtonOutline } from 'components/Button';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  handleClick: () => void;
  question: ReactNode;
  title: string;
  actionText: string;
  confirmationButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
};

export const ConfirmationModal = ({
  onClose,
  isOpen,
  handleClick,
  question,
  actionText,
  title,
  confirmationButtonProps,
  cancelButtonProps,
}: Props) => {
  const handleSendClick = () => {
    handleClick();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay bg="#0A1E40BF" backdropFilter="auto" backdropBlur="6px" />
      <ModalContent p={4} borderRadius={8}>
        <ModalHeader>
          <Heading size="h3">{title}</Heading>
        </ModalHeader>
        <ModalBody>
          <Text size="p1">{question}</Text>
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          <ItriomButtonOutline onClick={onClose} {...cancelButtonProps}>
            Cancel
          </ItriomButtonOutline>
          <ItriomButtonOutline
            bgColor="primary.lime.default"
            borderColor="ui.white"
            onClick={handleSendClick}
            {...confirmationButtonProps}
          >
            {actionText}
          </ItriomButtonOutline>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
