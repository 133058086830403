import { gql, useMutation } from '@apollo/client';

import { notificationsGql } from 'features/Notifications/hooks/useLoadNotifications';
import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationRejectGroupInvitationArgs } from 'types';

export const rejectGroupInvitationGql = gql`
  mutation RejectGroupInvitation($groupId: String!) {
    rejectGroupInvitation(groupId: $groupId)
  }
`;

export const useRejectGroupInvitation = () => {
  const [rejectGroupInvitationMutation] = useMutation<
    Pick<Mutation, 'rejectGroupInvitation'>,
    MutationRejectGroupInvitationArgs
  >(rejectGroupInvitationGql, { refetchQueries: [notificationsGql] });

  const handleRejectGroupInvitation = (groupId: string) =>
    rejectGroupInvitationMutation({ variables: { groupId } });

  const rejectGroupInvitation = useWithMutationToast(
    handleRejectGroupInvitation,
    {
      successMessage: 'Succesfully rejected group invitation',
      errorMessage: 'Error rejecting group invitation',
    }
  );

  return { rejectGroupInvitation };
};
