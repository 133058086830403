import { FlexProps } from '@chakra-ui/react';

import {
  ANGLE_MODIFIER,
  RING_WIDTH,
  VALUE_SVG_WIDTH,
  OUTER_RADIUS,
} from '../../constants';
import { ValuePosition, Position } from '../../types';

export const BOX_PATHS = {
  [ValuePosition.OuterRight]:
    'M0.323799 77.3238 C24.8385 52.8092 53.9416 33.3631 85.9716 20.0958 C118.002 6.82856 152.331 -4.13423e-07 187 0 V36.96 C157.185 36.96 127.661 42.8326 100.116 54.2424 C72.5698 65.6522 47.5411 82.3759 26.4585 103.458 L0.323799 77.3238 Z',
  [ValuePosition.InnerRight]:
    'M0.486748 66.4868 C21.5657 45.4079 46.5899 28.6872 74.1309 17.2793 C101.672 5.87153 131.19 0 161 0 V38.59 C136.258 38.59 111.758 43.4634 88.8986 52.9319 C66.0397 62.4003 45.2695 76.2785 27.774 93.774 L0.486748 66.4868 Z',
  [ValuePosition.OuterLeft]:
    'M0 187 C3.03086e-06 152.331 6.82857 118.001 20.0958 85.9715 C33.3631 53.9416 52.8092 24.8384 77.3238 0.323776 L103.458 26.4584 C82.3759 47.5411 65.6522 72.5698 54.2424 100.116 C42.8326 127.661 36.96 157.185 36.96 187 H0Z',
  [ValuePosition.InnerLeft]:
    'M0 161 C2.60608e-06 131.19 5.87153 101.672 17.2794 74.1308 C28.6872 46.5899 45.4079 21.5656 66.4868 0.486732 L93.774 27.774 C76.2785 45.2695 62.4004 66.0396 52.9319 88.8986 C43.4634 111.758 38.59 136.258 38.59 161 H0Z',
};

export const TEXT_PATHS_UPPER = {
  [ValuePosition.OuterRight]:
    'M0.323799 77.3238 C24.8385 52.8092 53.9416 33.3631 85.9716 20.0958 C118.002 6.82856 152.331 -4.13423e-07 187 0',
  [ValuePosition.InnerRight]:
    'M0.486748 66.4868 C21.5657 45.4079 46.5899 28.6872 74.1309 17.2793 C101.672 5.87153 131.19 0 161 0',
  [ValuePosition.OuterLeft]:
    'M0 187 C3.03086e-06 152.331 6.82857 118.001 20.0958 85.9715 C33.3631 53.9416 52.8092 24.8384 77.3238 0.323776',
  [ValuePosition.InnerLeft]:
    'M0 161 C2.60608e-06 131.19 5.87153 101.672 17.2794 74.1308 C28.6872 46.5899 45.4079 21.5656 66.4868 0.486732',
};

export const TEXT_PATHS_BOTTOM = {
  [ValuePosition.OuterRight]:
    'M187 36.96 C157.185 36.96 127.661 42.8326 100.116 54.2424 C72.5698 65.6522 47.5411 82.3759 26.4585 103.458',
  [ValuePosition.InnerRight]:
    'M161 38.59 C136.258 38.59 111.758 43.4634 88.8986 52.9319 C66.0397 62.4003 45.2695 76.2785 27.774 93.774',
  [ValuePosition.OuterLeft]:
    'M103.458 26.4584 C82.3759 47.5411 65.6522 72.5698 54.2424 100.116 C42.8326 127.661 36.96 157.185 36.96 187',
  [ValuePosition.InnerLeft]:
    'M93.774 27.774 C76.2785 45.2695 62.4004 66.0396 52.9319 88.8986 C43.4634 111.758 38.59 136.258 38.59 161',
};

const TRANSLATE_OFFSET = VALUE_SVG_WIDTH / ANGLE_MODIFIER - VALUE_SVG_WIDTH;
const SCALE_FACTOR = `scale(1.02)`;

export const PATH_TRANSFORM_STYLE = {
  [ValuePosition.InnerRight]: `translate(${
    RING_WIDTH * ANGLE_MODIFIER + TRANSLATE_OFFSET
  }px, ${RING_WIDTH}px) ${SCALE_FACTOR}`,
  [ValuePosition.OuterRight]: `translate(${TRANSLATE_OFFSET}px, 0px) ${SCALE_FACTOR}`,
  [ValuePosition.InnerLeft]: `translate(${RING_WIDTH}px, ${
    RING_WIDTH * ANGLE_MODIFIER + TRANSLATE_OFFSET
  }px) ${SCALE_FACTOR}`,
  [ValuePosition.OuterLeft]: `translate(0px, ${TRANSLATE_OFFSET}px) ${SCALE_FACTOR}`,
};

export const TOOLTIP_STYLING: Record<Position, FlexProps> = {
  [Position.RightBottom]: {
    bottom: 0,
    left: OUTER_RADIUS,
    pr: '16px',
    ml: 'auto',
  },
  [Position.LeftBottom]: { bottom: 0, left: -OUTER_RADIUS, pl: '16px' },
  [Position.RightTop]: { top: 0, left: OUTER_RADIUS, pr: '16px', ml: 'auto' },
  [Position.LeftTop]: { top: 0, left: -OUTER_RADIUS, pl: '16px' },
};

export const TOOLTIP_POSITIONING: Record<
  Position,
  Pick<FlexProps, 'top' | 'bottom' | 'left'>
> = {
  [Position.RightBottom]: { bottom: 0, left: OUTER_RADIUS },
  [Position.LeftBottom]: { bottom: 0, left: -OUTER_RADIUS },
  [Position.RightTop]: { top: 0, left: OUTER_RADIUS },
  [Position.LeftTop]: { top: 0, left: -OUTER_RADIUS },
};
