import { useTranslation } from 'react-i18next';

import { Box, Flex } from '@chakra-ui/react';

import { ButtonsGroup } from 'components/Button/ButtonsGroup';
import { Links } from 'router/links';

import { SdgIntroLayout } from '../../components/Layout';
import {
  SdgIcons,
  WhatAreSdgs,
} from '../../components/SdgConfigurator/SdgIntro';
import { SectionHeading } from '../../components/SectionHeader';

interface Props {
  onBackButtonClicked?: VoidFunction;
  onNextButtonClicked?: VoidFunction;
  withBackButton?: boolean;
}

export const WhatAreSdgsPage = ({
  onBackButtonClicked,
  onNextButtonClicked,
  withBackButton = true,
}: Props) => {
  const { t } = useTranslation(['common']);

  const backButton = {
    text: t('common:back'),
    color: 'ui.white',
    ...(onBackButtonClicked
      ? { onClick: onBackButtonClicked }
      : { link: Links.sdgConfiguratorWelcome }),
  };

  const nextButton = {
    text: t('common:next'),
    ...(onNextButtonClicked
      ? { onClick: onNextButtonClicked }
      : { link: Links.sdgConfiguratorWhyAlign }),
  };

  return (
    <SdgIntroLayout>
      <Box
        mx={{ lg: 'auto', base: 20 }}
        pt={{ lg: 140, base: 20 }}
        maxW={{ lg: '1056px', base: 'inherit' }}
      >
        <SectionHeading
          heading="What are the Sustainable Development Goals (SDGs)?"
          subheading="SDGs are-"
          color="ui.white"
          containerStyling={{ mb: 4 }}
        />
        <Flex w="full" direction={{ base: 'column', lg: 'row' }}>
          <WhatAreSdgs />
          <SdgIcons />
        </Flex>
      </Box>
      <Box mt="auto" px={16} py={12}>
        <ButtonsGroup
          leftButton={withBackButton ? backButton : undefined}
          rightButton={nextButton}
        />
      </Box>
    </SdgIntroLayout>
  );
};
