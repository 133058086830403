import { useEffect } from 'react';

import { useConversationsSelectors } from '../MessagingStateManager/hooks';
import { useLoadConversations } from './useLoadConversations';
import { useLoadMessages } from './useLoadMessages';

export const useLoadInitialMessagingState = (isLoggedIn?: boolean) => {
  const { selectActiveConversation } = useConversationsSelectors();

  const activeConversation = selectActiveConversation();

  const loadConversations = useLoadConversations(true);

  const loadMessages = useLoadMessages();

  useEffect(() => {
    if (isLoggedIn) {
      loadConversations();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (activeConversation) {
      loadMessages(activeConversation.id);
    }
  }, [activeConversation]);
};
