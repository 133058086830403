import { ValueConfiguratorCategoryEnum } from 'types';

import { AdvancementStep } from '../../components/ValuesConfigurator';

type Props = {
  valuesCategory?: ValueConfiguratorCategoryEnum;
};

export const AdvancementValues = ({
  valuesCategory = ValueConfiguratorCategoryEnum.Importance,
}: Props): JSX.Element => {
  return <AdvancementStep valuesCategory={valuesCategory} />;
};
