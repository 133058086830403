import { Flex, FlexProps } from '@chakra-ui/react';

import { WithChildren } from 'types';

type Props = {
  color?: string;
  background?: string;
  containerStyling?: FlexProps;
};

export const SdgIntroLayout = ({
  background = 'primary.blue.default',
  color = 'white',
  children,
  containerStyling,
}: WithChildren<Props>) => (
  <Flex
    background={background}
    color={color}
    h="100%"
    minH="100vh"
    w="full"
    justify="center"
    {...containerStyling}
  >
    <Flex direction="column" maxW="1350px" w="full">
      {children}
    </Flex>
  </Flex>
);
