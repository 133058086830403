import { gql, useMutation } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationDeleteMessageArgs } from 'types';

export const deleteMessageGql = gql`
  mutation DeleteMessage($id: String!) {
    deleteMessage(id: $id)
  }
`;

export const useDeleteMessage = () => {
  const [deleteMessageMutation] = useMutation<
    Pick<Mutation, 'deleteMessage'>,
    MutationDeleteMessageArgs
  >(deleteMessageGql);

  const deleteMessage = async (messageId: string) => {
    await deleteMessageMutation({
      variables: {
        id: messageId,
      },
    });
  };

  const deleteMessageWithToast = useWithMutationToast(deleteMessage, {
    successMessage: 'Message deleted',
    errorMessage: 'Failed deleting message',
  });

  return deleteMessageWithToast;
};
