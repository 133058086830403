import {
  Key,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { TFuncKey, useTranslation } from 'react-i18next';
import { useLocation, Link, To } from 'react-router-dom';

import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';

import { ConditionalWrapper } from 'components/ConditionalWrapper';
import { ComponentType, WithChildren } from 'types';

export interface Tab {
  onClick?: VoidFunction;
  navigateTo?: To;
  content: ReactNode;
  disabled?: boolean;
  isDefault?: boolean;
  labelKey: TFuncKey<'tabs'>;
}

interface Props<T extends string | number | Key> {
  tabsConfig: Record<T, Tab>;
  TabNavigationWrapper?: ComponentType<WithChildren>;
  TabContentWrapper?: ComponentType<WithChildren>;
  display?: {
    noMarginTop?: boolean;
    narrow?: boolean;
  };
}

export const TabPane = <T extends string | number | Key>({
  tabsConfig,
  TabContentWrapper,
  TabNavigationWrapper,
  display,
}: Props<T>) => {
  const tabList = Object.entries(tabsConfig);
  const location = useLocation();
  const { t } = useTranslation(['tabs']);

  const defaultIndex = useMemo(
    () => tabList.findIndex(([, tabData]) => tabData.isDefault),
    [tabList]
  );

  const getLocationIndex = useCallback(() => {
    return tabList.findIndex(
      ([tabKey]) =>
        tabKey.toString().toLowerCase() ===
        location.hash.substring(1).toLowerCase()
    );
  }, [location.hash, tabList]);

  const baseIndex = useMemo(() => {
    const locationIndex = getLocationIndex();
    if (locationIndex > 0) return locationIndex;
    if (defaultIndex > 0) return defaultIndex;

    return 0;
  }, [defaultIndex, getLocationIndex]);

  const [currentIndex, setCurrentIndex] = useState<number>(baseIndex);

  const handleTabChange = (nextTabIndex: number) => {
    setCurrentIndex(nextTabIndex);
  };

  useEffect(() => {
    const locationIndex = getLocationIndex();

    if (locationIndex !== currentIndex && locationIndex > -1) {
      setCurrentIndex(locationIndex);
    }
  }, [location.hash]);

  return (
    <Tabs
      isFitted
      isLazy
      index={currentIndex}
      onChange={handleTabChange}
      display="contents"
    >
      <ConditionalWrapper Wrapper={TabNavigationWrapper}>
        <TabList
          mt={display?.noMarginTop ? 0 : 8}
          w={display?.narrow ? 'fit-content' : '100%'}
        >
          {tabList.map(([tabKey, tabData]) => (
            <Tab
              isDisabled={tabData.disabled}
              key={tabKey}
              onClick={tabData.onClick}
              as={tabData.navigateTo ? Link : Tab}
              to={tabData.navigateTo}
            >
              {t(`tabs:${tabData.labelKey}`)}
            </Tab>
          ))}
        </TabList>
      </ConditionalWrapper>
      <ConditionalWrapper Wrapper={TabContentWrapper}>
        <TabPanels minH="50vh" p={0} display="contents">
          {tabList.map(([key, tab]) => (
            <TabPanel key={key} px={0} display="contents">
              {tab.content}
            </TabPanel>
          ))}
        </TabPanels>
      </ConditionalWrapper>
    </Tabs>
  );
};
