import { Heading, Flex, SimpleGrid, Box } from '@chakra-ui/react';

import { SdgOutput, SDGDragItem } from 'types';

import { SelectCell } from './SelectCell';

type Order = Array<{ id: string; index: number }>;

const getBankOrder = (sdgs: SdgOutput[]): Order =>
  [...sdgs]
    .sort((a, b) => a.index - b.index)
    .map(({ id }, index) => ({ id, index }));

type SdgBankProps = {
  allBankSdgs: SdgOutput[];
  availableItems: SDGDragItem[];
  moveToAvailable: (item: SDGDragItem) => void;
};

export const SdgBank = ({
  allBankSdgs,
  availableItems,
  moveToAvailable,
}: SdgBankProps) => {
  const order = getBankOrder(allBankSdgs);

  return (
    <Flex
      w="100%"
      direction="column"
      p={4}
      gap={2}
      border="1px solid white"
      borderRadius="10px"
      data-testid="sdg-bank"
    >
      <Heading size="h5">SDG Bank:</Heading>
      <SimpleGrid
        columns={{ base: 4, lg: order.length < 9 ? order.length : 9 }}
      >
        {order.map(({ id }) => (
          <Box key={id} mx="auto" mt={4} bgColor="darkBlue2">
            <SelectCell
              item={availableItems.find((item) => item.id === id)}
              onSelect={moveToAvailable}
            />
          </Box>
        ))}
      </SimpleGrid>
    </Flex>
  );
};
