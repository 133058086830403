import { gql, useQuery } from '@apollo/client';

import { InitiativeBlocksFragment } from 'gql/fragments';
import { Query } from 'types';
import {
  InitiativeHeroOutputFragment,
  InitiativeVisibilityOutputFragment,
  ItriomTagsV2OutputFragment,
} from 'types/generated-fragments';

export const initiativeGql = gql`
  ${InitiativeHeroOutputFragment}
  ${ItriomTagsV2OutputFragment}
  ${InitiativeBlocksFragment}
  ${InitiativeVisibilityOutputFragment}
  query Initiative($initiativeId: String!) {
    initiative(id: $initiativeId) {
      id
      createdAt
      updatedAt
      hero {
        ...InitiativeHeroOutputFragment
      }
      status
      visibilityDetails {
        ...InitiativeVisibilityOutputFragment
      }
      tags {
        ...ItriomTagsV2OutputFragment
      }
      blocks {
        ...InitiativeBlocksFragment
      }
      relatedArticles {
        ... {
          id
          hero {
            heroImage {
              signedUrl
            }
            headline
            summary
            author
          }
        }
      }
      createdBy
      updatedBy
      appreciationCount
      isFollowed
    }
  }
`;

export const useInitiative = (id: string) => {
  const { data, loading, error, refetch } = useQuery<Pick<Query, 'initiative'>>(
    initiativeGql,
    { variables: { initiativeId: id }, fetchPolicy: 'no-cache' }
  );
  const initiative = data?.initiative;

  return { initiative, loading, error, refetch };
};
