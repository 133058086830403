import { Heading } from '@chakra-ui/layout';
import { Text, VStack } from '@chakra-ui/react';

interface Props {
  title: string;
  subtitle: string;
}

export const IntroTile = ({ title, subtitle }: Props) => (
  <VStack
    spacing={4}
    alignItems="flex-start"
    p={4}
    pr={16}
    bg="ui.grey3"
    h="full"
  >
    <Heading size="h5" lineHeight="26px">
      {title}
    </Heading>
    <Text size="p2">{subtitle}</Text>
  </VStack>
);
