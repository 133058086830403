import { Text } from '@chakra-ui/react';

import { SectionHeading } from '../../../SectionHeader';

const Subheading = () => {
  return (
    <>
      <Text size="p1" mb={2}>
        Please drag and drop all of the tiles found in your SDG bank in order
        based on your personal view of the urgency with which you believe each
        SDG should be addressed.
      </Text>
      <Text size="p1">
        The SDG that you believe needs to be addressed first, should be
        positioned first in the rank order; the next urgent SDG should be
        positioned second, etc.
      </Text>
    </>
  );
};

export const SdgOrderHeader = () => (
  <SectionHeading
    heading="Individual ordering of SDGs"
    subheading={<Subheading />}
    color="ui.white"
  />
);
