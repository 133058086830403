import { useTranslation } from 'react-i18next';

import { Grid, Text, Flex } from '@chakra-ui/layout';
import { Image, Heading, GridItem } from '@chakra-ui/react';

import { SdgIcon } from 'components/Icons/SdgIcon';
import { QueryStateGuard } from 'components/QueryStateGuard';
import { SGDS_OVERVIEW } from 'constants/Sdgs';
import { useSdgs } from 'hooks/sdgs/useSdgs';
import { UltimateGoalOutput } from 'types';
import { SdgInternalName } from 'types/Sdg';

type UltimateGoalCardProps = {
  ultimateGoal: UltimateGoalOutput;
};

const UltimateGoalContainer = ({
  ultimateGoal,
}: UltimateGoalCardProps): JSX.Element => {
  const { description, sdIcon, title, image } = ultimateGoal;

  const { sdgs, loading } = useSdgs();
  const { t } = useTranslation(['events']);

  const sdg = sdgs.find(({ id }) => id === sdIcon);

  return (
    <QueryStateGuard loading={loading}>
      <Grid
        gridTemplateColumns="repeat(12, 1fr)"
        gridTemplateRows="minmax(auto, 320px)"
      >
        <GridItem colSpan={4}>
          <Image src={image?.signedUrl} w="full" h="full" objectFit="cover" />
        </GridItem>
        <GridItem as={Flex} direction="column" colSpan={8} p={8} gap={8}>
          {sdg && (
            <Flex gap={8} alignItems="center">
              <SdgIcon name={sdg.internalName} variant="outline" />
              <Flex
                alignItems="center"
                justifyContent="center"
                borderRadius="100%"
                w={6}
                h={6}
                backgroundColor={
                  SGDS_OVERVIEW[sdg.internalName as SdgInternalName].color
                }
              >
                <Text as="span" size="p3b" color="ui.white">
                  {sdg.index}
                </Text>
              </Flex>
            </Flex>
          )}
          <Flex direction="column" gap={1}>
            <Heading size="h3">{t('events:goal', { entity: title })}</Heading>
            <Heading size="h4">{description}</Heading>
          </Flex>
        </GridItem>
      </Grid>
    </QueryStateGuard>
  );
};

export const UltimateGoalCard = ({
  ultimateGoal,
}: Partial<UltimateGoalCardProps>): JSX.Element | null => {
  if (!ultimateGoal) return null;

  return <UltimateGoalContainer ultimateGoal={ultimateGoal} />;
};
