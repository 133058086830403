import { gql, useMutation } from '@apollo/client';

import { Mutation, MutationMarkNotificationsAsReadArgs } from 'types';

import { notificationsGql } from './useLoadNotifications';

export const markNotificationsAsReadGql = gql`
  mutation MarkNotificationsAsRead($data: NotificationIdsInput!) {
    markNotificationsAsRead(data: $data)
  }
`;

export const useMarkNotificationsAsRead = () => {
  const [markNotificationsAsReadMutation, { loading, error }] = useMutation<
    Pick<Mutation, 'markNotificationsAsRead'>,
    MutationMarkNotificationsAsReadArgs
  >(markNotificationsAsReadGql, {
    refetchQueries: [notificationsGql],
  });

  const markNotificationsAsRead = (notificationIds: string[]) =>
    markNotificationsAsReadMutation({
      variables: { data: { notificationIds } },
    });

  return {
    markNotificationsAsRead,
    error,
    loading,
  };
};
