import { gql, useQuery } from '@apollo/client';

import { InsightDeepNestingFragment } from 'gql/fragments';
import { Query } from 'types';

const bannerInsightGql = gql`
  ${InsightDeepNestingFragment}
  query BannerInsight {
    bannerInsight {
      ...InsightDeepNestingFragment
    }
  }
`;

export const useBannerInsight = () => {
  const { data, error, loading } =
    useQuery<Pick<Query, 'bannerInsight'>>(bannerInsightGql);

  return { bannerInsight: data?.bannerInsight, error, loading };
};
