export const Link = {
  baseStyle: {
    fontWeight: '500',
    _focus: {
      outline: 'none',
    },
  },
  sizes: {
    sm: {
      fontSize: '12px',
    },
    md: {
      fontSize: '14px',
    },
    lg: {
      fontSize: '18px',
    },
  },

  defaultProps: {
    size: 'md',
  },
};
