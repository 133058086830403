import { useTranslation } from 'react-i18next';

import { ParentChild, UserCertification } from '@carbon/icons-react/next';
import { Flex } from '@chakra-ui/react';

import { TextInput } from 'components/Form/TextInput';
import { FamilyOnboardMemberDetailsOutput, Role } from 'types';
import { getFullName } from 'utils/string';

import { FamilyRoleIconWrapper } from './FamilyRoleIconWrapper';

type Props = Pick<
  FamilyOnboardMemberDetailsOutput,
  'firstName' | 'lastName'
> & {
  role?: Role;
};

export const FamilyMemberNamePanel = ({
  firstName,
  lastName,
  role = Role.FamilyMember,
}: Props) => {
  const { t } = useTranslation(['family']);

  return (
    <Flex position="relative" w="full">
      <TextInput
        name="name"
        placeholder={getFullName(firstName, lastName)}
        value={getFullName(firstName, lastName)}
        isReadOnly
      />
      {role === Role.HeadOfFamily && (
        <FamilyRoleIconWrapper tooltip={t('family:roles.head')}>
          <ParentChild />
        </FamilyRoleIconWrapper>
      )}
      {role === Role.FamilyAdmin && (
        <FamilyRoleIconWrapper tooltip={t('family:roles.admin')}>
          <UserCertification />
        </FamilyRoleIconWrapper>
      )}
    </Flex>
  );
};
