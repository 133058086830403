import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { MenuItem } from '@chakra-ui/react';

import { useAuthContext } from 'contexts/AuthContext';
import { Links } from 'router/links';
import { Account, Role } from 'types';
import { generateUrlById } from 'utils/links';
import { hasRoleWithProfile } from 'utils/roles';

type AccountTabs = Partial<Record<Role, { label: string; link: Links }>>;

const accountTabs: AccountTabs = {
  [Role.Admin]: {
    label: 'Itriom Admin',
    link: Links.cmsAdvisors,
  },
  [Role.ItriomCurator]: {
    label: 'Itriom Curator',
    link: Links.cmsAdvisorProfiles,
  },
  [Role.FamilyAdmin]: {
    label: 'Family Admin',
    link: Links.cmsProfiles,
  },
  [Role.ExpertAdvisor]: {
    label: 'Itriom Advisor',
    link: Links.advisorManageOwnProfile,
  },
};

type Props = {
  account: Account;
};

export const AccountMenu = ({ account }: Props) => {
  const { logout } = useAuthContext();
  const { t } = useTranslation(['navigation']);

  const { id, role } = account;
  const accountTab = accountTabs[role];

  const profileLink = generateUrlById(Links.profile, id);

  return (
    <>
      {hasRoleWithProfile(role) && (
        <MenuItem as={Link} to={profileLink}>
          {t('navigation:profile')}
        </MenuItem>
      )}
      {accountTab && (
        <MenuItem as={Link} to={accountTab.link}>
          {accountTab.label}
        </MenuItem>
      )}
      <MenuItem as={Link} to={Links.account}>
        {t('navigation:account-settings')}
      </MenuItem>
      <MenuItem
        onClick={async () => {
          await logout();
        }}
      >
        {t('navigation:sign-out')}
      </MenuItem>
    </>
  );
};
