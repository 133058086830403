import { useQuery } from '@apollo/client';

import { BookingTypeEnum, Query } from 'types';

import { isDemoBookedGql } from '../documents';

export const useIsDemoBooked = (type: BookingTypeEnum) => {
  const { data, loading, error } = useQuery<Pick<Query, 'isDemoBooked'>>(
    isDemoBookedGql,
    {
      variables: {
        type,
      },
    }
  );

  return { isDemoBooked: data?.isDemoBooked, loading, error };
};
