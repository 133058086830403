import { useMemo } from 'react';

import { Image, ImageProps } from '@chakra-ui/react';

import { SdgInternalName } from 'types/Sdg';

import { getSdgSrc, SDGIconVariant } from './utils';

type Props = {
  name: SdgInternalName | string;
  variant?: SDGIconVariant;
  additionalStyling?: ImageProps;
};

export const SdgIcon = ({ name, variant, additionalStyling }: Props) => {
  const iconSrc = useMemo(() => getSdgSrc(name, variant), [name, variant]);

  return (
    <Image
      src={iconSrc}
      alt={name}
      maxW="full"
      maxH="full"
      {...additionalStyling}
    />
  );
};
