import { ContentSection } from 'components/CmsLayout/ContentSection';
import { FamilyValuesOutput } from 'types';
import { flattenValuesOutput } from 'utils/values';

import { ValueCardSimple } from './ValueCardSimple';

type Props = {
  values: FamilyValuesOutput;
  subtitle?: string;
};

export const SimpleValuesSection = ({ values, subtitle }: Props) => {
  const valuesList = flattenValuesOutput(values);

  if (!valuesList.length) return null;

  return (
    <ContentSection
      title="Values"
      subtitle={subtitle}
      contentStyles={{
        display: 'grid',
        gridTemplateColumns: { base: '1fr', lg: 'repeat(4, 1fr)' },
        gridGap: 1,
      }}
    >
      {valuesList.map((value) => (
        <ValueCardSimple key={value.id} value={value} />
      ))}
    </ContentSection>
  );
};
