import { createContext, ReactNode, useContext } from 'react';
import { useParams } from 'react-router-dom';

import { QueryStateGuard } from 'components/QueryStateGuard';
import { useFamilyCharter } from 'hooks/familyCharter/useFamilyCharter';
import { FamilyCharter } from 'types';

export const FamilyCharterContext = createContext<FamilyCharter | null>(null);

type FamilyCharterProviderProps = {
  children?: ReactNode;
};

export const FamilyCharterProvider = ({
  children,
}: FamilyCharterProviderProps): JSX.Element => {
  const { id } = useParams();
  if (!id) throw new TypeError('Family Charter id is required');

  const { familyCharter, loading, error } = useFamilyCharter(id);

  return (
    <QueryStateGuard loading={loading} error={error}>
      {familyCharter && (
        <FamilyCharterContext.Provider value={familyCharter}>
          {children}
        </FamilyCharterContext.Provider>
      )}
    </QueryStateGuard>
  );
};

export const useFamilyCharterContext = (): FamilyCharter => {
  const context = useContext(FamilyCharterContext);

  if (!context) {
    throw new Error('useFamilyCharterContext was used outside of its Provider');
  }

  return context;
};
