import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { TrashCan } from '@carbon/icons-react/next';
import { useDisclosure } from '@chakra-ui/react';

import { ItriomMenuButton } from 'components/Button/ItriomMenuButton';
import { DeleteConfirmationModal } from 'components/DeleteConfirmationModal';
import { useRoleValidation } from 'hooks/authorization/useRoleValidation';
import { useDeleteGroup } from 'hooks/groups/useDeleteGroup';
import { Links } from 'router/links';
import { Group } from 'types';

interface Props {
  group: Group;
}

export const DeleteGroup = ({ group }: Props) => {
  const { t } = useTranslation(['common', 'cms']);
  const { onOpen, onClose, isOpen } = useDisclosure();
  const navigate = useNavigate();
  const { isAdmin } = useRoleValidation();
  const { handleDeleteGroup } = useDeleteGroup();

  const handleDelete = async (validationInput: string) => {
    await handleDeleteGroup(group.id, validationInput);
    onClose();
    navigate(Links.groups);
  };

  if (!isAdmin) {
    return null;
  }

  return (
    <>
      <ItriomMenuButton
        onClick={onOpen}
        display="flex"
        justifyContent="space-between"
      >
        {t('cms:delete.action', { entity: t('common:group') })}
        <TrashCan />
      </ItriomMenuButton>
      <DeleteConfirmationModal
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handleDelete}
        entity="group"
        name={group.name}
      />
    </>
  );
};
