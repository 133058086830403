import { MediaOutput } from 'types';

type UploadInForm = Array<File | MediaOutput> | undefined;

export const isMediaOutput = (media: unknown): media is MediaOutput =>
  Boolean(media && (media as MediaOutput).signedUrl);

/**
 * return:
 * null - if we want to remove the files
 * undefined - if we don't want to change anything
 * file[] - if we want to update the files
 */
export const getUpdateFileValue = (
  upload: UploadInForm
): File[] | undefined | null => {
  if (!upload) {
    return null;
  }

  if (upload.length === 0 || upload.some((file) => isMediaOutput(file))) {
    return undefined;
  }

  return upload as File[]; // because filtering above, we are sure it's File[]
};

export const getImage = (
  media: MediaOutput | MediaOutput[] | null | undefined
): MediaOutput | undefined => {
  if (Array.isArray(media)) {
    return media[0];
  }

  return media || undefined;
};

export const getImageId = (
  media: MediaOutput | MediaOutput[] | null | undefined
): string | undefined => {
  const image = getImage(media);

  return image?.id;
};

export const mediaOutputToArray = (
  media: MediaOutput | undefined
): MediaOutput[] | undefined => {
  if (!media) return undefined;

  return [media];
};

export const getAvatarSrc = (avatarImage?: Partial<MediaOutput>) =>
  avatarImage?.signedUrl ?? '/assets/profile/default-avatar.png';
