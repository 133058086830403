import { AccountStatus, Post, PostType } from 'types';

import { familyMock } from './family.mock';
import { generateProfile } from './profiles/profile.mock';

export const TEST_VALUES = {
  id: '627bb20ec64d12192619eb04',
  referenceId: '627cd011a0b7540b515719ed',
  text: 'I have 2 or 3 experts/advisers',
};

export const generatePost = ({
  __typename = 'Post',
  appreciationCount = 1,
  id = `postId${Date.now()}`,
  link,
  postedBy = generateProfile(),
  referenceId,
  tags = [],
  images = [],
  comments = [],
  text = '',
  title,
  isSelfAppreciated = false,
  type = PostType.Itriom,
  createdAt = Date.now(),
  updatedAt = Date.now(),
  createdBy = '61ed723162715d298cd08420',
  updatedBy = '61ed723162715d298cd08420',
}: Partial<Post> = {}): Post => ({
  __typename,
  appreciationCount,
  comments,
  createdAt,
  createdBy,
  isSelfAppreciated,
  id,
  images,
  link,
  postedBy,
  referenceId,
  tags,
  text,
  title,
  type,
  updatedAt,
  updatedBy,
});

export const postsMock: Post[] = [
  generatePost({
    id: TEST_VALUES.id,
    postedBy: generateProfile({
      id: '61ed723162715d298cd08420',
      firstName: 'Adi',
      status: AccountStatus.Active,
      lastName: 'Godrej',
      title: 'Head of Family',
      family: familyMock,
    }),
    text: TEST_VALUES.text,
    title: 'Suggestions',
    referenceId: TEST_VALUES.referenceId,
    appreciationCount: 1,
  }),
];
