import { Flex, Heading, Text } from '@chakra-ui/react';

import { CheckIcon } from '../../CheckIcon';

export const ThankYouSection = () => (
  <Flex
    w={600}
    gap={12}
    flexDirection="column"
    align="center"
    textAlign="center"
    fontFamily="Publico Headline"
  >
    <Heading size="h4">
      Thank you for completing the prioritisation of your values
    </Heading>
    <CheckIcon color="ui.white" />
    <Text mt={4} size="h4">
      Once all of your family members have completed the process we will be able
      to determine the final four values and fill then nofity you accordingly
    </Text>
  </Flex>
);
