import { animated, AnimatedProps } from 'react-spring';

import { useWheelState } from '../../contexts';
import { Position, ValueCategory } from '../../types';

type Props = {
  textPathId: string;
  category: ValueCategory;
  animationStyles: AnimatedProps<Record<string, string | number>>;
};

export const CategoryLabel = ({
  textPathId,
  category,
  animationStyles,
}: Props) => {
  const { selectedCategory } = useWheelState();

  const { name, icon, textColor } = category;

  const fill = name === selectedCategory?.name ? textColor : 'white';

  return (
    <>
      <animated.path
        {...animationStyles}
        style={{ transform: icon.transform }}
        d={icon.path}
        fill={fill}
      />
      <animated.text
        {...animationStyles}
        fill={fill}
        key={name}
        textAnchor="middle"
        style={{ userSelect: 'none' }}
      >
        <textPath
          startOffset="55%"
          xlinkHref={textPathId}
          fontSize={20}
          fontWeight="bold"
          fontFamily="Publico headline"
          letterSpacing="2"
          alignmentBaseline={
            [Position.LeftBottom, Position.RightBottom].includes(
              category.position
            )
              ? 'after-edge'
              : 'before-edge'
          }
        >
          {name.toUpperCase()}
        </textPath>
      </animated.text>
    </>
  );
};
