import { Grid } from '@chakra-ui/react';

import { SearchEventResultOutput } from 'types';

import { MAX_PREVIEW } from '../constants';
import { EventResult } from './EventResult';

interface Props {
  results: SearchEventResultOutput[];
}

export const EventResults = ({ results }: Props) => {
  return (
    <Grid gap={12} templateColumns="1fr 1fr">
      {results.slice(0, MAX_PREVIEW).map((result) => (
        <EventResult result={result.object} key={result.object.id} />
      ))}
    </Grid>
  );
};
