import {
  AccountStatus,
  Honorific,
  Profile,
  ProfileConnectionStateEnum,
  Role,
} from 'types';

import { generateMedia } from '../images.mock';

export const generateProfile = ({
  __typename = 'Profile',
  avatarImage = generateMedia(),
  connectionState = ProfileConnectionStateEnum.NotConnected,
  description,
  family,
  honorific = Honorific.Mx,
  status = AccountStatus.Active,
  firstName = 'John',
  heroImage = generateMedia(),
  id = 'profileId',
  lastName = 'Paul',
  principal,
  title = 'Entrepreneur',
  isConnected = false,
  createdAt = Date.now(),
  updatedAt = Date.now(),
  canBeInvited = false,
  role = Role.FamilyMember,
}: Partial<Profile> = {}): Profile => ({
  __typename,
  status,
  honorific,
  canBeInvited,
  avatarImage,
  connectionState,
  createdAt,
  description,
  family,
  firstName,
  heroImage,
  id,
  lastName,
  principal,
  title,
  updatedAt,
  isConnected,
  role,
});

export const profileMock = generateProfile();
