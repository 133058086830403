import { Fragment, useEffect, useState } from 'react';

import { Divider, Flex, Heading } from '@chakra-ui/react';

import { EmptyState } from 'components/EmptyState';
import { QueryStateGuard } from 'components/QueryStateGuard';
import { mapEventTypeToVisibilityFilter, useEvents } from 'hooks/events';
import { Links } from 'router/links';
import {
  Event,
  EventFilters,
  EventStatusEnum,
  EventType,
  InputMaybe,
} from 'types';
import { getCurrentDateWithRounding } from 'utils/date';

import { EventCard } from './EventCard';
import { EventsFilters } from './EventsFilters';
import { EventContextProvider } from './utils/useEventFilters';

const getYear = (startTime?: string) =>
  startTime ? new Date(startTime).getFullYear().toString() : '';

const splitEventsByYear = (events: Event[]) => {
  const years = events.map((event) =>
    getYear(event.basicInfo?.startTime?.datetime)
  );

  return [...new Set(years)].sort().map((year) => ({
    year,
    events: events
      .filter(
        (event): event is Event =>
          getYear(event.basicInfo?.startTime?.datetime) === year
      )
      .sort((a, b) =>
        String(a.basicInfo?.startTime?.datetime) >
        String(b.basicInfo?.startTime?.datetime)
          ? 1
          : -1
      ),
  }));
};

interface Props {
  type: EventType;
}

export const EventList = ({ type }: Props) => {
  const [filters, setFilters] = useState<InputMaybe<EventFilters>>({
    from: getCurrentDateWithRounding(),
    visibility: mapEventTypeToVisibilityFilter(type),
    statuses: [EventStatusEnum.Published],
  });
  const [filtersEnabled, setFiltersEnabled] = useState<boolean>(false);
  const [splitedEvents, setSplitedEvents] = useState<
    {
      year: string;
      events: Event[];
    }[]
  >([]);

  const {
    events: upcomingEvents,
    loading,
    error,
  } = useEvents({
    filters,
  });

  const clearEventsFilters = () => {
    setFilters({
      from: getCurrentDateWithRounding(),
      visibility: mapEventTypeToVisibilityFilter(type),
      statuses: [EventStatusEnum.Published],
    });
    setFiltersEnabled(false);
  };

  useEffect(() => {
    setSplitedEvents(splitEventsByYear(upcomingEvents));
  }, [upcomingEvents]);

  return (
    <Flex direction="column" color="primary.blue.default" pb={8}>
      <EventContextProvider>
        <EventsFilters
          setFilters={setFilters}
          clearEventsFilters={clearEventsFilters}
          filters={filters}
          filtersEnabled={filtersEnabled}
          setFiltersEnabled={setFiltersEnabled}
        />
      </EventContextProvider>
      <QueryStateGuard error={error} loading={loading}>
        {splitedEvents.length ? (
          splitedEvents.map(({ events, year }) => {
            return (
              <Fragment key={year}>
                <Heading size="h5" mb={2} mt={3}>
                  {year}
                </Heading>
                <Divider borderColor="primary.blue.default" />
                {events.map((event: Event) => (
                  <EventCard hero={event.hero} event={event} key={event.id} />
                ))}
              </Fragment>
            );
          })
        ) : (
          <EmptyState
            title="You currently have no events listed"
            buttonName="View Itriom events"
            buttonLink={`${Links.events}#FAMILY`}
          />
        )}
      </QueryStateGuard>
    </Flex>
  );
};
