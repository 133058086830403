import { useBreakpointValue } from '@chakra-ui/react';

import { DesktopInsightsGrid } from './DesktopInsightsGrid';
import { MobileInsightsGrid } from './MobileInsightsGrid';
import { TabletInsightsGrid } from './TabletInsightsGrid';
import { CommonInsightsGridProps } from './types';

export const InsightsGrid = ({
  gridInsights,
  headingContent,
  type,
  loading,
}: CommonInsightsGridProps) => {
  const Content =
    useBreakpointValue({
      base: MobileInsightsGrid,
      sm: MobileInsightsGrid,
      md: TabletInsightsGrid,
      lg: DesktopInsightsGrid,
      xl: DesktopInsightsGrid,
    }) ?? DesktopInsightsGrid;

  return (
    <Content
      loading={loading}
      gridInsights={gridInsights}
      headingContent={headingContent}
      type={type}
    />
  );
};
