import { gql, useMutation } from '@apollo/client';

import { postsGql } from 'hooks/posts';
import { useWithMutationToast } from 'hooks/useWithMutationToast';
import {
  AppreciateInput,
  Mutation,
  MutationToggleAppreciationArgs,
} from 'types';

export const toggleAppreciationGql = gql`
  mutation ToggleAppreciation($appreciate: AppreciateInput!) {
    toggleAppreciation(appreciate: $appreciate) {
      id
    }
  }
`;

export const useToggleAppreciation = () => {
  const [mutate, { loading, error }] = useMutation<
    Pick<Mutation, 'toggleAppreciation'>,
    MutationToggleAppreciationArgs
  >(toggleAppreciationGql, {
    refetchQueries: [postsGql],
  });

  const toggle = (appreciate: AppreciateInput) =>
    mutate({ variables: { appreciate } });

  const toggleAppreciate = useWithMutationToast(toggle, {
    disableSuccessMessage: true,
  });

  return { toggleAppreciate, loading, error };
};
