import { Flex } from '@chakra-ui/react';

import { MenuUserProfile } from 'components/MenuUserProfile';
import { MostActiveGroups } from 'components/MostActiveGroups';
import { RecommendedGroups } from 'components/RecommendedGroups';
import { TrendingGroups } from 'components/TrendingGroups';
import { useAuthContext } from 'contexts/AuthContext';
import { hasSdg } from 'utils/roles';

import { ManageGroup } from './ManageGroup';
import { RecentlyJoined } from './RecentlyJoined';

export const GroupsSider = () => {
  const { account } = useAuthContext();

  return (
    <Flex direction="column">
      <MenuUserProfile profile={account}>
        <ManageGroup />
      </MenuUserProfile>

      <Flex pt={4} gap={4} direction="column">
        {hasSdg(account?.role) && <RecommendedGroups />}
        <RecentlyJoined />
        <MostActiveGroups />
        <TrendingGroups />
      </Flex>
    </Flex>
  );
};
