import { Text, Flex, Heading } from '@chakra-ui/react';

type Props = {
  title: string;
  subtitle: string;
  description: string;
};

export const ChartsSectionHeader = ({
  title,
  subtitle,
  description,
}: Props): JSX.Element => (
  <Flex direction="column" maxW={400}>
    <Heading size="h4">{title}</Heading>
    <Heading size="h3">{subtitle}</Heading>
    <Text>{description}</Text>
  </Flex>
);
