import { ArrowLeft20, ArrowRight20 } from '@carbon/icons-react';
import { Flex, IconButton } from '@chakra-ui/react';

type Props = {
  onClick: () => void;
  variant: 'next' | 'prev';
};

export const ArrowButton = ({ onClick, variant }: Props): JSX.Element => {
  const goNextButton = variant === 'next';
  const icon = goNextButton ? ArrowRight20 : ArrowLeft20;
  const position = goNextButton ? { right: 0 } : { left: 0 };

  return (
    <Flex
      position="absolute"
      {...position}
      top="50%"
      transform="translateY(-50%)"
      mx={8}
      background="ui.white"
      borderRadius="50%"
      sx={{ aspectRatio: '1/1' }}
      alignItems="center"
    >
      <IconButton
        aria-label={`${variant} image`}
        as={icon}
        variant="iconButton"
        onClick={onClick}
      />
    </Flex>
  );
};
