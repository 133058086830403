import { useAuthContext } from 'contexts/AuthContext';
import { useConnectionInvitations } from 'gql/connections/query/connectionInvitations';

export const useInvitationForMe = (userId: string) => {
  const { account } = useAuthContext();
  const { connectionInvitations } = useConnectionInvitations();

  const isOwnProfile = account?.id === userId;

  if (isOwnProfile) return undefined;

  const invitationForMe = connectionInvitations.find(
    (connection) =>
      connection.senderId === userId && connection.recipientId === account?.id
  );

  return invitationForMe;
};
