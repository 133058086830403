import { gql, MutationHookOptions, useMutation } from '@apollo/client';

import { accountGql } from 'gql/accounts/query/account';
import { useLocalStorageJWT } from 'hooks/useLocalStorageJWT';
import { Mutation, MutationVerifySecondFactorArgs } from 'types';

import { useWithMutationToast } from '../useWithMutationToast';

export const verifySecondFactorGql = gql`
  mutation VerifySecondFactor($jwt: String!, $secondFactor: Float!) {
    verifySecondFactor(jwt: $jwt, secondFactor: $secondFactor) {
      accessToken
    }
  }
`;

export const useVerifySecondFactor = <
  TData extends Pick<Mutation, 'verifySecondFactor'>,
  TVariables extends MutationVerifySecondFactorArgs
>(
  options?: MutationHookOptions<TData, TVariables>
) => {
  const { setJwtInLocalStorage } = useLocalStorageJWT();

  const [mutation, { loading, error }] = useMutation<TData, TVariables>(
    verifySecondFactorGql,
    {
      onCompleted: (data) => {
        setJwtInLocalStorage(data.verifySecondFactor.accessToken);
      },
      refetchQueries: [accountGql],
      ...options,
    }
  );

  const handleMutation = (variables: TVariables) => mutation({ variables });

  const verifySecondFactor = useWithMutationToast(handleMutation, {
    errorMessage: 'Two factor authentication error',
    disableSuccessMessage: true,
  });

  return {
    verifySecondFactor,
    loading,
    error,
  };
};
