import { ArrowLeft20, ArrowRight20 } from '@carbon/icons-react';
import { IconButton } from '@chakra-ui/react';

import { Direction } from './types';

type Props = {
  direction: Direction;
  disabled?: boolean;
  onClick?: () => void;
};

export const Arrow = ({ direction, disabled, onClick }: Props): JSX.Element => {
  const isLeft = direction === Direction.Left;
  const Icon = isLeft ? ArrowLeft20 : ArrowRight20;

  return (
    <IconButton
      aria-label="scroll left"
      variant="iconButton"
      bgColor="#C4C4C4"
      borderRadius={0}
      p={2}
      boxSizing="content-box"
      fill="white"
      isDisabled={disabled}
      onClick={onClick}
      icon={<Icon />}
    />
  );
};
