import { useTranslation } from 'react-i18next';

import { useDisclosure, Flex, Text } from '@chakra-ui/react';

import { ViewButton } from 'components/Button';

import { SdgsModal } from './SdgsModal';

export const SdgDescription = () => {
  const { t } = useTranslation(['family']);
  const { onOpen, isOpen, onClose } = useDisclosure();

  return (
    <Flex direction="column" gap={4}>
      <SdgsModal isOpen={isOpen} onClose={onClose} />
      <Text>{t('family:charter.section.sdgInfluences.description')}</Text>
      <ViewButton onClick={onOpen} variant="blue">
        {t('family:charter.section.sdgInfluences.cta')}
      </ViewButton>
    </Flex>
  );
};
