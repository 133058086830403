import { useMutation, gql } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationUpdateGroupArgs } from 'types';

import { groupGql } from './useGroup';

export const updateGroupGql = gql`
  mutation UpdateGroup(
    $group: GroupUpdateInput!
    $avatarImage: [Upload!]
    $heroImage: [Upload!]
  ) {
    updateGroup(
      group: $group
      avatarImage: $avatarImage
      heroImage: $heroImage
    ) {
      id
    }
  }
`;

export const useUpdateGroup = () => {
  const [mutation] = useMutation<
    Pick<Mutation, 'updateGroup'>,
    MutationUpdateGroupArgs
  >(updateGroupGql, {
    refetchQueries: [groupGql],
  });

  const handle = (variables: MutationUpdateGroupArgs) =>
    mutation({ variables });

  const updateGroup = useWithMutationToast(handle, {
    successMessage: `Group has been successfully updated`,
  });

  return { updateGroup };
};
