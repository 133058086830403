import { ValueConfiguratorCategoryEnum, ValueConfiguratorOutput } from 'types';

import { ValuesInputs, ValueType } from './types';

export const getValidInitialValues = <T extends ValuesInputs>(
  valueConfigurator: ValueConfiguratorOutput | undefined,
  valueKey: ValueType,
  categoryKey: Lowercase<ValueConfiguratorCategoryEnum>
): T | undefined => {
  if (!valueConfigurator) return undefined;

  const { __typename, status, ...restValueConfigurator } = valueConfigurator;

  const initialValues = restValueConfigurator?.[valueKey]?.[categoryKey];

  if (initialValues) {
    const { __typename: tp, ...restInitialValues } = initialValues;

    return restInitialValues as T;
  }

  return undefined;
};
