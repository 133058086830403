import { useMemo } from 'react';

import { Flex } from '@chakra-ui/layout';
import { Heading, Text } from '@chakra-ui/react';

import { Links } from 'router/links';
import { underlay } from 'theme/mixins/underlay';
import { Event } from 'types';
import { dateDistance, formatDate } from 'utils/date';
import { generateUrlById } from 'utils/links';
import { capitalize } from 'utils/string';

import { ViewButton } from '../CarouselBaseV2/ViewButton';

export const KeyEventCard = ({ item }: { item: Event }) => {
  const startTime = item.basicInfo?.startTime
    ? new Date(item.basicInfo?.startTime.datetime)
    : null;
  const distance = startTime
    ? dateDistance(startTime, {
        before: 'to go',
        after: 'ago',
      })
    : 'Date to be announced';

  const [distanceNumber, distanceText] = useMemo(() => {
    const splited = distance.split(/(\d)/);

    if (splited.length === 3) {
      return [`${splited[0]}${splited[1]}`, splited[2]];
    }

    return ['', splited[0]];
  }, [distance]);

  return (
    <Flex
      bgImage={item.hero.heroImage?.signedUrl}
      bgSize="cover"
      bgPosition="center"
      color="ui.white"
      pos="relative"
      w="full"
      h="full"
      {...underlay()}
    >
      <Flex
        position="absolute"
        top={0}
        right={0}
        bottom={0}
        left={0}
        direction="column"
        px={5}
        py={3}
        align="flex-start"
      >
        <Heading size="h1" mt="auto">
          {capitalize(distanceNumber)}
        </Heading>
        <Heading size="h3" mb={2}>
          {distanceText}
        </Heading>
        {startTime && (
          <Text size="p1b">{formatDate(startTime, 'EEEE do')}</Text>
        )}
        <Text size="p1" w="80%" mr="auto" mb={3} noOfLines={2}>
          {item.hero.name}
        </Text>
        <ViewButton to={generateUrlById(Links.event, item.id)}>
          View event
        </ViewButton>
      </Flex>
    </Flex>
  );
};
