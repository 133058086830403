import { gql, useMutation } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationAttendToEventArgs } from 'types';

import { eventGql } from './useEvent';
import { eventsGql } from './useEvents';

export const attendToEventGql = gql`
  mutation AttendToEvent($id: String!) {
    attendToEvent(id: $id)
  }
`;

export const useAttendToEvent = (eventId: string) => {
  const [attendToEventMutation, { loading }] = useMutation<
    Pick<Mutation, 'attendToEvent'>,
    MutationAttendToEventArgs
  >(attendToEventGql, {
    refetchQueries: [eventGql, eventsGql],
  });

  const handleAttendToEvent = () =>
    attendToEventMutation({ variables: { id: eventId } });

  const attendToEvent = useWithMutationToast(handleAttendToEvent, {
    successMessage: 'Saved',
    errorMessage: 'Cannot attend to the event',
  });

  return { attendToEvent, loading };
};
