import { About } from 'components/ValuesConfiguratorIntro/About';
import { Links } from 'router/links';

import { ValuesLayout } from '../../components';

export const AboutPage = () => (
  <ValuesLayout
    leftButton={{
      link: Links.valuesConfigurator,
      color: 'ui.white',
    }}
    rightButton={{
      link: Links.valuesConfiguratorEssential,
    }}
  >
    <About />
  </ValuesLayout>
);
