import { useTranslation } from 'react-i18next';

import { Flex } from '@chakra-ui/react';

import { Breadcrumbs } from 'components/Breadcrumbs';
import { EditContentButton } from 'components/EditContentButton';
import { LayoutGridElement } from 'components/Layout/Grid';
import { Links } from 'router/links';
import { Event } from 'types';
import { generateUrlById } from 'utils/links';

import { EventHero } from '../../EventHero';

export const HeroSection = ({ event }: { event: Event }) => {
  const { t } = useTranslation(['navigation', 'events']);

  const breadcrumbItems = [
    { link: Links.home, label: t('navigation:home') },
    { link: Links.events, label: t('events:event-listings') },
    {
      link: generateUrlById(Links.event, event.id),
      label: event.hero.name,
    },
  ];

  return (
    <LayoutGridElement>
      <Flex direction="column" gap={4}>
        <EventHero event={event} />
        <Flex justifyContent="space-between">
          <Breadcrumbs items={breadcrumbItems} />
          <EditContentButton
            item={event}
            editUrl={generateUrlById(Links.editEvent, event.id)}
          />
        </Flex>
      </Flex>
    </LayoutGridElement>
  );
};
