import { Option } from 'types';

import { useSdgs } from './useSdgs';

export const useSdgOptions = () => {
  const { sdgs, loading, error } = useSdgs();

  const sdgOptions = sdgs.map<Option>((sdg) => ({
    label: sdg.name,
    value: sdg.id,
  }));

  return { sdgOptions, loading, error };
};
