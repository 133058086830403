import { MediaOutput, Profile, Scalars, SearchOutput } from './generated-types';

export type SearchResult = Omit<SearchOutput, '__typename'>;

export type MenuLink = {
  text: string;
  href?: string;
  onClick?: () => void;
  enabled?: boolean;
};

export type Option<T extends string = string> = {
  label: string;
  value: T;
  disabled?: boolean;
  shortLabel?: string;
};

export type MenuProps = {
  profile: Profile;
};

export type Media = Array<Scalars['Upload'] | MediaOutput> | undefined;

export enum LocalStorageKeys {
  JWT = 'jwt',
  REDIRECT_URL = 'redirectUrl',
  IS_DEV = 'isDev',
}

export enum LayoutType {
  OldDesign = 'oldDesign',
  Default = 'default',
  ValuesIntro = 'valuesIntro',
  None = 'none',
  SignIn = 'signIn',
}

export enum GroupSubtype {
  Join = 'JoinInfo',
  Leave = 'LeaveInfo',
}

export enum EventType {
  All = 'ALL',
  Family = 'FAMILY',
  Itriom = 'ITRIOM',
  Peers = 'PEERS',
  Public = 'PUBLIC',
  My = 'MY',
}

export type EventTypeData = {
  type: EventType;
  referenceId: string;
};

export enum InsightItriomVisibilityTypes {
  All = 'ALL',
  Family = 'FAMILY',
  Itriom = 'ITRIOM',
  Peers = 'PEERS',
  Public = 'PUBLIC',
}

export type {
  WithChildren,
  WithOptionalChildren,
  WithRenderProp,
  WithTemplateComponent,
  ComponentType,
} from './Components';
export * from './Insights';
export * from './Initiatives';
export * from './Advisors';
export * from './Events';
export * from './SdgsConfig';
export type { FamilyCharterInputs } from './familyCharter';
export type { GroupInternalType } from './Groups';
export type { TagsInput } from './Tags';
export * from './generated-types';
export type {
  NotificationUnion,
  PaginatedNotificationUnion,
} from './Notifications';

export type BasePaginationOutput<T = unknown> = {
  nodes: T[];
  offset: number;
  totalCount: number;
};
