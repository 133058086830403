import { WheelModel } from 'components/ValuesConfiguratorIntro/WheelModel';
import { Links } from 'router/links';

import { ValuesLayout } from '../components/ValuesConfigurator';

export const ValuesImpactPage = () => {
  return (
    <ValuesLayout
      background="base2"
      leftButton={{
        link: Links.valuesConfiguratorCategorisation,
      }}
      rightButton={{
        link: Links.valuesConfiguratorCta,
      }}
    >
      <WheelModel />
    </ValuesLayout>
  );
};
