import { FlexProps } from '@chakra-ui/layout';
import { Flex, Spinner } from '@chakra-ui/react';

type Props = {
  spinnerSize?: string;
} & FlexProps;

export const Loader = ({
  spinnerSize = 'xl',
  ...flexProps
}: Props): JSX.Element => (
  <Flex
    w="100%"
    align="center"
    justify="center"
    data-testid="Loader"
    {...flexProps}
  >
    <Spinner size={spinnerSize} />
  </Flex>
);
