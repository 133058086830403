import { ReactNode } from 'react';

import { HTMLChakraProps, Td } from '@chakra-ui/react';

type Props = {
  children?: ReactNode;
  center?: boolean;
} & HTMLChakraProps<'td'>;

export const Cell = ({ children, center, ...rest }: Props) => (
  <Td
    border="none"
    p={0}
    position="relative"
    textAlign={center ? 'center' : 'left'}
    {...rest}
  >
    {children}
  </Td>
);
