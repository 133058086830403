import { ReactNode } from 'react';

import { Box, HStack } from '@chakra-ui/layout';
import { Flex, Text } from '@chakra-ui/react';

type HeaderLayoutProps = {
  avatarSection: ReactNode;
  actionMenu: ReactNode;
  title: string;
  subtitle: string;
  isGroupConversation?: boolean;
};

export const HeaderLayout = ({
  avatarSection,
  actionMenu,
  title,
  subtitle,
  isGroupConversation,
}: HeaderLayoutProps) => (
  <HStack
    justify="space-between"
    w="full"
    p={3}
    color="primary.blue.default"
    bg="ui.grey4"
    data-cy="messenger-header"
  >
    <Box maxW="48px" h="53px" w="full">
      {avatarSection}
    </Box>
    <Flex
      direction={isGroupConversation ? 'column-reverse' : 'column'}
      w="full"
      h="full"
      justify="space-between"
      p={0}
    >
      <Text size="p2b">{title}</Text>
      <Text size="p2" my="auto">
        {subtitle}
      </Text>
    </Flex>
    {actionMenu}
  </HStack>
);
