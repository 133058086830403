import { ReactNode } from 'react';

import { Grid, GridItem, Flex } from '@chakra-ui/react';

import { BackgroundImageTile } from 'components/ContentGrid/BackgroundImageTile';
import { SideImageTile } from 'components/ContentGrid/SideImageTile';
import { TextTile } from 'components/ContentGrid/TextTile';
import { Loader } from 'components/Loader';

import { CommonInsightsGridProps, GridInsight } from './types';
import { getTilePropsFromInsight } from './utils';

const REGULAR_ROW_HEIGHT = 320;

type InsightsGridContentRows = 2 | 3 | 5;

const getRowsNumber = (insightsCount: number): InsightsGridContentRows => {
  if (insightsCount >= 7) return 5;
  if (insightsCount >= 5) return 3;
  if (insightsCount >= 4) return 2;

  return 2;
};

export type DesktopInsightsGridProps = {
  gridInsights: GridInsight[];
  headingContent: ReactNode;
  type: 'featured' | 'other';
};

export const DesktopInsightsGrid = ({
  gridInsights,
  headingContent,
  type,
  loading,
}: CommonInsightsGridProps) => {
  const rowsNumber = getRowsNumber(gridInsights.length);

  if (loading) {
    return (
      <Flex alignItems="center" justifyContent="center" h={520}>
        <Loader />
      </Flex>
    );
  }

  if (!gridInsights.length) {
    return null;
  }

  return (
    <Grid
      gridTemplateColumns="repeat(4, 1fr)"
      gridTemplateRows={`repeat(${rowsNumber}, ${REGULAR_ROW_HEIGHT}px)`}
      gridGap={4}
      alignContent="flex-start"
    >
      <GridItem gridColumn="1" gridRow="1">
        {headingContent}
      </GridItem>

      {gridInsights[0] && (
        <GridItem gridColumn="2" gridRow="1 / 3" overflow="hidden">
          {type === 'featured' ? (
            <SideImageTile
              {...getTilePropsFromInsight(gridInsights[0])}
              imagePosition="top"
            />
          ) : (
            <BackgroundImageTile
              {...getTilePropsFromInsight(gridInsights[0])}
              justify="flex-start"
              align="flex-start"
              maxW="100%"
            />
          )}
        </GridItem>
      )}

      {gridInsights[1] && (
        <GridItem gridColumn="1" gridRow="2">
          <TextTile {...getTilePropsFromInsight(gridInsights[1])} />
        </GridItem>
      )}

      {gridInsights[2] && (
        <GridItem gridColumn="3 / 5" gridRow="1">
          <SideImageTile
            {...getTilePropsFromInsight(gridInsights[2])}
            imagePosition="left"
          />
        </GridItem>
      )}

      {gridInsights[3] && (
        <GridItem gridColumn="3 / 5" gridRow="2">
          <BackgroundImageTile {...getTilePropsFromInsight(gridInsights[3])} />
        </GridItem>
      )}

      {gridInsights[4] && (
        <GridItem gridColumn="1 / 3" gridRow="3">
          <BackgroundImageTile {...getTilePropsFromInsight(gridInsights[4])} />
        </GridItem>
      )}

      {gridInsights[5] && (
        <GridItem gridColumn="3 / 5" gridRow="3">
          <SideImageTile
            {...getTilePropsFromInsight(gridInsights[5])}
            imagePosition="right"
          />
        </GridItem>
      )}

      {gridInsights[6] && (
        <GridItem gridColumn="1 / 5" gridRow="4 / 6">
          <BackgroundImageTile
            {...getTilePropsFromInsight(gridInsights[6])}
            align="flex-end"
            type="banner"
          />
        </GridItem>
      )}
    </Grid>
  );
};
