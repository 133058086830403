import { useTranslation } from 'react-i18next';

import { Flex } from '@chakra-ui/react';

import { Breadcrumbs } from 'components/Breadcrumbs';
import { HeroMedia } from 'components/HeroMedia';
import { LayoutGrid, LayoutGridElement } from 'components/Layout/Grid';
import { useFamilyById } from 'gql/family/query/family';
import { Links } from 'router/links';
import { FamilyCharter } from 'types';

import { GoalsSection } from '../components/GoalsSection';
import { PurposeSection } from '../components/PurposeSection';
import { SdgInfluencesSection } from '../components/SdgInfluencesSection';
import { ValuesSection } from '../components/ValuesSection';

type Props = {
  useFamilyCharter: () => FamilyCharter;
};

export const FamilyCharterPage = ({ useFamilyCharter }: Props) => {
  const familyCharter = useFamilyCharter();
  const { t } = useTranslation(['navigation']);

  const { hero, goals } = familyCharter;
  const { heroImage, heroVideo, heading, preferredHeroMediaToDisplay } = hero;
  const { familyId } = hero;
  const { family } = useFamilyById(familyId);

  return (
    <LayoutGrid>
      <LayoutGridElement>
        <Flex direction="column" gap={4}>
          <HeroMedia
            imageUrl={heroImage?.signedUrl}
            videoUrl={heroVideo?.signedUrl}
            heading={heading}
            preferredHeroMediaToDisplay={preferredHeroMediaToDisplay}
          />
          <Breadcrumbs
            items={[
              { link: Links.home, label: t('navigation:home') },
              { link: Links.charter, label: t('navigation:cms.charter') },
            ]}
          />
        </Flex>
      </LayoutGridElement>

      <PurposeSection purpose={hero.purpose} />

      {family && <ValuesSection values={family.values} />}

      {familyId && <SdgInfluencesSection familyId={familyId} />}

      <GoalsSection goals={goals} />
    </LayoutGrid>
  );
};
