import { QueryStateGuard } from 'components/QueryStateGuard';
import { useAdvisorByRelatedAccountId } from 'gql/advisors';

import { AdvisorPreview } from '../components/AdvisorPreview';
import { NotCompletedProfile } from '../components/NotCompletedProfile';

type Props = {
  accountId: string;
};

export const AdvisorFromProfilePage = ({ accountId }: Props) => {
  const { advisor, error, loading } = useAdvisorByRelatedAccountId(accountId);

  return (
    <QueryStateGuard
      error={error}
      ErrorComponent={NotCompletedProfile}
      loading={loading}
    >
      {advisor && <AdvisorPreview advisor={advisor} />}
    </QueryStateGuard>
  );
};
