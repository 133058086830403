import { WithChildren } from 'types';

const ITRIOM_WEBSITE_URL = 'https://www.itriom.com';
const ITRIOM_INFO_MAIL = 'info@itriom.com';

export const ExternalLink = ({
  href,
  children,
}: WithChildren<{ href: string }>) => (
  <a target="_blank" rel="noopener noreferrer" href={href}>
    {children}
  </a>
);

export const WebsiteLink = () => (
  <ExternalLink href={ITRIOM_WEBSITE_URL}>itriom.com</ExternalLink>
);

export const MailLink = () => (
  <ExternalLink href={`mailto:${ITRIOM_INFO_MAIL}`}>
    info@itriom.com
  </ExternalLink>
);
