import { useFormikContext } from 'formik';

import { Button } from '@chakra-ui/react';

import { LocalStorageKeys } from 'types';

import { ValuesInputs } from './types';

const valueNumber = [23, 24, 26, 27];

// ONLY FOR DEV PURPOSES
export const HiddenAllocateButton = () => {
  const { setFieldValue, values } = useFormikContext<ValuesInputs>();

  const fields = Object.keys(values);

  const handleClick = () => {
    fields.forEach((field, index) =>
      setFieldValue(field, valueNumber[index] || 0)
    );
  };

  return localStorage.getItem(LocalStorageKeys.IS_DEV) ? (
    <Button type="button" onClick={handleClick}>
      Allocate (dev)
    </Button>
  ) : null;
};
