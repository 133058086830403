import { useMemo } from 'react';

import { Text, Flex } from '@chakra-ui/react';

import { CarouselFeaturedNews } from 'components/Carousel';
import { QueryStateGuard } from 'components/QueryStateGuard';
import { useAuthContext } from 'contexts/AuthContext';
import { useListInsights, useRecommendedInsights } from 'hooks/insights';
import { InsightsRecommendationEnum } from 'types';
import { hasSdg } from 'utils/roles';

export const Hero = () => {
  const { account } = useAuthContext();
  const recommendedInsights = useRecommendedInsights({
    pagination: { limit: 3, offset: 0 },
    matchingType: hasSdg(account?.role)
      ? InsightsRecommendationEnum.MatchingAtLeastOneSdg
      : InsightsRecommendationEnum.NotMatchingAnySdg,
    visibility: { userHasAccessTo: true },
  });

  const listInsights = useListInsights({
    filters: { visibility: { itriom: true } },
    pagination: { limit: 3, offset: 0 },
    skip:
      (recommendedInsights.loading || recommendedInsights.error) &&
      recommendedInsights.insights.length >= 0,
  });

  const insights = useMemo(
    () =>
      recommendedInsights.insights.length
        ? recommendedInsights.insights
        : listInsights.listInsights,
    [listInsights.listInsights, recommendedInsights.insights]
  );

  return (
    <Flex
      position="relative"
      bgColor="white"
      w="full"
      h="full"
      borderRadius={4}
      overflow="hidden"
    >
      <QueryStateGuard
        loading={recommendedInsights.loading || listInsights.loading}
        error={recommendedInsights.error}
        errorTitle="Cannot fetch insight"
      >
        {insights.length ? (
          <CarouselFeaturedNews insights={insights} />
        ) : (
          <Text p={2}>There is no insight to show</Text>
        )}
      </QueryStateGuard>
    </Flex>
  );
};
