import { ReactNode } from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';

type ConversationTileLayoutProps = {
  isActive: boolean;
  isUnread: boolean;
  avatarSection: ReactNode;
  title: string;
  subtitle?: string;
  date?: string;
  messagePreview?: string;
  actionMenu: ReactNode;
  handleSelect: () => void;
};

export const ConversationTileLayout = ({
  actionMenu,
  isUnread,
  avatarSection,
  date,
  isActive,
  subtitle,
  title,
  handleSelect,
  messagePreview,
}: ConversationTileLayoutProps) => {
  const fontWeight = isUnread ? 'bold' : 'inherit';

  return (
    <Flex
      align="center"
      w="100%"
      p={3}
      borderLeft={isActive ? '3px solid #041E42' : '3px solid white'}
      bgColor={isActive ? 'ui.grey4' : 'ui.white'}
      onClick={handleSelect}
      _hover={{
        bgColor: 'gray.100',
      }}
      cursor="pointer"
    >
      <Flex position="relative" w="80px" h="full">
        {avatarSection}
      </Flex>
      <Box width="100%">
        <Flex justifyContent="space-between" alignItems="baseline">
          <Text
            flex={4}
            size={isUnread ? 'p2b' : 'p2'}
            _hover={{ textDecoration: 'underline' }}
          >
            {title}
          </Text>
          <Text flex={3} size="p3" fontWeight={fontWeight}>
            {date}
          </Text>
          <Box mx="-10px">{actionMenu}</Box>
        </Flex>
        <Text size="p3" color="ui.grey2" fontWeight={fontWeight}>
          {subtitle}
        </Text>
        <Text size="p2" fontWeight={fontWeight}>
          {messagePreview}
        </Text>
      </Box>
    </Flex>
  );
};
