import { gql, useMutation } from '@apollo/client';

import { MediaOutput } from 'gql/fragments';
import { Mutation, MutationJoinGroupArgs } from 'types';

import { useWithMutationToast } from '../useWithMutationToast';
import { groupGql } from './useGroup';
import { groupsGql } from './useGroups';

export const joinGroupGql = gql`
  ${MediaOutput}
  mutation JoinGroup($groupId: String!) {
    joinGroup(groupId: $groupId) {
      members {
        id
        firstName
        lastName
        title
        avatarImage {
          ...MediaOutput
        }
      }
    }
  }
`;

export const useJoinGroup = () => {
  const [joinGroupMutation, { loading, error }] = useMutation<
    Pick<Mutation, 'joinGroup'>,
    MutationJoinGroupArgs
  >(joinGroupGql, { refetchQueries: [groupGql, groupsGql] });

  const handleJoin = (groupId: string) =>
    joinGroupMutation({
      variables: {
        groupId,
      },
    });

  const joinGroup = useWithMutationToast(handleJoin, {
    successMessage: 'Successfully joined the group',
    errorMessage: 'Failure',
  });

  return { joinGroup, loading, error };
};
