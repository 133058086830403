import { Carousel, CarouselType } from 'components/Carousel';
import { LayoutGridElement } from 'components/Layout/Grid';
import { InitiativeType } from 'features/Initiative/types';

import { InitiativeTabBasePanel } from './InitiativeTabBasePanel';

type Props = {
  type: InitiativeType;
};

export const InitiativesTabWithCarouselPanel = ({ type }: Props) => {
  return (
    <>
      <LayoutGridElement gridColumn="5 / span 12" gridRow="3 / span 1">
        <InitiativeTabBasePanel type={type} />
      </LayoutGridElement>

      <LayoutGridElement colSpan={16} py={4}>
        <Carousel type={CarouselType.Initiatives} />
      </LayoutGridElement>

      <LayoutGridElement gridColumn="5 / span 12">
        <InitiativeTabBasePanel type={type} isOther />
      </LayoutGridElement>
    </>
  );
};
