import { gql, useQuery } from '@apollo/client';

import { FamilyCharterDeepNestingFragment } from 'gql/fragments';
import { createMock } from 'mocks';
import { familyCharterMock } from 'mocks/familyCharter.mock';
import { Query } from 'types';

export const familyCharterGql = gql`
  ${FamilyCharterDeepNestingFragment}
  query FamilyCharter($familyCharterId: String!) {
    familyCharter(id: $familyCharterId) {
      ...FamilyCharterDeepNestingFragment
    }
  }
`;

export const familyCharterGqlMock = createMock<Pick<Query, 'familyCharter'>>(
  familyCharterGql,
  { data: { familyCharter: familyCharterMock } },
  {
    familyCharterId: familyCharterMock.id,
  }
);

export const useFamilyCharter = (id: string) => {
  const { data, error, loading } = useQuery<Pick<Query, 'familyCharter'>>(
    familyCharterGql,
    {
      variables: { familyCharterId: id },
    }
  );

  return { familyCharter: data?.familyCharter, error, loading };
};
