import { gql, useQuery } from '@apollo/client';

import { Event } from 'gql/fragments';
import { createMock, eventMock } from 'mocks';
import { Query } from 'types';

export const eventGql = gql`
  ${Event}
  query Event($eventId: String!) {
    event(id: $eventId) {
      ...Event
    }
  }
`;

export const eventGqlMock = createMock<Pick<Query, 'event'>>(
  eventGql,
  { data: { event: eventMock } },
  { eventId: '1' }
);

export const useEvent = (id: string | undefined) => {
  const { data, loading, error, refetch } = useQuery<Pick<Query, 'event'>>(
    eventGql,
    { variables: { eventId: id }, skip: !id }
  );
  const event = data?.event;

  return { event, loading, error, refetch };
};
