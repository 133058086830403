import { CSSObject } from '@emotion/react';
import { useField } from 'formik';

import { useCallback } from 'react';

import {
  FormControl,
  Checkbox,
  FormErrorMessage,
  CheckboxProps,
} from '@chakra-ui/react';

type Props = {
  name: string;
  label?: string;
  containerStyles?: CSSObject;
  fieldStyles?: CSSObject;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
} & CheckboxProps;

export const CheckboxInput = ({
  name,
  label,
  containerStyles,
  onChange,
  fieldStyles,
  ...checkboxProps
}: Props): JSX.Element => {
  const [field, { error, touched }, { setValue }] = useField(name);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.checked);

      if (onChange) {
        onChange(event);
      }
    },
    [setValue, onChange]
  );

  const isInvalid = !!error && !!touched;

  return (
    <FormControl isInvalid={isInvalid} sx={containerStyles}>
      <Checkbox
        isInvalid={isInvalid}
        fontWeight="normal"
        iconColor="ui.white"
        colorScheme="gray"
        isChecked={field.value}
        size="md"
        {...checkboxProps}
        {...field}
        onChange={handleChange}
        sx={fieldStyles}
      >
        {label}
      </Checkbox>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};
