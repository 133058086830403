import { useTranslation } from 'react-i18next';

import { Flex, Box, FlexProps } from '@chakra-ui/react';

import { AdvisorCard } from 'components/AdvisorCard';
import { ViewButton } from 'components/Button';
import { Panel } from 'components/Layout/Panel';
import { QueryStateGuard } from 'components/QueryStateGuard';
import { useListAdvisors } from 'gql/advisors';
import { Links } from 'router/links';

const ADVISOR_CARD_WIDTH = 300;

type Props = {
  variant: 'grey' | 'transparent';
  direction?: FlexProps['direction'];
};

export const YourAdvisors = ({ direction = 'row', variant }: Props) => {
  const { t } = useTranslation(['advisors', 'common']);

  const { advisors, loading, error } = useListAdvisors();

  if (!advisors.length) return null;

  return (
    <Panel
      title={t('advisors:your-advisors')}
      action={
        advisors.length > 4 && (
          <ViewButton variant="blue" to={Links.connections}>
            {t('common:view-all', {
              entity: t('common:advisors').toLowerCase(),
            })}
          </ViewButton>
        )
      }
      h="fit-content"
      titleWrapperStyling={{
        ...(direction === 'row' && { pb: 0 }),
      }}
      containerStyling={{
        background: variant === 'grey' ? 'ui.grey4' : 'transparent',
      }}
    >
      <QueryStateGuard
        loading={loading}
        error={error}
        errorTitle={t('advisors:fetch-error')}
      >
        <Flex w="100%" overflowX="auto" direction={direction} gap={4}>
          {advisors.map((advisor) => (
            <Box
              w={direction === 'row' ? ADVISOR_CARD_WIDTH : 'inherit'}
              key={advisor.id}
            >
              <AdvisorCard advisor={advisor} />
            </Box>
          ))}
        </Flex>
      </QueryStateGuard>
    </Panel>
  );
};
