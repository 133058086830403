import { Flex, Heading, Image, Text, VStack } from '@chakra-ui/react';

import { FamilyCharterGoalObjectiveOutput } from 'types';

const UNDERLAY_BEFORE_STYLING = {
  content: '""',
  bgGradient:
    'linear(90deg, rgba(10, 30, 64, 1) 50%, rgba(10, 30, 64, 0.2) 100%)',
  position: 'absolute',
  width: '100%',
  height: '100%',
  zIndex: 0,
};

type Props = {
  objective: FamilyCharterGoalObjectiveOutput;
};

export const ObjectiveBanner = ({ objective }: Props) => {
  return (
    <VStack display="inline-block" w="100%" id={objective.id}>
      <Flex
        w="100%"
        color="ui.white"
        pos="relative"
        _before={UNDERLAY_BEFORE_STYLING}
      >
        <Flex
          pos="absolute"
          direction="column"
          justify="flex-end"
          inset={0}
          p={3}
        >
          <Heading size="h5">{objective.label}</Heading>
          <Text size="p1">{objective.description}</Text>
        </Flex>
        <Image ml="auto" src="/assets/charter/objective-background.png" />
      </Flex>
    </VStack>
  );
};
