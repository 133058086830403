import { useHasFamilyFinishedValuesConfigurator } from 'hooks/onboarding/useHasFamilyFinishedValuesConfigurator';

import { ValuesIntroLayout } from '../../components/Layout';
import {
  ThankYouSection,
  ThankYouWithSdgLink,
} from '../../components/ValuesConfigurator';
import { useGetOnboardContent } from '../../hooks';

export const ThankYouPage = () => {
  const { hasFamilyFinished } = useHasFamilyFinishedValuesConfigurator();

  const {
    onboard: { completedValuesTitle, completedValuesDescription },
  } = useGetOnboardContent();

  return (
    <ValuesIntroLayout height="full">
      {hasFamilyFinished ? (
        <ThankYouWithSdgLink
          title={completedValuesTitle}
          description={completedValuesDescription}
        />
      ) : (
        <ThankYouSection />
      )}
    </ValuesIntroLayout>
  );
};
