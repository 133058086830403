import { useTranslation } from 'react-i18next';

import { Flex } from '@chakra-ui/react';

import { Avatar } from 'components/Avatar';
import { ViewButton } from 'components/Button/ViewButton';
import { Panel } from 'components/Layout/Panel';
import { SectionWithoutBorderedTile } from 'components/Layout/Sections/SectionWithoutBorderedTile';
import { QueryStateGuard } from 'components/QueryStateGuard';
import { useAuthContext } from 'contexts/AuthContext';
import { MAX_CONNECTIONS_PREVIEW } from 'features/Search/constants';
import { useAdvisorConnections } from 'gql/connections/query/advisorConnections';
import { useMutualConnections } from 'gql/connections/query/mutualConnections';
import { Links } from 'router/links';
import { Role } from 'types';

export const ConnectionsSection = ({ profileId }: { profileId: string }) => {
  const { t } = useTranslation(['common']);
  const { account } = useAuthContext();
  const { advisorConnections, loading, error } = useAdvisorConnections();
  const { mutualConnections } = useMutualConnections(profileId);

  const isOwnProfile = account && account?.id === profileId;
  const advisors = isOwnProfile
    ? advisorConnections
    : mutualConnections.filter(
        (connection) => connection.role === Role.ExpertAdvisor
      );

  return (
    <QueryStateGuard loading={loading} error={error}>
      <SectionWithoutBorderedTile>
        <Panel
          title={`${t(`common:${isOwnProfile ? 'connected' : 'mutual'}`)} ${t(
            'common:advisors'
          )} (${advisors.length})`}
          action={
            isOwnProfile && (
              <ViewButton
                variant="blue"
                to={isOwnProfile ? Links.connections : ''}
              >
                {t('common:search.view-all', {
                  type: t('common:connections').toLowerCase(),
                })}
              </ViewButton>
            )
          }
        >
          <Flex
            gap={6}
            px={6}
            justifyContent="flex-start"
            w="full"
            flexWrap="wrap"
          >
            {advisors
              .slice(
                0,
                !isOwnProfile ? advisors.length : MAX_CONNECTIONS_PREVIEW
              )
              .map((result) => (
                <Avatar key={result.id} profile={result} />
              ))}
          </Flex>
        </Panel>
      </SectionWithoutBorderedTile>
    </QueryStateGuard>
  );
};
