import { gql, useMutation } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import {
  Mutation,
  MutationUpdateFamilyOnboardManagementMeetingInvitationArgs,
} from 'types';
import { OnboardManagementMeetingInvitationOutputFragment } from 'types/generated-fragments';
import { TimezoneHandler } from 'utils/date';

import { FormType } from './types';

export const updateOnboardManagementMeetingInvitationGql = gql`
  ${OnboardManagementMeetingInvitationOutputFragment}
  mutation UpdateFamilyOnboardManagementMeetingInvitation(
    $data: OnboardManagementUpdateInvitationInput!
  ) {
    updateFamilyOnboardManagementMeetingInvitation(data: $data) {
      id
      meetingInvitation {
        ...OnboardManagementMeetingInvitationOutputFragment
      }
    }
  }
`;

export const useHandleSubmit = (familyId: string) => {
  const [updateMeetingInvitationMutation] = useMutation<
    Pick<Mutation, 'updateFamilyOnboardManagementMeetingInvitation'>,
    MutationUpdateFamilyOnboardManagementMeetingInvitationArgs
  >(updateOnboardManagementMeetingInvitationGql);

  const handleSubmit = (values: FormType) => {
    const { timezone, date, time, ...rest } = values;

    const inputDate = TimezoneHandler.clientToApi({ time, date, timezone });

    return updateMeetingInvitationMutation({
      variables: {
        data: {
          familyId,
          meetingInvitation: {
            ...rest,
            date: inputDate,
          },
        },
      },
    });
  };

  return useWithMutationToast(handleSubmit);
};
