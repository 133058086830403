import { gql, useMutation } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationUpdateEventTagsArgs, TagsInput } from 'types';

export const updateEventTagsGql = gql`
  mutation UpdateEventTags($event: EventTagsInput!) {
    updateEventTags(event: $event) {
      id
    }
  }
`;

export const useUpdateEventTags = () => {
  const [updateEventTagsMutation] = useMutation<
    Pick<Mutation, 'updateEventTags'>,
    MutationUpdateEventTagsArgs
  >(updateEventTagsGql, {
    refetchQueries: ['Event'],
  });

  const updateEventTags = useWithMutationToast(
    (values: TagsInput) =>
      updateEventTagsMutation({
        variables: {
          event: values,
        },
      }),
    {
      successMessage: 'Updating tags succeeded',
      errorMessage: 'Updating tags failed',
    }
  );

  return { updateEventTags };
};
