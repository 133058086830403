import { gql, useMutation } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationRestoreResourceInLibraryArgs } from 'types';

import {
  getResourcesFromDirectoryInLibraryGql,
  getTrashesFromLibraryGql,
} from '../documents';

const restoreResourceInLibraryGql = gql`
  mutation RestoreResourceInLibrary(
    $libraryType: LibraryTypeEnum!
    $resourceId: String!
  ) {
    restoreResourceInLibrary(libraryType: $libraryType, resourceId: $resourceId)
  }
`;

export const useRestoreResourceInLibrary = () => {
  const [mutation] = useMutation<
    Pick<Mutation, 'restoreResourceInLibrary'>,
    MutationRestoreResourceInLibraryArgs
  >(restoreResourceInLibraryGql, {
    fetchPolicy: 'no-cache',
    refetchQueries: [
      getResourcesFromDirectoryInLibraryGql,
      getTrashesFromLibraryGql,
    ],
  });

  const handleMutation = (variables: MutationRestoreResourceInLibraryArgs) =>
    mutation({ variables });

  const restoreResourceInLibrary = useWithMutationToast(handleMutation, {
    disableSuccessMessage: true,
  });

  return { restoreResourceInLibrary };
};
