import { useMutation } from '@apollo/client';

import { getResourcesFromDirectoryInLibraryGql } from 'gql/library/documents';
import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationCommitFileInLibraryArgs } from 'types';

import { commitFileInLibraryGql } from '../documents';

export const useCommitFileInLibrary = () => {
  const [mutation] = useMutation<
    Pick<Mutation, 'commitFileInLibrary'>,
    MutationCommitFileInLibraryArgs
  >(commitFileInLibraryGql, {
    fetchPolicy: 'no-cache',
    refetchQueries: [getResourcesFromDirectoryInLibraryGql],
  });

  const handleMutation = (variables: MutationCommitFileInLibraryArgs) =>
    mutation({ variables });

  const commitFileInLibrary = useWithMutationToast(handleMutation, {
    disableSuccessMessage: true,
  });

  return { commitFileInLibrary };
};
