import { gql, useQuery } from '@apollo/client';

import { InsightDeepNestingFragment } from 'gql/fragments';
import { Query } from 'types';

export const insightGql = gql`
  ${InsightDeepNestingFragment}
  query Insight($insightId: String!) {
    insight(id: $insightId) {
      ...InsightDeepNestingFragment
    }
  }
`;

export const useInsight = (insightId: string) => {
  const { data, loading, error, refetch } = useQuery<Pick<Query, 'insight'>>(
    insightGql,
    {
      variables: { insightId },
      fetchPolicy: 'no-cache',
    }
  );

  const insight = data?.insight;

  return { insight, loading, error, refetch };
};
