import { string, object } from 'yup';

const emailValidation = string()
  .email('Invalid email address')
  .required('Please provide email address.');

export const signInSchema = object().shape({
  email: emailValidation,
  password: string().required('Please provide password.'),
});

export const forgotPasswordSchema = object().shape({
  email: emailValidation,
});
