import { ReactNode } from 'react';

import { Accordion, Divider, Flex, Heading } from '@chakra-ui/react';

import { QueryStateGuard } from 'components/QueryStateGuard';
import { maxSize } from 'utils/size';

import { CmsLeftMenu } from './CmsLeftMenu';

type Props = {
  title: string;
  children: ReactNode;
  footer?: ReactNode;
  header?: ReactNode;
  loading?: boolean;
  error?: Error;
  disableLeftMenu?: boolean;
};

export const CmsLayout = ({
  title,
  children,
  footer,
  header,
  loading,
  error,
  disableLeftMenu,
}: Props): JSX.Element => {
  return (
    <Flex>
      {!disableLeftMenu && <CmsLeftMenu />}
      <Flex
        direction="column"
        flex={1}
        mt={5}
        pb={32}
        maxW={maxSize(1200)}
        minH="30vh"
      >
        <Heading
          size="h2"
          as="h1"
          pb={4}
          mb={8}
          borderBottom="1px solid"
          borderColor="primary.blue.default"
        >
          {title}
        </Heading>
        {header}
        <QueryStateGuard loading={loading} error={error}>
          <Accordion defaultIndex={[0]} allowMultiple mb={24}>
            {children}
          </Accordion>
          <Divider mb={4} borderColor="primary.blue.default" />
          {footer}
        </QueryStateGuard>
      </Flex>
    </Flex>
  );
};
