import { Link } from 'react-router-dom';

import { HeroBody } from 'components/HeroBody';
import { HeroMedia } from 'components/HeroMedia';
import { Links } from 'router/links';
import { InitiativeOutput } from 'types';
import { generateUrlById } from 'utils/links';

import { InitiativeCTA } from './InitiativeCTA';

type Props = {
  initiative: InitiativeOutput;
  isRedirectBlock?: boolean;
};

export const InitiativeHero = ({ initiative, isRedirectBlock }: Props) => {
  const { hero, id, tags, isFollowed } = initiative;

  return (
    <HeroMedia
      imageUrl={hero?.heroImage?.signedUrl}
      {...(isRedirectBlock && {
        as: Link,
        to: generateUrlById(Links.initiativesSingle, id),
      })}
    >
      <HeroBody title={hero.headline} subtitle={hero.summary} tags={tags}>
        <InitiativeCTA initiativeId={id} isFollowed={isFollowed} />
      </HeroBody>
    </HeroMedia>
  );
};
