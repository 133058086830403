import {
  TransitionFrom,
  useTransition,
  UseTransitionProps,
} from 'react-spring';

import { useWheelState } from '../../contexts';
import { StateValueCategory } from '../../types';

export const useCategoryTransition = (
  animationStates: Record<'on' | 'off', TransitionFrom<StateValueCategory>>,
  additionalProps?: UseTransitionProps<StateValueCategory>
) => {
  const { selectedCategory, selectedValue } = useWheelState();

  const animation = useTransition(selectedCategory, {
    from: animationStates.off,
    enter: animationStates.on,
    leave: animationStates.off,
    ...additionalProps,
    cancel: !selectedValue,
  });

  return animation;
};
