import { MediaOutput } from 'types';

export const generateMedia = ({
  __typename = 'MediaOutput',
  id = '6268dc1714e6fd1f1c506ffe',
  signedUrl = '/assets/OtpBgImage.jpg',
  createdAt = 1651039268692,
  updatedAt = 1651061407574,
}: Partial<MediaOutput> = {}): MediaOutput => ({
  __typename,
  id,
  signedUrl,
  createdAt,
  updatedAt,
});

export const generateMockedImage = (id: string, url?: string): MediaOutput => ({
  id,
  createdAt: 1652704397555,
  updatedAt: 1652704397555,
  signedUrl: url || 'https://itriom.com/mock-image.png',
  __typename: 'MediaOutput',
});

export const generateMockedImages = (count: number) =>
  new Array(count).fill(0).map((_, i) => generateMedia({ id: String(i) }));
