import { gql, useQuery } from '@apollo/client';

import { GroupInternalFragment } from 'gql/fragments';
import { GroupInternalType } from 'types';

export const trendingGroupsGql = gql`
  ${GroupInternalFragment}
  query TrendingGroups {
    trendingGroups {
      ...GroupInternalFragment
    }
  }
`;

export const useTrendingGroups = () => {
  const { data, loading, error } = useQuery<{
    trendingGroups: GroupInternalType[];
  }>(trendingGroupsGql, {
    fetchPolicy: 'cache-and-network',
  });

  const trendingGroups = data?.trendingGroups ?? [];

  return { trendingGroups, loading, error };
};
