import { useSwiper } from 'swiper/react';

import { ArrowLeft, ArrowRight } from '@carbon/icons-react/next';
import { Button, ButtonProps } from '@chakra-ui/react';

type Props = {
  type: 'prev' | 'next';
  buttonStyling?: ButtonProps;
};

export const CAROUSEL_NAV_BUTTON_SIZE = 48;
export const PAGINATION_GAP_SIZE = 24;

export const SwiperButton = ({ type, buttonStyling }: Props) => {
  const swiper = useSwiper();
  const isPrev = type === 'prev';

  const onClick = () => {
    if (isPrev) {
      swiper.slidePrev();
    } else {
      swiper.slideNext();
    }
  };

  return (
    <Button
      onClick={onClick}
      position="absolute"
      top={`calc(50% - ${PAGINATION_GAP_SIZE}px)`}
      transform="translateY(-50%)"
      w={12}
      h={12}
      alignItems="center"
      justifyContent="center"
      display="flex"
      color="primary.blue.default"
      bgColor="primary.lime.default"
      zIndex={10}
      p={0}
      border="none"
      sx={isPrev ? { left: 0 } : { right: 0 }}
      {...buttonStyling}
    >
      {isPrev ? <ArrowLeft size={24} /> : <ArrowRight size={24} />}
    </Button>
  );
};
