import MdPreview from '@uiw/react-markdown-preview';

import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { Flex, Image, Collapse, IconButton, Heading } from '@chakra-ui/react';

import { markdownPreviewStyles } from 'components/MarkdownPreview';

import { Value } from './types';

type Props = Value & {
  isActive: boolean;
  handleClick: () => void;
};

export const ValueBlock = ({
  title,
  iconUrl,
  description,
  color,
  handleClick,
  isActive,
}: Props) => (
  <Flex
    key={title}
    my={1}
    w="full"
    borderWidth={1}
    borderColor={color}
    color={color}
    py={4}
    px={2}
    onClick={handleClick}
    cursor="pointer"
    userSelect="none"
  >
    <Image src={iconUrl} h="fit-content" />
    <Flex flex={1} ml={2} alignItems="flex-start" direction="column">
      <Heading size="h4">{title}</Heading>
      <Collapse in={isActive} animateOpacity unmountOnExit>
        <MdPreview
          source={description}
          style={{ ...markdownPreviewStyles, color }}
        />
      </Collapse>
    </Flex>
    <IconButton
      aria-label="open tab"
      isRound
      variant="unstyled"
      icon={isActive ? <MinusIcon /> : <AddIcon />}
    />
  </Flex>
);
