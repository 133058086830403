import { gql, useMutation } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationCreateDirectoryInLibraryArgs } from 'types';

import { getResourcesFromDirectoryInLibraryGql } from '../documents';

const createDirectoryInLibraryGql = gql`
  mutation CreateDirectoryInLibrary(
    $libraryType: LibraryTypeEnum!
    $directoryName: String!
    $destinationId: String
  ) {
    createDirectoryInLibrary(
      libraryType: $libraryType
      directoryName: $directoryName
      destinationId: $destinationId
    ) {
      id
    }
  }
`;

export const useCreateDirectoryInLibrary = () => {
  const [mutation] = useMutation<
    Pick<Mutation, 'createDirectoryInLibrary'>,
    MutationCreateDirectoryInLibraryArgs
  >(createDirectoryInLibraryGql, {
    fetchPolicy: 'no-cache',
    refetchQueries: [getResourcesFromDirectoryInLibraryGql],
  });

  const handleMutation = (variables: MutationCreateDirectoryInLibraryArgs) =>
    mutation({ variables });

  const createDirectoryInLibrary = useWithMutationToast(handleMutation, {
    disableSuccessMessage: true,
  });

  return { createDirectoryInLibrary };
};
