import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Panel } from 'components/Layout/Panel';
import { GroupSubtype, Post } from 'types';

import { PostCardHeader } from './PostCardHeader';

type Props = {
  post: Post;
};

export const SystemMessagePostCard = ({ post }: Props) => {
  const { t } = useTranslation(['network']);

  const { createdAt, postedBy, subtype } = post;

  const systemMessage = useMemo(() => {
    const GROUP_INFO: Record<string, string> = {
      [GroupSubtype.Join]: t('network:group-message.join'),
      [GroupSubtype.Leave]: t('network:group-message.leave'),
    };

    if (!subtype) return undefined;

    return GROUP_INFO[subtype];
  }, [subtype, t]);

  return (
    <Panel
      contentWrapperStyling={{ direction: 'column', position: 'relative' }}
      ariaLabel="post"
    >
      <PostCardHeader
        createdAt={createdAt}
        postedBy={postedBy}
        systemMessage={systemMessage}
      />
    </Panel>
  );
};
