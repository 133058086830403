import { useTranslation } from 'react-i18next';

import { Flex, Heading, Grid } from '@chakra-ui/react';

import { Avatar } from 'components/Avatar';
import { Organiser } from 'types';

import { EventBodyElement } from '../../EventBodyElement';

interface Props {
  organisers: Organiser[];
}

export const OrganisersSection = ({ organisers }: Props) => {
  const { t } = useTranslation(['events']);

  if (!organisers || !organisers.length) return null;

  return (
    <EventBodyElement>
      <Grid gridTemplateColumns="repeat(12, 1fr)" gap={4}>
        <Flex gridColumn="1 / span 3" alignItems="center">
          <Heading size="h4">{t('events:organizers')}</Heading>
        </Flex>
        <Flex flexWrap="wrap" gridColumn="4 / span 9" columnGap={4} rowGap={8}>
          {organisers.map((organiser) => (
            <Avatar
              variant="vertical"
              profile={organiser.profile}
              key={organiser.profile.id}
            />
          ))}
        </Flex>
      </Grid>
    </EventBodyElement>
  );
};
