import { AccountRecommendationEnum } from 'types';

import { useRecommendedConnections } from './useRecommendedConnections';

export const useAllRecommendedConnections = (limit = 5) => {
  const matchingAtLeastOneSdg = useRecommendedConnections(
    AccountRecommendationEnum.MatchingAtLeastOneSdg,
    limit
  );
  const notMatchingAnySdg = useRecommendedConnections(
    AccountRecommendationEnum.NotMatchingAnySdg,
    limit
  );

  const recommendedConnections = [
    ...matchingAtLeastOneSdg.recommendedConnections,
    ...notMatchingAnySdg.recommendedConnections,
  ].slice(0, limit);

  const loading = matchingAtLeastOneSdg.loading || notMatchingAnySdg.loading;

  const error = matchingAtLeastOneSdg.error || notMatchingAnySdg.error;

  return { recommendedConnections, loading, error };
};
