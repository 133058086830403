import { Flex, FlexProps, Heading } from '@chakra-ui/react';

import { WithChildren } from 'types';

type Props = {
  title?: string;
  containerStyling?: FlexProps;
};

export const CmsSection = ({
  children,
  containerStyling,
  title,
}: WithChildren<Props>) => (
  <Flex direction="column" as="section" gap={4} mt={12} {...containerStyling}>
    {title && <Heading size="h3">{title}</Heading>}
    {children}
  </Flex>
);
