import { gql, useQuery } from '@apollo/client';

import { InitiativeBlocksFragment } from 'gql/fragments';
import { Query, QueryListInitiativesArgs } from 'types';
import {
  InitiativeHeroOutputFragment,
  ItriomTagsV2OutputFragment,
  InitiativeVisibilityOutputFragment,
} from 'types/generated-fragments';

export const listInitiativesGql = gql`
  ${ItriomTagsV2OutputFragment}
  ${InitiativeHeroOutputFragment}
  ${InitiativeBlocksFragment}
  ${InitiativeVisibilityOutputFragment}
  query ListInitiatives(
    $pagination: PaginationInput = { offset: 0, limit: 100 }
    $filters: ListInitiativesFiltersInput
  ) {
    listInitiatives(pagination: $pagination, filters: $filters) {
      nodes {
        ... {
          id
          createdAt
          updatedAt
          hero {
            ...InitiativeHeroOutputFragment
          }
          status
          tags {
            ...ItriomTagsV2OutputFragment
          }
          visibilityDetails {
            ...InitiativeVisibilityOutputFragment
          }
          createdBy
          updatedBy
          appreciationCount
          isFollowed
          blocks {
            ...InitiativeBlocksFragment
          }
        }
      }
      offset
      totalCount
    }
  }
`;

export const useInitiatives = (variables?: QueryListInitiativesArgs) => {
  const { data, loading, error, refetch } = useQuery<
    Pick<Query, 'listInitiatives'>,
    QueryListInitiativesArgs
  >(listInitiativesGql, {
    fetchPolicy: 'network-only',
    variables,
  });

  const initiatives = data?.listInitiatives?.nodes || [];

  return { initiatives, loading, error, refetch };
};
