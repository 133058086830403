import { useEffect } from 'react';

import { gql, useMutation } from '@apollo/client';

import { RightBlock } from 'components/Layout/RightBlock';
import { Mutation, MutationSupportPhoneVerificationArgs } from 'types';

import { Cta } from '../../components/Cta';

export const supportPhoneVerificationGql = gql`
  mutation SupportPhoneVerification($token: String!) {
    supportPhoneVerification(token: $token)
  }
`;

type Props = {
  token: string;
};

export const SupportBlock = ({ token }: Props) => {
  const [mutate, { loading, error }] = useMutation<
    Pick<Mutation, 'supportPhoneVerification'>,
    MutationSupportPhoneVerificationArgs
  >(supportPhoneVerificationGql);

  useEffect(() => {
    mutate({ variables: { token } });
  }, [mutate, token]);

  if (loading || error) {
    return <RightBlock loading={loading} error={error?.message} />;
  }

  return (
    <Cta
      title="Your RSVP has been received."
      subtitle="We will contact you to confirm your mobile number."
    />
  );
};
