import { Button } from '@chakra-ui/react';

type Props = {
  handleClick: () => void;
  text: string;
};

export const ProfileConnectionButton = ({ handleClick, text }: Props) => (
  <Button
    bgColor="primary.lime.default"
    color="primary.blue.default"
    onClick={handleClick}
  >
    {text}
  </Button>
);
