import { Divider, VStack } from '@chakra-ui/react';

import { SingleGroupCard } from 'components/SingleGroupCard';
import { GroupInternalType } from 'types';

type Props = {
  groups?: GroupInternalType[];
};

export const GroupList = ({ groups }: Props) => {
  if (!groups || groups.length === 0) return null;

  return (
    <VStack
      align="flex-start"
      py={2}
      divider={<Divider />}
      backgroundColor="ui.white"
    >
      {groups.map((group) => (
        <SingleGroupCard
          key={group.id}
          withEsgCategory
          group={group}
          withBorder={false}
        />
      ))}
    </VStack>
  );
};
