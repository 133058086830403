import { gql, useMutation } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationUnshareResourceInLibraryArgs } from 'types';

import {
  getLibraryMediaFavorites,
  getResourcesFromDirectoryInLibraryGql,
  getSharedResourcesFromLibrary,
} from '../documents';

const unshareResourceInLibraryGql = gql`
  mutation UnshareResourceInLibrary(
    $libraryType: LibraryTypeEnum!
    $resourceId: String!
    $consumerId: String!
  ) {
    unshareResourceInLibrary(
      libraryType: $libraryType
      resourceId: $resourceId
      consumerId: $consumerId
    )
  }
`;

export const useUnshareResourceInLibrary = () => {
  const [mutation] = useMutation<
    Pick<Mutation, 'unshareResourceInLibrary'>,
    MutationUnshareResourceInLibraryArgs
  >(unshareResourceInLibraryGql, {
    fetchPolicy: 'no-cache',
    refetchQueries: [
      getResourcesFromDirectoryInLibraryGql,
      getLibraryMediaFavorites,
      getSharedResourcesFromLibrary,
    ],
  });

  const handleMutation = (variables: MutationUnshareResourceInLibraryArgs) =>
    mutation({ variables });

  const unshareResourceInLibrary = useWithMutationToast(handleMutation, {
    disableSuccessMessage: true,
  });

  return { unshareResourceInLibrary };
};
