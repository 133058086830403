import { TFunction } from 'i18next';

import {
  libraryTypeBaseLinkMap,
  libraryTypeFolderLinkMap,
} from 'features/Library/utils/libraryTypeLinkMap';
import { libraryTypeToLabelMap } from 'features/Library/utils/libraryTypeToLabelMap';
import { LibraryMediaOutput, LibrarySharedMediaOutput } from 'types';
import { generateUrlById } from 'utils/links';

export const getItemPaths = (
  item: LibraryMediaOutput | LibrarySharedMediaOutput,
  t: TFunction
) => {
  const itemPaths: { label: string; url: string }[] = [];
  const { resolvedPath } = item;

  itemPaths.push({
    url: libraryTypeBaseLinkMap[item.libraryType],
    label: t(libraryTypeToLabelMap[item.libraryType]),
  });

  resolvedPath.forEach(({ name, id, libraryType }) =>
    itemPaths.push({
      url: generateUrlById(libraryTypeFolderLinkMap[libraryType], id),
      label: name,
    })
  );

  return itemPaths;
};
