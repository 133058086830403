import { useTranslation } from 'react-i18next';

import { Forum32 } from '@carbon/icons-react';
import { HStack, Box, Button, Icon, Text } from '@chakra-ui/react';

import { Avatar } from 'components/Avatar';
import { ProfileActionMenu } from 'components/ProfileActionMenu';
import { useAcceptConnectionInvitation } from 'gql/connections/mutation/acceptConnectionInvitation';
import { useInviteToConnections } from 'gql/connections/mutation/inviteToConnections';
import { useInvitationForMe } from 'hooks/connections/useInvitationForMe';
import { useRedirectToConversationWithUser } from 'hooks/useRedirectToConversationWithUser';
import { AccountStatus, Profile, ProfileConnectionStateEnum } from 'types';
import { isItriomStaff } from 'utils/roles';

import { ConnectionTab } from '../ConnectionTabs/types';

interface Props {
  connection: Profile;
  type: ConnectionTab;
}

export const ConnectionListItem = ({ connection, type }: Props) => {
  const { id, family, role, connectionState, status } = connection;
  const { t } = useTranslation(['connections']);

  const isActive = status === AccountStatus.Active;
  const invitationForMe = useInvitationForMe(id);

  const shouldDisplayMenu = !isItriomStaff(role);

  const handleRedirect = useRedirectToConversationWithUser(connection);
  const { inviteToConnections } = useInviteToConnections();
  const { acceptConnectionInvitation } = useAcceptConnectionInvitation();

  const handleConnect = () => {
    if (invitationForMe) {
      acceptConnectionInvitation(invitationForMe.id);
    } else if (
      !connectionState ||
      connectionState === ProfileConnectionStateEnum.NotConnected
    ) {
      inviteToConnections(id);
    }
  };

  return (
    <Box
      w="100%"
      px={4}
      backgroundColor="ui.white"
      _hover={{ backgroundColor: 'ui.grey4' }}
    >
      <HStack
        justify="space-between"
        w="100%"
        py={4}
        borderBottom="1px solid"
        borderColor="ui.grey3"
      >
        <Avatar
          subheadingInfo={family?.name}
          variant="author"
          profile={connection}
          key={connection.id}
        />

        {type === ConnectionTab.Recommended ? (
          <Button
            variant="primaryLime"
            height="fit-content"
            w="fit-content"
            px={2}
            py={1}
            onClick={handleConnect}
          >
            <Text size="p3">
              {t(
                invitationForMe
                  ? 'connections:accept-connection'
                  : 'connections:connect'
              )}
            </Text>
          </Button>
        ) : (
          <Box>
            {isActive && (
              <Button
                onClick={handleRedirect}
                variant="ghost"
                leftIcon={
                  <Icon
                    as={Forum32}
                    w={5}
                    h={5}
                    m={4}
                    data-cy="forum-header-icon"
                  />
                }
              >
                Message
              </Button>
            )}

            {shouldDisplayMenu && (
              <ProfileActionMenu id={id} connectionState={connectionState} />
            )}
          </Box>
        )}
      </HStack>
    </Box>
  );
};
