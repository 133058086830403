import { Link } from 'react-router-dom';

import { Flex, FlexProps } from '@chakra-ui/layout';
import { Box } from '@chakra-ui/react';

import { underlay } from 'theme/mixins/underlay';

import { TextContent } from '../TextContent';
import { CommonTileProps } from '../types';

export type Props = CommonTileProps &
  Pick<FlexProps, 'justify' | 'align' | 'maxW'> & {
    type?: 'tile' | 'banner';
  };

export const BackgroundImageTile = ({
  tags,
  title,
  description,
  image,
  link,
  align = 'flex-start',
  justify = 'flex-start',
  maxW = '60%',
  type = 'tile',
}: Props) => {
  return (
    <Flex
      as={Link}
      to={link}
      w="100%"
      h="100%"
      bgSize="cover"
      bgImage={image}
      bgPos="center"
      position="relative"
      justify={justify}
      align={align}
      {...underlay()}
    >
      <Box p={4} zIndex="1" maxW={maxW}>
        <TextContent
          type={type}
          variant="light"
          description={description}
          title={title}
          tags={tags}
        />
      </Box>
    </Flex>
  );
};
