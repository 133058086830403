import { ContentSection } from 'components/CmsLayout';
import {
  SdgInfluence,
  SdgInfluencesContent,
} from 'components/SdgInfluencesContent';

type Props = {
  sdgs: SdgInfluence[];
};

export const SdgInfluences = ({ sdgs }: Props) => {
  return (
    <ContentSection title="SDG influences">
      <SdgInfluencesContent sdgs={sdgs} />
    </ContentSection>
  );
};
