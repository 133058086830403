import { Fragment } from 'react';
import { Route, Routes } from 'react-router-dom';

import { CmsNavigationContextProvider } from 'contexts/CmsNavigationContext';
import { CMS_PAGES } from 'features/Cms/routes';

import { getCommonRoutes } from './routes/common';
import { getOnboardingRoutes } from './routes/onboarding';
import { createPagesFromConfig } from './utils/createPagesFromConfig';

export const Router = (): JSX.Element => {
  return (
    <CmsNavigationContextProvider pagesConfig={CMS_PAGES}>
      <Routes>
        {getCommonRoutes()}
        {getOnboardingRoutes()}
        <Route>
          {CMS_PAGES.map((pageConfig) => (
            <Fragment key={pageConfig.link}>
              {createPagesFromConfig(pageConfig)}
            </Fragment>
          ))}
        </Route>
      </Routes>
    </CmsNavigationContextProvider>
  );
};
