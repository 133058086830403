import { TickFormatter } from './types/types';

export const DEFAULT_COLOR = 'curentColor';

export const DEFAULT_MARGIN = {
  top: 20,
  right: 0,
  bottom: 40,
  left: 40,
} as const;

export const DEFAULT_PADDING = {
  top: 30,
  right: 0,
  left: 0,
  bottom: 0,
};

export const DEFAULT_WIDTH = 640;
export const DEFAULT_HEIGHT = 400;

export const DEFAULT_TICK_DISTANCE = 40;

export const percentFormatter: TickFormatter = (v) => `${v}%`;
