import { Flex, Tooltip } from '@chakra-ui/react';

import { WithChildren } from 'types';

type Props = {
  tooltip: string;
};

export const FamilyRoleIconWrapper = ({
  tooltip,
  children,
}: WithChildren<Props>) => (
  <Tooltip label={tooltip}>
    <Flex
      aria-label={tooltip}
      position="absolute"
      top={2}
      right={2}
      zIndex={2}
      background="primary.blue.default"
      color="primary.lime.default"
      borderRadius="50%"
      w={6}
      h={6}
      justify="center"
      align="center"
    >
      {children}
    </Flex>
  </Tooltip>
);
