import { AddIcon } from '@chakra-ui/icons';
import { Text } from '@chakra-ui/layout';
import { Avatar, Box, Button, HStack, useDisclosure } from '@chakra-ui/react';

import { ConfirmationModal } from 'components/Modal';
import { useInviteToGroup } from 'hooks/groups';
import { Group, MemberToAddOutput } from 'types';
import { getAvatarSrc } from 'utils/file';
import { getFullName } from 'utils/string';

type Props = {
  profile: MemberToAddOutput;
  groupId: Group['id'];
};

export const AddMemberRow = ({ profile, groupId }: Props): JSX.Element => {
  const confirmationModal = useDisclosure();

  const fullName = getFullName(profile.firstName, profile.lastName);

  const { inviteToGroup, loading } = useInviteToGroup(fullName);

  const handleAddToGroup = () =>
    inviteToGroup({ groupId, accountId: profile.id });

  return (
    <Box>
      <HStack justify="flex-start" mb={2}>
        <Avatar src={getAvatarSrc(profile?.avatarImage)} size="xs" />
        <HStack flex={1}>
          <Text size="p3">{fullName}</Text>
          <Text size="p3" color="ui.grey2">
            {profile.family?.name}
          </Text>
          <Text size="p2" color="ui.grey2">
            {profile.description}
          </Text>
        </HStack>
        <ConfirmationModal
          handleClick={handleAddToGroup}
          isOpen={confirmationModal.isOpen}
          onClose={confirmationModal.onClose}
          title="Add to group?"
          actionText="Add"
          question={`Are you sure you want to invite ${fullName}?`}
        />
        {profile.isInvited ? (
          <Text color="ui.grey2" size="p3">
            Already invited
          </Text>
        ) : (
          <Button
            isLoading={loading}
            loadingText="Adding"
            size="xs"
            variant="outline"
            rightIcon={<AddIcon />}
            onClick={confirmationModal.onOpen}
          >
            Add
          </Button>
        )}
      </HStack>
    </Box>
  );
};
