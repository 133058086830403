import { SdgInternalName } from 'types/Sdg';

export type SDGIconVariant = 'default' | 'transparent' | 'outline' | 'bgImage';

type VariantData = {
  folder: string;
  extension: string;
};

const variants: Record<SDGIconVariant, VariantData> = {
  default: { folder: 'default', extension: 'png' },
  transparent: { folder: 'transparent', extension: 'png' },
  outline: { folder: 'outline', extension: 'svg' },
  bgImage: { folder: 'image', extension: 'png' },
};

const SDG_INTERNAL_NAMES = Object.values(SdgInternalName);

const isInternalName = (
  internalName: string
): internalName is SdgInternalName => {
  return SDG_INTERNAL_NAMES.includes(internalName as SdgInternalName);
};

export const getSdgSrc = (
  internalName: SdgInternalName | string,
  type: SDGIconVariant = 'default'
) => {
  if (!isInternalName(internalName)) {
    throw new Error('Provided sdg internal name does is not valid');
  }

  const { folder, extension } = variants[type];

  return `/assets/SDG/${folder}/${internalName}.${extension}`;
};
