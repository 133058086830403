import { gql, useQuery } from '@apollo/client';

import { Query } from 'types';
import {
  InitiativeHeroOutputFragment,
  InitiativeOutputFragment,
  ItriomTagsV2OutputFragment,
} from 'types/generated-fragments';

const bannerInitiativeGql = gql`
  ${InitiativeOutputFragment}
  ${InitiativeHeroOutputFragment}
  ${ItriomTagsV2OutputFragment}
  query BannerEvents {
    bannerInitiative {
      ...InitiativeOutputFragment
      hero {
        ...InitiativeHeroOutputFragment
      }
      tags {
        ...ItriomTagsV2OutputFragment
      }
    }
  }
`;

export const useBannerInitiative = () => {
  const { data, loading, error } =
    useQuery<Pick<Query, 'bannerInitiative'>>(bannerInitiativeGql);

  return { bannerInitiative: data?.bannerInitiative, loading, error };
};
