import { useFormikContext } from 'formik';

import { Button, Flex } from '@chakra-ui/react';

import { ItriomButtonOutline } from 'components/Button';
import { Modal } from 'components/Modal';
import { configuratorStatus } from 'utils/status';

import { ConfigFormValues } from './types';

const CONFIG_STATUS_NAME: Record<ConfigFormValues['statusType'], string> = {
  sdgConfigStatus: 'SDG Config Status',
  valueConfigStatus: 'Value Config Status',
};

export const ChangeStatusModal = () => {
  const { setFieldValue, handleSubmit, values, isSubmitting } =
    useFormikContext<ConfigFormValues>();

  const { statusType, newStatus } = values;

  const onClose = () => setFieldValue('newStatus', '');

  const name = CONFIG_STATUS_NAME[statusType];

  return (
    <Modal
      isOpen={!!newStatus}
      onClose={onClose}
      title={`Changing the ${name}`}
      containerStyling={{ p: 4 }}
      footer={
        <Flex w="full" justify="space-between">
          <ItriomButtonOutline onClick={onClose}>Cancel</ItriomButtonOutline>
          <Button
            onClick={() => handleSubmit()}
            size="lg"
            color="primary.blue.default"
            bgColor="primary.lime.default"
            border="none"
            isLoading={isSubmitting}
            type="submit"
          >
            {newStatus && configuratorStatus[newStatus]}
          </Button>
        </Flex>
      }
    >
      Please confirm that you would like to mark the customer’s {name} as:
    </Modal>
  );
};
