import { CSSObject } from '@emotion/react';
import { Field, FieldProps, getIn } from 'formik';

import {
  Box,
  FormControl,
  FormErrorMessage,
  Slider,
  SliderFilledTrack,
  SliderProps,
  SliderThumb,
  SliderTrack,
  Image,
} from '@chakra-ui/react';

import { SliderLegend } from './SliderLegend';
import { SliderInputVariant } from './sliderInputVariant';

const defaultStepValues = [0, 20, 40, 60, 80, 100];

export type SliderInputProps = {
  type?: string;
  name: string;
  min?: number;
  max?: number;
  leftPoints?: number;
  stepValues?: number[];
  containerStyles?: CSSObject;
  inputStyles?: CSSObject;
  variant?: SliderInputVariant;
} & SliderProps;

export const SliderInput = ({
  type = 'range',
  name,
  min = 0,
  max = 100,
  leftPoints = max,
  stepValues = defaultStepValues,
  containerStyles = {},
  inputStyles = {},
  variant = SliderInputVariant.Green4,
  ...sliderProps
}: SliderInputProps) => {
  return (
    <Field type={type} name={name}>
      {({ field, form }: FieldProps) => (
        <FormControl
          isInvalid={Boolean(
            getIn(form.errors, name) && getIn(form.touched, name)
          )}
          sx={containerStyles}
        >
          <Box
            position="relative"
            width="100%"
            h={3}
            px={8}
            mt={2}
            borderRadius="md"
            bgGradient={`linear(to-r, ${variant}, white)`}
            boxShadow="inner"
          >
            <Slider
              {...field}
              id={name}
              name={name}
              mx={3}
              min={min}
              onChange={(value) =>
                form.setFieldValue(
                  name,
                  value < leftPoints ? value : leftPoints
                )
              }
              max={max}
              mt="-6px"
              sx={inputStyles}
              {...sliderProps}
            >
              <SliderLegend values={stepValues} />
              <SliderTrack h={3} mx={-3} bgColor="ui.white" boxShadow="inner">
                <SliderFilledTrack bgColor={variant} />
              </SliderTrack>
              <SliderThumb
                boxSize={6}
                as={Box}
                backgroundColor="transparent"
                shadow="none"
                border="none"
                mt={2}
                ml={-2}
                _focus={{ border: 'none' }}
              >
                <Image src="/assets/MarkIcon.svg" alt="Mark slide icon" />
              </SliderThumb>
            </Slider>
          </Box>
          <FormErrorMessage>{getIn(form.errors, name)}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};
