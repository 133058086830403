import { useTranslation } from 'react-i18next';

import { SortAscending, SortDescending } from '@carbon/icons-react/next';
import { Button } from '@chakra-ui/react';

import { useLibraryContext } from 'features/Library/context';

export const Sort = () => {
  const { t } = useTranslation(['cms']);
  const { sortDirection, toggleSortDirection } = useLibraryContext();

  return (
    <Button
      variant="ghost"
      onClick={toggleSortDirection}
      leftIcon={
        sortDirection === 'ASC' ? <SortAscending /> : <SortDescending />
      }
    >
      {t('cms:table.name')}
    </Button>
  );
};
