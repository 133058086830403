import { QueryStateGuard } from 'components/QueryStateGuard';
import { useRecommendedInsights } from 'hooks/insights';
import { InsightsRecommendationEnum } from 'types';

import { OtherInsightGrid } from './OtherInsightGrid';

export const OtherInsightsContent = () => {
  const { insights, loading, error } = useRecommendedInsights({
    matchingType: InsightsRecommendationEnum.NotMatchingAnySdg,
    visibility: { userHasAccessTo: true },
  });

  return (
    <QueryStateGuard loading={loading} error={error}>
      {insights.length > 0 && <OtherInsightGrid insights={insights} />}
    </QueryStateGuard>
  );
};
