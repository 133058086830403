import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Reply } from '@carbon/icons-react/next';
import { Button, Flex, Text } from '@chakra-ui/react';

import { Comment as CommentType } from 'types';

import { NewCommentForm } from '../CommentForm';
import { CommentBase } from './CommentBase';

export const CommentResponses = ({
  id: commentId,
  comments: nestedComments,
}: Pick<CommentType, 'comments' | 'id'>) => {
  const [isInputVisible, setInputVisible] = useState(false);
  const [showResponses, setShowResponses] = useState(false);
  const { t } = useTranslation(['network', 'cms']);

  const toggleInput = () => {
    setInputVisible((prev) => !prev);
  };

  const toggleResponses = () => {
    setShowResponses((prev) => !prev);
  };

  return (
    <Flex direction="column" pb={isInputVisible ? 0 : 3} px={6} gap={4}>
      <Flex direction="row" justifyContent="space-between">
        <Button
          px={0}
          onClick={toggleInput}
          leftIcon={<Reply height={14} />}
          variant="ghost"
          width="max-content"
        >
          <Text size="p3">
            {t('network:respond-to', { entity: t('network:comment') })}
          </Text>
        </Button>
        {!!nestedComments.length && (
          <Button
            px={0}
            variant="ghost"
            width="max-content"
            onClick={toggleResponses}
          >
            <Text size="p3">
              {t(
                showResponses
                  ? 'network:hide-responses'
                  : 'network:show-responses',
                { count: nestedComments.length }
              )}
            </Text>
          </Button>
        )}
      </Flex>
      {isInputVisible && (
        <NewCommentForm
          referenceId={commentId}
          placeholder={t('network:respond-to', {
            entity: t('network:comment'),
          })}
        />
      )}
      {showResponses &&
        nestedComments.map((nestedComment) => (
          <CommentBase comment={nestedComment} key={nestedComment.id} />
        ))}
    </Flex>
  );
};
