import { Flex, Text, Heading } from '@chakra-ui/react';

import { SolutionCard } from 'features/Marketplace/components/SolutionSection/SolutionCard';

import { CONTENT_PADDING_X } from '../../constants';
import { Solutions } from '../../types';

export const SolutionSection = ({
  sectionTitle,
  cards,
  description,
}: Solutions) => {
  return (
    <Flex
      w="full"
      direction="column"
      gap={6}
      px={CONTENT_PADDING_X}
      pb="80px"
      pt={12}
      sx={{
        '& .swiper-pagination-bullet': {
          backgroundColor: '#d9d9d9',
        },
        '& .swiper-pagination-bullet-active': {
          backgroundColor: '#c6d344',
        },
      }}
    >
      <Heading size="h3" textAlign="center" w="full" pb={9}>
        {sectionTitle}
      </Heading>

      {description && (
        <Text size="p1" maxWidth={800} mx="auto">
          {description}
        </Text>
      )}

      <Flex
        direction={{ md: 'row', base: 'column' }}
        w="full"
        justify="center"
        gap={{ md: '36px', base: '20px' }}
      >
        {cards.map((card) => (
          <SolutionCard
            title={card.title}
            description={card.description}
            iconSrc={card.iconSrc}
            key={card.description}
          />
        ))}
      </Flex>
    </Flex>
  );
};
