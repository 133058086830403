import { Properties } from 'csstype';

import { CheckIcon as ChakraCheckIcon } from '@chakra-ui/icons';
import { Center } from '@chakra-ui/react';

type Props = {
  color?: Properties['color'];
  diameter?: string | number;
  fontSize?: Properties['fontSize'];
};

export const CheckIcon = ({
  color = 'white',
  diameter = 70,
  fontSize = '3xl',
}: Props) => (
  <Center
    w={diameter}
    h={diameter}
    borderRadius="50%"
    borderColor={color}
    borderWidth={1}
  >
    <ChakraCheckIcon color={color} fontSize={fontSize} />
  </Center>
);
