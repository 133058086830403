import {
  CATEGORY_RADIUS,
  INNER_RADIUS,
  OUTER_RADIUS,
  RING_WIDTH,
} from '../../constants';
import { Curvature } from '../../types';
import { getArcPath } from '../../utils';

type Props = {
  onClick: () => void;
};

export const CategoryClickOverlay = ({ onClick }: Props) => (
  <path
    style={{ cursor: 'pointer' }}
    onClick={onClick}
    d={`M${RING_WIDTH * 2} ${OUTER_RADIUS} ${getArcPath({
      radius: CATEGORY_RADIUS,
      xDir: 1,
      yDir: -1,
      curveOrientation: Curvature.Outside,
    })} V ${OUTER_RADIUS - INNER_RADIUS} ${getArcPath({
      radius: INNER_RADIUS,
      xDir: -1,
      yDir: 1,
    })}  Z`}
    fill="transparent"
  />
);
