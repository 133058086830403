import { useTranslation } from 'react-i18next';

import { Divider, Flex, Grid, Heading, Text } from '@chakra-ui/react';

import { SdgIcon } from 'components/Icons/SdgIcon';
import { Section } from 'components/Layout/Sections/Section';
import { QueryStateGuard } from 'components/QueryStateGuard';
import { useFamilyCharterByFamilyId } from 'hooks/familyCharter/useFamilyCharterByFamilyId';

export const GoalsSection = ({ familyId }: { familyId?: string }) => {
  const { t } = useTranslation(['profile', 'common']);
  const { familyCharter, error, loading } =
    useFamilyCharterByFamilyId(familyId);

  if (!familyId || (!familyCharter?.goals && (!loading || !error))) return null;

  return (
    <QueryStateGuard error={error} loading={loading}>
      <Section
        title={t('profile:sections.family-goals.title')}
        subtitleContent={
          <Text size="p2">
            {t('profile:sections.family-goals.description')}
          </Text>
        }
      >
        <Grid gridTemplateColumns="repeat(3, 1fr)" gap={4}>
          {familyCharter?.goals.map((goal, index) => (
            <Flex
              borderRadius={4}
              key={goal.id}
              bgColor="ui.white"
              direction="column"
              p={4}
              gap={4}
            >
              <Heading size="h5">
                {t('common:goal_one')} {index + 1}
              </Heading>
              <Text size="p2">{goal.description}</Text>
              <Divider
                color="primary.blue.default"
                bgColor="primary.blue.default"
                opacity={1}
              />
              <Flex alignItems="center" gap={2}>
                <SdgIcon
                  name={goal.sdg.internalName}
                  variant="outline"
                  additionalStyling={{ w: 10, h: 10 }}
                />
                <Text size="p2">
                  {t('common:sdg-influenced', { number: goal.sdg.index })}
                </Text>
              </Flex>
            </Flex>
          ))}
        </Grid>
      </Section>
    </QueryStateGuard>
  );
};
