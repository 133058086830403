import { gql, useQuery } from '@apollo/client';

import { InsightDeepNestingFragment } from 'gql/fragments';
import { Query, QueryRecommendedInsightsArgs } from 'types';

export const recommendedInsightsGql = gql`
  ${InsightDeepNestingFragment}
  query RecommendedInsights(
    $matchingType: InsightsRecommendationEnum!
    $pagination: PaginationInput!
    $familyId: String
    $visibility: ListInsightVisibilityFiltersInput
  ) {
    recommendedInsights(
      matchingType: $matchingType
      pagination: $pagination
      familyId: $familyId
      visibility: $visibility
    ) {
      nodes {
        ...InsightDeepNestingFragment
      }
    }
  }
`;

type Props = Omit<QueryRecommendedInsightsArgs, 'pagination'> & {
  pagination?: QueryRecommendedInsightsArgs['pagination'];
};

export const useRecommendedInsights = ({
  familyId,
  matchingType,
  pagination = { limit: 10 },
  visibility,
}: Props) => {
  const { data, loading, error } = useQuery<
    Pick<Query, 'recommendedInsights'>,
    QueryRecommendedInsightsArgs
  >(recommendedInsightsGql, {
    variables: {
      familyId,
      matchingType,
      pagination,
      visibility,
    },
  });

  const insights = data?.recommendedInsights.nodes || [];

  return { insights, loading, error };
};
