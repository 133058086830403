import { gql, useMutation } from '@apollo/client';

import { onboardManagementGql } from 'hooks/onboarding/useOnboardingManagementByReference';
import { useWithMutationToast } from 'hooks/useWithMutationToast';
import {
  Mutation,
  MutationSetConfigStatusAsAdminArgs,
  SetConfigStatusAsAdminInput,
} from 'types';

export const setAttendanceResponseAsAdminGql = gql`
  mutation SetConfigStatusAsAdmin($data: SetConfigStatusAsAdminInput!) {
    setConfigStatusAsAdmin(data: $data)
  }
`;

export const useSetConfigStatusAsAdmin = () => {
  const [mutate, { loading }] = useMutation<
    Pick<Mutation, 'setConfigStatusAsAdmin'>,
    MutationSetConfigStatusAsAdminArgs
  >(setAttendanceResponseAsAdminGql, {
    refetchQueries: [onboardManagementGql],
  });

  const handleChange = ({
    accountId,
    sdgConfigStatus,
    valueConfigStatus,
  }: SetConfigStatusAsAdminInput) =>
    mutate({
      variables: {
        data: {
          accountId,
          sdgConfigStatus,
          valueConfigStatus,
        },
      },
    });

  const changeStatus = useWithMutationToast(handleChange, {
    successMessage: 'Status has been changed',
    errorMessage: 'Changing status failed',
  });

  return {
    changeStatus,
    loading,
  };
};
