import { Links } from 'router/links';

import { AllType, GroupTab, GROUP_TABS } from './types';

export const getGroupTabLink = (tab: GroupTab) => `${Links.groups}#${tab}`;

export const getCurrentTab = (hash: string): GroupTab => {
  const hashValue = hash.replace('#', '') as GroupTab;

  return GROUP_TABS.includes(hashValue) ? hashValue : AllType;
};
