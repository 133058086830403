import { gql, useQuery } from '@apollo/client';

import {
  OnboardReferenceType,
  Query,
  QueryValueConfiguratorNormalizedByReferenceArgs,
} from 'types';
import { ValueConfiguratorNormalizedOutputDeepNestingFragment } from 'types/generated-fragments';

export const valueConfiguratorFamilyNormalizedByReferenceGql = gql`
  ${ValueConfiguratorNormalizedOutputDeepNestingFragment}
  query ValueConfiguratorNormalizedByReference(
    $referenceId: String!
    $referenceType: OnboardReferenceType!
  ) {
    valueConfiguratorNormalizedByReference(
      referenceId: $referenceId
      referenceType: $referenceType
    ) {
      ...ValueConfiguratorNormalizedOutputDeepNestingFragment
    }
  }
`;

type Args = {
  referenceType: OnboardReferenceType;
  referenceId: string;
};

export const useFamilyNormalizedValuesByReference = ({
  referenceType,
  referenceId,
}: Args) => {
  const { data, error, loading } = useQuery<
    Pick<Query, 'valueConfiguratorNormalizedByReference'>,
    QueryValueConfiguratorNormalizedByReferenceArgs
  >(valueConfiguratorFamilyNormalizedByReferenceGql, {
    variables: { referenceId, referenceType },
  });

  return {
    normalizedFamilyValues: data?.valueConfiguratorNormalizedByReference || {},
    error,
    loading,
  };
};
