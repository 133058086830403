
export const PhoneFragment = `fragment PhoneFragment on Phone {
  number
  countryCode
}
`;

export const MediaOutputFragment = `fragment MediaOutputFragment on MediaOutput {
  id
  createdAt
  updatedAt
  signedUrl
}
`;

export const ConnectionSentMetadataFragment = `fragment ConnectionSentMetadataFragment on ConnectionSentMetadata {
  accountId
  invitationId
  actionStatus
}
`;

export const ConnectionRejectedMetadataFragment = `fragment ConnectionRejectedMetadataFragment on ConnectionRejectedMetadata {
  accountId
}
`;

export const ConnectionAcceptedMetadataFragment = `fragment ConnectionAcceptedMetadataFragment on ConnectionAcceptedMetadata {
  accountId
}
`;

export const ContentDeletedByAdminMetadataFragment = `fragment ContentDeletedByAdminMetadataFragment on ContentDeletedByAdminMetadata {
  accountId
  entityName
}
`;

export const MessageSentMetadataFragment = `fragment MessageSentMetadataFragment on MessageSentMetadata {
  accountId
  conversationId
}
`;

export const GroupInvitationSentMetadataFragment = `fragment GroupInvitationSentMetadataFragment on GroupInvitationSentMetadata {
  accountId
  groupId
  invitationId
  actionStatus
}
`;

export const GroupInvitationAcceptedMetadataFragment = `fragment GroupInvitationAcceptedMetadataFragment on GroupInvitationAcceptedMetadata {
  accountId
  groupId
}
`;

export const PaginatedNotificationUnionOutputFragment = `fragment PaginatedNotificationUnionOutputFragment on PaginatedNotificationUnionOutput {
  offset
  totalCount
  totalUnreadCount
}
`;

export const MessageFragment = `fragment MessageFragment on Message {
  id
  createdAt
  updatedAt
  text
  conversationId
  recipientIds
  deletedBy
  updatedBy
  createdBy
}
`;

export const EsgCategoryFragment = `fragment EsgCategoryFragment on EsgCategory {
  id
  createdAt
  updatedAt
  name
  description
  iconId
}
`;

export const InsightHeadingBlockOutputFragment = `fragment InsightHeadingBlockOutputFragment on InsightHeadingBlockOutput {
  type
  order
  heading
}
`;

export const InsightRichTextBlockOutputFragment = `fragment InsightRichTextBlockOutputFragment on InsightRichTextBlockOutput {
  type
  order
  text
  optionalHeading
}
`;

export const InitiativeVisibilityOutputFragment = `fragment InitiativeVisibilityOutputFragment on InitiativeVisibilityOutput {
  itriom
  familyOwnersIds
  family
  featured
  isPublic
  connections
}
`;

export const InitiativeHeadingBlockOutputFragment = `fragment InitiativeHeadingBlockOutputFragment on InitiativeHeadingBlockOutput {
  type
  order
  heading
}
`;

export const InitiativeRichTextBlockOutputFragment = `fragment InitiativeRichTextBlockOutputFragment on InitiativeRichTextBlockOutput {
  type
  order
  text
  optionalHeading
}
`;

export const SdgOutputFragment = `fragment SdgOutputFragment on SdgOutput {
  id
  createdAt
  updatedAt
  internalName
  name
  index
  description
  icon
}
`;

export const FamilyValueOutputFragment = `fragment FamilyValueOutputFragment on FamilyValueOutput {
  id
  createdAt
  updatedAt
  name
  category
}
`;

export const FamilyCharterValueOutputFragment = `fragment FamilyCharterValueOutputFragment on FamilyCharterValueOutput {
  id
  createdAt
  updatedAt
  name
}
`;

export const TimezoneDateOutputFragment = `fragment TimezoneDateOutputFragment on TimezoneDateOutput {
  datetime
  offset
  text
}
`;

export const EventTypeDataFragment = `fragment EventTypeDataFragment on EventTypeData {
  type
  referenceId
}
`;

export const LocationButtonOutputFragment = `fragment LocationButtonOutputFragment on LocationButtonOutput {
  buttonLink
  buttonLabel
}
`;

export const EventObjectiveFragment = `fragment EventObjectiveFragment on EventObjective {
  heading
  description
}
`;

export const SearchConnectionHighlightsOutputFragment = `fragment SearchConnectionHighlightsOutputFragment on SearchConnectionHighlightsOutput {
  firstName
  lastName
}
`;

export const SearchInsightHighlightsOutputFragment = `fragment SearchInsightHighlightsOutputFragment on SearchInsightHighlightsOutput {
  hero_author
  hero_summary
  hero_headline
  blocks_optionalHeading
  blocks_text
  blocks_heading
}
`;

export const SearchInitiativeHighlightsOutputFragment = `fragment SearchInitiativeHighlightsOutputFragment on SearchInitiativeHighlightsOutput {
  hero_summary
  hero_headline
  blocks_optionalHeading
  blocks_text
  blocks_heading
}
`;

export const SearchEventHighlightsOutputFragment = `fragment SearchEventHighlightsOutputFragment on SearchEventHighlightsOutput {
  name
  description
  hero_name
  topics_description
  agenda_description
  agenda_heading
  agenda_agendaItems_name
  agenda_agendaItems_description
}
`;

export const AppreciationFragment = `fragment AppreciationFragment on Appreciation {
  id
  createdAt
  updatedAt
  type
  targetId
  createdBy
  updatedBy
}
`;

export const MinimalAccountDataOutputFragment = `fragment MinimalAccountDataOutputFragment on MinimalAccountDataOutput {
  email
  phoneNumber
  firstName
}
`;

export const TokensOutputFragment = `fragment TokensOutputFragment on TokensOutput {
  accessToken
}
`;

export const TagOutputFragment = `fragment TagOutputFragment on TagOutput {
  id
  createdAt
  updatedAt
  name
  type
  order
}
`;

export const FamilyCharterGoalObjectiveOutputFragment = `fragment FamilyCharterGoalObjectiveOutputFragment on FamilyCharterGoalObjectiveOutput {
  id
  label
  description
}
`;

export const OnboardManagementActionsFragment = `fragment OnboardManagementActionsFragment on OnboardManagementActions {
  rsvpEmailSent
  sdgConfiguratorInvitationEmailSent
  sdgConfiguratorCompletedEmailSent
  profileCompletedEmailSent
}
`;

export const FamilyOnboardMemberActionsOutputFragment = `fragment FamilyOnboardMemberActionsOutputFragment on FamilyOnboardMemberActionsOutput {
  rsvp2ReminderEmailState
  oneHourBeforeWorkshopReminderEmailState
  tenDaysBeforeWorkshopReminderEmailState
  twoDaysBeforeWorkshopReminderEmailState
  oneDayBeforeWorkshopReminderEmailState
  workshopAbsenceConfirmedEmailState
  workshopAttendanceConfirmedEmailState
  threeDaysBeforeEventReminderEmailState
}
`;

export const SdgIdWithNumericScoreOutputFragment = `fragment SdgIdWithNumericScoreOutputFragment on SdgIdWithNumericScoreOutput {
  sdgId
  numericScore
}
`;

export const OrganisationalValuesOutputFragment = `fragment OrganisationalValuesOutputFragment on OrganisationalValuesOutput {
  resilience
  leadership
  transparency
  adaptability
}
`;

export const ContributionValuesOutputFragment = `fragment ContributionValuesOutputFragment on ContributionValuesOutput {
  fairness
  stewardship
  inclusivity
  community
}
`;

export const AdvancementValuesOutputFragment = `fragment AdvancementValuesOutputFragment on AdvancementValuesOutput {
  progress
  learning
  innovation
  curiosity
}
`;

export const SocietalValuesOutputFragment = `fragment SocietalValuesOutputFragment on SocietalValuesOutput {
  collaboration
  equality
  compassion
  empowerment
}
`;

export const OrganisationalValuesNormalizedOutputFragment = `fragment OrganisationalValuesNormalizedOutputFragment on OrganisationalValuesNormalizedOutput {
  resilience
  leadership
  transparency
  adaptability
}
`;

export const ContributionValuesNormalizedOutputFragment = `fragment ContributionValuesNormalizedOutputFragment on ContributionValuesNormalizedOutput {
  fairness
  stewardship
  inclusivity
  community
}
`;

export const AdvancementValuesNormalizedOutputFragment = `fragment AdvancementValuesNormalizedOutputFragment on AdvancementValuesNormalizedOutput {
  progress
  learning
  innovation
  curiosity
}
`;

export const SocietalValuesNormalizedOutputFragment = `fragment SocietalValuesNormalizedOutputFragment on SocietalValuesNormalizedOutput {
  collaboration
  equality
  compassion
  empowerment
}
`;

export const LinkMetadataFragment = `fragment LinkMetadataFragment on LinkMetadata {
  title
  hostname
  description
  image
}
`;

export const PrincipalPrivacySettingsOutputFragment = `fragment PrincipalPrivacySettingsOutputFragment on PrincipalPrivacySettingsOutput {
  connections
}
`;

export const PrincipalValueOutputFragment = `fragment PrincipalValueOutputFragment on PrincipalValueOutput {
  heading
  description
}
`;

export const PhoneNoNestingFragment = `fragment PhoneNoNestingFragment on Phone {
  number
  countryCode
}
`;

export const MediaOutputNoNestingFragment = `fragment MediaOutputNoNestingFragment on MediaOutput {
  id
  createdAt
  updatedAt
  signedUrl
}
`;

export const ConnectionSentMetadataNoNestingFragment = `fragment ConnectionSentMetadataNoNestingFragment on ConnectionSentMetadata {
  accountId
  invitationId
  actionStatus
}
`;

export const ConnectionRejectedMetadataNoNestingFragment = `fragment ConnectionRejectedMetadataNoNestingFragment on ConnectionRejectedMetadata {
  accountId
}
`;

export const ConnectionAcceptedMetadataNoNestingFragment = `fragment ConnectionAcceptedMetadataNoNestingFragment on ConnectionAcceptedMetadata {
  accountId
}
`;

export const ContentDeletedByAdminMetadataNoNestingFragment = `fragment ContentDeletedByAdminMetadataNoNestingFragment on ContentDeletedByAdminMetadata {
  accountId
  entityName
}
`;

export const MessageSentMetadataNoNestingFragment = `fragment MessageSentMetadataNoNestingFragment on MessageSentMetadata {
  accountId
  conversationId
}
`;

export const GroupInvitationSentMetadataNoNestingFragment = `fragment GroupInvitationSentMetadataNoNestingFragment on GroupInvitationSentMetadata {
  accountId
  groupId
  invitationId
  actionStatus
}
`;

export const GroupInvitationAcceptedMetadataNoNestingFragment = `fragment GroupInvitationAcceptedMetadataNoNestingFragment on GroupInvitationAcceptedMetadata {
  accountId
  groupId
}
`;

export const PaginatedNotificationUnionOutputNoNestingFragment = `fragment PaginatedNotificationUnionOutputNoNestingFragment on PaginatedNotificationUnionOutput {
  offset
  totalCount
  totalUnreadCount
}
`;

export const ConnectionSentNotificationOutputNoNestingFragment = `fragment ConnectionSentNotificationOutputNoNestingFragment on ConnectionSentNotificationOutput {
  id
  createdAt
  type
  isRead
  category
}
`;

export const ConnectionAcceptedNotificationOutputNoNestingFragment = `fragment ConnectionAcceptedNotificationOutputNoNestingFragment on ConnectionAcceptedNotificationOutput {
  id
  createdAt
  type
  isRead
  category
}
`;

export const GroupInvitationSentNotificationOutputNoNestingFragment = `fragment GroupInvitationSentNotificationOutputNoNestingFragment on GroupInvitationSentNotificationOutput {
  id
  createdAt
  type
  isRead
  category
}
`;

export const GroupInvitationAcceptedNotificationOutputNoNestingFragment = `fragment GroupInvitationAcceptedNotificationOutputNoNestingFragment on GroupInvitationAcceptedNotificationOutput {
  id
  createdAt
  type
  isRead
  category
}
`;

export const MessageSentNotificationOutputNoNestingFragment = `fragment MessageSentNotificationOutputNoNestingFragment on MessageSentNotificationOutput {
  id
  createdAt
  type
  isRead
  category
}
`;

export const ContentDeletedByAdminOutputNoNestingFragment = `fragment ContentDeletedByAdminOutputNoNestingFragment on ContentDeletedByAdminOutput {
  id
  createdAt
  type
  isRead
  category
}
`;

export const MessageNoNestingFragment = `fragment MessageNoNestingFragment on Message {
  id
  createdAt
  updatedAt
  text
  conversationId
  recipientIds
  deletedBy
  updatedBy
  createdBy
}
`;

export const PaginatedMessageOutputNoNestingFragment = `fragment PaginatedMessageOutputNoNestingFragment on PaginatedMessageOutput {
  offset
  totalCount
}
`;

export const EsgCategoryNoNestingFragment = `fragment EsgCategoryNoNestingFragment on EsgCategory {
  id
  createdAt
  updatedAt
  name
  description
  iconId
}
`;

export const SdgNoNestingFragment = `fragment SdgNoNestingFragment on Sdg {
  id
  createdAt
  updatedAt
  name
  description
  iconId
}
`;

export const ProfileNoNestingFragment = `fragment ProfileNoNestingFragment on Profile {
  id
  firstName
  lastName
  title
  description
  isConnected
  createdAt
  updatedAt
  status
  connectionState
  canBeInvited
  honorific
  role
}
`;

export const PaginatedProfileOutputNoNestingFragment = `fragment PaginatedProfileOutputNoNestingFragment on PaginatedProfileOutput {
  offset
  totalCount
}
`;

export const ItriomTagsV2OutputNoNestingFragment = `fragment ItriomTagsV2OutputNoNestingFragment on ItriomTagsV2Output {
  esgFocus
}
`;

export const InsightHeroOutputNoNestingFragment = `fragment InsightHeroOutputNoNestingFragment on InsightHeroOutput {
  headline
  summary
  author
  sourceUrl
  firstPublishedAt
}
`;

export const InsightNoNestingFragment = `fragment InsightNoNestingFragment on Insight {
  id
  createdAt
  updatedAt
  status
  createdBy
  updatedBy
}
`;

export const InsightFullMediaBlockOutputNoNestingFragment = `fragment InsightFullMediaBlockOutputNoNestingFragment on InsightFullMediaBlockOutput {
  type
  order
}
`;

export const InsightGalleryBlockOutputNoNestingFragment = `fragment InsightGalleryBlockOutputNoNestingFragment on InsightGalleryBlockOutput {
  type
  order
}
`;

export const InsightHeadingBlockOutputNoNestingFragment = `fragment InsightHeadingBlockOutputNoNestingFragment on InsightHeadingBlockOutput {
  type
  order
  heading
}
`;

export const InsightLeftTextBlockOutputNoNestingFragment = `fragment InsightLeftTextBlockOutputNoNestingFragment on InsightLeftTextBlockOutput {
  type
  order
  text
  optionalHeading
}
`;

export const InsightRichTextBlockOutputNoNestingFragment = `fragment InsightRichTextBlockOutputNoNestingFragment on InsightRichTextBlockOutput {
  type
  order
  text
  optionalHeading
}
`;

export const InsightRightTextBlockOutputNoNestingFragment = `fragment InsightRightTextBlockOutputNoNestingFragment on InsightRightTextBlockOutput {
  type
  order
  text
  optionalHeading
}
`;

export const InsightQuoteBlockOutputNoNestingFragment = `fragment InsightQuoteBlockOutputNoNestingFragment on InsightQuoteBlockOutput {
  type
  order
  text
  customName
  customTitle
}
`;

export const PaginatedInsightOutputNoNestingFragment = `fragment PaginatedInsightOutputNoNestingFragment on PaginatedInsightOutput {
  offset
  totalCount
}
`;

export const InitiativeHeroOutputNoNestingFragment = `fragment InitiativeHeroOutputNoNestingFragment on InitiativeHeroOutput {
  headline
  summary
  startDate
  leftChartHeadline
  leftChartSubheadline
  rightChartHeadline
  rightChartSubheadline
}
`;

export const InitiativeOutputNoNestingFragment = `fragment InitiativeOutputNoNestingFragment on InitiativeOutput {
  id
  createdAt
  updatedAt
  status
  createdBy
  updatedBy
  appreciationCount
  isFollowed
}
`;

export const InitiativeFullMediaBlockOutputNoNestingFragment = `fragment InitiativeFullMediaBlockOutputNoNestingFragment on InitiativeFullMediaBlockOutput {
  type
  order
}
`;

export const InitiativeGalleryBlockOutputNoNestingFragment = `fragment InitiativeGalleryBlockOutputNoNestingFragment on InitiativeGalleryBlockOutput {
  type
  order
}
`;

export const InitiativeHeadingBlockOutputNoNestingFragment = `fragment InitiativeHeadingBlockOutputNoNestingFragment on InitiativeHeadingBlockOutput {
  type
  order
  heading
}
`;

export const InitiativeLeftTextBlockOutputNoNestingFragment = `fragment InitiativeLeftTextBlockOutputNoNestingFragment on InitiativeLeftTextBlockOutput {
  type
  order
  text
  optionalHeading
}
`;

export const InitiativeRichTextBlockOutputNoNestingFragment = `fragment InitiativeRichTextBlockOutputNoNestingFragment on InitiativeRichTextBlockOutput {
  type
  order
  text
  optionalHeading
}
`;

export const InitiativeRightTextBlockOutputNoNestingFragment = `fragment InitiativeRightTextBlockOutputNoNestingFragment on InitiativeRightTextBlockOutput {
  type
  order
  text
  optionalHeading
}
`;

export const PaginatedInitiativeOutputNoNestingFragment = `fragment PaginatedInitiativeOutputNoNestingFragment on PaginatedInitiativeOutput {
  offset
  totalCount
}
`;

export const ObjectiveNoNestingFragment = `fragment ObjectiveNoNestingFragment on Objective {
  id
  createdAt
  updatedAt
  name
  description
}
`;

export const GoalNoNestingFragment = `fragment GoalNoNestingFragment on Goal {
  id
  createdAt
  updatedAt
  number
  name
  description
  shortDescription
}
`;

export const SdgOutputNoNestingFragment = `fragment SdgOutputNoNestingFragment on SdgOutput {
  id
  createdAt
  updatedAt
  internalName
  name
  index
  description
  icon
}
`;

export const FamilyValueOutputNoNestingFragment = `fragment FamilyValueOutputNoNestingFragment on FamilyValueOutput {
  id
  createdAt
  updatedAt
  name
  category
}
`;

export const FamilyCharterValueOutputNoNestingFragment = `fragment FamilyCharterValueOutputNoNestingFragment on FamilyCharterValueOutput {
  id
  createdAt
  updatedAt
  name
}
`;

export const FamilyNoNestingFragment = `fragment FamilyNoNestingFragment on Family {
  id
  createdAt
  updatedAt
  name
  purpose
  status
}
`;

export const PaginatedFamilyOutputNoNestingFragment = `fragment PaginatedFamilyOutputNoNestingFragment on PaginatedFamilyOutput {
  offset
  totalCount
}
`;

export const JoinOutputNoNestingFragment = `fragment JoinOutputNoNestingFragment on JoinOutput {
  name
  description
  email
  isEnabled
}
`;

export const HelpRequestOutputNoNestingFragment = `fragment HelpRequestOutputNoNestingFragment on HelpRequestOutput {
  name
  description
  email
  isEnabled
}
`;

export const TimezoneDateOutputNoNestingFragment = `fragment TimezoneDateOutputNoNestingFragment on TimezoneDateOutput {
  datetime
  offset
  text
}
`;

export const EventTypeDataNoNestingFragment = `fragment EventTypeDataNoNestingFragment on EventTypeData {
  type
  referenceId
}
`;

export const LocationImageOutputNoNestingFragment = `fragment LocationImageOutputNoNestingFragment on LocationImageOutput {
  description
}
`;

export const LocationButtonOutputNoNestingFragment = `fragment LocationButtonOutputNoNestingFragment on LocationButtonOutput {
  buttonLink
  buttonLabel
}
`;

export const LocationNoNestingFragment = `fragment LocationNoNestingFragment on Location {
  name
  description
  addressLine1
  addressLine2
  city
  postcode
  state
  country
  isGalleryEnabled
  telephone
  email
}
`;

export const EventBasicInfoNoNestingFragment = `fragment EventBasicInfoNoNestingFragment on EventBasicInfo {
  isVirtual
}
`;

export const EventHeroNoNestingFragment = `fragment EventHeroNoNestingFragment on EventHero {
  name
  subtitle
  summary
  description
  isPublic
  preferredHeroMediaToDisplay
  eventUrl
}
`;

export const EventObjectiveNoNestingFragment = `fragment EventObjectiveNoNestingFragment on EventObjective {
  heading
  description
}
`;

export const EventKeySpeakerOutputNoNestingFragment = `fragment EventKeySpeakerOutputNoNestingFragment on EventKeySpeakerOutput {
  keynoteSpeakerHeading
  name
  title
  about
}
`;

export const SponsorNoNestingFragment = `fragment SponsorNoNestingFragment on Sponsor {
  name
  title
  isCompany
  showName
}
`;

export const TopicNoNestingFragment = `fragment TopicNoNestingFragment on Topic {
  description
}
`;

export const UltimateGoalOutputNoNestingFragment = `fragment UltimateGoalOutputNoNestingFragment on UltimateGoalOutput {
  title
  description
  sdIcon
}
`;

export const EventNoNestingFragment = `fragment EventNoNestingFragment on Event {
  id
  createdAt
  updatedAt
  status
  isCommentsEnabled
  preferredHeroMediaToDisplay
  mainTopicDescription
  createdBy
  updatedBy
  appreciationCount
  isAttending
}
`;

export const EventPaginatedOutputNoNestingFragment = `fragment EventPaginatedOutputNoNestingFragment on EventPaginatedOutput {
  offset
  totalCount
}
`;

export const SearchConnectionHighlightsOutputNoNestingFragment = `fragment SearchConnectionHighlightsOutputNoNestingFragment on SearchConnectionHighlightsOutput {
  firstName
  lastName
}
`;

export const SearchConnectionOutputNoNestingFragment = `fragment SearchConnectionOutputNoNestingFragment on SearchConnectionOutput {
  totalCount
}
`;

export const SearchInsightHighlightsOutputNoNestingFragment = `fragment SearchInsightHighlightsOutputNoNestingFragment on SearchInsightHighlightsOutput {
  hero_author
  hero_summary
  hero_headline
  blocks_optionalHeading
  blocks_text
  blocks_heading
}
`;

export const SearchInsightOutputNoNestingFragment = `fragment SearchInsightOutputNoNestingFragment on SearchInsightOutput {
  totalCount
}
`;

export const SearchInitiativeHighlightsOutputNoNestingFragment = `fragment SearchInitiativeHighlightsOutputNoNestingFragment on SearchInitiativeHighlightsOutput {
  hero_summary
  hero_headline
  blocks_optionalHeading
  blocks_text
  blocks_heading
}
`;

export const SearchInitiativeOutputNoNestingFragment = `fragment SearchInitiativeOutputNoNestingFragment on SearchInitiativeOutput {
  totalCount
}
`;

export const SearchEventHighlightsOutputNoNestingFragment = `fragment SearchEventHighlightsOutputNoNestingFragment on SearchEventHighlightsOutput {
  name
  description
  hero_name
  topics_description
  agenda_description
  agenda_heading
  agenda_agendaItems_name
  agenda_agendaItems_description
}
`;

export const SearchEventOutputNoNestingFragment = `fragment SearchEventOutputNoNestingFragment on SearchEventOutput {
  totalCount
}
`;

export const AccountNoNestingFragment = `fragment AccountNoNestingFragment on Account {
  id
  createdAt
  updatedAt
  email
  honorific
  firstName
  lastName
  title
  description
  role
  status
  onboardingStatus
  isFamilyAdmin
  familyId
}
`;

export const AdvisorAboutOutputNoNestingFragment = `fragment AdvisorAboutOutputNoNestingFragment on AdvisorAboutOutput {
  content
}
`;

export const AdvisorHeroOutputNoNestingFragment = `fragment AdvisorHeroOutputNoNestingFragment on AdvisorHeroOutput {
  advisorAccountId
  headline
  mission
}
`;

export const AdvisorLocationOutputNoNestingFragment = `fragment AdvisorLocationOutputNoNestingFragment on AdvisorLocationOutput {
  name
  description
}
`;

export const AdvisorAdvocacyOutputNoNestingFragment = `fragment AdvisorAdvocacyOutputNoNestingFragment on AdvisorAdvocacyOutput {
  heading
  content
  preferredHeroMediaToDisplay
}
`;

export const AdvisorOutputNoNestingFragment = `fragment AdvisorOutputNoNestingFragment on AdvisorOutput {
  id
  createdAt
  updatedAt
  status
  awards
  createdBy
  updatedBy
  firstName
  lastName
}
`;

export const PaginatedAdvisorOutputNoNestingFragment = `fragment PaginatedAdvisorOutputNoNestingFragment on PaginatedAdvisorOutput {
  offset
  totalCount
}
`;

export const AppreciationNoNestingFragment = `fragment AppreciationNoNestingFragment on Appreciation {
  id
  createdAt
  updatedAt
  type
  targetId
  createdBy
  updatedBy
}
`;

export const MinimalAccountDataOutputNoNestingFragment = `fragment MinimalAccountDataOutputNoNestingFragment on MinimalAccountDataOutput {
  email
  phoneNumber
  firstName
}
`;

export const TokensOutputNoNestingFragment = `fragment TokensOutputNoNestingFragment on TokensOutput {
  accessToken
}
`;

export const ExtendedProfileOutputNoNestingFragment = `fragment ExtendedProfileOutputNoNestingFragment on ExtendedProfileOutput {
  id
  firstName
  lastName
  title
  description
  isConnected
  createdAt
  updatedAt
  status
  connectionState
  canBeInvited
  honorific
  role
  email
  dateOfBirth
  canBeDeleted
  canBeEdited
  isFamilyAdmin
  isHeadOfFamily
}
`;

export const PaginatedExtendedProfileOutputNoNestingFragment = `fragment PaginatedExtendedProfileOutputNoNestingFragment on PaginatedExtendedProfileOutput {
  offset
  totalCount
}
`;

export const TagOutputNoNestingFragment = `fragment TagOutputNoNestingFragment on TagOutput {
  id
  createdAt
  updatedAt
  name
  type
  order
}
`;

export const CommentNoNestingFragment = `fragment CommentNoNestingFragment on Comment {
  id
  createdAt
  updatedAt
  title
  text
  referenceId
  link
  type
  appreciationCount
  createdBy
  updatedBy
}
`;

export const ConnectionInvitationNoNestingFragment = `fragment ConnectionInvitationNoNestingFragment on ConnectionInvitation {
  id
  senderId
  recipientId
  createdAt
}
`;

export const ConversationNoNestingFragment = `fragment ConversationNoNestingFragment on Conversation {
  id
  createdAt
  updatedAt
  isMember
  updatedBy
  createdBy
}
`;

export const PaginatedConversationOutputNoNestingFragment = `fragment PaginatedConversationOutputNoNestingFragment on PaginatedConversationOutput {
  offset
  totalCount
}
`;

export const SpeakerOutputNoNestingFragment = `fragment SpeakerOutputNoNestingFragment on SpeakerOutput {
  isKeySpeaker
}
`;

export const AgendaItemNoNestingFragment = `fragment AgendaItemNoNestingFragment on AgendaItem {
  heading
  description
  startTime
  endTime
}
`;

export const AgendaNoNestingFragment = `fragment AgendaNoNestingFragment on Agenda {
  heading
  description
}
`;

export const OrganiserNoNestingFragment = `fragment OrganiserNoNestingFragment on Organiser {
  name
  title
}
`;

export const TriggeredCtaOutputNoNestingFragment = `fragment TriggeredCtaOutputNoNestingFragment on TriggeredCtaOutput {
  id
  createdAt
  updatedAt
  content
  createdBy
}
`;

export const FamilyCharterHeroOutputNoNestingFragment = `fragment FamilyCharterHeroOutputNoNestingFragment on FamilyCharterHeroOutput {
  familyId
  purpose
  heading
  preferredHeroMediaToDisplay
}
`;

export const FamilyCharterGoalObjectiveOutputNoNestingFragment = `fragment FamilyCharterGoalObjectiveOutputNoNestingFragment on FamilyCharterGoalObjectiveOutput {
  id
  label
  description
}
`;

export const FamilyCharterGoalInitiativeOutputNoNestingFragment = `fragment FamilyCharterGoalInitiativeOutputNoNestingFragment on FamilyCharterGoalInitiativeOutput {
  id
  initiativeId
}
`;

export const FamilyCharterGoalOutputNoNestingFragment = `fragment FamilyCharterGoalOutputNoNestingFragment on FamilyCharterGoalOutput {
  id
  sdgId
  description
}
`;

export const FamilyCharterNoNestingFragment = `fragment FamilyCharterNoNestingFragment on FamilyCharter {
  id
  createdAt
  updatedAt
  status
  createdBy
  updatedBy
}
`;

export const PaginatedFamilyCharterOutputNoNestingFragment = `fragment PaginatedFamilyCharterOutputNoNestingFragment on PaginatedFamilyCharterOutput {
  offset
  totalCount
}
`;

export const FamilyMemberRelationOutputNoNestingFragment = `fragment FamilyMemberRelationOutputNoNestingFragment on FamilyMemberRelationOutput {
  familyMemberId
  relatedFamilyMemberId
  relationType
}
`;

export const PostNoNestingFragment = `fragment PostNoNestingFragment on Post {
  id
  createdAt
  updatedAt
  title
  text
  referenceId
  link
  type
  subtype
  appreciationCount
  isSelfAppreciated
  createdBy
  updatedBy
}
`;

export const PaginatedPostOutputNoNestingFragment = `fragment PaginatedPostOutputNoNestingFragment on PaginatedPostOutput {
  offset
  totalCount
}
`;

export const GroupNoNestingFragment = `fragment GroupNoNestingFragment on Group {
  id
  createdAt
  updatedAt
  name
  category
  description
  type
  allowInvites
  familyOnly
  isAdmin
  isMember
  familyId
  automaticallyAddFamilyMembers
  createdBy
  updatedBy
  canBeModified
  canMakeAdmins
  canDeleteMembers
  isInvitationPending
}
`;

export const PaginatedGroupOutputNoNestingFragment = `fragment PaginatedGroupOutputNoNestingFragment on PaginatedGroupOutput {
  offset
  totalCount
}
`;

export const MemberToAddOutputNoNestingFragment = `fragment MemberToAddOutputNoNestingFragment on MemberToAddOutput {
  id
  firstName
  lastName
  title
  description
  isConnected
  createdAt
  updatedAt
  status
  connectionState
  canBeInvited
  honorific
  role
  isInvited
}
`;

export const PaginatedMembersToAddOutputNoNestingFragment = `fragment PaginatedMembersToAddOutputNoNestingFragment on PaginatedMembersToAddOutput {
  offset
  totalCount
}
`;

export const OnboardManagementMeetingInvitationOutputNoNestingFragment = `fragment OnboardManagementMeetingInvitationOutputNoNestingFragment on OnboardManagementMeetingInvitationOutput {
  location
  meetingLink
  meetingId
  meetingPasscode
}
`;

export const OnboardManagementActionsNoNestingFragment = `fragment OnboardManagementActionsNoNestingFragment on OnboardManagementActions {
  rsvpEmailSent
  sdgConfiguratorInvitationEmailSent
  sdgConfiguratorCompletedEmailSent
  profileCompletedEmailSent
}
`;

export const OnboardManagementOutputNoNestingFragment = `fragment OnboardManagementOutputNoNestingFragment on OnboardManagementOutput {
  id
  createdAt
  updatedAt
  referenceId
  referenceType
  state
}
`;

export const FamilyOnboardMemberActionsOutputNoNestingFragment = `fragment FamilyOnboardMemberActionsOutputNoNestingFragment on FamilyOnboardMemberActionsOutput {
  rsvp2ReminderEmailState
  oneHourBeforeWorkshopReminderEmailState
  tenDaysBeforeWorkshopReminderEmailState
  twoDaysBeforeWorkshopReminderEmailState
  oneDayBeforeWorkshopReminderEmailState
  workshopAbsenceConfirmedEmailState
  workshopAttendanceConfirmedEmailState
}
`;

export const FamilyOnboardMemberDetailsOutputNoNestingFragment = `fragment FamilyOnboardMemberDetailsOutputNoNestingFragment on FamilyOnboardMemberDetailsOutput {
  accountId
  isHeadOfFamily
  firstName
  lastName
  attendanceStatus
  sdgConfiguratorStatus
  valueConfiguratorStatus
}
`;

export const SdgIdWithNumericScoreOutputNoNestingFragment = `fragment SdgIdWithNumericScoreOutputNoNestingFragment on SdgIdWithNumericScoreOutput {
  sdgId
  numericScore
}
`;

export const FamilyValuesWithAvailableSdgOutputNoNestingFragment = `fragment FamilyValuesWithAvailableSdgOutputNoNestingFragment on FamilyValuesWithAvailableSdgOutput {
  id
  createdAt
  updatedAt
  name
  category
}
`;

export const OrganisationalValuesOutputNoNestingFragment = `fragment OrganisationalValuesOutputNoNestingFragment on OrganisationalValuesOutput {
  resilience
  leadership
  transparency
  adaptability
}
`;

export const ContributionValuesOutputNoNestingFragment = `fragment ContributionValuesOutputNoNestingFragment on ContributionValuesOutput {
  fairness
  stewardship
  inclusivity
  community
}
`;

export const AdvancementValuesOutputNoNestingFragment = `fragment AdvancementValuesOutputNoNestingFragment on AdvancementValuesOutput {
  progress
  learning
  innovation
  curiosity
}
`;

export const SocietalValuesOutputNoNestingFragment = `fragment SocietalValuesOutputNoNestingFragment on SocietalValuesOutput {
  collaboration
  equality
  compassion
  empowerment
}
`;

export const OrganisationalValuesNormalizedOutputNoNestingFragment = `fragment OrganisationalValuesNormalizedOutputNoNestingFragment on OrganisationalValuesNormalizedOutput {
  resilience
  leadership
  transparency
  adaptability
}
`;

export const ContributionValuesNormalizedOutputNoNestingFragment = `fragment ContributionValuesNormalizedOutputNoNestingFragment on ContributionValuesNormalizedOutput {
  fairness
  stewardship
  inclusivity
  community
}
`;

export const AdvancementValuesNormalizedOutputNoNestingFragment = `fragment AdvancementValuesNormalizedOutputNoNestingFragment on AdvancementValuesNormalizedOutput {
  progress
  learning
  innovation
  curiosity
}
`;

export const SocietalValuesNormalizedOutputNoNestingFragment = `fragment SocietalValuesNormalizedOutputNoNestingFragment on SocietalValuesNormalizedOutput {
  collaboration
  equality
  compassion
  empowerment
}
`;

export const ValueConfiguratorOutputNoNestingFragment = `fragment ValueConfiguratorOutputNoNestingFragment on ValueConfiguratorOutput {
  status
}
`;

export const LinkMetadataNoNestingFragment = `fragment LinkMetadataNoNestingFragment on LinkMetadata {
  title
  hostname
  description
  image
}
`;

export const PrincipalHeroOutputNoNestingFragment = `fragment PrincipalHeroOutputNoNestingFragment on PrincipalHeroOutput {
  purpose
  title
}
`;

export const PrincipalAboutOutputNoNestingFragment = `fragment PrincipalAboutOutputNoNestingFragment on PrincipalAboutOutput {
  content
}
`;

export const PrincipalPrivacySettingsOutputNoNestingFragment = `fragment PrincipalPrivacySettingsOutputNoNestingFragment on PrincipalPrivacySettingsOutput {
  connections
}
`;

export const PrincipalValueOutputNoNestingFragment = `fragment PrincipalValueOutputNoNestingFragment on PrincipalValueOutput {
  heading
  description
}
`;

export const PrincipalOutputNoNestingFragment = `fragment PrincipalOutputNoNestingFragment on PrincipalOutput {
  id
  createdAt
  updatedAt
  status
  createdBy
  updatedBy
  isHeadOfFamily
}
`;

export const PaginatedTagOutputNoNestingFragment = `fragment PaginatedTagOutputNoNestingFragment on PaginatedTagOutput {
  offset
  totalCount
}
`;

export const PhoneDeepNestingFragment = `fragment PhoneDeepNestingFragment on Phone {
  number
  countryCode
}
`;

export const MediaOutputDeepNestingFragment = `fragment MediaOutputDeepNestingFragment on MediaOutput {
  id
  createdAt
  updatedAt
  signedUrl
}
`;

export const ConnectionSentMetadataDeepNestingFragment = `fragment ConnectionSentMetadataDeepNestingFragment on ConnectionSentMetadata {
  accountId
  invitationId
  actionStatus
}
`;

export const ConnectionRejectedMetadataDeepNestingFragment = `fragment ConnectionRejectedMetadataDeepNestingFragment on ConnectionRejectedMetadata {
  accountId
}
`;

export const ConnectionAcceptedMetadataDeepNestingFragment = `fragment ConnectionAcceptedMetadataDeepNestingFragment on ConnectionAcceptedMetadata {
  accountId
}
`;

export const ContentDeletedByAdminMetadataDeepNestingFragment = `fragment ContentDeletedByAdminMetadataDeepNestingFragment on ContentDeletedByAdminMetadata {
  accountId
  entityName
}
`;

export const MessageSentMetadataDeepNestingFragment = `fragment MessageSentMetadataDeepNestingFragment on MessageSentMetadata {
  accountId
  conversationId
}
`;

export const GroupInvitationSentMetadataDeepNestingFragment = `fragment GroupInvitationSentMetadataDeepNestingFragment on GroupInvitationSentMetadata {
  accountId
  groupId
  invitationId
  actionStatus
}
`;

export const GroupInvitationAcceptedMetadataDeepNestingFragment = `fragment GroupInvitationAcceptedMetadataDeepNestingFragment on GroupInvitationAcceptedMetadata {
  accountId
  groupId
}
`;

export const PaginatedNotificationUnionOutputDeepNestingFragment = `fragment PaginatedNotificationUnionOutputDeepNestingFragment on PaginatedNotificationUnionOutput {
  offset
  totalCount
  totalUnreadCount
}
`;

export const MessageDeepNestingFragment = `fragment MessageDeepNestingFragment on Message {
  id
  createdAt
  updatedAt
  text
  conversationId
  recipientIds
  deletedBy
  updatedBy
  createdBy
}
`;

export const EsgCategoryDeepNestingFragment = `fragment EsgCategoryDeepNestingFragment on EsgCategory {
  id
  createdAt
  updatedAt
  name
  description
  iconId
}
`;

export const InsightHeadingBlockOutputDeepNestingFragment = `fragment InsightHeadingBlockOutputDeepNestingFragment on InsightHeadingBlockOutput {
  type
  order
  heading
}
`;

export const InsightRichTextBlockOutputDeepNestingFragment = `fragment InsightRichTextBlockOutputDeepNestingFragment on InsightRichTextBlockOutput {
  type
  order
  text
  optionalHeading
}
`;

export const InitiativeHeadingBlockOutputDeepNestingFragment = `fragment InitiativeHeadingBlockOutputDeepNestingFragment on InitiativeHeadingBlockOutput {
  type
  order
  heading
}
`;

export const InitiativeRichTextBlockOutputDeepNestingFragment = `fragment InitiativeRichTextBlockOutputDeepNestingFragment on InitiativeRichTextBlockOutput {
  type
  order
  text
  optionalHeading
}
`;

export const SdgOutputDeepNestingFragment = `fragment SdgOutputDeepNestingFragment on SdgOutput {
  id
  createdAt
  updatedAt
  internalName
  name
  index
  description
  icon
}
`;

export const FamilyValueOutputDeepNestingFragment = `fragment FamilyValueOutputDeepNestingFragment on FamilyValueOutput {
  id
  createdAt
  updatedAt
  name
  category
}
`;

export const FamilyCharterValueOutputDeepNestingFragment = `fragment FamilyCharterValueOutputDeepNestingFragment on FamilyCharterValueOutput {
  id
  createdAt
  updatedAt
  name
}
`;

export const TimezoneDateOutputDeepNestingFragment = `fragment TimezoneDateOutputDeepNestingFragment on TimezoneDateOutput {
  datetime
  offset
  text
}
`;

export const EventTypeDataDeepNestingFragment = `fragment EventTypeDataDeepNestingFragment on EventTypeData {
  type
  referenceId
}
`;

export const LocationButtonOutputDeepNestingFragment = `fragment LocationButtonOutputDeepNestingFragment on LocationButtonOutput {
  buttonLink
  buttonLabel
}
`;

export const EventObjectiveDeepNestingFragment = `fragment EventObjectiveDeepNestingFragment on EventObjective {
  heading
  description
}
`;

export const SearchConnectionHighlightsOutputDeepNestingFragment = `fragment SearchConnectionHighlightsOutputDeepNestingFragment on SearchConnectionHighlightsOutput {
  firstName
  lastName
}
`;

export const SearchInsightHighlightsOutputDeepNestingFragment = `fragment SearchInsightHighlightsOutputDeepNestingFragment on SearchInsightHighlightsOutput {
  hero_author
  hero_summary
  hero_headline
  blocks_optionalHeading
  blocks_text
  blocks_heading
}
`;

export const SearchInitiativeHighlightsOutputDeepNestingFragment = `fragment SearchInitiativeHighlightsOutputDeepNestingFragment on SearchInitiativeHighlightsOutput {
  hero_summary
  hero_headline
  blocks_optionalHeading
  blocks_text
  blocks_heading
}
`;

export const SearchEventHighlightsOutputDeepNestingFragment = `fragment SearchEventHighlightsOutputDeepNestingFragment on SearchEventHighlightsOutput {
  name
  description
  hero_name
  topics_description
  agenda_description
  agenda_heading
  agenda_agendaItems_name
  agenda_agendaItems_description
}
`;

export const AppreciationDeepNestingFragment = `fragment AppreciationDeepNestingFragment on Appreciation {
  id
  createdAt
  updatedAt
  type
  targetId
  createdBy
  updatedBy
}
`;

export const MinimalAccountDataOutputDeepNestingFragment = `fragment MinimalAccountDataOutputDeepNestingFragment on MinimalAccountDataOutput {
  email
  phoneNumber
  firstName
}
`;

export const TokensOutputDeepNestingFragment = `fragment TokensOutputDeepNestingFragment on TokensOutput {
  accessToken
}
`;

export const TagOutputDeepNestingFragment = `fragment TagOutputDeepNestingFragment on TagOutput {
  id
  createdAt
  updatedAt
  name
  type
  order
}
`;

export const FamilyCharterGoalObjectiveOutputDeepNestingFragment = `fragment FamilyCharterGoalObjectiveOutputDeepNestingFragment on FamilyCharterGoalObjectiveOutput {
  id
  label
  description
}
`;

export const OnboardManagementActionsDeepNestingFragment = `fragment OnboardManagementActionsDeepNestingFragment on OnboardManagementActions {
  rsvpEmailSent
  sdgConfiguratorInvitationEmailSent
  sdgConfiguratorCompletedEmailSent
  profileCompletedEmailSent
}
`;

export const FamilyOnboardMemberActionsOutputDeepNestingFragment = `fragment FamilyOnboardMemberActionsOutputDeepNestingFragment on FamilyOnboardMemberActionsOutput {
  rsvp2ReminderEmailState
  oneHourBeforeWorkshopReminderEmailState
  tenDaysBeforeWorkshopReminderEmailState
  twoDaysBeforeWorkshopReminderEmailState
  oneDayBeforeWorkshopReminderEmailState
  workshopAbsenceConfirmedEmailState
  workshopAttendanceConfirmedEmailState
}
`;

export const SdgIdWithNumericScoreOutputDeepNestingFragment = `fragment SdgIdWithNumericScoreOutputDeepNestingFragment on SdgIdWithNumericScoreOutput {
  sdgId
  numericScore
}
`;

export const OrganisationalValuesOutputDeepNestingFragment = `fragment OrganisationalValuesOutputDeepNestingFragment on OrganisationalValuesOutput {
  resilience
  leadership
  transparency
  adaptability
}
`;

export const ContributionValuesOutputDeepNestingFragment = `fragment ContributionValuesOutputDeepNestingFragment on ContributionValuesOutput {
  fairness
  stewardship
  inclusivity
  community
}
`;

export const AdvancementValuesOutputDeepNestingFragment = `fragment AdvancementValuesOutputDeepNestingFragment on AdvancementValuesOutput {
  progress
  learning
  innovation
  curiosity
}
`;

export const SocietalValuesOutputDeepNestingFragment = `fragment SocietalValuesOutputDeepNestingFragment on SocietalValuesOutput {
  collaboration
  equality
  compassion
  empowerment
}
`;

export const OrganisationalValuesNormalizedOutputDeepNestingFragment = `fragment OrganisationalValuesNormalizedOutputDeepNestingFragment on OrganisationalValuesNormalizedOutput {
  resilience
  leadership
  transparency
  adaptability
}
`;

export const ContributionValuesNormalizedOutputDeepNestingFragment = `fragment ContributionValuesNormalizedOutputDeepNestingFragment on ContributionValuesNormalizedOutput {
  fairness
  stewardship
  inclusivity
  community
}
`;

export const AdvancementValuesNormalizedOutputDeepNestingFragment = `fragment AdvancementValuesNormalizedOutputDeepNestingFragment on AdvancementValuesNormalizedOutput {
  progress
  learning
  innovation
  curiosity
}
`;

export const SocietalValuesNormalizedOutputDeepNestingFragment = `fragment SocietalValuesNormalizedOutputDeepNestingFragment on SocietalValuesNormalizedOutput {
  collaboration
  equality
  compassion
  empowerment
}
`;

export const LinkMetadataDeepNestingFragment = `fragment LinkMetadataDeepNestingFragment on LinkMetadata {
  title
  hostname
  description
  image
}
`;

export const PrincipalPrivacySettingsOutputDeepNestingFragment = `fragment PrincipalPrivacySettingsOutputDeepNestingFragment on PrincipalPrivacySettingsOutput {
  connections
}
`;

export const PrincipalValueOutputDeepNestingFragment = `fragment PrincipalValueOutputDeepNestingFragment on PrincipalValueOutput {
  heading
  description
}
`;

export const PaginatedMessageOutputFragment = `
${MessageNoNestingFragment}
fragment PaginatedMessageOutputFragment on PaginatedMessageOutput {
  nodes {
    ...MessageNoNestingFragment
  }
  offset
  totalCount
}
`;

export const MessageNotificationFragment = `
${MessageNoNestingFragment}
fragment MessageNotificationFragment on MessageNotification {
  payload {
    ...MessageNoNestingFragment
  }
}
`;

export const SdgFragment = `
${EsgCategoryNoNestingFragment}
fragment SdgFragment on Sdg {
  id
  createdAt
  updatedAt
  name
  description
  esgCategories {
    ...EsgCategoryNoNestingFragment
  }
  iconId
}
`;

export const PaginatedProfileOutputFragment = `
${ProfileNoNestingFragment}
fragment PaginatedProfileOutputFragment on PaginatedProfileOutput {
  nodes {
    ...ProfileNoNestingFragment
  }
  offset
  totalCount
}
`;

export const InsightHeroOutputFragment = `
${MediaOutputNoNestingFragment}
fragment InsightHeroOutputFragment on InsightHeroOutput {
  headline
  summary
  author
  sourceUrl
  firstPublishedAt
  heroImage {
    ...MediaOutputNoNestingFragment
  }
}
`;

export const InsightVisibilityDetailsOutputFragment = `
fragment InsightVisibilityDetailsOutputFragment on InsightVisibilityOutput {
  advisorIds
  connections
  family
  familyOwnersIds
  individual
  isPublic
  itriom
}
`;

export const InsightFullMediaBlockOutputFragment = `
${MediaOutputNoNestingFragment}
fragment InsightFullMediaBlockOutputFragment on InsightFullMediaBlockOutput {
  type
  order
  image {
    ...MediaOutputNoNestingFragment
  }
}
`;

export const InsightGalleryBlockOutputFragment = `
${MediaOutputNoNestingFragment}
fragment InsightGalleryBlockOutputFragment on InsightGalleryBlockOutput {
  type
  order
  images {
    ...MediaOutputNoNestingFragment
  }
}
`;

export const InsightLeftTextBlockOutputFragment = `
${MediaOutputNoNestingFragment}
fragment InsightLeftTextBlockOutputFragment on InsightLeftTextBlockOutput {
  type
  order
  text
  image {
    ...MediaOutputNoNestingFragment
  }
  optionalHeading
}
`;

export const InsightRightTextBlockOutputFragment = `
${MediaOutputNoNestingFragment}
fragment InsightRightTextBlockOutputFragment on InsightRightTextBlockOutput {
  type
  order
  text
  image {
    ...MediaOutputNoNestingFragment
  }
  optionalHeading
}
`;

export const InsightQuoteBlockOutputFragment = `
${ProfileNoNestingFragment}
fragment InsightQuoteBlockOutputFragment on InsightQuoteBlockOutput {
  type
  order
  text
  account {
    ...ProfileNoNestingFragment
  }
  customName
  customTitle
}
`;

export const PaginatedInsightOutputFragment = `
${InsightNoNestingFragment}
fragment PaginatedInsightOutputFragment on PaginatedInsightOutput {
  nodes {
    ...InsightNoNestingFragment
  }
  offset
  totalCount
}
`;

export const InitiativeHeroOutputFragment = `
${MediaOutputNoNestingFragment}
fragment InitiativeHeroOutputFragment on InitiativeHeroOutput {
  heroImage {
    ...MediaOutputNoNestingFragment
  }
  headline
  summary
  startDate
  locationImage {
    ...MediaOutputNoNestingFragment
  }
  leftChartImage {
    ...MediaOutputNoNestingFragment
  }
  leftChartHeadline
  leftChartSubheadline
  rightChartImage {
    ...MediaOutputNoNestingFragment
  }
  rightChartHeadline
  rightChartSubheadline
}
`;

export const InitiativeFullMediaBlockOutputFragment = `
${MediaOutputNoNestingFragment}
fragment InitiativeFullMediaBlockOutputFragment on InitiativeFullMediaBlockOutput {
  type
  order
  image {
    ...MediaOutputNoNestingFragment
  }
}
`;

export const InitiativeGalleryBlockOutputFragment = `
${MediaOutputNoNestingFragment}
fragment InitiativeGalleryBlockOutputFragment on InitiativeGalleryBlockOutput {
  type
  order
  images {
    ...MediaOutputNoNestingFragment
  }
}
`;

export const InitiativeLeftTextBlockOutputFragment = `
${MediaOutputNoNestingFragment}
fragment InitiativeLeftTextBlockOutputFragment on InitiativeLeftTextBlockOutput {
  type
  order
  text
  image {
    ...MediaOutputNoNestingFragment
  }
  optionalHeading
}
`;

export const InitiativeRightTextBlockOutputFragment = `
${MediaOutputNoNestingFragment}
fragment InitiativeRightTextBlockOutputFragment on InitiativeRightTextBlockOutput {
  type
  order
  text
  image {
    ...MediaOutputNoNestingFragment
  }
  optionalHeading
}
`;

export const PaginatedInitiativeOutputFragment = `
${InitiativeOutputNoNestingFragment}
fragment PaginatedInitiativeOutputFragment on PaginatedInitiativeOutput {
  nodes {
    ...InitiativeOutputNoNestingFragment
  }
  offset
  totalCount
}
`;

export const FamilyValuesOutputFragment = `
${FamilyValueOutputNoNestingFragment}
fragment FamilyValuesOutputFragment on FamilyValuesOutput {
  organisationalValue {
    ...FamilyValueOutputNoNestingFragment
  }
  contributionValue {
    ...FamilyValueOutputNoNestingFragment
  }
  advancementValue {
    ...FamilyValueOutputNoNestingFragment
  }
  societalValue {
    ...FamilyValueOutputNoNestingFragment
  }
}
`;

export const PaginatedFamilyOutputFragment = `
${FamilyNoNestingFragment}
fragment PaginatedFamilyOutputFragment on PaginatedFamilyOutput {
  nodes {
    ...FamilyNoNestingFragment
  }
  offset
  totalCount
}
`;

export const JoinOutputFragment = `
${MediaOutputNoNestingFragment}
fragment JoinOutputFragment on JoinOutput {
  image {
    ...MediaOutputNoNestingFragment
  }
  name
  description
  email
  isEnabled
}
`;

export const HelpRequestOutputFragment = `
${MediaOutputNoNestingFragment}
fragment HelpRequestOutputFragment on HelpRequestOutput {
  image {
    ...MediaOutputNoNestingFragment
  }
  name
  description
  email
  isEnabled
}
`;

export const LocationImageOutputFragment = `
${MediaOutputNoNestingFragment}
fragment LocationImageOutputFragment on LocationImageOutput {
  image {
    ...MediaOutputNoNestingFragment
  }
  description
}
`;

export const EventHeroFragment = `
${MediaOutputNoNestingFragment}
fragment EventHeroFragment on EventHero {
  name
  subtitle
  summary
  description
  heroImage {
    ...MediaOutputNoNestingFragment
  }
  heroVideo {
    ...MediaOutputNoNestingFragment
  }
  isPublic
  preferredHeroMediaToDisplay
  eventUrl
}
`;

export const EventKeySpeakerOutputFragment = `
${MediaOutputNoNestingFragment}
fragment EventKeySpeakerOutputFragment on EventKeySpeakerOutput {
  keynoteSpeakerHeading
  name
  title
  about
  image {
    ...MediaOutputNoNestingFragment
  }
}
`;

export const TopicFragment = `
${MediaOutputNoNestingFragment}
fragment TopicFragment on Topic {
  description
  heroImage {
    ...MediaOutputNoNestingFragment
  }
}
`;

export const UltimateGoalOutputFragment = `
${MediaOutputNoNestingFragment}
fragment UltimateGoalOutputFragment on UltimateGoalOutput {
  title
  description
  image {
    ...MediaOutputNoNestingFragment
  }
  sdIcon
}
`;

export const EventPaginatedOutputFragment = `
${EventNoNestingFragment}
fragment EventPaginatedOutputFragment on EventPaginatedOutput {
  nodes {
    ...EventNoNestingFragment
  }
  offset
  totalCount
}
`;

export const AdvisorAboutOutputFragment = `
${MediaOutputNoNestingFragment}
fragment AdvisorAboutOutputFragment on AdvisorAboutOutput {
  content
  images {
    ...MediaOutputNoNestingFragment
  }
}
`;

export const AdvisorHeroOutputFragment = `
${MediaOutputNoNestingFragment}
fragment AdvisorHeroOutputFragment on AdvisorHeroOutput {
  advisorAccountId
  headline
  mission
  heroImage {
    ...MediaOutputNoNestingFragment
  }
}
`;

export const AdvisorLocationOutputFragment = `
${MediaOutputNoNestingFragment}
fragment AdvisorLocationOutputFragment on AdvisorLocationOutput {
  image {
    ...MediaOutputNoNestingFragment
  }
  name
  description
}
`;

export const AdvisorAdvocacyOutputFragment = `
${MediaOutputNoNestingFragment}
fragment AdvisorAdvocacyOutputFragment on AdvisorAdvocacyOutput {
  heading
  content
  images {
    ...MediaOutputNoNestingFragment
  }
  heroImage {
    ...MediaOutputNoNestingFragment
  }
  heroVideo {
    ...MediaOutputNoNestingFragment
  }
  preferredHeroMediaToDisplay
}
`;

export const PaginatedAdvisorOutputFragment = `
${AdvisorOutputNoNestingFragment}
fragment PaginatedAdvisorOutputFragment on PaginatedAdvisorOutput {
  nodes {
    ...AdvisorOutputNoNestingFragment
  }
  offset
  totalCount
}
`;

export const PaginatedExtendedProfileOutputFragment = `
${ExtendedProfileOutputNoNestingFragment}
fragment PaginatedExtendedProfileOutputFragment on PaginatedExtendedProfileOutput {
  nodes {
    ...ExtendedProfileOutputNoNestingFragment
  }
  offset
  totalCount
}
`;

export const ConnectionInvitationFragment = `
${ProfileNoNestingFragment}
fragment ConnectionInvitationFragment on ConnectionInvitation {
  id
  senderId
  recipientId
  createdAt
  sender {
    ...ProfileNoNestingFragment
  }
  recipient {
    ...ProfileNoNestingFragment
  }
}
`;

export const PaginatedConversationOutputFragment = `
${ConversationNoNestingFragment}
fragment PaginatedConversationOutputFragment on PaginatedConversationOutput {
  nodes {
    ...ConversationNoNestingFragment
  }
  offset
  totalCount
}
`;

export const SpeakerOutputFragment = `
${ProfileNoNestingFragment}
fragment SpeakerOutputFragment on SpeakerOutput {
  isKeySpeaker
  profile {
    ...ProfileNoNestingFragment
  }
}
`;

export const TriggeredCtaOutputFragment = `
${ProfileNoNestingFragment}
fragment TriggeredCtaOutputFragment on TriggeredCtaOutput {
  id
  createdAt
  updatedAt
  content
  profile {
    ...ProfileNoNestingFragment
  }
  createdBy
}
`;

export const FamilyCharterValuesOutputFragment = `
${FamilyCharterValueOutputNoNestingFragment}
fragment FamilyCharterValuesOutputFragment on FamilyCharterValuesOutput {
  organisationalValue {
    ...FamilyCharterValueOutputNoNestingFragment
  }
  contributionValue {
    ...FamilyCharterValueOutputNoNestingFragment
  }
  advancementValue {
    ...FamilyCharterValueOutputNoNestingFragment
  }
  societalValue {
    ...FamilyCharterValueOutputNoNestingFragment
  }
}
`;

export const FamilyCharterHeroOutputFragment = `
${MediaOutputNoNestingFragment}
fragment FamilyCharterHeroOutputFragment on FamilyCharterHeroOutput {
  familyId
  purpose
  heading
  preferredHeroMediaToDisplay
  heroImage {
    ...MediaOutputNoNestingFragment
  }
  heroVideo {
    ...MediaOutputNoNestingFragment
  }
}
`;

export const FamilyCharterGoalInitiativeOutputFragment = `
${InitiativeOutputNoNestingFragment}
fragment FamilyCharterGoalInitiativeOutputFragment on FamilyCharterGoalInitiativeOutput {
  id
  initiativeId
  initiative {
    ...InitiativeOutputNoNestingFragment
  }
}
`;

export const PaginatedFamilyCharterOutputFragment = `
${FamilyCharterNoNestingFragment}
fragment PaginatedFamilyCharterOutputFragment on PaginatedFamilyCharterOutput {
  nodes {
    ...FamilyCharterNoNestingFragment
  }
  offset
  totalCount
}
`;

export const FamilyMemberRelationOutputFragment = `
${ProfileNoNestingFragment}
fragment FamilyMemberRelationOutputFragment on FamilyMemberRelationOutput {
  familyMemberId
  memberAccount {
    ...ProfileNoNestingFragment
  }
  relatedFamilyMemberId
  relatedMemberAccount {
    ...ProfileNoNestingFragment
  }
  relationType
}
`;

export const PaginatedPostOutputFragment = `
${PostNoNestingFragment}
fragment PaginatedPostOutputFragment on PaginatedPostOutput {
  nodes {
    ...PostNoNestingFragment
  }
  offset
  totalCount
}
`;

export const PaginatedGroupOutputFragment = `
${GroupNoNestingFragment}
fragment PaginatedGroupOutputFragment on PaginatedGroupOutput {
  nodes {
    ...GroupNoNestingFragment
  }
  offset
  totalCount
}
`;

export const PaginatedMembersToAddOutputFragment = `
${MemberToAddOutputNoNestingFragment}
fragment PaginatedMembersToAddOutputFragment on PaginatedMembersToAddOutput {
  nodes {
    ...MemberToAddOutputNoNestingFragment
  }
  offset
  totalCount
}
`;

export const OnboardManagementMeetingInvitationOutputFragment = `
${TimezoneDateOutputNoNestingFragment}
fragment OnboardManagementMeetingInvitationOutputFragment on OnboardManagementMeetingInvitationOutput {
  date {
    ...TimezoneDateOutputNoNestingFragment
  }
  location
  meetingLink
  meetingId
  meetingPasscode
}
`;

export const FamilyOnboardMemberDetailsOutputFragment = `
${FamilyOnboardMemberActionsOutputNoNestingFragment}
fragment FamilyOnboardMemberDetailsOutputFragment on FamilyOnboardMemberDetailsOutput {
  accountId
  isHeadOfFamily
  firstName
  lastName
  attendanceStatus
  sdgConfiguratorStatus
  valueConfiguratorStatus
  actions {
    ...FamilyOnboardMemberActionsOutputNoNestingFragment
  }
}
`;

export const SdgConfiguratorOutputFragment = `
${SdgIdWithNumericScoreOutputNoNestingFragment}
fragment SdgConfiguratorOutputFragment on SdgConfiguratorOutput {
  sdgIdsWithNumericScores {
    ...SdgIdWithNumericScoreOutputNoNestingFragment
  }
}
`;

export const FamilySdgConfiguratorOutputFragment = `
${SdgIdWithNumericScoreOutputNoNestingFragment}
fragment FamilySdgConfiguratorOutputFragment on FamilySdgConfiguratorOutput {
  sdgIdsWithNumericScores {
    ...SdgIdWithNumericScoreOutputNoNestingFragment
  }
}
`;

export const FamilyValuesWithAvailableSdgOutputFragment = `
${SdgOutputNoNestingFragment}
fragment FamilyValuesWithAvailableSdgOutputFragment on FamilyValuesWithAvailableSdgOutput {
  id
  createdAt
  updatedAt
  name
  category
  sdg {
    ...SdgOutputNoNestingFragment
  }
}
`;

export const OrganisationalValuesCategoriesOutputFragment = `
${OrganisationalValuesOutputNoNestingFragment}
fragment OrganisationalValuesCategoriesOutputFragment on OrganisationalValuesCategoriesOutput {
  importance {
    ...OrganisationalValuesOutputNoNestingFragment
  }
  relevance {
    ...OrganisationalValuesOutputNoNestingFragment
  }
}
`;

export const ContributionValuesCategoriesOutputFragment = `
${ContributionValuesOutputNoNestingFragment}
fragment ContributionValuesCategoriesOutputFragment on ContributionValuesCategoriesOutput {
  importance {
    ...ContributionValuesOutputNoNestingFragment
  }
  relevance {
    ...ContributionValuesOutputNoNestingFragment
  }
}
`;

export const AdvancementValuesCategoriesOutputFragment = `
${AdvancementValuesOutputNoNestingFragment}
fragment AdvancementValuesCategoriesOutputFragment on AdvancementValuesCategoriesOutput {
  importance {
    ...AdvancementValuesOutputNoNestingFragment
  }
  relevance {
    ...AdvancementValuesOutputNoNestingFragment
  }
}
`;

export const SocietalValuesCategoriesOutputFragment = `
${SocietalValuesOutputNoNestingFragment}
fragment SocietalValuesCategoriesOutputFragment on SocietalValuesCategoriesOutput {
  importance {
    ...SocietalValuesOutputNoNestingFragment
  }
  relevance {
    ...SocietalValuesOutputNoNestingFragment
  }
}
`;

export const PrincipalAboutOutputFragment = `
${MediaOutputNoNestingFragment}
fragment PrincipalAboutOutputFragment on PrincipalAboutOutput {
  content
  images {
    ...MediaOutputNoNestingFragment
  }
}
`;

export const PaginatedTagOutputFragment = `
${TagOutputNoNestingFragment}
fragment PaginatedTagOutputFragment on PaginatedTagOutput {
  nodes {
    ...TagOutputNoNestingFragment
  }
  offset
  totalCount
}
`;

export const PaginatedMessageOutputDeepNestingFragment = `
${MessageDeepNestingFragment}
fragment PaginatedMessageOutputDeepNestingFragment on PaginatedMessageOutput {
  nodes {
    ...MessageDeepNestingFragment
  }
  offset
  totalCount
}
`;

export const MessageNotificationDeepNestingFragment = `
${MessageDeepNestingFragment}
fragment MessageNotificationDeepNestingFragment on MessageNotification {
  payload {
    ...MessageDeepNestingFragment
  }
}
`;

export const SdgDeepNestingFragment = `
${EsgCategoryDeepNestingFragment}
fragment SdgDeepNestingFragment on Sdg {
  id
  createdAt
  updatedAt
  name
  description
  esgCategories {
    ...EsgCategoryDeepNestingFragment
  }
  iconId
}
`;

export const InsightHeroOutputDeepNestingFragment = `
${MediaOutputDeepNestingFragment}
fragment InsightHeroOutputDeepNestingFragment on InsightHeroOutput {
  headline
  summary
  author
  sourceUrl
  firstPublishedAt
  heroImage {
    ...MediaOutputDeepNestingFragment
  }
}
`;

export const InsightFullMediaBlockOutputDeepNestingFragment = `
${MediaOutputDeepNestingFragment}
fragment InsightFullMediaBlockOutputDeepNestingFragment on InsightFullMediaBlockOutput {
  type
  order
  image {
    ...MediaOutputDeepNestingFragment
  }
}
`;

export const InsightGalleryBlockOutputDeepNestingFragment = `
${MediaOutputDeepNestingFragment}
fragment InsightGalleryBlockOutputDeepNestingFragment on InsightGalleryBlockOutput {
  type
  order
  images {
    ...MediaOutputDeepNestingFragment
  }
}
`;

export const InsightLeftTextBlockOutputDeepNestingFragment = `
${MediaOutputDeepNestingFragment}
fragment InsightLeftTextBlockOutputDeepNestingFragment on InsightLeftTextBlockOutput {
  type
  order
  text
  image {
    ...MediaOutputDeepNestingFragment
  }
  optionalHeading
}
`;

export const InsightRightTextBlockOutputDeepNestingFragment = `
${MediaOutputDeepNestingFragment}
fragment InsightRightTextBlockOutputDeepNestingFragment on InsightRightTextBlockOutput {
  type
  order
  text
  image {
    ...MediaOutputDeepNestingFragment
  }
  optionalHeading
}
`;

export const InitiativeHeroOutputDeepNestingFragment = `
${MediaOutputDeepNestingFragment}
fragment InitiativeHeroOutputDeepNestingFragment on InitiativeHeroOutput {
  heroImage {
    ...MediaOutputDeepNestingFragment
  }
  headline
  summary
  startDate
  locationImage {
    ...MediaOutputDeepNestingFragment
  }
  leftChartImage {
    ...MediaOutputDeepNestingFragment
  }
  leftChartHeadline
  leftChartSubheadline
  rightChartImage {
    ...MediaOutputDeepNestingFragment
  }
  rightChartHeadline
  rightChartSubheadline
}
`;

export const InitiativeFullMediaBlockOutputDeepNestingFragment = `
${MediaOutputDeepNestingFragment}
fragment InitiativeFullMediaBlockOutputDeepNestingFragment on InitiativeFullMediaBlockOutput {
  type
  order
  image {
    ...MediaOutputDeepNestingFragment
  }
}
`;

export const InitiativeGalleryBlockOutputDeepNestingFragment = `
${MediaOutputDeepNestingFragment}
fragment InitiativeGalleryBlockOutputDeepNestingFragment on InitiativeGalleryBlockOutput {
  type
  order
  images {
    ...MediaOutputDeepNestingFragment
  }
}
`;

export const InitiativeLeftTextBlockOutputDeepNestingFragment = `
${MediaOutputDeepNestingFragment}
fragment InitiativeLeftTextBlockOutputDeepNestingFragment on InitiativeLeftTextBlockOutput {
  type
  order
  text
  image {
    ...MediaOutputDeepNestingFragment
  }
  optionalHeading
}
`;

export const InitiativeRightTextBlockOutputDeepNestingFragment = `
${MediaOutputDeepNestingFragment}
fragment InitiativeRightTextBlockOutputDeepNestingFragment on InitiativeRightTextBlockOutput {
  type
  order
  text
  image {
    ...MediaOutputDeepNestingFragment
  }
  optionalHeading
}
`;

export const FamilyValuesOutputDeepNestingFragment = `
${FamilyValueOutputDeepNestingFragment}
fragment FamilyValuesOutputDeepNestingFragment on FamilyValuesOutput {
  organisationalValue {
    ...FamilyValueOutputDeepNestingFragment
  }
  contributionValue {
    ...FamilyValueOutputDeepNestingFragment
  }
  advancementValue {
    ...FamilyValueOutputDeepNestingFragment
  }
  societalValue {
    ...FamilyValueOutputDeepNestingFragment
  }
}
`;

export const JoinOutputDeepNestingFragment = `
${MediaOutputDeepNestingFragment}
fragment JoinOutputDeepNestingFragment on JoinOutput {
  image {
    ...MediaOutputDeepNestingFragment
  }
  name
  description
  email
  isEnabled
}
`;

export const HelpRequestOutputDeepNestingFragment = `
${MediaOutputDeepNestingFragment}
fragment HelpRequestOutputDeepNestingFragment on HelpRequestOutput {
  image {
    ...MediaOutputDeepNestingFragment
  }
  name
  description
  email
  isEnabled
}
`;

export const LocationImageOutputDeepNestingFragment = `
${MediaOutputDeepNestingFragment}
fragment LocationImageOutputDeepNestingFragment on LocationImageOutput {
  image {
    ...MediaOutputDeepNestingFragment
  }
  description
}
`;

export const EventHeroDeepNestingFragment = `
${MediaOutputDeepNestingFragment}
fragment EventHeroDeepNestingFragment on EventHero {
  name
  subtitle
  summary
  description
  heroImage {
    ...MediaOutputDeepNestingFragment
  }
  heroVideo {
    ...MediaOutputDeepNestingFragment
  }
  isPublic
  preferredHeroMediaToDisplay
  eventUrl
}
`;

export const EventKeySpeakerOutputDeepNestingFragment = `
${MediaOutputDeepNestingFragment}
fragment EventKeySpeakerOutputDeepNestingFragment on EventKeySpeakerOutput {
  keynoteSpeakerHeading
  name
  title
  about
  image {
    ...MediaOutputDeepNestingFragment
  }
}
`;

export const TopicDeepNestingFragment = `
${MediaOutputDeepNestingFragment}
fragment TopicDeepNestingFragment on Topic {
  description
  heroImage {
    ...MediaOutputDeepNestingFragment
  }
}
`;

export const UltimateGoalOutputDeepNestingFragment = `
${MediaOutputDeepNestingFragment}
fragment UltimateGoalOutputDeepNestingFragment on UltimateGoalOutput {
  title
  description
  image {
    ...MediaOutputDeepNestingFragment
  }
  sdIcon
}
`;

export const AdvisorAboutOutputDeepNestingFragment = `
${MediaOutputDeepNestingFragment}
fragment AdvisorAboutOutputDeepNestingFragment on AdvisorAboutOutput {
  content
  images {
    ...MediaOutputDeepNestingFragment
  }
}
`;

export const AdvisorHeroOutputDeepNestingFragment = `
${MediaOutputDeepNestingFragment}
fragment AdvisorHeroOutputDeepNestingFragment on AdvisorHeroOutput {
  advisorAccountId
  headline
  mission
  heroImage {
    ...MediaOutputDeepNestingFragment
  }
}
`;

export const AdvisorLocationOutputDeepNestingFragment = `
${MediaOutputDeepNestingFragment}
fragment AdvisorLocationOutputDeepNestingFragment on AdvisorLocationOutput {
  image {
    ...MediaOutputDeepNestingFragment
  }
  name
  description
}
`;

export const AdvisorAdvocacyOutputDeepNestingFragment = `
${MediaOutputDeepNestingFragment}
fragment AdvisorAdvocacyOutputDeepNestingFragment on AdvisorAdvocacyOutput {
  heading
  content
  images {
    ...MediaOutputDeepNestingFragment
  }
  heroImage {
    ...MediaOutputDeepNestingFragment
  }
  heroVideo {
    ...MediaOutputDeepNestingFragment
  }
  preferredHeroMediaToDisplay
}
`;

export const FamilyCharterValuesOutputDeepNestingFragment = `
${FamilyCharterValueOutputDeepNestingFragment}
fragment FamilyCharterValuesOutputDeepNestingFragment on FamilyCharterValuesOutput {
  organisationalValue {
    ...FamilyCharterValueOutputDeepNestingFragment
  }
  contributionValue {
    ...FamilyCharterValueOutputDeepNestingFragment
  }
  advancementValue {
    ...FamilyCharterValueOutputDeepNestingFragment
  }
  societalValue {
    ...FamilyCharterValueOutputDeepNestingFragment
  }
}
`;

export const FamilyCharterHeroOutputDeepNestingFragment = `
${MediaOutputDeepNestingFragment}
fragment FamilyCharterHeroOutputDeepNestingFragment on FamilyCharterHeroOutput {
  familyId
  purpose
  heading
  preferredHeroMediaToDisplay
  heroImage {
    ...MediaOutputDeepNestingFragment
  }
  heroVideo {
    ...MediaOutputDeepNestingFragment
  }
}
`;

export const OnboardManagementMeetingInvitationOutputDeepNestingFragment = `
${TimezoneDateOutputDeepNestingFragment}
fragment OnboardManagementMeetingInvitationOutputDeepNestingFragment on OnboardManagementMeetingInvitationOutput {
  date {
    ...TimezoneDateOutputDeepNestingFragment
  }
  location
  meetingLink
  meetingId
  meetingPasscode
}
`;

export const FamilyOnboardMemberDetailsOutputDeepNestingFragment = `
${FamilyOnboardMemberActionsOutputDeepNestingFragment}
fragment FamilyOnboardMemberDetailsOutputDeepNestingFragment on FamilyOnboardMemberDetailsOutput {
  accountId
  isHeadOfFamily
  firstName
  lastName
  attendanceStatus
  sdgConfiguratorStatus
  valueConfiguratorStatus
  actions {
    ...FamilyOnboardMemberActionsOutputDeepNestingFragment
  }
}
`;

export const SdgConfiguratorOutputDeepNestingFragment = `
${SdgIdWithNumericScoreOutputDeepNestingFragment}
fragment SdgConfiguratorOutputDeepNestingFragment on SdgConfiguratorOutput {
  sdgIdsWithNumericScores {
    ...SdgIdWithNumericScoreOutputDeepNestingFragment
  }
}
`;

export const FamilySdgConfiguratorOutputDeepNestingFragment = `
${SdgIdWithNumericScoreOutputDeepNestingFragment}
fragment FamilySdgConfiguratorOutputDeepNestingFragment on FamilySdgConfiguratorOutput {
  sdgIdsWithNumericScores {
    ...SdgIdWithNumericScoreOutputDeepNestingFragment
  }
}
`;

export const FamilyValuesWithAvailableSdgOutputDeepNestingFragment = `
${SdgOutputDeepNestingFragment}
fragment FamilyValuesWithAvailableSdgOutputDeepNestingFragment on FamilyValuesWithAvailableSdgOutput {
  id
  createdAt
  updatedAt
  name
  category
  sdg {
    ...SdgOutputDeepNestingFragment
  }
}
`;

export const OrganisationalValuesCategoriesOutputDeepNestingFragment = `
${OrganisationalValuesOutputDeepNestingFragment}
fragment OrganisationalValuesCategoriesOutputDeepNestingFragment on OrganisationalValuesCategoriesOutput {
  importance {
    ...OrganisationalValuesOutputDeepNestingFragment
  }
  relevance {
    ...OrganisationalValuesOutputDeepNestingFragment
  }
}
`;

export const ContributionValuesCategoriesOutputDeepNestingFragment = `
${ContributionValuesOutputDeepNestingFragment}
fragment ContributionValuesCategoriesOutputDeepNestingFragment on ContributionValuesCategoriesOutput {
  importance {
    ...ContributionValuesOutputDeepNestingFragment
  }
  relevance {
    ...ContributionValuesOutputDeepNestingFragment
  }
}
`;

export const AdvancementValuesCategoriesOutputDeepNestingFragment = `
${AdvancementValuesOutputDeepNestingFragment}
fragment AdvancementValuesCategoriesOutputDeepNestingFragment on AdvancementValuesCategoriesOutput {
  importance {
    ...AdvancementValuesOutputDeepNestingFragment
  }
  relevance {
    ...AdvancementValuesOutputDeepNestingFragment
  }
}
`;

export const SocietalValuesCategoriesOutputDeepNestingFragment = `
${SocietalValuesOutputDeepNestingFragment}
fragment SocietalValuesCategoriesOutputDeepNestingFragment on SocietalValuesCategoriesOutput {
  importance {
    ...SocietalValuesOutputDeepNestingFragment
  }
  relevance {
    ...SocietalValuesOutputDeepNestingFragment
  }
}
`;

export const PrincipalAboutOutputDeepNestingFragment = `
${MediaOutputDeepNestingFragment}
fragment PrincipalAboutOutputDeepNestingFragment on PrincipalAboutOutput {
  content
  images {
    ...MediaOutputDeepNestingFragment
  }
}
`;

export const PaginatedTagOutputDeepNestingFragment = `
${TagOutputDeepNestingFragment}
fragment PaginatedTagOutputDeepNestingFragment on PaginatedTagOutput {
  nodes {
    ...TagOutputDeepNestingFragment
  }
  offset
  totalCount
}
`;

export const ConnectionSentNotificationOutputFragment = `
${ConnectionSentMetadataNoNestingFragment}
${ProfileNoNestingFragment}
fragment ConnectionSentNotificationOutputFragment on ConnectionSentNotificationOutput {
  id
  createdAt
  type
  isRead
  metadata {
    ...ConnectionSentMetadataNoNestingFragment
  }
  category
  sender {
    ...ProfileNoNestingFragment
  }
}
`;

export const ConnectionAcceptedNotificationOutputFragment = `
${ConnectionAcceptedMetadataNoNestingFragment}
${ProfileNoNestingFragment}
fragment ConnectionAcceptedNotificationOutputFragment on ConnectionAcceptedNotificationOutput {
  id
  createdAt
  type
  isRead
  metadata {
    ...ConnectionAcceptedMetadataNoNestingFragment
  }
  category
  sender {
    ...ProfileNoNestingFragment
  }
}
`;

export const GroupInvitationSentNotificationOutputFragment = `
${GroupInvitationSentMetadataNoNestingFragment}
${ProfileNoNestingFragment}
fragment GroupInvitationSentNotificationOutputFragment on GroupInvitationSentNotificationOutput {
  id
  createdAt
  type
  isRead
  metadata {
    ...GroupInvitationSentMetadataNoNestingFragment
  }
  category
  sender {
    ...ProfileNoNestingFragment
  }
}
`;

export const GroupInvitationAcceptedNotificationOutputFragment = `
${GroupInvitationAcceptedMetadataNoNestingFragment}
${ProfileNoNestingFragment}
fragment GroupInvitationAcceptedNotificationOutputFragment on GroupInvitationAcceptedNotificationOutput {
  id
  createdAt
  type
  isRead
  metadata {
    ...GroupInvitationAcceptedMetadataNoNestingFragment
  }
  category
  sender {
    ...ProfileNoNestingFragment
  }
}
`;

export const MessageSentNotificationOutputFragment = `
${MessageSentMetadataNoNestingFragment}
${ProfileNoNestingFragment}
fragment MessageSentNotificationOutputFragment on MessageSentNotificationOutput {
  id
  createdAt
  type
  isRead
  metadata {
    ...MessageSentMetadataNoNestingFragment
  }
  category
  sender {
    ...ProfileNoNestingFragment
  }
}
`;

export const LibraryResourceSharedOutputFragment = `
${ProfileNoNestingFragment}
fragment LibraryResourceSharedOutputFragment on LibraryResourceSharedOutput {
  id
  createdAt
  type
  isRead
  metadata {
    accountId
    resourceId
  }
  category
  sender {
    ...ProfileNoNestingFragment
  }
}
`;

export const ContentDeletedByAdminOutputFragment = `
${ContentDeletedByAdminMetadataNoNestingFragment}
${ProfileNoNestingFragment}
fragment ContentDeletedByAdminOutputFragment on ContentDeletedByAdminOutput {
  id
  createdAt
  type
  isRead
  metadata {
    ...ContentDeletedByAdminMetadataNoNestingFragment
  }
  category
  sender {
    ...ProfileNoNestingFragment
  }
}
`;

export const ItriomTagsV2OutputFragment = `
${SdgOutputNoNestingFragment}
${TagOutputNoNestingFragment}
fragment ItriomTagsV2OutputFragment on ItriomTagsV2Output {
  esgFocus
  sdGoalTag {
    ...SdgOutputNoNestingFragment
  }
  countryFocusTag {
    ...TagOutputNoNestingFragment
  }
}
`;

export const ObjectiveFragment = `
${MediaOutputNoNestingFragment}
${InitiativeOutputNoNestingFragment}
fragment ObjectiveFragment on Objective {
  id
  createdAt
  updatedAt
  name
  description
  image {
    ...MediaOutputNoNestingFragment
  }
  initiatives {
    ...InitiativeOutputNoNestingFragment
  }
}
`;

export const CallToActionOutputFragment = `
${JoinOutputNoNestingFragment}
${HelpRequestOutputNoNestingFragment}
fragment CallToActionOutputFragment on CallToActionOutput {
  joinInfo {
    ...JoinOutputNoNestingFragment
  }
  helpRequest {
    ...HelpRequestOutputNoNestingFragment
  }
}
`;

export const SponsorFragment = `
${ProfileNoNestingFragment}
${MediaOutputNoNestingFragment}
fragment SponsorFragment on Sponsor {
  name
  title
  isCompany
  showName
  profile {
    ...ProfileNoNestingFragment
  }
  image {
    ...MediaOutputNoNestingFragment
  }
}
`;

export const SearchConnectionResultOutputFragment = `
${ProfileNoNestingFragment}
${SearchConnectionHighlightsOutputNoNestingFragment}
fragment SearchConnectionResultOutputFragment on SearchConnectionResultOutput {
  object {
    ...ProfileNoNestingFragment
  }
  highlights {
    ...SearchConnectionHighlightsOutputNoNestingFragment
  }
}
`;

export const SearchInsightResultOutputFragment = `
${InsightNoNestingFragment}
${SearchInsightHighlightsOutputNoNestingFragment}
fragment SearchInsightResultOutputFragment on SearchInsightResultOutput {
  object {
    ...InsightNoNestingFragment
  }
  highlights {
    ...SearchInsightHighlightsOutputNoNestingFragment
  }
}
`;

export const SearchInitiativeResultOutputFragment = `
${InitiativeOutputNoNestingFragment}
${SearchInitiativeHighlightsOutputNoNestingFragment}
fragment SearchInitiativeResultOutputFragment on SearchInitiativeResultOutput {
  object {
    ...InitiativeOutputNoNestingFragment
  }
  highlights {
    ...SearchInitiativeHighlightsOutputNoNestingFragment
  }
}
`;

export const SearchEventResultOutputFragment = `
${EventNoNestingFragment}
${SearchEventHighlightsOutputNoNestingFragment}
fragment SearchEventResultOutputFragment on SearchEventResultOutput {
  object {
    ...EventNoNestingFragment
  }
  highlights {
    ...SearchEventHighlightsOutputNoNestingFragment
  }
}
`;

export const AccountFragment = `
${MediaOutputNoNestingFragment}
${ProfileNoNestingFragment}
fragment AccountFragment on Account {
  id
  createdAt
  updatedAt
  email
  avatarImage {
    ...MediaOutputNoNestingFragment
  }
  heroImage {
    ...MediaOutputNoNestingFragment
  }
  honorific
  firstName
  lastName
  title
  description
  role
  status
  onboardingStatus
  isFamilyAdmin
  familyId
  connections {
    ...ProfileNoNestingFragment
  }
}
`;

export const ItriomTagsOutputFragment = `
${SdgOutputNoNestingFragment}
${TagOutputNoNestingFragment}
fragment ItriomTagsOutputFragment on ItriomTagsOutput {
  sdGoalsTags {
    ...SdgOutputNoNestingFragment
  }
  countryFocusTags {
    ...TagOutputNoNestingFragment
  }
  environmentFocusTags {
    ...TagOutputNoNestingFragment
  }
  socialFocusTags {
    ...TagOutputNoNestingFragment
  }
  governanceFocusTags {
    ...TagOutputNoNestingFragment
  }
}
`;

export const ConversationFragment = `
${ProfileNoNestingFragment}
${MessageNoNestingFragment}
fragment ConversationFragment on Conversation {
  id
  createdAt
  updatedAt
  isMember
  members {
    ...ProfileNoNestingFragment
  }
  messages {
    ...MessageNoNestingFragment
  }
  updatedBy
  createdBy
}
`;

export const AgendaItemFragment = `
${MediaOutputNoNestingFragment}
${SpeakerOutputNoNestingFragment}
fragment AgendaItemFragment on AgendaItem {
  heading
  description
  image {
    ...MediaOutputNoNestingFragment
  }
  startTime
  endTime
  speakers {
    ...SpeakerOutputNoNestingFragment
  }
}
`;

export const AgendaFragment = `
${MediaOutputNoNestingFragment}
${AgendaItemNoNestingFragment}
fragment AgendaFragment on Agenda {
  heading
  description
  image {
    ...MediaOutputNoNestingFragment
  }
  agendaItems {
    ...AgendaItemNoNestingFragment
  }
}
`;

export const OrganiserFragment = `
${MediaOutputNoNestingFragment}
${ProfileNoNestingFragment}
fragment OrganiserFragment on Organiser {
  name
  title
  image {
    ...MediaOutputNoNestingFragment
  }
  profile {
    ...ProfileNoNestingFragment
  }
}
`;

export const EventVisibilityDetailsFragment = `
fragment EventVisibilityDetailsFragment on EventVisibilityOutput {
  connections
  externalUrl
  family
  familyOwnersIds
  isPublic
  itriom
}
`;

export const PrincipalHeroOutputFragment = `
${MediaOutputNoNestingFragment}
${ProfileNoNestingFragment}
fragment PrincipalHeroOutputFragment on PrincipalHeroOutput {
  banner {
    ...MediaOutputNoNestingFragment
  }
  avatarRound {
    ...MediaOutputNoNestingFragment
  }
  avatarSquare {
    ...MediaOutputNoNestingFragment
  }
  purpose
  title
  profile {
    ...ProfileNoNestingFragment
  }
}
`;

export const ItriomTagsV2OutputDeepNestingFragment = `
${SdgOutputDeepNestingFragment}
${TagOutputDeepNestingFragment}
fragment ItriomTagsV2OutputDeepNestingFragment on ItriomTagsV2Output {
  esgFocus
  sdGoalTag {
    ...SdgOutputDeepNestingFragment
  }
  countryFocusTag {
    ...TagOutputDeepNestingFragment
  }
}
`;

export const CallToActionOutputDeepNestingFragment = `
${JoinOutputDeepNestingFragment}
${HelpRequestOutputDeepNestingFragment}
fragment CallToActionOutputDeepNestingFragment on CallToActionOutput {
  joinInfo {
    ...JoinOutputDeepNestingFragment
  }
  helpRequest {
    ...HelpRequestOutputDeepNestingFragment
  }
}
`;

export const ItriomTagsOutputDeepNestingFragment = `
${SdgOutputDeepNestingFragment}
${TagOutputDeepNestingFragment}
fragment ItriomTagsOutputDeepNestingFragment on ItriomTagsOutput {
  sdGoalsTags {
    ...SdgOutputDeepNestingFragment
  }
  countryFocusTags {
    ...TagOutputDeepNestingFragment
  }
  environmentFocusTags {
    ...TagOutputDeepNestingFragment
  }
  socialFocusTags {
    ...TagOutputDeepNestingFragment
  }
  governanceFocusTags {
    ...TagOutputDeepNestingFragment
  }
}
`;

export const ProfileFragment = `
${MediaOutputNoNestingFragment}
${FamilyNoNestingFragment}
${PrincipalOutputNoNestingFragment}
fragment ProfileFragment on Profile {
  id
  avatarImage {
    ...MediaOutputNoNestingFragment
  }
  heroImage {
    ...MediaOutputNoNestingFragment
  }
  firstName
  lastName
  title
  description
  isConnected
  family {
    ...FamilyNoNestingFragment
  }
  createdAt
  updatedAt
  status
  principal {
    ...PrincipalOutputNoNestingFragment
  }
  connectionState
  canBeInvited
  honorific
  role
}
`;

export const GoalFragment = `
${MediaOutputNoNestingFragment}
${SdgNoNestingFragment}
${ObjectiveNoNestingFragment}
fragment GoalFragment on Goal {
  id
  createdAt
  updatedAt
  number
  name
  description
  shortDescription
  image {
    ...MediaOutputNoNestingFragment
  }
  sdg {
    ...SdgNoNestingFragment
  }
  objectives {
    ...ObjectiveNoNestingFragment
  }
}
`;

export const LocationFragment = `
${MediaOutputNoNestingFragment}
${LocationImageOutputNoNestingFragment}
${LocationButtonOutputNoNestingFragment}
fragment LocationFragment on Location {
  name
  description
  addressLine1
  addressLine2
  city
  postcode
  state
  country
  isGalleryEnabled
  telephone
  email
  heroImage {
    ...MediaOutputNoNestingFragment
  }
  images {
    ...LocationImageOutputNoNestingFragment
  }
  button {
    ...LocationButtonOutputNoNestingFragment
  }
}
`;

export const EventBasicInfoFragment = `
${TimezoneDateOutputNoNestingFragment}
${EventTypeDataNoNestingFragment}
${LocationNoNestingFragment}
fragment EventBasicInfoFragment on EventBasicInfo {
  startTime {
    ...TimezoneDateOutputNoNestingFragment
  }
  endTime {
    ...TimezoneDateOutputNoNestingFragment
  }
  isVirtual
  type {
    ...EventTypeDataNoNestingFragment
  }
  location {
    ...LocationNoNestingFragment
  }
}
`;

export const FamilyCharterGoalOutputFragment = `
${SdgOutputNoNestingFragment}
${FamilyCharterGoalObjectiveOutputNoNestingFragment}
${FamilyCharterGoalInitiativeOutputNoNestingFragment}
fragment FamilyCharterGoalOutputFragment on FamilyCharterGoalOutput {
  id
  sdgId
  sdg {
    ...SdgOutputNoNestingFragment
  }
  description
  objectives {
    ...FamilyCharterGoalObjectiveOutputNoNestingFragment
  }
  initiatives {
    ...FamilyCharterGoalInitiativeOutputNoNestingFragment
  }
}
`;

export const MemberToAddOutputFragment = `
${MediaOutputNoNestingFragment}
${FamilyNoNestingFragment}
${PrincipalOutputNoNestingFragment}
fragment MemberToAddOutputFragment on MemberToAddOutput {
  id
  avatarImage {
    ...MediaOutputNoNestingFragment
  }
  heroImage {
    ...MediaOutputNoNestingFragment
  }
  firstName
  lastName
  title
  description
  isConnected
  family {
    ...FamilyNoNestingFragment
  }
  createdAt
  updatedAt
  status
  principal {
    ...PrincipalOutputNoNestingFragment
  }
  connectionState
  canBeInvited
  honorific
  role
  isInvited
}
`;

export const OnboardManagementOutputFragment = `
${FamilyOnboardMemberDetailsOutputNoNestingFragment}
${OnboardManagementMeetingInvitationOutputNoNestingFragment}
${OnboardManagementActionsNoNestingFragment}
fragment OnboardManagementOutputFragment on OnboardManagementOutput {
  id
  createdAt
  updatedAt
  referenceId
  referenceType
  members {
    ...FamilyOnboardMemberDetailsOutputNoNestingFragment
  }
  meetingInvitation {
    ...OnboardManagementMeetingInvitationOutputNoNestingFragment
  }
  state
  actions {
    ...OnboardManagementActionsNoNestingFragment
  }
}
`;

export const LocationDeepNestingFragment = `
${MediaOutputDeepNestingFragment}
${LocationImageOutputDeepNestingFragment}
${LocationButtonOutputDeepNestingFragment}
fragment LocationDeepNestingFragment on Location {
  name
  description
  addressLine1
  addressLine2
  city
  postcode
  state
  country
  isGalleryEnabled
  telephone
  email
  heroImage {
    ...MediaOutputDeepNestingFragment
  }
  images {
    ...LocationImageOutputDeepNestingFragment
  }
  button {
    ...LocationButtonOutputDeepNestingFragment
  }
}
`;

export const EventBasicInfoDeepNestingFragment = `
${TimezoneDateOutputDeepNestingFragment}
${EventTypeDataDeepNestingFragment}
${LocationDeepNestingFragment}
fragment EventBasicInfoDeepNestingFragment on EventBasicInfo {
  startTime {
    ...TimezoneDateOutputDeepNestingFragment
  }
  endTime {
    ...TimezoneDateOutputDeepNestingFragment
  }
  isVirtual
  type {
    ...EventTypeDataDeepNestingFragment
  }
  location {
    ...LocationDeepNestingFragment
  }
}
`;

export const OnboardManagementOutputDeepNestingFragment = `
${FamilyOnboardMemberDetailsOutputDeepNestingFragment}
${OnboardManagementMeetingInvitationOutputDeepNestingFragment}
${OnboardManagementActionsDeepNestingFragment}
fragment OnboardManagementOutputDeepNestingFragment on OnboardManagementOutput {
  id
  createdAt
  updatedAt
  referenceId
  referenceType
  members {
    ...FamilyOnboardMemberDetailsOutputDeepNestingFragment
  }
  meetingInvitation {
    ...OnboardManagementMeetingInvitationOutputDeepNestingFragment
  }
  state
  actions {
    ...OnboardManagementActionsDeepNestingFragment
  }
}
`;

export const InitiativeOutputFragment = `
${InitiativeHeroOutputNoNestingFragment}
${ItriomTagsV2OutputNoNestingFragment}
${InsightNoNestingFragment}
${InitiativeVisibilityOutputFragment}
fragment InitiativeOutputFragment on InitiativeOutput {
  id
  createdAt
  updatedAt
  hero {
    ...InitiativeHeroOutputNoNestingFragment
  }
  status
  visibilityDetails {
    ...InitiativeVisibilityOutputFragment
  }
  tags {
    ...ItriomTagsV2OutputNoNestingFragment
  }
  relatedArticles {
    ...InsightNoNestingFragment
  }
  createdBy
  updatedBy
  appreciationCount
  isFollowed
}
`;

export const SearchOutputFragment = `
${SearchConnectionOutputNoNestingFragment}
${SearchInsightOutputNoNestingFragment}
${SearchInitiativeOutputNoNestingFragment}
${SearchEventOutputNoNestingFragment}
fragment SearchOutputFragment on SearchOutput {
  connections {
    ...SearchConnectionOutputNoNestingFragment
  }
  insights {
    ...SearchInsightOutputNoNestingFragment
  }
  initiatives {
    ...SearchInitiativeOutputNoNestingFragment
  }
  events {
    ...SearchEventOutputNoNestingFragment
  }
}
`;

export const ExtendedProfileOutputFragment = `
${MediaOutputNoNestingFragment}
${FamilyNoNestingFragment}
${PrincipalOutputNoNestingFragment}
${PhoneNoNestingFragment}
fragment ExtendedProfileOutputFragment on ExtendedProfileOutput {
  id
  avatarImage {
    ...MediaOutputNoNestingFragment
  }
  heroImage {
    ...MediaOutputNoNestingFragment
  }
  firstName
  lastName
  title
  description
  isConnected
  family {
    ...FamilyNoNestingFragment
  }
  createdAt
  updatedAt
  status
  principal {
    ...PrincipalOutputNoNestingFragment
  }
  connectionState
  canBeInvited
  honorific
  role
  phone {
    ...PhoneNoNestingFragment
  }
  email
  dateOfBirth
  canBeDeleted
  canBeEdited
  isFamilyAdmin
  isHeadOfFamily
}
`;

export const CommentFragment = `
${CommentNoNestingFragment}
${MediaOutputNoNestingFragment}
${TagOutputNoNestingFragment}
${ProfileNoNestingFragment}
fragment CommentFragment on Comment {
  id
  createdAt
  updatedAt
  title
  text
  referenceId
  link
  type
  comments {
    ...CommentNoNestingFragment
  }
  images {
    ...MediaOutputNoNestingFragment
  }
  tags {
    ...TagOutputNoNestingFragment
  }
  postedBy {
    ...ProfileNoNestingFragment
  }
  appreciationCount
  createdBy
  updatedBy
}
`;

export const PostFragment = `
${CommentNoNestingFragment}
${MediaOutputNoNestingFragment}
${TagOutputNoNestingFragment}
${ProfileNoNestingFragment}
fragment PostFragment on Post {
  id
  createdAt
  updatedAt
  title
  text
  referenceId
  link
  type
  subtype
  comments {
    ...CommentNoNestingFragment
  }
  images {
    ...MediaOutputNoNestingFragment
  }
  tags {
    ...TagOutputNoNestingFragment
  }
  postedBy {
    ...ProfileNoNestingFragment
  }
  appreciationCount
  isSelfAppreciated
  createdBy
  updatedBy
}
`;

export const GroupFragment = `
${MediaOutputNoNestingFragment}
${ProfileNoNestingFragment}
${PostNoNestingFragment}
${SdgNoNestingFragment}
fragment GroupFragment on Group {
  id
  createdAt
  updatedAt
  name
  category
  avatarImage {
    ...MediaOutputNoNestingFragment
  }
  heroImage {
    ...MediaOutputNoNestingFragment
  }
  description
  type
  allowInvites
  familyOnly
  isAdmin
  isMember
  familyId
  owner {
    ...ProfileNoNestingFragment
  }
  members {
    ...ProfileNoNestingFragment
  }
  admins {
    ...ProfileNoNestingFragment
  }
  advisors {
    ...ProfileNoNestingFragment
  }
  posts {
    ...PostNoNestingFragment
  }
  sdg {
    ...SdgNoNestingFragment
  }
  automaticallyAddFamilyMembers
  createdBy
  updatedBy
  canBeModified
  canMakeAdmins
  canDeleteMembers
  isInvitationPending
}
`;

export const ValueConfiguratorNormalizedOutputFragment = `
${OrganisationalValuesNormalizedOutputNoNestingFragment}
${ContributionValuesNormalizedOutputNoNestingFragment}
${AdvancementValuesNormalizedOutputNoNestingFragment}
${SocietalValuesNormalizedOutputNoNestingFragment}
fragment ValueConfiguratorNormalizedOutputFragment on ValueConfiguratorNormalizedOutput {
  organisationalValues {
    ...OrganisationalValuesNormalizedOutputNoNestingFragment
  }
  contributionValues {
    ...ContributionValuesNormalizedOutputNoNestingFragment
  }
  advancementValues {
    ...AdvancementValuesNormalizedOutputNoNestingFragment
  }
  societalValues {
    ...SocietalValuesNormalizedOutputNoNestingFragment
  }
}
`;

export const ValueConfiguratorNormalizedOutputDeepNestingFragment = `
${OrganisationalValuesNormalizedOutputDeepNestingFragment}
${ContributionValuesNormalizedOutputDeepNestingFragment}
${AdvancementValuesNormalizedOutputDeepNestingFragment}
${SocietalValuesNormalizedOutputDeepNestingFragment}
fragment ValueConfiguratorNormalizedOutputDeepNestingFragment on ValueConfiguratorNormalizedOutput {
  organisationalValues {
    ...OrganisationalValuesNormalizedOutputDeepNestingFragment
  }
  contributionValues {
    ...ContributionValuesNormalizedOutputDeepNestingFragment
  }
  advancementValues {
    ...AdvancementValuesNormalizedOutputDeepNestingFragment
  }
  societalValues {
    ...SocietalValuesNormalizedOutputDeepNestingFragment
  }
}
`;

export const ValueConfiguratorOutputDeepNestingFragment = `
${OrganisationalValuesCategoriesOutputDeepNestingFragment}
${ContributionValuesCategoriesOutputDeepNestingFragment}
${AdvancementValuesCategoriesOutputDeepNestingFragment}
${SocietalValuesCategoriesOutputDeepNestingFragment}
fragment ValueConfiguratorOutputDeepNestingFragment on ValueConfiguratorOutput {
  organisationalValues {
    ...OrganisationalValuesCategoriesOutputDeepNestingFragment
  }
  contributionValues {
    ...ContributionValuesCategoriesOutputDeepNestingFragment
  }
  advancementValues {
    ...AdvancementValuesCategoriesOutputDeepNestingFragment
  }
  societalValues {
    ...SocietalValuesCategoriesOutputDeepNestingFragment
  }
  status
}
`;

export const InsightFragment = `
${InsightHeroOutputNoNestingFragment}
${ItriomTagsV2OutputNoNestingFragment}
${InsightNoNestingFragment}
${ProfileNoNestingFragment}
${InsightVisibilityDetailsOutputFragment}
fragment InsightFragment on Insight {
  id
  createdAt
  updatedAt
  hero {
    ...InsightHeroOutputNoNestingFragment
  }
  status
  tags {
    ...ItriomTagsV2OutputNoNestingFragment
  }
  visibilityDetails {
    ...InsightVisibilityDetailsOutputFragment
  }
  relatedArticles {
    ...InsightNoNestingFragment
  }
  createdBy
  updatedBy
  writtenBy {
    ...ProfileNoNestingFragment
  }
}
`;

export const PrincipalOutputFragment = `
${PrincipalHeroOutputNoNestingFragment}
${PrincipalAboutOutputNoNestingFragment}
${PrincipalPrivacySettingsOutputNoNestingFragment}
${PrincipalValueOutputNoNestingFragment}
${ItriomTagsV2OutputNoNestingFragment}
${FamilyCharterNoNestingFragment}
${FamilyMemberRelationOutputNoNestingFragment}
fragment PrincipalOutputFragment on PrincipalOutput {
  id
  createdAt
  updatedAt
  hero {
    ...PrincipalHeroOutputNoNestingFragment
  }
  about {
    ...PrincipalAboutOutputNoNestingFragment
  }
  status
  privacySettings {
    ...PrincipalPrivacySettingsOutputNoNestingFragment
  }
  values {
    ...PrincipalValueOutputNoNestingFragment
  }
  tags {
    ...ItriomTagsV2OutputNoNestingFragment
  }
  familyCharter {
    ...FamilyCharterNoNestingFragment
  }
  createdBy
  updatedBy
  isHeadOfFamily
  memberRelations {
    ...FamilyMemberRelationOutputNoNestingFragment
  }
}
`;

export const AdvisorOutputDeepNestingFragment = `
${AdvisorHeroOutputDeepNestingFragment}
${AdvisorLocationOutputDeepNestingFragment}
${AdvisorAboutOutputDeepNestingFragment}
${AdvisorAdvocacyOutputDeepNestingFragment}
${ItriomTagsOutputDeepNestingFragment}
${SdgOutputDeepNestingFragment}
${FamilyValuesOutputDeepNestingFragment}
fragment AdvisorOutputDeepNestingFragment on AdvisorOutput {
  id
  createdAt
  updatedAt
  hero {
    ...AdvisorHeroOutputDeepNestingFragment
  }
  status
  location {
    ...AdvisorLocationOutputDeepNestingFragment
  }
  awards
  about {
    ...AdvisorAboutOutputDeepNestingFragment
  }
  advocacy {
    ...AdvisorAdvocacyOutputDeepNestingFragment
  }
  tags {
    ...ItriomTagsOutputDeepNestingFragment
  }
  createdBy
  updatedBy
  sdGoals {
    ...SdgOutputDeepNestingFragment
  }
  values {
    ...FamilyValuesOutputDeepNestingFragment
  }
  firstName
  lastName
}
`;
