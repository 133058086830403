import { Flex, Heading } from '@chakra-ui/react';

import {
  LayoutWithBlock,
  LayoutWithBlockProps,
} from 'components/Layout/LayoutWithBlock';

import { Cta } from '../Cta';
import { SectionHeading } from '../SectionHeader';

const LeftColumn = () => {
  return (
    <Flex direction="column">
      <SectionHeading heading="RSVP" />
      <Heading size="h3" maxWidth={540}>
        We are sorry but it is now too late to send us your RSVP.
        <br />
        <br />
        We will contact you as soon as possible to confirm your participation in
        the workshop.
        <br />
        <br />
        Thank you.
      </Heading>
    </Flex>
  );
};

export const RsvpExpired = (props: LayoutWithBlockProps) => {
  return (
    <LayoutWithBlock
      bgImage="/assets/backgrounds/rsvp.jfif"
      {...props}
      leftContent={<LeftColumn />}
    >
      <Cta title="We will contact you to confirm your participation in the workshop." />
    </LayoutWithBlock>
  );
};
