import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Divider, Flex, Heading } from '@chakra-ui/react';

import { ContentCommentItem } from 'components/ContentCommentItem';
import { NewPostForm } from 'components/Posts/PostForm/NewPostForm';
import { usePosts } from 'hooks/posts';
import { PostSource } from 'types';

export const InitiativeComments = () => {
  const { id } = useParams();
  const { t } = useTranslation(['common']);

  if (!id) throw new TypeError('Event id is required');

  const { posts, refetch } = usePosts({
    filters: {
      source: PostSource.Initiative,
      referenceId: id,
    },
    pagination: { limit: 100, offset: 0 },
  });

  return (
    <Flex direction="column" gap={4}>
      <Heading size="h4">{t('common:comments')}</Heading>
      {posts.map((post) => (
        <ContentCommentItem post={post} key={post.id} />
      ))}

      <Divider mt={5} mb={2} borderColor="grey" />
      <NewPostForm
        textLabel="Short Content"
        source={PostSource.Initiative}
        referenceId={id}
        onAdd={refetch}
        textCharLimit={100}
      />
    </Flex>
  );
};
