import { Grid, Image, Heading, Flex } from '@chakra-ui/react';

import { MarkdownPreview } from 'components/MarkdownPreview';
import { MediaOutput } from 'types';

type imagePlacement = 'left' | 'right';

interface Props {
  image?: MediaOutput;
  imagePosition: imagePlacement;
  heading?: string;
  content?: string;
}

const gridAreas: Record<imagePlacement, string> = {
  left: '"image text"',
  right: '"text image"',
};

export const ArticleSideImage = ({
  image,
  imagePosition,
  heading,
  content,
}: Props) => {
  if (!content || !image?.signedUrl) {
    return null;
  }

  return (
    <Grid
      gridTemplateColumns="1fr 1fr"
      gap={4}
      gridTemplateAreas={gridAreas[imagePosition]}
    >
      <Image src={image.signedUrl} gridArea="image" w="full" />
      <Flex direction="column" gridArea="text" gap={4}>
        {heading && <Heading size="h5">{heading}</Heading>}
        <MarkdownPreview content={content} />
      </Flex>
    </Grid>
  );
};
