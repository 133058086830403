import { useMemo } from 'react';

import { Text, Flex } from '@chakra-ui/react';

import { RightBlock } from 'components/Layout/RightBlock';
import { SupportEmailButton } from 'components/SupportEmailButton';

export const CheckMailPage = () => {
  const userEmail = useMemo(() => {
    const email = sessionStorage.getItem('userEmail');
    sessionStorage.clear();

    return email;
  }, []);

  return (
    <RightBlock title="Forgot Password">
      <Flex height="100%" justifyContent="center" direction="column">
        {userEmail && (
          <Text size="p1" mb={10}>
            We sent an email to {userEmail}.
          </Text>
        )}
        <Text size="p1">
          Please check your email for instructions to reset your password.
        </Text>
      </Flex>
      <Text size="p3" mt="auto">
        Please contact <SupportEmailButton /> for technical issues or if you
        need further assistance.
      </Text>
    </RightBlock>
  );
};
