import { TAG_COLORS } from 'constants/Tags';

import { ItriomTag } from './ItriomTag';
import { Variant } from './types';

type Props = {
  tag: string;
  variant?: Variant;
};

export const ColouredTag = ({ tag, variant = 'dark' }: Props) => {
  const label = tag.replace(/SDG\s\d+\s-\s/i, '');
  const background = TAG_COLORS[label];

  const colorProps = {
    bg: background ?? 'white',
    borderColor: background ?? 'white',
    color: 'primary.blue.default',
    fontWeight: 400,
    boxShadow: 'none',
  };

  return (
    <ItriomTag {...colorProps} variant={variant}>
      {label}
    </ItriomTag>
  );
};
