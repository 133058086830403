import { Text } from '@chakra-ui/layout';
import { Heading, HStack, VStack } from '@chakra-ui/react';

import { ItriomTag } from 'components/ItriomTag';

interface Props {
  title: string;
  description: string;
  tags?: string[];
  variant: 'light' | 'dark';
  type?: 'tile' | 'banner';
}

export const TextContent = ({
  variant,
  title,
  description,
  tags,
  type = 'tile',
}: Props) => {
  const isLightVariant = variant === 'light';

  return (
    <VStack
      spacing={2}
      alignItems="flex-start"
      justifyContent="flex-start"
      w="100%"
      h="100%"
      color={isLightVariant ? 'white' : 'primary.blue.default'}
    >
      <Heading
        size={type === 'tile' ? 'h5' : 'h3'}
        lineHeight={type === 'tile' ? '26px' : '38px'}
      >
        {title}
      </Heading>
      {tags?.length ? (
        <HStack flexWrap="wrap" gap={2} spacing={0}>
          {tags.map((tag) => {
            return (
              <ItriomTag
                key={tag}
                customVariant={isLightVariant ? 'outlineLight' : 'dark'}
              >
                {tag}
              </ItriomTag>
            );
          })}
        </HStack>
      ) : null}
      <Text size={type === 'tile' ? 'p2' : 'p1'}>{description}</Text>
    </VStack>
  );
};
