import { ValuesSummary } from 'components/ValuesSummary';
import { useFamilyNormalizedValuesByReference } from 'hooks/onboarding/useFamilyNormalizedValuesByReference';
import { FamilyOnboardStateEnum, OnboardReferenceType } from 'types';
import { isFamilyOnboardCompleted } from 'utils/values';

import { CmsSection } from '../../../CmsSection';
import { PreviewLayout } from './PreviewLayout';

type Props = {
  referenceId: string;
  state: FamilyOnboardStateEnum;
  referenceType: OnboardReferenceType;
};

type PreviewCopy = {
  buttonLabel: string;
  previewTitle: string;
  previewSubtitle: string;
  previewDescription: string;
};

const FAMILY_COPIES: PreviewCopy = {
  buttonLabel: 'View family values results',
  previewTitle: 'Family Values',
  previewSubtitle: 'Family Combined scores displayed',
  previewDescription:
    'Itriom has grouped values relevant to families, into four categories that capture both the areas of purpose for the family and enablers for achieving the purpose.',
};

const ADVISOR_COPIES: PreviewCopy = {
  buttonLabel: 'View advisor values results',
  previewTitle: 'Advisor Values',
  previewSubtitle: 'Advisor Combined scores displayed',
  previewDescription:
    'Itriom has grouped values relevant to advisor, into four categories that capture both the areas of purpose for the advisor and enablers for achieving the purpose.',
};

export const VALUES_PREVIEW_COPIES: Record<OnboardReferenceType, PreviewCopy> =
  {
    FAMILY: FAMILY_COPIES,
    EXPERT_ADVISOR: ADVISOR_COPIES,
  };

export const ValuesPreviewSection = ({
  state,
  referenceId,
  referenceType,
}: Props) => {
  const { normalizedFamilyValues } = useFamilyNormalizedValuesByReference({
    referenceType,
    referenceId,
  });

  const { previewDescription, previewTitle, buttonLabel, previewSubtitle } =
    VALUES_PREVIEW_COPIES[referenceType];

  return (
    <CmsSection title="Values" containerStyling={{ mt: 2 }}>
      <PreviewLayout
        title="Values"
        buttonText={buttonLabel}
        isCompleted={isFamilyOnboardCompleted(
          state,
          FamilyOnboardStateEnum.ValueConfiguratorCompleted
        )}
        isPreviewDisabled={
          !isFamilyOnboardCompleted(
            state,
            FamilyOnboardStateEnum.ValueConfiguratorCompleted
          )
        }
        modalContent={
          <ValuesSummary
            normalizedValues={normalizedFamilyValues}
            title={previewTitle}
            subtitle={previewSubtitle}
            description={previewDescription}
          />
        }
      />
    </CmsSection>
  );
};
