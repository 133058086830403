import { CheckmarkOutline } from '@carbon/icons-react/next';
import { MenuItem, useDisclosure } from '@chakra-ui/react';

import { ConfirmationModal } from 'components/Modal';

import { ActionMenuLayout } from '../ActionMenuLayout';

type Props = {
  handleDelete: () => void;
};

export const ConversationActionMenu = ({ handleDelete }: Props) => {
  const confirmationModal = useDisclosure();

  return (
    <ActionMenuLayout>
      <MenuItem onClick={confirmationModal.onOpen}>Delete</MenuItem>
      <ConfirmationModal
        actionText="Confirm"
        title="Are you sure you want to delete this conversation?"
        question="Note: this will delete the conversation only for you."
        handleClick={handleDelete}
        isOpen={confirmationModal.isOpen}
        onClose={confirmationModal.onClose}
        confirmationButtonProps={{
          rightIcon: <CheckmarkOutline height="14px" width="14px" />,
          background: 'positive',
          color: 'ui.white',
        }}
        cancelButtonProps={{
          borderWidth: '1px',
        }}
      />
    </ActionMenuLayout>
  );
};
