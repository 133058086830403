import { lazy } from 'react';

import { Links } from 'router/links';
import { PageConfig } from 'router/types';
import { LayoutType, Role } from 'types';

import { EditFamilyPage } from './Family/FamilyAdmin/EditFamilyPage';
import { EditMemberPage } from './Family/FamilyAdmin/EditMemberPage';

const AdvisorOnboardPage = lazy(
  () => import('./Onboarding/AdvisorOnboardPage')
);
const AdvisorProfilesAdminPage = lazy(
  () => import('./Advisors/AdvisorProfilesAdminPage')
);
const EditAdvisorPage = lazy(() => import('./Advisors/EditAdvisorPage'));
const EditAdvisorProfilePage = lazy(
  () => import('./Advisors/EditAdvisorProfilePage')
);
const AddEventPage = lazy(() => import('./Events/AddEventPage'));
const EditEventPage = lazy(() => import('./Events/EditEventPage'));
const EventsAdminPage = lazy(() => import('./Events/EventsAdminPage'));
const AddFamilyCharter = lazy(
  () => import('./Family/Curator/AddFamilyCharterPage')
);
const CharterCuratorPage = lazy(
  () => import('./Family/Curator/CharterCuratorPage')
);
const EditFamilyCharterPage = lazy(
  () => import('./Family/Curator/EditFamilyCharterPage')
);
const AddInitiativePage = lazy(() => import('./Initiatives/AddInitiativePage'));
const EditInitiativePage = lazy(
  () => import('./Initiatives/EditInitiativePage')
);
const InitiativesAdminPage = lazy(
  () => import('./Initiatives/InitiativesAdminPage')
);
const AddInsightPage = lazy(() => import('./Insights/AddInsightPage'));
const EditInsightPage = lazy(() => import('./Insights/EditInsightPage'));
const InsightsAdminPage = lazy(() => import('./Insights/InsightsAdminPage'));
const AddUserPage = lazy(() => import('./Onboarding/AddUserPage'));
const EditUserPage = lazy(() => import('./Onboarding/EditUserPage'));
const EditUsersPage = lazy(() => import('./Onboarding/EditUsersPage'));
const FamilyOnboardingPage = lazy(
  () => import('./Onboarding/FamilyOnboardingPage')
);
const CmsPrincipalEditPage = lazy(
  () => import('./Principals/CmsPrincipalEditPage')
);
const CmsPrincipalsPage = lazy(() => import('./Principals/CmsPrincipalsPage'));
const ManageMarketplaceDemoPage = lazy(
  () => import('./Services/ManageMarketplaceDemoPage')
);
const NextGenServicesDemoPage = lazy(
  () => import('./Services/NextGenServicesDemoPage')
);

const AddAdvisorPage = lazy(() => import('./Advisors/AddAdvisorPage'));
const AddAdvisorProfilePage = lazy(
  () => import('./Advisors/AddAdvisorProfilePage')
);
const AdvisorAccountsAdminPage = lazy(
  () => import('./Advisors/AdvisorAccountsAdminPage')
);
const AdvisorManageOwnProfilePage = lazy(
  () => import('./Advisors/AdvisorManageOwnProfilePage')
);

const ADVISOR_ACCOUNT_CMS_ROUTES: PageConfig = {
  link: Links.cmsAdvisors,
  component: <AdvisorAccountsAdminPage />,
  roles: [Role.Admin],
  navigationLabelKey: 'cms.advisors',
  subPages: [
    { link: Links.editAdvisor, component: <EditAdvisorPage /> },
    { link: Links.createAdvisor, component: <AddAdvisorPage /> },
  ],
  options: {
    layout: LayoutType.OldDesign,
  },
};

const INITIATIVE_CMS_ROUTES: PageConfig = {
  link: Links.cmsInitiatives,
  component: <InitiativesAdminPage />,
  roles: [Role.Admin, Role.ItriomCurator, Role.ExpertAdvisor],
  navigationLabelKey: 'cms.initiatives',
  subPages: [
    { link: Links.createInitiative, component: <AddInitiativePage /> },
    { link: Links.editInitiative, component: <EditInitiativePage /> },
  ],
  options: {
    layout: LayoutType.OldDesign,
  },
};

const EVENTS_CMS_ROUTES: PageConfig = {
  link: Links.cmsEvents,
  component: <EventsAdminPage />,
  roles: [Role.Admin, Role.ItriomCurator, Role.ExpertAdvisor],
  navigationLabelKey: 'cms.events',
  subPages: [
    { link: Links.createEvent, component: <AddEventPage /> },
    { link: Links.editEvent, component: <EditEventPage /> },
  ],
  options: {
    layout: LayoutType.OldDesign,
  },
};

const INSIGHTS_CMS_ROUTES: PageConfig = {
  link: Links.cmsInsights,
  component: <InsightsAdminPage />,
  roles: [Role.Admin, Role.ItriomCurator, Role.ExpertAdvisor],
  navigationLabelKey: 'cms.insights',
  subPages: [
    { link: Links.createInsight, component: <AddInsightPage /> },
    { link: Links.editInsight, component: <EditInsightPage /> },
  ],
  options: {
    layout: LayoutType.OldDesign,
  },
};

const ADVISOR_PROFILE_CMS_ROUTES: PageConfig = {
  link: Links.cmsAdvisorProfiles,
  component: <AdvisorProfilesAdminPage />,
  roles: [Role.Admin, Role.ItriomCurator],
  navigationLabelKey: 'cms.advisorProfiles',
  subPages: [
    { link: Links.editAdvisorProfile, component: <EditAdvisorProfilePage /> },
    { link: Links.createAdvisorProfile, component: <AddAdvisorProfilePage /> },
  ],
  options: {
    layout: LayoutType.OldDesign,
  },
};

const ADVISOR_PROFILE_MANAGE_OWN_ROUTES: PageConfig = {
  link: Links.advisorManageOwnProfile,
  component: <AdvisorManageOwnProfilePage />,
  navigationLabelKey: 'cms.advisorManageOwnProfile',
  roles: [Role.ExpertAdvisor],
  options: {
    layout: LayoutType.OldDesign,
  },
};

const PRINCIPALS_CMS_ROUTES: PageConfig = {
  // PRINCIPAL is a legacy name see https://itriom.atlassian.net/browse/LEIP-2969
  link: Links.cmsProfiles,
  component: <CmsPrincipalsPage />,
  roles: [Role.Admin, Role.ItriomCurator, Role.FamilyAdmin],
  navigationLabelKey: 'cms.profiles',
  subPages: [
    { link: Links.cmsProfileEdit, component: <CmsPrincipalEditPage /> },
  ],
  options: {
    layout: LayoutType.OldDesign,
  },
};

const FAMILY_CHARTERS_CMS_ROUTES: PageConfig = {
  link: Links.cmsFamilyCharters,
  component: <CharterCuratorPage />,
  roles: [Role.Admin, Role.ItriomCurator],
  navigationLabelKey: 'cms.charters',
  subPages: [
    { link: Links.cmsCreateFamilyCharter, component: <AddFamilyCharter /> },
    { link: Links.editFamilyCharter, component: <EditFamilyCharterPage /> },
  ],
  options: {
    layout: LayoutType.OldDesign,
  },
};

const FAMILY_ONBOARDING_CMS_ROUTES: PageConfig = {
  link: Links.familyOnboardingSetup,
  component: <FamilyOnboardingPage />,
  navigationLabelKey: 'cms.familyOnboarding',
  roles: [Role.Admin],
  options: {
    layout: LayoutType.OldDesign,
  },
};

const FAMILY_MANAGEMENT_CMS_ROUTES: PageConfig = {
  link: Links.familyManagement,
  component: <EditFamilyPage />,
  navigationLabelKey: 'cms.familyManagement',
  roles: [Role.FamilyAdmin],
  subPages: [
    { link: Links.familyManagementUserEdit, component: <EditMemberPage /> },
  ],
  options: {
    layout: LayoutType.OldDesign,
  },
};

const ADVISOR_ONBOARDING_CMS_ROUTES: PageConfig = {
  link: Links.advisorOnboardPage,
  component: <AdvisorOnboardPage />,
  navigationLabelKey: 'cms.advisorOnboarding',
  roles: [Role.Admin],
  options: {
    layout: LayoutType.OldDesign,
  },
};

const REGISTER_USER_CMS_ROUTES: PageConfig = {
  link: Links.cmsUserCreate,
  component: <AddUserPage />,
  roles: [Role.Admin],
  navigationLabelKey: 'cms.userRegistration',
  options: {
    layout: LayoutType.OldDesign,
  },
};

const EDIT_USER_CMS_ROUTES: PageConfig = {
  link: Links.cmsUsers,
  component: <EditUsersPage />,
  roles: [Role.Admin],
  navigationLabelKey: 'cms.userManagement',
  subPages: [{ link: Links.cmsUserEdit, component: <EditUserPage /> }],
  options: {
    layout: LayoutType.OldDesign,
  },
};

const MARKETPLACE_DEMO_REQUESTS_CMS_ROUTES: PageConfig = {
  link: Links.cmsMarketplaceDemoRequests,
  component: <ManageMarketplaceDemoPage />,
  navigationLabelKey: 'cms.marketplaceDemo',
  roles: [Role.Admin],
  options: {
    layout: LayoutType.OldDesign,
  },
};

const SERVICES_DEMO_REQUESTS_CMS_ROUTES: PageConfig = {
  link: Links.cmsNextGenServicesDemoRequests,
  component: <NextGenServicesDemoPage />,
  navigationLabelKey: 'cms.servicesDemo',
  roles: [Role.Admin],
  options: {
    layout: LayoutType.OldDesign,
  },
};

export const CMS_PAGES: PageConfig[] = [
  ADVISOR_ACCOUNT_CMS_ROUTES,
  ADVISOR_ONBOARDING_CMS_ROUTES,
  ADVISOR_PROFILE_CMS_ROUTES,
  ADVISOR_PROFILE_MANAGE_OWN_ROUTES,
  FAMILY_CHARTERS_CMS_ROUTES,
  FAMILY_ONBOARDING_CMS_ROUTES,
  FAMILY_MANAGEMENT_CMS_ROUTES,
  EDIT_USER_CMS_ROUTES,
  REGISTER_USER_CMS_ROUTES,
  PRINCIPALS_CMS_ROUTES,
  EVENTS_CMS_ROUTES,
  INITIATIVE_CMS_ROUTES,
  INSIGHTS_CMS_ROUTES,
  MARKETPLACE_DEMO_REQUESTS_CMS_ROUTES,
  SERVICES_DEMO_REQUESTS_CMS_ROUTES,
];
