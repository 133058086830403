import { useTranslation } from 'react-i18next';

import { HStack, VStack } from '@chakra-ui/layout';
import { Avatar, Box, Flex, Text } from '@chakra-ui/react';

import { useAuthContext } from 'contexts/AuthContext';
import type { Message as MessageType, Profile } from 'types';
import { formatDateTime } from 'utils/date';
import { getAvatarSrc } from 'utils/file';
import { getFullName } from 'utils/string';

import { MessagesActionMenu } from './MessagesActionMenu';

const getIsLeftAligned = (accountId?: string, authorId?: string) => {
  return accountId !== authorId;
};

type Props = {
  message: MessageType;
  handleDelete: () => void;
  sender?: Profile;
  isAuthor?: boolean;
};

export const Message = ({
  message,
  handleDelete,
  sender,
  isAuthor = false,
}: Props): JSX.Element => {
  const { account } = useAuthContext();

  const isLeftAligned = getIsLeftAligned(account?.id, message?.createdBy);
  const { t } = useTranslation(['common']);

  return (
    <Flex direction="column" w="100%" p={3} data-cy="message">
      <VStack w="100%" align={isLeftAligned ? 'flex-start' : 'flex-end'}>
        <HStack>
          <Avatar src={getAvatarSrc(sender?.avatarImage)} size="xs" mr={1} />
          <Text size="p3b">
            {sender
              ? getFullName(sender?.firstName, sender?.lastName)
              : t('common:user-deleted')}
          </Text>
          <Text size="p2" ml={1} mr={1}>
            •
          </Text>
          <Text size="p3">{formatDateTime(message.createdAt)}</Text>
        </HStack>
        <Box
          w="50%"
          m={2}
          bgColor={isLeftAligned ? 'ui.grey4' : 'primary.blue.default'}
          color={isLeftAligned ? 'primary.blue.default' : 'ui.white'}
          p={2}
          borderRadius={10}
          pos="relative"
          sx={{
            '&:hover div': {
              visibility: 'visible',
            },
          }}
        >
          {message.text ? (
            <Text size="p2">{message.text}</Text>
          ) : (
            <Text size="p2" as="i" color="ui.white">
              This message has been deleted
            </Text>
          )}
          {message.text && isAuthor && (
            <MessagesActionMenu handleDelete={handleDelete} />
          )}
        </Box>
      </VStack>
    </Flex>
  );
};
