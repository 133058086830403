import { Account, Event, EventStatusEnum } from 'types';
import { isItriomStaff } from 'utils/roles';

export const shouldForbidContentDisplay = (
  loading: boolean,
  event?: Event,
  user?: Account
) => {
  if (loading) return false;
  if (!event) return false;
  if (!user) return true;

  const isDraft = event.status === EventStatusEnum.Draft;
  if (!isDraft) return false;

  if (isItriomStaff(user.role)) return false;

  if (event.createdBy === user.id) return false;

  const familyEventType = event.visibilityDetails?.family;

  if (
    familyEventType &&
    user.familyId &&
    event.visibilityDetails?.familyOwnersIds?.includes(user.familyId)
  ) {
    return false;
  }

  return true;
};
