import { Form, FormikConfig, FormikProvider, useFormik } from 'formik';

import { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Box, Button, Flex, Grid } from '@chakra-ui/react';

import { ItriomButtonOutline } from 'components/Button';
import { CheckboxInput } from 'components/Form/CheckboxInput';
import { SelectInput } from 'components/Form/SelectInput';
import { TextInput } from 'components/Form/TextInput';
import { PhoneWithCountrySelect } from 'components/PhoneWithCountrySelect';
import { UserFormFieldName, UserFormType } from 'features/Cms/types';
import { useFamilyOptions } from 'gql/family/query/familyOptions';
import { stringToInputDate } from 'utils/date';
import { HONORIFIC_OPTIONS } from 'utils/honorific';

import { HeadOfFamilyCheckboxBase } from './ReasignHeadOfFamily';
import { userSchema } from './validation';

type Props<T> = {
  isSubmitDisabled?: boolean;
  initialValues: T;
  onSubmit: FormikConfig<T>['onSubmit'];
  buttonText: string;
  isEditing?: boolean;
  backLink?: string;
  headOfFamilyCheckbox?: ReactNode;
  deleteUserSection?: ReactNode;
  hiddenFields?: UserFormFieldName[];
};

export const UserForm = <T extends UserFormType>({
  isSubmitDisabled,
  initialValues,
  onSubmit,
  buttonText,
  isEditing,
  backLink,
  hiddenFields = [],
  deleteUserSection,
  headOfFamilyCheckbox = <HeadOfFamilyCheckboxBase />,
}: Props<T>) => {
  const formik = useFormik<T>({
    initialValues,
    onSubmit,
    validationSchema: userSchema,
  });
  const { t } = useTranslation(['cms', 'common']);
  const { values, setFieldValue } = formik;

  const { activeFamilyOptions, loading, error } = useFamilyOptions();

  useEffect(() => {
    setFieldValue(
      UserFormFieldName.IsHeadOfFamily,
      initialValues.isHeadOfFamily
    );
  }, [initialValues.isHeadOfFamily]);

  const isHidden = (fieldName: UserFormFieldName) =>
    hiddenFields.includes(fieldName);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid
          gridTemplateColumns="1fr 1fr"
          gridTemplateRows="repeat(4, auto)"
          gridAutoFlow="rows"
          gridGap={10}
        >
          <Flex gridColumn="1 / span 2">
            <SelectInput
              containerStyles={{ minWidth: '230px', width: 'fit-content' }}
              name={UserFormFieldName.Honorific}
              label={t('cms:user.title')}
              options={HONORIFIC_OPTIONS}
            />
          </Flex>
          <TextInput
            name={UserFormFieldName.FirstName}
            label={t('cms:user.first-name')}
            placeholder="Johnny"
            isDisabled={isEditing}
          />

          <TextInput
            name={UserFormFieldName.LastName}
            label={t('cms:user.last-name')}
            placeholder="Appleseed"
            isDisabled={isEditing}
          />

          <PhoneWithCountrySelect
            label={t('common:phone-number')}
            phoneBaseInputName={`phone.${UserFormFieldName.PhoneBase}`}
            phoneCodeInputName={`phone.${UserFormFieldName.PhoneCountryCode}`}
          />

          <TextInput
            name={UserFormFieldName.Email}
            label={t('common:email')}
            placeholder="example@itriom.com"
          />
          <TextInput
            type="date"
            name={UserFormFieldName.dateOfBirth}
            label={t('cms:date-of-birth')}
            max={stringToInputDate(new Date().toISOString())}
          />
        </Grid>

        <Box hidden={isHidden(UserFormFieldName.IsHeadOfFamily)}>
          {headOfFamilyCheckbox}
        </Box>

        <Grid
          gridTemplateColumns="1fr 1fr"
          gridTemplateRows="repeat(2, auto)"
          gridAutoFlow="rows"
          gridGap={10}
          pb={16}
        >
          <TextInput
            name={UserFormFieldName.FamilyName}
            label="Family / Group Name"
            placeholder="Johnson"
            isDisabled={!values.isHeadOfFamily || isEditing}
          />

          <SelectInput
            name={UserFormFieldName.FamilyId}
            label={t('cms:family.name')}
            options={activeFamilyOptions}
            disabled={
              (values.isHeadOfFamily && !values.isFamilyAdmin) || isEditing
            }
            loading={loading}
            error={error}
            hidden={isHidden(UserFormFieldName.FamilyId)}
          />

          <CheckboxInput
            name={UserFormFieldName.IsFamilyAdmin}
            label="Click here if Client being registered is the Family Admin"
            isDisabled={isEditing}
            hidden={isHidden(UserFormFieldName.IsFamilyAdmin)}
          />
        </Grid>

        {deleteUserSection}

        <Flex
          w="full"
          direction="row"
          alignItems="center"
          justify="space-between"
        >
          {backLink && (
            <Link to={backLink}>
              <ItriomButtonOutline type="button">
                {t('common:back')}
              </ItriomButtonOutline>
            </Link>
          )}
          <Button ml="auto" type="submit" isDisabled={isSubmitDisabled}>
            {buttonText}
          </Button>
        </Flex>
      </Form>
    </FormikProvider>
  );
};
