import { Flex, Grid, Heading, Text } from '@chakra-ui/react';

import { Avatar } from 'components/Avatar';
import { AgendaItem as AgendaItemType } from 'types';
import { formatDate, getDurationString } from 'utils/date';

import { ElementWithBackgroundImage } from '../ElementWithBackgroundImage';

export const AgendaItem = ({ item }: { item: AgendaItemType }) => {
  return (
    <Grid
      gridTemplateColumns="repeat(12, 1fr)"
      gridTemplateRows="auto 1fr auto"
      gap={4}
    >
      <ElementWithBackgroundImage
        image={item.image}
        childrenGap={0}
        gridColumn="1 / span 4"
        gridRow="1 / span 3"
        aspectRatio="3 / 2"
      >
        <Heading size="h3" color="ui.white">
          {`${formatDate(item.startTime, 'HH:mm')} - ${formatDate(
            item.endTime,
            'HH:mm'
          )}`}
        </Heading>
        <Text size="p1" color="ui.white">
          {getDurationString(new Date(item.startTime), new Date(item.endTime))}
        </Text>
      </ElementWithBackgroundImage>
      <Flex gridColumn="5 / span 8" pt={4}>
        <Heading size="h4">{item.heading}</Heading>
      </Flex>
      <Flex gridColumn="5 / span 8">
        <Text size="h2">{item.description}</Text>
      </Flex>
      {!!item.speakers.length && (
        <Flex
          pb={4}
          pt={2}
          gap={4}
          flexWrap="wrap"
          gridColumn="5 / span 8"
          borderColor="primary.blue.default"
          borderTopWidth="1px"
        >
          {item.speakers.map((speaker) => (
            <Avatar
              profile={speaker.profile}
              key={speaker.profile.id}
              variant="author"
            />
          ))}
        </Flex>
      )}
    </Grid>
  );
};
