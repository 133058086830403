import { useFormikContext } from 'formik';

import { useMemo } from 'react';

import { Option, UpdateInitiativeRelatedArticlesInput } from 'types';

import { useListInsights } from './useListInsights';

export const useInsightOptions = () => {
  const formik = useFormikContext<UpdateInitiativeRelatedArticlesInput>();

  const { listInsights, loading, error } = useListInsights();

  const relatedArticlesIds = useMemo(
    () => formik.values.relatedArticles ?? [],
    [formik.values.relatedArticles]
  );

  const insightOptions = useMemo<Option[]>(
    () =>
      listInsights.map((insight) => ({
        label: insight.hero.headline,
        value: insight.id,
        disabled: relatedArticlesIds.includes(insight.id),
      })),
    [listInsights, relatedArticlesIds]
  );

  return { insightOptions, loading, error };
};
