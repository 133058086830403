export type UnderlayDirection =
  | 'column'
  | 'column-reverse'
  | 'row'
  | 'row-reverse';

type UnderlayOptions = {
  enable?: boolean;
  lightLayer?: boolean;
  direction?: UnderlayDirection;
  size?: string;
};

const gradientDirection = {
  row: 'to right',
  column: 'to top',
  'row-reverse': 'to left',
  'column-reverse': 'to bottom',
} as const;

export const underlay = ({
  enable = true,
  lightLayer = false,
  direction = 'row',
  size = '100%',
}: UnderlayOptions = {}) => {
  if (!enable) {
    return {};
  }

  const color = lightLayer ? 'rgba(255,255,255,0.75)' : 'rgba(0,0,0,0.75)';

  return {
    _before: {
      content: '""',
      bgGradient: `linear(${gradientDirection[direction]}, ${color}, transparent ${size})`,
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: 0,
    },
  };
};
