import { ComponentStyleConfig } from '@chakra-ui/react';

import { colors } from '../colors';

export const Button: ComponentStyleConfig = {
  baseStyle: {
    // TODO: leip-2621
    fontWeight: '400',
    borderRadius: '2px',
    boxSizing: 'border-box',
    border: '2px solid transparent',
    _focus: {
      outline: 'none',
    },
  },
  sizes: {
    large: {
      fontSize: '16px',
      lineHeight: '24px',
      height: '48px',
      width: 'fit-content',
      padding: '12px 24px',
      gap: '16px',
    },
    small: {
      height: 'fit-content',
      width: 'fit-content',
      padding: '4px 8px',
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  variants: {
    link: {
      color: colors.primary.blue.highlight,
    },
    primaryBlue: {
      background: colors.primary.blue.default,
      color: colors.ui.white,
      _hover: {
        background: colors.ui.white,
        border: `2px solid ${colors.primary.blue.shadow}`,
        color: colors.primary.blue.default,
      },
      _focus: {
        background: colors.primary.blue.shadow,
        color: colors.ui.white,
      },
    },

    primaryLime: {
      background: colors.primary.lime.default,
      color: colors.primary.blue.default,
      _hover: {
        background: colors.primary.lime.highlight,
      },
      _focus: {
        background: colors.primary.lime.shadow,
      },
    },

    primaryWhite: {
      background: colors.ui.white,
      color: colors.primary.blue.default,
      _hover: {
        background: colors.ui.grey4,
      },
    },

    secondaryBlue: {
      color: colors.primary.blue.default,
      border: `2px solid ${colors.primary.blue.default}`,
      background: 'transparent',
      _hover: {
        color: colors.ui.white,
        background: colors.primary.blue.default,
      },
    },

    secondaryLime: {
      background: 'transparent',
      color: colors.primary.lime.default,
      border: `2px solid ${colors.primary.lime.default}`,
      _hover: {
        color: colors.primary.lime.highlight,
        borderColor: colors.primary.lime.highlight,
      },
      _focus: {
        color: colors.primary.lime.shadow,
        borderColor: colors.primary.lime.shadow,
      },
    },

    secondaryWhite: {
      background: 'transparent',
      color: colors.ui.white,
      border: `2px solid ${colors.ui.white}`,
      _hover: {
        color: colors.ui.grey4,
        borderColor: colors.ui.grey4,
      },
    },
  },
  // defaultProps: {
  //   size: 'large',
  //   variant: 'primaryBlue',
  // },
};
