import { gql, useMutation } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationUnfollowInitiativeArgs } from 'types';

import { initiativeGql } from './useInitiative';

const unfollowInitiativeGql = gql`
  mutation UnfollowInitiative($id: String!) {
    unfollowInitiative(id: $id)
  }
`;

export const useUnfollowInitiative = (initiativeId: string) => {
  const [unfollowInitiativeMutation, { loading }] = useMutation<
    Pick<Mutation, 'unfollowInitiative'>,
    MutationUnfollowInitiativeArgs
  >(unfollowInitiativeGql, { refetchQueries: [initiativeGql] });

  const unfollowInitiative = () =>
    unfollowInitiativeMutation({ variables: { id: initiativeId } });

  const unfollowInitiativeWithMutationToast = useWithMutationToast(
    unfollowInitiative,
    {
      successMessage: 'Unfollowed initiative',
      errorMessage: 'Failed unfollowing initiative',
    }
  );

  return { unfollowInitiative: unfollowInitiativeWithMutationToast, loading };
};
