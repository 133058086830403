import { ReactNode, useState } from 'react';

import { Text } from '@chakra-ui/react';

import { RightBlock } from 'components/Layout/RightBlock';
import { SupportEmailButton } from 'components/SupportEmailButton';
import { useAuthContext } from 'contexts/AuthContext';
import { useAccountByToken } from 'hooks/authorization';
import { SecondFactorVerificationTypeEnum } from 'types';

import { OnboardingOtpValidator } from './OtpValidator/OnboardingOtpValidator';
import { SendOtp } from './SendOtp';

type Props = {
  title?: string;
  subtitle?: string;
  token?: string;
  tokenType: SecondFactorVerificationTypeEnum;
  successBlock?: ReactNode;
  footerBlock?: ReactNode;
  buttonAfterSubmit?: ReactNode;
};

export const OtpForm = ({
  title,
  subtitle,
  token,
  tokenType,
  successBlock = <>Success</>,
  footerBlock,
  buttonAfterSubmit,
}: Props) => {
  const { account, error, loading } = useAccountByToken(token);
  const { account: currentAccount } = useAuthContext();
  const [codeWasSend, setCodeWasSend] = useState(false);

  if (currentAccount && currentAccount?.email === account?.email) {
    return <>{successBlock}</>;
  }

  return (
    <RightBlock
      loading={loading}
      error={!token ? 'You need token to go next step' : error?.message}
      title={title ?? `Welcome ${account?.firstName}`}
      subtitle={subtitle ?? 'Sign-in with a One-time passcode'}
    >
      <Text size="p1" mb={14}>
        For security reasons, we will ask you to enter a 6 digit one-time
        passcode which will be sent to your mobile number.
      </Text>
      <Text size="p1" mb={14}>
        A code will be sent to <b>{account?.phoneNumber}</b>
      </Text>
      {codeWasSend ? (
        <OnboardingOtpValidator
          token={token ?? ''}
          tokenType={tokenType}
          onBack={() => setCodeWasSend(false)}
          buttonAfterSubmit={buttonAfterSubmit}
        />
      ) : (
        <SendOtp
          token={token ?? ''}
          tokenType={tokenType}
          email={account?.email ?? ''}
          onSuccess={() => setCodeWasSend(true)}
          buttonAfterSubmit={buttonAfterSubmit}
        />
      )}
      <Text size="p3">
        {footerBlock ?? (
          <>
            Please contact
            <SupportEmailButton />
            if you do not recognise the last 3 digits of the mobile number or if
            the email address shown is not yours.
          </>
        )}
      </Text>
    </RightBlock>
  );
};
