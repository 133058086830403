import { useMessagingCommunication } from '../MessagingCommunication';
import { useMessagesActions } from '../MessagingStateManager/hooks';

export const useLoadMoreMessages = (conversationId: string) => {
  const { loadMoreMessagesForConversation } = useMessagingCommunication();

  const { addMessage, setMessagesTotalCount } = useMessagesActions();

  const loadMoreMessages = () => {
    loadMoreMessagesForConversation(conversationId).then((messages) => {
      messages.nodes.forEach((message) => addMessage(message));
      setMessagesTotalCount(conversationId, messages.totalCount);
    });
  };

  return loadMoreMessages;
};
