import { listInitiativesGql } from 'hooks/initiatives';
import { usePaginatedQuery } from 'hooks/pagination';
import {
  ListInitiativesFiltersInput,
  Query,
  QueryListInitiativesArgs,
} from 'types';

const NOTIFICATIONS_DEFAULT_OFFSET = 0;
const NOTIFICATIONS_DEFAULT_LIMIT = 20;

const DEFAULT_PAGINATION_VARIABLES = {
  offset: NOTIFICATIONS_DEFAULT_OFFSET,
  limit: NOTIFICATIONS_DEFAULT_LIMIT,
};

export const useLoadInitiatives = (filters?: ListInitiativesFiltersInput) => {
  const { initialLoad, loadMore, error, loading, reload } = usePaginatedQuery<
    Pick<Query, 'listInitiatives'>,
    QueryListInitiativesArgs
  >(listInitiativesGql, DEFAULT_PAGINATION_VARIABLES);

  const loadInitiatives = () =>
    initialLoad({ filters }).then((result) => result.listInitiatives);

  const loadMoreInitiatives = () =>
    loadMore({ filters }).then((result) => result.listInitiatives);

  const reloadInitiatives = () =>
    reload({ filters }).then((result) => result.listInitiatives);

  return {
    loadInitiatives,
    loadMoreInitiatives,
    reloadInitiatives,
    error,
    loading,
  };
};
