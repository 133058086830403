import { useNavigate } from 'react-router-dom';

import { useMutation, gql } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Links } from 'router/links';
import { Mutation, MutationCreateGroupArgs } from 'types';
import { generateUrlById } from 'utils/links';

export const createGroupGql = gql`
  mutation CreateGroup(
    $group: GroupCreateInput!
    $avatarImage: [Upload!]!
    $heroImage: [Upload!]!
  ) {
    createGroup(
      group: $group
      avatarImage: $avatarImage
      heroImage: $heroImage
    ) {
      id
    }
  }
`;

export const useCreateGroup = () => {
  const navigate = useNavigate();

  const [mutation] = useMutation<
    Pick<Mutation, 'createGroup'>,
    MutationCreateGroupArgs
  >(createGroupGql, {
    onCompleted: ({ createGroup: { id } }) => {
      navigate(generateUrlById(Links.groupsSingle, id));
    },
  });

  const handle = (variables: MutationCreateGroupArgs) =>
    mutation({ variables });

  const createGroup = useWithMutationToast(handle, {
    successMessage: `Group has been successfully created`,
  });

  return { createGroup };
};
