import { ButtonProps, Button, forwardRef } from '@chakra-ui/react';

export const ItriomMenuButton = forwardRef((props: ButtonProps, ref) => (
  <Button
    ref={ref}
    justifyContent="flex-start"
    background="ui.white"
    color="primary.blue.default"
    borderWidth="1px"
    borderColor="ui.grey4"
    borderRadius={0}
    py={2}
    h="auto"
    {...props}
  />
));
