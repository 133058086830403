export const fonts = {
  heading: 'Publico Headline Roman',
  headingLight: 'Publico Headline Light',
  body: 'Roboto, Arial',
};

// https://chakra-ui.com/docs/styled-system/theme
export const fontSizes = {
  '2xs': '0.625rem', // 10px
  xs: '0.75rem', // 12px
  sm: '0.875rem', // 14px
  md: '1rem', // 16px
  lg: '1.125rem', // 18px
  xl: '1.25rem', // 20px
  '2xl': '1.5rem', // 24px
  '3xl': '1.875rem', // 30px
  '4xl': '2.25rem', // 36px
  '5xl': '3rem', // 48px
  '6xl': '3.75rem', // 60px
  '7xl': '4.5rem', // 72px
};
