import { createContext, ReactNode, useContext } from 'react';

import { Error as ErrorComponent } from 'components/Error';
import { QueryStateGuard } from 'components/QueryStateGuard';
import { useFamilyCharterForMember } from 'hooks/familyCharter/useFamilyCharterForMember';
import { FamilyCharter, FamilyCharterStatusEnum } from 'types';

const AdminFamilyCharterContext = createContext<FamilyCharter | null>(null);

type AdminFamilyCharterProviderProps = {
  children?: ReactNode;
};

export const AdminFamilyCharterProvider = ({
  children,
}: AdminFamilyCharterProviderProps): JSX.Element => {
  const { familyCharter, loading, error } = useFamilyCharterForMember();

  if (
    familyCharter === null ||
    familyCharter?.status === FamilyCharterStatusEnum.Draft
  ) {
    return (
      <ErrorComponent title="Charter not exists">
        Contact to Itriom Curator or your Family Admin to create it
      </ErrorComponent>
    );
  }

  return (
    <QueryStateGuard loading={loading} error={error}>
      {familyCharter && (
        <AdminFamilyCharterContext.Provider value={familyCharter}>
          {children}
        </AdminFamilyCharterContext.Provider>
      )}
    </QueryStateGuard>
  );
};

export const useAdminFamilyCharterContext = (): FamilyCharter => {
  const context = useContext(AdminFamilyCharterContext);

  if (!context) {
    throw new Error(
      'useAdminFamilyCharterContext was used outside of its Provider'
    );
  }

  return context;
};
