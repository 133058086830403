import { gql, useMutation } from '@apollo/client';

import { notificationsGql } from 'features/Notifications/hooks/useLoadNotifications';
import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationRejectConnectionInvitationArgs } from 'types';

const rejectConnectionInvitationGql = gql`
  mutation RejectConnectionInvitation($invitationId: String!) {
    rejectConnectionInvitation(invitationId: $invitationId)
  }
`;

export const useRejectConnectionInvitation = () => {
  const [rejectConnectionInvitationMutation] = useMutation<
    Pick<Mutation, 'rejectConnectionInvitation'>,
    MutationRejectConnectionInvitationArgs
  >(rejectConnectionInvitationGql, { refetchQueries: [notificationsGql] });

  const handleRejectConnectionInvitation = (invitationId: string) =>
    rejectConnectionInvitationMutation({ variables: { invitationId } });

  const rejectConnectionInvitation = useWithMutationToast(
    handleRejectConnectionInvitation,
    {
      successMessage: 'Succesfully rejected connection invitation',
      errorMessage: 'Error rejecting connection invitation',
    }
  );

  return { rejectConnectionInvitation };
};
