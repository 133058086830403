import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useApolloClient } from '@apollo/client';

import { useAccount } from 'gql/accounts/query/account';
import { useLocalStorageJWT } from 'hooks/useLocalStorageJWT';
import { useRedirectUrl } from 'hooks/useRedirectUrl';
import { Links } from 'router/links';

import { makeContext } from './MakeContext';

export const [useAuthContext, AuthProvider] = makeContext(() => {
  const [secondFactorJWT, setSecondFactorJWT] = useState<string | null>(null);
  const { clearJwtInLocalStorage, getJwtFromLocalStorage } =
    useLocalStorageJWT();
  const { memorizeCurrentUrl } = useRedirectUrl();

  const { account, loading, error, refetch } = useAccount({
    skip: !getJwtFromLocalStorage(),
  });

  const navigate = useNavigate();

  const client = useApolloClient();

  const handleRefetch = useCallback(() => {
    if (getJwtFromLocalStorage()) {
      refetch();
    }
  }, [refetch, getJwtFromLocalStorage]);

  // refetch account data if the JWT in local storage changes
  useEffect(() => {
    window.addEventListener('storage', handleRefetch);

    return () => window.removeEventListener('storage', handleRefetch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearSecondFactorJWT = useCallback(() => setSecondFactorJWT(null), []);

  const logout = useCallback(
    async (redirectUrl?: Links) => {
      memorizeCurrentUrl();
      navigate(redirectUrl || Links.signIn);
      clearSecondFactorJWT();
      clearJwtInLocalStorage();
      await client.resetStore();
      await client.clearStore();
    },
    [
      clearSecondFactorJWT,
      clearJwtInLocalStorage,
      client,
      navigate,
      memorizeCurrentUrl,
    ]
  );

  return {
    secondFactorJWT,
    setSecondFactorJWT,
    clearSecondFactorJWT,
    account,
    loading,
    error,
    logout,
  };
});
