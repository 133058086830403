import {
  AdvisorHeroOutput,
  AdvisorLocationOutput,
  AdvisorOutput,
  AdvisorStatusEnum,
} from 'types';

import { generateFamilyValues } from './family.mock';
import { generateMedia } from './images.mock';
import { generateSdgOutput } from './sdgs';

export const TEST_VALUES = {
  id: '627bb20ec64d12192619eb04',
  advisorAccountId: 'advisor test name',
  headline: 'advisor test headline',
  mission: 'advisor test mission',
  heroImageSignedUrl: 'https://itriom.com/advisor-mock-image.png',
  locationName: 'Nepal',
  awards: 'test awards text',
  aboutContent: 'test content',
};

export const generateAdvisorLocation = ({
  description = 'locationDescription',
  name = 'locationName',
  image = generateMedia(),
}: Partial<AdvisorLocationOutput> = {}): AdvisorLocationOutput => ({
  description,
  name,
  image,
  __typename: 'AdvisorLocationOutput',
});

export const generateAdvisorHero = ({
  headline = 'advisorHeadline',
  heroImage = generateMedia(),
  mission = 'advisorMission',
  advisorAccountId = 'advisorId',
}: Partial<AdvisorHeroOutput> = {}): AdvisorHeroOutput => ({
  headline,
  heroImage,
  mission,
  advisorAccountId,
});

export const generateAdvisor = ({
  about,
  advocacy,
  awards,
  createdAt = Date.now(),
  createdBy = Date.now().toString(16),
  hero = generateAdvisorHero(),
  firstName = 'first',
  lastName = 'last',
  id = 'advisorId',
  location,
  status = AdvisorStatusEnum.Draft,
  tags,
  sdGoals = [generateSdgOutput()],
  values = generateFamilyValues(),
  updatedAt = Date.now(),
  updatedBy = Date.now().toString(16),
}: Partial<AdvisorOutput> = {}): AdvisorOutput => ({
  about,
  advocacy,
  awards,
  createdAt,
  createdBy,
  hero,
  firstName,
  lastName,
  id,
  location,
  status,
  tags,
  updatedAt,
  updatedBy,
  sdGoals,
  values,
  __typename: 'AdvisorOutput',
});

export const advisorMock = generateAdvisor();
