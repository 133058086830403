import { gql, useQuery } from '@apollo/client';

import { useAuthContext } from 'contexts/AuthContext';
import { FamilyCharterDeepNestingFragment } from 'gql/fragments';
import { Query } from 'types';

export const familyCharterByFamilyIdGql = gql`
  ${FamilyCharterDeepNestingFragment}
  query FamilyCharterByFamilyId($familyId: String!) {
    familyCharterByFamilyId(id: $familyId) {
      ...FamilyCharterDeepNestingFragment
    }
  }
`;

export const useFamilyCharterByFamilyId = (id?: string) => {
  const { account } = useAuthContext();
  const familyId = id || account?.familyId;

  const { data, error, loading } = useQuery<
    Pick<Query, 'familyCharterByFamilyId'>
  >(familyCharterByFamilyIdGql, {
    variables: {
      familyId,
    },
    skip: !familyId,
  });

  return { familyCharter: data?.familyCharterByFamilyId, error, loading };
};
