import { useQuery } from '@apollo/client';

import { Query } from 'types';

import { listEditableAdvisorsGql } from '../documents';

export const useEditableAdvisors = () => {
  const { data, error, loading } = useQuery<
    Pick<Query, 'listEditableAdvisors'>
  >(listEditableAdvisorsGql);

  const advisors = data?.listEditableAdvisors.nodes || [];

  return { advisors, error, loading };
};
