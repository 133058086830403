import { gql, useQuery } from '@apollo/client';

import { Event } from 'gql/fragments';
import { Query, QueryEventsPublishedPaginatedArgs } from 'types';

export const eventsPublishedPaginatedGql = gql`
  ${Event}
  query EventsPublishedPaginated(
    $pagination: PaginationInput = { offset: 0, limit: 100 }
    $filters: EventsPublishedPaginatedFiltersInput = {}
  ) {
    eventsPublishedPaginated(pagination: $pagination, filters: $filters) {
      nodes {
        ...Event
      }
    }
  }
`;

export const useEventsPublishedPaginated = ({
  filters,
}: Pick<QueryEventsPublishedPaginatedArgs, 'filters'>) => {
  const { data, loading, error, refetch } = useQuery<
    Pick<Query, 'eventsPublishedPaginated'>,
    Pick<QueryEventsPublishedPaginatedArgs, 'filters'>
  >(eventsPublishedPaginatedGql, { variables: { filters } });

  const eventsPublishedPaginated = data?.eventsPublishedPaginated.nodes || [];

  return { eventsPublishedPaginated, loading, error, refetch };
};
