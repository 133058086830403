import { Image } from '@chakra-ui/react';

import { Modal } from 'components/Modal';
import { useLibraryContext } from 'features/Library/context';
import { LibraryMediaOutput } from 'types';

enum PreviewType {
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
  IMAGE = 'IMAGE',
}

const getPreviewType = ({
  mimeType,
}: Pick<LibraryMediaOutput, 'mimeType'>): PreviewType => {
  if (!mimeType) {
    throw new Error('mimeType missing');
  }

  if (/^audio\/\w*$/.test(mimeType)) {
    return PreviewType.AUDIO;
  }

  if (/^video\/\w*$/.test(mimeType)) {
    return PreviewType.VIDEO;
  }

  if (/^image\/\w*$/.test(mimeType)) {
    return PreviewType.IMAGE;
  }

  throw new Error('mimeType not handled');
};

export const PreviewModal = () => {
  const { previewModal } = useLibraryContext();

  if (!previewModal.item) {
    return null;
  }

  const previewType = getPreviewType({ mimeType: previewModal.item.mimeType });

  return (
    <Modal
      isOpen={previewModal.isOpen}
      onClose={previewModal.onClose}
      containerStyling={{
        pt: 8,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {previewType === PreviewType.IMAGE && (
        <Image
          src={previewModal.item.downloadUrl}
          alt={previewModal.item.name}
        />
      )}
      {previewType === PreviewType.AUDIO && (
        <audio controls autoPlay>
          <track kind="captions" />
          <source
            src={previewModal.item.downloadUrl}
            type={previewModal.item.mimeType}
          />
        </audio>
      )}
      {previewType === PreviewType.VIDEO && (
        <video controls autoPlay>
          <track kind="captions" />
          <source
            src={previewModal.item.downloadUrl}
            type={previewModal.item.mimeType}
          />
        </video>
      )}
    </Modal>
  );
};
