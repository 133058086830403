import { gql, useMutation } from '@apollo/client';

import { extendedProfileGql } from 'hooks/profiles/useExtendedProfile';
import { extendedProfilesGql } from 'hooks/profiles/useExtendedProfiles';
import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationUpdateFamilyHeadArgs } from 'types';

const updateFamilyHeadGql = gql`
  mutation UpdateFamilyHead($familyId: String!, $newFamilyHeadUserId: String!) {
    updateFamilyHead(
      familyId: $familyId
      newFamilyHeadUserId: $newFamilyHeadUserId
    )
  }
`;

export const useUpdateFamilyHead = () => {
  const [mutation] = useMutation<
    Pick<Mutation, 'updateFamilyHead'>,
    MutationUpdateFamilyHeadArgs
  >(updateFamilyHeadGql, {
    fetchPolicy: 'no-cache',
    refetchQueries: [extendedProfilesGql, extendedProfileGql],
  });

  const handleMutation = (variables: MutationUpdateFamilyHeadArgs) =>
    mutation({ variables });

  const updateFamilyHead = useWithMutationToast(handleMutation, {
    disableSuccessMessage: true,
  });

  return { updateFamilyHead };
};
