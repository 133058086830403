import { Flex, Text, Heading } from '@chakra-ui/react';

import { categoryDescriptions } from './content';
import { Category } from './types';

export const ValueInfo = ({ category }: { category: Category }) => {
  const value = categoryDescriptions[category];

  const { title, subtitle1, description1, subtitle2, description2 } = value;

  return (
    <Flex direction="column" gap={4} maxW={490} flex={1} minH={320}>
      <Heading size="h3">{title}</Heading>
      <Heading size="h5">{subtitle1}</Heading>
      <Text size="p2">{description1}</Text>
      <Heading size="h5">{subtitle2}</Heading>
      <Text size="p2">{description2}</Text>
    </Flex>
  );
};
