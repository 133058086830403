import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Avatar } from '@chakra-ui/react';

import { Links } from 'router/links';
import { Conversation as ConversationType, Message, Profile } from 'types';
import { getAvatarSrc } from 'utils/file';
import { generateUrlById } from 'utils/links';
import { isItriomStaff } from 'utils/roles';
import { getFullName } from 'utils/string';

import { useOtherConversationMembers } from '../../hooks/useOtherConversationMembers';
import {
  formatMessageDateTime,
  getGroupChatSubtitle,
  getGroupChatTitle,
  isGroupChat,
} from '../../utils';
import { GroupConversationAvatars } from '../GroupConversationAvatars';
import { ConversationActionMenu } from './ConversationActionMenu';
import { ConversationTileLayout } from './ConversationTileLayout';

type Props = {
  conversation: ConversationType;
  firstMessage?: Message;
  otherMembers: Profile[];
  handleSetActive: () => void;
  handleDelete: () => void;
  isActive?: boolean;
  isUnread?: boolean;
};

const SingleConversation = ({
  conversation,
  firstMessage,
  otherMembers,
  handleSetActive,
  handleDelete,
  isActive = false,
  isUnread = false,
}: Props) => {
  const [interlocutor] = otherMembers;
  const { t } = useTranslation(['common']);

  const to = isItriomStaff(interlocutor?.role)
    ? '#'
    : generateUrlById(Links.profile, interlocutor?.id || '');

  return (
    <ConversationTileLayout
      key={conversation.id}
      title={
        interlocutor
          ? getFullName(interlocutor?.firstName, interlocutor?.lastName)
          : t('common:user-deleted')
      }
      subtitle={interlocutor?.family?.name}
      messagePreview={firstMessage?.text || ''}
      actionMenu={<ConversationActionMenu handleDelete={handleDelete} />}
      avatarSection={
        <Link to={to}>
          <Avatar src={getAvatarSrc(interlocutor?.avatarImage)} size="md" />
        </Link>
      }
      date={firstMessage && formatMessageDateTime(firstMessage.createdAt)}
      handleSelect={handleSetActive}
      isActive={isActive}
      isUnread={isUnread}
    />
  );
};

const GroupConversation = ({
  conversation,
  firstMessage,
  otherMembers,
  handleSetActive,
  handleDelete,
  isActive = false,
  isUnread = false,
}: Props) => {
  const title = getGroupChatTitle(otherMembers);

  const subtitle = getGroupChatSubtitle(conversation);

  return (
    <ConversationTileLayout
      key={conversation.id}
      title={title}
      subtitle={subtitle}
      messagePreview={firstMessage?.text || ''}
      actionMenu={<ConversationActionMenu handleDelete={handleDelete} />}
      avatarSection={<GroupConversationAvatars conversation={conversation} />}
      date={firstMessage && formatMessageDateTime(firstMessage.createdAt)}
      handleSelect={handleSetActive}
      isActive={isActive}
      isUnread={isUnread}
    />
  );
};

export const Conversation = (props: Omit<Props, 'otherMembers'>) => {
  const { conversation } = props;

  const otherMembers = useOtherConversationMembers(conversation);

  const isConversationGroupChat = isGroupChat(conversation);

  if (isConversationGroupChat) {
    return <GroupConversation {...props} otherMembers={otherMembers} />;
  }

  return <SingleConversation {...props} otherMembers={otherMembers} />;
};
