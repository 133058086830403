import { gql, useMutation } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationRemoveGroupMemberArgs } from 'types';

import { groupGql } from './useGroup';

const removeGroupMemberGql = gql`
  mutation RemoveGroupMember($memberId: String!, $groupId: String!) {
    removeGroupMember(memberId: $memberId, groupId: $groupId) {
      id
    }
  }
`;

type UseRemoveGroupMemberArgs = {
  memberId: string;
  groupId: string;
  fullName: string;
};

export const useRemoveGroupMember = ({
  groupId,
  memberId,
  fullName,
}: UseRemoveGroupMemberArgs) => {
  const [removeGroupMember] = useMutation<
    Pick<Mutation, 'removeGroupMember'>,
    MutationRemoveGroupMemberArgs
  >(removeGroupMemberGql, {
    refetchQueries: [groupGql],
  });

  const handleRemoveGroupMember = () =>
    removeGroupMember({
      variables: {
        memberId,
        groupId,
      },
    });

  const handleRemoveGroupMemberWithToast = useWithMutationToast(
    handleRemoveGroupMember,
    {
      successMessage: `You have successfully removed ${fullName} from this group.`,
      errorMessage: 'Error removing user',
    }
  );

  return handleRemoveGroupMemberWithToast;
};
