import { FC, ComponentClass, useMemo } from 'react';

import { CarbonIconType } from '@carbon/icons-react';
import {
  IconButton as IconButtonChakra,
  IconButtonProps,
} from '@chakra-ui/react';

import { withProperAriaHidden } from './withProperAriaHidden';

type Props = Omit<IconButtonProps, 'icon'> & {
  icon: CarbonIconType | FC | ComponentClass;
};

export const IconButton = ({ icon, ...props }: Props) => {
  const IconWithProperAriaHidden = useMemo(
    () => withProperAriaHidden(icon),
    [icon]
  );

  return <IconButtonChakra {...props} icon={<IconWithProperAriaHidden />} />;
};
