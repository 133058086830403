import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useTranslation } from 'react-i18next';

import { Close20 } from '@carbon/icons-react';
import { Button, Text, Heading, Grid } from '@chakra-ui/react';

import { SwiperButton } from 'components/Carousel/SwiperButton';
import { PAGINATION } from 'components/Carousel/constants';
import 'components/Carousel/paginationClass.css';

SwiperCore.use([Navigation, Pagination]);

const BUTTON_STYLING = {
  h: 8,
  bg: 'transparent',
  top: 'unset',
  bottom: 0,
  transform: 'unset',
};

type ActionWithId = {
  id: string;
  action: string;
};

type Props = {
  name: string;
  actions: [string, string, string];
  handleCloseActions: VoidFunction;
};

export const ValueActions = ({ name, actions, handleCloseActions }: Props) => {
  const { t } = useTranslation(['family']);

  const actionsWithIds: ActionWithId[] = actions.map((action, i) => ({
    action,
    id: `${i}`,
  }));

  return (
    <Grid
      position="relative"
      gridAutoFlow="row"
      gridTemplateRows="auto auto minmax(7rem, 1fr)"
      p={4}
      bgColor="ui.white"
      gap={4}
    >
      <Heading size="h4">{name}</Heading>

      <Text size="p1b">{t('family:charter.section.values.actions')}</Text>

      <Button
        position="absolute"
        right={2}
        top={2}
        p={0}
        size="xs"
        bg="ui.grey4"
        _hover={{ bg: 'ui.grey3' }}
        color="primary.blue.default"
        border="none"
        onClick={handleCloseActions}
      >
        <Close20 />
      </Button>

      <Swiper
        observer
        slidesPerView="auto"
        modules={[Pagination]}
        pagination={PAGINATION}
        style={{ width: '100%', height: '100%' }}
      >
        {actionsWithIds.map((item) => (
          <SwiperSlide key={item.id}>
            <Text size="p2">{item.action}</Text>
          </SwiperSlide>
        ))}
        <SwiperButton type="prev" buttonStyling={BUTTON_STYLING} />
        <SwiperButton type="next" buttonStyling={BUTTON_STYLING} />
      </Swiper>
    </Grid>
  );
};
