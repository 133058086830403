import { gql, useMutation } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationCreateAdvisorArgs } from 'types';

import {
  advisorGql,
  advisorByRelatedAccountIdGql,
  listEditableAdvisorsGql,
} from '../documents';

const createAdvisorGql = gql`
  mutation CreateAdvisor($data: CreateAdvisorInput!) {
    createAdvisor(data: $data) {
      id
    }
  }
`;

export const useCreateAdvisor = () => {
  const [createAdvisorMutation] = useMutation<
    Pick<Mutation, 'createAdvisor'>,
    MutationCreateAdvisorArgs
  >(createAdvisorGql, {
    refetchQueries: [
      advisorGql,
      listEditableAdvisorsGql,
      advisorByRelatedAccountIdGql,
    ],
  });

  const createAdvisorWithToast = useWithMutationToast(createAdvisorMutation, {
    successMessage: 'Advisor created successfully',
    errorMessage: 'Error creating advisor',
  });

  return createAdvisorWithToast;
};
