import { Flex, Text } from '@chakra-ui/react';

import { INFO_SEPARATOR } from '../../constants';

interface Props {
  items: string[];
}

export const InfoItems = ({ items }: Props) => {
  const itemsWithSeparator = items.flatMap((item, index) =>
    items.length - 1 > index ? [item, INFO_SEPARATOR] : item
  );

  return (
    <Flex gap={4} justifyContent="center" alignItems="center">
      {itemsWithSeparator.map((item, i) => (
        <Text size="p2" whiteSpace="nowrap" key={i}>
          {item}
        </Text>
      ))}
    </Flex>
  );
};
