export const selectInputStyles = { maxWidth: 310, height: 45 };

export const bottomButtonStyles = {
  justifyContent: 'start',
  alignItems: 'start',
  p: 2,
  boxSizing: 'border-box',
  minHeight: '64px',
  border: 'none',
  mb: '-32px',
};
