import { useParams } from 'react-router-dom';

import { makeContext } from 'contexts/MakeContext';
import { useGroup } from 'hooks/groups';

export const [useGroupContext, GroupContextProvider] = makeContext(() => {
  const { id } = useParams();

  if (!id) throw new Error("Couldn't find group");

  const { loading, error, group } = useGroup(id);

  const canSeeGroup = group?.canBeModified;
  const isMember = group?.isMember;

  return {
    error,
    loading,
    group,
    canSeeGroup,
    isMember,
  };
});
