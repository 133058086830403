import {
  OnboardManagementSdgConfiguratorStatusEnum,
  OnboardManagementValueConfiguratorStatusEnum,
  SdgConfiguratorStatusEnum,
  ValueConfiguratorStatusEnum,
} from 'types';

export const statusToastOptions = {
  successMessage: 'Status changed successfully',
  errorMessage: 'Changing the status failed',
};

export type OnboardManagementStatus =
  | OnboardManagementSdgConfiguratorStatusEnum
  | OnboardManagementValueConfiguratorStatusEnum
  | SdgConfiguratorStatusEnum
  | ValueConfiguratorStatusEnum;

export const configuratorStatus: Record<`${OnboardManagementStatus}`, string> =
  {
    Completed: 'Completed',
    InProgress: 'In-progress',
    NotStarted: 'Not started',
    OptOut: 'No show',
    NotCompleted: 'Not completed',
    ImportanceFinished: 'Importance finished',
  };
