import { ReactNode } from 'react';

import { Flex, Heading } from '@chakra-ui/react';

export const ContentWithHeading = ({
  children,
  heading,
}: {
  children: ReactNode;
  heading: string;
}) => (
  <Flex direction="column" gap={4}>
    <Heading size="h4">{heading}</Heading>
    {children}
  </Flex>
);
