import { useAuthContext } from 'contexts/AuthContext';
import { isItriomStaff } from 'utils/roles';

export const useItriomStaffFilter = () => {
  const { account } = useAuthContext();

  const filters =
    isItriomStaff(account?.role) || !account?.id
      ? {}
      : { createdBy: [account.id] };

  return filters;
};
