import { useTranslation } from 'react-i18next';

import { useToast } from '@chakra-ui/react';

import { useCommitFileInLibrary } from 'gql/upload/mutation/commitFileInLibrary';
import { useSignedUploadUrl } from 'gql/upload/query/signedUploadUrl';
import { humanFileSize } from 'utils/size';
import { getFileTypeFromName } from 'utils/string';

import { UploadingFile, useLibraryContext } from '../context';
import { mapLibraryLocationToLibraryType } from '../utils/mapLibraryLocationToLibraryType';
import { useCurrentLibraryLocation } from './useCurrentLibraryLocation';

const uploadFile = (
  url: string,
  file: File,
  progressCallback: (item: UploadingFile) => void
) => {
  return new Promise((resolve, reject) => {
    const request = new XMLHttpRequest();
    request.open('PUT', url);
    request.setRequestHeader('Content-Type', file.type);

    // upload progress event
    request.upload.addEventListener('progress', (e) => {
      // upload progress as percentage
      const percentCompleted = (e.loaded / e.total) * 100;

      progressCallback({
        name: file.name,
        size: humanFileSize(file.size),
        progress: percentCompleted,
      });
    });

    request.addEventListener('error', (e) => {
      progressCallback({
        name: file.name,
        size: humanFileSize(file.size),
        hasError: true,
      });
      reject(e);
    });

    // request finished event
    request.addEventListener('load', () => {
      // HTTP status message (200, 404 etc)
      if (request.status === 200) {
        resolve({});
      } else {
        progressCallback({
          name: file.name,
          size: humanFileSize(file.size),
          hasError: true,
        });
        reject(request.statusText);
      }
    });

    window.addEventListener('ABORT_UPLOAD', () => {
      request.abort();
      reject();
    });

    request.send(file);
  });
};

export const useLibraryUpload = () => {
  const { t } = useTranslation(['library']);
  const { getSignedUploadUrl } = useSignedUploadUrl();
  const { commitFileInLibrary } = useCommitFileInLibrary();
  const { libraryLocation, folderId } = useCurrentLibraryLocation();

  const { updateUploadingItem } = useLibraryContext();
  const toast = useToast();

  const uploadLibrary = mapLibraryLocationToLibraryType(libraryLocation);

  const upload = async (file: File) => {
    const fileExtension = getFileTypeFromName(file);

    if (!fileExtension || !uploadLibrary) {
      throw new Error();
    }

    const { data, loading, error } = await getSignedUploadUrl(fileExtension);

    if (error) {
      throw new Error(error.message);
    }

    if (!data?.signedUploadUrl) {
      toast({
        title: t('library:toasts.upload-fail-title'),
        description: 'something went wrong, try again',
        status: 'error',
        isClosable: true,
      });
      throw new Error('something went wong');
    }

    const { temporaryFileKey, signedUrl } = data.signedUploadUrl;

    try {
      uploadFile(signedUrl, file, updateUploadingItem).then(() => {
        commitFileInLibrary({
          libraryType: uploadLibrary,
          temporaryFileKey,
          fileName: file.name,
          destinationId: folderId,
        });
      });
    } catch (err) {
      if (err instanceof Error) {
        toast({
          title: t('library:toasts.upload-fail-title'),
          description: err.message,
          status: 'error',
          isClosable: true,
        });
      }
    }
  };

  return { upload };
};
