import { gql, useQuery } from '@apollo/client';

import { ProfileWithImages } from 'gql/fragments';
import { Query } from 'types';

export const suggestedAgendaSpeakersGql = gql`
  ${ProfileWithImages}
  query SuggestedAgendaSpeakers {
    suggestedAgendaSpeakers {
      nodes {
        ...ProfileWithImages
      }
    }
  }
`;

export const useSuggestedAgendaSpeakers = () => {
  const { data, loading, error } = useQuery<
    Pick<Query, 'suggestedAgendaSpeakers'>
  >(suggestedAgendaSpeakersGql);

  const suggestedAgendaSpeakers = data?.suggestedAgendaSpeakers?.nodes ?? [];

  const getSuggestedAgendaSpeakers = (disabledIds: string[] = []) =>
    suggestedAgendaSpeakers.map((member) => ({
      label: `${member.firstName} ${member.lastName}`,
      value: member.id,
      disabled: disabledIds.includes(member.id),
    }));

  const getSuggestedAgendaSpeaker = (id?: string) =>
    suggestedAgendaSpeakers.find((member) => member.id === id);

  return {
    suggestedAgendaSpeakers,
    loading,
    error,
    getSuggestedAgendaSpeakers,
    getSuggestedAgendaSpeaker,
  };
};
