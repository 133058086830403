import { Box, Heading, Image } from '@chakra-ui/react';

import { ContentSection } from 'components/CmsLayout';
import { HeroMedia } from 'components/HeroMedia';
import { MarkdownPreview } from 'components/MarkdownPreview';
import { MultiCarousel } from 'components/MultiCarousel';
import { AdvisorAdvocacyOutput } from 'types';

export const Advocacy = ({ advocacy }: { advocacy: AdvisorAdvocacyOutput }) => {
  const {
    images,
    content,
    heading,
    heroVideo,
    heroImage,
    preferredHeroMediaToDisplay,
  } = advocacy;

  return (
    <>
      <ContentSection title="Advocacy">
        <Box>
          <MultiCarousel
            items={images}
            renderItem={(item) => (
              <Image
                src={item.signedUrl}
                w={200}
                h={200}
                objectFit="cover"
                objectPosition="center"
              />
            )}
          />
        </Box>
        <Box>
          {heading && <Heading size="h4">{heading}</Heading>}
          <MarkdownPreview content={content} />
        </Box>
      </ContentSection>
      <HeroMedia
        videoUrl={heroVideo?.signedUrl}
        imageUrl={heroImage?.signedUrl}
        preferredHeroMediaToDisplay={preferredHeroMediaToDisplay}
        containerStyles={{
          h: 440,
          w: 'full',
        }}
      />
    </>
  );
};
