import dayjs from 'dayjs';
import { FormikProvider, useFormik } from 'formik';

import { CloseIcon } from '@chakra-ui/icons';
import { Flex, Text } from '@chakra-ui/react';

import { SelectInput } from 'components/Form/SelectInput';
import { EventFilters, InputMaybe } from 'types';

import { useEventFilters } from './utils/useEventFilters';

interface EventsFiltersProps {
  setFilters: (value: InputMaybe<EventFilters>) => void;
  clearEventsFilters: () => void;
  filters: InputMaybe<EventFilters>;
  filtersEnabled: boolean;
  setFiltersEnabled: (value: boolean) => void;
}

export const EventsFilters = ({
  setFilters,
  clearEventsFilters,
  filters,
  filtersEnabled,
  setFiltersEnabled,
}: EventsFiltersProps) => {
  const {
    yearFilter,
    yearOptions,
    setYearFilter,
    selectedYearLabel,
    monthFilter,
    monthOptions,
    setMonthFilter,
    selectedMonthLabel,
    selectedSDGLabel,
    setSdgFilter,
    sdgOptions,
    currentYear,
  } = useEventFilters();

  const formik = useFormik({
    initialValues: {},
    onSubmit: (e) => undefined,
  });

  const filterBySdg = (value: string) => {
    setSdgFilter(value);
    const newFilters = {
      ...filters,
      tags: {
        sdGoalTagId: value,
      },
    };
    setFilters(newFilters);
    setFiltersEnabled(true);
  };

  const filterByYear = (value: string) => {
    setYearFilter(value);

    if (value === '0') {
      setMonthFilter('Month');
    }

    const fromMonth = monthFilter !== 'Month' ? monthFilter : '1';
    const toMonth = monthFilter !== 'Month' ? monthFilter : '12';

    const fromDate = `${value}-${fromMonth}-01`;
    const toDate = `${value}-${toMonth}-31 23:59:59`;
    const allDates = `${1978}-12-31 23:59:59`;

    const newFilters =
      value !== '0'
        ? {
            ...filters,
            from: +dayjs(fromDate, 'x'),
            to: +dayjs(toDate, 'x'),
          }
        : {
            ...filters,
            from: +dayjs(allDates, 'x'),
            to: null,
          };

    setFilters(newFilters);
    setFiltersEnabled(true);
  };

  const filterByMonth = (value: string) => {
    setMonthFilter(value);

    const selectedYear = yearFilter !== 'Year' ? yearFilter : currentYear;

    const fromDate = `${selectedYear}-${value}-01`;
    const toDate = `${selectedYear}-${value}-31 23:59:59`;

    const newFilters = {
      ...filters,
      from: +dayjs(fromDate, 'x'),
      to: +dayjs(toDate, 'x'),
    };
    setFilters(newFilters);
    setFiltersEnabled(true);
  };

  return (
    <Flex
      direction="row"
      alignItems="center"
      justifyContent="start"
      color="primary.blue.default"
      marginTop="30px"
      pb={8}
    >
      <FormikProvider value={formik}>
        <Text size="p1" fontWeight="medium">
          Filter:
        </Text>
        <SelectInput
          onChange={filterBySdg}
          name="sdg"
          options={sdgOptions}
          defaultOptionText={selectedSDGLabel ?? 'Choose the SDG'}
          containerStyles={{
            width: 245,
            marginInline: '10px',
            marginLeft: '20px',
          }}
        />
        <SelectInput
          name="year"
          options={yearOptions}
          onChange={filterByYear}
          defaultOptionText={selectedYearLabel ?? 'Year'}
          containerStyles={{ width: 110, marginInline: '10px' }}
        />
        <SelectInput
          name="month"
          options={monthOptions}
          defaultOptionText={selectedMonthLabel ?? 'Month'}
          containerStyles={{ width: 141, marginInline: '10px' }}
          onChange={filterByMonth}
          disabled={yearFilter === '0'}
        />
        {filtersEnabled ? (
          <Flex
            direction="row"
            marginLeft="20px"
            justifyContent="center"
            alignItems="center"
            cursor="pointer"
            onClick={() => {
              setYearFilter('Year');
              setMonthFilter('Month');
              setSdgFilter('Choose the SDG');
              clearEventsFilters();
            }}
          >
            <Text size="p1" fontWeight="medium">
              Clear filters
            </Text>
            <CloseIcon width={10} height={3} />
          </Flex>
        ) : null}
      </FormikProvider>
    </Flex>
  );
};
