import { useState } from 'react';

import {
  Button,
  Flex,
  ModalBody,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Heading,
} from '@chakra-ui/react';

type Props = {
  onSubmit: () => Promise<void>;
  disabled?: boolean;
};

export const SubmitButtonWithModal = ({ onSubmit, disabled }: Props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOpen = () => setIsModalVisible(true);

  const handleClose = () => setIsModalVisible(false);

  const handleSubmit = () => onSubmit().finally(handleClose);

  return (
    <>
      <Button
        ml="auto"
        variant="solid"
        color="primary.blue.default"
        background="primary.lime.default"
        type="button"
        onClick={handleOpen}
        disabled={disabled}
        h={12}
        w={216}
        borderRadius={2}
      >
        <Text size="p2">Submit your goals</Text>
      </Button>
      <Modal isOpen={isModalVisible} onClose={handleClose} isCentered size="lg">
        <ModalOverlay />
        <ModalContent p={4}>
          <ModalHeader>
            <Heading size="h5">Submit SDG ordering?</Heading>
          </ModalHeader>
          <ModalBody>
            <Text size="p1">
              Are you sure you want to submit your SDGs ordering? Once
              submitted, you will no longer be able to change the order.category
              that you have said is the most important and relevant to you.
            </Text>
          </ModalBody>

          <ModalFooter>
            <Flex justify="space-between" mt={10} w="full">
              <Button
                variant="outline"
                onClick={handleClose}
                h={12}
                w={120}
                borderRadius={2}
                borderColor="primary.blue.default"
              >
                <Text size="p1">Cancel</Text>
              </Button>
              <Button
                variant="solid"
                background="primary.lime.default"
                type="button"
                onClick={handleSubmit}
                h={12}
                w={216}
                borderRadius={2}
              >
                <Text size="p1">Submit your goals</Text>
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
