import {
  Account,
  AccountStatus,
  Honorific,
  OnboardAccountStatus,
  Role,
} from 'types';

import { generateMedia } from './images.mock';

export const generateAccount = ({
  __typename = 'Account',
  avatarImage = generateMedia(),
  connections = [],
  createdAt = Date.now(),
  description,
  email = 'adi@itriom.com',
  familyId,
  firstName = 'Adi',
  heroImage = generateMedia(),
  id = '61eabc70322b2415e6e53272',
  isFamilyAdmin = false,
  lastName = 'Godrej',
  role = Role.FamilyMember,
  status = AccountStatus.Active,
  honorific = Honorific.Mr,
  title,
  onboardingStatus = OnboardAccountStatus.Onboarded,
  updatedAt = Date.now(),
}: Partial<Account> = {}): Account => ({
  __typename,
  avatarImage,
  connections,
  createdAt,
  honorific,
  description,
  email,
  familyId,
  firstName,
  heroImage,
  id,
  onboardingStatus,
  isFamilyAdmin,
  lastName,
  role,
  status,
  title,
  updatedAt,
});

export const accountMock = generateAccount();
