import { useMessagingCommunication } from '../MessagingCommunication';
import { NewConversationInput } from '../MessagingCommunication/types';
import { useConversationsActions } from '../MessagingStateManager/hooks';
import { useLoadConversations } from './useLoadConversations';

export const useCreateConversation = () => {
  const { createNewConversation } = useMessagingCommunication();
  const { handleConversationSelect } = useConversationsActions();

  const loadConversations = useLoadConversations();

  const handleCreateNewConversation = async (
    newConversationInput: NewConversationInput
  ) => {
    const newConversationId = await createNewConversation(newConversationInput);
    await loadConversations();

    if (newConversationId) handleConversationSelect(newConversationId);
  };

  return handleCreateNewConversation;
};
