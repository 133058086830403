import { ReactNode } from 'react';
import { useDrag } from 'react-dnd';

import { Box, BoxProps } from '@chakra-ui/react';

type Props<T> = {
  children: ReactNode;
  item: T;
  type: string;
  width?: BoxProps['width'];
  height?: BoxProps['height'];
};

export const DragItem = <T extends { id: string | number }>({
  children,
  type,
  item,
  height = 32,
  width = 32,
}: Props<T>) => {
  const [, drag] = useDrag<T>(
    () => ({
      type,
      item,
    }),
    [item]
  );

  return (
    <Box w={width} h={height} ref={drag}>
      {children}
    </Box>
  );
};
