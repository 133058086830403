import { useInviteToConnections } from 'gql/connections/mutation/inviteToConnections';
import { ProfileConnectionStateEnum } from 'types';

import { ProfileConnectionButton } from './ProfileConnectionButton';
import { ConnectCtaProps } from './types';

const getConnectButtonText = (
  connectionState: ProfileConnectionStateEnum | undefined
): string => {
  switch (connectionState) {
    case ProfileConnectionStateEnum.InvitationPending:
      return 'Invitation sent. Connection is pending.';
    case ProfileConnectionStateEnum.Connected:
      return 'You are now connected in Itriom.';
    case ProfileConnectionStateEnum.NotConnected:
    case undefined:
    default:
      return 'Connect';
  }
};

export const ConnectButton = ({
  userName,
  userId,
  connectionState,
}: ConnectCtaProps) => {
  const { inviteToConnections } = useInviteToConnections({
    customSuccessMessage: `You've sent an invitation to ${userName} to connect.`,
  });

  const isConnected = connectionState === ProfileConnectionStateEnum.Connected;

  const handleClick = async () => {
    if (!isConnected) {
      await inviteToConnections(userId);
    }
  };

  const buttonText = getConnectButtonText(connectionState);

  return (
    <ProfileConnectionButton handleClick={handleClick} text={buttonText} />
  );
};
