import { useTranslation, TFuncKey } from 'react-i18next';

import { FamilyValuesCategoryEnum } from 'types';

type CategoryCopyType = {
  name: string;
  question: string;
  description: string;
};

const isCategoryType = (obj: unknown): obj is CategoryCopyType => {
  const assertedObj = obj as CategoryCopyType;

  const hasName = typeof assertedObj.name === 'string';

  const hasQuestion = typeof assertedObj.question === 'string';

  const hasDescription = typeof assertedObj.description === 'string';

  return hasName && hasQuestion && hasDescription;
};

const categoryTransCopyKeyMap: Record<
  FamilyValuesCategoryEnum,
  TFuncKey<'values'>
> = {
  [FamilyValuesCategoryEnum.Advancement]: 'categories.advancement',
  [FamilyValuesCategoryEnum.Contribution]: 'categories.contribution',
  [FamilyValuesCategoryEnum.Organisational]: 'categories.organisational',
  [FamilyValuesCategoryEnum.Societal]: 'categories.societal',
};

export const useCategoryCopy = () => {
  const { t } = useTranslation(['values']);

  const getCategoryCopy = (
    categoryName: FamilyValuesCategoryEnum
  ): CategoryCopyType => {
    const categoryCopyObject = t(
      `values:${categoryTransCopyKeyMap[categoryName]}`,
      {
        returnObjects: true,
      }
    );

    if (!isCategoryType(categoryCopyObject)) {
      throw new Error(
        `Value category copy object retrieved from i18n is in wrong format: ${categoryName}`
      );
    }

    return categoryCopyObject;
  };

  return { getCategoryCopy };
};
