import { useTranslation } from 'react-i18next';

import { Flex } from '@chakra-ui/react';

import { LayoutGrid, LayoutGridElement } from 'components/Layout/Grid';
import { PageHeader } from 'components/PageHeader';
import { NewPostForm } from 'components/Posts/PostForm';
import { TrendingGroups } from 'components/TrendingGroups';
import { YourGroups } from 'components/YourGroups';
import { postsGql } from 'hooks/posts';
import { PostSource } from 'types';

import { ForumSider } from '../components/ForumSider';
import { ForumTabs } from '../components/ForumTabs';

export const ForumPage = () => {
  const { t } = useTranslation(['common']);

  return (
    <LayoutGrid>
      <LayoutGridElement>
        <PageHeader
          name={t('common:the-entity', { entity: t('common:forum') })}
        />
      </LayoutGridElement>

      <LayoutGridElement colSpan={4}>
        <ForumSider />
      </LayoutGridElement>

      <LayoutGridElement colSpan={8}>
        <Flex direction="column">
          <NewPostForm
            source={PostSource.Forum}
            refetchQueries={[postsGql]}
            withTitle
          />
          <ForumTabs />
        </Flex>
      </LayoutGridElement>

      <LayoutGridElement colSpan={4}>
        <Flex direction="column" gap={4}>
          <YourGroups />
          <TrendingGroups />
        </Flex>
      </LayoutGridElement>
    </LayoutGrid>
  );
};
