import { Form, useFormik, FormikProvider } from 'formik';

import { Box, Grid, HStack, Text, Flex, Heading } from '@chakra-ui/react';

import { Loader } from 'components/Loader';
import { valueCategorySchema } from 'constants/ValuesConfigurator';
import {
  ValueConfiguratorCategoryEnum,
  ValueConfiguratorOutput,
  ValueConfiguratorStatusEnum,
} from 'types';
import { capitalize } from 'utils/string';

import { BubbleStepper } from '../BubbleStepper';
import { valuesSchema } from '../Steps/validation';
import { HiddenAllocateButton } from './HiddenAllocateButton';
import {
  AllocatedPoints,
  GuideModal,
  SliderField,
  SubmitButton,
} from './components';
import { VALUES_CATEGORY_DEFINITION } from './constants';
import { getValidInitialValues } from './getValidInitialValues';
import { ValuesForm, ValuesInputs, ValueType } from './types';
import { useValuesConfigurator } from './useValuesConfigurator';

type ValuesConfiguratorFormProps<T extends ValuesInputs> = {
  onSubmit: (val: T) => void;
  category: ValuesForm<T>;
  valuesCategory: ValueConfiguratorCategoryEnum;
  initialValues?: Partial<T>;
};

type ContentFormProps<T extends ValuesInputs> =
  ValuesConfiguratorFormProps<T> & {
    valueConfigurator?: ValueConfiguratorOutput;
  };

const ContentForm = <T extends ValuesInputs>({
  onSubmit,
  category,
  valuesCategory,
  valueConfigurator,
  initialValues,
}: ContentFormProps<T>) => {
  const { valueCategoryName, fields, description } = category;
  const valueKey: ValueType = `${valueCategoryName}Values`;
  const categoryKey =
    valuesCategory.toLowerCase() as Lowercase<ValueConfiguratorCategoryEnum>;

  const currentInitialValues = getValidInitialValues<T>(
    valueConfigurator,
    valueKey,
    categoryKey
  );

  const hasValues = !!currentInitialValues;

  const initialValuesForm = fields
    .map((field) => field.name)
    .reduce<T>((p, n) => {
      const value = currentInitialValues
        ? currentInitialValues[n]
        : initialValues?.[n] ?? 0;

      return {
        ...p,
        [n]: value,
      };
    }, {} as T);

  const formik = useFormik<T>({
    initialValues: initialValuesForm,
    onSubmit,
    validationSchema: valuesSchema(valueCategorySchema[valueCategoryName]),
  });

  const allocatedPoints = Object.values(formik.values).reduce(
    (previousValue, currentValue) => previousValue + currentValue
  );

  return (
    <Box>
      <HStack justifyContent="space-between" mb={5}>
        <Box>
          <Heading
            size="h6"
            alignSelf="flex-start"
            textTransform="uppercase"
            alignItems="flex-start"
          >
            {valuesCategory}
          </Heading>
          <Text size="p2">{VALUES_CATEGORY_DEFINITION[valuesCategory]}</Text>
        </Box>

        <BubbleStepper activeStep={valueCategoryName} />
      </HStack>
      <Grid
        gridTemplateColumns={{ base: '1fr', md: '3fr 1fr 2fr' }}
        gap={2}
        p={6}
        bgColor="primary.blue.default"
        color="ui.white"
        alignItems="flex-start"
        borderRadius="10px 10px 0 0"
      >
        <HStack>
          <Flex h="100%" justify="center">
            <Heading size="h4" ml={{ base: 0, md: 10 }} alignSelf="flex-start">
              {capitalize(valueCategoryName)}
            </Heading>
          </Flex>

          <Text>{description || ''}</Text>
        </HStack>
        <Box />
        <Flex>
          <Text>
            The total allocated must be equal to your budget of 100 points. You
            may not allocate the same points to 2 or more values.
          </Text>
          <GuideModal />
        </Flex>
      </Grid>
      <Box bgColor="#E7F3F6" borderRadius="0 0 10px 10px" py={6} px={2}>
        <FormikProvider value={formik}>
          <Form>
            {fields.map((field) => (
              <SliderField field={field} key={String(field.name)} />
            ))}
            <AllocatedPoints points={allocatedPoints}>
              <Flex>
                <HiddenAllocateButton />
                <SubmitButton
                  currentStep={valueCategoryName}
                  wasSubmitted={
                    valueConfigurator?.status ===
                      ValueConfiguratorStatusEnum.Completed || hasValues
                  }
                />
              </Flex>
            </AllocatedPoints>
          </Form>
        </FormikProvider>
      </Box>
    </Box>
  );
};

export const ValuesConfiguratorForm = <T extends ValuesInputs>(
  props: ValuesConfiguratorFormProps<T>
) => {
  const { valueConfigurator, loading, error } = useValuesConfigurator();

  if (loading) return <Loader />;

  const data = error ? undefined : valueConfigurator;

  return <ContentForm {...props} valueConfigurator={data} />;
};
