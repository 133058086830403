import { useCallback } from 'react';

import { useMutation } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationSendMessageArgs } from 'types';

import { NewMessageInput } from '../types';
import { sendMessageGql } from './sendMessageGql';

export const useSendMessage = () => {
  const [sendMessageMutation] = useMutation<
    Pick<Mutation, 'sendMessage'>,
    MutationSendMessageArgs
  >(sendMessageGql);

  const sendMessage = useCallback(
    async (newMessage: NewMessageInput) => {
      await sendMessageMutation({
        variables: { message: newMessage },
      });
    },
    [sendMessageMutation]
  );

  const sendMessageWithToast = useWithMutationToast(sendMessage, {
    successMessage: 'Successfully sent message',
    errorMessage: 'Error sending message',
  });

  return sendMessageWithToast;
};
