import { WithTemplateComponent } from 'types';

import { GroupSectionTemplate } from './GroupSectionTemplate';
import { GroupSectionTemplateProps, GroupSectionProps } from './types';

export const GroupSection = ({
  heading,
  groups,
  TemplateComponent = GroupSectionTemplate,
}: WithTemplateComponent<GroupSectionProps, GroupSectionTemplateProps>) => {
  if (!groups || groups.length === 0) return null;

  return <TemplateComponent groups={groups} heading={heading} />;
};
