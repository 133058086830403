import { ReactNode } from 'react';

import { OverflowMenuHorizontal } from '@carbon/icons-react/next';
import { CloseIcon } from '@chakra-ui/icons';
import {
  Flex,
  IconButton,
  MenuButton,
  Menu,
  MenuList,
  FlexProps,
  ButtonProps,
  MenuListProps,
  useDisclosure,
} from '@chakra-ui/react';

import { WithChildren } from 'types';

type Props = {
  containerStyling?: FlexProps;
  buttonStyling?: ButtonProps;
  menuListStyling?: MenuListProps;
  icon?: ReactNode;
  withCloseIcon?: boolean;
};

// TODO: https://itriom.atlassian.net/browse/LEIP-2963 remove this component and use ThreeDotsMenu
export const ActionMenuLayout = ({
  children,
  buttonStyling,
  containerStyling,
  menuListStyling,
  icon = <OverflowMenuHorizontal />,
  withCloseIcon,
}: WithChildren<Props>) => {
  const disclosure = useDisclosure();

  const shouldShowCloseIcon = withCloseIcon && disclosure.isOpen;

  return (
    <Flex color="primary.blue.default" {...containerStyling}>
      <Menu {...disclosure}>
        <MenuButton
          as={IconButton}
          icon={shouldShowCloseIcon ? <CloseIcon w="9x" h="9px" /> : icon}
          variant="ghost"
          border="none"
          color="ui.white"
          onClick={(e) => {
            e.stopPropagation();
          }}
          _hover={{ bg: 'transparent' }}
          _focus={{ bg: 'transparent' }}
          _active={{ bg: 'transparent' }}
          {...buttonStyling}
        />
        <MenuList fontSize="sm" {...menuListStyling}>
          {children}
        </MenuList>
      </Menu>
    </Flex>
  );
};
