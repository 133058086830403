import { useState } from 'react';

import { StackDivider, Heading, Center, Flex } from '@chakra-ui/react';

import { ValueBlock } from './ValueBlock';
import { values } from './content';
import { Category } from './types';

export const About = () => {
  const [activeValue, setActiveValue] = useState<Category | null>(null);

  return (
    <Center flex={1} maxW={560} w="full">
      <Flex alignItems="flex-start" direction="column" w="full">
        <Heading size="h1">What do family values provide?</Heading>
        <StackDivider
          borderColor="ui.white"
          borderBottomWidth={3}
          w={14}
          my={5}
        />
        <Heading size="h5">Click each category to discover more</Heading>
        <Flex direction="column" minH={420} w="full" mt={10}>
          {Object.entries(values).map(([category, value]) => (
            <ValueBlock
              {...value}
              key={value.title}
              isActive={category === activeValue}
              handleClick={() =>
                setActiveValue(activeValue === category ? null : category)
              }
            />
          ))}
        </Flex>
      </Flex>
    </Center>
  );
};
