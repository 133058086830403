import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Flex, Grid, Text } from '@chakra-ui/react';

import { ArticleBody } from 'components/ArticleBody/ArticleBody';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { ImageTextTile } from 'components/ImageTextTile';
import { LayoutGrid, LayoutGridElement } from 'components/Layout/Grid';
import { QueryStateGuard } from 'components/QueryStateGuard';
import { useAuthContext } from 'contexts/AuthContext';
import { useInitiative } from 'hooks/initiatives';
import { Links } from 'router/links';
import { InitiativeBlockUnion } from 'types';
import { generateUrlById } from 'utils/links';

import { EditContentButton } from '../../../components/EditContentButton';
import { InitiativeHero } from '../components';
import { InitiativeComments } from '../components/InitiativeComments';
import { shouldForbidContentDisplay } from '../shouldForbidContentDisplay';

export const InitiativePage = () => {
  const { id } = useParams();
  const { account, loading: accountLoading } = useAuthContext();
  const { t } = useTranslation(['navigation', 'common']);
  if (!id) throw new TypeError('Initiative id is required');
  const { initiative, error, loading: initiativeLoading } = useInitiative(id);

  if (
    shouldForbidContentDisplay(
      initiativeLoading || accountLoading,
      initiative,
      account
    )
  ) {
    return (
      <LayoutGrid>
        <Text>{t('common:content-unavailable')}</Text>
      </LayoutGrid>
    );
  }

  const sortedBlocks =
    initiative && [...initiative.blocks].sort((a, b) => a.order - b.order);

  return (
    <QueryStateGuard loading={initiativeLoading} error={error}>
      {initiative && (
        <LayoutGrid rowGap={10}>
          <LayoutGridElement>
            <Flex direction="column" gap={4}>
              <InitiativeHero initiative={initiative} />
              <Flex justifyContent="space-between">
                <Breadcrumbs
                  items={[
                    { link: Links.home, label: t('navigation:home') },
                    {
                      link: Links.initiatives,
                      label: t('navigation:cms.initiatives'),
                    },
                    {
                      link: generateUrlById(
                        Links.initiativesSingle,
                        initiative.id
                      ),
                      label: initiative.hero.headline,
                    },
                  ]}
                />
                <EditContentButton
                  item={initiative}
                  editUrl={generateUrlById(Links.editInitiative, initiative.id)}
                />
              </Flex>
            </Flex>
          </LayoutGridElement>
          <LayoutGridElement gridColumn="3/ span 12">
            <Flex direction="column" gap={10}>
              {sortedBlocks?.map((block, i) => (
                <ArticleBody body={block as InitiativeBlockUnion} key={i} />
              ))}
            </Flex>
          </LayoutGridElement>
          <LayoutGridElement gridColumn="3/ span 12">
            <Grid gridTemplateColumns="repeat(3, 1fr)" gap={4}>
              {initiative.relatedArticles.map((article) => (
                <ImageTextTile
                  key={article.id}
                  src={article.hero.heroImage.signedUrl}
                  title={article.hero.headline}
                  bodyText={article.hero.summary}
                  author={article.hero.author}
                  findOutMoreUrl={generateUrlById(Links.insight, article.id)}
                />
              ))}
            </Grid>
          </LayoutGridElement>
          <LayoutGridElement gridColumn="3/ span 12">
            <InitiativeComments />
          </LayoutGridElement>
        </LayoutGrid>
      )}
    </QueryStateGuard>
  );
};
