export const Progress = {
  variants: {
    amber: {
      track: {
        bg: 'ui.grey3',
      },
      filledTrack: {
        bg: 'status.amber.default',
      },
    },
  },
};
