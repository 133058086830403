import { gql, useMutation } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import {
  FamilyCharterGoalInput,
  Mutation,
  MutationUpdateFamilyCharterGoalsArgs,
} from 'types';

export type FamilyCharterGoalInputsWithId = {
  id: string;
  goals: FamilyCharterGoalInput[];
};

export const updateFamilyCharterGoalsGql = gql`
  mutation UpdateFamilyCharterGoals($data: UpdateFamilyCharterGoalsInput!) {
    updateFamilyCharterGoals(data: $data) {
      id
    }
  }
`;

export const useUpdateFamilyCharterGoals = () => {
  const [updateFamilyCharterGoalsMutation] = useMutation<
    Pick<Mutation, 'updateFamilyCharterGoals'>,
    MutationUpdateFamilyCharterGoalsArgs
  >(updateFamilyCharterGoalsGql, {
    refetchQueries: ['FamilyCharter'],
  });

  const updateFamilyCharterGoals = ({
    goals,
    id,
  }: MutationUpdateFamilyCharterGoalsArgs['data']) => {
    return updateFamilyCharterGoalsMutation({
      variables: {
        data: {
          goals,
          id,
        },
      },
    });
  };

  const updateFamilyCharterGoalsWithToast = useWithMutationToast(
    updateFamilyCharterGoals,
    {
      successMessage: 'Family Charter updated successfully',
      errorMessage: 'Error updating Family Charter',
    }
  );

  return {
    updateFamilyCharterGoals: updateFamilyCharterGoalsWithToast,
  };
};
