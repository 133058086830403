import { useFormikContext } from 'formik';

import { gql, useMutation } from '@apollo/client';

import { MediaOutput } from 'gql/fragments';
import { ToastConfig, useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationCreateMediaArgs } from 'types';

import { FileWithPathAndPreview } from '../types';

export const createMediaGql = gql`
  ${MediaOutput}
  mutation CreateMedia($media: Upload!) {
    createMedia(media: $media) {
      ...MediaOutput
    }
  }
`;

const mutationConfig: ToastConfig = {
  disableSuccessMessage: true,
  errorConfig: {
    title: 'Uploading failed',
    description: 'Please try again later',
    status: 'error',
    isClosable: true,
  },
};

export const useUpload = (name: string) => {
  const [uploadMedia, { loading }] = useMutation<
    Pick<Mutation, 'createMedia'>,
    MutationCreateMediaArgs
  >(createMediaGql);

  const { setFieldValue } = useFormikContext();

  const uploadFile = useWithMutationToast(
    async (newValue: FileWithPathAndPreview) => {
      const media = await uploadMedia({
        variables: { media: newValue },
      });

      const file = media.data?.createMedia;
      setFieldValue(name, [file]);
    },
    mutationConfig
  );

  const uploadFiles = useWithMutationToast(
    async (files: FileWithPathAndPreview[]) => {
      const uploadResults = await Promise.all(
        files.map((file) =>
          uploadMedia({
            variables: { media: file },
          })
        )
      );

      const fileIds = uploadResults.map((result) => result.data?.createMedia);
      setFieldValue(name, fileIds);
    }
  );

  return { uploadFile, uploadFiles, loading };
};
