import { useCmsProfiles } from 'hooks/profiles/useCmsProfiles';
import { AccountStatus, OnboardAccountStatus, Option, Role } from 'types';
import { getFullName } from 'utils/string';

export const useAdvisorOptions = () => {
  const { profiles, loading, error } = useCmsProfiles({
    roles: [Role.ExpertAdvisor],
    status: [AccountStatus.Active, AccountStatus.Inactive],
    onboardingStatus: [
      OnboardAccountStatus.InProgress,
      OnboardAccountStatus.Onboarded,
    ],
  });

  const advisorOptions =
    profiles?.map<Option>(({ id, firstName, lastName }) => ({
      value: id,
      label: getFullName(firstName, lastName),
    })) ?? [];

  return { advisorOptions, loading, error };
};
