import { useTranslation } from 'react-i18next';

import { Flex, Grid, Image, Text, useDisclosure } from '@chakra-ui/react';

import { ViewButton } from 'components/Button/ViewButton';
import { Section } from 'components/Layout/Sections/Section';
import { ValueCard } from 'components/ValueCard/ValueCard';
import { OnboardingIntroModal } from 'features/Charter/components/OnboardingIntoModal';
import { FamilyValueOutput } from 'types';

export const ValuesSection = ({ values }: { values?: FamilyValueOutput[] }) => {
  const { t } = useTranslation(['profile']);
  const valuesModal = useDisclosure();

  if (!values?.length) return null;

  return (
    <>
      <Section
        title={t('profile:sections.values.title')}
        subtitleContent={
          <Flex direction="column" gap={4}>
            <Image src="/assets/onboardingValues.png" />
            <Text size="p2">{t('profile:sections.values.description')}</Text>
            <ViewButton onClick={valuesModal.onOpen} variant="blue">
              {t('profile:sections.values.action-button')}
            </ViewButton>
          </Flex>
        }
      >
        <Grid gridTemplateColumns="1fr 1fr" gap={4}>
          {values.map((value) => (
            <ValueCard key={value.id} value={value} />
          ))}
        </Grid>
      </Section>
      <OnboardingIntroModal
        isOpen={valuesModal.isOpen}
        onClose={valuesModal.onClose}
      />
    </>
  );
};
