import { Tag as ChakraTag, TagProps, Text } from '@chakra-ui/react';

import { Variant } from './types';

type Props = {
  customVariant?: Variant;
} & Omit<TagProps, 'fontSize' | 'size'>;

const variantProps: Record<
  Variant,
  { bg: string; color: string; borderColor: string }
> = {
  dark: {
    bg: 'primary.blue.default',
    color: 'ui.white',
    borderColor: 'darkBlue',
  },
  light: {
    bg: 'white',
    color: 'primary.blue.default',
    borderColor: 'ui.white',
  },
  outlineDark: {
    bg: 'transparent',
    color: 'primary.blue.default',
    borderColor: 'primary.blue.default',
  },
  outlineLight: {
    bg: 'transparent',
    color: 'ui.white',
    borderColor: 'ui.white',
  },
};

export const ItriomTag = ({
  customVariant = 'dark',
  children,
  ...tagProps
}: Props): JSX.Element => {
  const { bg, borderColor, color } = variantProps[customVariant];

  return (
    <ChakraTag
      bg={bg}
      color={color}
      borderRadius={14}
      borderWidth={1}
      borderColor={borderColor}
      minH={0}
      px={2}
      py={1}
      {...tagProps}
    >
      <Text size="p3" noOfLines={1} wordBreak="break-all">
        {children}
      </Text>
    </ChakraTag>
  );
};
