import { ArrowRight } from '@carbon/icons-react/next';
import { Flex, Heading, Image, Text } from '@chakra-ui/react';

import { Loader } from 'components/Loader';
import { useOpenGraphMetadata } from 'gql/posts/query/openGraphMetadata';

interface Props {
  url: string;
}

export const LinkPreview = ({ url }: Props) => {
  const { metadata, loading, error } = useOpenGraphMetadata(url);

  if (loading) {
    return <Loader />;
  }

  if (!metadata || error) {
    return null;
  }

  const { image, description, title, hostname } = metadata;

  const onClick = () => {
    window.open(url, '_blank');
  };

  return (
    <Flex
      w="full"
      bgColor="ui.background"
      onClick={onClick}
      cursor="pointer"
      role="link"
    >
      {image && (
        <Flex flex={6}>
          <Image
            src={image}
            h="full"
            alt={title || ''}
            align="center"
            fit="cover"
          />
        </Flex>
      )}
      <Flex flex={7} flexDirection="column" px={6} py={5} gap={2}>
        {hostname && (
          <Text size="p2" color="primary.blue.highlight">
            {hostname}
          </Text>
        )}
        {title && (
          <Heading size="h5" noOfLines={2}>
            {title}
          </Heading>
        )}
        {description && (
          <Text size="p2" noOfLines={4}>
            {description}
          </Text>
        )}
        <Flex justifyContent="flex-end" paddingTop={2}>
          <ArrowRight />
        </Flex>
      </Flex>
    </Flex>
  );
};
