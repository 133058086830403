import { GroupsListSection } from 'components/GroupsListSection';
import { SingleGroupCard } from 'components/SingleGroupCard';
import { useYourGroups } from 'hooks/groups';

export const RecentlyJoined = () => {
  const { yourGroups, loading, error } = useYourGroups();

  const recentlyJoined = yourGroups.slice(0, 3);

  return (
    <GroupsListSection
      heading="Recently joined"
      loading={loading}
      error={error}
      showChildren={!!yourGroups.length}
    >
      {recentlyJoined.map((group) => (
        <SingleGroupCard
          key={group.id}
          group={group}
          withEsgCategory
          variant="secondary"
        />
      ))}
    </GroupsListSection>
  );
};
