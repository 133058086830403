import cloneDeep from 'lodash.clonedeep';

export type ObjectWithTypeName =
  | undefined
  | string
  | number
  | {
      [key: PropertyKey]: ObjectWithTypeName;
      __typename?: string;
    };

function recursiveOmit<T extends ObjectWithTypeName>(
  obj: T
): Omit<T, '__typename'> {
  if (obj && typeof obj === 'object') {
    Object.keys(obj).forEach((key) => {
      if (key === '__typename') {
        delete obj[key];
      } else {
        recursiveOmit(obj[key]);
      }
    });
  }

  return obj;
}

export function stripTypenames<T extends ObjectWithTypeName>(
  obj: T
): Omit<T, '__typename'> {
  const cloned = cloneDeep(obj);

  return recursiveOmit(cloned);
}
