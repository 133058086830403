import { ApolloError } from '@apollo/client';
import { Flex } from '@chakra-ui/react';

import { ValueConfiguratorNormalizedOutput } from 'types';
import { ValueCategoryName } from 'types/ValuesConfigurator';

import { CategoryChartSection } from './CategoryChartSection';
import { ChartsSectionHeader } from './ChartsSectionHeader';
import { mapCategoriesData } from './mapCategoriesData';

type Props = {
  normalizedValues: ValueConfiguratorNormalizedOutput;
  title: string;
  subtitle: string;
  description: string;
  error?: ApolloError;
};

export const ValuesSummary = ({
  title,
  subtitle,
  normalizedValues,
  description,
  error,
}: Props) => {
  const categoriesData = mapCategoriesData(normalizedValues);

  return (
    <Flex direction="column" justifyContent="flex-start" gap={4} w="full">
      <ChartsSectionHeader
        title={title}
        subtitle={subtitle}
        description={description}
      />
      <Flex
        justifyContent="space-around"
        wrap="wrap"
        alignItems="center"
        gap={1}
      >
        {error ? (
          error.message
        ) : (
          <>
            <CategoryChartSection
              categoryName="Organisational"
              values={categoriesData[ValueCategoryName.Organisational]}
            />
            <CategoryChartSection
              categoryName="Contribution"
              values={categoriesData[ValueCategoryName.Contribution]}
            />
            <CategoryChartSection
              categoryName="Advancement"
              values={categoriesData[ValueCategoryName.Advancement]}
            />
            <CategoryChartSection
              categoryName="Societal"
              values={categoriesData[ValueCategoryName.Societal]}
            />
          </>
        )}
      </Flex>
    </Flex>
  );
};
