import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  Book20,
  Forum20,
  Notification20,
  UserFollow20,
} from '@carbon/icons-react';
import { Flex, IconButton } from '@chakra-ui/react';

import { useSearchContext } from 'contexts/SearchContext';
import { Notifications } from 'features/Notifications';
import { Search } from 'features/Search';
import { useHasConnections } from 'hooks/connections/useHasConnections';
import { FeatureFlag, useFeatureFlag } from 'hooks/useFeatureFlag';
import { Links } from 'router/links';
import { NotificationCategoryEnum } from 'types';

export const MenuIcons = () => {
  const { t } = useTranslation(['library']);

  // TODO: REMOVE FEATURE FLAG WHEN ENTIRE FEATURE IMPLEMENTED
  const isLibraryEnabled = useFeatureFlag(FeatureFlag.LIBRARY);

  return (
    <Flex gap={1}>
      <Search />
      {!!isLibraryEnabled && (
        <IconButton
          as={Link}
          isRound
          variant="ghost"
          display="flex"
          justifyContent="center"
          alignItems="center"
          to={Links.libraryMy}
          aria-label={t('library:location.MY_LIBRARY')}
          icon={<Book20 />}
        />
      )}
      <NotificationIcons />
    </Flex>
  );
};

const NotificationIcons = () => {
  const { hasConnections } = useHasConnections();
  const { isSearchInputOpen } = useSearchContext();

  if (isSearchInputOpen) {
    return null;
  }

  return (
    <>
      {hasConnections && (
        <Notifications
          icon={<UserFollow20 />}
          heading="Connections"
          category={NotificationCategoryEnum.Connection}
        />
      )}
      <Notifications
        icon={<Forum20 />}
        heading="Messages"
        category={NotificationCategoryEnum.Message}
        showMarkAllAsReadButton
      />
      <Notifications
        icon={<Notification20 />}
        heading="Notifications"
        category={NotificationCategoryEnum.Other}
        showMarkAllAsReadButton
      />
    </>
  );
};
