import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Text } from '@chakra-ui/react';

import { LayoutGrid } from 'components/Layout/Grid';
import { QueryStateGuard } from 'components/QueryStateGuard';
import { useAuthContext } from 'contexts/AuthContext';
import { useInsight } from 'hooks/insights';
import { InsightBlockUnion } from 'types';

import { ContentSection } from '../components/sections/ContentSection';
import { HeroSection } from '../components/sections/HeroSection';
import { RelatedArticlesSection } from '../components/sections/RelatedArticlesSection';
import { shouldForbidContentDisplay } from '../shouldForbidContentDisplay';

export const InsightPage = (): JSX.Element => {
  const { id } = useParams();
  const { t } = useTranslation(['common', 'navigation']);
  if (!id) throw new TypeError('Insight id is required');
  const { insight, loading: insightLoading, error } = useInsight(id);
  const { account, loading: accountLoading } = useAuthContext();

  if (
    shouldForbidContentDisplay(
      insightLoading || accountLoading,
      insight,
      account
    )
  ) {
    return (
      <LayoutGrid>
        <Text>{t('common:content-unavailable')}</Text>
      </LayoutGrid>
    );
  }

  return (
    <QueryStateGuard loading={insightLoading} error={error}>
      {insight && (
        <LayoutGrid rowGap={10}>
          <HeroSection insight={insight} />
          <ContentSection content={insight.blocks as InsightBlockUnion[]} />
          <RelatedArticlesSection relatedArticles={insight.relatedArticles} />
        </LayoutGrid>
      )}
    </QueryStateGuard>
  );
};
