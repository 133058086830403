import { Flex } from '@chakra-ui/react';

import { WithChildren } from 'types';

export const DarkBlueCenter = ({ children }: WithChildren) => (
  <Flex
    background="primary.blue.default"
    color="ui.white"
    w="full"
    minH={800}
    justify="center"
    align="center"
  >
    {children}
  </Flex>
);
