import { gql, useMutation } from '@apollo/client';

import { postsGql } from 'hooks/posts';
import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationCreateCommentArgs } from 'types';

export const createCommentGql = gql`
  mutation CreateComment($comment: CommentCreateInput!, $images: [Upload!]) {
    createComment(comment: $comment, images: $images) {
      id
    }
  }
`;

export const useCreateComment = () => {
  const [createCommentMutation] = useMutation<
    Pick<Mutation, 'createComment'>,
    MutationCreateCommentArgs
  >(createCommentGql, { refetchQueries: [postsGql] });

  const handleSubmit = ({ comment, images }: MutationCreateCommentArgs) =>
    createCommentMutation({
      variables: {
        comment,
        images,
      },
    });

  const createComment = useWithMutationToast(handleSubmit, {
    successMessage: 'The comment was successfully published',
    errorMessage: 'Failed publishing comment',
  });

  return { createComment };
};
