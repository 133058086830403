import { useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { Page404 } from 'components/Page404';
import { QueryStateGuard } from 'components/QueryStateGuard';
import { AdvisorFromProfilePage } from 'features/Advisors';
import { UserProfile } from 'features/Profile/components/UserProfile';
import { useProfile } from 'hooks/profiles/useProfile';
import { Links } from 'router/links';
import { Role } from 'types';
import { isItriomStaff } from 'utils/roles';

export const ProfilePage = () => {
  const { id } = useParams();
  const { profile, loading, error } = useProfile(id);

  const pageContent = useMemo(() => {
    if (!profile) {
      return <Page404 />;
    }

    if (isItriomStaff(profile.role)) {
      return <Navigate to={Links.home} />;
    }

    if (profile.role === Role.ExpertAdvisor) {
      return <AdvisorFromProfilePage accountId={profile.id} />;
    }

    return <UserProfile />;
  }, [profile]);

  if (!pageContent) return null;

  return (
    <QueryStateGuard loading={loading} error={error}>
      <>{pageContent}</>
    </QueryStateGuard>
  );
};
