import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Flex, Heading, Grid } from '@chakra-ui/react';

import { CmsMenu } from 'features/Cms/components/CmsLayout/CmsMenu';
import { Links } from 'router/links';
import { NavigationItem } from 'router/types';

import { PersonalInformation } from '../components/PersonalInformation';
import { SecuritySettings } from '../components/SecuritySettings';
import { SignInDetails } from '../components/SignInDetails';

type ProfileLink = NavigationItem & {
  content: ReactNode;
};

type Props = {
  type: Links;
};

// TODO: refactor this whole component
export const AccountPage = ({ type }: Props) => {
  const { t } = useTranslation(['profile']);

  const profileLinks: ProfileLink[] = [
    {
      label: t('profile:manage.signInDetails'),
      link: Links.account,
      content: <SignInDetails />,
    },
    {
      label: t('profile:manage.profileInformation'),
      link: Links.accountDetails,
      content: <PersonalInformation />,
    },
    {
      label: t('profile:manage.securitySettings'),
      link: Links.accountSettings,
      content: <SecuritySettings />,
    },
  ];

  return (
    <Flex direction="column" p={8} w="70%" h="100%" justify="flex-end">
      <Heading size="h3" pb={10}>
        Account settings
      </Heading>
      <Grid
        templateAreas={{
          base: '"menu" "content"',
          sm: '"menu" "content"',
          lg: '"menu content"',
        }}
        gridTemplateRows={{
          base: 'auto',
          sm: 'auto',
          lg: 'auto',
        }}
        gridTemplateColumns={{
          base: '1fr',
          sm: '1fr',
          lg: '1fr 4fr',
        }}
        gridGap={2}
        m={0}
        mt={0}
        w="100%"
      >
        <Box gridArea="menu" m={8}>
          <CmsMenu links={profileLinks} strictLink />
        </Box>
        <Box
          gridArea="content"
          alignContent="flex-end"
          w="100%"
          m={8}
          ml={16}
          minW="500px"
        >
          {
            profileLinks.find((profileLink) => profileLink.link === type)
              ?.content
          }
        </Box>
      </Grid>
    </Flex>
  );
};
