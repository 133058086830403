import { Links } from 'router/links';

import { NextGenService, ServiceCard, SolutionCards } from '../types';

const cards: SolutionCards[] = [
  {
    title: 'Informed',
    description:
      'Building a next generation which knows what they know, is conscious of what they don’t know and is determined to discover their unknowns.',
    iconSrc:
      '/assets/nextGenServices/solutionServices/geostrategy/informed.svg',
  },
  {
    title: 'Empowered',
    description:
      'A next generation that embraces responsibility with the chance to build a personal portfolio of change, establishing their own individual long term global posture.',
    iconSrc:
      '/assets/nextGenServices/solutionServices/geostrategy/empowered.svg',
  },
  {
    title: 'Aware',
    description:
      'An informed and empowered next generation which listens, can voice others’ concerns, share their own global visions and work across divides for a better future.',
    iconSrc: '/assets/nextGenServices/solutionServices/geostrategy/aware.svg',
  },
];

const services: ServiceCard[] = [
  {
    src: '/assets/nextGenServices/interServices/geostrategy/power.png',
    title: 'Power and influence',
    description:
      'A series of private discussions designed to develop a better understanding of the nature of power and its relationship with influence. These would generally be in-person to encourage the free flow of dialogue and debate, which would be led and moderated by experienced practitioners in these fields.',
  },
  {
    src: '/assets/nextGenServices/interServices/geostrategy/economics.png',
    title: 'Political economics',
    description:
      'A series of lectures, discussions and dinners over the course of a year which look at the various schools of economic thought, the effect of economics on geopolitics and the human dynamics within all markets. This will promote an informed view of the world whilst inviting curiosity about the unknown.',
  },
  {
    src: '/assets/nextGenServices/interServices/geostrategy/geopolitics.png',
    title: 'The geopolitics of our planet',
    description:
      'A series of open presentations and discussions around the relationship between environmental issues and continental, regional and national geopolitical limitations in response. Designed to invite dialogue and collaboration amongst the next generation regarding solutions, building geopolitical coalitions of mutual interest and establish a consensus for the future.',
  },
  {
    src: '/assets/nextGenServices/interServices/geostrategy/geostrategic.png',
    title: 'Geostrategic planning',
    description:
      'Bespoke private discussions and meetings around next generation priorities and visions for the future. Designed to provoke long-term geostrategic planning around creating informed portfolios of change, building relationships with political and international entities and establishing the seeds of long-term dialogues on global issues.',
  },
  {
    src: '/assets/nextGenServices/interServices/geostrategy/projects.png',
    title: 'Horizon projects',
    description:
      'Individual or collaborative environmental or humanitarian projects which fuse the interests of international agencies, lobby groups or governments with the next generation. Designed to provide the means of wider dialogues and relationships to underwrite their emerging posture and substantiate their longer-term contributions of choice on the world stage.',
  },
  {
    src: '/assets/nextGenServices/interServices/geostrategy/alpha.png',
    title: 'The Alpha list',
    description:
      'A direct input method for the next generation to draw attention to their environmental initiatives of choice where they can initiate activity, organise coalitions of interest and generate transformational activity which will be directly linked to them and their emerging status as independent global actors.',
  },
];

export const geostrategy: NextGenService = {
  hero: {
    src: '/assets/nextGenServices/heroImages/geostrategy.png',
    title: 'Next Generation Geostrategy',
    subtitle: 'Shaping the future',
    link: Links.nextGenGeoStrategy,
  },
  heroDescription:
    'Providing the next generation with the geostrategic knowledge, understanding and opportunities required to develop their capabilities as emerging actors on the world stage.',
  interConnectedServices: {
    serviceCards: services,
  },
  solutions: {
    sectionTitle: 'How our next generation geostrategy services help you',
    description:
      'Working with the next generation through bespoke courses, immersive collaboration projects and direct involvement in their issues of choice to establish them as influential and pivotal to our geostrategic future.',
    cards,
  },
  leader: {
    src: '/assets/nextGenServices/leaders/simon.png',
    title: 'Simon Hulland-Lucas',
    subtitle: 'Senior Partner Practice Leader - Geostrategy',
    bodyText:
      'Simon harnesses research, liason and networks globally to identify opportunities for Itriom, building the knowledge needed to deliver intergeneration legacies for UHNW families.',
    findOutMoreUrl: '',
  },
  quote: {
    quote:
      '“By understanding our next generation, we create a powerful fusion of hope and legacy. My mission is to give that fusion a purpose, designing ESG/SDG projects which draw on UHNW families’ generational visions to transform our world’s future”',
    author: (
      <>
        Simon Hulland-Lucas <br /> Senior Partner <br />
        Practice Leader - Geostrategy
      </>
    ),
  },
  services: [
    {
      title: 'Next Generation Leadership & Resilience',
      subtitle: 'Delivering the way forward',
      src: '/assets/nextGenServices/heroImages/lr.png',
      link: Links.nextGenLR,
    },
    {
      title: 'Next Generation Sustainability',
      subtitle: 'Accelerating and maintaining sustainability',
      src: '/assets/nextGenServices/heroImages/sustainability.png',
      link: Links.nextGenSustainability,
    },
  ],
};
