import { ReactNode } from 'react';

import { Menu, MenuList } from '@chakra-ui/react';

import { NotificationCategoryEnum } from 'types';

import { useNotificationsContext } from './NotificationsProviders';
import { NotificationsButton } from './components/NotificationsButton';
import { NotificationsInfiniteList } from './components/NotificationsInfiniteList';
import { NotificationsListHeader } from './components/NotificationsListHeader';
import { useMarkAllNotificationsAsRead } from './hooks/useMarkAllNotificationsAsRead';

type Props = {
  icon: ReactNode;
  heading: string;
  showMarkAllAsReadButton?: boolean;
  category: NotificationCategoryEnum;
};

export const Notifications = ({
  icon,
  heading,
  showMarkAllAsReadButton,
  category,
}: Props) => {
  const {
    hasMore,
    loadMore,
    notifications,
    totalUnreadCount,
    markAllAsRead,
    markAsRead,
  } = useNotificationsContext(category);

  const { markAllNotificationsAsReadMutation, loading } =
    useMarkAllNotificationsAsRead(category);

  const handleMarkAllAsRead = () =>
    markAllNotificationsAsReadMutation().then(markAllAsRead);

  return (
    <Menu>
      <NotificationsButton
        notificationsUnreadCount={totalUnreadCount}
        icon={icon}
      />
      <MenuList zIndex="dropdown" p={0} overflowY="auto" w={250}>
        <NotificationsListHeader
          isDisabled={totalUnreadCount === 0}
          handleMarkAllAsRead={handleMarkAllAsRead}
          isMarkAllAsReadLoading={loading}
          heading={heading}
          showMarkAllAsReadButton={showMarkAllAsReadButton}
        />
        <NotificationsInfiniteList
          hasMore={hasMore}
          loadMore={loadMore}
          notifications={notifications}
          markAsRead={markAsRead}
        />
      </MenuList>
    </Menu>
  );
};
