import React, {
  ComponentProps,
  ElementType,
  forwardRef,
  Fragment,
  ReactElement,
  ReactNode,
  Ref,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Box,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';

import { ArrowButton } from './ArrowButton';

enum ChangePhoto {
  Prev,
  Next,
}

type Props<T extends ElementType> = {
  children: ReactNode;
  as?: T;
} & Omit<ComponentProps<T>, 'children'>;

export const LightBoxWrapper = forwardRef(
  <T extends ElementType>(
    { children, as, ...props }: Props<T>,
    ref: Ref<HTMLDivElement>
  ): JSX.Element => {
    const Container = as || Fragment;
    const [index, setIndex] = useState(0);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const imageUrls = useMemo(() => {
      return (
        React.Children.map(
          children,
          (child) => React.isValidElement(child) && child.props.src
        )?.filter(Boolean) || []
      );
    }, [children]);

    const openLightBox = (picIdx: number): void => {
      setIndex(picIdx);
      onOpen();
    };

    const changePhoto = useCallback(
      (direction: ChangePhoto): void => {
        if (direction === ChangePhoto.Prev) {
          if (index === 0) return;
          setIndex((idx) => idx - 1);

          return;
        }

        if (index === imageUrls.length - 1) return;
        setIndex((idx) => idx + 1);
      },
      [index, imageUrls.length]
    );

    useEffect(() => {
      const handleKeyDown = (event: KeyboardEvent): void => {
        switch (event.key) {
          case 'Left':
          case 'ArrowLeft':
            changePhoto(ChangePhoto.Prev);
            break;
          case 'Right':
          case 'ArrowRight':
            changePhoto(ChangePhoto.Next);
            break;
          default:
            break;
        }
      };

      document.addEventListener('keydown', handleKeyDown);

      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }, [changePhoto]);

    return (
      <Container {...props} ref={ref}>
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            if (child.type !== Image) {
              return child;
            }

            const imageIdx = imageUrls.findIndex(
              (image) => image === child.props.src
            );

            return React.cloneElement(
              child as ReactElement<{ onClick: VoidFunction; cursor: string }>,
              {
                onClick: () => openLightBox(imageIdx),
                cursor: 'pointer',
              }
            );
          }

          return child;
        })}

        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent position="static" width="auto">
            {index > 0 && (
              <ArrowButton
                variant="prev"
                onClick={() => changePhoto(ChangePhoto.Prev)}
              />
            )}

            <Box>
              <Image src={imageUrls[index]} />
            </Box>

            {index < imageUrls.length - 1 && (
              <ArrowButton
                variant="next"
                onClick={() => changePhoto(ChangePhoto.Next)}
              />
            )}
          </ModalContent>
        </Modal>
      </Container>
    );
  }
);
