import { DragItem } from 'components/Draggable';
import { SDGDragItem } from 'types';

import { SdgElement } from '../../SdgElement';
import { ItemType } from '../types';

type Props = {
  item: SDGDragItem;
};

export const SdgDragElement = ({ item }: Props) => {
  return (
    <DragItem width="auto" height="auto" type={ItemType} item={item}>
      <SdgElement internalName={item.sdg.internalName} />
    </DragItem>
  );
};
