import { useTranslation } from 'react-i18next';

import { Edit } from '@carbon/icons-react/next';

import { ViewButton } from 'components/Button';
import { useAuthContext } from 'contexts/AuthContext';
import { InitiativeOutput, Role, Event, Insight } from 'types';

interface Props {
  item: Pick<InitiativeOutput | Event | Insight, 'id' | 'createdBy'>;
  editUrl: string;
}

export const EditContentButton = ({ item, editUrl }: Props) => {
  const { t } = useTranslation(['common']);
  const { account } = useAuthContext();

  if (!account) return null;

  const hasRoleWithEditRights = [Role.Admin, Role.ItriomCurator].includes(
    account.role
  );

  const isAdvisorAuthor =
    account.role === Role.ExpertAdvisor && item.createdBy === account.id;

  if (hasRoleWithEditRights || isAdvisorAuthor) {
    return (
      <ViewButton variant="blue" rightIcon={<Edit />} to={editUrl}>
        {t('common:edit')}
      </ViewButton>
    );
  }

  return null;
};
