import { useTranslation } from 'react-i18next';

import { Box, VStack, Flex, Heading } from '@chakra-ui/react';

import { ConnectionTabs } from '../components/ConnectionTabs';
import { LeftMenu } from '../components/LeftMenu';

export const ConnectionsPage = (): JSX.Element => {
  const { t } = useTranslation(['connections']);

  return (
    <VStack align="flex-start" mt={10}>
      <Heading pb={5}>{t('connections:connections')}</Heading>
      <Flex gap={3} w="full">
        <LeftMenu />
        <Box flex={1}>
          <ConnectionTabs />
        </Box>
      </Flex>
    </VStack>
  );
};
