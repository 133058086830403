import { useParams } from 'react-router-dom';

import { HeroMedia } from 'components/HeroMedia';
import { LayoutGrid, LayoutGridElement } from 'components/Layout/Grid';
import { QueryStateGuard } from 'components/QueryStateGuard';
import { ConnectCta } from 'features/Profile/components/ConnectCta';
import { AboutSection } from 'features/Profile/components/Sections/AboutSection';
import { ConnectionsSection } from 'features/Profile/components/Sections/ConnectionsSection';
import { GoalsSection } from 'features/Profile/components/Sections/GoalsSection';
import { InitiativesSection } from 'features/Profile/components/Sections/InitiativesSection';
import { PurposeSection } from 'features/Profile/components/Sections/PurposeSection';
import { ValuesSection } from 'features/Profile/components/Sections/ValuesSection';
import { INVITABLE_ROLES } from 'hooks/connections/useHasConnections';
import { useProfile } from 'hooks/profiles/useProfile';
import { AccountStatus } from 'types';
import { getFullName, getFullNameWithActiveStatus } from 'utils/string';
import { flattenValuesOutput } from 'utils/values';

export const UserProfile = () => {
  const { id: urlId } = useParams();
  const { profile, loading, error } = useProfile(urlId);

  const canBeInvited =
    profile &&
    profile.status === AccountStatus.Active &&
    INVITABLE_ROLES.includes(profile.role);
  const familyValuesList = profile?.family?.values
    ? flattenValuesOutput(profile?.family.values)
    : [];

  return (
    <QueryStateGuard loading={loading} error={error}>
      {!!profile && (
        <LayoutGrid rowGap={16}>
          <LayoutGridElement>
            <HeroMedia
              imageUrl={profile.principal?.hero.banner.signedUrl}
              heading={getFullNameWithActiveStatus(profile)}
            >
              {canBeInvited && (
                <ConnectCta
                  key="connect"
                  userId={profile.id}
                  userName={getFullName(profile.firstName, profile.lastName)}
                  connectionState={profile.connectionState}
                />
              )}
            </HeroMedia>
          </LayoutGridElement>
          <PurposeSection purpose={profile.principal?.hero.purpose} />
          <ValuesSection values={familyValuesList} />
          <AboutSection about={profile.principal?.about} />
          <ConnectionsSection profileId={profile.id} />
          <InitiativesSection
            familyId={profile.family?.id}
            profileId={profile.id}
          />
          <GoalsSection familyId={profile.family?.id} />
        </LayoutGrid>
      )}
    </QueryStateGuard>
  );
};
