import { useQuery } from '@apollo/client';

import { Query, QueryGetSharedResourcesFromLibraryArgs } from 'types';

import { getSharedResourcesFromLibrary } from '../documents';

export const useGetSharedResourcesFromLibrary = ({
  skip,
  destinationId,
}: QueryGetSharedResourcesFromLibraryArgs & {
  skip?: boolean;
}) => {
  const { data, error, loading, refetch } = useQuery<
    Pick<Query, 'getSharedResourcesFromLibrary'>,
    QueryGetSharedResourcesFromLibraryArgs
  >(getSharedResourcesFromLibrary, { skip, variables: { destinationId } });

  return {
    resources: data?.getSharedResourcesFromLibrary,
    error,
    loading,
    refetch,
  };
};
