import { ContentSection } from 'components/CmsLayout';
import { MarkdownPreview } from 'components/MarkdownPreview';
import { AdvisorBlockId } from 'types';

type Props = {
  awards: string;
};

export const AwardsAndRecognition = ({ awards }: Props): JSX.Element => {
  return (
    <ContentSection title="Awards and Recognition" id={AdvisorBlockId.Awards}>
      <MarkdownPreview content={awards} />
    </ContentSection>
  );
};
