import {
  addMessageHandler,
  deleteAllMessagesHandler,
  deleteMessageHandler,
  setMessagesHandler,
  setMessagesTotalCountHandler,
} from './handlers';
import {
  MessagesActionsUnion,
  MessagesActionTypes,
  MessagesState,
} from './types';

export const messagesReducer = (
  state: MessagesState,
  action: MessagesActionsUnion
): MessagesState => {
  switch (action.type) {
    case MessagesActionTypes.AddMessage:
      return addMessageHandler(state, action.payload);
    case MessagesActionTypes.DeleteMessage:
      return deleteMessageHandler(state, action.payload);
    case MessagesActionTypes.SetMessages:
      return setMessagesHandler(state, action.payload);
    case MessagesActionTypes.SetMessagesTotalCount:
      return setMessagesTotalCountHandler(state, action.payload);
    case MessagesActionTypes.DeleteAllMessages:
      return deleteAllMessagesHandler(state, action.payload);
    default:
      return state;
  }
};
