import { gql, useMutation } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationRemoveTrashFromLibraryArgs } from 'types';

import {
  getLibraryMediaFavorites,
  getResourcesFromDirectoryInLibraryGql,
  getTrashesFromLibraryGql,
} from '../documents';

const removeTrashFromLibraryGql = gql`
  mutation RemoveTrashFromLibrary(
    $libraryType: LibraryTypeEnum!
    $resourceId: String!
  ) {
    removeTrashFromLibrary(libraryType: $libraryType, resourceId: $resourceId)
  }
`;

export const useRemoveTrashFromLibrary = () => {
  const [mutation] = useMutation<
    Pick<Mutation, 'removeTrashFromLibrary'>,
    MutationRemoveTrashFromLibraryArgs
  >(removeTrashFromLibraryGql, {
    fetchPolicy: 'no-cache',
    refetchQueries: [
      getResourcesFromDirectoryInLibraryGql,
      getTrashesFromLibraryGql,
      getLibraryMediaFavorites,
    ],
  });

  const handleMutation = (variables: MutationRemoveTrashFromLibraryArgs) =>
    mutation({ variables });

  const removeTrashFromLibrary = useWithMutationToast(handleMutation, {
    disableSuccessMessage: true,
  });

  return { removeTrashFromLibrary };
};
