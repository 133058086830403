import { gql, useMutation } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationLeaveGroupArgs } from 'types';

import { groupGql } from './useGroup';
import { groupsGql } from './useGroups';

export const leaveGroupGql = gql`
  mutation LeaveGroup($groupId: String!) {
    leaveGroup(groupId: $groupId) {
      id
    }
  }
`;

export const useLeaveGroup = () => {
  const [leaveGroupMutation, { loading, error }] = useMutation<
    Pick<Mutation, 'leaveGroup'>,
    MutationLeaveGroupArgs
  >(leaveGroupGql, {
    refetchQueries: [groupsGql, groupGql],
  });

  const handleLeave = (groupId: string) =>
    leaveGroupMutation({
      variables: {
        groupId,
      },
    });

  const leaveGroup = useWithMutationToast(handleLeave, {
    successMessage: 'Successfully left the group',
    errorMessage: 'Failure',
  });

  return { leaveGroup, loading, error };
};
