import { gql, useQuery } from '@apollo/client';

import { CmsProfileFiltersInput, Query, QueryCmsProfilesArgs } from 'types';
import { ProfileFragment } from 'types/generated-fragments';

export const cmsProfilesGql = gql`
  ${ProfileFragment}
  query CmsProfiles(
    $pagination: PaginationInput!
    $filters: CmsProfileFiltersInput
  ) {
    cmsProfiles(pagination: $pagination, filters: $filters) {
      nodes {
        ...ProfileFragment
      }
      offset
      totalCount
    }
  }
`;

type Props = Partial<CmsProfileFiltersInput> & {
  limit?: number;
  page?: number;
  skip?: boolean;
};

export const useCmsProfiles = ({
  includeOnlyOwnFamily,
  limit = 100,
  page = 1,
  familyId,
  onboardingStatus,
  status,
  roles,
  skip,
}: Props = {}) => {
  const offset = (page - 1) * limit;

  const { data, loading, error } = useQuery<
    Pick<Query, 'cmsProfiles'>,
    QueryCmsProfilesArgs
  >(cmsProfilesGql, {
    skip,
    variables: {
      pagination: { limit, offset },
      filters: {
        roles,
        includeOnlyOwnFamily,
        familyId,
        onboardingStatus,
        status,
      },
    },
    fetchPolicy: 'no-cache',
  });

  const profiles = data?.cmsProfiles.nodes ?? [];

  const totalCount = data?.cmsProfiles.totalCount ?? 0;

  const maxPages = Math.ceil(totalCount / limit);

  return { profiles, loading, error, maxPages, totalCount };
};
