import { ReactNode } from 'react';

import { Flex, Box, FlexProps } from '@chakra-ui/react';

import { underlay, UnderlayDirection } from 'theme/mixins/underlay';
import { MediaOutput } from 'types';

interface Props
  extends Pick<
    FlexProps,
    | 'minH'
    | 'maxH'
    | 'backgroundSize'
    | 'gridRow'
    | 'gridColumn'
    | 'alignItems'
    | 'py'
  > {
  children: ReactNode;
  image?: MediaOutput;
  aspectRatio?: string;
  underlayDirection?: UnderlayDirection;
  childrenGap?: number;
}

export const ElementWithBackgroundImage = ({
  children,
  image,
  minH,
  maxH,
  backgroundSize = 'cover',
  alignItems = 'flex-end',
  py = 6,
  aspectRatio,
  underlayDirection,
  childrenGap = 6,
  gridColumn,
  gridRow,
}: Props) => {
  return (
    <Flex
      position="relative"
      backgroundImage={image?.signedUrl}
      backgroundSize={backgroundSize}
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      minH={minH}
      maxH={maxH}
      alignItems={alignItems}
      py={py}
      display="flex"
      {...(!!aspectRatio && { __css: { aspectRatio } })}
      gridRow={gridRow}
      gridColumn={gridColumn}
    >
      <Box
        {...underlay({ direction: underlayDirection })}
        inset={0}
        position="absolute"
      />
      <Flex zIndex={2} direction="column" gap={childrenGap} p={4} w="full">
        {children}
      </Flex>
    </Flex>
  );
};
