import { Category, CategoryDescription } from './types';

export const categoryDescriptions: Record<Category, CategoryDescription> = {
  [Category.Purpose]: {
    color: '#89C3D3',
    clipPath: 'polygon(0 0,0 50%,50% 50%)',
    translate: 'translate(15%,-90%)',
    index: 1,
    name: 'Purpose',
    title: 'Family purpose and cohesion',
    subtitle1: `Values promote collective purpose and family cohesion:`,
    description1: `By giving a clear purpose to the entire family, values provide a basis around which it can unite. Values help achieve internal cohesion and family aims.`,
    subtitle2: 'Values clarify family identity:',
    description2: `Values set out a clear frame of reference for the family’s expectations and behaviours. Fostering a shared and strong identity for all generations of the family and reducing the risk of friction.`,
  },
  [Category.Governance]: {
    color: '#5D889F',
    clipPath: 'polygon(0 0,50% 50%,50% 0)',
    translate: 'translate(0%,-100%)',
    index: 2,
    name: 'Governance',
    title: 'Governing family decisions',
    subtitle1: `Values enable competing demands to be prioritised:`,
    description1: `Values provide a framework for guiding and governing family decisions, enabling it to evaluate its involvement in competing actions, policies, initiatives, and events.`,
    subtitle2: `Values ensure consistent decision- making:`,
    description2: `Once values are defined and agreed, family members are empowered to reflect these values from their own perspective and initiatives.`,
  },
  [Category.Legacy]: {
    color: '#F9C950',
    clipPath: 'polygon(50% 0,50% 50%,100% 0)',
    translate: 'translate(0,-135%)',
    index: 3,
    name: 'Legacy',
    title: `Family resilience, future proofing and legacy`,
    subtitle1: `Values provide a platform for responding to events:`,
    description1: `A strong purpose and clear values to guide the family’s actions provide a resilient basis for dealing with both expected and unexpected events.`,
    subtitle2: `Values enable the family’s ethos to be firmly embedded and continued by the next family generation:`,
    description2: `The process of defining values not only ensures that the next generation’s views are heard, but also provide a basis for them to build on the family’s ethos/legacy in the future. `,
  },
  [Category.Engagement]: {
    color: '#56BD8F',
    clipPath: 'polygon(100% 0,50% 50%,100% 50%)',
    translate: 'translate(0,-80%)',
    index: 4,
    name: 'Engagement',
    title: `Clear external signal of what the family stands for`,
    subtitle1: `Values clarify the family approach to external stakeholders:`,
    description1: `Values ensure that all those who come into contact with the family, know and understand what underpin the family’s interests and culture, including its approach to doing business.`,
    subtitle2: `Values set the external perception of the family’s brand:`,
    description2: `By clearly demonstrating to external stakeholders what’s important to the family, the values inevitably help shape the external perception of the family, i.e., its brand.`,
  },
};
