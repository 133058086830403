import 'swiper/swiper-bundle.min.css';
import 'typeface-roboto';

import { BrowserRouter } from 'react-router-dom';

import { ApolloProvider } from '@apollo/client';
import { ChakraProvider } from '@chakra-ui/react';

import { client } from 'client';
import { ScrollToTop } from 'components/ScrollToTop';
import { UnauthorizedModal } from 'components/UnauthorizedModal';
import { AuthProvider } from 'contexts/AuthContext';
import { SearchProvider } from 'contexts/SearchContext';
import { MessagingProviders } from 'features/Messaging';
import { NotificationsProviders } from 'features/Notifications';
import { Router } from 'router';
import { theme, Fonts } from 'theme';

import './i18n/config';

const App = (): JSX.Element => (
  <ApolloProvider client={client}>
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <AuthProvider>
          <MessagingProviders>
            <NotificationsProviders>
              <SearchProvider>
                <ScrollToTop />
                <Fonts />
                <Router />
                <UnauthorizedModal />
              </SearchProvider>
            </NotificationsProviders>
          </MessagingProviders>
        </AuthProvider>
      </BrowserRouter>
    </ChakraProvider>
  </ApolloProvider>
);

export default App;
