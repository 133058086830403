import i18n from 'i18n/config';

import { AccountStatus, Profile } from 'types';

export const stripWhiteSpace = (str: string): string => str.replace(/\s+/g, '');

export const addHTTPs = (str: string): string => {
  const hasHttp = str.match(/^https?:\/\//g);

  return hasHttp ? str : `https://${str}`;
};

export const capitalize = (str: string): string =>
  str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export const getPluralSuffix = (
  name: string,
  value: number | undefined
): string => {
  if (value && value > 1) return `${name}s`;

  return name;
};

export const getFullName = (firstName = '', lastName = '') =>
  `${firstName} ${lastName}`;

export const getTitleAndFamily = (
  title?: string | null,
  familyName?: string | null
) => {
  return [title, familyName].filter(Boolean).join(' / ');
};

export const getFullNameWithActiveStatus = (profile?: Profile) => {
  if (!profile) return '';
  const { firstName, lastName, status } = profile;

  const isActive = status === AccountStatus.Active;

  const fullName = getFullName(firstName, lastName);

  return `${fullName}${isActive ? '' : ` ${i18n.t('common.inactive-user')}`}`;
};

export const concat = (string: string, maxLength: number) => {
  if (string.length <= maxLength || string.length < 3) {
    return string;
  }

  return `${string.slice(0, maxLength - 3)}...`;
};

export const extractUrls = (string: string) => {
  const regexp =
    /(https?:\/\/(www\.)?|www\.)[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()'@:%_+.~#?!&//=]*)/gi;

  if (typeof string !== 'string') {
    throw new TypeError(
      `The string argument should be a string, got ${typeof string}`
    );
  }

  return string.match(regexp) || undefined;
};

export const getFileTypeFromName = (file: File) => {
  return file.name.split('.').pop();
};
