import { ReactNode } from 'react';

import { Box, Grid } from '@chakra-ui/react';

type Props = {
  points: number;
  children: ReactNode;
};

export const AllocatedPoints = ({ points, children }: Props) => {
  return (
    <Grid
      gridTemplateColumns={{ base: '1fr', md: '3fr 1fr 2fr' }}
      color={points > 100 ? 'red' : 'primary.blue.default'}
    >
      <Box textAlign="end" my="auto" fontWeight={500}>
        {points > 100 ? 'Over allocated points' : 'Remaining points'}
      </Box>
      <Box
        my="auto"
        fontSize="2xl"
        textAlign="center"
        py={{ base: 3, md: 0 }}
        fontFamily="Publico Headline"
      >
        {Math.abs(100 - points)}
      </Box>
      {children}
    </Grid>
  );
};
