import { Link, useLocation } from 'react-router-dom';

import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';

import { DropdownMenuItem } from 'router/links';

type Props = {
  item: DropdownMenuItem;
};

export const DropdownMenuItems = ({ item }: Props) => {
  const { pathname } = useLocation();

  const { label: name, dropdownItems } = item;

  const isActive = dropdownItems
    .map(({ link }) => link.toString())
    .includes(pathname);

  return (
    <Menu>
      <MenuButton
        as={Button}
        variant="ghost"
        rightIcon={<ChevronDownIcon boxSize={6} />}
        h="full"
        display="flex"
        justifyContent="center"
        alignItems="center"
        border="none"
        px={0}
        pt={2}
        borderBottomWidth={0}
        borderBottom="thick"
        {...(!isActive && { color: 'ui.grey2' })}
        borderBottomColor={isActive ? 'primary.lime.highlight' : 'transparent'}
        borderBottomStyle="solid"
        transition="border-color 200ms linear"
        _hover={{
          color: 'primary.blue.default',
          pb: '-12px',
          borderBottom: 'thick',
          borderBottomStyle: 'solid',
          borderBottomColor: 'primary.lime.highlight',
          backgroundColor: 'transparent',
        }}
        _active={{ backgroundColor: 'transparent' }}
      >
        <Text as="span" size="p1">
          {name}
        </Text>
      </MenuButton>
      <MenuList mt={-2} borderTopRadius={0}>
        {dropdownItems.map(({ link, label }) => {
          const isCurrentPage = link.toString() === pathname;

          return (
            <MenuItem
              as={Link}
              to={link}
              key={label}
              {...(!isCurrentPage && { color: 'ui.grey2' })}
              _hover={{ color: 'primary.blue.default' }}
            >
              {label}
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
};
