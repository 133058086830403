import { HeroBanner } from 'components/HeroBanner';

export const MarketplaceHeaderSection = () => {
  return (
    <HeroBanner
      isVideo
      src="/assets/marketplace/hero_video.mp4"
      title="Welcome to the Itriom Marketplace"
      subtitle="Investing with purpose"
    />
  );
};
