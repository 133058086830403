import { useFormikContext } from 'formik';

import { useCallback } from 'react';

import { FileWithPathAndPreview } from '../types';
import { useUpload } from './useUpload';

type Props = {
  name: string;
  maxFiles: number;
  uploadAndUseId: boolean;
};

export const useHandleDrop = ({ maxFiles, name, uploadAndUseId }: Props) => {
  const { setFieldValue } = useFormikContext();

  const { uploadFile, uploadFiles, loading } = useUpload(name);

  const handleDrop = useCallback(
    async (files: FileWithPathAndPreview[]) => {
      const filesToAttach = files.slice(0, maxFiles).map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      const newValue: FileWithPathAndPreview[] | null =
        filesToAttach.length > 0 ? filesToAttach : null;

      if (uploadAndUseId && filesToAttach.length > 1) {
        uploadFiles(filesToAttach);
      } else if (uploadAndUseId && newValue?.[0]) {
        uploadFile(newValue[0]);
      } else {
        setFieldValue(name, newValue);
      }
    },
    [maxFiles, uploadAndUseId, uploadFiles, uploadFile, setFieldValue, name]
  );

  return { handleDrop, loading };
};
