import { Link } from 'react-router-dom';

import { CloseButton, Flex, Heading, Image, Text } from '@chakra-ui/react';

import { ColouredTag } from 'components/ItriomTag';
import { Links } from 'router/links';
import { InitiativeOutput } from 'types';
import { generateUrlById } from 'utils/links';
import { flattenTags } from 'utils/tags';

type Props = {
  initiative: InitiativeOutput;
  handleClose: () => void;
};

export const InitiativeMapModal = ({ initiative, handleClose }: Props) => {
  const { id, hero, tags } = initiative;

  const to = generateUrlById(Links.initiativesSingle, id);

  return (
    <Flex
      position="absolute"
      top="50%"
      left="50%"
      transform="translate(-50%, -50%)"
      maxW={460}
      w="full"
      minH={210}
      color="ui.white"
      bgColor="primary.blue.default"
      boxShadow="lg"
      zIndex={10}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <CloseButton
        pos="absolute"
        top={1}
        right={1}
        size="sm"
        color="ui.white"
        onClick={handleClose}
      />
      <Image
        w={150}
        src={hero.heroImage.signedUrl}
        objectFit="cover"
        objectPosition="center"
      />
      <Flex direction="column" p={4} w="auto" flex={1} gap={2}>
        <Link to={to}>
          <Heading
            size="h5"
            color="ui.white"
            mr={2}
            noOfLines={3}
            _hover={{ textDecoration: 'underline' }}
          >
            {hero.headline}
          </Heading>
        </Link>
        <Flex gap={2} wrap="wrap">
          {flattenTags(tags).map((tag) => (
            <ColouredTag tag={tag} key={tag} variant="light" />
          ))}
        </Flex>
        <Text size="p3" color="ui.white" noOfLines={4}>
          {hero.summary}
        </Text>
      </Flex>
    </Flex>
  );
};
