import { useTranslation } from 'react-i18next';

import { Flex, Heading, Button, Grid, Image } from '@chakra-ui/react';

import { HeroMedia } from 'components/HeroMedia';
import { LightBoxWrapper } from 'components/LightBoxWrapper';
import { Location } from 'types';

import { EventBodyElement } from '../../EventBodyElement';

export const LocationSection = ({ location }: { location?: Location }) => {
  const { t } = useTranslation(['events']);

  if (!location) return null;

  return (
    <>
      <EventBodyElement>
        <HeroMedia
          imageUrl={location?.heroImage?.signedUrl}
          heading={t('events:about-location')}
          containerStyles={{ maxH: 530 }}
        >
          <Flex direction="column" gap={8}>
            <Heading size="h5" maxW="66%">
              {location.description}
            </Heading>
            {location.button && (
              <Button
                width="max-content"
                variant="primaryLime"
                as="a"
                cursor="pointer"
                target="_blank"
                referrerPolicy="no-referrer"
                href={location.button.buttonLink}
              >
                {location.button.buttonLabel}
              </Button>
            )}
          </Flex>
        </HeroMedia>
      </EventBodyElement>
      {location.images.length ? (
        <EventBodyElement>
          <Grid
            gridTemplateColumns={{
              base: 'repeat(2, 1fr)',
              md: 'repeat(3, 1fr)',
            }}
            gridGap={4}
          >
            <LightBoxWrapper>
              {location.images.map(({ image }) => (
                <Image
                  key={image.id}
                  src={image.signedUrl}
                  sx={{ aspectRatio: '3 / 2' }}
                  objectFit="cover"
                  objectPosition="center"
                  w="100%"
                />
              ))}
            </LightBoxWrapper>
          </Grid>
        </EventBodyElement>
      ) : null}
    </>
  );
};
