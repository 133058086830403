import { ValueCategoryName } from 'types/ValuesConfigurator';

export const STEEL_BLUE_2 = '#7C9EB1';
export const LIGHT_BLUE_5 = '#E7F3F6';

export enum Question {
  What = 'what',
  Why = 'why',
  How = 'how',
  Who = 'who',
}

export enum Variant {
  PositiveState1 = '#C7F1CA',
  Highlight = '#13C8D3',
  LightBlue3 = '#B8DBE5',
  Orange1 = '#F9C950',
}

export const valuesConfiguratorBubbles = [
  {
    label: ValueCategoryName.Organisational,
    variant: Variant.PositiveState1,
    question: Question.How,
  },
  {
    label: ValueCategoryName.Contribution,
    variant: Variant.Highlight,
    question: Question.Why,
  },
  {
    label: ValueCategoryName.Advancement,
    variant: Variant.LightBlue3,
    question: Question.What,
  },
  {
    label: ValueCategoryName.Societal,
    variant: Variant.Orange1,
    question: Question.Who,
  },
];
