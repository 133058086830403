import { TabPane } from 'components/TabPane';

import { connectionTabsConfig } from './connectionTabsConfig';

export const ConnectionTabs = () => {
  return (
    <TabPane
      tabsConfig={connectionTabsConfig}
      display={{ noMarginTop: true, narrow: true }}
    />
  );
};
