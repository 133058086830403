import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { As, Flex, FlexProps, Link, StackDivider } from '@chakra-ui/react';

import { ITRIOM_EMAIL_SUPPORT } from 'constants/itriom';
import { Links } from 'router/links';

type LinkType = { text: string; href: string };

export const WelcomeLayoutFooter = ({
  color = 'white',
}: Pick<FlexProps, 'color'>) => {
  const { t } = useTranslation(['common']);

  const links: LinkType[] = [
    { text: t('common:contact'), href: `mailto:${ITRIOM_EMAIL_SUPPORT}` },
    { text: t('common:privacy'), href: Links.privacy },
    { text: t('common:terms-of-use'), href: Links.termsOfUse },
  ];

  return (
    <>
      <StackDivider borderColor="white" borderBottomWidth={1} />
      <Flex direction="row" justifyContent="center" w="100%" gap="6.5rem" p={6}>
        {links.map(({ text, href }) => (
          <Link
            as={href.includes('/') ? RouterLink : ('a' as As)}
            {...(href.includes('/') ? { to: href } : { href })}
            key={text}
            color={color}
            fontFamily="Roboto"
            fontSize="xs"
            fontWeight={400}
          >
            {text}
          </Link>
        ))}
      </Flex>
    </>
  );
};
