import { useTranslation } from 'react-i18next';

import {
  ActionStatusEnum,
  DeletedEntityName,
  NotificationTypeEnum,
  NotificationUnion,
} from 'types';

type T = 'common:comment' | 'common:group' | 'common:post';

const DELETED_ENTITY_TRANSLATION: Record<DeletedEntityName, T> = {
  [DeletedEntityName.Comment]: 'common:comment',
  [DeletedEntityName.Group]: 'common:group',
  [DeletedEntityName.Post]: 'common:post',
};

const getStatusKey = (
  actionStatus?: ActionStatusEnum
): 'request' | 'accepted' | 'rejected' => {
  if (!actionStatus) return 'request';

  return actionStatus === ActionStatusEnum.Accepted ? 'accepted' : 'rejected';
};

export const useNotificationText = (notification: NotificationUnion) => {
  const { t } = useTranslation(['notifications', 'common']);

  const { type, metadata } = notification;

  switch (type) {
    // CONNECTIONS
    case NotificationTypeEnum.ConnectionInvitationAccepted:
      return t('notifications:connection-invitation-accepted');

    case NotificationTypeEnum.ConnectionInvitationSent: {
      const { actionStatus } = metadata;

      return t(
        `notifications:connection-invitation-sent.${getStatusKey(actionStatus)}`
      );
    }

    // MESSAGES
    case NotificationTypeEnum.MessageSent:
      return t('notifications:message-sent');

    // GROUP
    case NotificationTypeEnum.GroupInvitationAccepted:
      return t('notifications:group-invitation-accepted');
    case NotificationTypeEnum.GroupInvitationRejected:
      return t('notifications:group-invitation-rejected');
    case NotificationTypeEnum.GroupAdminLeft:
      return t('notifications:group-admin-left', {
        group: 'group' in metadata && metadata.group.name,
      });
    case NotificationTypeEnum.GroupNewAdmin:
      return t('notifications:group-you-are-now-the-admin', {
        group: 'group' in metadata && metadata.group.name,
      });
    case NotificationTypeEnum.GroupNewMember:
      return t('notifications:joined-your-group');

    case NotificationTypeEnum.GroupInvitationSent: {
      const { actionStatus } = metadata;

      if (!actionStatus) return t(`notifications:group-invitation-sent.action`);

      const statusTranslation =
        actionStatus === ActionStatusEnum.Accepted
          ? t('notifications:group-invitation-sent.accepted')
          : t('notifications:group-invitation-sent.rejected');

      return t('notifications:group-invitation-sent.status', {
        status: statusTranslation,
      });
    }

    // POSTS
    case NotificationTypeEnum.PostNewComment:
      return t('notifications:post-new-comment', {
        group: 'postInGroupId' in metadata && metadata.postInGroupId,
      });
    case NotificationTypeEnum.CommentNewResponse:
      return t('notifications:comment-new-response');
    case NotificationTypeEnum.PostNewAppreciation:
      return t('notifications:post-new-appreciation', {
        group: 'postInGroupId' in metadata && metadata.postInGroupId,
      });

    // INITIATIVES
    case NotificationTypeEnum.InitiativeNewComment:
      return t('notifications:initiative-new-comment');

    // MISC
    case NotificationTypeEnum.ContentRemovedByAdmin: {
      const { entityName } = notification.metadata;

      const entityTranslation = DELETED_ENTITY_TRANSLATION[entityName];

      return t('notifications:content-removed-by-admin', {
        contentType: t(entityTranslation).toLowerCase(),
      });
    }

    // FAMILY
    case NotificationTypeEnum.ContentPublishedByFamily:
      return t('notifications:family-new-content', {
        entity: 'entityType' in metadata && metadata.entityType.toLowerCase(),
      });

    // LIBRARY
    case NotificationTypeEnum.LibraryResourceShared:
      return t('notifications:shared');

    default:
      return t('notifications:unknown');
  }
};

export const buttonActionStyles = {
  fontSize: 10,
  lineHeight: 1.2,
  h: 4,
  minW: 0,
  py: 0,
  px: 2,
  variant: 'outline',
  borderRadius: 5,
  borderColor: 'primary.blue.default',
  color: 'primary.blue.default',
};
