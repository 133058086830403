import { gql, useMutation } from '@apollo/client';

import { recommendedConnectionsGql } from 'components/RecommendedConnections/useRecommendedConnections';
import { profileGql } from 'hooks/profiles/useProfile';
import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationInviteToConnectionsArgs } from 'types';

import { recentConnectionsGql } from '../documents';

const inviteToConnectionsGql = gql`
  mutation InviteToConnections($accountId: String!) {
    inviteToConnections(accountId: $accountId) {
      id
      senderId
      recipientId
    }
  }
`;

type Props = {
  customSuccessMessage?: string;
};

export const useInviteToConnections = (props?: Props) => {
  const { customSuccessMessage = 'Your invitation to connect has been sent' } =
    props || {};

  const [inviteToConnectionsMutation] = useMutation<
    Pick<Mutation, 'inviteToConnections'>,
    MutationInviteToConnectionsArgs
  >(inviteToConnectionsGql, {
    refetchQueries: [
      profileGql,
      recentConnectionsGql,
      recommendedConnectionsGql,
    ],
  });

  const handleInvite = (accountId: string) =>
    inviteToConnectionsMutation({ variables: { accountId } });

  const inviteToConnections = useWithMutationToast(handleInvite, {
    successMessage: customSuccessMessage,
    errorMessage: 'Error sending connection invitation',
  });

  return { inviteToConnections };
};
