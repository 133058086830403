import { EventStatusEnum } from 'types';
import { getCurrentDateWithRounding } from 'utils/date';

import { useEvents } from './useEvents';

export const useUpcomingEvent = () => {
  const { events, loading, error } = useEvents({
    filters: {
      from: getCurrentDateWithRounding(),
      statuses: [EventStatusEnum.Published],
      visibility: { userHasAccessTo: true },
    },
    pagination: { limit: 1, offset: 0 },
  });

  const { events: alternative } = useEvents({
    filters: {
      from: getCurrentDateWithRounding(),
      statuses: [EventStatusEnum.Published],
      visibility: { userHasAccessTo: true },
    },
    pagination: { limit: 1, offset: 0 },
  });

  const upcomingEvent = events[0] || alternative[0];

  return { upcomingEvent, loading, error };
};
