import { gql } from '@apollo/client';

import { usePaginatedQuery } from 'hooks/pagination';
import { Query, QueryConversationsArgs } from 'types';
import {
  ConversationFragment,
  MediaOutputNoNestingFragment,
  ProfileNoNestingFragment,
} from 'types/generated-fragments';

import {
  MESSAGING_DEFAULT_LIMIT,
  MESSAGING_DEFAULT_OFFSET,
} from '../constants';

export const getConversationsGql = gql`
  ${ConversationFragment}
  ${ProfileNoNestingFragment}
  ${MediaOutputNoNestingFragment}
  query Conversations($pagination: PaginationInput) {
    conversations(pagination: $pagination) {
      nodes {
        ...ConversationFragment
        members {
          ...ProfileNoNestingFragment
          avatarImage {
            ...MediaOutputNoNestingFragment
          }
        }
      }
      offset
      totalCount
    }
  }
`;

export const useLoadConversations = () => {
  const { initialLoad, loadMore } = usePaginatedQuery<
    Pick<Query, 'conversations'>,
    QueryConversationsArgs
  >(getConversationsGql, {
    offset: MESSAGING_DEFAULT_OFFSET,
    limit: MESSAGING_DEFAULT_LIMIT,
  });

  const loadConversations = () =>
    initialLoad().then((result) => result.conversations);

  const loadMoreConversations = () =>
    loadMore().then((result) => result.conversations);

  return { loadConversations, loadMoreConversations };
};
