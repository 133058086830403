import { QueryStateGuard } from 'components/QueryStateGuard';
import { useInitiative } from 'hooks/initiatives';

import { InitiativeCharts } from './InitiativeCharts';
import { InitiativePanel } from './InitiativePanel';

type Props = {
  initiativeId: string;
  shouldShowCharts?: boolean;
};

export const GoalInitiative = ({ initiativeId, shouldShowCharts }: Props) => {
  const { initiative, loading, error } = useInitiative(initiativeId);

  return (
    <QueryStateGuard loading={loading} error={error}>
      {initiative && (
        <>
          {shouldShowCharts && (
            <InitiativeCharts initiativeHero={initiative.hero} />
          )}

          <InitiativePanel
            id={initiativeId}
            heroImage={initiative.hero.heroImage}
            name={initiative.hero.headline}
          />
        </>
      )}
    </QueryStateGuard>
  );
};
