import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
  Context,
} from 'react';

import { useAuthContext } from 'contexts/AuthContext';
import { usePaginationState } from 'hooks/pagination';
import {
  NotificationCategoryEnum,
  NotificationUnion,
  PaginatedNotificationUnion,
  WithChildren,
} from 'types';

import { useLoadNotifications } from '../hooks/useLoadNotifications';

type NotificationsContextType = {
  notifications: NotificationUnion[];
  hasMore: boolean;
  loadMore: () => void;
  totalUnreadCount: number;
  markAllAsRead: () => void;
  markAsRead: (notificationId: string) => void;
  addNotification: (notification: NotificationUnion) => void;
};

type Contexts = Record<
  NotificationCategoryEnum,
  Context<NotificationsContextType | null>
>;

const contexts: Contexts = {
  [NotificationCategoryEnum.Connection]:
    createContext<NotificationsContextType | null>(null),
  [NotificationCategoryEnum.Message]:
    createContext<NotificationsContextType | null>(null),
  [NotificationCategoryEnum.Other]:
    createContext<NotificationsContextType | null>(null),
};

export const useNotificationsContext = (
  category: NotificationCategoryEnum
): NotificationsContextType => {
  const context = useContext(contexts[category]);

  if (!context) {
    throw new Error("useNotificationsContext used outside of it's provider");
  }

  return context;
};

type Props = {
  useLoad?: (category: NotificationCategoryEnum) => {
    initialLoad: () => Promise<PaginatedNotificationUnion>;
    loadMore: () => Promise<PaginatedNotificationUnion>;
  };
  category: NotificationCategoryEnum;
};

export const NotificationsContextProvider = ({
  children,
  category,
  useLoad = useLoadNotifications,
}: WithChildren<Props>) => {
  const { account } = useAuthContext();

  const [totalUnreadCount, setTotalUnreadCount] = useState(0);

  const onLoadCallback = (output: PaginatedNotificationUnion) => {
    setTotalUnreadCount(output.totalUnreadCount);
  };

  const { initialLoad, loadMore } = useLoad(category);

  const { items, setItems, setTotalCount, hasMore, loadMoreItems } =
    usePaginationState<PaginatedNotificationUnion>({
      initialLoad,
      loadMore,
      onLoadCallback,
      shouldLoadInitially: !!account,
    });

  const markAllAsRead = useCallback(() => {
    setItems((prevNotifications) =>
      prevNotifications.map(({ isRead, ...notification }) => ({
        ...notification,
        isRead: true,
      }))
    );

    setTotalUnreadCount(0);
  }, []);

  const markAsRead = useCallback((notificationId: string) => {
    setItems((prevNotifications) =>
      prevNotifications.map((notification) =>
        notification.id === notificationId
          ? { ...notification, isRead: true }
          : notification
      )
    );

    setTotalUnreadCount((prev) => {
      return prev === 0 ? 0 : prev - 1;
    });
  }, []);

  const addNotification = useCallback((notification: NotificationUnion) => {
    let shouldIncrementUnreadCounter = true;

    setItems((prevNotifications) => {
      const isNotificationAlreadyInState = prevNotifications.some(
        ({ id }) => id === notification.id
      );

      // update existing notification
      if (isNotificationAlreadyInState) {
        shouldIncrementUnreadCounter = false;

        return prevNotifications.map((prevNotification) =>
          prevNotification.id === notification.id
            ? notification
            : prevNotification
        );
      }

      return [notification, ...prevNotifications];
    });

    if (shouldIncrementUnreadCounter) {
      setTotalUnreadCount((prev) => prev + 1);
    }

    setTotalCount((prev) => prev + 1);
  }, []);

  const value: NotificationsContextType = useMemo(
    () => ({
      hasMore,
      loadMore: loadMoreItems,
      notifications: items,
      totalUnreadCount,
      markAllAsRead,
      markAsRead,
      addNotification,
    }),
    [
      hasMore,
      loadMoreItems,
      markAllAsRead,
      markAsRead,
      items,
      totalUnreadCount,
      addNotification,
    ]
  );

  const NotificationContextForCategory = contexts[category];

  return (
    <NotificationContextForCategory.Provider value={value}>
      {children}
    </NotificationContextForCategory.Provider>
  );
};
