import { Button } from '@chakra-ui/react';

type Props = {
  disabled: boolean;
  handleClick: () => void;
  isLoading?: boolean;
};

export const MarkAllAsReadButton = ({
  disabled,
  handleClick,
  isLoading,
}: Props) => {
  return (
    <Button
      variant="ghost"
      fontSize={10}
      lineHeight={1.8}
      _hover={{ bgColor: 'none', textDecoration: 'underline' }}
      h={18}
      py={0.5}
      ml="auto"
      my={2}
      mr={2}
      isDisabled={disabled}
      onClick={handleClick}
      isLoading={isLoading}
    >
      Mark all as read
    </Button>
  );
};
