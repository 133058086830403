import { useMessagingStateContext } from './MessagingStateProvider';

export const useMessagesActions = () => {
  const { messagesActions } = useMessagingStateContext();

  return messagesActions;
};

export const useMessagesSelectors = () => {
  const { messagesSelectors } = useMessagingStateContext();

  return messagesSelectors;
};

export const useConversationsActions = () => {
  const { conversationsActions } = useMessagingStateContext();

  return conversationsActions;
};

export const useConversationsSelectors = () => {
  const { conversationsSelectors } = useMessagingStateContext();

  return conversationsSelectors;
};

export const useReportMessagingError = () => {
  const { reportError } = useMessagingStateContext();

  return reportError;
};
