import { Link } from 'react-router-dom';

import { Box, Flex, Image, Text } from '@chakra-ui/react';

import { GroupMenu } from 'components/SingleGroupCard/GroupMenu';
import { useEsgCategories } from 'hooks/useEsgCategories';
import { Links } from 'router/links';
import { underlay } from 'theme/mixins/underlay';
import { GroupInternalType } from 'types';
import { generateUrlById } from 'utils/links';

type Props = {
  group: GroupInternalType;
};

export const GroupCard = ({ group }: Props) => {
  const { heroImage, id, name, category } = group;

  const { getEsgCategory } = useEsgCategories();

  return (
    <Flex
      color="ui.white"
      pos="relative"
      as={Link}
      to={generateUrlById(Links.groupsSingle, id)}
      h={250}
      flex={1}
      {...underlay({ direction: 'column' })}
      aria-label="group card"
    >
      <Image
        src={heroImage?.signedUrl}
        w="full"
        h="full"
        objectFit="cover"
        objectPosition="center"
      />
      <Flex
        direction="column"
        p={3}
        pos="absolute"
        bottom={0}
        left={0}
        right={0}
        color="ui.white"
      >
        <Text size="p1" noOfLines={2}>
          {name}
        </Text>
        <Text size="p3">{getEsgCategory(category)?.name}</Text>
      </Flex>

      <Box position="absolute" right={0} top={0}>
        <GroupMenu group={group} iconVariant="light" />
      </Box>
    </Flex>
  );
};
