import { Flex, Heading, StackDivider, VStack } from '@chakra-ui/react';

import { SdgIcon } from 'components/Icons/SdgIcon';

import { SdgInfluence } from './types';

const SdgInfluenceTemplate = ({ sdg }: { sdg: SdgInfluence }) => {
  const { internalName, name } = sdg;

  return (
    <Flex my={2} gap={4} ml={3} align="center">
      <Flex width={16} justify="center">
        <SdgIcon
          name={internalName}
          variant="outline"
          additionalStyling={{ h: 12 }}
        />
      </Flex>
      <Heading size="h3">{name}</Heading>
    </Flex>
  );
};

type Props = {
  sdgs: SdgInfluence[];
};

export const SdgInfluencesContent = ({ sdgs }: Props) => {
  return (
    <VStack
      align="flex-start"
      w="full"
      divider={<StackDivider borderBottomWidth="1px" borderColor="ui.grey4" />}
      borderWidth="1px 0px 1px 0px"
      borderColor="ui.grey4"
    >
      {sdgs.map((sdg) => (
        <SdgInfluenceTemplate sdg={sdg} key={sdg.id} />
      ))}
    </VStack>
  );
};
