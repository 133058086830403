import SwiperCore, { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useMemo } from 'react';

import { PAGINATION } from '../constants';
import '../paginationClass.css';

const TRANSITION_DELAY_MS = 8000;
const TRANSITION_SPEED_MS = 500;

SwiperCore.use([Autoplay]);

type CarouselBaseProps<T extends { id: string }> = {
  items: T[];
  ItemComponent: ({ item }: { item: T }) => JSX.Element;
  children?: JSX.Element;
};

export const CarouselBaseV2 = <T extends { id: string }>({
  items,
  ItemComponent,
  children,
}: CarouselBaseProps<T>) => {
  const autoplayConfig = useMemo(
    () => (items.length === 1 ? false : { delay: TRANSITION_DELAY_MS }),
    [items.length]
  );

  if (items.length === 0) return null;

  return (
    <Swiper
      modules={[Autoplay, Pagination]}
      style={{ width: '100%', height: '100%' }}
      loop
      pagination={PAGINATION}
      autoplay={autoplayConfig}
      speed={TRANSITION_SPEED_MS}
    >
      {items.map((item) => (
        <SwiperSlide key={item.id}>
          <ItemComponent key={item.id} item={item} />
        </SwiperSlide>
      ))}
      {children}
    </Swiper>
  );
};
