import { Flex } from '@chakra-ui/react';

import { ConnectionsPanel } from 'components/ConnectionsPanel';
import { MenuUserProfile } from 'components/MenuUserProfile';

// With new grid system this would be 4 gridSpan units
const LEFT_MENU_WIDTH = 308;

export const LeftMenu = () => {
  return (
    <Flex
      direction="column"
      maxW={LEFT_MENU_WIDTH}
      w="full"
      h="fit-content"
      borderColor="darkBlue5"
      borderWidth={1}
      borderRadius={8}
      overflow="hidden"
    >
      <MenuUserProfile />

      <ConnectionsPanel />
    </Flex>
  );
};
