import { Flex } from '@chakra-ui/react';

import { useCurrentLibraryLocation } from 'features/Library/hooks/useCurrentLibraryLocation';

import { BreadcrumbItem } from './BreadCrumbItem';

export const DroppableBreadcrumbsMapper = ({
  breadcrumbItems,
}: {
  breadcrumbItems: BreadcrumbItem[];
}) => {
  const { folderId } = useCurrentLibraryLocation();

  if (!folderId) {
    return null;
  }

  return (
    <Flex>
      {breadcrumbItems.map((item, i) => {
        const isLastItem = breadcrumbItems.length - 1 === i;

        return (
          <BreadcrumbItem key={item.link} item={item} isLastItem={isLastItem} />
        );
      })}
    </Flex>
  );
};
