import { Heading, Flex } from '@chakra-ui/react';

import { Agenda } from 'types';

import { AgendaItem } from '../../AgendaItem';
import { ElementWithBackgroundImage } from '../../ElementWithBackgroundImage';
import { EventBodyElement } from '../../EventBodyElement';

const AGENDA_HEAD_IMAGE_MIN_H = 360;

interface Props {
  agenda?: Agenda;
}

export const AgendaSection = ({ agenda }: Props) => {
  if (!agenda) return null;

  return (
    <>
      <EventBodyElement>
        <ElementWithBackgroundImage
          image={agenda.image}
          minH={AGENDA_HEAD_IMAGE_MIN_H}
        >
          <Heading size="h1" color="ui.white">
            {agenda.heading}
          </Heading>
          <Heading size="h3" color="ui.white">
            {agenda.description}
          </Heading>
        </ElementWithBackgroundImage>
      </EventBodyElement>
      <EventBodyElement>
        <Flex direction="column" gap={4}>
          {agenda.agendaItems.map((item) => (
            <AgendaItem item={item} key={item.heading} />
          ))}
        </Flex>
      </EventBodyElement>
    </>
  );
};
