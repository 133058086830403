import { CSSObject } from '@emotion/react';
import { useField } from 'formik';

import { ReactElement, useId } from 'react';

import { WarningFilled } from '@carbon/icons-react/next';
import {
  FormControl,
  FormErrorMessage,
  Textarea,
  TextareaProps,
  Flex,
  Text,
  Box,
} from '@chakra-ui/react';

import { MaxLengthInfo } from '../MaxLengthInfo';

type Props = {
  type?: string;
  name: string;
  label?: string;
  maxLength?: number;
  labelStyles?: CSSObject;
  containerStyles?: CSSObject;
  inputStyles?: CSSObject;
  placeholder?: string;
  showErrorIcon?: boolean;
} & TextareaProps;

export const TextAreaInput = ({
  name,
  label,
  placeholder,
  labelStyles = {},
  containerStyles = {},
  inputStyles = {},
  maxLength,
  showErrorIcon,
  ...textareaprops
}: Props): ReactElement => {
  const [field, { error, touched }] = useField(name);
  const isInvalid = !!(error && touched);

  const uniqueId = useId();

  return (
    <FormControl isInvalid={isInvalid} sx={containerStyles}>
      <Flex>
        <Box flex="1" fontWeight="unset" mb={0}>
          <Text size="p2" as="label" htmlFor={uniqueId} sx={labelStyles}>
            {label}
          </Text>
        </Box>
        {maxLength && <MaxLengthInfo maxLength={maxLength} field={field} />}
      </Flex>
      <Textarea
        {...field}
        id={uniqueId}
        placeholder={placeholder}
        border="none"
        borderBottom="1px solid"
        borderBottomColor="primary.blue.default"
        bgColor="lightGrey5"
        mt={2}
        borderRadius="0"
        sx={inputStyles}
        value={field.value || ''}
        {...textareaprops}
      />
      <FormErrorMessage gap={1}>
        {error}
        <Flex>{showErrorIcon && <WarningFilled />}</Flex>
      </FormErrorMessage>
    </FormControl>
  );
};
