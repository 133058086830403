import { useFormikContext } from 'formik';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Flex, Button, Text, Box, useDisclosure } from '@chakra-ui/react';

import { ValueCategoryName } from 'types/ValuesConfigurator';

import { useNextValuesConfiguratorPath } from '../useNextValuesConfiguratorPath';
import { SocietalSubmitModal } from './SocietalSubmitModal';

type Props = {
  currentStep: ValueCategoryName;
  wasSubmitted?: boolean;
};

export const SubmitButton = ({ currentStep, wasSubmitted }: Props) => {
  const { t } = useTranslation(['common', 'onboarding']);
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false);
  const [hasChangedAfterSubmission, setHasChangedAfterSubmission] =
    useState(false);
  const [prevValues, setPrevValues] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isValid, errors, dirty, values } = useFormikContext();
  const navigate = useNavigate();
  const errorValue = Object.values(errors);

  const nextPath = useNextValuesConfiguratorPath();

  const isSubmitted = (hasBeenSubmitted && isValid) || wasSubmitted;

  // THIS IS THE UGLIEST THING EVER CREATED, BUT FIXING THAT WOULD REQUIRE
  // MEGA REFACTOR OF THE ENTIRE ONBOARDING STRUCTURE SO THIS IS IT FOR NOW
  useEffect(() => {
    if (isSubmitted && prevValues !== values) {
      setPrevValues(values as object);
      setHasChangedAfterSubmission(true);
    }
  }, [values]);

  useEffect(() => {
    if (wasSubmitted) {
      setHasChangedAfterSubmission(false);
    }
  }, []);

  const handleModalSubmit = () => {
    if (currentStep === ValueCategoryName.Societal) {
      onOpen();
    } else {
      setHasBeenSubmitted(true);
      setHasChangedAfterSubmission(false);
    }
  };

  const handleClick = () =>
    isSubmitted && !hasChangedAfterSubmission
      ? navigate(nextPath)
      : handleModalSubmit();

  const valuesValidated = dirty && isValid;

  return (
    <Flex w="full" ml="auto" direction="column" alignItems="flex-end">
      <SocietalSubmitModal
        onClose={onClose}
        setHasBeenSubmitted={setHasBeenSubmitted}
        isOpen={isOpen}
      />
      <Button
        type={currentStep === ValueCategoryName.Societal ? 'button' : 'submit'}
        onClick={handleClick}
        variant="solid"
        backgroundColor="status.green.default"
        isDisabled={
          isSubmitted && !hasChangedAfterSubmission ? false : !valuesValidated
        }
        position="relative"
        right={0}
        textTransform="uppercase"
        borderRadius={4}
        px={10}
        my={2}
        border="none"
        color="ui.white"
      >
        {isSubmitted && !hasChangedAfterSubmission
          ? t('common:continue')
          : t('onboarding:submit-points')}
      </Button>
      <Text color="status.red.default" size="p3" h={5}>
        {errorValue[0]}
      </Text>
      {isSubmitted && (
        <Box>
          <Text color="primary.blue.default" size="p2">
            {t('onboarding:points-allocation-accepted')}
          </Text>
          <Text size="p2" color="primary.blue.default">
            {t('onboarding:click-to-proceed')}
          </Text>
        </Box>
      )}
    </Flex>
  );
};
