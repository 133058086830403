import { TableContainer, Tbody, Thead, Tr, Table } from '@chakra-ui/react';

import { FamilyMemberNamePanel } from 'components/FamilyMemberNamePanel';
import {
  FamilyOnboardMemberDetailsOutput,
  Role,
  SdgConfiguratorStatusEnum,
  ValueConfiguratorStatusEnum,
} from 'types';

import { AttendanceSelect } from './AttendanceSelect';
import { Cell } from './Cell';
import { InsideBox } from './InsideBox';
import { RsvpCell, RsvpHeaderCell } from './RsvpCell';

const RSVP_LABELS = {
  CA: 'CA means send Email confirming attendance',
  NA: 'NA means send Email confirming customer is not attending but may do the Values Config on his own',
  RSVP2: 'RSVP2 means send a reminder Email asking customer to RSVP',
  P10: 'P10 means sending a Prompt email 10 days before workshop to customers that are attending the workshop in person or by zoom',
  P2: 'P2 means sending a Prompt email 2 days before workshop to customers that are attending the workshop in person or by zoom',
  P24h: 'P24h means sending a Prompt email 24 hours before workshop to customers that are attending the workshop in person or by zoom',
  P1h: 'P1h means sending a Prompt email 1 hour before workshop to customers that are attending the workshop by zoom',
} as const;

type Props = {
  members: FamilyOnboardMemberDetailsOutput[];
};

const Row = ({ member }: { member: FamilyOnboardMemberDetailsOutput }) => {
  const {
    accountId,
    attendanceStatus,
    firstName,
    lastName,
    isHeadOfFamily,
    sdgConfiguratorStatus,
    valueConfiguratorStatus,
    actions,
  } = member;

  return (
    <Tr key={accountId}>
      <Cell>
        <FamilyMemberNamePanel
          firstName={firstName}
          lastName={lastName}
          role={isHeadOfFamily ? Role.HeadOfFamily : Role.FamilyMember}
        />
      </Cell>
      <Cell>
        <AttendanceSelect
          defaultValue={attendanceStatus}
          accountId={accountId}
        />
      </Cell>
      <RsvpCell status={actions.workshopAttendanceConfirmedEmailState} />
      <RsvpCell status={actions.workshopAbsenceConfirmedEmailState} />
      <RsvpCell status={actions.rsvp2ReminderEmailState} />
      <RsvpCell status={actions.tenDaysBeforeWorkshopReminderEmailState} />
      <RsvpCell status={actions.twoDaysBeforeWorkshopReminderEmailState} />
      <RsvpCell status={actions.oneDayBeforeWorkshopReminderEmailState} />
      <RsvpCell status={actions.oneHourBeforeWorkshopReminderEmailState} />
      <RsvpCell status={actions.threeDaysBeforeEventReminderEmailState} />
      <Cell center>
        <InsideBox
          accountId={accountId}
          statusType="valueConfigStatus"
          initialStatus={
            valueConfiguratorStatus as unknown as ValueConfiguratorStatusEnum
          }
        />
      </Cell>
      <Cell center>
        <InsideBox
          accountId={accountId}
          statusType="sdgConfigStatus"
          initialStatus={
            sdgConfiguratorStatus as unknown as SdgConfiguratorStatusEnum
          }
        />
      </Cell>
    </Tr>
  );
};

export const OnboardingTable = ({ members }: Props) => {
  return (
    <TableContainer maxW="5xl" mx="-8px">
      <Table sx={{ borderSpacing: '8px 16px', borderCollapse: 'separate' }}>
        <Thead>
          <Tr fontWeight={900}>
            <Cell width={200}>Member name</Cell>
            <Cell center width={100}>
              Attend
            </Cell>
            {Object.entries(RSVP_LABELS).map(([title, description]) => (
              <RsvpHeaderCell
                title={title}
                description={description}
                key={title}
              />
            ))}
            <Cell center width={100}>
              Value Config
            </Cell>
            <Cell center width={100}>
              SDG Config
            </Cell>
          </Tr>
        </Thead>
        <Tbody>
          {members.length
            ? members.map((member) => (
                <Row member={member} key={member.accountId} />
              ))
            : 'No members in this family'}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
