import { object, number } from 'yup';

const sum = (values: number[]): number => {
  return values.reduce((a, b) => a + b, 0);
};

export const valuesSchema = (valueNames: string[]) =>
  object().shape({
    points: number()
      .test({
        message: 'Total points allocated must be = 100 points.',
        test: (_, { parent }) =>
          sum(valueNames.map((name) => parent[name])) === 100,
      })
      .test({
        message: 'You may not allocate the same points to 2 or more values.',
        test: (_, { parent }) =>
          new Set(valueNames.map((name) => parent[name])).size === 4,
      }),
  });
