import { gql, useMutation } from '@apollo/client';

import { postsGql } from 'hooks/posts';
import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationDeleteCommentArgs } from 'types';

const deleteCommentGql = gql`
  mutation DeleteComment($id: String!) {
    deleteComment(id: $id)
  }
`;

export const useDeleteComment = () => {
  const [mutation] = useMutation<
    Pick<Mutation, 'deleteComment'>,
    MutationDeleteCommentArgs
  >(deleteCommentGql, {
    refetchQueries: [postsGql],
  });

  const handleDeleteComment = async (id: string) => {
    await mutation({ variables: { id } });
  };

  const handleDeleteCommentWithToast = useWithMutationToast(
    handleDeleteComment,
    { disableSuccessMessage: true }
  );

  return { handleDeleteComment: handleDeleteCommentWithToast };
};
