import { ReactNode } from 'react';

import { Flex, FlexProps } from '@chakra-ui/react';

import {
  QueryStateGuard,
  QueryStateGuardProps,
} from 'components/QueryStateGuard';

import { Panel } from './Layout/Panel';

type Props = Pick<QueryStateGuardProps, 'loading' | 'error'> & {
  heading: string;
  children: ReactNode;
  showChildren?: boolean;
  wrapperProps?: FlexProps;
};

export const GroupsListSection = ({
  heading,
  loading,
  error,
  children,
  wrapperProps,
  showChildren = true,
}: Props) => {
  if (!showChildren) {
    return null;
  }

  return (
    <Panel title={heading} variant="secondary" h="fit-content">
      <Flex direction="column" w="full" gap={2} {...wrapperProps}>
        <QueryStateGuard loading={loading} error={error}>
          {children}
        </QueryStateGuard>
      </Flex>
    </Panel>
  );
};
