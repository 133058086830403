import {
  BasePaginationOutput,
  Family,
  FamilyStatusEnum,
  FamilyValueOutput,
  FamilyValuesCategoryEnum,
  FamilyValuesOutput,
} from 'types';

export const generateFamilyValue = ({
  id = Date.now().toString(16),
  name = 'familyValue',
  category = FamilyValuesCategoryEnum.Advancement,
  createdAt = Date.now(),
  updatedAt = Date.now(),
}: Partial<FamilyValueOutput> = {}): FamilyValueOutput => ({
  id,
  name,
  category,
  createdAt,
  updatedAt,
  __typename: 'FamilyValueOutput',
});

export const generateFamilyValues = ({
  advancementValue = generateFamilyValue({ name: 'curiosity' }),
  contributionValue = generateFamilyValue({ name: 'community' }),
  organisationalValue = generateFamilyValue({
    name: 'adaptability',
  }),
  societalValue = generateFamilyValue({ name: 'empowerment' }),
}: Partial<FamilyValuesOutput> = {}): FamilyValuesOutput => ({
  advancementValue,
  contributionValue,
  organisationalValue,
  societalValue,
  __typename: 'FamilyValuesOutput',
});

export const generateFamily = ({
  admins = [],
  advisors = [],
  createdAt = Date.now(),
  familyCharter,
  goals = [],
  heroImage,
  heroVideo,
  id = 'familyId',
  influences = [],
  members = [],
  name = 'familyName',
  purpose = 'familyPurpose',
  sdGoals = [],
  updatedAt = Date.now(),
  values = generateFamilyValues(),
  status = FamilyStatusEnum.Active,
}: Partial<Family> = {}): Family => ({
  admins,
  status,
  advisors,
  createdAt,
  familyCharter,
  goals,
  heroImage,
  heroVideo,
  id,
  influences,
  members,
  name,
  purpose,
  sdGoals,
  updatedAt,
  values,
  __typename: 'Family',
});

export const familyOptionsMock: BasePaginationOutput<
  Pick<Family, 'id' | 'name' | 'status'>
> = {
  nodes: Array.from({ length: 10 }).map((_, i) => ({
    id: `familyId-${i + 1}`,
    name: `familyName-${i + 1}`,
    status: FamilyStatusEnum.Active,
  })),
  offset: 0,
  totalCount: 10,
};

export const familyMock = generateFamily();
