import { useMessagingCommunication } from '../MessagingCommunication';
import {
  useConversationsActions,
  useConversationsSelectors,
  useMessagesActions,
} from '../MessagingStateManager/hooks';

export const useDeleteConversation = () => {
  const { deleteConversation } = useMessagingCommunication();

  const {
    deleteConversation: deleteConversationFromState,
    handleConversationSelect,
  } = useConversationsActions();
  const { deleteAllMessages: deleteAllMessagesFromState } =
    useMessagesActions();
  const { selectConversations } = useConversationsSelectors();

  const handleDeleteConversation = (conversationId: string) =>
    deleteConversation(conversationId).then(() => {
      deleteConversationFromState(conversationId);
      deleteAllMessagesFromState(conversationId);

      handleConversationSelect(
        selectConversations().filter(({ id }) => id !== conversationId)[0]?.id
      );
    });

  return handleDeleteConversation;
};
