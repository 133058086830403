import { useNavigate } from 'react-router-dom';

import { useConversationsActions } from 'features/Messaging/MessagingStateManager/hooks';
import { Links } from 'router/links';
import { AccountStatus, Profile } from 'types';

export const useRedirectToConversationWithUser = (user?: Profile) => {
  const navigation = useNavigate();

  const { handleSelectConversationByUser } = useConversationsActions();

  const handleRedirect = () => {
    const isActive = user?.status === AccountStatus.Active;

    if (!user || !isActive) return;

    handleSelectConversationByUser(user);
    navigation(Links.messageCentre);
  };

  return handleRedirect;
};
