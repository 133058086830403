import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Flex } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/react';

import { Links } from 'router/links';
import {
  ContentEntityType,
  InitiativeNotificationMetadata,
  NewFamilyContentMetadata,
} from 'types';
import { generateUrlById } from 'utils/links';

import { buttonActionStyles } from '../utils';

type Props = {
  metadata: InitiativeNotificationMetadata | NewFamilyContentMetadata;
};

const entityLinkMap: Record<ContentEntityType, Links> = {
  [ContentEntityType.Event]: Links.event,
  [ContentEntityType.Initiative]: Links.initiativesSingle,
  [ContentEntityType.Insight]: Links.insight,
};

export const ViewAction = ({ metadata }: Props) => {
  const entityType = isNewFamilyContent(metadata)
    ? metadata.entityType
    : ContentEntityType.Initiative;

  const entityId = isNewFamilyContent(metadata)
    ? metadata.entityId
    : metadata.initiativeId;

  const navigate = useNavigate();
  const { t } = useTranslation(['common']);

  const handleClick = () => {
    navigate(generateUrlById(entityLinkMap[entityType], entityId));
  };

  if (entityId) return null;

  return (
    <Flex mt={1}>
      <Button onClick={handleClick} {...buttonActionStyles}>
        {t('common:view')}
      </Button>
    </Flex>
  );
};

const isNewFamilyContent = (
  metadata: NewFamilyContentMetadata | InitiativeNotificationMetadata
): metadata is NewFamilyContentMetadata =>
  (metadata as NewFamilyContentMetadata).entityId !== undefined;
