import { AdvisorCardByProfile } from 'components/AdvisorCard';
import { Panel } from 'components/Layout/Panel';
import { Profile } from 'types';

type Props = {
  advisors: Profile[];
};

export const GroupAdvisors = ({ advisors }: Props) => (
  <Panel title="Group Advisors">
    {advisors.map((advisor) => (
      <AdvisorCardByProfile key={advisor.id} profile={advisor} />
    ))}
  </Panel>
);
