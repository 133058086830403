type SdgBlockDescription = {
  title: string;
  description: string;
  thankYouCopy: string;
  linkedSdgsTitle: string;
};

type ValuesConfiguratorCharter = {
  hasFamilyCharter: boolean;
  completedValuesTitle: string;
  completedValuesDescription: string;
  selectionCompleted: string;
};

type OnboardCopy = {
  sdg: SdgBlockDescription;
  onboard: ValuesConfiguratorCharter;
};

const FAMILY_SDG_INFLUENCES_BLOCK: SdgBlockDescription = {
  title: 'Family SDG influences',
  description:
    'These are your family’s 3 SDG influences. They form the cornerstone of your family’s legacy and will be central to creating a lasting and significant impact.',
  thankYouCopy:
    'We will notify you when your family impact charter is ready to view on the Itriom platform.',
  linkedSdgsTitle: 'Your Family Values and their linked SDGs',
};

const INDIVIDUAL_SDG_INFLUENCES_BLOCK: SdgBlockDescription = {
  title: 'Your SDG influences',
  description:
    'These are your 3 SDG influences. They form the cornerstone of your legacy and will be central to creating a lasting and significant impact.',
  thankYouCopy:
    'We will notify you when your profile is ready to view on the Itriom platform. ',
  linkedSdgsTitle: 'Your Values and their linked SDGs',
};

const FAMILY_ONBOARD: ValuesConfiguratorCharter = {
  hasFamilyCharter: true,
  completedValuesDescription:
    'Please proceed onto your Sustainable Development Goals (SDGs) Configurator.',
  completedValuesTitle:
    'Thank you for completing the Itriom Values Configurator to identify your family values.',
  selectionCompleted:
    'Thank you for completing the prioritisation of the UN’s Sustainable Development Goals linked to your family’s values.',
};

const INDIVIDUAL_ONBOARD: ValuesConfiguratorCharter = {
  hasFamilyCharter: false,
  completedValuesDescription:
    'Please proceed on to your Sustainable Development Goals (SDGs) Configurator.',
  completedValuesTitle:
    'Thank you for completing the Itriom Values Configurator to identify your personal values.',
  selectionCompleted:
    'Thank you for completing the prioritisation of the UN’s Sustainable Development Goals linked to your values.',
};

const FAMILY_ONBOARD_COPY: OnboardCopy = {
  sdg: FAMILY_SDG_INFLUENCES_BLOCK,
  onboard: FAMILY_ONBOARD,
};

const INDIVIDUAL_ONBOARD_COPY: OnboardCopy = {
  sdg: INDIVIDUAL_SDG_INFLUENCES_BLOCK,
  onboard: INDIVIDUAL_ONBOARD,
};

export enum OnboardCopyType {
  Individual = 'INDIVIDUAL',
  Family = 'FAMILY',
}

export const SDG_INFLUENCES_BLOCK: Record<OnboardCopyType, OnboardCopy> = {
  INDIVIDUAL: INDIVIDUAL_ONBOARD_COPY,
  FAMILY: FAMILY_ONBOARD_COPY,
};
