import { useTranslation } from 'react-i18next';

import { Download, Share, TrashCan } from '@carbon/icons-react/next';
import { Flex, Button } from '@chakra-ui/react';

import { useLibraryContext } from 'features/Library/context';
import { useBulkActions } from 'features/Library/hooks/useBulkActions';
import { useCurrentLibraryLocation } from 'features/Library/hooks/useCurrentLibraryLocation';
import { LibraryLocation } from 'features/Library/types';

export const Actions = () => {
  const { t } = useTranslation(['library']);
  const { actionBarSelectedItems, shareModal } = useLibraryContext();
  const { libraryLocation } = useCurrentLibraryLocation();
  const {
    deleteMultipleItems,
    downloadMultipleItems,
    removeMultipleTrashItems,
  } = useBulkActions();

  const isTrash = libraryLocation === LibraryLocation.RecentlyDeleted;

  return (
    <Flex flex={1}>
      {!!actionBarSelectedItems.length && (
        <>
          {!actionBarSelectedItems.some((item) => item.isDirectory) && (
            <Button
              variant="ghost"
              leftIcon={<Download />}
              onClick={downloadMultipleItems}
            >
              {t('library:actions.download')}
            </Button>
          )}
          <Button
            variant="ghost"
            leftIcon={<Share />}
            onClick={shareModal.onOpen}
          >
            {t('library:actions.share')}
          </Button>
          <Button
            variant="ghost"
            leftIcon={<TrashCan />}
            onClick={isTrash ? removeMultipleTrashItems : deleteMultipleItems}
          >
            {t(`library:actions.${isTrash ? 'delete-forever' : 'delete'}`)}
          </Button>
        </>
      )}
    </Flex>
  );
};
