import { Loader } from 'components/Loader';
import { ValuesSummary } from 'components/ValuesSummary';

import { useNormalizedValues } from './useNormalizedValues';

export const IndividualValues = (): JSX.Element => {
  const { normalizedValues, error, loading } = useNormalizedValues();

  if (loading) {
    return <Loader />;
  }

  return (
    <ValuesSummary
      error={error}
      normalizedValues={normalizedValues}
      title="Individual Values"
      subtitle="Your Combined Value Category results"
      description="Itriom has grouped values relevant to families, into four categories that capture both the areas of purpose for the family and enablers for achieving the purpose."
    />
  );
};
