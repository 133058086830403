import { useDisclosure } from '@chakra-ui/react';

import { ItriomButtonOutline } from 'components/Button';
import { ConfirmationModal } from 'components/Modal';

import { Row } from './Row';

type Props = {
  label: string;
  question: string;
  isSent: boolean;
  handleTestClick: () => void;
  handleSendClick: () => void;
};

export const NotificationRow = ({
  label,
  handleTestClick,
  handleSendClick,
  isSent,
  question,
}: Props) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const handleClick = () => onOpen();

  return (
    <Row label={label}>
      <ConfirmationModal
        title="Do you want to send this email?"
        actionText="Send"
        question={question}
        isOpen={isOpen}
        onClose={onClose}
        handleClick={handleSendClick}
      />
      <ItriomButtonOutline onClick={handleTestClick}>Test</ItriomButtonOutline>
      <ItriomButtonOutline onClick={handleClick} disabled={isSent}>
        Send
      </ItriomButtonOutline>
    </Row>
  );
};
