import {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useReducer,
} from 'react';

import { StateValue, StateValueCategory, ValueCategory } from '../types';

type WheelState = {
  selectedCategory: StateValueCategory;
  selectedValue: StateValue;
  handleValueChange: (value: string, category: ValueCategory) => void;
  handleCategoryChange: (category: ValueCategory) => void;
};

const WheelStateContext = createContext<WheelState | null>(null);

type StateType = {
  category: StateValueCategory;
  value: StateValue;
};

const reducer = (state: StateType, data: StateType): StateType => {
  if (data.value !== null && data.value === state.value) {
    return { value: null, category: null };
  }

  if (data.value === null && data.category === state.category) {
    return { value: null, category: null };
  }

  return data;
};

export const WheelStateContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [wheelState, dispatch] = useReducer(reducer, {
    category: null,
    value: null,
  });

  const handleValueChange = (value: string, category: ValueCategory) => {
    dispatch({ value, category });
  };

  const handleCategoryChange = (category: ValueCategory) => {
    dispatch({ value: null, category });
  };

  const value: WheelState = useMemo(
    () => ({
      selectedValue: wheelState.value,
      selectedCategory: wheelState.category,
      handleValueChange,
      handleCategoryChange,
    }),
    [wheelState.value, wheelState.category]
  );

  return (
    <WheelStateContext.Provider value={value}>
      {children}
    </WheelStateContext.Provider>
  );
};

export const useWheelState = (): WheelState => {
  const context = useContext(WheelStateContext);

  if (!context) {
    throw new Error("Used useWheelState outside of it's provider");
  }

  return context;
};
