import { ReactNode } from 'react';

import { Flex } from '@chakra-ui/layout';
import { Box } from '@chakra-ui/react';

import { Avatar } from 'components/Avatar';
import { Profile } from 'types';

type Props = {
  profile: Profile;
  type?: string;
  children: ReactNode;
};

export const GroupMemberRow = ({
  profile,
  type = 'Member',
  children,
}: Props) => {
  return (
    <Box w="100%" style={{ boxShadow: `0 2px 5px 0 #354C554D` }}>
      <Flex p={2} align="center" w="100%">
        <Avatar
          profile={profile}
          variant="author"
          subheadingInfo={profile.family?.name}
        />

        <Box ml="auto" color="ui.grey2">
          {type}
        </Box>

        {children}
      </Flex>
    </Box>
  );
};
