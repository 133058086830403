import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Flex, Heading } from '@chakra-ui/layout';
import { Text, Button } from '@chakra-ui/react';

import {
  MAX_OTP_LENGTH,
  VerificationCodeInput,
} from 'components/VerificationCodeInput';
import { useAuthContext } from 'contexts/AuthContext';
import { useVerifySecondFactor } from 'hooks/authorization/useVerifySecondFactor';
import { useRedirectUrl } from 'hooks/useRedirectUrl';
import { Links } from 'router/links';

import { ResendOtpSection } from '../components/ResendOtpSection';

const OTP_CODE_LENGTH = 6;

export const TwoFA = () => {
  const { secondFactorJWT } = useAuthContext();
  const { t } = useTranslation(['common']);

  const navigate = useNavigate();

  const [otpCode, setOtpCode] = useState('');
  const [isValid, setIsValid] = useState(true);
  const { navigateToRedirectUrl } = useRedirectUrl();

  const { verifySecondFactor, loading } = useVerifySecondFactor({
    onError: () => {
      setIsValid(false);
    },
  });

  const handleAuthenticate = useCallback(() => {
    if (!secondFactorJWT) return;
    verifySecondFactor({
      secondFactor: Number(otpCode),
      jwt: secondFactorJWT,
    }).then(({ errors }) => {
      if (!errors) {
        navigateToRedirectUrl();
      }
    });
  }, [otpCode]);

  useEffect(() => {
    if (otpCode.length === OTP_CODE_LENGTH) {
      handleAuthenticate();
    }
  }, [handleAuthenticate, otpCode]);

  const disabled = otpCode.length < MAX_OTP_LENGTH || loading;

  const handleComplete = (value: string) => setOtpCode(value);

  useEffect(() => {
    if (!secondFactorJWT) {
      navigate(Links.signIn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex direction="column" align="center" gridGap={2} minH="100vh">
      <Heading mt={6}>{t('common:otp.enter-2fa-code')}</Heading>
      <Text>{t('common:otp.page-subheading')}</Text>
      <Flex m={4}>
        <VerificationCodeInput
          setIsValid={setIsValid}
          isValid={isValid}
          border="none"
          w={14}
          h={78}
          bgColor="ui.white"
          borderRadius={8}
          m={2}
          fontSize={40}
          onComplete={handleComplete}
        />
      </Flex>
      <Flex gridGap={2}>
        <Button variant="outline" onClick={() => navigate(Links.signIn)}>
          {t('common:back')}
        </Button>
        <Button
          isLoading={loading}
          onClick={handleAuthenticate}
          disabled={disabled}
        >
          {t('common:submit')}
        </Button>
      </Flex>
      <ResendOtpSection />
    </Flex>
  );
};
