import { Center, Heading } from '@chakra-ui/react';

import { Links } from 'router/links';

import { ValuesLayout, CheckIcon } from '../components';

export const ValuesCtaPage = () => {
  return (
    <ValuesLayout
      showHeader
      leftButton={{
        link: Links.valuesConfiguratorImpact,
        color: 'ui.white',
      }}
      rightButton={{
        link: Links.valuesConfiguratorHowToUse,
        text: 'Launch configurator',
        color: 'primary.blue.default',
      }}
      background="primary.blue.default"
      color="ui.white"
    >
      <Center flexDirection="column" maxW={540} textAlign="center" gap={16}>
        <Heading size="h1">
          You can now start the prioritisation of your values
        </Heading>
        <CheckIcon />
        <Heading size="h3">How will you make an impact?</Heading>
      </Center>
    </ValuesLayout>
  );
};
