import { DocumentNode, OperationVariables } from '@apollo/client';
import { MockedResponse } from '@apollo/client/testing';

import { Mutation, Query } from 'types';

// it is nested Partial<> for objects
type Subset<K> = {
  [attr in keyof K]?: K[attr] extends object ? Subset<K[attr]> : K[attr];
};

type QM = Query | Mutation;

export const createMock = <
  TData extends Subset<QM> = Partial<Record<string, unknown>>,
  TVariables extends OperationVariables = OperationVariables
>(
  query: DocumentNode,
  result: MockedResponse<Subset<TData>>['result'],
  variables?: TVariables
): MockedResponse<Subset<TData>> => ({
  request: {
    query,
    variables,
  },
  result,
});
