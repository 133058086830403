import { Fragment } from 'react';

import { createPage } from 'router/utils/createPage';

import { PageConfig } from '../types';

export const createPagesFromConfig = (config: PageConfig) => {
  const {
    roles: mainConfigRole,
    navigationLabelKey,
    subPages,
    options,
  } = config;

  const subpagesConfigs: PageConfig[] =
    subPages?.map((subpage) => ({
      ...subpage,
      roles: mainConfigRole,
      navigationLabelKey,
    })) || [];

  const flatConfig = [config, ...subpagesConfigs];

  return (
    <>
      {flatConfig.map(({ link, component, roles }) => (
        <Fragment key={link}>
          {createPage(link, component, { roles, ...options })}
        </Fragment>
      ))}
    </>
  );
};
