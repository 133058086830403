import { Box, Heading, Text } from '@chakra-ui/react';

import { useCategoryCopy } from 'hooks/copy/useCategoryCopy';
import { useValuesCopy } from 'hooks/copy/useValueCopy';
import { FamilyValueOutput } from 'types';

type Props = {
  value: FamilyValueOutput;
};

export const ValueCardSimple = ({ value }: Props): JSX.Element => {
  const { category, name } = value;

  const { getValueCopy } = useValuesCopy();
  const { getCategoryCopy } = useCategoryCopy();

  const { description } = getValueCopy(name);
  const { question } = getCategoryCopy(category);

  return (
    <Box background="ui.white" p={4} pb={8} minHeight="16rem">
      <Heading size="h4" mb={4} pb={2} borderBottom="1px solid">
        {value.name}
      </Heading>
      <Text size="p2b" mb={2}>
        {question}
      </Text>
      <Text>{description}</Text>
    </Box>
  );
};
