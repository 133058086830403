import {
  differenceInDays,
  Duration,
  format,
  formatDistanceToNow,
  intervalToDuration,
} from 'date-fns';

export const formatDateTime = (date?: Date | number, withSeconds?: boolean) =>
  date ? format(date, `dd MMM yyyy, HH:mm${withSeconds ? ':ss' : ''}`) : '-';

export const formatDate = (
  date: Date | number | string,
  dateFormat = 'dd/MM/yyyy'
): string => format(new Date(date), dateFormat);

export const formatTimestamp = (date: Date | number): string =>
  formatDate(date, "yyyy-MM-dd'T'HH:mm");

export const dateDistance = (
  date: Date | number,
  phrases?: { before: string; after: string }
): string => {
  if (!phrases) {
    return formatDistanceToNow(date, { addSuffix: true });
  }

  return `${formatDistanceToNow(date)} ${
    date > Date.now() ? phrases.before : phrases.after
  }`;
};

export const duration = (
  date1: Date | number,
  date2: Date | number
): Duration => {
  return intervalToDuration({
    start: date1,
    end: date2,
  });
};

export const stringToInputDate = (date?: string): string =>
  date?.split('T')[0] || '';

export const getIsMultipleDay = (
  startDay?: string | number | Date,
  endDate?: string | number | Date
) => {
  return startDay && endDate
    ? Math.abs(differenceInDays(new Date(startDay), new Date(endDate))) > 0
    : false;
};

export const getCurrentDateWithRounding = () =>
  Math.ceil(Date.now() / 1e5) * 1e5;
