import { useTranslation } from 'react-i18next';

import { Close20, Search32 } from '@carbon/icons-react';
import {
  Flex,
  GridItem,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  IconButton,
} from '@chakra-ui/react';

import { Panel } from 'components/Layout/Panel';
import { useSearchContext } from 'contexts/SearchContext';

export const SearchBox = () => {
  const {
    hasResults,
    pageSearchTerm,
    setPageSearchTerm,
    clearSearchTerm,
    totalResults,
    handleRefetchResults,
  } = useSearchContext();
  const { t } = useTranslation(['common']);

  return (
    <GridItem as={Panel}>
      <Flex direction="column" gap="11" alignItems="center" w="full">
        <Text size="p1" w="75%" color="ui.grey2">
          {t('common:search.found-results', {
            numberOfResults: hasResults ? totalResults : t('common:no'),
          })}
        </Text>
        <InputGroup w="75%">
          <Input
            px={3}
            type="text"
            autoFocus
            fontSize="sm"
            value={pageSearchTerm}
            onChange={(e) => {
              setPageSearchTerm(e.target.value);
            }}
            onKeyDown={(e) =>
              handleRefetchResults({ event: e, isTopRightSearchBox: false })
            }
            placeholder={t('common:search.placeholder')}
          />
          <InputRightElement>
            <IconButton
              variant="ghost"
              isRound
              aria-label={t('common:search.close')}
              onClick={clearSearchTerm}
            >
              <Close20 />
            </IconButton>
          </InputRightElement>
        </InputGroup>
        {!hasResults && (
          <Flex direction="column" alignItems="center" px="6" py="5" gap="2">
            <Heading textAlign="center">
              {t('common:search.no-results-heading')}
            </Heading>
            <Search32 width={88} height={88} />
            <Text size="p1" textAlign="center">
              {t('common:search.no-results-text')}
            </Text>
          </Flex>
        )}
      </Flex>
    </GridItem>
  );
};
