import { Form, FormikProvider, useFormik } from 'formik';

import { KeyboardEvent } from 'react';

import { Send } from '@carbon/icons-react/next';
import { Divider, HStack } from '@chakra-ui/layout';
import { Button, Flex, Icon } from '@chakra-ui/react';

import { TextAreaInput } from 'components/Form/TextAreaInput';

type Props = {
  disabled?: boolean;
  sendMessage: (content: string) => Promise<void>;
};

export const NewMessageInput = ({
  disabled,
  sendMessage,
}: Props): JSX.Element => {
  const formik = useFormik({
    initialValues: {
      message: '',
    },
    onSubmit: (values, helpers) =>
      sendMessage(values.message).then(() => helpers.resetForm()),
  });

  const handleOnKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (
      event.shiftKey &&
      event.key === 'Enter' &&
      formik.values.message.trim().length
    ) {
      event.preventDefault();
      event.stopPropagation();
      formik.submitForm();
    }
  };

  const isDisabled = disabled || formik.values.message.trim().length === 0;

  return (
    <FormikProvider value={formik}>
      <Form>
        <Flex direction="column" data-cy="message-input-component">
          <Divider />
          <TextAreaInput
            name="message"
            borderBottom="none"
            bgColor="ui.white"
            placeholder="Type your message..."
            size="sm"
            border="none"
            minH={20}
            onKeyDown={handleOnKeyDown}
          />
          <Divider />
          <HStack p={5} justify="flex-end">
            <Button
              type="submit"
              isDisabled={isDisabled}
              aria-label="send message"
              background="transparent"
              color="primary.blue.default"
              border="none"
              pr="0"
              _hover={{ color: 'ui.grey2' }}
            >
              <Icon as={Send} w={6} h={6} />
            </Button>
          </HStack>
        </Flex>
      </Form>
    </FormikProvider>
  );
};
