import { Box, Flex, Text } from '@chakra-ui/react';

import { Avatar } from 'components/Avatar';
import { Profile } from 'types';
import { dateDistance } from 'utils/date';

type PostCardHeaderProps = {
  postedBy: Profile;
  createdAt: number;
  systemMessage?: string;
};

export const PostCardHeader = ({
  postedBy,
  createdAt,
  systemMessage,
}: PostCardHeaderProps) => {
  return (
    <Flex w="full" direction="column">
      <Avatar
        profile={postedBy}
        subheadingInfo={dateDistance(createdAt)}
        variant="horizontal"
      />
      {systemMessage && (
        <Box ml={14}>
          <Text size="p3b" color="ui.grey2">
            {systemMessage}
          </Text>
        </Box>
      )}
    </Flex>
  );
};
