import { Avatar, Box } from '@chakra-ui/react';

import { Conversation as ConversationType } from 'types';
import { getAvatarSrc } from 'utils/file';

type Props = {
  conversation: ConversationType;
};

export const GroupConversationAvatars = ({ conversation }: Props) => {
  const [firstMember, secondMember] = conversation.members;

  if (!firstMember || !secondMember) return null;

  return (
    <Box w="full">
      <Box>
        <Avatar src={getAvatarSrc(firstMember.avatarImage)} size="sm" />
      </Box>
      <Box transform="translate(14px, -12px)">
        <Avatar src={getAvatarSrc(secondMember.avatarImage)} size="sm" />
      </Box>
    </Box>
  );
};
