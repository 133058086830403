import { useQuery } from '@apollo/client';

import { Query } from 'types';

import { advisorGql } from '../documents';

export const useAdvisor = (id: string | undefined) => {
  const { data, loading, error, refetch } = useQuery<Pick<Query, 'advisor'>>(
    advisorGql,
    { variables: { advisorId: id }, skip: !id }
  );
  const advisor = data?.advisor;

  return { advisor, loading, error, refetch };
};
