import { Flex } from '@chakra-ui/react';

import { ArticleBody } from 'components/ArticleBody/ArticleBody';
import { LayoutGridElement } from 'components/Layout/Grid';
import { InsightBlockUnion } from 'types';

interface Props {
  content: InsightBlockUnion[];
}

export const ContentSection = ({ content }: Props) => {
  const sortedBlocks = [...content].sort((a, b) => a.order - b.order);

  return (
    <LayoutGridElement gridColumn="3/ span 12">
      <Flex direction="column" gap={4}>
        {sortedBlocks.map((block, i) => (
          <ArticleBody key={i} body={block} />
        ))}
      </Flex>
    </LayoutGridElement>
  );
};
