import { Divider, VStack } from '@chakra-ui/react';

import { Avatar } from 'components/Avatar';
import { Profile } from 'types';

type Props = {
  filteredConnections: Profile[];
  onConnectionSelect: (profile: Profile) => void;
};

export const InputSuggestions = ({
  onConnectionSelect,
  filteredConnections,
}: Props) => (
  <VStack
    align="flex-start"
    w="100%"
    divider={<Divider borderColor="darkBlue5" />}
    spacing="0px"
  >
    {filteredConnections.map((profile) => (
      <Avatar
        profile={profile}
        key={profile.id}
        variant="author"
        onProfileClick={() => onConnectionSelect(profile)}
      />
    ))}
  </VStack>
);
