import { CSSObject } from '@emotion/react';
import { useField } from 'formik';

import { ReactNode, useCallback, useMemo, useId } from 'react';

import { ApolloError } from '@apollo/client';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  Text,
  Select,
} from '@chakra-ui/react';

import type { Option } from 'types';

type Props<T extends string> = {
  type?: string;
  name: string;
  label?: string;
  helperIcons?: ReactNode;
  options: Option<T>[];
  placeholder?: string;
  containerStyles?: CSSObject;
  inputStyles?: CSSObject;
  onChange?: (value: T) => void;
  disabled?: boolean;
  isClearable?: boolean;
  optionValueKey?: string;
  hideError?: boolean;
  defaultOptionText?: string;
  loading?: boolean;
  error?: ApolloError;
  labelHint?: string;
  hidden?: boolean;
};

export const SelectInput = <T extends string = string>({
  name,
  label,
  helperIcons,
  options,
  containerStyles = {},
  inputStyles = {},
  placeholder,
  onChange,
  disabled = false,
  isClearable = false,
  optionValueKey,
  hideError,
  loading,
  error: fetchError,
  labelHint,
  defaultOptionText = '-- Select --',
  hidden = false,
}: Props<T>) => {
  const [field, { error, touched }, { setValue }] = useField({ name });
  const uniqueId = useId();

  const value =
    (optionValueKey ? field.value?.[optionValueKey] : field.value) ?? '';

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      if (onChange) {
        onChange(event.target.value as T);
      } else {
        setValue(event.target.value);
      }
    },
    [onChange, setValue]
  );

  const isInvalid = touched && !!error;

  const shouldDisplayClear = isClearable && !placeholder && !!field.value;

  const ClearOption = <option value="">-- Clear --</option>;

  const noOptions = options.length === 0;
  const isDisabled = loading || disabled || noOptions;

  const defaultText = useMemo(() => {
    if (loading) return 'Loading...';
    if (fetchError) return 'Error loading options';
    if (noOptions) return 'No options to select';

    return defaultOptionText;
  }, [defaultOptionText, loading, noOptions, fetchError]);

  const DefaultOption = (
    <option value="" disabled hidden={Boolean(placeholder)}>
      {defaultText}
    </option>
  );

  if (hidden) return null;

  return (
    <FormControl isInvalid={isInvalid} sx={containerStyles}>
      <Flex mb={2}>
        {label && (
          <Text as="label" htmlFor={uniqueId}>
            {label}
          </Text>
        )}
        {labelHint && <Text ml={1}>{labelHint}</Text>}
        {helperIcons}
      </Flex>
      <Select
        {...field}
        value={value}
        name={name}
        id={uniqueId}
        placeholder={placeholder}
        onChange={handleChange}
        position="relative"
        sx={inputStyles}
        border="none"
        borderBottom="1px solid"
        borderBottomColor="primary.blue.default"
        bgColor="lightGrey5"
        borderRadius="0"
        isDisabled={isDisabled}
        rootProps={{ sx: { display: 'inline' } }}
      >
        {shouldDisplayClear ? ClearOption : DefaultOption}
        {options.map(
          ({
            label: optionLabel,
            value: optionsValue,
            disabled: disabledOption,
          }) => (
            <option
              value={optionsValue}
              key={optionsValue + optionLabel}
              disabled={disabledOption}
            >
              {optionLabel}
            </option>
          )
        )}
      </Select>
      {!hideError && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
};
