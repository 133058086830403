import { ReactNode } from 'react';

import { Flex, FlexProps, Heading } from '@chakra-ui/react';

import { WithChildren } from 'types';

export type PanelVariant = 'primary' | 'secondary';

const BACKGROUND: Record<PanelVariant, FlexProps['background']> = {
  primary: 'ui.white',
  secondary: 'transparent',
};

type Props = WithChildren<{
  title?: ReactNode;
  action?: ReactNode;
  variant?: PanelVariant;
  ariaLabel?: string;
  containerStyling?: FlexProps;
  contentWrapperStyling?: FlexProps;
  titleWrapperStyling?: FlexProps;
  h?: 'full' | 'fit-content';
}>;

export const Panel = ({
  children,
  title,
  action,
  variant = 'primary',
  ariaLabel,
  containerStyling,
  contentWrapperStyling,
  titleWrapperStyling,
  h = 'full',
}: Props) => (
  <Flex
    h={h}
    w="full"
    direction="column"
    background={BACKGROUND[variant]}
    borderWidth="1px"
    borderStyle="solid"
    borderColor="ui.grey4"
    borderRadius="2px"
    aria-label={ariaLabel}
    {...containerStyling}
  >
    {title && (
      <Flex
        p={4}
        direction="row"
        w="full"
        borderBottomWidth="1px"
        borderStyle="solid"
        borderColor="ui.grey4"
        justify="space-between"
        {...titleWrapperStyling}
      >
        <Heading size="h5">{title}</Heading>
        {action}
      </Flex>
    )}

    <Flex p={4} h="full" w="full" {...contentWrapperStyling}>
      {children}
    </Flex>
  </Flex>
);
