import { Flex, Text } from '@chakra-ui/react';

import { GroupsListSection } from 'components/GroupsListSection';
import { SingleGroupCard } from 'components/SingleGroupCard';
import { useTrendingGroups } from 'hooks/groups';
import { useEsgCategories } from 'hooks/useEsgCategories';

export const TrendingGroups = () => {
  const {
    esgCategories,
    loading: esgLoading,
    error: esgError,
  } = useEsgCategories();

  const { trendingGroups, loading, error } = useTrendingGroups();

  return (
    <GroupsListSection
      heading="Trending"
      loading={loading || esgLoading}
      error={error || esgError}
      showChildren={!!trendingGroups.length}
    >
      {esgCategories.map(({ id, name }) => {
        const filteredGroups = trendingGroups
          .filter(({ category }) => category === id)
          .slice(0, 4)
          .map((group) => (
            <SingleGroupCard key={group.id} group={group} variant="secondary" />
          ));

        if (!filteredGroups.length) {
          return null;
        }

        return (
          <Flex key={name} direction="column" gap={2}>
            <Text>{name}</Text>
            {filteredGroups}
          </Flex>
        );
      })}
    </GroupsListSection>
  );
};
