import { Flex, Image, Heading } from '@chakra-ui/react';

import { CategoryDescription } from './types';

type Props = {
  isActive: boolean;
  handleSelect: VoidFunction;
  valueDescription: CategoryDescription;
};

export const CategorySection = ({
  valueDescription,
  handleSelect,
  isActive,
}: Props) => {
  const { color, clipPath, name, translate, index } = valueDescription;

  const rotate = -112.5 + index * 45;

  return (
    <Flex
      as="button"
      position="absolute"
      width="full"
      height="full"
      bgColor={color}
      borderRadius="50%"
      clipPath={clipPath}
      zIndex={1}
      onClick={handleSelect}
    >
      {!isActive && (
        <Flex
          position="absolute"
          width="62.5%"
          height="62.5%"
          bgColor="rgba(255,255,255,0.25)"
          top="50%"
          left="50%"
          transform="translate(-50%,-50%)"
          borderRadius="50%"
          justifyContent="center"
          zIndex={2}
        />
      )}
      <Flex
        position="absolute"
        width="65%"
        height="65%"
        top="50%"
        left="50%"
        transform={`translate(-50%,-50%) rotate(${rotate}deg)`}
        justifyContent="flex-start"
        alignItems="center"
        fontSize="xl"
        fontWeight={900}
        zIndex={4}
        direction="column"
      >
        {!isActive && (
          <Heading size="h4" mt={1} color="ui.white">
            {index}
          </Heading>
        )}
        <Image
          position="absolute"
          src={`/assets/values-essential/${name.toLowerCase()}.svg`}
          transform={`${translate} rotate(${-rotate}deg)`}
        />
      </Flex>
    </Flex>
  );
};
