import { Grid, GridItem, Flex } from '@chakra-ui/react';

import { BackgroundImageTile } from 'components/ContentGrid/BackgroundImageTile';
import { SideImageTile } from 'components/ContentGrid/SideImageTile';
import { TextTile } from 'components/ContentGrid/TextTile';
import { Loader } from 'components/Loader';

import { CommonInsightsGridProps } from './types';
import { getTilePropsFromInsight } from './utils';

export const MobileInsightsGrid = ({
  gridInsights,
  loading,
  headingContent,
}: CommonInsightsGridProps) => {
  if (loading) {
    return (
      <Flex alignItems="center" justifyContent="center" h={520}>
        <Loader />
      </Flex>
    );
  }

  if (!gridInsights.length) {
    return null;
  }

  return (
    <Grid
      display="inline-grid"
      gridTemplateColumns="1fr"
      gridGap={6}
      alignContent="flex-start"
    >
      <GridItem p={4}>{headingContent}</GridItem>

      {gridInsights[0] && (
        <GridItem>
          <SideImageTile
            {...getTilePropsFromInsight(gridInsights[0])}
            imagePosition="top"
          />
        </GridItem>
      )}
      {gridInsights[1] && (
        <GridItem>
          <TextTile {...getTilePropsFromInsight(gridInsights[1])} />
        </GridItem>
      )}
      {gridInsights[2] && (
        <GridItem>
          <BackgroundImageTile {...getTilePropsFromInsight(gridInsights[2])} />
        </GridItem>
      )}
      {gridInsights[3] && (
        <GridItem>
          <SideImageTile
            {...getTilePropsFromInsight(gridInsights[3])}
            imagePosition="top"
          />
        </GridItem>
      )}
      {gridInsights[4] && (
        <GridItem>
          <BackgroundImageTile {...getTilePropsFromInsight(gridInsights[4])} />
        </GridItem>
      )}
    </Grid>
  );
};
