import { Close } from '@carbon/icons-react/next';
import { Icon, IconButton } from '@chakra-ui/react';

import { MenuElement, ThreeDotsMenu } from 'components/ThreeDotsMenu';

type Props = {
  isEditing: boolean;
  handleCancelEditing: VoidFunction;
  menuElements: MenuElement[];
};

export const ManagePostMenuButton = ({
  isEditing,
  handleCancelEditing,
  menuElements,
}: Props) => {
  if (!menuElements.length) return null;

  return isEditing ? (
    <IconButton
      w="30px"
      h="30px"
      minW="30px"
      variant="ghost"
      icon={<Icon as={Close} h={5} w={5} />}
      aria-label="close"
      onClick={handleCancelEditing}
    />
  ) : (
    <ThreeDotsMenu elements={menuElements} iconVariant="dark" />
  );
};
