import { useTranslation } from 'react-i18next';

import { Heading, Text } from '@chakra-ui/react';

import { Section } from 'components/Layout/Sections/Section';
import { QueryStateGuard } from 'components/QueryStateGuard';
import { SdgInfluencesContent } from 'components/SdgInfluencesContent';
import { useFamilyById } from 'gql/family/query/family';

import { SdgDescription } from './SdgDescription';

type Props = {
  familyId: string;
};

const ErrorComponent = () => {
  const { t } = useTranslation(['family']);

  return <Text size="p1">{t('family:charter.error.loadingSdgs')}</Text>;
};

export const SdgInfluencesSection = ({ familyId }: Props) => {
  const { t } = useTranslation(['family']);

  const { family, error, loading } = useFamilyById(familyId);

  return (
    <Section
      title={t('family:charter.section.sdgInfluences.title')}
      subtitleContent={<SdgDescription />}
    >
      <QueryStateGuard
        error={error}
        loading={loading}
        ErrorComponent={ErrorComponent}
      >
        {family && family.sdGoals.length ? (
          <SdgInfluencesContent sdgs={family?.sdGoals} />
        ) : (
          <Heading size="h4">
            {t('family:charter.error.sdgConfigNotFinished')}
          </Heading>
        )}
      </QueryStateGuard>
    </Section>
  );
};
