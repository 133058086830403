import { Link } from 'react-router-dom';

import { Flex } from '@chakra-ui/react';

import { Avatar } from 'components/Avatar';
import { useAuthContext } from 'contexts/AuthContext';
import { Links } from 'router/links';
import { WithChildren } from 'types';
import { generateUrlById } from 'utils/links';
import { getFullName } from 'utils/string';

export const MenuUserProfile = ({ children }: WithChildren) => {
  const { account } = useAuthContext();

  return (
    <Flex w="full" direction="column" color="primary.blue.default" gap={1}>
      {account && (
        <Link
          to={generateUrlById(Links.profile, account.id)}
          title={getFullName(account.firstName, account.lastName)}
        >
          <Flex
            p={3}
            h={145}
            w="full"
            align="center"
            justify="center"
            border="default"
            borderRadius="4px 4px 0px 0px"
            borderBottomWidth={0}
          >
            <Avatar profile={account} />
          </Flex>
        </Link>
      )}

      {children}
    </Flex>
  );
};
