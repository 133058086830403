import { Tab } from 'components/TabPane';
import { InitiativeType } from 'features/Initiative/types';

import { InitiativesTabPanel } from './InitiativesTabPanel';
import { InitiativesTabWithCarouselPanel } from './InitiativesTabWithCarouselPanel';

type InitiativesTabs = InitiativeType;

export const initiativesTabsConfig: Record<InitiativesTabs, Tab> = {
  [InitiativeType.All]: {
    content: <InitiativesTabPanel type={InitiativeType.All} />,
    navigateTo: `#${InitiativeType.All}`,
    labelKey: 'all',
  },
  [InitiativeType.Family]: {
    content: <InitiativesTabPanel type={InitiativeType.Family} />,
    navigateTo: `#${InitiativeType.Family}`,
    labelKey: 'family',
  },
  [InitiativeType.Joint]: {
    content: <InitiativesTabPanel type={InitiativeType.Joint} />,
    navigateTo: `#${InitiativeType.Joint}`,
    labelKey: 'joint',
  },
  [InitiativeType.Peer]: {
    content: <InitiativesTabPanel type={InitiativeType.Peer} />,
    navigateTo: `#${InitiativeType.Peer}`,
    labelKey: 'peer',
  },
  [InitiativeType.Public]: {
    content: <InitiativesTabPanel type={InitiativeType.Public} />,
    navigateTo: `#${InitiativeType.Public}`,
    labelKey: 'public',
  },
  [InitiativeType.Followed]: {
    content: <InitiativesTabWithCarouselPanel type={InitiativeType.Followed} />,
    navigateTo: `#${InitiativeType.Followed}`,
    labelKey: 'followed',
  },
};
