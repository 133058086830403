import { useTranslation, TFuncKey } from 'react-i18next';

import { Button, Flex } from '@chakra-ui/react';

import { ItriomButtonOutline } from 'components/Button';
import { Modal } from 'components/Modal';

type Props = {
  isOpen: boolean;
  name: string;
  isActive: boolean;
  onClose: VoidFunction;
  onConfirm: VoidFunction;
  entity: string;
};

export const ActivationConfirmationModal = ({
  isOpen,
  name,
  onClose,
  onConfirm,
  isActive,
  entity,
}: Props) => {
  const { t } = useTranslation(['cms', 'common']);

  const transKeyPrefix: TFuncKey<'cms'[]> = `cms:${
    isActive ? 'deactivate' : 'activate'
  }`;
  const buttonColor = isActive ? 'status.red.default' : 'status.green.default';

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t(`${transKeyPrefix}.title`, { name })}
      containerStyling={{ p: 4 }}
      footer={
        <Flex w="full" justify="space-between">
          <Button
            onClick={onConfirm}
            size="lg"
            color="ui.white"
            background={buttonColor}
            borderColor={buttonColor}
          >
            {t(`${transKeyPrefix}.confirm`)}
          </Button>
          <ItriomButtonOutline onClick={onClose}>
            {t('common:no')}
          </ItriomButtonOutline>
        </Flex>
      }
    >
      {t(`${transKeyPrefix}.action-explanation`, {
        entity,
      })}
    </Modal>
  );
};
