import { FormikProvider, useFormik } from 'formik';

import { useTranslation } from 'react-i18next';

import { useAuthContext } from 'contexts/AuthContext';
import { CmsLayout } from 'features/Cms/components/CmsLayout';
import { EditFamilyMembersSection } from 'features/Cms/components/Onboarding';
import { useExtendedProfiles } from 'hooks/profiles/useExtendedProfiles';

export const EditFamilyPage = () => {
  const { t } = useTranslation(['cms']);

  const { account } = useAuthContext();

  const { extendedProfiles, loading, error } = useExtendedProfiles(
    account?.familyId
  );

  const formik = useFormik({
    initialValues: {},
    onSubmit: () => Promise.resolve(),
  });

  return (
    <FormikProvider value={formik}>
      <CmsLayout
        title={t('cms:members.family')}
        loading={loading}
        error={error}
      >
        {extendedProfiles && (
          <EditFamilyMembersSection
            members={extendedProfiles}
            adminType="family"
          />
        )}
      </CmsLayout>
    </FormikProvider>
  );
};
