import { Links } from 'router/links';
import { LibraryTypeEnum } from 'types';

export const libraryTypeFolderLinkMap: Record<LibraryTypeEnum, Links> = {
  [LibraryTypeEnum.Family]: Links.libraryFamilyFolder,
  [LibraryTypeEnum.Itriom]: Links.libraryItriomFolder,
  [LibraryTypeEnum.Own]: Links.libraryMyFolder,
};

export const libraryTypeBaseLinkMap: Record<LibraryTypeEnum, Links> = {
  [LibraryTypeEnum.Family]: Links.libraryFamily,
  [LibraryTypeEnum.Itriom]: Links.libraryItriom,
  [LibraryTypeEnum.Own]: Links.libraryMy,
};
