import { conversationsReducer } from './Conversations';
import {
  ConversationsActionsUnion,
  ConversationsActionTypes,
} from './Conversations/types';
import { messagesReducer } from './Messages';
import { MessagesActionsUnion, MessagesActionTypes } from './Messages/types';
import { CombinedState } from './types';

const isMessagesAction = (
  action: MessagesActionsUnion | ConversationsActionsUnion
): action is MessagesActionsUnion => {
  return (Object.values(MessagesActionTypes) as string[]).includes(action.type);
};

const isConversationsAction = (
  action: MessagesActionsUnion | ConversationsActionsUnion
): action is ConversationsActionsUnion => {
  return (Object.values(ConversationsActionTypes) as string[]).includes(
    action.type
  );
};

export const combinedReducer = (
  state: CombinedState,
  action: MessagesActionsUnion | ConversationsActionsUnion
): CombinedState => ({
  conversations: isConversationsAction(action)
    ? conversationsReducer(state.conversations, action)
    : state.conversations,
  messages: isMessagesAction(action)
    ? messagesReducer(state.messages, action)
    : state.messages,
});
