import { FieldInputProps } from 'formik';

import { ChangeEvent, useCallback, useState } from 'react';

import { Box, CSSObject, Select, Wrap, WrapItem } from '@chakra-ui/react';
import type { InputProps } from '@chakra-ui/react';

import { Option } from 'types';

import { ItemTag } from './ItemTag';

export type ChakraTagInputProps = InputProps & {
  options: Option[];
  tags: string[];
  field: FieldInputProps<string[]>;
  onTagsChange(tags: string[]): void;
  setError: (message: string) => void;
  inputStyles?: CSSObject;
};

export const TagInputCore = ({
  options,
  tags,
  onTagsChange,
  field,
  setError,
  inputStyles = {},
  ...props
}: ChakraTagInputProps) => {
  const [inputValue, setInputValue] = useState('');

  const addTag = useCallback(
    (value: string) => {
      onTagsChange([...tags, value]);
      setInputValue('');
    },
    [tags, onTagsChange]
  );

  const removeTag = useCallback(
    (index: number) => {
      onTagsChange([...tags.slice(0, index), ...tags.slice(index + 1)]);
    },
    [tags, onTagsChange]
  );

  const handleRemoveTag = (index: number) => () => {
    removeTag(index);
  };

  const handleSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;

    // @ts-expect-error Picking element from datalist doesn't have inputType in event
    if (event.nativeEvent.inputType) {
      setInputValue(value);
    } else {
      addTag(value);
    }
  };

  return (
    <>
      <Box flexGrow={1} sx={inputStyles}>
        <Select
          value={inputValue}
          onChange={handleSelect}
          onBlur={field.onBlur}
          autoComplete="off"
          border="none"
          borderBottom="1px solid"
          borderBottomColor="primary.blue.default"
          bgColor="lightGrey5"
          borderRadius={0}
          height={45}
        >
          <>
            <option disabled selected value="">
              Select
            </option>

            {options.map(({ value, label }) => (
              <option
                key={`option-${value}`}
                value={value}
                disabled={!!tags.find((tag) => tag === value)}
              >
                {label}
              </option>
            ))}
          </>
        </Select>
      </Box>

      <Wrap align="center" mt={2}>
        {tags.map((tag, index) => {
          const item = options.find((option) => option.value === tag);

          return (
            item && (
              <WrapItem key={tag}>
                <ItemTag
                  onRemove={handleRemoveTag(index)}
                  colorScheme={props.colorScheme}
                  size={props.size}
                >
                  {item?.shortLabel || item?.label}
                </ItemTag>
              </WrapItem>
            )
          );
        })}
      </Wrap>
    </>
  );
};
