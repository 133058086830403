import { useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useNavigate } from 'react-router-dom';

import { Folder } from '@carbon/icons-react/next';
import { Text, Checkbox, Flex, Grid, Box } from '@chakra-ui/react';

import { useAuthContext } from 'contexts/AuthContext';
import { useLibraryContext } from 'features/Library/context';
import { useCurrentLibraryLocation } from 'features/Library/hooks/useCurrentLibraryLocation';
import { DropResults, LibraryLocation } from 'features/Library/types';
import { downloadFile } from 'features/Library/utils/downloadFile';
import { getBgDropColor } from 'features/Library/utils/getBgDropColor';
import { libraryTypeFolderLinkMap } from 'features/Library/utils/libraryTypeLinkMap';
import { useMoveResourceInLibrary } from 'gql/library/mutations/moveResourceInLibrary';
import { Links } from 'router/links';
import { LibraryMediaOutput, LibrarySharedMediaOutput } from 'types';
import { generateUrlById } from 'utils/links';
import { concat } from 'utils/string';

import { DragItemType, INFO_SEPARATOR } from '../../constants';
import { useGetInfoItems } from '../../hooks/useGetInfoItems';
import { Favourite } from './Favourite';
import { FileIcon } from './FileIcon';
import { InfoItems } from './InfoItems';
import { ItemLocation } from './ItemLocation';
import { Menu } from './Menu';
import { SharedWith } from './SharedWith';

interface Props {
  item: LibraryMediaOutput | LibrarySharedMediaOutput;
  isChecked: boolean;
  displayCheckbox: boolean;
  onCheckToggle: (
    item: LibraryMediaOutput | LibrarySharedMediaOutput,
    checked: boolean
  ) => void;
  handleItemClick: () => void;
  enableDragAndDrop: boolean;
  downloadUrl: string | undefined;
}

export const ItemRowDisplay = ({
  item,
  onCheckToggle,
  isChecked,
  handleItemClick,
  downloadUrl,
  displayCheckbox,
  enableDragAndDrop,
}: Props) => {
  const { libraryLocation } = useCurrentLibraryLocation();
  const { moveResourceInLibrary } = useMoveResourceInLibrary();
  const [isMouseOver, setIsMouseOver] = useState(false);
  const { previewModal } = useLibraryContext();
  const navigate = useNavigate();
  const { account } = useAuthContext();
  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: [DragItemType.FILE, DragItemType.FOLDER],
    drop: () => ({ id: item.id, isDirectory: item.isDirectory }),
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
  }));
  const [, dragRef] = useDrag(() => ({
    type: item.isDirectory ? DragItemType.FOLDER : DragItemType.FILE,
    item,
    end: (droppedItem, monitor) => {
      const dropResult = monitor.getDropResult<DropResults>();

      if (dropResult?.isDirectory && dropResult.id !== droppedItem.id) {
        moveResourceInLibrary({
          libraryType: droppedItem.libraryType,
          resourceId: droppedItem.id,
          destinationId: dropResult.id,
        });
      }
    },
  }));

  const handleClick = () => {
    if (
      item.isDirectory &&
      libraryLocation === LibraryLocation.SearchLibraryContext &&
      account
    ) {
      const isResourceShared =
        item.shares.length > 0
          ? item.shares.some((member) => member.id === account.id)
          : false;

      if (isResourceShared) {
        navigate(generateUrlById(Links.librarySharedWithMeFolder, item.id));
      } else {
        navigate(
          generateUrlById(libraryTypeFolderLinkMap[item.libraryType], item.id)
        );
      }
    }

    if (
      item.isDirectory &&
      libraryLocation !== LibraryLocation.RecentlyDeleted &&
      libraryLocation !== LibraryLocation.SearchLibraryContext
    ) {
      handleItemClick();
    } else if (!downloadUrl) {
      throw new Error('cannot open file');
    } else if (
      /^(?!image\/heic$)(?:image|video|audio)\/\w*$/.test(item.mimeType)
    ) {
      previewModal.onOpen(item, downloadUrl);
    } else if (/^application\/pdf$/.test(item.mimeType)) {
      window.open(downloadUrl);
    } else {
      downloadFile(downloadUrl, item);
    }
  };

  const prepareName = () => {
    if (item.shares) {
      if (item.shares.length > 0) {
        return concat(item.name, 16);
      }
    }

    return concat(item.name, 50);
  };

  return (
    <Box
      ref={drop}
      backgroundColor={getBgDropColor(item.isDirectory, canDrop, isOver)}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      <Grid
        position="relative"
        ref={enableDragAndDrop ? dragRef : null}
        gridTemplateColumns="auto auto auto auto 1fr minmax(20px, auto) auto"
        alignItems="center"
        gap={4}
        paddingY={2}
        paddingX={6}
        borderWidth="1px"
        borderColor={isOver && item.isDirectory ? 'ui.grey2' : 'ui.grey4'}
        h="3.75rem"
      >
        {displayCheckbox ? (
          <Checkbox
            isChecked={isChecked}
            onChange={(event) => onCheckToggle(item, event.target.checked)}
          />
        ) : null}
        {item.isDirectory ? (
          <Folder color="ui.grey2" size={32} />
        ) : (
          <FileIcon mimeType={item.mimeType} name={item.name} />
        )}
        <Flex
          gap={4}
          cursor="pointer"
          justifyContent="center"
          alignItems="center"
          _hover={{ textDecoration: 'underline' }}
          onClick={handleClick}
        >
          <Text size="p2">{prepareName()}</Text>
          <Text size="p2">{INFO_SEPARATOR}</Text>
        </Flex>
        <Flex gap={4} alignItems="center">
          <InfoItems items={useGetInfoItems(item)} />
          {libraryLocation &&
            [
              LibraryLocation.Favourites,
              LibraryLocation.RecentlyDeleted,
            ].includes(libraryLocation) && <ItemLocation item={item} />}
        </Flex>
        <SharedWith item={item} />
        <Favourite item={item} isMouseOver={isMouseOver} />
        <Menu item={item} />
      </Grid>
    </Box>
  );
};
