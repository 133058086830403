import { useAdvisorConnections } from 'gql/connections/query/advisorConnections';
import { useFamilyConnections } from 'gql/connections/query/familyConnections';
import { usePeerConnections } from 'gql/connections/query/peerConnections';

import { ConnectionSection } from '../ConnectionSection';

export const AllConnections = () => {
  const { familyConnections, ...familyConnectionsStatus } =
    useFamilyConnections();

  const { advisorConnections, ...advisorConnectionsStatus } =
    useAdvisorConnections();

  const { peerConnections, ...peerConnectionsStatus } = usePeerConnections();

  return (
    <>
      <ConnectionSection
        heading="Family"
        connections={familyConnections}
        {...familyConnectionsStatus}
      />
      <ConnectionSection
        heading="Peers"
        connections={peerConnections}
        {...peerConnectionsStatus}
      />
      <ConnectionSection
        heading="Advisors"
        connections={advisorConnections}
        {...advisorConnectionsStatus}
      />
    </>
  );
};
