import { useTranslation } from 'react-i18next';

import { useListInsights } from 'hooks/insights';
import { InsightStatusEnum } from 'types';

import { CarouselInsightCard } from '../Card/CarouselInsightCard';
import { CarouselBase } from '../CarouselBase';
import { CAROUSEL_NAV_BUTTON_SIZE, PAGINATION_GAP_SIZE } from '../SwiperButton';

export const CarouselInsights = () => {
  const { t } = useTranslation(['insights']);
  const { listInsights } = useListInsights({
    filters: {
      statuses: [InsightStatusEnum.Published],
      visibility: { userHasAccessTo: true },
    },
    pagination: { limit: 5, offset: 0 },
  });

  return (
    <CarouselBase
      items={listInsights}
      ItemComponent={CarouselInsightCard}
      title={t('insights:itriom-insights')}
      slidesPerView={1}
      buttonStyling={{
        top: `calc(50% - ${
          CAROUSEL_NAV_BUTTON_SIZE / 2
        }px - ${PAGINATION_GAP_SIZE}px)`,
      }}
    />
  );
};
