import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ChevronRight } from '@carbon/icons-react/next';
import { Flex, Text } from '@chakra-ui/react';

import { INFO_SEPARATOR } from 'features/Library/constants';
import { useCurrentLibraryLocation } from 'features/Library/hooks/useCurrentLibraryLocation';
import { LibraryLocation } from 'features/Library/types';
import { LibraryMediaOutput, LibrarySharedMediaOutput } from 'types';

import { FolderLink } from './FolderLink';
import { HoverablePaths } from './HoverablePaths';
import { getItemPaths } from './utils/getItemPaths';

export const ItemLocation = ({
  item,
}: {
  item: LibraryMediaOutput | LibrarySharedMediaOutput;
}) => {
  const { libraryLocation } = useCurrentLibraryLocation();
  const { t } = useTranslation(['library']);

  const itemPaths = useMemo(() => getItemPaths(item, t), [item, t]);
  const libraryBasePath = itemPaths[0];
  const remainingPaths = itemPaths.slice(1, -1);
  const [lastPath] = itemPaths.slice(-1);

  if (
    !libraryBasePath ||
    !libraryLocation ||
    ![LibraryLocation.Favourites, LibraryLocation.RecentlyDeleted].includes(
      libraryLocation
    )
  ) {
    return null;
  }

  return (
    <Flex gap={2} alignItems="center">
      <Text pr={2}>{INFO_SEPARATOR}</Text>
      <FolderLink
        isDeep={item.resolvedPath.length > 1}
        isShared={item.shares === undefined ? false : item.shares.length > 0}
        path={libraryBasePath}
      />
      {!!remainingPaths.length && (
        <Flex gap={2} alignItems="center">
          <ChevronRight />
          <HoverablePaths
            isDeep={item.resolvedPath.length > 1}
            isShared={
              item.shares === undefined ? false : item.shares.length > 0
            }
            paths={remainingPaths}
          />
        </Flex>
      )}
      {lastPath && lastPath !== libraryBasePath && (
        <Flex gap={2} alignItems="center">
          <ChevronRight />
          <FolderLink
            isDeep={item.resolvedPath.length > 1}
            isShared={
              item.shares === undefined ? false : item.shares.length > 0
            }
            path={lastPath}
          />
        </Flex>
      )}
    </Flex>
  );
};
