import { ValuesWheel, CategoryWheel, CenterWheel } from './components';
import {
  WheelStateContextProvider,
  BuildingAnimationStateContextProvider,
} from './contexts';

export const WheelModel = () => {
  return (
    <BuildingAnimationStateContextProvider>
      <WheelStateContextProvider>
        <ValuesWheel>
          <CategoryWheel>
            <CenterWheel />
          </CategoryWheel>
        </ValuesWheel>
      </WheelStateContextProvider>
    </BuildingAnimationStateContextProvider>
  );
};
