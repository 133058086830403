import { Account, TriggeredCtaEnum } from 'types';

type CreateTemplateParams = {
  type: TriggeredCtaEnum;
  account: Account;
  eventHeading: string;
  eventId: string;
  userMessage?: string;
};

const createJoinTemplate = ({
  account,
  eventHeading,
  eventId,
}: Omit<CreateTemplateParams, 'type'>): string => {
  const { email, firstName, lastName } = account;

  return `@User requested to join EVENT: 
    name: ${eventHeading}
    id: ${eventId}

    User's email address is: ${email}
    User's name is: ${firstName} ${lastName}`;
};

const createHelpTemplate = ({
  account,
  eventHeading,
  eventId,
  userMessage,
}: Omit<CreateTemplateParams, 'type'>): string => {
  const { email, firstName, lastName } = account;

  return `@User requested for more information about EVENT: 
    name: ${eventHeading}
    id: ${eventId}

    User's email address is: ${email}
    User's name is: ${firstName} ${lastName}
    
    Please contact the customer as soon as possible. 
    User's message is:
    ${userMessage}`;
};

export const createTemplate = ({
  type,
  account,
  eventHeading,
  eventId,
  userMessage,
}: CreateTemplateParams): string => {
  if (type === TriggeredCtaEnum.Join) {
    return createJoinTemplate({ account, eventHeading, eventId });
  }

  return createHelpTemplate({ account, eventHeading, eventId, userMessage });
};

export const initialMessage = {
  message: '',
};
