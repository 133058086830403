import { gql, useMutation } from '@apollo/client';

import { useWithMutationToast } from 'hooks/useWithMutationToast';
import { Mutation, MutationMoveResourceInLibraryArgs } from 'types';

import { getResourcesFromDirectoryInLibraryGql } from '../documents';

const moveResourceInLibraryGql = gql`
  mutation Mutation(
    $libraryType: LibraryTypeEnum!
    $resourceId: String!
    $destinationId: String
  ) {
    moveResourceInLibrary(
      libraryType: $libraryType
      resourceId: $resourceId
      destinationId: $destinationId
    ) {
      id
    }
  }
`;

export const useMoveResourceInLibrary = () => {
  const [mutation] = useMutation<
    Pick<Mutation, 'moveResourceInLibrary'>,
    MutationMoveResourceInLibraryArgs
  >(moveResourceInLibraryGql, {
    fetchPolicy: 'no-cache',
    refetchQueries: [getResourcesFromDirectoryInLibraryGql],
  });

  const handleMutation = (variables: MutationMoveResourceInLibraryArgs) =>
    mutation({ variables });

  const moveResourceInLibrary = useWithMutationToast(handleMutation, {
    disableSuccessMessage: true,
  });

  return { moveResourceInLibrary };
};
