import { gql, QueryHookOptions, useQuery } from '@apollo/client';

import { Query } from 'types';
import { SdgConfiguratorOutputFragment } from 'types/generated-fragments';

export const personalSdgsGql = gql`
  ${SdgConfiguratorOutputFragment}
  query PersonalSdgsConfiguration {
    personalSdgsConfiguration {
      ...SdgConfiguratorOutputFragment
    }
  }
`;

export const usePersonalSdgs = (options?: QueryHookOptions) => {
  const { data, error, loading } = useQuery<
    Pick<Query, 'personalSdgsConfiguration'>
  >(personalSdgsGql, options);

  return { personalSdgs: data?.personalSdgsConfiguration, error, loading };
};
