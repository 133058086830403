import { useTranslation } from 'react-i18next';

import { Star, StarFilled } from '@carbon/icons-react/next';
import { Flex } from '@chakra-ui/react';

import { useAddLibraryMediaToFavorite } from 'gql/library/mutations/addLibraryMediaToFavorite';
import { useRemoveLibraryMediaFromFavorite } from 'gql/library/mutations/removeLibraryMediaFromFavourite';
import { LibraryMediaOutput } from 'types';

interface Props {
  item: Pick<LibraryMediaOutput, 'isFavorite' | 'id'>;
  isMouseOver: boolean;
}

export const Favourite = ({ item, isMouseOver }: Props) => {
  const { t } = useTranslation(['library']);
  const { addLibraryMediaToFavorite } = useAddLibraryMediaToFavorite();
  const { removeLibraryMediaFromFavorite } =
    useRemoveLibraryMediaFromFavorite();

  const toggleFavourite = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (!item.isFavorite) {
      addLibraryMediaToFavorite({ resourceId: item.id });
    } else {
      removeLibraryMediaFromFavorite({ resourceId: item.id });
    }
  };

  return (
    <Flex
      marginLeft="auto"
      w="42px"
      h="full"
      alignItems="center"
      cursor="pointer"
      aria-label={t('library:favourite')}
      onClick={toggleFavourite}
    >
      {!item.isFavorite && isMouseOver && <Star size={20} />}
      {item.isFavorite && <StarFilled size={20} />}
    </Flex>
  );
};
