import { useTranslation } from 'react-i18next';

import { Box, VStack, Divider, Flex } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';

import { Avatar } from 'components/Avatar';
import { CalendarBlock } from 'components/CalendarBlock';
import { DeleteEntityConfirmationModal } from 'components/Posts/DeleteEntityConfirmationModal';
import { ManagePostMenuButton } from 'components/Posts/ManagePostMenuButton';
import { EditPostForm } from 'components/Posts/PostForm/EditPostForm';
import { ManagePostContextProvider } from 'contexts/ManagePostContext';
import { useDeletePost } from 'hooks/posts/useDeletePost';
import { Post } from 'types';

type Props = {
  post: Post;
};

export const ContentCommentItem = ({ post }: Props) => {
  const { t } = useTranslation(['common', 'cms']);

  const { postedBy, createdAt, text, createdBy } = post;

  const entityName = t('common:comment').toLowerCase();

  const { handleDeletePost } = useDeletePost();

  const handleDelete = () => handleDeletePost(post.id);

  return (
    <ManagePostContextProvider createdBy={createdBy} entityName={entityName}>
      {({
        menuElements,
        isEditing,
        handleCancelEditing,
        isDeleteConfirmationModalOpen,
        handleCancelDeleting,
      }) => (
        <Box backgroundColor="" width="100%" mt={0} position="relative">
          <VStack align="flex-start">
            <Divider mt={2} mb={4} sx={{ borderColor: 'grey' }} />

            <Flex direction="column" w="full">
              <Avatar profile={postedBy} variant="horizontal" />
              <Box ml={14} mr={2}>
                <CalendarBlock
                  startDate={createdAt}
                  containerStyling={{ color: 'ui.grey2' }}
                />
                {isEditing ? (
                  <EditPostForm
                    post={post}
                    onUpdate={handleCancelEditing}
                    textLabel={t('common:edit-entity', { entity: entityName })}
                  />
                ) : (
                  <Text mt={6}>{text}</Text>
                )}
              </Box>
            </Flex>
          </VStack>

          <Box position="absolute" right={4} top={4}>
            <ManagePostMenuButton
              isEditing={isEditing}
              handleCancelEditing={handleCancelEditing}
              menuElements={menuElements}
            />
          </Box>

          <DeleteEntityConfirmationModal
            isOpen={isDeleteConfirmationModalOpen}
            onClose={handleCancelDeleting}
            handleDelete={handleDelete}
            entity={entityName}
          >
            {t('cms:delete.action-explanation', {
              entity: entityName,
              entityWithRelatedContent: entityName,
            })}
          </DeleteEntityConfirmationModal>
        </Box>
      )}
    </ManagePostContextProvider>
  );
};
