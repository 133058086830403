import {
  ImagePosition,
  SideImageTile,
} from 'components/ContentGrid/SideImageTile/SideImageTile';
import { QueryStateGuard } from 'components/QueryStateGuard';
import { useInitiative } from 'hooks/initiatives';
import { Links } from 'router/links';
import { generateUrlById } from 'utils/links';
import { flattenTags } from 'utils/tags';

export const InitiativeCard = ({
  initiativeId,
  imagePosition,
}: {
  initiativeId: string;
  imagePosition: ImagePosition;
}) => {
  const { initiative, loading, error } = useInitiative(initiativeId);

  return (
    <QueryStateGuard loading={loading} error={error}>
      {!!initiative && (
        <SideImageTile
          tags={flattenTags(initiative?.tags)}
          imagePosition={imagePosition}
          image={initiative.hero.heroImage.signedUrl || ''}
          title={initiative.hero.headline}
          description={initiative.hero.summary}
          link={generateUrlById(Links.initiativesSingle, initiative.id)}
        />
      )}
    </QueryStateGuard>
  );
};
