import { Link } from 'react-router-dom';

import { Flex, Text, Image, Heading } from '@chakra-ui/react';

import { ItriomTag } from 'components/ItriomTag';
import { Links } from 'router/links';
import { Insight } from 'types';
import { generateUrlById } from 'utils/links';
import { flattenTags } from 'utils/tags';

export const CarouselInsightCard = ({ item }: { item: Insight }) => {
  const { id, hero, tags } = item;
  const { heroImage, headline, summary } = hero;

  return (
    <Flex
      as={Link}
      to={generateUrlById(Links.insight, id)}
      key={id}
      h={400}
      position="relative"
      _hover={{ '& img': { transform: 'scale(1.05)' } }}
      overflow="hidden"
    >
      <Image
        src={heroImage.signedUrl}
        w="full"
        h="full"
        objectFit="cover"
        transition="transform ease-out 0.2s"
      />
      <Flex
        position="absolute"
        left={0}
        bottom={0}
        p={4}
        pb={3}
        h="50%"
        w="45%"
        gap={2}
        direction="column"
        bgColor="ui.white"
        opacity="90%"
        color="primary.blue.default"
      >
        <Heading size="h3" noOfLines={2}>
          {headline}
        </Heading>

        <Text size="p2" noOfLines={3}>
          {summary}
        </Text>

        <Flex alignItems="flex-end" flex={1} wrap="wrap" gap={1}>
          {flattenTags(tags).map((tag) => (
            <ItriomTag key={tag} customVariant="dark">
              {tag}
            </ItriomTag>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};
