import { Links } from 'router/links';

import { NextGenCardProps } from '../types';

export const INSIGHTS: NextGenCardProps[] = [
  {
    src: '/assets/nextGenServices/insights/warfare.png',
    title: 'Cognitive warfare. Self-awareness, influence & control',
    bodyText:
      'We live in a Volatile, Uncertain, Complex and Ambiguous (VUCA) world. Many of us cannot define what normal is in the World context or our daily lives. How do we take control and flourish?',
    author: 'Tim Boughton',
    findOutMoreUrl:
      'https://itriom.com/cognitive-warfare-self-awareness-influence-control/',
    downloadFilePath:
      '/assets/nextGenServices/insights/pdf/Itriom_Insights_Cognitive-Warfare.pdf',
  },
  {
    src: '/assets/nextGenServices/insights/landscape.png',
    title:
      'Understanding the geopolitical landscape: the terrain of human influence',
    bodyText:
      'Geopolitics is about micro relationships producing macro-outcomes. This is the human influence terrain and understanding it is the key to becoming wiser actors in the geopolitical landscape.',
    author: 'Simon Hulland-Lucas',
    findOutMoreUrl:
      'https://itriom.com/understanding-the-geopolitical-landscape-the-terrain-of-human-influence/',
    downloadFilePath:
      '/assets/nextGenServices/insights/pdf/Itriom_Insights_Geopolitical-Landscape.pdf',
  },
  {
    src: '/assets/nextGenServices/insights/uhnw.png',
    title:
      'How UHNW families can successfully manage sustainability-related risks & enjoy the opportunities they bring',
    bodyText:
      'Itriom has developed proprietary tools that enable UHNW families to realise the tangible opportunities that sustainability-based strategies offer.',
    author: 'Dr Herb Castillo',
    findOutMoreUrl:
      'https://itriom.com/how-uhnw-families-can-successfully-manage-sustainability-related-risks-and-enjoy-the-opportunities-they-bring/',
    downloadFilePath:
      '/assets/nextGenServices/insights/pdf/Itriom_Insights_Sustainability-Related-Risks.pdf',
  },
];

export const SERVICES: NextGenCardProps[] = [
  {
    src: '/assets/nextGenServices/services/leadership.png',
    title:
      'Next Generation Leadership & Resilience: Strengthening & instilling future leadership',
    bodyText:
      'We instill unique and all-encompassing leadership. Uniting UHNW families in purpose and engaging multiple generations in delivering collective initiatives while creating legacies with enduring and significant ESG impact.',
    findOutMoreUrl: Links.nextGenLR.toString(),
  },
  {
    src: '/assets/nextGenServices/services/geostrategy.png',
    title:
      'Next Generation Sustainability: Accelerating & maintaining sustainability',
    bodyText:
      'We develop the methodologies to help families identify opportunities for impact that best express their values, and the frameworks which track the resulting impact. Enabling families to make genuine and lasting environmental commitments.',
    findOutMoreUrl: Links.nextGenSustainability.toString(),
  },
  {
    src: '/assets/nextGenServices/services/sustainability.png',
    title: 'Next Generation Geostrategy: Providing a strategy of understanding',
    bodyText:
      'Through courses and collaborative projects, we’re providing the next generation with the geostrategic knowledge, understanding and opportunities they’ll need to take their future place in the world.',
    findOutMoreUrl: Links.nextGenGeoStrategy.toString(),
  },
];

export const LEADERS: NextGenCardProps[] = [
  {
    src: '/assets/nextGenServices/leaders/matthew.png',
    title: 'Matthew Millard-Beer',
    subtitle: 'Managing Partner \n CEO',
    bodyText:
      'Matthew is Itriom Founder & CEO, providing solutions and a global platform for Ultra High Net Worth Principals and their families to engage in unifying purpose, enduring legacy, and sustainable impact.',
  },
  {
    src: '/assets/nextGenServices/leaders/quentin.jpg',
    title: 'Quentin Naylor',
    subtitle: 'Senior Partner \n COO',
    bodyText:
      'Quentin is Itriom COO, bringing to life our solutions and the global platform for clients and their families as they embark on their journey to achieve their purpose and leave a lasting legacy.',
  },
  {
    src: '/assets/nextGenServices/leaders/tim.png',
    title: 'Tim Boughton',
    subtitle: 'Senior Partner \n Practice Leader - Leadership & Resilience',
    bodyText:
      'Renowned family office thought leader, Tim works with UHNW families to ensure they are fully equipped to deliver their legacies inter-generationally and effectively.',
  },
  {
    src: '/assets/nextGenServices/leaders/herb.png',
    title: 'Dr. Herb Castillo',
    subtitle: 'Associate Partner \n Practice Leader - Sustainability',
    bodyText:
      'Dr. Herb creates methodologies and frameworks for managing, measuring and assessing sustainability performance. His work identifies where maximum impact can be made.',
  },
  {
    src: '/assets/nextGenServices/leaders/simon.png',
    title: 'Simon Hulland-Lucas',
    subtitle: 'Senior Partner \n Practice Leader - Geostrategy',
    bodyText:
      'Simon harnesses research, liaison and networks globally to identify opportunities for Itriom, building the knowledge needed to deliver intergenerational legacies for UHNW families.',
  },
];
