import { gql } from '@apollo/client';

import { InsightDeepNestingFragment } from 'gql/fragments';
import { usePaginatedQuery } from 'hooks/pagination';
import { ListInsightInput, Query, QueryListInsightsArgs } from 'types';

export const insightsGql = gql`
  ${InsightDeepNestingFragment}
  query ListInsights(
    $pagination: PaginationInput = { offset: 0, limit: 100 }
    $filters: ListInsightInput = {}
  ) {
    listInsights(pagination: $pagination, filters: $filters) {
      nodes {
        ...InsightDeepNestingFragment
      }
      totalCount
    }
  }
`;

const NOTIFICATIONS_DEFAULT_OFFSET = 0;
const NOTIFICATIONS_DEFAULT_LIMIT = 20;

const DEFAULT_PAGINATION_VARIABLES = {
  offset: NOTIFICATIONS_DEFAULT_OFFSET,
  limit: NOTIFICATIONS_DEFAULT_LIMIT,
};

export const useLoadInsights = (filters: ListInsightInput = {}) => {
  const { initialLoad, loadMore, reload, error, loading } = usePaginatedQuery<
    Pick<Query, 'listInsights'>,
    QueryListInsightsArgs
  >(insightsGql, DEFAULT_PAGINATION_VARIABLES);

  const loadInsights = () =>
    initialLoad({ filters }).then((result) => result.listInsights);

  const loadMoreInsights = () =>
    loadMore({ filters }).then((result) => result.listInsights);

  const reloadInsights = () =>
    reload({ filters }).then((result) => result.listInsights);

  return {
    loadInsights,
    loadMoreInsights,
    reloadInsights,
    error,
    loading,
  };
};
