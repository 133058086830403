import { Link } from 'react-router-dom';

import { ArrowRight16 } from '@carbon/icons-react';
import { Button, Flex, Heading } from '@chakra-ui/react';

import { Links } from 'router/links';

import { CheckIcon } from '../..';

type Props = {
  title: string;
  description: string;
};

export const ThankYouWithSdgLink = ({ title, description }: Props) => {
  return (
    <Flex
      w={600}
      gap={12}
      flexDirection="column"
      align="center"
      textAlign="center"
    >
      <Heading size="h4">{title}</Heading>
      <CheckIcon color="ui.white" />
      <Heading size="h6" mt={4}>
        {description}
      </Heading>
      <Link to={Links.sdgConfiguratorWelcome}>
        <Button variant="primaryLime" rightIcon={<ArrowRight16 />}>
          Launch the SDG configurator
        </Button>
      </Link>
    </Flex>
  );
};
