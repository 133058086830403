import { useQuery } from '@apollo/client';

import { Query, QueryGetResourcesFromDirectoryInLibraryArgs } from 'types';

import { getResourcesFromDirectoryInLibraryGql } from '../documents';

type GetResourceDetailsInLibrary =
  QueryGetResourcesFromDirectoryInLibraryArgs & { skip?: boolean };

export const useGetResourcesFromDirectoryInLibrary = ({
  libraryType,
  destinationId,
  skip,
}: GetResourceDetailsInLibrary) => {
  const { data, error, loading, refetch } = useQuery<
    Pick<Query, 'getResourcesFromDirectoryInLibrary'>,
    Partial<QueryGetResourcesFromDirectoryInLibraryArgs>
  >(getResourcesFromDirectoryInLibraryGql, {
    variables: {
      libraryType,
      destinationId,
    },
    skip,
  });

  return {
    resources: data?.getResourcesFromDirectoryInLibrary,
    loading,
    error,
    refetch,
  };
};
